import { useMemo, useState } from 'react';
import {
  chakra, Box, Button, Icon, Input,
} from '@chakra-ui/react';

import { useTranslation } from 'hooks';

import { DiamondStone } from 'components/Icons';

import { USERS_PRONOUNS, USERTAGS } from '../../constants';
import locales from './i18n';

type UserTag = {
  key: keyof typeof USERTAGS;
  label: string;
  price: number;
};

interface Props {
  filter: (tag: UserTag) => boolean;
  withBorderColor: (tag: UserTag) => boolean;
  onClick: (tag: UserTag['key']) => () => void;
  addingLabel?: string;
  pronoun?: keyof typeof USERS_PRONOUNS;
  showPrice?: boolean;
}

const TagsList: React.FC<Props> = ({
  filter, withBorderColor, onClick, addingLabel, pronoun, showPrice,
}) => {
  const { t } = useTranslation(locales);

  const [searchText, setSearchText] = useState('');

  const groups = useMemo(() => {
    const res: Record<string, UserTag[]> = {};

    Object.keys(USERTAGS).forEach((tagkey) => {
      const tag = USERTAGS[tagkey as keyof typeof USERTAGS];
      if (!res[tag.group]) res[tag.group] = [];
      res[tag.group].push({ ...tag, key: tagkey as keyof typeof USERTAGS });
    });

    return res;
  }, []);

  const tagsSearchIndex = useMemo(() => {
    const res: Record<string, string> = {};

    Object.keys(USERTAGS).forEach((tagkey) => {
      res[t(`global:TAG.${tagkey}`, { context: pronoun }).toLowerCase()] = tagkey;
    });

    return res;
  }, [pronoun, t]);

  const searchResults: Record<string, boolean> = useMemo(() => {
    if (!searchText) return {};
    const search = searchText.toLowerCase();
    const results: Record<string, boolean> = {};

    Object.keys(tagsSearchIndex).forEach((tag) => {
      if (tag.includes(search)) {
        results[tagsSearchIndex[tag]] = true;
      }
    });

    return results;
  }, [searchText, tagsSearchIndex]);


  const filterSearch = (tag: UserTag) => {
    if (!searchText) return true;
    return searchResults[tag.key];
  };

  return (
    <Box>
      <Box mb={4}>
        <Input value={searchText} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setSearchText(event?.target.value); }} placeholder={t('Search tags')} />
      </Box>
      {Object.values(groups).map(group => (
        <Box key={`taggroup-${group}`} mb={4}>
          {Object.values(group).filter(filter).filter(filterSearch).map((tag) => (
            <Button
              key={`usertag-general-${tag.label}`}
              mr={3}
              mb={2}
              borderColor={withBorderColor(tag) ? 'gold' : undefined}
              borderWidth={1}
              fontWeight="normal"
              size="xs"
              onClick={onClick(tag.key)}
              leftIcon={tag.price > 0 ? <Icon as={DiamondStone} boxSize={3} fill="gold.500" /> : undefined}
              isLoading={tag.label === addingLabel}
              isDisabled={!!addingLabel && tag.label !== addingLabel}
            >
              <chakra.span>{t(`global:TAG.${tag.key}`, { context: pronoun })}</chakra.span>
              {showPrice && tag.price > 0 && <chakra.span ml={3} pl={3} borderLeft="1px" borderColor="gray.300">{`§ ${tag.price}`}</chakra.span>}
            </Button>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default TagsList;
