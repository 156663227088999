import { useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTitle, useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';
import * as appSelectors from 'state/app/selectors';

import Sidebar from 'containers/Sidebar';
import Layout from 'components/Layout';
import PageTitle from 'components/PageTitle';
import ToggleButtonGroup from 'components/ToggleButtonGroup';

import Publications from './Publications';
import Threads from './Threads';
import LatestHashtags from '../LatestHashtags';
import locales from '../i18n';

const Hashtags = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(locales);

  useTitle(null);
  const uiLeftColumn = useSelector(appSelectors.selectIsUiLeftColumnActive);

  const selected = location.search.includes('threads') ? 'threads' : 'publications';

  useEffect(() => {
    // Fix scroll position on mount
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  useEffect(() => {
    dispatch(appActions.uiLeftColumn(true));
  }, [uiLeftColumn, dispatch]);

  const setSelected = (val) => {
    if (val === 'threads') history.replace(`/hashtags/${params.hashtag}?threads`);
    else history.replace(`/hashtags/${params.hashtag}`);
  };

  return (
    <Layout columns={2} feed leftColumnOpen={false} rightColumnOpen={false}>
      <div style={{ marginTop: '16px' }}>
        <LatestHashtags />

        <PageTitle>{`#${params.hashtag}`}</PageTitle>
        <ToggleButtonGroup
          buttons={[
            { key: 'publications', label: t('global:Publications') },
            { key: 'threads', label: t('global:Threads') },
          ]}
          selected={selected}
          onChange={setSelected}
        />

        {selected === 'publications'
          ? <Publications />
          : <Threads />
        }
      </div>
      <Sidebar />
    </Layout>
  );
};

export default Hashtags;
