import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import * as appSelectors from 'state/app/selectors';

import { Link } from 'react-router-dom';
import useHomeLinkClick, { HOME_URL } from 'hooks/useHomeLinkClick';
import NavLeft from './NavLeft';
import NavRight from './NavRight';
import logo from '../logo.svg';

const NavContainer = styled.div`
  height: 64px;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  background-color: ${colors.red};
  z-index: 1020;

  @media(max-width: 768px) {
    justify-content: space-between;
    ${props => props.hide && `
      display: none;
    `}
  }

  ${props => props.pride && `
    --opa: .65;
    --g-red: rgba(208, 75, 54, var(--opa));
    --g-orange: rgba(227, 101, 17, var(--opa));
    --g-yellow: rgba(255, 186, 0, var(--opa));
    --g-green: rgba(0, 177, 128, var(--opa));
    --g-blue: rgba(20, 122, 171, var(--opa));
    --g-indigo: rgba(103, 89, 151, var(--opa));

    background-color: black;
    background-image: linear-gradient(
      var(--g-red) 0%,
      var(--g-red) 16.6666%,
      var(--g-orange) 16.6666%,
      var(--g-orange) 33.333%,
      var(--g-yellow) 33.333%,
      var(--g-yellow) 50%,
      var(--g-green) 50%,
      var(--g-green) 66.6666%,
      var(--g-blue) 66.6666%,
      var(--g-blue) 83.3333%,
      var(--g-indigo) 83.3333%,
      var(--g-indigo) 100%
    );
  `}
`;

NavContainer.propTypes = {
  hide: PropTypes.bool,
  pride: PropTypes.bool,
};

NavContainer.defaultProps = {
  hide: false,
  pride: false,
};

const Logo = styled.div`
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 160px;
  width: 300px;
  height: 60px;
`;
Logo.displayName = 'Logo';

const LogoLink = styled(Link)`
  @media(max-width: 768px) {
    display: none;
  }
`;

const Nav = () => {
  const uiLeftColumn = useSelector(appSelectors.selectIsUiLeftColumnActive);
  const onLogoClick = useHomeLinkClick();

  return (
    <NavContainer hide={!uiLeftColumn}>
      <LogoLink to={HOME_URL} onClick={onLogoClick}>
        <Logo />
      </LogoLink>
      <NavLeft />
      <NavRight />
    </NavContainer>
  );
};

export default memo(Nav);
