import styled from 'styled-components';

interface WrapperProps extends React.HTMLProps<HTMLElement> {
  height?: string;
  maxHeight?: string;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: 100%;

  textarea {
    border: 0;
    resize: none;
    height: 16px;
  }

  .CodeMirror {
    background-color: #FFFFFF;
    color: #000000;
    font-family: ${props => props.theme.fontFamily};
    height: ${props => (props.height ? props.height : 'auto')};
    width: 100%;
    box-sizing: border-box;
  }

  .CodeMirror-cursor {
    border-left: 1px solid #000000;
  }

  ${props => props.maxHeight && `
    .CodeMirror-scroll {
      max-height: ${props.maxHeight};
    }
  `}

  .CodeMirror-empty {
    color: #999;
  }

  .CodeMirror-wrap pre.CodeMirror-line, .CodeMirror-wrap pre.CodeMirror-line-like {
    word-break: break-word;
  }

  .cm-header-1 {
    font-size: 2em;
  }

  .cm-header-2 {
    font-size: 1.5em;
  }

  .cm-hashtag {
    color: ${props => props.theme.colors.main};
  }

  .cm-mention, .mention, .cm-community, .community, .cm-channel, .channel {
    &::first-letter {
      font-weight: 500;
    }

    color: ${props => props.theme.colors.main};
    background-color: ${props => props.theme.colors.mainLight};
    text-decoration: none;
    padding: 0px;

    span {
      color: #aaa;
    }
  }

  .cm-strong {
    font-weight: bold;
  }

  .cm-formatting {
    opacity: .3;
  }

  .cm-builtin {
    color: black;
    opacity: 1;
  }

  .emoji-mart-emoji {
    vertical-align: text-bottom;
  }

  .cm-comment {
    color: #999;
    font-style: italic;
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
