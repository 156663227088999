import { useSelector } from 'react-redux';
import {
  Icon, MenuDivider, MenuItem, useDisclosure,
} from '@chakra-ui/react';
import { Pencil } from 'lucide-react';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';

import EditModal from '../Modals/Edit';
import locales from '../i18n';

interface Props {
  publicationId: string;
}

const MenuItemEditPublication: React.FC<Props> = ({ publicationId }) => {
  const { t } = useTranslation(locales);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const userIsAuthor = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.userIsAuthor(state, publicationId),
  );

  if (!userIsAuthor) return null;

  return (
    <>
      <MenuDivider />
      <MenuItem
        icon={<Icon as={Pencil} />}
        onClick={onOpen}
      >
        {t('global:Edit')}
      </MenuItem>

      {isOpen && (
        <EditModal publicationId={publicationId} close={onClose} />
      )}
    </>
  );
};

export default MenuItemEditPublication;
