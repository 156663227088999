import {
  Box, Flex, Skeleton, SkeletonCircle, SkeletonText, VStack,
} from '@chakra-ui/react';

const PublicationLoading = () => {
  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} margin="16px 16px 32px">
      <Flex alignItems="center" mb={4}>
        <SkeletonCircle size="10" />
        <Box ml={3}>
          <Skeleton height="20px" width="100px" mb={2} />
          <Skeleton height="16px" width="80px" />
        </Box>
      </Flex>

      <Skeleton height="450px" mb={4} />

      <SkeletonText noOfLines={2} spacing="4" mb={4} />

      <Flex justifyContent="space-between" alignItems="center">
        <Skeleton height="20px" width="60px" />
        <Flex>
          <Skeleton height="20px" width="30px" mr={2} />
          <Skeleton height="20px" width="30px" />
        </Flex>
      </Flex>

      <VStack mt={4} align="stretch" spacing={3}>
        <Flex alignItems="center">
          <SkeletonCircle size="8" mr={2} />
          <SkeletonText noOfLines={2} spacing="2" flex={1} />
        </Flex>
        <Flex alignItems="center">
          <SkeletonCircle size="8" mr={2} />
          <SkeletonText noOfLines={1} spacing="2" flex={1} />
        </Flex>
      </VStack>
    </Box>
  );
};

export default PublicationLoading;
