import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';
import { entityEqual } from 'state/channels/equalityFunctions';

import { SelectableListContainer } from 'components/SelectableList';
import Overflow from 'components/Layout/Overflow';

import Messenger from './Messenger';
import Channel from './Channel';
import Archived from './Archived';
import ExploreCreateButton from './ExploreCreateButton';

const Entities = ({ filter }) => {
  const entities = useSelector(
    state => channelSelectors.sortedAndFilteredEntitiesSelector(state, filter),
    entityEqual,
  );

  return (
    <SelectableListContainer>
      <Overflow>
        <ul>
          {entities.map(({ type, id }) => {
            if (type === 'messenger') {
              return (
                <Messenger
                  key={`chat-list-messenger-${id}`}
                  id={id}
                />
              );
            }

            return (
              <Channel key={`chat-list-channel-${id}`} id={id} />
            );
          })}

          <Archived filter={filter} />
        </ul>
      </Overflow>

      <ExploreCreateButton />
    </SelectableListContainer>
  );
};

Entities.propTypes = {
  filter: PropTypes.string,
};

Entities.defaultProps = {
  filter: '',
};

export default Entities;
