import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appSelectors from 'state/app/selectors';

import { FlexWrapper, FlexInnerWrapper } from 'components/FlexWrapper';
import Header from 'components/Header';
import UserAvatar from 'components/UserAvatar';
import EmptyState from 'components/EmptyState';

import RestrictedActions from '../Actions/Restricted';
import locales from '../../i18n';

const Blocked = ({ userId, username }) => {
  const history = useHistory();
  const { t } = useTranslation(locales);
  const historyCanGoBack = useSelector(appSelectors.selectHistoryCanGoBack);

  const renderAvatar = useCallback(() => (
    <UserAvatar userId={userId} onlineBorderColor="black" size="40px" />
  ), [userId]);

  const renderActions = useCallback(() => (
    <RestrictedActions userId={userId} />
  ), [userId]);

  const onBackClick = useCallback(() => {
    if (historyCanGoBack) {
      history.goBack();
    } else {
      history.push('/');
    }
  }, [history, historyCanGoBack]);

  return (
    <FlexWrapper canOverflow>
      <FlexInnerWrapper>
        <Header
          title={username}
          backgroundColor="white"
          foregroundColor="black"
          renderAvatar={renderAvatar}
          renderActions={renderActions}
          negativeBadge
          desktopAbsolute
          border="0"
          onBackClick={onBackClick}
        />

        <EmptyState
          title={t('You can\'t view this profile')}
          subtitle={t('This person has blocked you')}
        />
      </FlexInnerWrapper>
    </FlexWrapper>
  );
};

Blocked.propTypes = {
  userId: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
};

Blocked.defaultProps = {
};

export default Blocked;
