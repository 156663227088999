import { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';
import * as channelSelectors from 'state/channels/selectors';
import { useTranslation } from 'hooks';

import UserAvatar from 'components/UserAvatar';
import { ShieldStar, Shield, MoreActions } from 'components/Icons';
import Menu, { Item as MenuItem } from 'components/Menu';

import BanConfirmModal from './Modals/BanConfirmModal';
import MakeModConfirmModal from './Modals/MakeModConfirmModal';
import UnmakeModConfirmModal from './Modals/UnmakeModConfirmModal';
import ParticipantGenderSuffix from './ParticipantGenderSuffix';
import { CHANNEL_ROLES } from '../../../constants';
import locales from '../i18n';

const ContextMenu = styled(MoreActions).attrs({
  outline: true,
  color: '#999',
})`
  width: 4px;
  margin-left: auto;
`;

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  z-index: 1;

  ${props => !props.online && !props.selected && `
    opacity: .5;
  `}

  ${ContextMenu} {
    display: none;
  }

  ${props => (props.selected ? '&' : '&:hover')} {
    background-color: #e0e0e0;

    ${ContextMenu} {
      display: block;
    }
  }

  .userlink {
    width: 100%;
  }
`;

const ParticipantContent = styled.div`
  padding: 5px 10px;
  display: flex;

  .avatar {
    margin-right: 8px;
  }
`;

const ParticipantName = styled.div`
  align-self: center;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${props => props.busy && `
    animation: animation 1.25s forwards infinite linear;
    background: #F6F6F6;
    background: linear-gradient(to right,#DDD 8%,#F0F0F0 18%,#DFDFDF 33%);
    background-size: 800px 104px;
    position: relative;
    height: 12px;
    width: 100px;
    color: transparent;
    border-radius: 8px;

    @keyframes animation {
      0% {
        background-position: -468px 0
      }
      100% {
        background-position: 468px 0
      }
    }
  `}
`;

const Owner = styled(ShieldStar).attrs({
  outline: true,
  fill: '#999',
})`
  width: 18px;
  height: 22px;
  margin-right: 2px;
`;

const Mod = styled(Shield).attrs({
  outline: true,
  color: '#999',
})`
  width: 18px;
  height: 22px;
  margin-right: 2px;
`;

const show = (e, setFunction) => {
  e.preventDefault();
  e.stopPropagation();
  setFunction(true);
};

const Participant = memo(({
  userId,
  channelId,
  style,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  const user = useSelector(userSelectors.getById(userId), shallowEqual);
  const role = useSelector(state => channelSelectors.getRoleByUserId(state, channelId, userId));
  const iAmSuperior = useSelector(state => channelSelectors.iAmSuperior(state, channelId, userId));
  const iAmOwner = useSelector(state => channelSelectors.isOwnerOfChannel(state, channelId));
  const isOnline = useSelector(userSelectors.isOnline(userId));

  const [showingContextMenu, setShowingContextMenu] = useState(false);
  const [showingBanConfirmDialog, setShowingBanConfirmDialog] = useState(false);
  const [showingMakeModConfirmDialog, setShowingMakeModConfirmDialog] = useState(false);
  const [showingUnmakeModConfirmDialog, setShowingUnmakeModConfirmDialog] = useState(false);

  const showContextMenu = useCallback((e) => {
    show(e, setShowingContextMenu);
  }, []);
  const hideContextMenu = useCallback(() => {
    setShowingContextMenu(false);
  }, []);
  const showBanConfirmDialog = useCallback((e) => {
    show(e, setShowingBanConfirmDialog);
  }, []);
  const hideBanConfirmDialog = useCallback(() => {
    setShowingBanConfirmDialog(false);
  }, []);
  const showMakeModConfirmDialog = useCallback((e) => {
    show(e, setShowingMakeModConfirmDialog);
  }, []);
  const hideMakeModConfirmDialog = useCallback(() => {
    setShowingMakeModConfirmDialog(false);
  }, []);
  const showUnmakeModConfirmDialog = useCallback((e) => {
    show(e, setShowingUnmakeModConfirmDialog);
  }, []);
  const hideUnmakeModConfirmDialog = useCallback(() => {
    setShowingUnmakeModConfirmDialog(false);
  }, []);

  if (user.loading) dispatch(userActions.fetchData(userId));

  return (
    <div style={style}>
      <Wrapper
        online={isOnline}
        selected={showingContextMenu}
      >
        <Link to={`/@${user.username}`} className="userlink">
          <ParticipantContent>
            <UserAvatar userId={user.id} size="24px" />
            {role === CHANNEL_ROLES.OWNER && <Owner />}
            {role === CHANNEL_ROLES.MOD && <Mod />}

            <ParticipantName busy={user.loading}>{user.displayname}</ParticipantName>
            <ParticipantGenderSuffix userId={user.id} />
            {iAmSuperior && (
              <>
                <ContextMenu onClick={showContextMenu} className="nouserlink" />
                <Menu open={showingContextMenu} onClose={hideContextMenu} className="nouserlink">
                  {iAmOwner && role === CHANNEL_ROLES.MOD && (
                    <MenuItem onClick={showUnmakeModConfirmDialog}>
                      {t('Unmake mod', { context: user.pronoun })}
                    </MenuItem>
                  )}
                  {iAmOwner && role === CHANNEL_ROLES.USER && (
                    <MenuItem onClick={showMakeModConfirmDialog}>
                      {t('Make mod', { context: user.pronoun })}
                    </MenuItem>
                  )}
                  <MenuItem onClick={showBanConfirmDialog}>
                    {t('Ban user', { context: user.pronoun })}
                  </MenuItem>
                </Menu>
              </>
            )}
          </ParticipantContent>
        </Link>
      </Wrapper>

      {showingBanConfirmDialog && (
        <BanConfirmModal user={user} channelId={channelId} close={hideBanConfirmDialog} />
      )}
      {showingMakeModConfirmDialog && (
        <MakeModConfirmModal user={user} channelId={channelId} close={hideMakeModConfirmDialog} />
      )}
      {showingUnmakeModConfirmDialog && (
        <UnmakeModConfirmModal
          user={user}
          channelId={channelId}
          close={hideUnmakeModConfirmDialog}
        />
      )}
    </div>
  );
});

Participant.propTypes = {
  userId: PropTypes.number.isRequired,
  channelId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

Participant.defaultProps = {
  style: {},
};

export default Participant;
