import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import colors from 'utils/css/colors';
import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import { SelectableListItem } from 'components/SelectableList';
import UserAvatar from 'components/UserAvatar';
import Button from 'components/Button';
import { CloseCircle, CheckCircle } from 'components/Icons';
import UserLink from 'components/UserLink';

const Username = styled.div`
  color: ${colors.grey};
  font-size: 14px;
  margin-top: 2px;
`;
Username.displayName = 'Username';

const Request = ({
  userId,
  membershipId,
  approve,
  reject,
  working,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.getById(userId), shallowEqual);
  if (user.loading) dispatch(userActions.fetchData(user.id));

  const renderAvatar = useCallback(id => () => <UserAvatar userId={id} />, []);
  const onRejectClick = useCallback(
    (e) => { e.stopPropagation(); reject(membershipId); },
    [membershipId, reject],
  );
  const onApproveClick = useCallback(
    (e) => { e.stopPropagation(); approve(membershipId); },
    [membershipId, approve],
  );

  const actions = working
    ? [<Button className="empty" loading />]
    : [
      <Button onClick={onRejectClick} className="empty">
        <CloseCircle color={colors.red} outline />
      </Button>,
      <Button onClick={onApproveClick} className="empty">
        <CheckCircle color={colors.approve} outline />
      </Button>,
    ];

  return (
    <UserLink userId={user.id}>
      <SelectableListItem
        renderAvatar={renderAvatar(userId)}
        title={user.displayname}
        actions={actions}
      >
        <Username>{`@${user.username}`}</Username>
      </SelectableListItem>
    </UserLink>
  );
};

Request.propTypes = {
  userId: PropTypes.number.isRequired,
  membershipId: PropTypes.string.isRequired,
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  working: PropTypes.bool.isRequired,
};

Request.defaultProps = {
};

export default memo(Request);
