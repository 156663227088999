import { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import fastdom from 'fastdom';

interface WrapperProps extends Omit<React.HTMLProps<HTMLElement>, 'coords'> {
  coords: Props['coords'];
  dimensions: Props['dimensions'];
  gap: number;
  lineHeight: number;
  paddingLeft: number;
  paddingTop: number;
  position: Position;
}

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  background: white;
  z-index: 1000;
  box-shadow: 0px 0px 4px 0px #b9b9b9;

  ${props => (props.position.vertical === 'bottom' ? `
    top: ${props.coords.top + props.lineHeight + props.gap + props.paddingTop}px;
    ` : `
    bottom: ${props.dimensions.height + props.coords.top + props.gap}px;
  `)}

  ${props => (!props.position.forcedRight ? `
    left: ${props.coords.left + props.paddingLeft}px;
  ` : `
    right: 0;
  `)}

  @media(max-width: 768px) {
    max-height: calc(100vh - 180px);
  }
`;
Wrapper.displayName = 'Wrapper';

interface SuggestionProps extends React.HTMLProps<HTMLDivElement> {
  selected: boolean;
}

const Suggestion = styled.div<SuggestionProps>`
  padding: 4px;

  ${props => props.selected && `
    background-color: ${props.theme.colors.mainLight};
  `}

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.actionListItemBG};
  }

  > span.emoji {
    display: flex;
    align-items: center;

    > span:first-child {
      margin-right: 4px;
    }
  }
`;
Suggestion.displayName = 'Suggestion';

interface Position {
    vertical: 'top' | 'bottom';
    forcedRight: boolean;
}

interface Props {
  data: Array<{
    suggest: React.ReactNode;
    text: string;
    mark: React.ReactNode;
    selectedIndex: number;
  }>;
  onSelect: (suggestion: any) => void;
  selectedIndex?: number;
  coords: {
    left: number;
    right: number;
    top: number;
    bottom: number;
  };
  lineHeight: number;
  dimensions: {
    left: number;
    top: number;
    width: number;
    height: number;
    clientWidth: number;
    clientHeight: number;
    element: Element
  };
  gap: number;
}

const Suggestions = ({
  data, onSelect, selectedIndex, coords, lineHeight = 16, dimensions, gap = 8,
}: Props) => {
  const el = useRef<HTMLElement>(null);
  const [position, setPosition] = useState<Position>({ vertical: 'top', forcedRight: false });

  useLayoutEffect(() => {
    if (el.current && dimensions) {
      let forcedRight = false;
      let vertical: Position['vertical'] = 'bottom';

      fastdom.measure(() => {
        if (el.current && (dimensions.width < (el.current.offsetWidth + coords.left))) {
          forcedRight = true;
        }

        const top = (dimensions.element.getBoundingClientRect().top + coords.top);
        const middle = (document.body.offsetHeight / 2);
        if (top > middle) {
          vertical = 'top';
        }

        setPosition({ vertical, forcedRight });
      });
    }
  }, [el, dimensions, coords]);

  const paddingLeft = parseInt(window.getComputedStyle(dimensions?.element, null).getPropertyValue('padding-left').replace('px', ''), 10);
  const paddingTop = parseInt(window.getComputedStyle(dimensions?.element, null).getPropertyValue('padding-left').replace('px', ''), 10);

  return (
    <Wrapper
      ref={el}
      coords={coords}
      dimensions={dimensions}
      lineHeight={lineHeight}
      position={position}
      paddingLeft={paddingLeft}
      paddingTop={paddingTop}
      gap={gap}
    >
      {data.map((suggestion, index) => (
        <Suggestion
          key={`suggestion-${suggestion.text}`}
          // @ts-ignore
          // This is a weird behaviour, test this case before pushing
          onClick={onSelect(suggestion)}
          selected={index === selectedIndex}
        >
          {suggestion.suggest}
        </Suggestion>
      ))}
    </Wrapper>
  );
};

export default Suggestions;
