import { createSelector } from 'reselect';

import * as authSelectors from 'state/auth/selectors';

// Input selectors
export const selectFeed = state => state.feed.feed;

export const selectQueue = state => state.feed.feedQueue;

const selectFeedFullyLoaded = state => state.feed.feedFullyLoaded;

const selectPublications = state => state.feed.publications;

const selectComments = state => state.feed.comments;
//

export const isFeedEmpty = createSelector(
  selectFeed,
  selectFeedFullyLoaded,
  (feed, fullyLoaded) => !feed.length && fullyLoaded,
);

export const publicationsCount = createSelector(
  selectFeed,
  feed => feed.length,
);

export const selectInFeedOrQueuePublicationIds = createSelector(
  selectFeed,
  selectQueue,
  (feed, queue) => [...feed, ...queue],
);

export const selectPublication = createSelector(
  selectPublications,
  (_, id) => id,
  (publications, id) => publications[id],
);

export const selectCommentIds = createSelector(
  selectPublication,
  publication => publication?.comments || [],
);

export const selectComment = createSelector(
  selectComments,
  (_, id) => id,
  (comments, id) => comments[id],
);

export const publications = {
  selectReactionsList: createSelector(
    selectPublication,
    publication => (publication ? publication.reactedByUserIds : []),
  ),

  // Needed for compatibility with other reactionable entities
  selectDislikesList: createSelector(
    () => [],
  ),

  following: createSelector(
    selectPublication,
    publication => (publication ? publication.following : []),
  ),

  commentsBlocked: createSelector(
    selectPublication,
    publication => (publication ? publication.commentsBlocked : false),
  ),

  selectPrivacy: createSelector(
    selectPublication,
    publication => (publication ? publication.privacy : null),
  ),

  selectPrivacyLists: createSelector(
    selectPublication,
    publication => (publication ? publication.privacyLists : []),
  ),

  selectPromoted: createSelector(
    selectPublication,
    publication => (publication ? publication.promoted : null),
  ),

  selectAuthorId: createSelector(
    selectPublication,
    publication => (publication ? publication.author : null),
  ),

  userIsAuthor: createSelector(
    selectPublication,
    authSelectors.selectId,
    (publication, meId) => ((publication && meId) ? publication.author === meId : false),
  ),

  selectCreatedAt: createSelector(
    selectPublication,
    publication => (publication ? publication.createdAt : null),
  ),

  selectType: createSelector(
    selectPublication,
    publication => (publication ? publication.type : null),
  ),

  selectUrl: createSelector(
    selectPublication,
    publication => ((publication && publication.payload) ? publication.payload.url : null),
  ),

  selectMedia: createSelector(
    selectPublication,
    publication => ((publication && publication.payload) ? publication.payload.media : null),
  ),

  selectPoll: createSelector(
    selectPublication,
    publication => publication?.payload?.poll || null,
  ),

  isPoll: createSelector(
    selectPublication,
    publication => !!publication?.payload?.poll,
  ),

  userHasSpanked: createSelector(
    selectPublication,
    authSelectors.selectId,
    (publication, meId) => {
      if (!publication || !meId) return false;
      return publication.reactedByUserIds.includes(meId);
    },
  ),

  selectSpankCount: createSelector(
    selectPublication,
    publication => (publication ? publication.reactedByUserIds.length : false),
  ),

  userHasCommented: createSelector(
    selectPublication,
    authSelectors.selectId,
    (publication, meId) => {
      if (!publication || !meId) return false;
      return publication.commentedByUserIds.includes(meId);
    },
  ),

  selectCommentCount: createSelector(
    selectPublication,
    publication => (publication ? publication.commentedByUserIds.length : false),
  ),

  selectRawContent: createSelector(
    selectPublication,
    (publication) => {
      if (!publication || !publication.payload) return null;
      return publication.payload.rawContent;
    },
  ),

  selectIsPromoted: createSelector(
    selectPublication,
    publication => !!publication?.promoted,
  ),

  selectIsMarkdownAllowed: createSelector(
    selectPublication,
    (publication) => !!publication?.promoted,
  ),

  isBlurred: createSelector(
    selectPublication,
    publication => publication?.isBlurred || false,
  ),

  hasFunding: createSelector(
    selectPublication,
    publication => publication?.funding?.amount > 0 || false,
  ),

  getFundingAmount: createSelector(
    selectPublication,
    publication => publication?.funding?.amount || 0,
  ),

  fundingIsTarget: createSelector(
    selectPublication,
    publication => publication?.funding?.isTarget,
  ),

  isFundedByMe: createSelector(
    selectPublication,
    authSelectors.selectId,
    (publication, myId) => myId && !!publication?.funding?.funders.find(f => f.userId === myId),
  ),

  getFundingCollected: createSelector(
    selectPublication,
    publication => publication?.funding?.collected || 0,
  ),

  getFunders: createSelector(
    selectPublication,
    publication => publication?.funding?.funders || [],
  ),
};

export const comments = {
  selectReactionsList: createSelector(
    selectComment,
    comment => (comment ? comment.reactedByUserIds : []),
  ),

  // Needed for compatibility with other reactionable entities
  selectDislikesList: createSelector(
    () => [],
  ),

  selectMedia: createSelector(
    selectComment,
    comment => (comment ? comment.media : []),
  ),
};

export const getFeedQueueCount = () => state => state.feed.feedQueue.length;

export const getLatestHashtags = () => state => state.feed.latestHashtags;

export const hasLovenseBySpank = createSelector(
  selectPublication,
  publication => publication?.lovense?.spanks || false,
);

export const selectScroll = state => state.feed.scrollTop;
