import { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useOpenClose } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';
import * as channelActions from 'state/channels/actions';

import Modal from 'components/Modal';
import ChannelAvatar from 'components/ChannelAvatar';
import BotName from 'components/BotName';
import BotAvatar from 'components/BotAvatar';

import OptionsModal from './OptionsModal';
import Content from './Content';
import Info from './Info';
import Options from './Options';
import SadesAsk from './SadesAsk';
import Wrapper from '../Wrapper';
import ContentWrapper from '../Content';
import AvatarLink from '../AvatarLink';
import AuthorLink from '../AuthorLink';
import AutoresponderAuthor from '../AutoresponderAuthor';
import Edited from '../Edited';
import { CHANNEL_MESSAGES_TYPES, CM_AUTHOR_TYPES } from '../../../../../constants';

const ChannelBubble = ({
  messageId, showAvatar, channelId, type,
}) => {
  const dispatch = useDispatch();
  const el = useRef(null);

  const isOutgoing = useSelector(state => channelSelectors.isMessageOutgoing(state, messageId));
  const authorId = useSelector(state => channelSelectors.getMessageAuthorId(state, messageId));
  const authorType = useSelector(state => channelSelectors.getMessageAuthorType(state, messageId));
  const isBeingRemoved = useSelector(
    state => channelSelectors.messageIsBeingRemoved(state, messageId),
  );

  const channelAvatar = useSelector(state => channelSelectors.getAvatar(state, channelId));
  const channelName = useSelector(state => channelSelectors.getName(state, channelId));
  const hasSadesAsk = useSelector(state => channelSelectors.hasSadesAsk(state, messageId));

  const [mobileOptionsOpened, openMobileOptions, closeMobileOptions] = useOpenClose();

  useEffect(() => {
    const element = el.current;
    const removeEnded = () => {
      dispatch(channelActions.messageRemoved(messageId));
    };

    if (element && isBeingRemoved) {
      element.addEventListener('transitionend', removeEnded);
    }

    return () => {
      if (element) element.removeEventListener('transitionend', removeEnded);
    };
  }, [isBeingRemoved, messageId, dispatch]);

  return (
    <Wrapper
      outgoing={isOutgoing}
      hasAvatar={showAvatar || authorType !== CM_AUTHOR_TYPES.USER}
      remove={isBeingRemoved}
      ref={el}
    >
      {showAvatar && authorType === CM_AUTHOR_TYPES.USER && <AvatarLink authorId={authorId} />}
      {authorType === CM_AUTHOR_TYPES.AUTORESPONDER && <ChannelAvatar image={channelAvatar} size="32px" />}
      {authorType === CM_AUTHOR_TYPES.BOT && <BotAvatar botId={authorId} size="32px" />}

      <ContentWrapper
        outgoing={isOutgoing}
        hasAvatar={showAvatar || authorType !== CM_AUTHOR_TYPES.USER}
        showMobileOptions={openMobileOptions}
        type={type}
      >
        <div className="message-header">
          {showAvatar && authorType === CM_AUTHOR_TYPES.USER && <AuthorLink authorId={authorId} />}
          {authorType === CM_AUTHOR_TYPES.AUTORESPONDER && (
            <AutoresponderAuthor>{channelName}</AutoresponderAuthor>
          )}
          {authorType === CM_AUTHOR_TYPES.BOT && (
            <AutoresponderAuthor><BotName botId={authorId} /></AutoresponderAuthor>
          )}

          <Edited type="channel" messageId={messageId} />

          <Options channelId={channelId} messageId={messageId} />
        </div>

        <Content messageId={messageId} channelId={channelId} />
        {hasSadesAsk && <SadesAsk messageId={messageId} />}
        <Info messageId={messageId} type={type} />
      </ContentWrapper>

      {mobileOptionsOpened && (
        <Modal onClose={closeMobileOptions}>
          <OptionsModal
            channelId={channelId}
            messageId={messageId}
            close={closeMobileOptions}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

ChannelBubble.propTypes = {
  messageId: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  showAvatar: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(CHANNEL_MESSAGES_TYPES)).isRequired,
};

ChannelBubble.defaultProps = {
};

export default memo(ChannelBubble);
