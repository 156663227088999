export default {
  variants: {
    outline: {
      border: '2px solid',
      borderColor: 'gray.400',
      _hover: {
        borderColor: 'black',
      },
      _focus: {
        borderColor: 'black',
        boxShadow: '0 0 0 1px #EEE',
      },
    },
  },
};
