import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';
import BotDisplayName from 'components/BotDisplayName';
import BotAvatar from 'components/BotAvatar';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';
import { BANK_BOX_TYPES } from '../../../../constants';

const NewTransaction = ({
  timestamp, from, amount, read,
}) => {
  const { t } = useTranslation(locales);

  const Avatar = () => {
    if (from.type === 'BOT') return <BotAvatar botId={from.id} size="40px" />;
    if (from.type === 'CENTRAL_BANK') return <img src="/images/isologo.png" width="40px" height="40px" alt="Mazmo" style={{ borderRadius: '100%' }} />;
    return <UserAvatar userId={from.id} size="40px" showOnline={false} />;
  };

  const DisplayName = () => {
    if (from.type === 'BOT') return <BotDisplayName botId={from.id} />;
    if (from.type === 'CENTRAL_BANK') return 'Mazmo';
    return <UserDisplayName userId={from.id} />;
  };

  return (
    <AlertContainer timestamp={timestamp} image={<Avatar />} to="/user/sades" read={read}>
      <strong><DisplayName /></strong>
      {' '}
      {t('transferred you {{amount}} sades', { amount: amount.toFixed(2) })}
    </AlertContainer>
  );
};

NewTransaction.propTypes = {
  read: PropTypes.bool.isRequired,
  from: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(BANK_BOX_TYPES)).isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default NewTransaction;
