import styled from 'styled-components';

import colors from 'utils/css/colors';

const Typing = styled.div`
  font-size: 14px;
  color: #666;

  @media(max-width: 767px) {
    color: ${colors.borderRed};
  }
`;

export default Typing;
