import { schema } from 'normalizr';

import user from 'state/users/schema';
import community from 'state/communities/schema';

const ban = new schema.Entity('bans', {
  community,
  user,
}, {
  idAttribute: '_id',
});

export default ban;
