import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const normalPath = 'M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1Z';
const outlinePath = 'M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18L5,6.3V11.22C5,15.54 8.25,20 12,21Z';

const Shield = memo(({ color, outline, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path fill={color} d={outline ? outlinePath : normalPath} />
  </svg>
));

Shield.propTypes = IconType;
Shield.defaultProps = IconDefaults;

export default Shield;
