import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import isMobile from 'utils/isMobile';
import { useTranslation } from 'hooks';
import * as threadSelectors from 'state/threads/selectors';
import * as authSelectors from 'state/auth/selectors';
import * as membershipSelectors from 'state/memberships/selectors';
import * as threadActions from 'state/threads/actions';
import * as appActions from 'state/app/actions';

import { MoreActions } from 'components/Icons';
import Menu, { Item } from 'components/Menu';
import SpankListModal from 'components/SpankListModal';

import RemoveModal from './RemoveModal';
import EditModal from './EditModal';
import ActionButton from './Event/AssistButton/Button';
import locales from './i18n';

const OptionsWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  padding: 2px;
  text-align: center;
  border-radius: 100%;
  line-height: 24px;
  cursor: pointer;

  ${props => props.pressed && `
    background: rgba(0, 0, 0, .1);
  `}

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${colors.grey} !important;
    }
  }
`;
OptionsWrapper.displayName = 'OptionsWrapper';

const Options = ({ threadId, eventHeaderVisible }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const authorId = useSelector(threadSelectors.getAuthorId(threadId));
  const userId = useSelector(authSelectors.selectUserId);
  const communityId = useSelector(threadSelectors.getCommunityId(threadId));
  const userIsMod = useSelector(state => membershipSelectors.userIsModOf(state, communityId));
  const isPinned = useSelector(threadSelectors.isPinned(threadId));
  const isClosed = useSelector(threadSelectors.isClosed(threadId));
  const isDeleted = useSelector(threadSelectors.isDeleted(threadId));
  const eventId = useSelector(threadSelectors.getEventId(threadId));

  const [showingContextMenu, setShowingContextMenu] = useState(false);
  const openContextMenu = useCallback(() => { setShowingContextMenu(true); }, []);
  const closeContextMenu = useCallback(() => { setShowingContextMenu(false); }, []);

  const [showingSpanksModal, setShowingSpanksModal] = useState(false);
  const openSpanksModal = useCallback(() => { setShowingSpanksModal(true); }, []);
  const closeSpanksModal = useCallback(() => { setShowingSpanksModal(false); }, []);

  const [pinning, setPinning] = useState(false);
  const togglePin = useCallback(async () => {
    try {
      setPinning(true);
      if (isPinned) {
        dispatch(threadActions.unpin(threadId));
        await dispatch(appActions.addToast(t('Thread unpinned')));
      } else {
        dispatch(threadActions.pin(threadId));
        await dispatch(appActions.addToast(t('Thread pinned')));
      }
      setPinning(false);
    } catch (error) {
      dispatch(appActions.addError(error));
      setPinning(false);
    }
  }, [dispatch, isPinned, t, threadId]);

  const [closing, setClosing] = useState(false);
  const toggleClose = useCallback(async () => {
    try {
      setClosing(true);
      if (isClosed) {
        dispatch(threadActions.reopen(threadId));
        await dispatch(appActions.addToast(t('Thread reopened')));
      } else {
        dispatch(threadActions.close(threadId));
        await dispatch(appActions.addToast(t('Thread closed')));
      }
      setClosing(false);
    } catch (error) {
      dispatch(appActions.addError(error));
      setClosing(false);
    }
  }, [dispatch, isClosed, t, threadId]);

  const [showingRemoveModal, setShowingRemoveModal] = useState(false);
  const openRemoveModal = useCallback(() => { setShowingRemoveModal(true); }, []);
  const closeRemoveModal = useCallback(() => { setShowingRemoveModal(false); }, []);

  const [showingEditModal, setShowingEditModal] = useState(false);
  const openEditModal = useCallback(() => { setShowingEditModal(true); }, []);
  const closeEditModal = useCallback(() => { setShowingEditModal(false); }, []);

  if (!userId) return null;

  return (
    <>
      {!isMobile && !eventHeaderVisible && eventId && <ActionButton eventId={eventId} />}
      <OptionsWrapper onClick={openContextMenu} pressed={showingContextMenu}>
        <MoreActions />
        <Menu width="250px" open={showingContextMenu} onClose={closeContextMenu} orientation="left" maxHeight="100%">
          <Item onClick={openSpanksModal}>{t('See reactions')}</Item>
          {userIsMod && (
            <Item onClick={!pinning ? togglePin : null}>
              {isPinned ? t('Unpin') : t('Pin')}
            </Item>
          )}
          {userIsMod && (
            <Item onClick={!closing ? toggleClose : null}>
              {isClosed ? t('Re open') : t('Close')}
            </Item>
          )}

          {(userIsMod || authorId === userId) && (
            <>
              <Item onClick={openEditModal}>{t('global:Edit')}</Item>
              {!isDeleted && <Item onClick={openRemoveModal} danger>{t('global:Remove')}</Item>}
            </>
          )}
        </Menu>
      </OptionsWrapper>

      {/* Modals */}
      {showingSpanksModal && (
        <SpankListModal close={closeSpanksModal} entityId={threadId} type="thread" />
      )}

      {showingEditModal && (
        <EditModal close={closeEditModal} threadId={threadId} />
      )}

      {showingRemoveModal && (
        <RemoveModal close={closeRemoveModal} threadId={threadId} />
      )}
    </>
  );
};

Options.propTypes = {
  threadId: PropTypes.string.isRequired,
  eventHeaderVisible: PropTypes.bool.isRequired,
};

Options.defaultProps = {
};

export default Options;
