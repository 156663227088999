import { useTranslation, useAuth } from 'hooks';

import { Separator, Title } from 'components/Menu';
import UserAvatar from 'components/UserAvatar';

import locales from '../../i18n';

const BackToUser = () => {
  const { t } = useTranslation(locales);

  const { switchToUser } = useAuth();

  const userId = localStorage.getItem('userId');

  if (!userId) return null;

  return (
    <>
      <Title>{t('Back to user')}</Title>

      <div
        onClick={() => switchToUser(parseInt(userId, 10))}
        role="button"
        tabIndex={-1}
        onKeyDown={() => switchToUser(parseInt(userId, 10))}
      >
        <UserAvatar userId={parseInt(userId, 10)} showOnline={false} />
      </div>

      <Separator />
    </>
  );
};

BackToUser.propTypes = {
};

BackToUser.defaultProps = {
};

export default BackToUser;
