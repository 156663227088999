import PropTypes from 'prop-types';

import { CHANNEL_PRIVACIES } from '../../constants';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  privacy: PropTypes.oneOf(Object.values(CHANNEL_PRIVACIES)).isRequired,
});
