import { useState } from 'react';
import { Icon, IconButton } from '@chakra-ui/react';
import { MessageSquare } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as messengerSelectors from 'state/messengers/selectors';
import * as messengerActions from 'state/messengers/actions';
import * as appActions from 'state/app/actions';

interface Props {
  userId: number;
}

const ChatButton: React.FC<Props> = ({ userId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // @ts-ignore
  const messengerId = useSelector(state => messengerSelectors.selectByUserId(state, userId));

  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    if (messengerId) {
      history.push(`/chat/messengers/${messengerId}`);
    } else {
      setIsLoading(true);
      const messenger: any = await dispatch(messengerActions.create([userId]));
      history.push(`/chat/messengers/${messenger.id}`);
    }
    dispatch(appActions.setMiniprofile(null));
  };

  return (
    <IconButton
      aria-label="Chat"
      icon={<Icon as={MessageSquare} transform="scale(1.5)" />}
      onClick={onClick}
      isLoading={isLoading}
    />
  );
};

export default ChatButton;
