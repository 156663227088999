import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';

import CircleMultiple from 'components/Icons/CircleMultiple';

import ItemDetail from './ItemDetail';
import locales from '../../i18n';

const Sades = () => {
  const { t } = useTranslation(locales);

  const sades = useSelector(authSelectors.getSades);

  return (
    <ItemDetail>
      <div>
        <CircleMultiple />
        <Link to="/user/sades">{t('global:Sades')}</Link>
      </div>
      <div>{`${sades.toFixed(2)} §`}</div>
    </ItemDetail>
  );
};

Sades.propTypes = {
};

Sades.defaultProps = {
};

export default Sades;
