import { memo } from 'react';
import styled, { keyframes } from 'styled-components';

import colors from 'utils/css/colors';

const Item = styled.li`
  background-color: transparent;
  display: flex;
  padding: 16px;
  border-bottom: 1px solid ${colors.borderRed};
`;

const animation = keyframes`
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
`;

const Background = styled.div`
  animation: ${animation} 1.25s forwards infinite linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  position: relative;
`;

const Avatar = styled(Background)`
  background-color: red;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin: 0 16px 0 0;
  flex-shrink: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const Title = styled(Background)`
  height: 12px;
  width: 100%;
  background-color: red;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ThreadLoader = memo(() => (
  <ul>
    <Item>
      <Avatar />
      <Content>
        <Title />
        <Title />
      </Content>
    </Item>
    <Item>
      <Avatar />
      <Content>
        <Title />
        <Title />
      </Content>
    </Item>
    <Item>
      <Avatar />
      <Content>
        <Title />
        <Title />
      </Content>
    </Item>
    <Item>
      <Avatar />
      <Content>
        <Title />
        <Title />
      </Content>
    </Item>
    <Item>
      <Avatar />
      <Content>
        <Title />
        <Title />
      </Content>
    </Item>
    <Item>
      <Avatar />
      <Content>
        <Title />
        <Title />
      </Content>
    </Item>
  </ul>
));

export default ThreadLoader;
