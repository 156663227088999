import { useEffect, useState } from 'react';
import {
  Text, Box, Progress, Stack,
  HStack,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import api from 'state/api';
import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';

import ShareButton from 'components/ShareButton';

import ErrorState from 'components/ErrorState';
import locales from './i18n';

const archetypeKeys = [
  'sadist',
  'masochist',
  'dominant',
  'submissive',
  'non_monogamist',
  'degrader',
  'degradee',
  'daddy_mommy',
  'little',
  'bondageplayer',
  'exhibitionist',
  'voyeur',
  'roleplayer',
  'fetishist',
];

type ArchetypeKeys = (typeof archetypeKeys)[number];

type ArchetypeScores = {
  [K in ArchetypeKeys]: number;
};

const ContentUser: React.FC = () => {
  const { t } = useTranslation(locales);
  const history = useHistory();
  const username = useSelector(authSelectors.selectUsername);

  const [scores, setScores] = useState<ArchetypeScores>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    (async () => {
      try {
        if (!scores && !error) {
          const { data } = await api.req!.get<ArchetypeScores>('/users/kinkytest');
          setScores(data);
        }
      } catch (e: any) {
        if (e?.response?.data?.error === 'Not enough progress') history.push('/user/checklist');
        else setError(e?.response?.data?.error || e.message || t('An unexpected error occurred'));
      }
    })();
  }, [t, scores, error, history]);

  const sortedArchetypes = archetypeKeys.sort((a, b) => {
    if (scores && scores[a] > scores[b]) return -1;
    if (scores && scores[a] < scores[b]) return 1;
    return 0;
  });

  const imageneratorParams = new URLSearchParams();
  imageneratorParams.append('username', username);
  imageneratorParams.append('width', '540');
  imageneratorParams.append('height', '960');
  sortedArchetypes.forEach((key) => {
    imageneratorParams.append(key, (scores && scores[key].toString()) || '0');
  });
  const url = `https://imagenerator.mazmo.net/kinkytest?${imageneratorParams.toString()}`;

  const params = new URLSearchParams();
  imageneratorParams.set('width', '430');
  imageneratorParams.set('height', '600');
  params.append('url', `https://imagenerator.mazmo.net/kinkytest?${imageneratorParams.toString()}`);
  params.append('content', `:bar_chart: Resultados de mi #KinkyTest\n\n${sortedArchetypes.map((archetype) => `${t(`ARCHETYPE.${archetype}`)}: ${(scores && scores[archetype].toString()) || '0'}%`).join('\n')}`);

  if (error) return <ErrorState message={error} reload />;

  return (
    <Box>
      <Stack spacing={5} mt={12}>
        {sortedArchetypes.map((key) => (
          <Stack key={key} spacing={1}>
            <HStack justifyContent="space-between">
              <Text>{t(`ARCHETYPE.${key}`)}</Text>
              <Text color="gray.400" fontSize="sm">{scores && `${scores[key]}%`}</Text>
            </HStack>
            <Progress
              height="16px"
              value={scores && scores[key]}
              max={100}
              isIndeterminate={!scores}
              rounded="md"
            />
          </Stack>
        ))}
      </Stack>

      <ButtonGroup size="lg" mt={8}>
        <Link to={`/new/url?${params.toString()}`}><Button variant="primary">{t('Share in Mazmo')}</Button></Link>
        <ShareButton imgUrl={url} />
      </ButtonGroup>
    </Box>
  );
};

export default ContentUser;
