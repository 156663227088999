import { memo, useCallback, useRef } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Box, chakra } from '@chakra-ui/react';

import * as authSelectors from 'state/auth/selectors';
import * as appActions from 'state/app/actions';

import Information from 'components/Icons/Information';
import BoxDivider from 'components/BoxDivider';

import zones from './zones.json';
import BoxWrapper from './BoxWrapper';
import Info from './Info';
import { GUEST_MAX_THREADS } from '../../constants';

interface Props {
  id: string;
  divider?: 'bottom' | 'top';
  data?: {
    index: number;
  };
}

interface Zone {
  code: string;
  width: number;
  height: number;
}

const Ad: React.FC<Props> = ({ id, divider = 'bottom' }) => {
  const dispatch = useDispatch();
  const elementRef = useRef(null);

  const userIsLoggedIn = useSelector(authSelectors.loggedIn);
  const userHasEmailConfirmed = useSelector(authSelectors.selectIsEmailConfirmed);
  const country = useSelector(authSelectors.selectCountry);
  const niches = useSelector(authSelectors.selectNiches, shallowEqual);

  const piece = (zones as Record<string, Zone>)[id];

  const onInfoClick = useCallback(() => {
    dispatch(appActions.openAdInformation());
  }, [dispatch]);

  if (!piece) return null;

  let usertype = 'Unlogg';
  if (userIsLoggedIn) usertype = 'UnConf';
  if (userHasEmailConfirmed) usertype = 'BasicR';
  if (
    !userIsLoggedIn
    && parseInt(Cookies.get('threadsRead') ?? '0', 10) >= GUEST_MAX_THREADS
    && !/bot|crawler|spider|crawling/i.test(navigator.userAgent)
  ) usertype = 'Lurker';

  const code = piece.code
    .replace(/INSERT_RANDOM_NUMBER_HERE/g, (Math.floor(Math.random() * 1000) + 1).toString())
    .replace(/R_USERTYPE/g, usertype)
    .replace(/R_COUNTRY/g, country?.isoCode ?? '')
    .replace(/R_NICHES/g, niches.join(','));

  return (
    <BoxWrapper
      topDivider={divider === 'top'}
      ref={elementRef}
      width={piece.width}
      height={piece.height}
    >
      {divider === 'top' && <BoxDivider top />}

      {/* eslint-disable-next-line react/no-danger */}
      <Box dangerouslySetInnerHTML={{ __html: code }} display="flex" justifyContent="space-around" />

      <Info onClick={onInfoClick}>
        Ad
        <Information color="#666" />
      </Info>

      {divider === 'bottom' && <BoxDivider />}
    </BoxWrapper>
  );
};

const isSameAd = (prevState: Props, nextState: Props) => {
  if (prevState.id !== nextState.id) return false;
  if (prevState.data && nextState.data && prevState.data.index !== nextState.data.index) {
    return false;
  }
  return true;
};

export default memo(chakra(Ad, { shouldForwardProp: (prop) => ['divider', 'id'].includes(prop) }), isSameAd);
