import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;

  button, div[role="button"] {
    outline: none;
  }

  .emoji-mart-title-label {
    display: none;
  }

  .emoji-mart-preview {
    height: 42px;
  }

  .emoji-mart-search-icon svg {
    width: 13px;
    height: 13px;
  }

  @media(max-width: 768px) {
    .emoji-mart-scroll {
      max-height: 150px;
    }

    .emoji-mart {
      width: 100% !important;
      margin: 0 !important;
      left: 0;
    }
  }
`;
Wrapper.displayName = 'ComposerEmojiPickerWrapper';

export default Wrapper;
