import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';
import * as appActions from 'state/app/actions';

import { useHotKeys } from 'hooks';

import Modal from 'components/Modal';

const Routines = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(authSelectors.loggedIn);

  const [showingPity, setShowinngPity] = useState(false);
  const showPity = () => setShowinngPity(true);
  const hidePity = () => setShowinngPity(false);

  const [showingFatality, setShowinngFatality] = useState(false);
  const showFatality = () => setShowinngFatality(true);
  const hideFatality = () => setShowinngFatality(false);

  useHotKeys(
    [{ key: '9' }, { key: '1' }, { key: '2' }, { key: '1' }, { key: '8' }],
    showPity,
  );
  useHotKeys(
    [{ key: 'ArrowRight' }, { key: 'ArrowRight' }, { key: 'ArrowDown' }, { key: 'ArrowUp' }],
    showFatality,
  );

  useEffect(() => {
    if (loggedIn) {
      dispatch(appActions.fetchResources());
    }
  }, [dispatch, loggedIn]);

  if (showingPity) {
    return (
      <Modal
        title="Y va el tercero y va el tercero y va el tercero"
        onClose={hidePity}
      >
        <iframe
          title="Pity"
          width="540"
          height="315"
          src="https://www.youtube.com/embed/mikBKyvQMw0?start=28&autoplay=1"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      </Modal>
    );
  }

  if (showingFatality) {
    return (
      <Modal
        title="Fatality"
        onClose={hideFatality}
      >
        <iframe
          title="Pity"
          width="540"
          height="315"
          src="https://www.youtube.com/embed/_b5-QBKwq_0?start=165&autoplay=1"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      </Modal>
    );
  }

  return null;
};

export default Routines;
