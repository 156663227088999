import { normalize } from 'normalizr';
import ReactGA from 'react-ga4';

import Api from 'state/api';
import eventSchema from 'state/events/schema';

import {
  LOAD,
  EVENT_RSVP,
  EVENT_UNRSVP,
  ASSISTED,
  UNASSISTED,
  ADD_BAN,
  REMOVE_BAN,
  LOAD_INVITES,
  ADD_INVITE,
  REMOVE_INVITE,
  ENROLLED,
} from './constants';

export const load = data => (dispatch) => {
  dispatch({ type: LOAD, data });
};

export const fetch = eventId => async (dispatch) => {
  const { data } = await Api.req.get(`/events/${eventId}`);
  const normalizedData = normalize(data, eventSchema);

  dispatch(load(normalizedData.entities.events));
};

export const fetchAll = () => async (dispatch) => {
  const { data } = await Api.req.get('/events');
  const normalizedData = normalize(data, [eventSchema]);

  dispatch(load(normalizedData.entities.events));
};

export const create = params => async (dispatch) => {
  const { data } = await Api.req.post('/events', params);
  const normalizedData = normalize(data, eventSchema);

  ReactGA.event({
    category: 'Events',
    action: 'Created',
    label: data.id,
  });

  dispatch(load(normalizedData.entities.events));

  return data;
};

export const remove = (eventId) => async () => {
  await Api.req.delete(`/events/${eventId}`);
};

export const uploadFlyer = blob => async () => {
  const formData = new FormData();
  formData.append('image', blob, blob.name);

  const { data } = await Api.req.post('/events/upload', formData, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
    timeout: 30000,
  });

  return data;
};

export const rsvp = eventId => async (dispatch, getState) => {
  const { me } = getState().auth;
  if (!me) throw new Error('NOT_LOGGED_IN');

  const { emailConfirmed } = getState().auth.me;
  if (!emailConfirmed) throw new Error('EMAIL_UNCONFIRMED');

  const { data } = await Api.req.post(`/events/${eventId}/rsvps`);
  const { rsvps } = getState().events.data[eventId];
  data.event.rsvps = [
    { userId: me.id },
    ...rsvps,
  ];

  ReactGA.event({
    category: 'Events',
    action: 'RSVP Created',
    label: eventId,
  });


  dispatch({ type: LOAD, data: { [eventId]: data.event } });
};

export const unrsvp = eventId => async (dispatch, getState) => {
  const { me } = getState().auth;
  if (!me) throw new Error('NOT_LOGGED_IN');

  const { data } = await Api.req.delete(`/events/${eventId}/rsvps`);
  const { rsvps } = getState().events.data[eventId];
  data.rsvps = rsvps.filter(r => r.userId !== me.id);

  ReactGA.event({
    category: 'Events',
    action: 'RSVP Removed',
    label: eventId,
  });

  dispatch({ type: LOAD, data: { [eventId]: data } });
};

export const enroll = (eventId) => async (dispatch) => {
  const { data: { event, ...enrollment } } = await Api.req.post(`/events/${eventId}/enrollments`);
  dispatch({ type: ENROLLED, eventId, data: enrollment });

  ReactGA.event({
    category: 'Events',
    action: 'Enrollment',
    label: eventId,
  });
};

export const regenerateQR = eventId => async (dispatch) => {
  const { data } = await Api.req.put(`/events/${eventId}/qr`);
  const normalizedData = normalize(data, eventSchema);

  dispatch(load(normalizedData.entities.events));
};

export const addBan = (eventId, userId) => async (dispatch) => {
  await Api.req.post(`/events/${eventId}/bans`, { userId });
  dispatch({ type: ADD_BAN, userId, eventId });
};

export const removeBan = (eventId, userId) => async (dispatch) => {
  await Api.req.delete(`/events/${eventId}/bans/${userId}`);
  dispatch({ type: REMOVE_BAN, userId, eventId });
};

export const loadInvites = eventId => async (dispatch) => {
  const { data } = await Api.req.get(`/events/${eventId}/invites`);
  dispatch({ type: LOAD_INVITES, eventId, invites: data });
};

export const addInvite = (eventId, userId) => async (dispatch) => {
  const { data } = await Api.req.post(`/events/${eventId}/invites`, { userId });
  dispatch({ type: ADD_INVITE, eventId, invite: data });
};

export const removeInvite = (eventId, inviteId) => async (dispatch) => {
  await Api.req.delete(`/events/${eventId}/invites/${inviteId}`);
  dispatch({ type: REMOVE_INVITE, inviteId, eventId });
};

export const adminAssist = (eventId, userId) => async (dispatch) => {
  await Api.req.put(`/events/${eventId}/rsvps/assist`, null, { params: { userId } });
  dispatch({ type: ASSISTED, userId, eventId });
};

export const adminUnassist = (eventId, userId) => async (dispatch) => {
  await Api.req.put(`/events/${eventId}/rsvps/unassist`, null, { params: { userId } });
  dispatch({ type: UNASSISTED, userId, eventId });
};

export const selfAssist = (eventId, key) => async () => {
  await Api.req.put(`/events/${eventId}/rsvps/assist`, null, { params: { key } });
};

export const updateFlyer = (eventId, cover) => async (dispatch) => {
  const { data } = await Api.req.put(`/events/${eventId}/cover`, { cover });
  dispatch({ type: LOAD, data: { [eventId]: data } });
};

export const update = (eventId, payload) => async (dispatch) => {
  const { data } = await Api.req.put(`/events/${eventId}`, payload);
  dispatch({ type: LOAD, data: { [eventId]: data } });
};

export const handleRsvpCreated = ({ eventId, userId }) => (dispatch, getState) => {
  const { me } = getState().auth;

  const payload = {
    type: EVENT_RSVP,
    eventId,
    userId,
  };

  if (me && me.id === userId) {
    payload.rsvpd = true;
  }

  dispatch(payload);
};

export const handleRsvpRemoved = ({ eventId, userId }) => (dispatch, getState) => {
  const { me } = getState().auth;
  if (!me || me.id !== userId) {
    dispatch({
      type: EVENT_UNRSVP,
      eventId,
      userId,
    });
  }
};

export const listByUserId = userId => async () => {
  const { data } = await Api.req.get('/events', { params: { userId } });
  return data;
};

export const capturePaypalOrder = (eventId) => async (dispatch) => {
  const { data } = await Api.req.post(`/events/${eventId}/paypal/capture`);

  const normalizedData = normalize(data, eventSchema);

  dispatch(load(normalizedData.entities.events));
  return data;
};
