const main = {
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.08)',
};

export const breakpoints = {
  desktop: '992px',
  tablet: '768px',
};

export default main;
