import { memo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as appSelectors from 'state/app/selectors';

import HomeLink from './HomeLink';
import CommunitiesLink from './CommunitiesLink';
import ChatLink from './ChatLink';
import EventsLink from './EventsLink';

const NavLeftComponent = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;

  @media(min-width: 768px) {
    flex: 1;
  }
`;

const NavLeft = () => {
  const active = useSelector(appSelectors.selectIsNavLeftActive);

  return (
    <NavLeftComponent active={active}>
      <HomeLink />
      <CommunitiesLink />
      <ChatLink />
      <EventsLink />
    </NavLeftComponent>
  );
};

NavLeft.propTypes = {
};

NavLeft.defaultProps = {
};

export default memo(NavLeft);
