import {
  useState, useCallback, useEffect, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslation, useOpenClose } from 'hooks';
import * as bankActions from 'state/bank/actions';
import * as eventSelectors from 'state/events/selectors';

import Processors from 'components/PurchaseSadesModal/UI/Processors';
import mercadopago from 'components/PurchaseSadesModal/img/mercadopago.png';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Loading from 'components/Loading';

import locales from '../../i18n';
import { PAYMENTS_INTEGRATION_URL } from '../../../../../constants';

const ButtonBuy = ({
  eventId, startListening, stopListening, size,
}) => {
  const { t } = useTranslation(locales);

  const paymentContainer = useRef(null);
  const [order, setOrder] = useState(null);
  const [buyModalOpened, openBuyModal, closeBuyModal] = useOpenClose(false);
  const soldOut = useSelector(state => eventSelectors.selectIsEventRsvpFull(state, eventId));

  useEffect(() => {
    if (paymentContainer.current && order && order !== true) {
      const script = document.createElement('script');
      script.src = PAYMENTS_INTEGRATION_URL;
      script.setAttribute('data-preference-id', order.mercadopago.preference.id);
      script.onload = () => {
        const button = document.querySelector('.mercadopago-button');
        if (button) {
          button.click();
          closeBuyModal();
        }
      };
      paymentContainer.current.appendChild(script);
    }
  }, [order, paymentContainer, closeBuyModal]);

  const createOrder = useCallback(async () => {
    try {
      setOrder(true);
      // const couponCode = coupon ? coupon.code : undefined;
      const couponCode = undefined;
      const data = await bankActions.createOrder('EARLY_EVENT_TICKET', { eventId, price: { ars: 100 } }, 'MERCADOPAGO', couponCode);
      setOrder(data);
    } catch (error) {
      setOrder(null);
    }
  }, [eventId]);

  const onClick = useCallback(() => {
    startListening();
    openBuyModal();
  }, [startListening, openBuyModal]);

  const onCancel = useCallback(() => {
    stopListening();
    closeBuyModal();
  }, [stopListening, closeBuyModal]);

  return (
    <>
      <Button disabled={soldOut} onClick={onClick} className="event-buy-button" size={size}>
        {t(soldOut ? 'Sold Out' : 'Buy Ticket')}
      </Button>

      {/* Modals */}
      {buyModalOpened && (
        <Modal
          title={t('Buy Ticket')}
          onCancel={onCancel}
        >
          {order === true && <Loading />}
          {!order && (
            <Processors>
              <div onClick={createOrder} role="button" tabIndex={-1} onKeyDown={createOrder}>
                <img src={mercadopago} alt="Mercado Pago" />
                <div className="flag">{t('Only argentine peso')}</div>
              </div>
            </Processors>
          )}
          {!!order && <div ref={paymentContainer} />}
        </Modal>
      )}
    </>
  );
};

ButtonBuy.propTypes = {
  eventId: PropTypes.string.isRequired,
  startListening: PropTypes.func.isRequired,
  stopListening: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['normal', 'big']).isRequired,
};

ButtonBuy.defaultProps = {
};

export default ButtonBuy;
