import { extendTheme } from '@chakra-ui/react';

import Alert from './components/Alert';
import Button from './components/Button';
import Checkbox from './components/Checkbox';
import Form from './components/Form';
import FormLabel from './components/FormLabel';
import Heading from './components/Heading';
import Input from './components/Input';
import Modal from './components/Modal';
import Progress from './components/Progress';
import Textarea from './components/Textarea';
import Select from './components/Select';
import Switch from './components/Switch';
import Tabs from './components/Tabs';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme(
  {
    config,
    semanticTokens: {
      colors: {
        text: {
          default: '#000000',
          muted: '#F1786C',
        },
      },
    },
    colors: {
      gray: {
        50: '#FBFBFB',
        100: '#F8F8F8',
        200: '#EAEAEA',
        300: '#CACACA',
        400: '#A8A8A8',
        500: '#6F6666',
        600: '#514C4C',
        700: '#494343',
        800: '#323232',
        900: '#000000',
      },
      brand: {
        50: '#FCE3E0',
        100: '#F5F5F5',
        // 200: '#f6a39a', // should be this one
        200: '#F5EFEF',
        300: '#F38D83',
        400: '#F1786C',
        500: '#ED4D3D',
        600: '#E22815',
        700: '#CB2413',
        800: '#B31F11',
        900: '#85170C',
      },
      gold: {
        50: '#FAF4EB',
        100: '#F1E1C6',
        200: '#E8CEA1',
        300: '#DFBB7C',
        400: '#D6A757',
        500: '#CD9432',
        600: '#A47728',
        700: '#7B591E',
        800: '#523B14',
        900: '#291E0A',
      },
    },
    styles: {
      global: {
        body: {
          lineHeight: 'normal',
        },
        'img, video': {
          maxWidth: 'none',
          height: 'inherit',
        },
      },
    },
    components: {
      Alert,
      Button,
      Checkbox,
      Form,
      FormLabel,
      Heading,
      Input,
      Modal,
      Progress,
      Textarea,
      Select,
      Switch,
      Tabs,
    },
  },
);

export default theme;
