import { Link } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import isMobile from 'utils/isMobile';
import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';
import * as messengerSelectors from 'state/messengers/selectors';
import * as appSelectors from 'state/app/selectors';

import { Forum } from 'components/Icons';
import Badge from 'components/Badge';

import NavItem from './Item';
import locales from '../i18n';

const ChatLink = () => {
  const { t } = useTranslation(locales);

  const channelsUnread = useSelector(channelSelectors.haveUnread);
  const messengersUnread = useSelector(messengerSelectors.totalUnread);
  const locationHistory = useSelector(state => appSelectors.selectUrlHistory(state, /\/chat\/(messengers|channels)\/(.*)/g), shallowEqual);

  const to = (locationHistory.length > 0 && !isMobile) ? locationHistory[0] : '/chat';

  return (
    <NavItem>
      <Link to={to}>
        <Forum fill="white" outline />
        <span>{t('global:Chat')}</span>
        {messengersUnread > 0 && <Badge value={messengersUnread} />}
        {messengersUnread === 0 && channelsUnread && <Badge />}
      </Link>
    </NavItem>
  );
};

ChatLink.propTypes = {
};

export default ChatLink;
