import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import * as messengerSelectors from 'state/messengers/selectors';
import * as authSelectors from 'state/auth/selectors';
import * as userActions from 'state/users/actions';

import Messages from '../Content/Messages';
import EditMessage from '../Content/ComposeMessage/EditMessage';
import Blocked from './Blocked';
import ComposeMessage from '../Content/ComposeMessage';
import RequestChat from './RequestChat';
import WaitingForApproval from './WaitingForApproval';
import ResolveRequest from './ResolveRequest';

const Wrapper = styled.div`
  flex: 1;
  align-self: center;
  display: flex;
  overflow: auto;
`;

const ChatArea = ({ id, participant }) => {
  const dispatch = useDispatch();

  const needsRequest = useSelector(state => messengerSelectors.needsRequest(state, id));
  const isWaitingForApproval = useSelector(
    state => messengerSelectors.isWaitingForApproval(state, id),
  );
  const messengerIsBlocked = useSelector(state => messengerSelectors.isBlocked(state, id));
  const userIsBlocked = useSelector(state => authSelectors.selectIsBlocked(state, participant.id));
  const isRejected = useSelector(state => messengerSelectors.isRejected(state, id));
  const needsResolveRequest = useSelector(
    state => messengerSelectors.needsResolveRequest(state, id),
  );
  const userId = useSelector(state => messengerSelectors.getUserId(state, id));
  const isEditing = useSelector(state => messengerSelectors.isEditing(state, id));

  const isBlocked = messengerIsBlocked || userIsBlocked;

  if (!isBlocked) {
    if (needsRequest) {
      return <Wrapper><RequestChat id={id} participant={participant} /></Wrapper>;
    }

    if (isWaitingForApproval) {
      return (
        <Wrapper><WaitingForApproval messengerId={id} /></Wrapper>
      );
    }
  }


  if (needsResolveRequest) {
    const requester = dispatch(userActions.fetchData(userId));

    return (
      <Wrapper>
        <ResolveRequest
          id={id}
          requester={requester}
          rejected={isRejected}
          isBlocked={isBlocked}
        />
      </Wrapper>
    );
  }

  return (
    <>
      <Messages key={`chat-messages-${id}`} type="messenger" id={id} />
      <div>
        {!isBlocked ? (
          <>
            {isEditing
              ? <EditMessage key={`compose-messenger-edit-${id}`} type="messenger" id={id} />
              : <ComposeMessage key={`compose-messenger-${id}`} type="messenger" id={id} />
            }
          </>
        ) : (
          <Blocked />
        )}
      </div>
    </>
  );
};

ChatArea.propTypes = {
  id: PropTypes.string.isRequired,
  participant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pronoun: PropTypes.string.isRequired,
  }).isRequired,
};

const equality = (prevProps, nextProps) => (
  prevProps.id === nextProps.id
  && prevProps.participant.id === nextProps.participant.id
  && prevProps.participant.loading === nextProps.participant.loading
);
export default memo(ChatArea, equality);
