import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Box, Image, Text, VStack,
} from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import * as bankSelectors from 'state/bank/selectors';

import * as images from './img';
import { AWARDS } from './constants';
import locales from '../i18n';

const Award = ({ name, onClick }) => {
  const { t } = useTranslation(locales);

  const price = useSelector(state => bankSelectors.selectBuyableItemPrice(state, `AWARD.${name}`));

  return (
    <VStack key={`award-${name}`} onClick={onClick} cursor="pointer" rounded="md" p={4} _hover={{ bgColor: 'brand.200' }}>
      <Image src={images[name]} alt={name} w="64px" h="64px" />
      <Box textAlign="center">
        <Text fontWeight="bold">{t(`AWARD.${name}`)}</Text>
        <Text fontSize="sm" color="gray.500">{`§${price}`}</Text>
      </Box>
    </VStack>
  );
};

Award.propTypes = {
  name: PropTypes.oneOf(Object.keys(AWARDS)).isRequired,
  onClick: PropTypes.func.isRequired,
};

Award.defaultProps = {
};

export default Award;
