import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useTranslation, useTitle, useInputValue } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';
import * as communityActions from 'state/communities/actions';
import * as appActions from 'state/app/actions';

import Button from 'components/Button';
import InputSearch from 'components/Forms/InputSearch';
import PageTitle from 'components/PageTitle';
import {
  CreateText,
  CreateWrapper,
  Grid,
  SearchWrapper,
  Loader,
  LoadError,
} from 'components/Explore';
import {
  FlexWrapper,
  FlexInnerWrapper,
  FlexContainer,
} from 'components/FlexWrapper';

import Community from './Community';
import locales from './i18n';

const Explore = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  useTitle(t('Explore communities'));

  const [loaded, setLoaded] = useState(false);
  const [loadError, setLoadError] = useState(null);
  const filter = useInputValue('');

  const communityIds = useSelector(
    state => communitySelectors.selectAllIdsSortedAndFiltered(state, filter.value),
    shallowEqual,
  );

  useEffect(() => {
    const fetch = async () => {
      try {
        await dispatch(communityActions.fetchAll());
        setLoaded(true);
      } catch (error) {
        setLoadError(error);
      }
    };

    // It's a single column, so it's like a left column for the layout
    dispatch(appActions.uiLeftColumn(true));

    fetch();
  }, [dispatch]);

  return (
    <FlexWrapper canOverflow mobileHasHeader>
      <FlexInnerWrapper>
        <PageTitle>{t('Find your community')}</PageTitle>

        <FlexContainer>
          <SearchWrapper>
            <InputSearch placeholder={t('Search for a community')} {...filter} />
          </SearchWrapper>
          <CreateWrapper>
            <CreateText>{t('Didn\'t find your kink? Create it!')}</CreateText>
            <Button to="/communities/create" className="small">{t('Create a community')}</Button>
          </CreateWrapper>
          {loadError
            ? <LoadError message={loadError} />
            : (
              <Grid>
                {loaded
                  ? communityIds.map(communityId => (
                    <Community key={`explore-${communityId}`} communityId={communityId} />
                  ))
                  : <Loader />
                }
              </Grid>
            )
          }
        </FlexContainer>
      </FlexInnerWrapper>
    </FlexWrapper>
  );
};

export default Explore;
