import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import * as appActions from 'state/app/actions';
import * as threadActions from 'state/threads/actions';

import { useTranslation } from 'hooks';

import Modal from 'components/Modal';
import Button from 'components/Button';

import locales from './i18n';

const RemoveThread = ({ close, threadId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const [removing, setRemoving] = useState(false);

  const remove = async () => {
    try {
      setRemoving(true);

      await dispatch(threadActions.remove(threadId));
      setRemoving(false);
      close();
      dispatch(appActions.addToast(t('Thread removed')));
    } catch (error) {
      dispatch(appActions.addError(error));
      setRemoving(false);
      close();
    }
  };

  return (
    <Modal
      title={t('Remove thread')}
      onCancel={close}
      actions={[
        <Button key="remove-thread" onClick={remove} loading={removing}>{t('global:Confirm')}</Button>,
      ]}
    >
      {t('Are you sure you want to remove this thread?')}
    </Modal>
  );
};

RemoveThread.propTypes = {
  close: PropTypes.func.isRequired,
  threadId: PropTypes.string.isRequired,
};

export default RemoveThread;
