import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #F5F0F0;
  box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.08);
  padding: 8px;

  > div:first-child {
    margin-right: 32px;
  }

  > div:nth-child(2) {
    flex: 1;
  }

  .calendar {
    font-size: 1em; /* change icon size */
    display: block;
    position: relative;
    width: 7em;
    height: 7em;
    background-color: #fff;
    margin: 0;
    border-radius: 0.6em;
    box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
    overflow: hidden;
    backface-visibility: hidden;
    transform: rotate(0deg) skewY(0deg);
    -webkit-transform-origin: 50% 10%;
    transform-origin: 50% 10%;

    & * {
      display: block;
      width: 100%;
      font-size: 1em;
      font-weight: bold;
      font-style: normal;
      text-align: center;
    }

    & strong {
      position: absolute;
      top: 0;
      padding: 0.4em 0;
      color: #fff;
      background-color: ${props => props.theme.colors.main};
      border-bottom: 1px dashed #f37302;
      box-shadow: 0 2px 0 ${props => props.theme.colors.main};
    }

    & em {
      position: absolute;
      bottom: 0.3em;
      color: ${props => props.theme.colors.main};
    }

    & span {
      width: 100%;
      font-size: 2.8em;
      letter-spacing: -0.05em;
      padding-top: 0.8em;
      color: #2f2f2f;
    }

    &:hover, &:focus {
      -webkit-animation: swing 0.6s ease-out;
      animation: swing 0.6s ease-out;
    }

    @keyframes swing {
      0%   { transform: rotate(0deg)  skewY(0deg); }
      20%  { transform: rotate(3deg) skewY(1deg); }
      60%  { transform: rotate(-3deg) skewY(-1deg); }
      80%  { transform: rotate(2deg)  skewY(-1deg); }
      100% { transform: rotate(0deg)  skewY(0deg); }
    }
  }

  table {
    margin-bottom: 16px;

    tr {
      > td:first-child {
        font-weight: bold;
        padding-right: 8px;
      }
    }
  }

  .features {
    margin-bottom: 32px;
    font-size: 18px;

    > div {
      display: flex;
      margin-bottom: 4px;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      span {
        line-height: 24px;
      }

      &.disabled {
        color: #AAA;
        text-decoration: line-through;

        svg {
          path {
            fill: #AAA;
          }
        }
      }
    }
  }

  .actions button {
    margin-right: 16px;
  }

  @media(max-width: 768px) {
    flex-direction: column;

    > div:first-child {
      margin-right: 0;
    }

    > div:nth-child(3) {
      display: none;
    }

    .calendar {
      margin: 0 auto 32px;
    }

    table {
      margin: 0 auto;
    }

    .actions {
      margin: 16px auto 0;
      text-align: center;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
