import { useState } from 'react';

import { useTranslation } from 'hooks';

import Modal from 'components/Modal';
import Button from 'components/Button';

import locales from './i18n';

const LurkerModal = () => {
  const { t } = useTranslation(locales);

  const [showing, setShowing] = useState(true);
  const close = () => setShowing(false);

  if (!showing) return null;

  return (
    <Modal
      actions={[
        <Button key="lurker-modal-close" color="white" fontColor="black" onClick={close}>{t('Not now')}</Button>,
        <Button key="lurker-modal-signup" to="/signup">{t('I want to sign up!')}</Button>,
      ]}
    >
      <div>
        {t('lurker.copy.1')}
        <br />
        <br />
        {t('lurker.copy.2')}
        <br />
        <br />
        {t('lurker.copy.3')}
      </div>
    </Modal>
  );
};

LurkerModal.propTypes = {
};

LurkerModal.defaultProps = {
};

export default LurkerModal;
