import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as invoiceSelectors from 'state/invoices/selectors';

import { Item } from 'components/Menu';

import locales from '../../i18n';

const Invoices = () => {
  const { t } = useTranslation(locales);
  const hasInvoices = useSelector(invoiceSelectors.hasInvoices);
  const unpaidInvoicesCount = useSelector(invoiceSelectors.unpaidInvoicesCount);

  if (!hasInvoices) return null;

  return (
    <Item warning={unpaidInvoicesCount > 0}>
      <Link to="/user/invoices">
        <span>{t('Invoices')}</span>
        {unpaidInvoicesCount > 0 && <span className="counter">{unpaidInvoicesCount}</span>}
      </Link>
    </Item>
  );
};

Invoices.propTypes = {
};

Invoices.defaultProps = {
};

export default Invoices;
