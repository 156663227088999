import styled from 'styled-components';

interface Props extends React.HTMLProps<HTMLDivElement> {
  hasTitle?: boolean;
}

const Main = styled.div<Props>`
  background-color: #FAFAFA;
  height: 100%;
  padding: 40px 0 0;
  flex-grow: 1;
  box-sizing: border-box;
  overflow: auto;

  @media(max-width: 768px) {
    ${props => props.hasTitle && `
      margin-top: 64px;
    `}
  }
`;

export default Main;
