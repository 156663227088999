import styled from 'styled-components';

const Separator = styled.div.attrs({
  className: 'nav-dd-content',
})`
  background-color: ${props => props.theme.colors.mainLight};
  height: 1px;
  line-height: 1px;
  margin: 8px;
`;
Separator.displayName = 'Separator';

export default Separator;
