import { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import NotFound from 'containers/NotFound';
import Loading from 'components/Loading';

const AuthedRoute = lazy(() => import('./AuthedRoute' /* webpackChunkName: "authedroute" */));
const UserNotifications = lazy(() => import('containers/User/Notifications' /* webpackChunkName: "usernotifications" */));
const EmailConfirmation = lazy(() => import('containers/User/EmailConfirmation' /* webpackChunkName: "useremailconfirmation" */));
const ChatBots = lazy(() => import('containers/User/Bots' /* webpackChunkName: "chatbots" */));
const NewChatBot = lazy(() => import('containers/User/Bots/New' /* webpackChunkName: "newchatbot" */));
const UserEdit = lazy(() => import('containers/User/Edit' /* webpackChunkName: "useredit" */));
const UserCollections = lazy(() => import('containers/User/Collections' /* webpackChunkName: "usercollections" */));
const UserRelationships = lazy(() => import('containers/User/Relationships' /* webpackChunkName: "userrelationships" */));
const UserBlocks = lazy(() => import('containers/User/Blocks' /* webpackChunkName: "userblocks" */));
const UserLists = lazy(() => import('containers/User/Lists' /* webpackChunkName: "userlists" */));
const UserListsList = lazy(() => import('containers/User/Lists/List' /* webpackChunkName: "userlistslist" */));
const UserChecklist = lazy(() => import('containers/User/Checklist' /* webpackChunkName: "userchecklist" */));
const UserInvoices = lazy(() => import('containers/User/Invoices' /* webpackChunkName: "userinvoices" */));
const UserInvoice = lazy(() => import('containers/User/Invoices/Invoice' /* webpackChunkName: "userinvoice" */));
const UserSades = lazy(() => import('containers/User/Sades' /* webpackChunkName: "usersades" */));

const UsersSwitch = () => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <AuthedRoute
        path="/user/bots"
        exact
        component={ChatBots}
      />
      <AuthedRoute
        path="/user/bots/new"
        exact
        component={NewChatBot}
      />
      <AuthedRoute
        path="/user/notifications"
        exact
        component={UserNotifications}
      />
      <AuthedRoute
        path="/user/collections"
        exact
        component={UserCollections}
      />
      <AuthedRoute
        path="/user/relationships"
        exact
        component={UserRelationships}
      />
      <AuthedRoute
        path="/user/blocks"
        exact
        component={UserBlocks}
      />
      <AuthedRoute
        path="/user/lists"
        exact
        component={UserLists}
      />
      <AuthedRoute
        path="/user/lists/:listId"
        exact
        component={UserListsList}
      />
      <AuthedRoute
        path="/user/checklist"
        exact
        component={UserChecklist}
      />
      <Route
        path="/user/emailconfirmation/:hash"
        component={EmailConfirmation}
      />
      <AuthedRoute
        path="/user/invoices"
        component={UserInvoices}
        exact
      />
      <AuthedRoute
        path="/user/invoices/:invoiceId"
        component={UserInvoice}
      />
      <AuthedRoute
        path="/user/sades"
        component={UserSades}
      />
      <AuthedRoute path="/user/edit" component={UserEdit} />

      <Route component={NotFound} />
    </Switch>
  </Suspense>
);

UsersSwitch.propTypes = {
};

UsersSwitch.defaultProps = {
};

export default UsersSwitch;
