import {
  Menu, Button, Icon, MenuButton, MenuItem, MenuList,
  Link,
} from '@chakra-ui/react';
import { ChevronDown } from 'lucide-react';
import {
  google, outlook, office365, yahoo, ics,
} from 'calendar-link';

import { useTranslation } from 'hooks';

import locales from '../i18n';

interface Props {
  event: {
    title: string;
    details: string;
    location: string;
    start: string;
    end: string;
  };
}

const AddToCalendarButton: React.FC<Props> = ({ event }) => {
  const { t } = useTranslation(locales);

  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<Icon as={ChevronDown} />}>
        {t('Add to calendar')}
      </MenuButton>
      <MenuList>
        <MenuItem _hover={{ textDecoration: 'none' }} as={Link} href={google(event)} target="_blank">Google</MenuItem>
        <MenuItem _hover={{ textDecoration: 'none' }} as={Link} href={outlook(event)} target="_blank">Outlook</MenuItem>
        <MenuItem _hover={{ textDecoration: 'none' }} as={Link} href={office365(event)} target="_blank">Office 365</MenuItem>
        <MenuItem _hover={{ textDecoration: 'none' }} as={Link} href={yahoo(event)} target="_blank">Yahoo!</MenuItem>
        <MenuItem _hover={{ textDecoration: 'none' }} as={Link} href={ics(event)} target="_blank">ICS</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default AddToCalendarButton;
