import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';
import * as messengerSelectors from 'state/messengers/selectors';
import { entityEqual } from 'state/channels/equalityFunctions';

interface Entity {
  name: string;
}

const filterEntities = (entities: Entity[], filter: string) => entities
  .filter(({ name }) => {
    if (!name) return false;
    return name.toLowerCase().includes(filter.toLowerCase());
  });

const useChatList = (filter: string) => {
  const filteredEntities = useSelector(
    state => channelSelectors.sortedAndFilteredEntitiesSelector(state, filter),
    entityEqual,
  );
  const myArchivedMessengers = useSelector(messengerSelectors.getSortedArchived, entityEqual);

  const result = useMemo(() => {
    const archivedMessengers = filterEntities(myArchivedMessengers, filter);

    return [filteredEntities, archivedMessengers];
  }, [filteredEntities, myArchivedMessengers, filter]);

  return result;
};

export default useChatList;
