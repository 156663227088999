import styled from 'styled-components';

const SingleColumn = styled.div`
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  height: 100%;
`;

export default SingleColumn;
