import styled from 'styled-components';

interface Props {
  top?: boolean;
}

const BoxDivider = styled.div<Props>`
  height: 20px;
  position: absolute;
  width: 100%;
  background: radial-gradient(ellipse farthest-side at bottom center, rgba(0,0,0,.08), transparent);
  ${props => props.top && `
    top: 0;
  `}

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    height: 3px;
    background: linear-gradient(left, transparent, rgba(0,0,0,.02), rgba(0,0,0,.02), transparent);
  }
`;
BoxDivider.displayName = 'BoxDivider';

export default BoxDivider;
