import useTranslation from 'hooks/useTranslation';
import { Icon } from '@chakra-ui/react';

import { Information } from 'components/Icons';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

interface Props {
  read: boolean;
  timestamp: string;
}

const WarningIcon = () => <Icon as={Information} bgColor="brand.500" fill="white" color="white" rounded="full" boxSize={10} p={2} w="auto" />;

const ChannelDeleted: React.FC<Props> = ({ timestamp, read }) => {
  const { t } = useTranslation(locales);

  return (
    <AlertContainer timestamp={timestamp} image={<WarningIcon />} read={read}>
      {t('One of your publications has been reported and is under review. In the meantime, it has been hidden.')}
    </AlertContainer>
  );
};

export default ChannelDeleted;
