import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'utils/css/colors';

const Title = styled.div`
  font-weight: 500;
`;

// We don't use CommunityAvatar because results are not stored in state
const Avatar = styled.img`
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  ${props => props.withShadow && 'box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.16);'}
  flex-shrink: 0;
  color: white;
  overflow: hidden;
`;
Avatar.displayName = 'Avatar';

const Wrapper = styled.div`
  display: flex;
  padding: 10px;

  ${props => (props.active ? `
    background-color: ${colors.red};
    ` : `
    &:hover {
      background-color: ${colors.borderRed};
    }
  `)}
`;

const Names = styled.div`
  margin-left: 16px;
`;

const ResultItem = ({ data }) => (
  <Wrapper>
    <Avatar src={data.community.avatar} />
    <Names>
      <Title>{data.title}</Title>
    </Names>
  </Wrapper>
);

ResultItem.propTypes = {
  data: PropTypes.shape({
    community: PropTypes.shape({
      avatar: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

ResultItem.defaultProps = {
};

export default ResultItem;
