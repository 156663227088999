import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import loadImage from 'blueimp-load-image';

import { useTranslation } from 'hooks';

import Input from 'components/Forms/Input';

import { POLL_COLORS } from '../../../../constants';
import OptionsWrapper from './OptionsWrapper';
import ColorPickerModal from './ColorPickerModal';
import ImageCropModal from './ImageCropModal';
import PollOption from './PollOption';
import TypeSelector from './TypeSelector';
import locales from '../../i18n';

const randomColor = () => {
  const candidates = Object.keys(POLL_COLORS);
  return candidates[Math.floor(Math.random() * candidates.length)];
};

const NewPoll = ({ payload, setPayload, children }) => {
  const { t } = useTranslation(locales);

  useEffect(() => {
    if (!payload || !payload.poll) {
      setPayload({
        poll: {
          options: [
            { text: t('First option'), color: randomColor() },
            { text: t('Second option'), color: randomColor() },
          ],
        },
      });
    }
  }, [payload, setPayload, t]);

  const [pickingColor, setPickingColor] = useState(null);
  const [localImage, setLocalImage] = useState(null);

  const selectColor = useCallback(key => () => {
    setPayload((currVal) => {
      const newVal = [...currVal.poll.options];
      newVal[pickingColor] = {
        ...newVal[pickingColor],
        color: key,
        image: undefined,
      };

      return { poll: { options: newVal } };
    });
    setPickingColor(null);
  }, [pickingColor, setPayload]);

  const setText = useCallback(index => (e) => {
    setPayload((currVal) => {
      const newVal = [...currVal.poll.options];
      newVal[index] = {
        ...newVal[index],
        text: e.target.value,
      };

      return { poll: { options: newVal } };
    });
  }, [setPayload]);

  const onFileSelect = useCallback(index => (e) => {
    loadImage(
      e.target.files[0],
      (img) => {
        const base64data = img.toDataURL('image/jpeg');
        setLocalImage({
          src: base64data,
          img,
          index,
        });
      },
      {
        maxWidth: 334, maxHeight: 400, orientation: true, canvas: true,
      },
    );
  }, []);

  const setImage = useCallback((image, index) => {
    setPayload((currVal) => {
      const newVal = [...currVal.poll.options];
      newVal[index] = {
        ...newVal[index],
        image,
        color: undefined,
      };

      return { poll: { options: newVal } };
    });
  }, [setPayload]);

  if (!payload || !payload.poll || !payload.poll.options) return null;

  return (
    <>
      <div>
        <OptionsWrapper>
          <div>
            <div>
              <PollOption {...payload.poll.options[0]} />
              <TypeSelector
                setPickingColor={() => setPickingColor(0)}
                onFileSelect={onFileSelect(0)}
              />
              <Input placeholder={t('Text (optional if image)')} onChange={setText(0)} value={payload.poll.options[0].text || ''} />
            </div>
            <div>
              <PollOption {...payload.poll.options[1]} />
              <TypeSelector
                setPickingColor={() => setPickingColor(1)}
                onFileSelect={onFileSelect(1)}
              />
              <Input placeholder={t('Text (optional if image)')} onChange={setText(1)} value={payload.poll.options[1].text || ''} />
            </div>
          </div>
        </OptionsWrapper>

        {children}
      </div>

      {/* Modals */}
      {pickingColor !== null && (
        <ColorPickerModal
          setPickingColor={setPickingColor}
          selectColor={selectColor}
        />
      )}

      {localImage !== null && (
        <ImageCropModal setLocalImage={setLocalImage} image={localImage} setImage={setImage} />
      )}
    </>
  );
};

NewPoll.propTypes = {
  children: PropTypes.node.isRequired,
  payload: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    poll: PropTypes.shape({ options: PropTypes.arrayOf(PropTypes.object) }),
  }),
  setPayload: PropTypes.func.isRequired,
};

NewPoll.defaultProps = {
  payload: null,
};

export default NewPoll;
