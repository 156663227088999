import styled from 'styled-components';

const TwoColumns = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'left';
  max-width: 1048px;
  width: 100%;
  align-self: center;

  @media(min-width: 768px) {
    grid-template-columns: 1fr 348px;
    grid-template-areas: 'left right';
  }

  @media(max-width: 768px) {
    grid-template-areas: 'left';
  }

  .modal-opacity {
    display: none;

    @media(max-width: 1279px) {
      display: block;
      background-color: rgba(0, 0, 0, 0.6);
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      pointer-events: none;
      opacity: 0;
    }
  }

  > div {
    &:nth-child(1) {
      grid-area: left;
      background-color: white;

      @media(max-width: 1279px) {
        left: 0;
        bottom: 0;
        z-index: 1000;
      }

      @media(max-width: 768px) {
        top: 64px;
        position: relative;
      }
    }

    &:nth-child(2) {
      grid-area: right;

      @media(max-width: 768px) {
        display: none;
      }
    }
  }
`;

export default TwoColumns;
