import { useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';

import ContentUser from './ContentUser';
import ContentNonUser from './ContentNonUser';

const Content: React.FC = () => {
  const isLoggedIn = useSelector(authSelectors.loggedIn);

  if (isLoggedIn) return <ContentUser />;
  return <ContentNonUser />;
};

export default Content;
