import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';
import * as authActions from 'state/auth/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Radio from 'components/Forms/Radio';
import Toggle from 'components/Toggle';

import locales from '../../i18n';
import { REPORT_REASONS } from '../../../../constants';

const Report = ({ userId, close }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [block, setBlock] = useState(true);
  const [greet, setGreet] = useState(false);

  const onReasonChange = useCallback(reason => () => {
    setSelectedReason(reason);
  }, []);

  const onBlockChange = useCallback(() => {
    setBlock(b => !b);
  }, []);

  const onConfirm = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(authActions.report(userId, selectedReason, block));
      setGreet(true);
    } catch (error) {
      dispatch(appActions.addError(error));
      close();
    }
  }, [dispatch, close, userId, selectedReason, block]);

  if (greet) {
    return (
      <Modal title={t('Block')} onClose={close}>
        <p>{t('Your report has been received. Please take into account that if enough reports are commited to this profile, it will be suspended automatically.')}</p>
        <p>
          <Trans t={t} ns="Profiles" i18nKey="block.read.more">
            If you want to know more, please read the following article:
            {' '}
            <Link to="/+ayuda-y-sugerencias/lcvwgo1qx">Automatic moderation system</Link>
          </Trans>
        </p>
      </Modal>
    );
  }

  return (
    <Modal
      title={t('Report')}
      onCancel={close}
      actions={[(
        <Button
          key="report-confirm"
          onClick={onConfirm}
          loading={loading}
          disabled={selectedReason === null}
        >
          {t('global:Confirm')}
        </Button>
      )]}
    >
      {Object.values(REPORT_REASONS).map(reason => (
        <Radio
          key={`report-reason-${reason}`}
          label={t(reason)}
          onSelect={onReasonChange(reason)}
          selected={selectedReason === reason}
        />
      ))}

      <Toggle
        label={t('Also block this user')}
        onChange={onBlockChange}
        active={block}
        position="left"
      />
    </Modal>
  );
};

Report.propTypes = {
  userId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};

Report.defaultProps = {
};

export default Report;
