import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const Arrow = memo(({ color }) => (
  <svg viewBox="0 0 306 306">
    <polygon points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153" fill={color} />
  </svg>
));

Arrow.propTypes = IconType;
Arrow.defaultProps = IconDefaults;

export default Arrow;
