import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import formatNumber from 'utils/formatNumber';
import { useTranslation } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';
import * as appActions from 'state/app/actions';

import locales from './i18n';

const UsersStatus = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0 !important;

  > div, > a {
    flex: 1;
  }
`;

const UsersStatusItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    &:first-child {
      font-size: 30px;
      font-weight: 300;
      color: #666;
    }
    &:last-child {
      color: ${colors.grey};
      font-size: 14px;
    }
  }
`;

const Counters = ({ communityId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const slug = useSelector(state => communitySelectors.selectSlugById(state, communityId));
  const membershipCount = useSelector(
    state => communitySelectors.selectMembershipCount(state, communityId),
  );
  const threadCount = useSelector(
    state => communitySelectors.selectThreadCount(state, communityId),
  );
  const replyCount = useSelector(state => communitySelectors.selectReplyCount(state, communityId));

  const hideLeftColumn = useCallback(() => {
    dispatch(appActions.uiLeftColumn(false));
  }, [dispatch]);

  return (
    <UsersStatus>
      <Link to={`/+${slug}/members`}>
        <UsersStatusItem>
          <span>{formatNumber(membershipCount)}</span>
          <span>{t('global:Subscribers')}</span>
        </UsersStatusItem>
      </Link>
      <UsersStatusItem onClick={hideLeftColumn}>
        <span>{formatNumber(threadCount)}</span>
        <span>{t('global:Threads', { context: threadCount === 1 && 'SINGULAR' })}</span>
      </UsersStatusItem>
      <UsersStatusItem>
        <span>{formatNumber(replyCount)}</span>
        <span>{t('global:Replies', { context: replyCount === 1 && 'SINGULAR' })}</span>
      </UsersStatusItem>
    </UsersStatus>
  );
};

Counters.propTypes = {
  communityId: PropTypes.string.isRequired,
};

Counters.defaultProps = {
};

export default Counters;
