import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import UserLink from 'components/UserLink';
import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import { MEMBERSHIP_ROLES } from '../../../constants';
import locales from './i18n';

const ModContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;

  .avatar {
    margin-right: 8px;
  }

  .displayname {
    color: #666;
  }
`;
ModContainer.displayName = 'ModContainer';

const Description = styled.div`

`;
Description.displayName = 'Description';

const Role = styled.div`
  font-size: 12px;
`;
Role.displayName = 'Role';

const Mod = memo(({ membership }) => {
  const { t } = useTranslation(locales);

  const pronoun = useSelector(userSelectors.getPronoun(membership.user));

  return (
    <UserLink userId={membership.user}>
      <ModContainer>
        <UserAvatar userId={membership.user} size="32px" />
        <Description>
          <UserDisplayName userId={membership.user} />
          <Role>{t(`global:${membership.role}`, { context: pronoun })}</Role>
        </Description>
      </ModContainer>
    </UserLink>
  );
});

Mod.propTypes = {
  membership: PropTypes.shape({
    user: PropTypes.number.isRequired,
    role: PropTypes.oneOf(Object.values(MEMBERSHIP_ROLES)).isRequired,
  }).isRequired,
};

Mod.defaultProps = {
};

export default Mod;
