import {
  memo, useEffect, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import isMobile from 'utils/isMobile';
import ComposerRef from 'utils/ComposerRef';
import * as channelSelectors from 'state/channels/selectors';
import * as messengerSelectors from 'state/messengers/selectors';
import * as appActions from 'state/app/actions';
import * as channelActions from 'state/channels/actions';
import * as messengerActions from 'state/messengers/actions';

import { Check, Close } from 'components/Icons';
import Composer, { EmojiPicker } from 'components/Composer';
import Spinner from 'components/Spinner';

import Wrapper from './Wrapper';
import ReplyTo from './ReplyTo';
import Action from './Action';
import locales from '../../i18n';

const ComposeMessage = memo(({ type, id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const key = `${type}edit-${id}`;
  const entitySelectors = type === 'channel' ? channelSelectors : messengerSelectors;
  const entityActions = type === 'channel' ? channelActions : messengerActions;

  const messageId = useSelector(state => entitySelectors.getEditingMessageId(state, id));
  const rawContent = useSelector(state => entitySelectors.getMessageContent(state, messageId));

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const ref = ComposerRef.getRef(key);

    if (ref) {
      ref.setValue(rawContent);
      ref.setCursor(ref.lineCount(), 0);
    }
  }, [rawContent, key]);

  const cancel = useCallback(() => {
    dispatch(entityActions.cancelEditing(id));
  }, [dispatch, id, entityActions]);

  const save = useCallback(async () => {
    setSaving(true);
    await dispatch(appActions.composerSendToServer(key));
  }, [dispatch, key]);

  return (
    <Wrapper>
      <div className="composer">
        <div>
          <Action>
            <EmojiPicker id={key} />
          </Action>
        </div>
        <div>
          <ReplyTo type={type} id={id} />
          <Composer
            key={`chat-${key}`}
            id={key}
            placeholder={t('Enter your message')}
            submitOnEnter
            autofocus={!isMobile}
            maxHeight="100px"
            onEscKeyPress={cancel}
          />
        </div>
        <div className="edit-buttons">
          {saving
            ? <Spinner color="#666" />
            : (
              <>
                <button type="button" onClick={save}><Check color="green" /></button>
                <button type="button" onClick={cancel}><Close color="red" /></button>
              </>
            )
          }
        </div>
      </div>
    </Wrapper>
  );
});

ComposeMessage.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['channel', 'messenger']).isRequired,
};

export default ComposeMessage;
