import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Api from 'state/api';
import isMobile from 'utils/isMobile';
import urlBase64ToUint8Array from 'utils/urlBase64ToUint8Array';
import { useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';

import Toggle from 'components/Toggle';

import locales from './i18n';

const MobileAskWrapper = styled.div`
  background-color: ${props => props.theme.colors.warningBackground};

  & > label {
    height: 48px;
  }
`;
MobileAskWrapper.displayName = 'MobileAskWrapper';

const isSupported = (devices, registration) => {
  try {
    const supported = ServiceWorkerRegistration && 'showNotification' in ServiceWorkerRegistration.prototype;
    if (devices === null || !registration || !isMobile || !supported || (Notification.permission !== 'default' && devices > 0)) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

const MobileAskNotifications = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [registration, setRegistration] = useState(null);
  const [activating, setActivating] = useState(false);
  const [devices, setDevices] = useState(null);

  useEffect(() => {
    const getWorker = async () => {
      if ('serviceWorker' in navigator) {
        const reg = await navigator.serviceWorker.ready;
        setRegistration(reg);
      }
    };

    const getDevices = async () => {
      const { data: { total } } = await Api.req.get('/notifications/pushsubscriptions');
      setDevices(total);
    };

    getWorker();
    getDevices();
  }, []);

  if (!isSupported(devices, registration)) return null;

  const activate = async () => {
    const publicKey = 'BF3heukCK5qmDPQCvLXR84cWibySWzV76T0DitVQPbX-vuvtXSOug-ZOgejR7I-EqV1GL1Nm4MoPx2y94R6jjQ0';
    setActivating(true);

    try {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicKey),
      });

      await Api.req.post('/notifications/pushsubscriptions', { subscription });

      dispatch(appActions.addToast(t('Notifications enabled')));
      setRegistration(null);
    } catch (error) {
      dispatch(appActions.addError(error));
      setActivating(false);
    }
  };

  return (
    <MobileAskWrapper>
      <Toggle
        label={t('Get notifications in this device')}
        active={false}
        onChange={activate}
        loading={activating}
      />
    </MobileAskWrapper>
  );
};

MobileAskNotifications.propTypes = {
};

MobileAskNotifications.defaultProps = {
};

export default MobileAskNotifications;
