import styled from 'styled-components';

import { Item } from 'components/Menu';

const ItemDetail = styled(Item)`
  display: flex;
  justify-content: space-between;

  > div:first-child {
    display: flex;

    > a {
      line-height: normal !important;
    }

    > svg {
      width: 20px !important;
      height: 20px;
      margin-right: 4px;

      path {
        fill: ${props => props.theme.colors.secondary};
      }
    }
  }

  > div:last-child {
    font-size: 12px;
    font-weight: bold;
    background-color: ${props => props.theme.colors.mainLight};
    padding: 4px 8px;
    border-radius: 8px;
  }
`;
ItemDetail.displayName = 'ItemDetail';

export default ItemDetail;
