import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';

import UserDisplayName from 'components/UserDisplayName';
import BotName from 'components/BotName';

import { CM_AUTHOR_TYPES } from '../../../constants';

const ReplyingToWrapper = styled.div`
  font-size: 14px;
  background: #ed4d3d1f;
  padding: 4px 12px 8px;
  border-radius: 8px;
  margin-right: 32px;
  border-left: 3px solid ${props => props.theme.colors.main};
  margin-bottom: 8px;
  color: #444;
  position: relative;
  max-height: 70px;

  overflow: hidden;

  ${props => props.fullWidth && `
    width: 100%;
    margin-top: 4px;
  `}

  .content {
    line-height: 14px;
    display: flex;

    > div {
      flex: 1;
      margin-right: 16px;

      img, .gif {
        max-width: 100%;
        max-height: 150px;
        width: auto;
        height: auto;
      }
    }

    button {
      border: 0;
      background: none;
      padding: 0;
      outline: none;
      cursor: pointer;
      position: absolute;
      top: 6px;
      right: 8px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &:after {
    content: '';
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,.8) 100%);
    position: absolute;
    top: 64px;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;
ReplyingToWrapper.displayName = 'ReplyingToWrapper';

const Author = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: #333;
  margin-right: 16px;
`;
Author.displayName = 'Author';

const ChannelName = ({ channelId }) => {
  const name = useSelector(state => channelSelectors.getName(state, channelId));
  return <span>{name}</span>;
};

ChannelName.propTypes = { channelId: PropTypes.string.isRequired };

const ReplyingTo = ({
  authorType, authorId, fullWidth, children, loading, deleted,
}) => (
  <ReplyingToWrapper fullWidth={fullWidth}>
    {!loading && !deleted && (
      <Author>
        {authorType === 'USER' && <UserDisplayName userId={authorId} />}
        {authorType === 'AUTORESPONDER' && <ChannelName channelId={authorId} />}
        {authorType === 'BOT' && <BotName botId={authorId} />}
      </Author>
    )}
    <div className="content">
      {children}
    </div>

    <div className="fader" />
  </ReplyingToWrapper>
);

ReplyingTo.propTypes = {
  authorType: PropTypes.oneOf(Object.values(CM_AUTHOR_TYPES)),
  authorId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  deleted: PropTypes.bool,
};

ReplyingTo.defaultProps = {
  authorType: 'USER',
  fullWidth: false,
  loading: false,
  deleted: false,
};

export default memo(ReplyingTo);
