import { forwardRef } from 'react';
import styled from 'styled-components';

import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';

import locales from './i18n';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.span`
  color: ${colors.blackRed};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
`;

interface InputComponentProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasError: boolean;
  ref?: React.Ref<unknown>;
}

const InputComponent = styled.input.attrs<InputComponentProps>(props => ({
  type: props.type || 'text',
}))<InputComponentProps>`
  font-size: 16px;
  font-weight: 500;
  line-height: 56px;
  padding: 0 24px;
  margin: 0 0 10px;
  border: 1px solid ${props => (props.hasError ? 'red' : '#F5F0F0')};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  transition: all 250ms ease-out;
  &:focus {
    outline: none;
  }
`;

const InputRequeriments = styled.span`
  font-size: 14px;
  color: #A8A8A8;
`;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string | number;
  label?: string;
  maxChars?: number;
  error?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = forwardRef(({
  value,
  label,
  maxChars,
  error,
  onChange,
  ...rest
}: InputProps, ref) => {
  const { t } = useTranslation(locales);

  const hasError = !!((error || (maxChars && value.toString().length > maxChars)) && value !== '');

  return (
    <InputWrapper>
      {label && <InputLabel>{label}</InputLabel>}
      <InputComponent
        ref={ref}
        value={value}
        onChange={onChange}
        hasError={hasError}
        {...rest}
      />
      {maxChars && <InputRequeriments>{t('Max {{maxChars}} chars', { maxChars })}</InputRequeriments>}
      {error !== '' && <div>{error}</div>}
    </InputWrapper>
  );
});

Input.displayName = 'Input';

export default Input;
