import Color from 'color';

import getRandomInt from 'utils/getRandomInt';

const generateParticipants = (qty) => {
  const participants = [];
  for (let i = 0; i < qty; i += 1) {
    participants.push({
      avatarColor: Color.rgb(
        getRandomInt(0, 255),
        getRandomInt(0, 255),
        getRandomInt(0, 255),
      ).string(),
      usernameWidth: getRandomInt(35, 100),
    });
  }
  return participants;
};

export default generateParticipants;
