import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';

import Modal from 'components/Modal';
import Button from 'components/Button';
import { SelectableList, SelectableListItem } from 'components/SelectableList';

import locales from '../i18n';

const PrivacyListModal = ({ close, onConfirm, initialLists }) => {
  const { t } = useTranslation(locales);
  const [selectedLists, setSelectedLists] = useState(initialLists);
  const lists = useSelector(authSelectors.selectUserLists, shallowEqual);

  const toggleSelectedList = id => () => {
    if (selectedLists.includes(id)) setSelectedLists(selectedLists.filter(listId => listId !== id));
    else setSelectedLists([...selectedLists, id]);
  };

  const onConfirmButtonClick = () => {
    onConfirm(selectedLists);
  };

  return (
    <Modal
      title={t('Select privacy lists')}
      onCancel={close}
      actions={[
        <Button
          key="privacy-lists-confirm"
          onClick={onConfirmButtonClick}
        >
          {t('global:Confirm')}
        </Button>,
      ]}
    >
      {t('Select the lists of contacts you wish to show your publication')}

      <SelectableList>
        {lists.map(list => (
          <SelectableListItem
            key={`list-${list.id}`}
            title={list.name}
            onClick={toggleSelectedList(list.id)}
            active={selectedLists.includes(list.id)}
          />
        ))}
      </SelectableList>
    </Modal>
  );
};

PrivacyListModal.propTypes = {
  close: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  initialLists: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrivacyListModal;
