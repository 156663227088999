import styled from 'styled-components';

import { useTranslation } from 'hooks';

import locales from '../i18n';

const Wrapper = styled.div`
  color: ${props => props.theme.colors.secondaryText};
  margin-top: 16px;
  font-size: 14px;
  margin-top: 16px;
  font-size: 14px;
  border-top: 1px solid #eee;
  padding-top: 16px;
`;
Wrapper.displayName = 'Wrapper';

const Disclaimer = () => {
  const { t } = useTranslation(locales);

  return (
    <Wrapper>
      {t('This Event is organized by a private party and has no direct link with Mazmo whatsoever. For any complaint or inquiry please refer to the organizers.')}
    </Wrapper>
  );
};

Disclaimer.propTypes = {
};

Disclaimer.defaultProps = {
};

export default Disclaimer;
