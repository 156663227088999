import { useState } from 'react';
import {
  Button, ButtonGroup, Stack, Text,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import * as appActions from 'state/app/actions';
import * as messengerActions from 'state/messengers/actions';
import { useTranslation } from 'hooks';

import RejectReason from '../RejectReason';
import locales from '../../i18n';

interface Props {
  messengerId: string;
  isRejected: boolean;
  isBlocked: boolean;
}

const ResolveRequestActions: React.FC<Props> = ({ messengerId, isRejected, isBlocked }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();


  const [isAccepting, setIsAccepting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const accept = async () => {
    try {
      setIsAccepting(true);
      await dispatch(messengerActions.approve(messengerId));
    } catch (error) {
      dispatch(appActions.addError(error));
      setIsAccepting(false);
    }
  };

  const reject = async () => {
    try {
      setIsRejecting(true);
      await dispatch(messengerActions.reject(messengerId));
      setIsRejecting(false);
    } catch (error) {
      dispatch(appActions.addError(error));
      setIsRejecting(false);
    }
  };

  if (isBlocked) {
    return (
      <Text mt={4} color="gray.500">{t('This chat has been blocked')}</Text>
    );
  }

  if (isRejected) {
    return (
      <Stack mt={4} spacing={4} alignItems="center">
        <Stack alignItems="center">
          <Text color="gray.600" fontWeight="bold">{t('You have rejected this request')}</Text>
          <Button
            size="sm"
            onClick={accept}
            isLoading={isAccepting}
            color="gray.500"
            bgColor="gray.50"
            fontWeight="normal"
          >
            {t('I\'ve made a mistake. I want to accept this request.')}
          </Button>
        </Stack>
        <RejectReason messengerId={messengerId} />
      </Stack>
    );
  }

  return (
    <ButtonGroup size="lg" justifyContent="center" mt={4} spacing={4}>
      <Button variant="primary" onClick={accept} isLoading={isAccepting} isDisabled={isRejecting}>{t('Accept and chat')}</Button>
      {!isRejected && <Button onClick={reject} isLoading={isRejecting} isDisabled={isAccepting}>{t('global:Reject')}</Button>}
    </ButtonGroup>
  );
};

export default ResolveRequestActions;
