import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import * as communitySelectors from 'state/communities/selectors';
import * as communityActions from 'state/communities/actions';

import Busy from 'components/UserDisplayName/Busy';

const Plus = styled.span`
  color: #aaa;
`;
Plus.displayName = 'Plus';

const Community = ({ value }) => {
  const dispatch = useDispatch();

  const slug = value.trim().substr(1);

  const communityId = useSelector(state => communitySelectors.selectIdBySlug(state, slug));
  const name = useSelector(state => communitySelectors.selectName(state, communityId));

  const [error, setError] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        await dispatch(communityActions.fetch(slug));
      } catch (e) {
        setError(true);
      }
    };

    if (!communityId) fetch();
  }, [communityId, dispatch, slug]);

  const renderContent = () => {
    if (error) return value.trim();
    if (!communityId) return <Busy />;
    return (
      <Link to={`/+${slug}`} className="communitylink">
        <Plus>+</Plus>
        {name}
      </Link>
    );
  };

  return (
    <>
      {value.substr(0, 1) === ' ' && ' '}
      {renderContent()}
    </>
  );
};
Community.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Community;
