import { memo } from 'react';
import { Icon } from '@chakra-ui/react';

import { ProgressClock, Check, DoubleCheck } from 'components/Icons';

interface Props {
  state: 'SENT' | 'RECEIVED' | 'READ' | 'SENDING';
}

const ChatStateIcon = memo(({ state }: Props) => {
  switch (state) {
    case 'SENT':
      return <Icon as={Check} fill="#666" boxSize={4} />;
    case 'RECEIVED':
      return <Icon as={DoubleCheck} fill="#666" boxSize={4} />;
    case 'READ':
      return <Icon as={DoubleCheck} fill="#4FC3F7" boxSize={4} />;
    case 'SENDING':
    default:
      return <Icon as={ProgressClock} fill="#666" boxSize={4} />;
  }
});

ChatStateIcon.displayName = 'ChatStateIcon';

export default ChatStateIcon;
