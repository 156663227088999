import { useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';
import * as invoiceSelectors from 'state/invoices/selectors';

import Unconfirmed from './Unconfirmed';
import Presentation from './Presentation';
import UnpaidInvoices from './UnpaidInvoices';
import ChatRequestsExhausted from './ChatRequestsExhausted';
import LocationMissing from './LocationMissing';

const WarningBoxes = () => {
  const isEmailConfirmed = useSelector(authSelectors.selectIsEmailConfirmed);
  const presentation = useSelector(authSelectors.getOnboardingPresentation);
  const unpaidInvoicesCount = useSelector(invoiceSelectors.unpaidInvoicesCount);
  const chatRequests = useSelector(authSelectors.getRequestsLeft);
  const locationMissing = useSelector(authSelectors.locationMissing);

  if (!isEmailConfirmed) return <Unconfirmed />;
  if (presentation) return <Presentation />;
  if (unpaidInvoicesCount > 0) return <UnpaidInvoices />;
  if (chatRequests === 0) return <ChatRequestsExhausted />;
  if (locationMissing) return <LocationMissing />;

  return null;
};

WarningBoxes.propTypes = {};

WarningBoxes.defaultProps = {};

export default WarningBoxes;
