import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as replySelectors from 'state/replies/selectors';

import { UnfoldLessHorizontal } from 'components/Icons';

import Unread from './Unread';
import DeletedHeader from './DeletedHeader';
import Options from './Options';
import AuthorInfo from './AuthorInfo';
import Body from './Body';
import Actions from '../Actions';

const ReplyWrapper = styled.div`
  border-bottom: 1px solid rgba(230, 220, 220, 0.34);
  padding-top: 24px;
  box-shadow: 0px -8px 20px rgba(230, 220, 220, 0.42);
  position: relative;

  a.userinactive > div {
    opacity: .5;
  }

  ${props => props.folded && `
    color: #999;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;

      path {
        fill: #999;
      }
    }
  `}

  @media(min-width: 767px) {
    padding: 24px 24px 8px;
  }

  @media(max-width: 767px) {
    padding-bottom: 8px;
  }
`;
ReplyWrapper.displayName = 'ReplyWrapper';

const Reply = ({ replyId }) => {
  const reactionsCount = useSelector(replySelectors.getReactionsCount(replyId));

  const [folded, setFolded] = useState(reactionsCount < -9);
  const unfold = useCallback(() => setFolded(false), []);

  if (folded) {
    return (
      <ReplyWrapper folded onClick={unfold}>
        <UnfoldLessHorizontal />
        <span>Respuesta negativa oculta</span>
      </ReplyWrapper>
    );
  }

  return (
    <ReplyWrapper>
      <DeletedHeader replyId={replyId} />
      <Unread replyId={replyId} />
      <Options replyId={replyId} />
      <AuthorInfo replyId={replyId} />
      <Body replyId={replyId} />
      <Actions entityId={replyId} type="reply" />
    </ReplyWrapper>
  );
};

Reply.propTypes = {
  replyId: PropTypes.string.isRequired,
};

Reply.defaultProps = {
};

export default Reply;
