import { combineReducers } from 'redux';

import app from './app/reducer';
import auth from './auth/reducer';
import feed from './feed/reducer';
import users from './users/reducer';
import alerts from './alerts/reducer';
import threads from './threads/reducer';
import replies from './replies/reducer';
import channels from './channels/reducer';
import messengers from './messengers/reducer';
import memberships from './memberships/reducer';
import communities from './communities/reducer';
import events from './events/reducer';
import invoices from './invoices/reducer';
import bank from './bank/reducer';

export default combineReducers({
  app,
  auth,
  feed,
  users,
  alerts,
  threads,
  replies,
  channels,
  messengers,
  memberships,
  communities,
  events,
  invoices,
  bank,
});
