import { useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import isMobile from 'utils/isMobile';
import * as authSelectors from 'state/auth/selectors';
import * as threadSelectors from 'state/threads/selectors';

import Thread from './Thread';

const List = () => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const userIsLoggedIn = useSelector(authSelectors.loggedIn);

  const communitySlug = (!userIsLoggedIn || (params.communitySlug && !location.search.includes('showAll'))) ? params.communitySlug : null;

  const threads = useSelector(threadSelectors.getList(communitySlug), shallowEqual);
  const firstThreadUrl = useSelector(
    threadSelectors.getUrl(threads ? threads[0] : null),
    shallowEqual,
  );

  useEffect(() => {
    if (!params.threadSlug && firstThreadUrl && !isMobile) {
      history.replace(`${firstThreadUrl}${!params.communitySlug ? '?showAll' : ''}`);
    }
  }, [history, firstThreadUrl, params.threadSlug, params.communitySlug]);

  return (
    <>
      {threads.map(threadId => (
        <Thread
          key={`thread-list-${threadId}`}
          threadId={threadId}
          genericListing={!communitySlug}
        />
      ))}
    </>
  );
};

List.propTypes = {
};

List.defaultProps = {
};

export default List;
