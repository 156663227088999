import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';

import TransferButton from 'components/TransferButton';

const Wrapper = styled.div`
  margin: 16px 0;
`;
Wrapper.displayName = 'SadesAskWrapper';

const SadesAsk = ({ messageId }) => {
  const authorType = useSelector(state => channelSelectors.getMessageAuthorType(state, messageId));
  const authorId = useSelector(state => channelSelectors.getMessageAuthorId(state, messageId));
  const askData = useSelector(state => channelSelectors.sadesAskData(state, messageId));

  return (
    <Wrapper>
      <TransferButton to={{ type: authorType, id: authorId }} data={askData} />
    </Wrapper>
  );
};

SadesAsk.propTypes = {
  messageId: PropTypes.string.isRequired,
};

SadesAsk.defaultProps = {
};

export default SadesAsk;
