import { useState } from 'react';
import { MenuItem } from '@chakra-ui/react';
import { User } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import loadImage from 'blueimp-load-image';

import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';

import FileSelector from 'components/FileSelector';

import locales from '../../../i18n';
import AvatarModal from '../../Modals/AvatarModal';

interface Props {
}

const ChangeAvatarAction: React.FC<Props> = () => {
  const { t } = useTranslation(locales);
  const { chatId: channelId } = useParams<{ chatId: string }>();

  const isOwner = useSelector((state) => (
    // @ts-ignore
    channelSelectors.isOwnerOfChannel(state, channelId)
  ));

  const [localAvatar, setLocalAvatar] = useState<{ src: string; img: HTMLCanvasElement } | null>(null);

  if (!isOwner) return null;

  const onAvatarFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    loadImage(
      e.target.files[0],
      (img) => {
        const base64data = (img as HTMLCanvasElement).toDataURL('image/jpeg');
        setLocalAvatar({
          src: base64data,
          img: img as HTMLCanvasElement,
        });
      },
      {
        maxWidth: 600, maxHeight: 700, orientation: true, canvas: true,
      },
    );
  };

  return (
    <>
      <FileSelector onChange={onAvatarFileChange}>
        <MenuItem icon={<User size={16} />}>{t('Change avatar')}</MenuItem>
      </FileSelector>

      {!!localAvatar && (
        <AvatarModal
          channelId={channelId}
          image={localAvatar}
          close={() => setLocalAvatar(null)}
        />
      )}
    </>
  );
};

export default ChangeAvatarAction;
