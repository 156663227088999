import { useEffect, useState } from 'react';
import z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import api from 'state/api';
import useToast from 'hooks/useToast';
import { useTranslation } from 'hooks';

import locales from 'containers/Profiles/Checklist/i18n';

import { checklistSelectionsFormSchema, ChecklistStructure } from './ChecklistForm';

interface Props {
  defaultValues?: (payload?: unknown) => Promise<z.infer<typeof checklistSelectionsFormSchema>>;
}

export const useChecklistForm = ({ defaultValues }: Props = {}) => {
  const { t } = useTranslation(locales);
  const toast = useToast();

  const [checklistStructure, setChecklistStructure] = useState<ChecklistStructure | null>(null);
  const [isLoadingStructure, setIsLoadingSturcture] = useState(true);
  const [typeFilters, setTypeFilters] = useState<('TOP' | 'BOTTOM' | null)[]>(['TOP', 'BOTTOM', null]);

  const form = useForm<z.infer<typeof checklistSelectionsFormSchema>>({
    resolver: zodResolver(checklistSelectionsFormSchema),
    defaultValues,
  });

  useEffect(() => {
    (async () => {
      const { data } = await api.req!.get<ChecklistStructure>('/users/checklists');

      const sorted = data.sort((a, b) => a.category.order - b.category.order);
      setChecklistStructure(sorted);

      setIsLoadingSturcture(false);
    })();
  }, []);

  const onSubmit = async (data: z.infer<typeof checklistSelectionsFormSchema>) => {
    try {
      const selections = Object.entries(data.selections)
        .filter(([, { experience, interest }]) => experience && interest)
        .map(([id, { experience, interest }]) => ({
          item: id,
          experience,
          interest,
        }));

      await api.req!.put('/users/checklist/selections', { selections });

      form.reset(data);
      toast.success(t('Checklist updated'));
    } catch (error) {
      toast.error(error);
    }
  };

  return {
    form,
    checklistStructure,
    typeFilters,
    setTypeFilters,
    isLoadingStructure,
    onSubmit,
  };
};
