/* eslint-disable react/no-array-index-key */

import {
  Participants as ParticipanstWrapper, Participant, Avatar, Username,
} from './UI';
import generateParticipants from './generateParticipants';

const participants = generateParticipants(30);

const Participants = () => (
  <ParticipanstWrapper>
    {participants.map(({ avatarColor, usernameWidth }, index) => (
      <Participant key={`participant-${index}-${usernameWidth}`}>
        <Avatar width={24} color={avatarColor} />
        <Username width={usernameWidth} />
      </Participant>
    ))}
  </ParticipanstWrapper>
);

Participants.propTypes = {
};

Participants.defaultProps = {
};

export default Participants;
