import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useTranslation, useUploadMedia } from 'hooks';
import ComposerRef from 'utils/ComposerRef';
import * as appSelectors from 'state/app/selectors';
import * as replySelectors from 'state/replies/selectors';
import * as appActions from 'state/app/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Composer, { EmojiPicker } from 'components/Composer';
import CommunityUploadMedia, { DropZone, UploadMediaButton } from 'components/CommunityUploadMedia';

import locales from '../i18n';

const EditorWrapper = styled.div`
  max-width: 100%;
  min-height: 56px;
  display: flex;
  flex-direction: column;

  &:focus {
    outline: none;
  }

  .actions {
    margin-top: 16px;
    display: flex;

    > div, > button {
      margin-right: 8px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const EditModal = ({ close, replyId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const composerId = `editreply-${replyId}`;

  const rawContent = useSelector(replySelectors.getRawContent(replyId), shallowEqual);
  const media = useSelector(replySelectors.getMedia(replyId), shallowEqual);

  const canSubmit = useSelector(
    state => appSelectors.selectIsComposerReadyToSubmit(state, composerId),
  );
  const isSaving = useSelector(state => appSelectors.selectIsComposerSaving(state, composerId));
  const submitDisabled = !canSubmit || isSaving;

  const [saving, setSaving] = useState(false);

  const {
    getInputProps,
    isDragActive,
    open,
    handlePastedFiles,
  } = useUploadMedia(composerId, '/communities/upload');

  useEffect(() => {
    const ref = ComposerRef.getRef(composerId);
    if (ref) {
      ref.setValue(rawContent);
      dispatch(appActions.mediaUploadSet(composerId, media.images));
    }
  }, [rawContent, dispatch, composerId, media.images]);

  const edit = async () => {
    setSaving(true);
    await dispatch(appActions.composerSendToServer(composerId));

    close();
    dispatch(appActions.addToast(t('Reply edited')));
  };

  return (
    <Modal
      title={t('Edit reply')}
      onCancel={close}
      actions={[(
        <Button
          key="remove-reply"
          onClick={edit}
          loading={saving}
          disabled={submitDisabled}
        >
          {t('global:Confirm')}
        </Button>
      )]}
    >
      {isDragActive && (
        <DropZone>{t('global:Drop the files here...')}</DropZone>
      )}

      <EditorWrapper>
        <Composer
          id={composerId}
          placeholder={t('Enter your reply here')}
          height="250px"
          handlePastedFiles={handlePastedFiles}
          autofocus
        />

        <div className="actions">
          <EmojiPicker id={composerId} />
          <UploadMediaButton open={open} />
        </div>
      </EditorWrapper>
      <br />
      <CommunityUploadMedia
        id={composerId}
        open={open}
        getInputProps={getInputProps}
      />
    </Modal>
  );
};

EditModal.propTypes = {
  close: PropTypes.func.isRequired,
  replyId: PropTypes.string.isRequired,
};

export default EditModal;
