import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import qs from 'qs';
import { useDispatch } from 'react-redux';

import * as authActions from 'state/auth/actions';

import {
  useInputValue,
  useTranslation,
  useFocusOnMount,
  useTitle,
} from 'hooks';

import AuthLayout from 'components/AuthLayout';
import Input from 'components/Forms/InputSimple';
import Button from 'components/Button';
import Birthdate from 'components/ProfileForm/Birthdate';
import Pronouns from 'components/ProfileForm/Pronouns';
import Genders from 'components/ProfileForm/Genders';
import Countries from 'components/ProfileForm/Countries';
import Regions from 'components/ProfileForm/Regions';

import SectionTitle from './UI/SectionTitle';
import Row from './UI/Row';
import Error from './UI/Error';
import { GENDERS, USERS_PRONOUNS } from '../../constants';
import locales from './i18n';

const Signup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  useTitle(t('Create your account'));

  const queryparams = qs.parse(document.location.search, { ignoreQueryPrefix: true });

  const usernameEl = useFocusOnMount();
  const [signingUp, setSigningUp] = useState(false);
  const username = useInputValue(queryparams.username || '');
  const email = useInputValue(queryparams.email || '');
  const password = useInputValue('');
  const passwordconfirm = useInputValue('');
  const [gender, setGender] = useState(queryparams.gender ? {
    value: GENDERS[queryparams.gender],
    label: t(`global:GENDER.${queryparams.gender}`),
  } : null);
  const [pronoun, setPronoun] = useState(queryparams.pronoun ? {
    value: USERS_PRONOUNS[queryparams.pronoun],
    label: t(`global:PRONOUN.${queryparams.pronoun}`),
  } : null);
  const [birthdate, setBirthdate] = useState(null);

  const [country, setCountry] = useState(null); // TO DO: ADD DEFAULT WITH VERCEL MIDDLEWARE
  const [region, setRegion] = useState(null); // TO DO: ADD DEFAULT WITH VERCEL MIDDLEWARE

  const [tos, setTos] = useState(false);

  const [passwordConfirmError, setPasswordConfirmError] = useState(null);
  const [error, setError] = useState(null);

  const onTosChange = e => setTos(e.target.checked);

  const signup = async () => {
    try {
      setSigningUp(true);

      await dispatch(authActions.signup({
        username: username.value,
        password: password.value,
        gender: gender.value,
        email: email.value,
        pronoun: pronoun.value,
        birthdate: birthdate.toDate(),
        country,
        region,
      }));

      document.location.href = '/';
    } catch ({ ...err }) {
      setError(err.response.data.message);
      setSigningUp(false);
    }
  };

  const checkPasswordConfirmError = () => {
    if (passwordconfirm.value.length && passwordconfirm.value !== password.value) {
      setPasswordConfirmError(t('Passwords don\'t match'));
    } else {
      setPasswordConfirmError(null);
    }
  };

  const canRegister = (
    username.value && email.value && password.value && gender && pronoun && birthdate && tos
    && !passwordConfirmError && country
  );

  return (
    <AuthLayout
      title={t('Create your account')}
      subtitle={(
        <>
          {t('Already have an account?')}
          {' '}
          <Link to="/login">{t('Log in')}</Link>
        </>
      )}
    >
      <SectionTitle>{t('Your account')}</SectionTitle>
      <Input
        ref={usernameEl}
        placeholder={t('Username')}
        {...username}
        disabled={signingUp}
        withBorder
        error={(error && error.username) ? t(`global:${error.username}`) : null}
      />
      <Input
        placeholder={t('E-mail')}
        type="email"
        {...email}
        disabled={signingUp}
        withBorder
      />
      <Input
        placeholder={t('Password')}
        type="password"
        {...password}
        disabled={signingUp}
        onBlur={checkPasswordConfirmError}
        withBorder
      />
      <Input
        placeholder={t('Confirm password')}
        type="password"
        {...passwordconfirm}
        disabled={signingUp}
        error={passwordConfirmError}
        onBlur={checkPasswordConfirmError}
        withBorder
      />

      <SectionTitle>{t('About you')}</SectionTitle>

      <Birthdate birthdate={birthdate} setBirthdate={setBirthdate} />

      <Row>
        <Genders value={gender} onChange={setGender} />
      </Row>

      <Row>
        <Pronouns value={pronoun} onChange={setPronoun} />
      </Row>

      <Row>
        <Countries value={country} onChange={setCountry} simple />
      </Row>

      <Row>
        <Regions value={region} onChange={setRegion} countryId={country} simple />
      </Row>

      <Row>
        <Trans t={t} i18nKey="signup.tyc" ns="signup">
          <label htmlFor="signup-tos">
            <input type="checkbox" id="signup-tos" checked={tos} onChange={onTosChange} />
            I read and accept the
            {' '}
            <a href="/info/tos" target="_blank">terms and conditions</a>
          </label>
        </Trans>
      </Row>

      {error && !signingUp && typeof error === 'object' && Object.values(error).map(err => (
        <Error key={`error-${err}`}>{t(`global:${err}`)}</Error>
      ))}
      {error && !signingUp && typeof error === 'string' && (
        <Error key={`error-${error}`}>{t(`global:${error}`)}</Error>
      )}

      <Button
        onClick={signup}
        loading={signingUp}
        content={t('Register')}
        disabled={!canRegister}
      />
    </AuthLayout>
  );
};

export default Signup;
