import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import shortid from 'shortid';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import * as feedSelectors from 'state/feed/selectors';
import * as appActions from 'state/app/actions';

import ImageBox from 'components/ImageBox';
import { Arrow } from 'components/Icons';
import Link from 'components/ParsedContent/Link';

import { Box } from '@chakra-ui/react';
import Image from './Image';
import Funding from './Funding';
import SafeForWorkLayer from './SafeForWorkLayer';

const MediaContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const Counter = styled.div`
  background: rgba(0,0,0,0.5);
  position: absolute;
  top: 8px;
  right: 8px;
  color: #fff;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 16px;
  z-index: 1;
`;

const ImageSlideshow = styled.div.attrs(props => ({
  style: {
    left: `calc((-100% * ${props.position.index}) + ${props.position.offset}px)`,
    transition: props.position.offset !== 0 ? undefined : '0.7s',
  },
}))`
  position: relative;
  display: -webkit-box;
`;

const ImageContainer = styled(ImageBox)`
  background: black;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Button = styled.button`
  border: 0;
  padding: 0 8px;
  width: 48px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  height: 100%;
  opacity: 0;
  z-index: 20;

  @media(max-width: 768px) {
    display: none;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`;

const PrevButton = styled(Button)`
  left: 0;
  transform: rotate(-180deg);
`;

const NextButton = styled(Button)`
`;

const Media = ({ publicationId }) => {
  const dispatch = useDispatch();

  const data = useSelector(
    state => feedSelectors.publications.selectMedia(state, publicationId),
    shallowEqual,
  );
  const promoted = useSelector(
    state => feedSelectors.publications.selectPromoted(state, publicationId),
  );

  const container = useRef(null);
  const [position, setPosition] = useState({ index: 0, offset: 0 });

  const next = () => {
    setPosition(({ index }) => ({
      index: ((index + 1) % data.length),
      offset: 0,
    }));
    dispatch(appActions.sendActivity('feed_photo_slide'));
  };
  const prev = () => {
    setPosition(({ index }) => ({
      index: ((index - 1 + data.length) % data.length),
      offset: 0,
    }));
    dispatch(appActions.sendActivity('feed_photo_slide'));
  };

  useEffect(() => {
    const element = container.current;
    let x = null;
    let y = null;

    const touchcancel = () => {
      setPosition(({ index }) => ({ index, offset: 0 }));
    };
    const touchstart = (e) => {
      x = e.changedTouches[0].clientX;
      y = e.changedTouches[0].clientY;
    };
    const touchend = (e) => {
      const diffX = e.changedTouches[0].clientX - x;
      const diffY = e.changedTouches[0].clientY - y;

      if ((diffX < 50 && diffX > -50) || diffY > 300 || diffY < -300) {
        touchcancel();
      } else if (diffX > 0) {
        setPosition(({ index }) => {
          if (index === 0) return { index, offset: 0 };
          return { index: index - 1, offset: 0 };
        });
      } else if (diffX < 0) {
        setPosition(({ index }) => {
          if (index === data.length - 1) return { index, offset: 0 };
          return { index: index + 1, offset: 0 };
        });
      }
    };
    const touchmove = (e) => {
      setPosition(({ index }) => ({
        index,
        offset: e.changedTouches[0].clientX - x,
      }));
    };

    if (element && data.length > 1) {
      element.addEventListener('touchcancel', touchcancel, false);
      element.addEventListener('touchstart', touchstart, false);
      element.addEventListener('touchend', touchend, false);
      element.addEventListener('touchmove', touchmove, false);
    }

    return () => {
      if (element && data.length > 1) {
        element.removeEventListener('touchcancel', touchcancel, false);
        element.removeEventListener('touchstart', touchstart, false);
        element.removeEventListener('touchend', touchend, false);
        element.removeEventListener('touchmove', touchmove, false);
      }
    };
  }, [container, data.length]);

  const WrapperComponent = !promoted?.link?.url ? Box : Link;

  return (
    <MediaContainer ref={container}>
      <SafeForWorkLayer />
      {data.length > 1 && <Counter>{`${position.index + 1}/${data.length}`}</Counter>}

      <WrapperComponent href={promoted?.link?.url}>
        <ImageSlideshow position={position}>
          {data.map(({
            filename, metadata = {}, optimized, path,
          }) => (
            <ImageContainer key={filename || shortid.generate()}>
              <Image
                src={filename}
                alt={filename}
                width={metadata.width}
                height={metadata.height}
                optimized={optimized}
                path={path}
              />
            </ImageContainer>
          ))}
        </ImageSlideshow>
        {data.length > 1 && (
          <>
            <PrevButton onClick={prev}>
              <Arrow color="white" />
            </PrevButton>
            <NextButton onClick={next}>
              <Arrow color="white" />
            </NextButton>
          </>
        )}
      </WrapperComponent>

      <Funding publicationId={publicationId} />
    </MediaContainer>
  );
};

Media.propTypes = {
  publicationId: PropTypes.string.isRequired,
};

export default Media;
