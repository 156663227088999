import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';
import * as feedActions from 'state/feed/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';

import locales from '../i18n';

const RemoveModal = ({ close, publicationId, onRemove }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [removing, setRemoving] = useState(false);

  const remove = async () => {
    try {
      setRemoving(true);
      await dispatch(feedActions.remove(publicationId));
      dispatch(appActions.addToast(t('Publication removed')));
      if (onRemove) {
        onRemove(publicationId);
      }
      setRemoving(false);
      close();
    } catch (error) {
      dispatch(appActions.addError(error));
      setRemoving(false);
    }
  };

  return (
    <Modal
      title={t('Remove publication')}
      onCancel={close}
      actions={[
        <Button key="publication-remove" onClick={remove} loading={removing}>{t('global:Confirm')}</Button>,
      ]}
    >
      {t('Are you sure you want to remove this publication?')}
    </Modal>
  );
};

RemoveModal.propTypes = {
  close: PropTypes.func.isRequired,
  publicationId: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
};

RemoveModal.defaultProps = {
  onRemove: null,
};

export default RemoveModal;
