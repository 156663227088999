import {
  useCallback, useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import * as bankActions from 'state/bank/actions';

import Loading from 'components/Loading';

import Columns from './UI/Columns';
import PurchaseColumn from './UI/PurchaseColumn';
import { PAYMENTS_INTEGRATION_URL, SADES_PACKAGES } from '../../constants';

const Mercadopago = ({ done, coupon }) => {
  const paymentContainer = useRef(null);

  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (paymentContainer.current && order && order !== true) {
      const script = document.createElement('script');
      script.src = PAYMENTS_INTEGRATION_URL;
      script.setAttribute('data-preference-id', order.mercadopago.preference.id);
      script.onload = () => {
        const button = document.querySelector('.mercadopago-button');
        if (button) {
          button.click();
          done();
        }
      };
      paymentContainer.current.appendChild(script);
    }
  }, [order, paymentContainer, done]);

  const buy = useCallback(itemName => async () => {
    try {
      setOrder(true);
      const couponCode = coupon ? coupon.code : undefined;
      const data = await bankActions.createOrder(itemName, {}, 'MERCADOPAGO', couponCode);
      setOrder(data);
    } catch (error) {
      setOrder(null);
    }
  }, [coupon]);

  if (order === true) return <Loading />;
  if (order) return <div ref={paymentContainer} />;

  return (
    <Columns>
      {SADES_PACKAGES.map(({
        pesos, name, highlighted, amount,
      }) => (
        <PurchaseColumn
          key={`mp-${name}`}
          sades={amount}
          price={pesos}
          currency="pesos"
          coupon={coupon}
          onBuy={buy(name)}
          highlighted={highlighted}
        />
      ))}
    </Columns>
  );
};

Mercadopago.propTypes = {
  done: PropTypes.func.isRequired,
  coupon: PropTypes.shape({
    code: PropTypes.string.isRequired,
    increment: PropTypes.number,
    discount: PropTypes.number,
  }),
};

Mercadopago.defaultProps = {
  coupon: null,
};

export default Mercadopago;
