import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Icon } from '@chakra-ui/react';
import { Search } from 'lucide-react';

import { useTranslation } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';

import MembershipRequestButton from 'components/MembershipRequestButton';

import ImagesHeader from './ImagesHeader';
import NameAndDescription from './NameAndDescription';
import Counters from './Counters';
import Mods from './Mods';
import HeaderActions from './HeaderActions';
import locales from './i18n';

const Wrapper = styled.div`
  height: 100%;
  background-color: #F3F3F3;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Top = styled.div`
  flex-shrink: 0;
  padding: 16px 16px 0;
  position: relative;
`;

const Info = ({ communitySlug }) => {
  const { t } = useTranslation(locales);

  const communityId = useSelector(
    state => communitySelectors.selectIdBySlug(state, communitySlug),
  );

  return (
    <Wrapper>
      <HeaderActions communityId={communityId} />

      <Top>
        <ImagesHeader communityId={communityId} />
        <NameAndDescription communityId={communityId} />
        <Counters communityId={communityId} />
        <MembershipRequestButton communityId={communityId} canCancel />
      </Top>

      <Mods communityId={communityId} />

      <Button leftIcon={<Icon as={Search} />} as={Link} to={`/+${communitySlug}`}>{t('Go to threads list')}</Button>
    </Wrapper>
  );
};

Info.propTypes = {
  communitySlug: PropTypes.string.isRequired,
};

export default Info;
