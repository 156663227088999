import { Icon, IconProps } from '@chakra-ui/react';

const Spank: React.FC<IconProps> = ({ fill, borderColor, ...props }) => (
  <Icon viewBox="0 0 512 512" {...props} transform="rotate(-127deg)">
    <path fill={fill as string} d="M207.3,79.65V53.14c0-17.85,13.51-32.33,30.18-32.33h.71c16.67,0,30.18,14.47,30.18,32.32V99.36a33.314,33.314,0,0,1,8.82-22.21,29.145,29.145,0,0,1,21.35-9.47c16.66,0,30.17,14.46,30.19,32.31l.14,204.83,24.46-29.77c16.45-20.03,44.88-22.17,63.78-4.8l9.13,7.58L338.85,445.72c-14.61,28.07-42.26,45.47-72.26,45.47H168.58c-45.65,0-82.67-39.62-82.72-88.52-.08-87.09-.17-191.54-.03-249.85.04-17.82,13.54-32.24,30.18-32.24h.67c16.67,0,30.18,14.47,30.18,32.33V79.65c0-17.86,13.52-32.34,30.19-32.34h.07C193.79,47.31,207.3,61.79,207.3,79.65Z" />
    <path fill={borderColor as string} d="M168.58,499h98.01c32.968,0,63.376-18.978,79.356-49.681l87.39-167.843a7.966,7.966,0,0,0-1.985-9.825l-8.982-7.444a51.263,51.263,0,0,0-38.409-13.585,52.1,52.1,0,0,0-36.811,19.346L336.855,282.5,336.73,99.981c-.025-22.222-17.157-40.3-38.19-40.3a36.546,36.546,0,0,0-22.172,7.492l0-13.947C276.37,30.992,259.243,13,238.19,13h-.71c-19.134,0-35.022,14.85-37.76,34.269a36.6,36.6,0,0,0-22.6-7.911C156.062,39.358,139,57.406,139,79.65v40.465c-6-4.736-14.053-7.115-22.32-7.115h-.67c-21,0-38.13,17.833-38.18,40.011-.144,60.188-.044,169.624.03,249.677C77.915,455.905,118.611,499,168.58,499ZM93.83,153.048C93.86,139.672,103.81,129,116.01,129h.67c12.23,0,22.32,10.5,22.32,23.91V258.12a8,8,0,0,0,16,0V79.65c0-13.421,9.735-24.34,22.04-24.34,12.23,0,21.96,10.919,21.96,24.34V258.12a8,8,0,0,0,16,0V53.14C215,39.725,225.25,29,237.48,29h.71c12.23,0,22.18,10.815,22.18,24.225l0,46.1c-.011.287-.015.523-.015.712,0,.1.011.177.015.272l-.009,66.883c-.01,46.672-.02,86.917-.006,90.9a7.946,7.946,0,0,0,8,7.91h.027a7.94,7.94,0,0,0,7.973-7.967c-.014-3.956,0-44.22.006-90.877l.009-67.568c0-.035,0-.063,0-.1a25.265,25.265,0,0,1,6.664-16.881,21.133,21.133,0,0,1,15.507-6.932c12.221,0,22.175,10.911,22.19,24.317l.14,204.83a8,8,0,0,0,14.181,5.073l24.461-29.771a36.256,36.256,0,0,1,25.568-13.538A35.454,35.454,0,0,1,411.7,276.14c.1.091.2.179.3.265l4.164,3.457-84.41,162.07C318.539,467.322,293.569,483,266.59,483H168.58c-41.155,0-74.674-35.925-74.72-80.327C93.786,322.631,93.686,213.211,93.83,153.048Z" />
  </Icon>

);

export default Spank;
