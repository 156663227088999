import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as appActions from 'state/app/actions';
import * as communitySelectors from 'state/communities/selectors';
import * as membershipSelectors from 'state/memberships/selectors';
import * as membershipActions from 'state/memberships/actions';

import { useTranslation } from 'hooks';
import CommunityType from 'state/communities/type';

import Button from 'components/Button';
import Modal from 'components/Modal';

import locales from './i18n';
import { COMMUNITY_PRIVACIES } from '../../constants';

const Requested = styled.span`
  color: #6F6666;
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  display: block;
`;

const ActionButton = ({
  community,
  create,
  requestAccess,
  cancel,
  full,
}) => {
  const { t } = useTranslation(locales);
  const isLoading = useSelector(state => membershipSelectors.isLoading(state, community.slug));
  const hasApprovedMembership = useSelector(
    state => membershipSelectors.hasApprovedMembership(state, community._id),
  );
  const hasPendingMembership = useSelector(
    state => membershipSelectors.hasPendingMembership(state, community._id),
  );

  if (isLoading) {
    return <Button loading full={full} className="border small">...</Button>;
  }

  if (!cancel && hasApprovedMembership) {
    return <Requested>{t('Already a member')}</Requested>;
  }

  if (!cancel && hasPendingMembership) {
    return <Requested>{t('Pending membership')}</Requested>;
  }

  if (cancel && hasApprovedMembership) {
    return <Button className="empty small" onClick={cancel} textOnHover={t('Cancel membership')}>{t('Already a member')}</Button>;
  }

  if (cancel && hasPendingMembership) {
    return <Button className="empty small" onClick={cancel} textOnHover={t('Cancel membership request')}>{t('Pending membership')}</Button>;
  }

  if (community.privacy === COMMUNITY_PRIVACIES.PRIVATE) {
    return (
      <Button
        full={full}
        className="border small"
        onClick={requestAccess}
      >
        {t('Request Access')}
      </Button>
    );
  }

  return (
    <Button
      full={full}
      onClick={create}
      className="small"
    >
      {t('Enter')}
    </Button>
  );
};

ActionButton.propTypes = {
  community: CommunityType.isRequired,
  create: PropTypes.func.isRequired,
  requestAccess: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  full: PropTypes.bool.isRequired,
};

ActionButton.defaultProps = {
  cancel: null,
};

const MembershipRequestButton = ({
  communityId,
  canCancel,
  full,
  onClick,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const community = useSelector(
    state => communitySelectors.selectById(state, communityId),
    shallowEqual,
  );
  const membership = useSelector(
    state => membershipSelectors.selectByCommunityId(state, communityId),
    shallowEqual,
  );

  const [requestAccessModal, setRequestAccessModal] = useState(false);
  const [cancelMembershipModal, setCancelMembershipModal] = useState(false);

  const closeRequestAccessModal = () => setRequestAccessModal(false);
  const openRequestAccessModal = () => setRequestAccessModal(true);
  const createMembershipClicked = async () => {
    closeRequestAccessModal();
    try {
      await dispatch(membershipActions.create(community));
      if (onClick) onClick();
    } catch (err) {
      dispatch(appActions.addError(err.message));
    }
  };
  const openCancelMembershipModal = () => setCancelMembershipModal(true);
  const closeCancelMembershipModal = () => setCancelMembershipModal(false);
  const cancelMembershipClicked = async () => {
    closeCancelMembershipModal();
    try {
      await dispatch(membershipActions.del(membership));
      if (onClick) onClick();
    } catch (err) {
      dispatch(appActions.addError(err.message));
    }
  };

  return (
    <>
      <ActionButton
        community={community}
        create={createMembershipClicked}
        requestAccess={openRequestAccessModal}
        cancel={canCancel ? openCancelMembershipModal : null}
        full={full}
      />

      {requestAccessModal && (
        <Modal
          title={t('Request access to this community?')}
          onCancel={closeRequestAccessModal}
          actions={[
            <Button key="explore-request-access" onClick={createMembershipClicked}>{t('Request Access')}</Button>,
          ]}
        >
          {t('You need access to this community in order to view it\'s content.')}
        </Modal>
      )}
      {cancelMembershipModal && (
        <Modal
          title={t('Cancel membership')}
          onCancel={closeCancelMembershipModal}
          actions={[
            <Button key="explore-request-access" onClick={cancelMembershipClicked}>{t('global:Confirm')}</Button>,
          ]}
        >
          <Trans t={t} ns="membershiprequestbutton" i18nKey="cancelMembershipConfirm" values={{ name: community.name }}>
            <span>Are you sure you want to cancel your membership to this </span>
            <strong>{community.name}</strong>
            <span>?</span>
          </Trans>
        </Modal>
      )}
    </>
  );
};

MembershipRequestButton.propTypes = {
  communityId: PropTypes.string.isRequired,
  canCancel: PropTypes.bool,
  full: PropTypes.bool,
  onClick: PropTypes.func,
};

MembershipRequestButton.defaultProps = {
  canCancel: false,
  full: true,
  onClick: null,
};

export default MembershipRequestButton;
