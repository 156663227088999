import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, ButtonGroup } from '@chakra-ui/react';

import formatNumber from 'utils/formatNumber';
import { useOpenClose, useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import Counter from './Counter';
import KnownBy from './Modals/KnownBy';
import Events from './Modals/Events';
import Followers from './Modals/Followers';
import Following from './Modals/Following';

import locales from '../i18n';

const Counters = ({ userId }) => {
  const { t } = useTranslation(locales);

  const isOrganization = useSelector(userSelectors.isOrganization(userId));
  const known = useSelector(userSelectors.getKnown(userId));
  const eventCount = useSelector(userSelectors.getEventCount(userId));
  const followers = useSelector(userSelectors.getFollowers(userId));
  const following = useSelector(userSelectors.getFollowing(userId));

  const [showingKnownBy, openKnownBy, closeKnownBy] = useOpenClose();
  const [showingEvents, openEvents, closeEvents] = useOpenClose();
  const [showingFollowers, openFollowers, closeFollowers] = useOpenClose();
  const [showingFollowing, openFollowing, closeFollowing] = useOpenClose();

  return (
    <Box m={0}>
      <ButtonGroup spacing={{ base: 2, md: 8 }}>
        {!isOrganization && <Counter label={t('global:Known by')} value={formatNumber(known)} onClick={openKnownBy} />}
        {!isOrganization && <Counter label={t('global:Events')} value={formatNumber(eventCount)} onClick={openEvents} />}
        <Counter label={t('global:Followers')} value={formatNumber(followers)} onClick={openFollowers} />
        <Counter label={t('global:Following')} value={formatNumber(following)} onClick={openFollowing} />
      </ButtonGroup>

      {/* Modals */}
      {showingKnownBy && <KnownBy userId={userId} close={closeKnownBy} />}
      {showingEvents && <Events userId={userId} close={closeEvents} />}
      {showingFollowers && <Followers userId={userId} close={closeFollowers} />}
      {showingFollowing && <Following userId={userId} close={closeFollowing} />}
    </Box>
  );
};

Counters.propTypes = {
  userId: PropTypes.number.isRequired,
};

Counters.defaultProps = {
};

export default Counters;
