import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const Know = ({ timestamp, read, ...params }) => {
  const { t } = useTranslation(locales);

  const userId = params.user ? params.user.id : params.userId;
  const username = useSelector(userSelectors.getUsername(userId));

  return (
    <AlertContainer timestamp={timestamp} image={<UserAvatar userId={userId} size="40px" showOnline={false} />} to={`/@${username}`} read={read}>
      <strong><UserDisplayName userId={userId} /></strong>
      {' '}
      {t('indicated that he knows you')}
    </AlertContainer>
  );
};

Know.propTypes = {
  read: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default Know;
