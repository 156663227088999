const presentacion = `¡Hola!


<!-- Puedes editar este texto para hacer tu presentación de la forma que mejor te identifique. Todos los bloques encerrados en este formato son comentarios y no se mostrarán en tu presentación final. Siéntete libre de modificarlos o eliminarlos. -->


## Quién soy
<!-- Aquí puedes compartir alguna información personal sobre tí: edad, lugar de procedencia, orientación sexual... -->

## Mis kinks
<!-- Aquí puedes contar cuál es tu rol (en caso de que lo tengas) y tus gustos con respecto a la sexualidad: fetiches, prácticas, morbos... -->

## Cómo conocí Mazmo
<!-- Aquí puedes contar brevemente cómo llegaste al sitio y cómo te enteraste de su existencia. -->

## Me registré porque...
<!-- Aquí puedes completar con tus razones para estar en Mazmo: informarte, conocer gente, entretenerte, estar al tanto de Eventos, etc. -->

## Otros intereses
<!-- Aquí puedes completar con otros intereses que tengas: arte, literatura, música, ciencias, política... -->


¡Saludos!`;

const getTemplate = (name) => {
  switch (name) {
    case 'presentacion':
      return presentacion;
    default:
      return '';
  }
};

export default getTemplate;
