import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import z from 'zod';
import { UseFormRegister } from 'react-hook-form';

import { useTranslation } from 'hooks';

import locales from 'containers/Profiles/Checklist/i18n';

export type ChecklistItem = {
  id: string;
  name: string;
  relatesTo: string;
  order: number;
  type: 'TOP' | 'BOTTOM' | null;
  inShortVersion: boolean;
};

export type ChecklistStructure = {
  category: {
    id: string;
    name: string;
    order: number;
  },
  items: ChecklistItem[],
}[];

export enum Experience {
  EMPTY = '',
  NONE = 'NONE',
  LITTLE = 'LITTLE',
  SOME = 'SOME',
  LOT = 'LOT',
}

export enum Interest {
  EMPTY = '',
  HARD_LIMIT = 'HARD_LIMIT',
  SOFT_LIMIT = 'SOFT_LIMIT',
  NONE = 'NONE',
  LITTLE = 'LITTLE',
  SOME = 'SOME',
  LOT = 'LOT',
}

export const checklistSelectionsFormSchema = z.object({
  selections: z.record(z.string(), z.object({
    interest: z.nativeEnum(Interest).optional(),
    experience: z.nativeEnum(Experience).optional(),
  })),
});

interface Props {
  checklistStructure: ChecklistStructure | null;
  itemFilter: (item: ChecklistItem) => boolean;
  register: UseFormRegister<z.infer<typeof checklistSelectionsFormSchema>>;
}

const ChecklistForm: React.FC<Props> = ({ checklistStructure, itemFilter, register }) => {
  const { t } = useTranslation(locales);

  const cateogries: ChecklistStructure = [];
  checklistStructure?.forEach(({ category, items }) => {
    const filteredItems = items.filter(itemFilter);
    if (filteredItems.length) {
      cateogries.push({ category, items: filteredItems });
    }
  });

  return (
    <Accordion allowToggle defaultIndex={0}>
      {cateogries?.map(({ category, items }) => (
        <AccordionItem key={category.id}>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="semibold">
                {t(category.name)}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box fontSize="sm">
              <HStack
                justifyContent="flex-end"
                fontWeight="500"
                color="gray.500"
                display={{ base: 'none', md: 'flex' }}
                textTransform="uppercase"
                mb={1}
                px={2}
              >
                <Box w="115px">{t('Experience')}</Box>
                <Box w="115px">{t('Interest')}</Box>
              </HStack>
              {items.filter(itemFilter).map((item, index) => (
                <Stack
                  key={item.id}
                  bgColor={!(index % 2) ? 'gray.100' : 'transparent'}
                  px={2}
                  py={4}
                  direction={{ base: 'column', md: 'row' }}
                  justifyContent={{ base: 'initial', md: 'space-between' }}
                  alignItems={{ base: 'stretch', md: 'center' }}
                >
                  <Box>{t(item.name)}</Box>
                  <Stack justifyContent="flex-end" color="gray.400" fontSize="xs" direction={{ base: 'column', md: 'row' }}>
                    <HStack justifyContent="space-between">
                      <Text display={{ base: 'block', md: 'none' }}>
                        {t('Experience')}
                        :
                      </Text>
                      <Select defaultValue={Experience.EMPTY} {...register(`selections.${item.id}.experience`)} size="xs" w="115px">
                        <option value={Experience.EMPTY} />
                        <option value={Experience.NONE}>{t('EXPERIENCE.NONE')}</option>
                        <option value={Experience.LITTLE}>{t('EXPERIENCE.LITTLE')}</option>
                        <option value={Experience.SOME}>{t('EXPERIENCE.SOME')}</option>
                        <option value={Experience.LOT}>{t('EXPERIENCE.LOT')}</option>
                      </Select>
                    </HStack>
                    <HStack justifyContent="space-between">
                      <Text display={{ base: 'block', md: 'none' }}>
                        {t('Interest')}
                        :
                      </Text>
                      <Select defaultValue={Interest.EMPTY} {...register(`selections.${item.id}.interest`)} size="xs" w="115px">
                        <option value={Interest.EMPTY} />
                        <option value={Interest.HARD_LIMIT}>{t('INTEREST.HARD_LIMIT')}</option>
                        <option value={Interest.SOFT_LIMIT}>{t('INTEREST.SOFT_LIMIT')}</option>
                        <option value={Interest.NONE}>{t('INTEREST.NONE')}</option>
                        <option value={Interest.LITTLE}>{t('INTEREST.LITTLE')}</option>
                        <option value={Interest.SOME}>{t('INTEREST.SOME')}</option>
                        <option value={Interest.LOT}>{t('INTEREST.LOT')}</option>
                      </Select>
                    </HStack>
                  </Stack>
                </Stack>
              ))}
            </Box>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default ChecklistForm;
