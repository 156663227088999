import { schema } from 'normalizr';

import user from 'state/users/schema';

export const directmessageSchema = new schema.Entity('directmessages', {
  author: user,
  messenger: {
    participants: [{
      user,
    }],
  },
});

export const messengerSchema = new schema.Entity('messengers', {
  participants: [{
    user,
  }],
  lastMessage: directmessageSchema,
});
