import { createSelector } from 'reselect';
import moment from 'moment';
import getUrls from 'get-urls';
import ReactPlayer from 'react-player';

import * as communitySelectors from 'state/communities/selectors';

// Input selectors
const selectFullyLoaded = state => state.threads.fullyLoaded;

const selectData = state => state.threads.data;
//

export const selectById = createSelector(
  selectData,
  (_, id) => id,
  (data, id) => data[id],
);

export const isCommunityFullyLoaded = createSelector(
  selectFullyLoaded,
  (_, communityId) => communityId,
  (data, communityId) => data.includes(communityId),
);

export const getAll = () => ({ threads: state }) => state.data;

export const get = id => ({ threads: state }) => state.data[id];

export const getById = id => ({ threads: state }) => state.data[id];

export const getBySlug = slug => ({ threads: state }) => (
  Object.values(state.data).find(t => t.slug === slug)
);

export const getIdBySlug = slug => ({ threads: state }) => {
  const thread = Object.values(state.data).find(t => t.slug === slug);
  if (!thread) return null;
  return thread._id;
};

const sortThreads = pinnedMatters => (a, b) => {
  if (pinnedMatters) {
    if (a.pinned && !b.pinned) return -1;
    if (!a.pinned && b.pinned) return 1;
  }

  return moment(b.lastReply.at).diff(a.lastReply.at);
};

export const getRecent = () => state => state.threads.recent
  .map(tId => state.threads.data[tId])
  .sort(sortThreads(false))
  .map(t => t._id);

export const getList = communitySlug => (state) => {
  if (!communitySlug || communitySlug === 'recent') return getRecent()(state);

  const community = communitySelectors.selectBySlug(state, communitySlug);
  if (!community) return [];

  return community.threads
    .map(tId => state.threads.data[tId])
    .filter(t => !!t)
    .sort(sortThreads(true))
    .map(t => t.id);
};

export const listCount = communitySlug => (state) => {
  if (!communitySlug || communitySlug === 'recent') return state.threads.recent.length;
  const community = communitySelectors.selectBySlug(state, communitySlug);
  if (!community) return 0;
  return community.threads.length;
};

export const isCreating = () => ({ threads: state }) => state.creating;

export const isLoading = id => ({ threads: state }) => state.loading.includes(id || 'recent');

export const isFullyLoaded = id => ({ threads: state }) => state.fullyLoaded.includes(id || 'recent');

export const hasUnread = () => (state) => {
  const { recent } = state.threads;

  return recent.some((threadId) => {
    const thread = state.threads.data[threadId];
    const lastUpdate = moment(thread.lastReply.at).unix();
    const lastRead = thread.readAt ? moment(thread.readAt).unix() : 0;

    return lastUpdate > lastRead;
  });
};

export const getCommunityId = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread) return null;
  return thread.community;
};

export const hasEvent = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread) return false;
  return !!thread.event;
};

export const getTitle = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread) return null;
  return thread.title;
};

export const getSlug = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  return thread.slug;
};

export const getUrl = threadId => (state) => {
  const { threads } = state;
  const thread = threads.data[threadId];
  if (!thread) return null;

  const communitySlug = communitySelectors.selectSlugById(state, thread.community);
  if (!communitySlug) return null;

  return `+${communitySlug}/${thread.slug}`;
};

// TODO: Deprecate in favor of getReplyCount - Bad naming
export const getCommentCount = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  return thread.replyCount;
};

export const getReplyCount = threadId => state => getCommentCount(threadId)(state);

export const hasReplies = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  return thread.replyCount > 0;
};

export const getLastReplyAuthorId = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  return thread.lastReply.user;
};

export const getAuthorId = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread) return null;
  return thread.author;
};

export const getLastReplyAt = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  return thread.lastReply.at;
};

export const getCreatedAt = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread) return null;
  return thread.createdAt;
};

export const isDeleted = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  return !!thread.deletedAt;
};

export const isUnread = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  const lastUpdate = moment(thread.lastReply.at).unix();
  const lastRead = thread.readAt ? moment(thread.readAt).unix() : 0;

  return lastUpdate > lastRead;
};

export const getLastRead = threadId => ({ threads }) => threads.data[threadId].readAt;

export const isLikedByUser = threadId => ({ threads, auth }) => {
  const thread = threads.data[threadId];
  if (!thread || !thread.reactedByUserIds || !auth.me) return false;

  return thread.reactedByUserIds.some(uId => uId === auth.me.id);
};

export const isDislikedByUser = threadId => ({ threads, auth }) => {
  const thread = threads.data[threadId];
  if (!thread || !thread.dislikedByUserIds || !auth.me) return false;

  return thread.dislikedByUserIds.some(uId => uId === auth.me.id);
};

export const getReactionsCount = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread || !thread.reactedByUserIds) return 0;

  return thread.reactionCount;
};

export const getLikesCount = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread || !thread.reactedByUserIds) return 0;

  return thread.reactedByUserIds.length;
};

export const getDislikesCount = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread || !thread.dislikedByUserIds) return 0;

  return thread.dislikedByUserIds.length;
};

export const selectReactionsList = createSelector(
  selectById,
  thread => thread?.reactedByUserIds || [],
);

export const selectDislikesList = createSelector(
  selectById,
  thread => thread?.dislikedByUserIds || [],
);

export const getAwards = createSelector(
  selectById,
  thread => thread?.awards || [],
);

export const isPinned = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  return thread.pinned;
};

export const isClosed = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  return thread.closed && thread.closed.at;
};

export const getRawContent = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread) return null;
  return thread.rawContent;
};

export const hasOldContent = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread) return false;
  return !!thread.content;
};

export const getContentForEditor = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread) return null;
  return thread.editorContent;
};

export const getMedia = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread) return null;
  return thread.media;
};

export const getVideoUrls = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread) return null;
  return [...getUrls(thread.rawContent || '')].filter(url => ReactPlayer.canPlay(url));
};

export const getLastLoadedThreadDate = communityId => (state) => {
  const { communities, threads } = state;
  const slug = communityId === 'recent' ? 'recent' : communities.data[communityId].slug;
  const ids = getList(slug)(state);
  if (!ids || !ids.length) return null;
  return threads.data[ids[ids.length - 1]].lastReply.at;
};

export const slugIsLoading = threadSlug => state => (
  state.threads.bySlug.loading.includes(threadSlug)
);

export const slugIsNotFound = threadSlug => state => (
  state.threads.bySlug.notFound.includes(threadSlug)
);

export const getEventId = threadId => ({ threads }) => {
  const thread = threads.data[threadId];
  if (!thread || !thread.event) return null;

  return thread.event;
};

export const getThreadUrlByEventId = eventId => ({ threads, communities }) => {
  const thread = Object.values(threads.data).find(t => t.eventId === eventId);
  if (!thread) return null;

  const community = communities.data[thread.community];
  return `/+${community.slug}/${thread.slug}`;
};
