import React, { useCallback } from 'react';
import {
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as userActions from 'state/users/actions';
import * as userSelectors from 'state/users/selectors';

import UserList from 'components/UserList';

import locales from '../../i18n';

interface Props {
  userId: number;
  close: () => void;
}

const KnownBy: React.FC<Props> = ({ userId, close: onClose }) => {
  const { t } = useTranslation(locales);
  const pronoun = useSelector(userSelectors.getPronoun(userId));

  const fetchAction = useCallback(() => userActions.getKnowing(userId), [userId]);

  return (
    <Modal isOpen onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('Known by', { context: pronoun })}</ModalHeader>
        <ModalBody>
          <UserList fetchAction={fetchAction} onUserClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default KnownBy;
