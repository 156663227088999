import {
  useCallback, useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import * as bankActions from 'state/bank/actions';

import Loading from 'components/Loading';

import { SADES_PACKAGES } from 'constants';
import Columns from './UI/Columns';
import PurchaseColumn from './UI/PurchaseColumn';

const PayPal = ({ done, coupon }) => {
  const paymentContainer = useRef(null);

  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (paymentContainer.current && order && order !== true) {
      const script = document.createElement('script');
      script.src = 'https://www.paypal.com/sdk/js?client-id=ATGYwzCgJUCX-SUs9T--gGCa4lK_6YXX8fDu39u3zy24ApFaOVI-qvUt51W2v9Lq61wSIKbCAvRrUt-J';
      script.onload = () => {
        // eslint-disable-next-line no-undef
        paypal.Buttons({
          createOrder() {
            return order.paypal.order.id;
          },
          onApprove: async () => {
            const orderId = order.id;
            setOrder(true);
            await bankActions.capturePaypalOrder(orderId);
            done(true);
          },
        }).render('#paypal-button-container');
      };
      paymentContainer.current.appendChild(script);
    }
  }, [order, paymentContainer, done]);

  const buy = useCallback(itemName => async () => {
    try {
      setOrder(true);
      const couponCode = coupon ? coupon.code : undefined;
      const data = await bankActions.createOrder(itemName, {}, 'PAYPAL', couponCode);
      setOrder(data);
    } catch (error) {
      setOrder(null);
    }
  }, [coupon]);

  if (order === true) return <Loading />;
  if (order) {
    return (
      <div>
        <div ref={paymentContainer} />
        <div id="paypal-button-container" />
      </div>
    );
  }

  return (
    <Columns>
      {SADES_PACKAGES.map(({
        usd, name, highlighted, amount,
      }) => (
        <PurchaseColumn
          key={`paypal-${name}`}
          sades={amount}
          price={usd}
          currency="usd"
          coupon={coupon}
          onBuy={buy(name)}
          highlighted={highlighted}
        />
      ))}
    </Columns>
  );
};

PayPal.propTypes = {
  done: PropTypes.func.isRequired,
  coupon: PropTypes.shape({
    code: PropTypes.string.isRequired,
    increment: PropTypes.number,
    discount: PropTypes.number,
  }),
};

PayPal.defaultProps = {
  coupon: null,
};

export default PayPal;
