import { Link } from 'react-router-dom';

import { Pencil } from 'components/Icons';
import { Action } from 'components/Header';

const ActionsOwn = () => (
  <Action><Link to="/user/edit/profile"><Pencil color="white" /></Link></Action>
);

ActionsOwn.propTypes = {
};

ActionsOwn.defaultProps = {
};

export default ActionsOwn;
