import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'utils/css/colors';

const Secondary = styled.h2`
  color: ${colors.h1};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  margin: 0;
  text-align: center;
  padding: 24px;
`;

Secondary.propTypes = {
  mobileOnly: PropTypes.bool,
};

Secondary.defaultProps = {
  mobileOnly: false,
};

export default Secondary;
