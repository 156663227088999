import { Heading, HeadingProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends HeadingProps {
  children: React.ReactNode;
}

const H2: React.FC<Props> = ({ children, size = 'lg', ...props }) => (
  <Heading as="h2" lineHeight={size} size={size} mb={4} {...props}>
    {children}
  </Heading>
);

export default H2;
