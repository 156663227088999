import { MenuDivider, MenuItem, useDisclosure } from '@chakra-ui/react';
import { UserPlus } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';

import locales from '../../../i18n';
import InvitesModal from '../../Modals/InvitesModal';
import AddInviteModal from '../../Modals/AddInviteModal';

interface Props {
}

const InvitesAction: React.FC<Props> = () => {
  const { t } = useTranslation(locales);
  const { chatId: channelId } = useParams<{ chatId: string }>();

  const { isOpen: isInvitesModalOpen, onOpen: openInvitesModal, onClose: closeInvitesModal } = useDisclosure();
  const { isOpen: isAddInviteModalOpen, onOpen: openAddInviteModal, onClose: closeAddInviteModal } = useDisclosure();

  const isMod = useSelector((state) => (
    // @ts-ignore
    channelSelectors.isModOfChannel(state, channelId)
  ));

  if (!isMod) return null;

  return (
    <>
      <MenuItem icon={<UserPlus size={16} />} onClick={openInvitesModal}>{t('Invites')}</MenuItem>
      <MenuDivider />

      {isInvitesModalOpen && (
        <InvitesModal
          channelId={channelId}
          close={closeInvitesModal}
          openAddInvite={openAddInviteModal}
        />
      )}

      <AddInviteModal
        channelId={channelId}
        isOpen={isAddInviteModalOpen}
        onClose={closeAddInviteModal}
      />
    </>
  );
};

export default InvitesAction;
