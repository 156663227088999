import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { useTranslation } from 'hooks';

import Input from 'components/Forms/Input';

import locales from './i18n';

const Confirmation = styled.div`
  margin: 20px 0;
  color: #666;

  span {
    color: black;
    font-weight: bold;
  }
`;

interface Props {
  warning: string;
  validString: string;
  onEnabledChange: (enabled: boolean) => void;
}

const DeleteConfirmation: React.FC<Props> = ({ warning, validString, onEnabledChange }) => {
  const { t } = useTranslation(locales);

  const [validationString, setValidationString] = useState('');

  const onValidationStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidationString(event.target.value);
    if (event.target.value === validString) onEnabledChange(true);
    else onEnabledChange(false);
  };

  return (
    <>
      <span>{warning}</span>
      <Trans t={t} i18nKey="delete.confirmation" ns="deleteConfirmation" values={{ validString }}>
        <Confirmation>
          To confirm, please enter
          {' '}
          <span>{validString}</span>
          {' '}
          below
        </Confirmation>
      </Trans>
      <Input
        value={validationString}
        onChange={onValidationStringChange}
      />
    </>
  );
};

DeleteConfirmation.propTypes = {
  warning: PropTypes.string.isRequired,
  validString: PropTypes.string.isRequired,
  onEnabledChange: PropTypes.func.isRequired,
};

DeleteConfirmation.defaultProps = {
};

export default DeleteConfirmation;
