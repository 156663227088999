import { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
  Text,
  Box,
  SlideFade,
} from '@chakra-ui/react';

import { useTranslation } from 'hooks';

import Toggle from 'components/Toggle';
import HelpHint from 'components/HelpHint';

import locales from '../i18n';

const Funding = ({ funding, setFunding }) => {
  const { t } = useTranslation(locales);

  const changeFunding = useCallback(e => (
    setFunding(e.target.checked ? { amount: 10, isTarget: false } : null)
  ), [setFunding]);
  const changeAmount = useCallback((nv) => setFunding(cv => (
    { ...cv, amount: parseInt(nv, 10) }
  )), [setFunding]);
  const changeIsTarget = useCallback(e => setFunding(cv => (
    { ...cv, isTarget: e.target.checked }
  )), [setFunding]);

  return (
    <Box>
      <HStack justifyContent="space-between">
        <Toggle position="left" label={t('Sell this content for Sades')} active={!!funding} onChange={changeFunding} />
        <HelpHint
          content={(
            <Box>
              <Text mb={2}>{t('funding.line1')}</Text>
              <Text mb={2}>{t('funding.line2')}</Text>
              <Text mb={2}>{t('funding.line3')}</Text>
            </Box>
          )}
        />
      </HStack>

      <SlideFade in={!!funding}>
        <Box>
          <HStack my={2}>
            <Text noOfLines={1}>{t('Sell for')}</Text>
            <NumberInput
              defaultValue={funding ? funding.amount : 10}
              onChange={changeAmount}
              min={1}
            >
              <NumberInputField w={24} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text>{t('global:Sades')}</Text>
          </HStack>

          <Box mt={2}>
            <Toggle position="left" label={t('Make it crowdfunded')} active={!!funding && funding.isTarget} onChange={changeIsTarget} />
          </Box>
        </Box>
      </SlideFade>
    </Box>
  );
};

Funding.propTypes = {
  funding: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    isTarget: PropTypes.bool.isRequired,
  }),
  setFunding: PropTypes.func.isRequired,
};

Funding.defaultProps = {
  funding: null,
};

export default Funding;
