import { useState } from 'react';
import { UserPlus } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { MenuItem, Icon, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import useToast from 'hooks/useToast';
import * as communityActions from 'state/communities/actions';
import * as communitySelectors from 'state/communities/selectors';

import SearchUsersModal from 'components/SearchUsersModal';

import locales from '../i18n';

interface Props {
}

const InviteMenuItem: React.FC<Props> = () => {
  const { t } = useTranslation(locales);
  const toast = useToast();
  const dispatch = useDispatch();

  const { communitySlug } = useParams<{ communitySlug: string }>();
  // @ts-ignore
  const communityId = useSelector((state) => communitySelectors.selectIdBySlug(state, communitySlug));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sending, setSending] = useState(false);

  const onConfirm = async (userId: number) => {
    try {
      setSending(true);
      await dispatch(communityActions.sendInvites(communityId, [userId]));
      toast.success(t('Invitation sent'));

      onClose();
    } catch (error) {
      toast.error(error);
    }
    setSending(false);
  };

  return (
    <>
      <MenuItem icon={<Icon as={UserPlus} />} onClick={onOpen}>{t('Invite users')}</MenuItem>

      {isOpen && <SearchUsersModal title={t('Search Users')} close={onClose} confirm={onConfirm} confirmLoading={sending} />}
    </>
  );
};

export default InviteMenuItem;
