import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import ChannelType from 'state/channels/type';

import Button from 'components/Button';
import ChannelMembershipRequestButton from 'components/ChannelMembershipRequestButton';

import locales from '../../i18n';

const JoinButton = ({ channel }) => {
  const { t } = useTranslation(locales);
  const userIsLoggedIn = useSelector(authSelectors.loggedIn);

  if (!userIsLoggedIn) return <Button to="/signup" style={{ marginTop: '20px' }}>{t('global:Join')}</Button>;

  return <ChannelMembershipRequestButton channel={channel} style={{ marginTop: '20px' }} full={false} />;
};

JoinButton.propTypes = {
  channel: ChannelType.isRequired,
};

JoinButton.defaultProps = {
};

export default JoinButton;
