import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';

import Avatar from 'components/ChannelAvatar';
import { SelectableListItem } from 'components/SelectableList';

const Channel = ({ id, active, onSelect }) => {
  const name = useSelector(state => channelSelectors.getName(state, id));
  const avatar = useSelector(state => channelSelectors.getAvatar(state, id));

  return (
    <SelectableListItem
      avatar={<Avatar image={avatar} size="24px" />}
      title={name}
      onClick={onSelect(id)}
      active={active}
    />
  );
};

Channel.propTypes = {
  id: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

Channel.defaultProps = {
};

export default Channel;
