import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import * as messengerSelectors from 'state/messengers/selectors';

import ChatStateIcon from 'components/ChatStateIcon';

const ChatState = ({ messageId }) => {
  const state = useSelector(s => messengerSelectors.selectMessageState(s, messageId));

  if (!state) return null;
  return <ChatStateIcon state={state} />;
};

ChatState.propTypes = {
  messageId: PropTypes.string.isRequired,
};

ChatState.defaultProps = {
};

export default ChatState;
