import { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';
import loadImage from 'blueimp-load-image';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';
import * as communityActions from 'state/communities/actions';
import * as appActions from 'state/app/actions';

import CommunityHeader from 'containers/Communities/Header';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import FileSelector from 'components/FileSelector';
import {
  FlexWrapper,
  FlexInnerWrapper,
  FlexContainer,
  ActionsFooter,
} from 'components/FlexWrapper';

import locales from './i18n';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 48px);
  overflow: hidden;
`;
ContentWrapper.displayName = 'ContentWrapper';

const ContainerWrapper = styled.div`
  flex: 1;
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
`;
ContainerWrapper.displayName = 'ContainerWrapper';

const Container = styled.div`
  margin: 24px;
  flex: 1;
  display: flex;
  align-items: center;
  text-align: center;
  align-self: center;
`;
Container.displayName = 'Container';

const ButtonWrapper = styled.div`
  flex-shrink: 0;
`;
ButtonWrapper.displayName = 'ButtonWrapper';

const CropWrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .ReactCrop__image {
    max-height: inherit;
  }
`;

const Content = ({ communityId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  const history = useHistory();

  const imageDOM = useRef(null);
  const slug = useSelector(state => communitySelectors.selectSlugById(state, communityId));
  const [image, setImage] = useState(null);

  const [saving, setSaving] = useState(false);

  const onChange = useCallback((e) => {
    loadImage(
      e.target.files[0],
      (img) => {
        const base64data = img.toDataURL('image/jpeg');
        setImage({
          src: base64data,
          img,
        });
      },
      { orientation: true, canvas: true },
    );
  }, []);

  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 160,
    height: 50,
    aspect: 5 / 2,
  });

  const onSubmitClick = useCallback(async () => {
    const getCroppedImg = (img, c, fileName) => {
      const canvas = document.createElement('canvas');
      const scaleX = img.naturalWidth / img.width;
      const scaleY = img.naturalHeight / img.height;
      canvas.width = c.width;
      canvas.height = c.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        img,
        c.x * scaleX,
        c.y * scaleY,
        c.width * scaleX,
        c.height * scaleY,
        0,
        0,
        c.width,
        c.height,
      );

      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(t('global:Something went wrong'));
          } else {
            // eslint-disable-next-line no-param-reassign
            blob.name = fileName;
            resolve(blob);
          }
        }, 'image/jpeg');
      });
    };

    try {
      setSaving(true);
      const croppedImg = await getCroppedImg(imageDOM.current, crop, 'temp.jpg');
      await dispatch(communityActions.changeCover(communityId, croppedImg));
      history.replace(`/+${slug}`);
      dispatch(appActions.addToast(t('Cover updated!')));
    } catch (e) {
      setSaving(false);
      dispatch(appActions.addError(e));
    }
  }, [communityId, dispatch, history, crop, t, slug]);

  const imageLoaded = useCallback((img) => {
    imageDOM.current = img;
  }, []);

  return (
    <FlexWrapper fullHeight mobileHasHeader>
      <FlexInnerWrapper>
        <CommunityHeader communityId={communityId} />
        <PageTitle>{t('Change cover')}</PageTitle>

        <FlexContainer centered>
          {!image
            ? (
              <FileSelector onChange={onChange}>
                <Button>{t('Upload image')}</Button>
              </FileSelector>
            ) : (
              <CropWrapper>
                <ReactCrop
                  src={image.src}
                  onChange={setCrop}
                  onImageLoaded={imageLoaded}
                  crop={crop}
                  minWidth={100}
                  minHeight={100}
                  keepSelection
                />
              </CropWrapper>
            )}
        </FlexContainer>
      </FlexInnerWrapper>

      <ActionsFooter>
        <div>
          <Button className="empty" to={`/@${slug}`}>{t('global:Cancel')}</Button>
        </div>
        <div>
          <Button onClick={onSubmitClick} loading={saving} disabled={!image}>{t('global:Confirm')}</Button>
        </div>
      </ActionsFooter>
    </FlexWrapper>
  );
};

Content.propTypes = {
  communityId: PropTypes.string.isRequired,
};

Content.defaultProps = {
};

export default Content;
