import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { useOpenClose } from 'hooks';
import * as eventSelectors from 'state/events/selectors';

import Slideshow from 'components/Slideshow';

const Preview = styled.img`
  width: 135px;
  cursor: pointer;
`;
Preview.displayName = 'Preview';

const Flyer = ({ eventId }) => {
  const [openedSlideshow, openSlideshow, closeSlideshow] = useOpenClose(false);

  const flyer = useSelector(state => eventSelectors.selectFlyer(state, eventId));

  return (
    <div>
      <Preview
        src={flyer}
        alt="Event flyer"
        onClick={openSlideshow}
        onKeyPress={openSlideshow}
        role="presentation"
      />

      {openedSlideshow && (
        <Slideshow
          images={[flyer]}
          index={0}
          close={closeSlideshow}
          goToIndex={() => {}}
        />
      )}
    </div>
  );
};

Flyer.propTypes = {
  eventId: PropTypes.string.isRequired,
};

Flyer.defaultProps = {
};

export default Flyer;
