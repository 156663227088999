import { forwardRef, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslation } from 'hooks';

import FilterComponent from 'components/Filter';

import locales from '../i18n';

const FilterWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100;
  width: calc(100% - 40px);
  box-sizing: border-box;
  padding: 8px;
  transition: all 250ms ease-out;

  transform: translate3d(${props => (props.showing ? '0' : '-100')}%, 0, 0);
`;
FilterWrapper.displayName = 'FilterWrapper';

const Filter = forwardRef(({ showing, onChange, value }, ref) => {
  const { t } = useTranslation(locales);
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));

  const setFilter = e => onChange(e.target.value);

  return (
    <FilterWrapper showing={showing}>
      <FilterComponent
        placeholder={t('global:Filter')}
        onChange={setFilter}
        value={value}
        ref={inputRef}
      />
    </FilterWrapper>
  );
});

Filter.propTypes = {
  showing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

Filter.defaultProps = {
};

export default Filter;
