import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { UserCog } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';

import locales from '../../../i18n';
import TransferOwnershipModal from '../../Modals/TransferOwnership';

const TransferOwnershipAction: React.FC = () => {
  const { t } = useTranslation(locales);
  const { chatId: channelId } = useParams<{ chatId: string }>();

  const { isOpen: isTransferOwnershipModalOpen, onOpen: openTransferOwnershipModal, onClose: closeTransferOwnershipModal } = useDisclosure();

  const isOwner = useSelector((state) => (
    // @ts-ignore
    channelSelectors.isOwnerOfChannel(state, channelId)
  ));

  if (!isOwner) return null;

  return (
    <>
      <MenuItem icon={<UserCog size={16} />} color="red.500" onClick={openTransferOwnershipModal}>{t('Transfer ownership')}</MenuItem>

      {isTransferOwnershipModalOpen && (
        <TransferOwnershipModal
          channelId={channelId}
          close={closeTransferOwnershipModal}
        />
      )}
    </>
  );
};

export default TransferOwnershipAction;
