import styled from 'styled-components';

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.colors.main};
  display: flex;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;
Title.displayName = 'Title';

export default Title;
