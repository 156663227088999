import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import * as communitySelectors from 'state/communities/selectors';

import CommunityAvatar from 'components/CommunityAvatar';
import { SelectableListItem } from 'components/SelectableList';

import Stats from './Stats';

const Community = ({ communityId }) => {
  const name = useSelector(
    state => communitySelectors.selectName(state, communityId),
    shallowEqual,
  );
  const slug = useSelector(
    state => communitySelectors.selectSlugById(state, communityId),
    shallowEqual,
  );

  const avatar = useMemo(() => (
    <CommunityAvatar communityId={communityId} />
  ), [communityId]);

  return (
    <SelectableListItem
      key={`community-list-${communityId}`}
      title={name}
      avatar={avatar}
      to={`/+${slug}`}
    >
      <Stats communityId={communityId} />
    </SelectableListItem>
  );
};

Community.propTypes = {
  communityId: PropTypes.string.isRequired,
};

export default Community;
