import styled from 'styled-components';

import CommunityAvatar from 'components/CommunityAvatar';
import { Community } from 'types';

const Wrapper = styled.div`
  display: flex;
  padding: 8px;

  > div:last-child {
    flex: 1;
    margin-left: 8px;

    .displayname {
      color: ${props => props.theme.colors.main};
      font-weight: 500;
      font-size: 14px;
    }

    .slug {
      color: ${props => props.theme.colors.secondary};
      font-size: 12px;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

interface Props {
  community: Community
}

const SuggestionCommunity = ({ community }: Props) => (
  <Wrapper>
    <div><CommunityAvatar communityId={community.id} size="32px" /></div>
    <div>
      <div className="displayname">{community.name}</div>
      <div className="slug">{`+${community.slug.toLowerCase()}`}</div>
    </div>
  </Wrapper>
);

export default SuggestionCommunity;
