import { chakra } from '@chakra-ui/react';

import { IconType, IconDefaults } from './type';

const ImageMultiple = ({ className, style }) => (
  <svg viewBox="0 0 24 24" className={className} style={style}>
    <path
      d="M22,16V4A2,2 0 0,0 20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16M11,12L13.03,14.71L16,11L20,16H8M2,6V20A2,2 0 0,0 4,22H18V20H4V6"
    />
  </svg>
);

ImageMultiple.propTypes = IconType;
ImageMultiple.defaultProps = IconDefaults;

export default chakra(ImageMultiple);
