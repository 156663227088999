import { useTitle, useTranslation } from 'hooks';

import Title from 'components/Main/Title';
import Main from 'components/Forms/Main';
import Wrapper from 'components/Forms/Wrapper';
import TitleWrapper from 'components/Forms/TitleWrapper';

import Form from './Form';
import locales from '../i18n';

const Create = () => {
  const { t } = useTranslation(locales);
  useTitle(t('Create channel'));

  return (
    <Main>
      <Wrapper>
        <TitleWrapper>
          <Title>{t('Create channel')}</Title>
        </TitleWrapper>
        <Form />
      </Wrapper>
    </Main>
  );
};

export default Create;
