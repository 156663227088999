import {
  useState, useCallback, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation, useInputValue } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';
import * as channelActions from 'state/channels/actions';
import * as appActions from 'state/app/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Toggle from 'components/Toggle';
import Input from 'components/Forms/Input';
import HelpHint from 'components/HelpHint';

import locales from '../../i18n';

const Wrapper = styled.div`
  transform: scaleY(0);
  height: 30px;
  transition: transform 0.26s ease;

  ${props => props.active && `
    transform: scaleY(1);
    height: auto;
  `}

  input {
    padding: 0 12px;
    line-height: 32px;
    margin-top: 16px;
  }
`;
Wrapper.displayName = 'Wrapper';

const Secret = styled.div`
  margin: 32px 32px 0;

  .input-secret {
    transform: scaleY(0);
    height: 30px;
    transition: transform 0.26s ease;

    ${props => props.active && `
      transform: scaleY(1);
      height: auto;
    `}
  }
`;
Secret.displayName = 'Secret';

const Hint = styled.p`
  span {
    background: #E2E2E2;
    padding: 8px;
    border-radius: 5px;
  }
`;
Hint.displayName = 'Hint';

const EmbedModal = ({ channelId, close }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  const urlRef = useRef(null);

  const hasEmbed = useSelector(state => channelSelectors.hasEmbed(state, channelId));
  const embedUrl = useSelector(state => channelSelectors.getEmbedUrl(state, channelId));
  const hasEmbedToken = useSelector(state => channelSelectors.hasEmbedToken(state, channelId));

  const [saving, setSaving] = useState(false);
  const [active, setActive] = useState(hasEmbed);
  const [hasSecret, setHasSecret] = useState(hasEmbedToken);
  const inputUrl = useInputValue(embedUrl || '');
  const inputSecret = useInputValue('');

  const activeChange = useCallback((e) => {
    setActive(e.target.checked);
  }, []);
  const hasSecretChange = useCallback((e) => {
    setHasSecret(e.target.checked);
  }, []);

  const save = useCallback(async () => {
    try {
      setSaving(true);

      const payload = { url: null, secret: null };
      if (active) payload.url = inputUrl.value;
      if (hasSecret) payload.secret = inputSecret.value;

      await dispatch(channelActions.updateEmbed(channelId, payload));
      dispatch(appActions.addToast(t('Embed updated')));

      close();
    } catch (error) {
      setSaving(false);
      dispatch(appActions.addError(error));
    }
  }, [dispatch, channelId, active, hasSecret, inputUrl, inputSecret, close, t]);

  useEffect(() => {
    if (active && urlRef.current) {
      urlRef.current.focus();
    }
  }, [active]);

  return (
    <Modal
      onCancel={close}
      actions={[
        <Button key="chat-embed-confirm" onClick={save} loading={saving}>{t('global:Confirm')}</Button>,
      ]}
    >
      <Toggle
        label={(
          <div>
            {t('Embed frame')}
            {' '}
            <HelpHint
              content={(
                <div>
                  <p>{t('embed.url.hint')}</p>
                </div>
              )}
            />
          </div>
        )}
        active={active}
        onChange={activeChange}
        position="left"
      />

      <Wrapper active={active}>
        <div>
          <Input {...inputUrl} placeholder={t('URL')} ref={urlRef} />
        </div>

        <Secret active={hasSecret}>
          <Toggle
            label={(
              <div>
                {t('Use user tokens')}
                {' '}
                <HelpHint
                  content={(
                    <div>
                      <Trans t={t} i18nKey="embed.secret.hint" ns="chatDashboard">
                        <p>
                          Adds a
                          {' '}
                          <span>token</span>
                          {' '}
                          queryparam to the url.
                        </p>
                        <p>
                          The token is en encrypted as a
                          {' '}
                          <a href="https://jwt.io" target="_blank" rel="noreferrer">JWT</a>
                          {' '}
                          method.
                        </p>
                        <Hint>
                          The token has 3 params:
                          {' '}
                          <span>userId</span>
                          {', '}
                          <span>channelId</span>
                          {' and '}
                          <span>role</span>
                        </Hint>
                      </Trans>
                    </div>
                  )}
                />
              </div>
            )}
            active={hasSecret}
            onChange={hasSecretChange}
            position="left"
          />

          <div className="input-secret">
            <Input {...inputSecret} placeholder={t('Secret')} />
          </div>
        </Secret>
      </Wrapper>
    </Modal>
  );
};

EmbedModal.propTypes = {
  channelId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

EmbedModal.defaultProps = {
};

export default EmbedModal;
