import PropTypes from 'prop-types';

import { COMMUNITY_PRIVACIES } from '../../constants';

export default PropTypes.shape({
  _id: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  privacy: PropTypes.oneOf(Object.values(COMMUNITY_PRIVACIES)).isRequired,
});
