import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import * as channelActions from 'state/channels/actions';

import AvatarModalComponent from 'components/AvatarModal';

const AvatarModal = ({ channelId, image, close }) => {
  const dispatch = useDispatch();

  const onSubmit = async (croppedImg) => {
    await dispatch(channelActions.changeAvatar(channelId, croppedImg));
  };

  return (
    <AvatarModalComponent
      onSubmit={onSubmit}
      image={image}
      close={close}
    />
  );
};

AvatarModal.propTypes = {
  channelId: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }).isRequired,
  close: PropTypes.func.isRequired,
};

export default AvatarModal;
