import { normalize } from 'normalizr';
import { batch } from 'react-redux';

import * as userActions from 'state/users/actions';

import Api from 'state/api';

import alertSchema from './schema';
import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAIL,
  ADD,
  UPDATE,
  MARK_AS_READ,
  WIPE,
} from './constants';

export const add = rawData => (dispatch) => {
  if (!rawData) return;
  const data = normalize(rawData, [alertSchema]);

  batch(() => {
    dispatch(userActions.add(data.entities.users || {}));
    dispatch({ type: FETCH_SUCCESS, data: Object.values(data.entities.alerts || {}) });
  });
};

export const fetch = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH });
    const { data } = await Api.req.get('/alerts');
    dispatch(add(data));
  } catch (err) {
    let error = err.message;
    if (err.response) {
      // eslint-disable-next-line
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }

    dispatch({ type: FETCH_FAIL, error });
  }
};

export const markAsRead = () => async (dispatch, getState) => {
  try {
    const ids = getState().alerts.data.map(alert => alert.id);
    dispatch({ type: MARK_AS_READ, ids });
    await Api.req.put('/alerts/read', { ids });
  } catch (err) {
    //
  }
};

export const handleAlertCreated = alert => (dispatch) => {
  try {
    const data = normalize(alert, alertSchema);

    batch(() => {
      dispatch(userActions.add(
        data.entities.users
        || data.entities.alerts[data.result].payload.user
        || {},
      ));

      dispatch({ type: ADD, data: data.entities.alerts[data.result] });
    });
  } catch (err) {
    //
  }
};

export const handleAlertUpdated = alert => (dispatch) => {
  try {
    const data = normalize(alert, alertSchema);

    batch(() => {
      dispatch(userActions.add(data.entities.users || {}));
      dispatch({ type: UPDATE, id: data.result, data: data.entities.alerts[data.result] });
    });
  } catch (err) {
    //
  }
};

export const wipe = () => dispatch => dispatch({ type: WIPE });
