import PropTypes from 'prop-types';

import ImageBox from 'components/ImageBox';

import SafeForWorkLayer from './SafeForWorkLayer';

const LinkImage = ({ data }) => (
  <ImageBox>
    <SafeForWorkLayer />
    {data.url && (
      <img src={data.url} alt={data.title} width={data.imageWidth} height={data.imageWidth} />
    )}
  </ImageBox>
);

LinkImage.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string.isRequired,
    imageWidth: PropTypes.string,
    imageHeight: PropTypes.string,
  }).isRequired,
};

export default LinkImage;
