import { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as appSelectors from 'state/app/selectors';

const Layer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  backdrop-filter: blur(40px);
  cursor: zoom-in;
`;
Layer.displayName = 'Layer';

const SafeForWorkLayer = () => {
  const isSafeForWork = useSelector(appSelectors.selectIsSafeForWork);
  const [closed, setClosed] = useState(false);

  if (closed || !isSafeForWork) return null;

  return <Layer onClick={() => setClosed(true)} />;
};

SafeForWorkLayer.propTypes = {
};

SafeForWorkLayer.defaultProps = {
};

export default SafeForWorkLayer;
