import { useSelector } from 'react-redux';
import { ButtonGroup } from '@chakra-ui/react';

import * as authSelectors from 'state/auth/selectors';

import UserFollowButton from 'components/UserFollowButton';

import ChatButton from './ChatButton';
import GiftButton from './GiftButton';

interface Props {
  userId: number;
}

const MainActionButtons: React.FC<Props> = ({ userId }) => {
  const myUserId = useSelector(authSelectors.selectUserId);

  if (!userId || userId === myUserId) return null;

  return (
    <ButtonGroup alignItems="center" spacing={4} mt={4}>
      <UserFollowButton userId={userId} size="lg" />
      <ChatButton userId={userId} />
      <GiftButton userId={userId} />
    </ButtonGroup>
  );
};

export default MainActionButtons;
