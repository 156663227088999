import { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

import UsersOnline from './UsersOnline';
import LastThreads from './LastThreads';

const Ad = lazy(() => import('components/Ad' /* webpackChunkName: "ad" */));

const Sidebar = ({ landing, showMiddleAd }) => (
  <div>
    <UsersOnline landing={landing} />

    <Suspense fallback={<div />}>
      {showMiddleAd && <Ad id="Sidebar Middle" />}
    </Suspense>

    <LastThreads landing={landing} />
  </div>
);

Sidebar.propTypes = {
  landing: PropTypes.bool,
  showMiddleAd: PropTypes.bool,
};

Sidebar.defaultProps = {
  landing: true,
  showMiddleAd: false,
};

export default Sidebar;
