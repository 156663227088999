import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useTranslation } from 'hooks';

import Datepicker from 'components/Datepicker';

import locales from './i18n';

const Birthdate = ({ birthdate, setBirthdate }) => {
  const { t } = useTranslation(locales);

  const [isFocused, setIsFocused] = useState(false);

  const onFocusChange = useCallback(({ focused }) => setIsFocused(focused), []);
  const isOutsideRange = useCallback(date => moment().diff(date, 'years') < 18, []);
  const initialVisibleMonth = useCallback(() => birthdate || moment().subtract(18, 'years'), [birthdate]);

  return (
    <Datepicker
      id="signup-birthdate"
      date={birthdate}
      onDateChange={setBirthdate}
      focused={isFocused}
      onFocusChange={onFocusChange}
      placeholder={t('Birth date')}
      required
      noBorder
      keepOpenOnDateSelect={false}
      numberOfMonths={1}
      showClearDate
      block
      hideKeyboardShortcutsPanel
      isOutsideRange={isOutsideRange}
      initialVisibleMonth={initialVisibleMonth}
      monthSelection
    />
  );
};

Birthdate.propTypes = {
  birthdate: PropTypes.shape({}),
  setBirthdate: PropTypes.func.isRequired,
};

Birthdate.defaultProps = {
  birthdate: null,
};

export default Birthdate;
