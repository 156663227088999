import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'hooks';
import * as userActions from 'state/users/actions';

import LoadingPage from 'containers/App/LoadingPage';
import AuthLayout from 'components/AuthLayout';
import ErrorState from 'components/ErrorState';

import EmailToggles from '../Notifications/Email';
import locales from './i18n';

const Unsubscribe = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  const { key } = useParams();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const load = async () => {
      try {
        const config = await dispatch(userActions.loadNotificationsConfig(key));
        setData(config.email);
      } catch (e) {
        setError(e);
      }
    };

    load();
  }, [dispatch, key]);

  const renderContent = () => {
    if (error !== null) return <ErrorState message={t('error.fetch')} reload={false} />;
    if (data === null) return <LoadingPage />;

    return <EmailToggles data={data} token={key} />;
  };


  return (
    <AuthLayout title={t('Manage your e-mail subscriptions')}>
      {renderContent()}
    </AuthLayout>
  );
};

Unsubscribe.propTypes = {
};

Unsubscribe.defaultProps = {
};

export default Unsubscribe;
