import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';
import {
  Box, Icon, Text, VStack,
} from '@chakra-ui/react';

import getBadgeColor from 'utils/getBadgeColor';
import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import Decagram from 'components/Icons/Decagram';

import locales from '../i18n';
import { BADGES } from '../../../constants';

const getBadgeValue = badge => BADGES[badge]?.value || 0;

const Badge = styled.div`
  margin: 0 8px;

  svg {
    height: 64px;
    width: 64px;

    path {
      fill: ${props => getBadgeColor({ ...props, badgesValue: getBadgeValue(props.name) }).fill};
    }
  }

  .badge-name {
    background-color: rgba(0, 0, 0, .5);
    padding: 4px 8px;
    border-radius: 8px;
  }
`;
Badge.displayName = 'Badge';

const badgesort = (a, b) => getBadgeValue(b) - getBadgeValue(a);

const Badges = ({ userId }) => {
  const { t } = useTranslation(locales);

  const badges = useSelector(state => userSelectors.getBadges(state, userId), shallowEqual);
  const pronoun = useSelector(userSelectors.getPronoun(userId));

  return (
    <Box>
      {badges.sort(badgesort).map(badge => (
        <VStack key={`badge-${badge}`} spacing={0}>
          <Icon
            as={Decagram}
            boxSize={16}
            fill={getBadgeColor({ badgesValue: getBadgeValue(badge) }).fill}
          />
          <Text bgColor="rgba(0, 0, 0, .5)" px={2} py={1} rounded="lg">{t(`badge.${badge}`, { context: pronoun })}</Text>
        </VStack>
      ))}
    </Box>
  );
};

Badges.propTypes = {
  userId: PropTypes.number.isRequired,
};

Badges.defaultProps = {
};

export default Badges;
