import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Text } from '@chakra-ui/react';

import * as threadSelectors from 'state/threads/selectors';
import useDisplayName from 'hooks/useDisplayName';

const LastReply = ({ threadId }) => {
  const lastReplyAuthorId = useSelector(threadSelectors.getLastReplyAuthorId(threadId));
  const lastReplyAt = useSelector(threadSelectors.getLastReplyAt(threadId));
  const displayname = useDisplayName(lastReplyAuthorId);

  return (
    <Text fontSize="xs" color="gray.400">
      {`${displayname} ${moment(lastReplyAt).fromNow()}`}
    </Text>
  );
};

LastReply.propTypes = {
  threadId: PropTypes.string.isRequired,
};

export default LastReply;
