export default {
  variants: {
    primary: {
      bgColor: 'black',
      color: 'white',
      _hover: {
        bgColor: 'gray.800',
        _disabled: {
          bgColor: 'black',
        },
      },
    },
    danger: {
      color: 'white',
      bg: 'red.500',
      _hover: {
        bg: 'red.600',
      },
      _focus: {
        bg: 'red.600',
      },
      _active: {
        bg: 'red.700',
      },
    },
  },
};
