import styled from 'styled-components';

const Wrapper = styled.div`
  ${props => props.fullHeight && `
    flex: 1;
    overflow: hidden;
  `}

  > p {
    margin-bottom: 16px;
  }

  p a {
    color: ${props => props.theme.colors.main};
  }

  ul {
    list-style: disc;
    margin: 8px 0 8px 32px;

    li {
      margin-bottom: 8px;
    }
  }

  ol {
    margin: 8px 0 8px 32px;

    li {
      margin-bottom: 8px;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
