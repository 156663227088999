/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import fastdom from 'fastdom';
import styled from 'styled-components';

import { useSearchUsers } from 'hooks';

import { UserSearchInput, UserSearchResults } from 'components/UserSearch';

const SearchbarWrapper = styled.div`
  width: ${props => (props.showing ? '400' : '0')}px;
  overflow: hidden;
  position: absolute;
  right: 0px;
  transition: all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1);
  top: 0;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .search-container {
    flex: 1
  }

  @media(max-width: 768px) {
    position: fixed;
    z-index: 10;
    width: ${props => (props.showing ? 'auto' : '0px')};
    left: 8px;
    right: 8px;
  }
`;

const ResultsWrapper = styled.div`
  position: fixed;
  top: 64px;
  width: 400px;
  right: 135px;
  color: black;
  text-transform: none;
  text-align: left;
  font-weight: 100;
  font-size: 16px;

  @media(max-width: 768px) {
    width: auto;
    right: 0;
    left: 0;
  }
`;

const UserSearch = memo(({ showing, onBlur, changeType }) => {
  const containerEl = useRef(null);
  const inputEl = useRef(null);
  const [searchProps, results, reset] = useSearchUsers();

  const onBlurTriggered = () => {
    reset();
    onBlur();
  };

  const listenClickOutside = (e) => {
    const el = containerEl.current;

    const hasActionOnClick = (element) => {
      // if ([...element.classList].includes('prevent-close')) return false;
      if (element.href) return true;
      return element.parentNode && hasActionOnClick(element.parentNode);
    };
    const hasPreventionClass = (element) => {
      if (element.classList && [...element.classList].includes('prevent-close')) return true;
      return element.parentNode && hasPreventionClass(element.parentNode);
    };

    if (
      el
      && !hasPreventionClass(e.target)
      && (!el.contains(e.target) || hasActionOnClick(e.target))
    ) {
      onBlurTriggered();
    }
  };

  useEffect(() => {
    if (showing) {
      fastdom.mutate(() => {
        document.addEventListener('click', listenClickOutside);
      });
    } else {
      fastdom.mutate(() => {
        document.removeEventListener('click', listenClickOutside);
      });
    }

    return () => document.removeEventListener('click', listenClickOutside);
  }, [showing]);

  useEffect(() => {
    if (showing && inputEl.current) {
      inputEl.current.focus();
    }
  }, [showing, inputEl]);

  return (
    <div ref={containerEl}>
      <SearchbarWrapper showing={showing} className="search-wrapper">
        <UserSearchInput ref={inputEl} {...searchProps} onBlurTriggered={onBlurTriggered} changeType={changeType} type="users" />
      </SearchbarWrapper>
      <ResultsWrapper>
        {results.length > 0 && <UserSearchResults results={results} />}
      </ResultsWrapper>
    </div>
  );
});

UserSearch.propTypes = {
  showing: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  changeType: PropTypes.func.isRequired,
};

export default UserSearch;
