import {
  memo, useEffect, useCallback, useState,
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import Spinner from 'components/Spinner';

import User from './User';
import Box from '../Box';
import locales from '../i18n';

const RANDOM_COEF = 30000;

const SuggestionUser = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const suggestionlist = useSelector(userSelectors.selectSuggestions, shallowEqual);
  const [suggestions, setSuggestions] = useState([]);
  const [removed, setRemoved] = useState([]);

  useEffect(() => {
    if (suggestionlist === null) {
      dispatch(userActions.loadSuggestions());
    } else {
      const s = suggestionlist.sort((a, b) => {
        const scoreA = a.relevance + (Math.random() * RANDOM_COEF);
        const scoreB = b.relevance + (Math.random() * RANDOM_COEF);
        return scoreB - scoreA;
      });
      setSuggestions(s);
    }
  }, [dispatch, suggestionlist]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (removed.length > 0) {
        dispatch(userActions.clearSuggestions());
      }
    };
  }, [dispatch, removed]);

  const remove = useCallback((userId) => {
    setRemoved(cv => ([
      ...cv,
      userId,
    ]));
    dispatch(userActions.removeSuggestion(userId)); // Optimistic
  }, [dispatch]);

  const filteredSuggestions = suggestions
    .filter(s => !removed.includes(s.userId))
    .slice(0, 3);

  return (
    <Box title={t('Suggested Users')} height={128}>
      {suggestionlist === null
        ? <Spinner />
        : (
          <div>
            {filteredSuggestions.map(s => (
              <User key={`user-suggestion-${s.userId}`} userId={s.userId} remove={remove} />
            ))}
          </div>
        )
      }
    </Box>
  );
};

SuggestionUser.propTypes = {
};

SuggestionUser.defaultProps = {
};

export default memo(SuggestionUser);
