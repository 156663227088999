import { memo } from 'react';
import PropTypes from 'prop-types';
import { Emoji as EmojiMart, emojiIndex } from 'emoji-mart';

import customemojis from 'components/Composer/customemojis';

const customEmojis = customemojis();

const Emoji = ({ value, size }) => {
  const name = value.substr(1, value.length - 2);
  const custom = emojiIndex
    .search(name, { custom: customEmojis })
    .find(e => e.short_names.includes(name));

  const emoji = custom || value;

  return (
    <EmojiMart
      emoji={emoji}
      size={size}
      set="apple"
      tooltip
      fallback={() => value}
    />
  );
};

Emoji.propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export default memo(Emoji);
