import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import Select from 'components/Select';

import locales from './i18n';
import { GENDERS } from '../../constants';

const Genders = ({ value, onChange, simple }) => {
  const { t } = useTranslation(locales);

  const setGender = useCallback((v) => {
    onChange(v);
  }, [onChange]);

  const options = useMemo(() => (
    Object.values(GENDERS).map(g => ({
      label: t(`global:GENDER.${g.label}`),
      value: g.label,
    }))
  ), [t]);

  return (
    <Select
      value={value}
      onChange={setGender}
      placeholder={t('global:Gender')}
      options={options}
      simple={simple}
    />
  );
};

Genders.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  simple: PropTypes.bool,
};

Genders.defaultProps = {
  value: null,
  simple: true,
};

export default Genders;
