import { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import colors from 'utils/css/colors';
import * as threadSelectors from 'state/threads/selectors';
import * as eventSelectors from 'state/events/selectors';
import * as replySelectors from 'state/replies/selectors';
import * as replyActions from 'state/replies/actions';

import Ad from 'components/Ad';

import LoadMoreReplies from './LoadMoreReplies';
import ReplyLoading from './Reply/Loading';
import Reply from './Reply';

const ReplyListWrapper = styled.div`
  background-color: ${colors.secondaryListItem};
`;
ReplyListWrapper.displayName = 'ReplyListWrapper';

const ReplyList = ({ threadId }) => {
  const dispatch = useDispatch();

  const container = useRef(null);
  const scrolled = useRef(false);
  const total = useSelector(threadSelectors.getReplyCount(threadId));
  const replies = useSelector(replySelectors.getIdsForThread(threadId), shallowEqual);
  const isLoading = useSelector(replySelectors.isLoading(threadId));
  const eventId = useSelector(threadSelectors.getEventId(threadId));
  const eventHighlight = useSelector(state => eventSelectors.isHighlighted(state, eventId));

  useEffect(() => {
    if (!replies.length && total > 0) {
      container.current.scrollIntoView();
      dispatch(replyActions.load(threadId));
    }
  }, [dispatch, total, replies.length, threadId]);

  useEffect(() => {
    if (!scrolled.current && container.current) {
      const parent = container.current.closest('.overflow-component');
      if (!isLoading) {
        scrolled.current = true;

        // Small delay to wait new unread information kicks in
        // We should find a better method
        setTimeout(() => {
          // Conditional check because container.current might be gone when timeout kicks in
          if (container.current) {
            const unreadElements = container.current.querySelectorAll('.unread');

            if (unreadElements.length === replies.length) {
              parent.scrollTo(0, 0);
            } else if (unreadElements.length > 0) {
              unreadElements[0].parentElement.scrollIntoView();
            } else {
              //
            }
          }
        }, 300);
      } else {
        // Scroll to top if replies are loading (initially)
        parent.scrollTo(0, 0);
      }
    }

    return () => {
      scrolled.current = false;
    };
  }, [scrolled, isLoading, threadId, container, replies.length]);

  return (
    <ReplyListWrapper ref={container} className="riverplate">
      <LoadMoreReplies threadId={threadId} />

      {isLoading && <ReplyLoading />}

      {replies.map((replyId, index) => (
        <Fragment key={`thread-reply-${replyId}`}>
          <Reply replyId={replyId} />
          {index > 0 && !(index % 10) && !eventHighlight && (
            // eslint-disable-next-line react/no-array-index-key
            <Ad id="In Content" key={`threadreply-ad-${threadId}-${index}`} />
          )}
        </Fragment>
      ))}
    </ReplyListWrapper>
  );
};

ReplyList.propTypes = {
  threadId: PropTypes.string.isRequired,
};

ReplyList.defaultProps = {
};

export default ReplyList;
