import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';

import Time from './Time';
import Reactions from './Reactions';
import InfoComponent from '../Info';
import OnlyYou from './OnlyYou';
import { CHANNEL_MESSAGES_TYPES } from '../../../../../constants';

import locales from '../i18n';

const Info = ({ messageId, type }) => {
  const { t } = useTranslation(locales);

  const hasReactions = useSelector(state => channelSelectors.messageHasReactions(state, messageId));

  return (
    <InfoComponent hasReactions={hasReactions}>
      <Reactions messageId={messageId} key={`reactions-${messageId}`} />
      <div>
        {type === CHANNEL_MESSAGES_TYPES.NOTICE && (
          <OnlyYou>{t('Only you can see this message')}</OnlyYou>
        )}
        <Time messageId={messageId} />
      </div>
    </InfoComponent>
  );
};

Info.propTypes = {
  messageId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(CHANNEL_MESSAGES_TYPES)).isRequired,
};

Info.defaultProps = {
};

export default Info;
