import { IconType, IconDefaults } from './type';

const AccountSupervisor = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M16.5,12A2.5,2.5 0 0,0 19,9.5A2.5,2.5 0 0,0 16.5,7A2.5,2.5 0 0,0 14,9.5A2.5,2.5 0 0,0 16.5,12M9,11A3,3 0 0,0 12,8A3,3 0 0,0 9,5A3,3 0 0,0 6,8A3,3 0 0,0 9,11M16.5,14C14.67,14 11,14.92 11,16.75V19H22V16.75C22,14.92 18.33,14 16.5,14M9,13C6.67,13 2,14.17 2,16.5V19H9V16.75C9,15.9 9.33,14.41 11.37,13.28C10.5,13.1 9.66,13 9,13Z"
      fill={color}
    />
  </svg>
);

AccountSupervisor.propTypes = IconType;
AccountSupervisor.defaultProps = IconDefaults;

export default AccountSupervisor;
