/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import fastdom from 'fastdom';
import styled from 'styled-components';

import DropdownMenu from 'components/DropdownMenu';

const Wrapper = styled.div.attrs(props => ({
  className: props.className,
}))`
  position: absolute;
  top: ${props => props.top};
  ${props => (props.orientation === 'left' ? 'right: 0' : 'left: 0')};
  border: 1px solid #eee;
  z-index: 110;
  min-width: ${props => props.size * 45}px;

  &.nav-dropdown {
    z-index: 1000;

    @media(max-width: 767px) {
      position: fixed;
      top: 64px;
      right: 0;
      left: 0;
      border: 0 solid #eee;
      height: calc(100vh - 64px);

      > div {
        height: 100%;
      }
    }
  }
`;

const Menu = ({
  open,
  onClose,
  children,
  width,
  maxHeight,
  className,
  top,
  orientation,
  size,
}) => {
  const menu = useRef(null);

  const listenClickOutside = (e) => {
    const el = menu.current;

    const hasActionOnClick = (element) => {
      if (element.href) return true;
      return element.parentNode && hasActionOnClick(element.parentNode);
    };
    const hasPreventionClass = (element) => {
      if (element.classList && [...element.classList].includes('prevent-close')) return true;
      return element.parentNode && hasPreventionClass(element.parentNode);
    };

    if (
      el
      && !hasPreventionClass(e.target)
      && (!el.contains(e.target) || hasActionOnClick(e.target))
    ) {
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      fastdom.mutate(() => {
        document.body.addEventListener('click', listenClickOutside);
      });
    } else {
      fastdom.mutate(() => {
        document.body.removeEventListener('click', listenClickOutside);
      });
    }

    return () => document.body.removeEventListener('click', listenClickOutside);
  }, [open]);

  if (!open) return null;

  return (
    <Wrapper ref={menu} className={className} top={top} orientation={orientation} size={size}>
      <DropdownMenu width={width} maxHeight={maxHeight}>
        {children}
      </DropdownMenu>
    </Wrapper>
  );
};

Menu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  maxHeight: PropTypes.string,
  top: PropTypes.string,
  className: PropTypes.string,
  orientation: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.number,
};

Menu.defaultProps = {
  open: false,
  width: 'auto',
  maxHeight: null,
  top: '100%',
  className: '',
  orientation: 'left',
  size: 4,
};

export default Menu;
