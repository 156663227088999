import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTitle, useTranslation } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';

import CommunityHeader from 'containers/Communities/Header';
import Info from 'containers/Communities/Info';
import Layout from 'components/Layout';
import EmptyState from 'components/EmptyState';
import MembershipRequestButton from 'components/MembershipRequestButton';
import { FlexWrapper } from 'components/FlexWrapper';

import locales from './i18n';

const PrivateAndNotMember = ({ communityId }) => {
  const { t } = useTranslation(locales);
  const params = useParams();
  const name = useSelector(state => communitySelectors.selectName(state, communityId));

  useTitle(`${t('Not authorized')} - ${name}`);

  return (
    <Layout columns={2}>
      <Info communitySlug={params.communitySlug} />
      <FlexWrapper>
        <CommunityHeader communityId={communityId} />
        <EmptyState
          title={t('This community is private')}
          subtitle={t('Request access to join')}
        >
          <MembershipRequestButton communityId={communityId} canCancel full={false} />
        </EmptyState>
      </FlexWrapper>
    </Layout>
  );
};

PrivateAndNotMember.propTypes = {
  communityId: PropTypes.string.isRequired,
};

PrivateAndNotMember.defaultProps = {
};

export default PrivateAndNotMember;
