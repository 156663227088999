import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as messengerSelectors from 'state/messengers/selectors';
import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import { useTitle, useTranslation } from 'hooks';

import NotFound from 'containers/NotFound';

import Header from './Header';
import ChatArea from './ChatArea';
import Wrapper from '../Content/Wrapper';
import locales from '../i18n';

const MessengerContent = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const userId = useSelector(state => messengerSelectors.getUserId(state, id));
  const user = useSelector(userSelectors.getById(userId), shallowEqual);

  if (user && user.loading) dispatch(userActions.fetchData(userId));

  useTitle(`${user ? user.displayname : ''} - ${t('global:Chat')}`);

  if (!user) return <NotFound />;

  return (
    <Wrapper>
      <Header messengerId={id} />
      <ChatArea id={id} participant={user} />
    </Wrapper>
  );
};

MessengerContent.propTypes = {
  id: PropTypes.string.isRequired,
};

export default MessengerContent;
