/* eslint-disable import/prefer-default-export */
export const alertListEqual = (listA, listB) => {
  try {
    if (listA.length !== listB.length) return false;

    // eslint-disable-next-line consistent-return
    listA.forEach((alertA, index) => {
      if (alertA.id !== listB[index].id) return false;
    });

    return true;
  } catch (error) {
    return false;
  }
};
