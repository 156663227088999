import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as appSelectors from 'state/app/selectors';
import sfwImage from 'utils/img/noimage.png';

import Slideshow from 'components/Slideshow';

import Item from './Item';

const ContentMediaWrapper = styled.ul.attrs({
  className: 'content-media',
})<React.HTMLProps<HTMLElement>>`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 0;
  padding: 0;

  @media(max-width: 767px) {
    width: 100%;
    max-width: 320px;
    margin: 20px auto 20px;
  }
`;

interface Props {
  media: {
    images: Array<{
      id?: string;
      filename: string;
    }>;
  };
  threshold: boolean;
}

const ContentMedia = ({ media, threshold }: Props) => {
  const [slideshowIndex, setSlideshowIndex] = useState<number>();

  const openSlideshowAtIndex = (index: number) => () => setSlideshowIndex(index);
  const closeSlideshow = () => setSlideshowIndex(undefined);

  const isSafeForWork = useSelector(appSelectors.selectIsSafeForWork);

  if (!media || !media.images || !media.images.length) return null;

  if (isSafeForWork) return <img alt="Media" src={sfwImage} width="32px" height="32px" />;

  return (
    <>
      <ContentMediaWrapper>
        {media.images.map((image, index) => (
          <Item
            // What is going on with these types?
            // @ts-ignore
            key={image.id || image}
            onClick={openSlideshowAtIndex(index)}
            image={image}
            threshold={threshold}
          />
        ))}
      </ContentMediaWrapper>

      {slideshowIndex !== undefined && (
        <Slideshow
          images={media.images.map(image => image.filename)}
          index={slideshowIndex}
          close={closeSlideshow}
          goToIndex={setSlideshowIndex}
        />
      )}
    </>
  );
};

ContentMedia.propTypes = {
  media: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string,
        filename: PropTypes.string.isRequired,
      }),
      PropTypes.string,
    ])),
  }),
  threshold: PropTypes.bool,
};

ContentMedia.defaultProps = {
  media: null,
  threshold: false,
};

export default memo(ContentMedia);
