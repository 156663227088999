const Isologo = () => (
  <svg width="35px" height="33px" viewBox="0 0 35 33" fill="none">
    <path
      d="M20.9489 23.2692L17.5426 28.0923L9.28223 16.3308V33H0V0H8.9416L17.5426 12.1846L26.0584 0H35V33H25.8029V16.3308L20.9489 23.2692Z"
      fill="#ED4D3D"
    />
  </svg>
);

export default Isologo;
