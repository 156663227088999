import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { useSelector, shallowEqual } from 'react-redux';

import * as replySelectors from 'state/replies/selectors';

import ContentMedia from 'components/ContentMedia';
import ParsedContent from 'components/ParsedContent';

const BodyWrapper = styled.div`
  margin: 16px;
  line-height: 20px;
  font-size: 16px;
  text-align: left;

  p {
    padding: 8px 0;
  }

  .body-content {
    ul {
      margin-left: 24px;
      list-style: disc;
    }
  }

  .content-media li {
    max-width: 31%;
  }
`;
BodyWrapper.displayName = 'BodyWrapper';

const ReplyBody = ({ replyId }) => {
  const rawContent = useSelector(replySelectors.getRawContent(replyId));
  const media = useSelector(replySelectors.getMedia(replyId), shallowEqual);
  const videos = useSelector(replySelectors.getVideoUrls(replyId), shallowEqual);

  const renderContent = () => {
    if (rawContent) return <ParsedContent content={rawContent} />;
    return null;
  };

  return (
    <BodyWrapper>
      <div className="body-content">
        {renderContent()}
      </div>
      <ContentMedia media={media || []} threshold />
      {(videos || []).map(url => (
        <ReactPlayer key={url} url={url} width="auto" />
      ))}
    </BodyWrapper>
  );
};

ReplyBody.propTypes = {
  replyId: PropTypes.string.isRequired,
};

ReplyBody.defaultProps = {
};

export default ReplyBody;
