import { schema } from 'normalizr';

import user from 'state/users/schema';

export const commentSchema = new schema.Entity('comments', {
  author: user,
});

export const publicationSchema = new schema.Entity('publications', {
  author: user,
  comments: [commentSchema],
});
