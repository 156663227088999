import React from 'react';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Skeleton,
  SkeletonCircle,
  useBreakpointValue,
} from '@chakra-ui/react';

const LoadingDashboard: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex h="100vh" bg="gray.50" mt={{ base: '64px', md: 0 }}>
      {/* Sidebar */}
      <Box w={isMobile ? 'full' : '300px'} bg="white" borderRight="1px" borderColor="gray.200">
        <VStack align="stretch" spacing={0}>
          {/* Top icons */}
          <Box height="41px" w="full" />

          {/* Chat rooms */}
          <VStack align="stretch" p={2} spacing={3} overflowY="auto">
            {[...Array(10)].map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <HStack key={i} spacing={3}>
                <SkeletonCircle size="10" />
                <VStack align="start" spacing={1} flex={1}>
                  <Skeleton height="20px" width="80%" />
                  <Skeleton height="16px" width="60%" />
                </VStack>
              </HStack>
            ))}
          </VStack>
        </VStack>
      </Box>

      {/* Main chat area */}
      {!isMobile && (
        <Flex flex={1} direction="column" borderLeft="1px" borderColor="gray.200">
          {/* Chat header */}
          <HStack p={4} borderBottom="1px" borderColor="gray.200" bg="white">
            <SkeletonCircle size="10" />
            <Skeleton height="20px" width="150px" />
            <Skeleton height="20px" width="50px" ml="auto" />
          </HStack>

          {/* Chat messages */}
          <VStack flex={1} p={4} spacing={4} align="stretch" overflowY="auto">
            {[...Array(6)].map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <HStack key={i} alignSelf={i % 2 === 0 ? 'flex-start' : 'flex-end'}>
                {i % 2 === 0 && <SkeletonCircle size="8" />}
                <VStack align={i % 2 === 0 ? 'start' : 'end'} maxW="70%">
                  <Skeleton height="44px" width="200px" />
                </VStack>
                {i % 2 !== 0 && <SkeletonCircle size="8" />}
              </HStack>
            ))}
          </VStack>

          {/* Chat input */}
          <HStack p={4} borderTop="1px" borderColor="gray.200" bg="white">
            <Skeleton height="40px" flex={1} borderRadius="full" />
            <Skeleton height="40px" width="40px" borderRadius="full" />
          </HStack>
        </Flex>
      )}
    </Flex>
  );
};

export default LoadingDashboard;
