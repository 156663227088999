import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as feedSelectors from 'state/feed/selectors';

import Poll from './Poll';
import Url from './Url';
import MediaPublication from './Media';

import { PUBLICATION_TYPES } from '../../../constants';

const MoreContent = ({ publicationId }) => {
  const type = useSelector(state => feedSelectors.publications.selectType(state, publicationId));

  if (type === PUBLICATION_TYPES.POLL) {
    return <Poll publicationId={publicationId} />;
  }

  if (type === PUBLICATION_TYPES.LINK) {
    return <Url publicationId={publicationId} />;
  }

  if (type === PUBLICATION_TYPES.MEDIA) {
    return <MediaPublication publicationId={publicationId} />;
  }

  return null;
};

MoreContent.propTypes = {
  publicationId: PropTypes.string.isRequired,
};

MoreContent.defaultProps = {
};

export default MoreContent;
