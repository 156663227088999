import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Icon, IconButton, Menu, MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import {
  EllipsisVertical, EyeOff, Flag, UserX, Users,
} from 'lucide-react';

import { useTranslation, useOpenClose } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as userSelectors from 'state/users/selectors';

import KnowModal from '../Modals/Know';
import UnknowModal from '../Modals/Unknow';
import BlockModal from '../Modals/Block';
import UnblockModal from '../Modals/Unblock';
import MuteModal from '../Modals/Mute';
import ReportModal from '../Modals/Report';
import locales from '../../i18n';

const ActionsOthers = ({ userId }) => {
  const { t } = useTranslation(locales);

  const isOrganization = useSelector(userSelectors.isOrganization(userId));
  const pronoun = useSelector(userSelectors.getPronoun(userId));
  const knowing = useSelector(state => authSelectors.selectDoIKnow(state, userId));
  const blocked = useSelector(state => authSelectors.selectIsBlocked(state, userId));

  const [showingKnowModal, openKnowModal, closeKnowModal] = useOpenClose();
  const [showingUnknowModal, openUnknowModal, closeUnknowModal] = useOpenClose();
  const [showingBlockModal, openBlockModal, closeBlockModal] = useOpenClose();
  const [showingUnblockModal, openUnblockModal, closeUnblockModal] = useOpenClose();
  const [showingMuteModal, openMuteModal, closeMuteModal] = useOpenClose();
  const [showingReportModal, openReportModal, closeReportModal] = useOpenClose();

  return (
    <>
      <Menu>
        <MenuButton variant="solid" colorScheme="blackAlpha" bgColor="rgba(0, 0, 0, 0.1)" as={IconButton} icon={<Icon as={EllipsisVertical} boxSize={4} color="white" />} />
        <MenuList>
          {!isOrganization && !knowing && <MenuItem onClick={openKnowModal} icon={<Icon as={Users} boxSize={4} />}>{t('I know this person')}</MenuItem>}
          {!isOrganization && knowing && <MenuItem onClick={openUnknowModal} icon={<Icon as={Users} boxSize={4} />}>{t('Remove as known', { context: pronoun })}</MenuItem>}
          <MenuDivider />
          <MenuItem onClick={openMuteModal} icon={<Icon as={EyeOff} boxSize={4} />}>{t('Mute/Unmute')}</MenuItem>
          {!blocked && <MenuItem onClick={openBlockModal} icon={<Icon as={UserX} boxSize={4} />}>{t('Block')}</MenuItem>}
          {blocked && <MenuItem onClick={openUnblockModal} icon={<Icon as={UserX} boxSize={4} />}>{t('Unblock')}</MenuItem>}
          <MenuItem onClick={openReportModal} icon={<Icon as={Flag} boxSize={4} color="red.600" />}><Text color="red.600">{t('Report')}</Text></MenuItem>
        </MenuList>
      </Menu>

      {/* Modals */}
      {showingKnowModal && <KnowModal userId={userId} close={closeKnowModal} />}
      {showingUnknowModal && <UnknowModal userId={userId} close={closeUnknowModal} />}
      {showingBlockModal && <BlockModal userId={userId} close={closeBlockModal} />}
      {showingUnblockModal && <UnblockModal userId={userId} close={closeUnblockModal} />}
      {showingMuteModal && <MuteModal userId={userId} close={closeMuteModal} />}
      {showingReportModal && <ReportModal userId={userId} close={closeReportModal} />}
    </>
  );
};

ActionsOthers.propTypes = {
  userId: PropTypes.number.isRequired,
};

ActionsOthers.defaultProps = {
};

export default ActionsOthers;
