import {
  memo, useEffect, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as messengerSelectors from 'state/messengers/selectors';
import * as messengerActions from 'state/messengers/actions';
import { mediaGifEqual } from 'state/app/equalityFunctions';

import Busy from 'components/UserDisplayName/Busy';
import ParsedContent from 'components/ParsedContent';
import ContentMedia from 'components/ContentMedia';

import ReplyingTo from '../../ReplyingTo';
import DeletedReply from '../../DeletedReply';
import Gif from '../../Gif';

const InReplyTo = ({ messengerId, messageId }) => {
  const dispatch = useDispatch();

  const [deleted, setDeleted] = useState(false);
  const authorId = useSelector(state => messengerSelectors.getMessageAuthorId(state, messageId));
  const authorType = useSelector(
    state => messengerSelectors.getMessageAuthorType(state, messageId),
  );
  const content = useSelector(state => messengerSelectors.getMessageContent(state, messageId));
  const gif = useSelector(
    state => messengerSelectors.getMessageGif(state, messageId),
    mediaGifEqual,
  );
  const media = useSelector(
    state => messengerSelectors.getMessageMedia(state, messageId),
    shallowEqual,
  );

  const busy = !deleted && !content && !media && !gif;

  useEffect(() => {
    const fetch = async () => {
      try {
        await dispatch(messengerActions.fetchSingleMessage(messengerId, messageId));
      } catch (error) {
        setDeleted(true);
      }
    };

    if (busy) {
      fetch();
    }
  }, [busy, messengerId, messageId, dispatch]);

  const renderContent = useCallback(() => {
    if (busy) return <Busy />;
    if (deleted) return <DeletedReply />;

    return (
      <>
        {gif && <Gif data={gif} />}
        <ContentMedia media={media} />
        {content && <ParsedContent content={content} />}
      </>
    );
  }, [busy, deleted, gif, media, content]);


  return (
    <ReplyingTo fullWidth authorType={authorType} authorId={authorId} loading={busy}>
      {renderContent()}
    </ReplyingTo>
  );
};

InReplyTo.propTypes = {
  messengerId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
};

InReplyTo.defaultProps = {
};

export default memo(InReplyTo);
