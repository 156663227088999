import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';

import TypingWrapper from '../TypingWrapper';
import locales from '../../i18n';

const Typing = ({ id }) => {
  const { t } = useTranslation(locales);
  const isTyping = useSelector(state => channelSelectors.getIsTyping(state, id));

  if (!isTyping) return null;

  return (
    <TypingWrapper>{t('typing')}</TypingWrapper>
  );
};

Typing.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(Typing);
