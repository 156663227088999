import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import colors from 'utils/css/colors';
import * as threadSelectors from 'state/threads/selectors';
import * as communitySelectors from 'state/communities/selectors';

import CommunityAvatar from 'components/CommunityAvatar';
import { Unread } from 'components/Icons';

import LastReply from './LastReply';

const Title = styled.div`
  display: flex;
  overflow: hidden;

  > div:first-child {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;
Title.displayName = 'Title';

const ListItem = styled.li`
  display: flex;
  padding: 0;
  margin: 0;

  &:hover {
    ${Title} > div:first-child {
      color: ${props => props.theme.colors.main};
    }
  }
`;
ListItem.displayName = 'ListItem';

const Info = styled.div`
  margin: 0 0 16px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;

  > div {
    margin: 0;
  }
`;
Info.displayName = 'Info';

const Thread = memo(({ threadId }) => {
  const communityId = useSelector(threadSelectors.getCommunityId(threadId));
  const communitySlug = useSelector(state => communitySelectors.selectSlugById(state, communityId));
  const title = useSelector(threadSelectors.getTitle(threadId));
  const slug = useSelector(threadSelectors.getSlug(threadId));
  const isUnread = useSelector(threadSelectors.isUnread(threadId));

  const url = `/+${communitySlug}/${slug}`;

  return (
    <Link to={url}>
      <ListItem>
        <CommunityAvatar communityId={communityId} size="32px" withShadow={false} />
        <Info>
          <Title>
            <div title={title}>{title}</div>
            {isUnread && <div><Unread color={colors.red} /></div>}
          </Title>
          <LastReply threadId={threadId} />
        </Info>
      </ListItem>
    </Link>
  );
});

Thread.propTypes = {
  threadId: PropTypes.string.isRequired,
};

Thread.defaultProps = {
};

export default Thread;
