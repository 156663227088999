import produce from 'immer';

import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  CREATE_SUCCESS,
  FULLY_LOADED,
  PREPEND_RECENT,
  APPEND_RECENT,
  SLUG_LOADING_START,
  SLUG_LOADING_STOP,
  SLUG_NOT_FOUND,
  APPEND_REACTION,
  REMOVE_REACTION,
  APPEND_DISLIKE,
  REMOVE_DISLIKE,
  REMOVE,
  EDIT,
  MARK_AS_READ,
  WIPE,
} from './constants';

export const initialState = {
  data: {},
  recent: [],
  loading: [],
  fullyLoaded: [],
  creating: false,
  creatingError: null,
  bySlug: {
    loading: [],
    notFound: [],
  },
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOAD:
      draft.loading.push(action.id);
      break;

    case LOAD_SUCCESS:
      draft.loading = state.loading.filter(e => e !== action.id);

      Object.keys(action.data).forEach((threadId) => {
        draft.data[threadId] = {
          ...state.data[threadId],
          ...action.data[threadId],
        };
      });
      break;

    case LOAD_FAIL:
      draft.loading = initialState.loading;
      break;

    case FULLY_LOADED:
      draft.fullyLoaded.push(action.id);
      break;

    case CREATE_SUCCESS:
      Object.keys(action.data).forEach((threadId) => {
        draft.data[threadId] = {
          ...state.data[threadId],
          ...action.data[threadId],
        };
      });
      break;

    case PREPEND_RECENT: {
      const recentIds = state.recent.filter(id => !action.data.includes(id));
      draft.recent = [...action.data, ...recentIds];
      break;
    }

    case APPEND_RECENT: {
      // Avoid duplication
      const threads = action.data.filter(id => !(state.recent || []).includes(id));
      draft.recent = [...state.recent, ...threads];
      break;
    }

    case SLUG_LOADING_START: {
      if (!state.bySlug.loading.includes(action.slug)) {
        draft.bySlug.loading.push(action.slug);
      }

      break;
    }

    case SLUG_LOADING_STOP:
      draft.bySlug.loading = state.bySlug.loading.filter(slug => slug !== action.slug);
      break;

    case SLUG_NOT_FOUND: {
      if (!state.bySlug.notFound.includes(action.slug)) {
        draft.bySlug.notFound.push(action.slug);
      }

      break;
    }

    case APPEND_REACTION:
      draft.data[action.threadId].reactedByUserIds.push(action.userId);
      draft.data[action.threadId].dislikedByUserIds = state.data[action.threadId].dislikedByUserIds
        .filter(uId => uId !== action.userId);
      break;

    case REMOVE_REACTION: {
      const reactions = state.data[action.threadId].reactedByUserIds
        .filter(uId => uId !== action.userId);
      draft.data[action.threadId].reactedByUserIds = reactions;
      break;
    }

    case APPEND_DISLIKE:
      draft.data[action.threadId].dislikedByUserIds.push(action.userId);
      draft.data[action.threadId].reactedByUserIds = state.data[action.threadId].reactedByUserIds
        .filter(uId => uId !== action.userId);
      break;

    case REMOVE_DISLIKE: {
      const reactions = state.data[action.threadId].dislikedByUserIds
        .filter(uId => uId !== action.userId);
      draft.data[action.threadId].dislikedByUserIds = reactions;
      break;
    }

    case REMOVE:
      draft.data[action.id] = {
        ...draft.data[action.id],
        deletedAt: new Date(),
      };
      break;

    case EDIT:
      draft.data[action.id] = {
        ...state.data[action.id],
        ...action.data,
      };
      break;

    case MARK_AS_READ: {
      const now = (new Date()).toISOString();
      action.threadIds.forEach((id) => {
        draft.data[id].readAt = now;
        draft.data[id].prevReadAt = action.silent ? now : state.data[id].readAt;
      });
      break;
    }

    case WIPE:
      draft.data = initialState.data;
      draft.recent = initialState.recent;
      draft.loading = initialState.loading;
      draft.fullyLoaded = initialState.fullyLoaded;
      draft.creating = initialState.creating;
      draft.creatingError = initialState.creatingError;
      break;

    default:
  }
});

export default reducer;
