import { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';

import { ProgressClock } from 'components/Icons';

const Time = ({ messageId }) => {
  const time = useSelector(state => channelSelectors.getMessageCreatedAt(state, messageId));

  if (!time) return <span><ProgressClock color="#666" /></span>;
  return <span>{moment(time).format('h:mm a')}</span>;
};

Time.propTypes = {
  messageId: PropTypes.string.isRequired,
};

Time.defaultProps = {
};

export default memo(Time);
