import { useSelector } from 'react-redux';

import * as feedSelectors from 'state/feed/selectors';

import UserAvatar from 'components/UserAvatar';
import UserLink from 'components/UserLink';

interface Props {
  publicationId: string;
}

const Avatar: React.FC<Props> = ({ publicationId }) => {
  const authorId = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectAuthorId(state, publicationId),
  );

  return (
    <UserLink userId={authorId}>
      {/* @ts-ignore */}
      <UserAvatar userId={authorId} size="36px" />
    </UserLink>
  );
};

export default Avatar;
