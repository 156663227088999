import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslation, useSearchUsers } from 'hooks';

import Modal from 'components/Modal';
import Button from 'components/Button';
import InputSearch from 'components/Forms/InputSearch';
import ResultItem from 'components/UserSearch/ResultItem';

import locales from './i18n';

const ResultsWrapper = styled.div`
  max-height: 300px;
  overflow: auto;
  margin-top: 16px;
`;
ResultsWrapper.displayName = 'ResultsWrapper';

const SearchUsersModal = ({
  title,
  close,
  confirm,
  confirmLoading,
  children,
  bypassBlocks,
}) => {
  const { t } = useTranslation(locales);

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [{ value, contentChange: onChange }, results] = useSearchUsers(bypassBlocks);

  const add = useCallback(() => {
    confirm(selectedUserId);
  }, [confirm, selectedUserId]);

  const selectUserId = useCallback(userId => () => {
    setSelectedUserId(userId);
  }, []);

  useEffect(() => {
    if (selectedUserId && !results.find(r => r.id === selectedUserId)) {
      setSelectedUserId(null);
    }
  }, [selectedUserId, results]);

  return (
    <Modal
      title={title}
      onCancel={close}
      actions={[
        <Button
          key="discard-thread-button"
          onClick={add}
          disabled={!selectedUserId}
          loading={confirmLoading}
        >
          {t('global:Confirm')}
        </Button>,
      ]}
    >
      {children}
      <InputSearch value={value} onChange={onChange} />

      <ResultsWrapper>
        {results.map(result => (
          <ResultItem
            key={`search-users-modal-result-${result.id}`}
            active={result.id === selectedUserId}
            data={result}
            onClick={selectUserId(result.id)}
          />
        ))}
      </ResultsWrapper>
    </Modal>
  );
};

SearchUsersModal.propTypes = {
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  confirmLoading: PropTypes.bool,
  children: PropTypes.node,
  bypassBlocks: PropTypes.bool,
};

SearchUsersModal.defaultProps = {
  confirmLoading: false,
  children: null,
  bypassBlocks: false,
};

export default SearchUsersModal;
