import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import UserDisplayName from 'components/UserDisplayName';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const ChannelInvitation = ({
  channel, inviterId, read, timestamp,
}) => {
  const { t } = useTranslation(locales);

  return (
    <AlertContainer timestamp={timestamp} image={channel.avatar} read={read} to={`/chat/channels/${channel.id}`}>
      <strong><UserDisplayName userId={inviterId} /></strong>
      {' '}
      {t('invited you to join')}
      {' '}
      <strong>{channel.name}</strong>
    </AlertContainer>
  );
};

ChannelInvitation.propTypes = {
  read: PropTypes.bool.isRequired,
  channel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
  }).isRequired,
  inviterId: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default ChannelInvitation;
