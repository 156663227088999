import { useState, useEffect } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import Api from 'state/api';
import * as bankSelectors from 'state/bank/selectors';

interface Coupon {
  code: string;
}

const useCoupon = () => {
  const location = useLocation();

  const [coupon, setCoupon] = useState(null);
  const allCoupons = useSelector(bankSelectors.selectCoupons, shallowEqual);

  useEffect(() => {
    const { coupon: code } = qs.parse(location.search, { ignoreQueryPrefix: true });

    const load = async () => {
      try {
        const { data } = await Api.req.get(`/bank/coupons/${code}`);
        setCoupon(data);
      } catch (error) {
        setCoupon(null);
      }
    };

    if (code) {
      const loadedCoupon = allCoupons.find((c: Coupon) => c.code === code);

      if (loadedCoupon) setCoupon(loadedCoupon);
      else if (code) load();
    } else {
      setCoupon(null);
    }
  }, [allCoupons, location.search]);

  return coupon;
};

export default useCoupon;
