import { useState, useCallback } from 'react';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
  Alert,
  ButtonGroup,
  Flex,
} from '@chakra-ui/react';
import { TicketPercentIcon } from 'lucide-react';

import { useTranslation } from 'hooks';
import * as bankSelectors from 'state/bank/selectors';
import * as bankActions from 'state/bank/actions';
import * as appActions from 'state/app/actions';

import locales from '../i18n';

const Coupons = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  const history = useHistory();

  const coupon = useSelector(bankSelectors.selectUnacknowledgedCoupon, shallowEqual);

  const [loading, setLoading] = useState(false);

  const ack = useCallback(async () => {
    try {
      setLoading('ack');
      await dispatch(bankActions.acknowledgeCoupon(coupon.code));
      setLoading(false);
    } catch (error) {
      dispatch(appActions.addError(error));
    }
  }, [dispatch, coupon]);

  const buy = useCallback(async () => {
    try {
      setLoading('buy');
      await dispatch(bankActions.acknowledgeCoupon(coupon.code));
      setLoading(false);
      history.push({
        pathname: '/user/sades/buy',
        search: qs.stringify({ coupon: coupon.code }),
      });
    } catch (error) {
      dispatch(appActions.addError(error));
    }
  }, [dispatch, coupon, history]);

  if (!coupon) return null;

  return (
    <Modal isOpen>
      <ModalOverlay />
      <ModalContent w="4xl">
        <ModalCloseButton />
        <ModalBody textAlign="center">
          <TicketPercentIcon style={{ height: '256px' }} />

          <Heading as="h3" size="lg" mb={8}>{t('You have a new coupon for your next Sades purchase!')}</Heading>

          <Alert status="warning">
            <Flex w="full" direction="column">
              <Text fontSize="2xl" textAlign="center" w="full">
                {coupon.discount > 0 && `${coupon.discount}% off`}
                {!coupon.discount && coupon.increment > 0 && `+ ${coupon.increment}%`}
              </Text>
              <Text textAlign="center" mt={1} fontSize="sm">
                {t('Code')}
                {': '}
                {coupon.code}
              </Text>
            </Flex>
          </Alert>
        </ModalBody>

        <ModalFooter mt={4}>
          <ButtonGroup spacing={4}>
            <Button onClick={ack} isLoading={loading === 'ack'}>{t('Maybe later')}</Button>
            <Button onClick={buy} isLoading={loading === 'buy'} variant="primary">{t('Buy now')}</Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

Coupons.propTypes = {
};

Coupons.defaultProps = {
};

export default Coupons;
