import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';
import * as channelActions from 'state/channels/actions';
import * as appActions from 'state/app/actions';

import { useTranslation } from 'hooks';

import Modal from 'components/Modal';
import Button from 'components/Button';
import ChannelForm from 'components/ChannelForm';

import locales from '../../i18n';

const EditModal = ({ channelId, close, openDelete }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const name = useSelector(state => channelSelectors.getName(state, channelId));
  const description = useSelector(state => channelSelectors.getDescription(state, channelId));
  const privacy = useSelector(state => channelSelectors.getPrivacy(state, channelId));

  const [editData, setEditData] = useState({ name, description, privacy });
  const [editing, setEditing] = useState(false);

  const onDeleteClick = () => {
    openDelete();
    close();
  };

  const onEditConfirm = async () => {
    try {
      setEditing(true);
      await dispatch(channelActions.edit(channelId, editData));

      dispatch(appActions.addToast(t('Channel succesfully edited!')));
      setEditing(false);
      close();
    } catch (error) {
      dispatch(appActions.addError(error));
      setEditing(false);
      close();
    }
  };

  return (
    <Modal
      title={t('Edit channel')}
      onCancel={close}
      actions={[
        <Button key="delete-channel-confirm" onClick={onDeleteClick} className="empty">{t('Delete channel')}</Button>,
        <Button key="edit-channel-confirm" onClick={onEditConfirm} loading={editing}>{t('global:Confirm')}</Button>,
      ]}
    >
      <ChannelForm onDataChange={setEditData} initialData={{ name, description, privacy }} />
    </Modal>
  );
};

EditModal.propTypes = {
  channelId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  openDelete: PropTypes.func.isRequired,
};

export default EditModal;
