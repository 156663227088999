import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation, useOpenClose } from 'hooks';
import * as messengerSelectors from 'state/messengers/selectors';
import * as messengerActions from 'state/messengers/actions';

import { ReactionAdd, Reply, DotsVertical } from 'components/Icons';
import EmojiPicker from 'components/EmojiPicker';
import Menu, { Item as MenuItem } from 'components/Menu';

import DeleteOption from './DeleteOption';
import locales from '../i18n';

const OptionsWrapper = styled.div.attrs({
  className: 'options',
})`
  display: flex;
  visibility: hidden;
  position: absolute;
  top: -4px;
  right: -8px;
  background-color: ${props => props.theme.colors.mainLight};
  z-index: 10;

  ${props => props.active && `
    visibility: visible;
  `}

  button:not(.emoji-mart-anchor) {
    background: none;
    border: 1px solid transparent;
    border-radius: 8px;
    outline: none;
    cursor: pointer;
    background-color: rgba(255, 255, 255, .3);
    margin-right: 4px;
    font-size: 12px;
    display: flex;
    align-items: center;
    min-width: 38px;
    justify-content: center;
    padding: 2px 0;

    &:hover {
      border-color: #ec4e3e5c;
      background-color: rgba(255, 255, 255, .5);

      span {
        color: ${props => props.theme.colors.main};
      }
    }

    span {
      margin: 0 1px;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
OptionsWrapper.displayName = 'OptionsWrapper';

const PickerWrapper = styled.div`
  position: fixed;
  visibility: visible;
  z-index: 20;
`;
PickerWrapper.displayName = 'PickerWrapper';

const Options = ({ messageId, messengerId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const picker = useRef(null);
  const selector = useRef(null);
  const [opened,, close, toggle] = useOpenClose(false);
  const [showingMoreActions,, hideMoreActions, toggleMoreActions] = useOpenClose(false);
  const [pickerOffset, setPickerOffset] = useState({});

  const hasReactions = useSelector(
    state => messengerSelectors.messageHasReactions(state, messageId),
  );
  const outgoing = useSelector(state => messengerSelectors.isMessageOutgoing(state, messageId));

  const add = name => dispatch(messengerActions.addReaction(messageId, name));

  useEffect(() => {
    const handleClickOutside = (event) => {
      try {
        if (!picker.current.contains(event.target) && !selector.current.contains(event.target)) {
          close();
        }
      } catch (error) {
        //
      }
    };

    if (opened) {
      document.addEventListener('mousedown', handleClickOutside, false);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [opened, close]);

  const addReaction = (emoji) => {
    add(emoji.id);
    close();
  };

  const addReplyTo = () => {
    dispatch(messengerActions.addReplyTo(messengerId, messageId));
  };

  const addEditComposer = () => {
    dispatch(messengerActions.addEditComposer(messengerId, messageId));
    hideMoreActions();
  };

  const toggleEmojiPicker = (e) => {
    setPickerOffset({ left: e.clientX, top: e.clientY });
    toggle(e);
  };

  return (
    <OptionsWrapper active={showingMoreActions}>
      {opened && (
        <PickerWrapper style={pickerOffset} ref={picker}>
          <EmojiPicker
            onSelect={addReaction}
            onMouseLeave={close}
          />
        </PickerWrapper>
      )}

      {!hasReactions && (
        <>
          <button onClick={toggleEmojiPicker} type="button" ref={selector} data-tip data-for={`add-reaction-${messageId}`}>
            <ReactionAdd color="#AAA" />
          </button>
          <ReactTooltip effect="solid" id={`add-reaction-${messageId}`}>{t('Add reaction')}</ReactTooltip>
        </>
      )}

      <button onClick={addReplyTo} type="button" data-tip data-for={`replyTo-${messageId}`}>
        <Reply color="#AAA" />
      </button>
      <ReactTooltip effect="solid" id={`replyTo-${messageId}`}>{t('Reply')}</ReactTooltip>

      {outgoing && (
        <>
          <button onClick={toggleMoreActions} type="button">
            <DotsVertical color="#AAA" />
          </button>
          <Menu open={showingMoreActions} onClose={hideMoreActions}>
            <MenuItem onClick={addEditComposer}>{t('global:Edit')}</MenuItem>
            <DeleteOption messageId={messageId} />
          </Menu>
        </>
      )}
    </OptionsWrapper>
  );
};

Options.propTypes = {
  messageId: PropTypes.string.isRequired,
  messengerId: PropTypes.string.isRequired,
};

Options.defaultProps = {
};

export default Options;
