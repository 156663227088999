import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const normalPath = 'M12,3C17.5,3 22,6.58 22,11C22,15.42 17.5,19 12,19C10.76,19 9.57,18.82 8.47,18.5C5.55,21 2,21 2,21C4.33,18.67 4.7,17.1 4.75,16.5C3.05,15.07 2,13.13 2,11C2,6.58 6.5,3 12,3Z';
const outlinePath = 'M12,3C6.5,3 2,6.58 2,11C2.05,13.15 3.06,15.17 4.75,16.5C4.75,17.1 4.33,18.67 2,21C4.37,20.89 6.64,20 8.47,18.5C9.61,18.83 10.81,19 12,19C17.5,19 22,15.42 22,11C22,6.58 17.5,3 12,3M12,17C7.58,17 4,14.31 4,11C4,7.69 7.58,5 12,5C16.42,5 20,7.69 20,11C20,14.31 16.42,17 12,17Z';

const Comment = ({ color, outline, style }) => (
  <svg viewBox="0 0 24 24" fill="none" style={style}>
    <path
      d={outline ? outlinePath : normalPath}
      fill={color}
    />
  </svg>
);

Comment.propTypes = IconType;
Comment.defaultProps = IconDefaults;

export default memo(Comment);
