import styled from 'styled-components';

const ChecklistWrapper = styled.div(props => `
  ${props.theme.css.content}

  .emptystate {
    margin: 64px 16px;
  }

  h3 {
    margin: 32px 0 0;
  }

  td, th {
    padding: 4px 8px;
  }

  th {
    &:first-child {
      width: 100%;
    }

    font-weight: 500;
    color: #333;
  }

  tbody tr:nth-child(2n) {
    background-color: ${props.theme.colors.mainLight};
  }
`);
ChecklistWrapper.displayName = 'ChecklistWrapper';

export default ChecklistWrapper;
