import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import useTranslation from 'hooks/useTranslation';
import useFocusOnMount from 'hooks/useFocusOnMount';
import useInputValue from 'hooks/useInputValue';

import Button from 'components/Button';
import Input from 'components/Forms/Input';
import Select from 'components/Select';

import { GENDERS, USERS_PRONOUNS } from '../../constants';
import locales from './i18n';

const Form = styled.div`
  background: white;
  border-radius: 10px;
  min-width: 330px;
  padding: 30px;
  position: relative;

  input {
    font-size: 20px;
    font-weight: 300;
    line-height: 40px;
  }

  @media(max-width: 767px) {
    display: none;
  }
`;

const Separator = styled.div`
  margin: 15px 0;
`;

const FormFooter = styled.div`
  text-align: center;
  margin-top: 50px;
`;

const SignupForm = () => {
  const { t } = useTranslation(locales);
  const usernameEl = useFocusOnMount();

  const username = useInputValue('');
  const email = useInputValue('');
  const [gender, setGender] = useState(null);
  const [pronoun, setPronoun] = useState(null);

  const signupfields = [];
  if (username.value.length) signupfields.push(`username=${username.value}`);
  if (email.value.length) signupfields.push(`email=${email.value}`);
  if (gender) signupfields.push(`gender=${gender.value}`);
  if (pronoun) signupfields.push(`pronoun=${pronoun.value}`);

  const signuphref = `/signup?${signupfields.join('&')}`;

  const onGenderChange = useCallback((g) => {
    setGender(g);
  }, []);

  const onPronounChange = useCallback((p) => {
    setPronoun(p);
  }, []);

  const genderOptions = Object.values(GENDERS).map(g => ({
    label: t(`global:GENDER.${g.label}`),
    value: g.label,
  }));

  const pronounOptions = Object.values(USERS_PRONOUNS).map(p => ({
    label: t(`global:PRONOUN.${p}`),
    value: p,
  }));

  return (
    <Form className="signup-form">
      <Input
        ref={usernameEl}
        placeholder={t('global:Username')}
        {...username}
      />
      <Input
        placeholder={t('global:E-mail')}
        {...email}
      />

      <Separator>
        <Select
          value={gender}
          onChange={onGenderChange}
          placeholder={t('global:Gender')}
          options={genderOptions}
        />
        <Select
          value={pronoun}
          onChange={onPronounChange}
          placeholder={t('global:Pronoun')}
          options={pronounOptions}
        />
      </Separator>

      <FormFooter>
        <Link to={signuphref}><Button>{t('Join the network')}</Button></Link>
      </FormFooter>
    </Form>
  );
};

SignupForm.propTypes = {
};

SignupForm.defaultProps = {
};

export default SignupForm;
