import {
  Fragment, useEffect, useCallback, useRef, useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import fastdom from 'fastdom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as messengerSelectors from 'state/messengers/selectors';
import * as messengerActions from 'state/messengers/actions';
import * as authSelectors from 'state/auth/selectors';

import { useScrollAtBottomSensor } from 'hooks';

import Wrapper from './Wrapper';
import Container from './Container';
import RequestMessage from './RequestMessage';
import LoadingMore from './LoadingMore';
import DirectMessage from '../Bubble/Messenger';
import Day from '../Day';

const MessengerMessages = ({ id }) => {
  const dispatch = useDispatch();

  const isLoadingMessages = useRef(false);
  const prevHeight = useRef(null);

  const messages = useSelector(
    state => messengerSelectors.selectMessagesByMessengerId(state, id),
    shallowEqual,
  );
  const meId = useSelector(authSelectors.selectUserId);
  const isFullyLoaded = useSelector(state => messengerSelectors.isFullyLoaded(state, id));
  const entity = useSelector(state => messengerSelectors.selectRequested(state, id), shallowEqual);

  const [container, isAtBottom] = useScrollAtBottomSensor([messages]);

  useEffect(() => {
    dispatch(messengerActions.setActive(id));
    return () => dispatch(messengerActions.unmount(id));
  }, [dispatch, id]);

  const focusHandler = useCallback(() => {
    if (document.hasFocus()) dispatch(messengerActions.setActive(id));
    else dispatch(messengerActions.setActive(null));
  }, [dispatch, id]);

  const blurHandler = useCallback(() => {
    dispatch(messengerActions.setActive(null));
  }, [dispatch]);

  useEffect(() => {
    focusHandler();

    window.addEventListener('focus', focusHandler);
    window.addEventListener('blur', blurHandler);

    return () => {
      window.removeEventListener('focus', focusHandler);
      window.removeEventListener('blur', blurHandler);
      dispatch(messengerActions.setActive(null));
    };
  }, [entity.unreadCount, dispatch, focusHandler, blurHandler]);

  useEffect(() => {
    dispatch(messengerActions.scrollAtBottomChange(id, isAtBottom));
  }, [isAtBottom, dispatch, id]);

  useEffect(() => {
    const el = container.current;

    const loadMoreScrollChanged = async () => {
      const loadMore = async () => {
        await dispatch(messengerActions.loadMoreMessages(id));
        isLoadingMessages.current = false;
      };

      fastdom.measure(() => {
        if (!isLoadingMessages.current && el.scrollTop < 300) {
          isLoadingMessages.current = true;
          prevHeight.current = el ? el.scrollHeight : null;
          loadMore();
        }
      });
    };

    if (container.current) {
      el.addEventListener('scroll', loadMoreScrollChanged);
      setTimeout(loadMoreScrollChanged, 500);
    }

    return () => {
      el.removeEventListener('scroll', loadMoreScrollChanged);
    };
  }, [container, dispatch, id]);

  useLayoutEffect(() => {
    if (prevHeight.current) {
      // Restore scroll position after loading previous messages
      const nextHeight = container.current.scrollHeight;
      container.current.scrollTo(0, nextHeight - prevHeight.current);
      prevHeight.current = null;
    }
  }, [messages, container]);

  return (
    <Wrapper>
      <Container ref={container}>
        {!isFullyLoaded && messages.length > 0 && (
          <LoadingMore />
        )}
        {isFullyLoaded && <RequestMessage messengerId={id} />}
        {messages.map((message, index) => {
          if (!message) return null;
          const key = message.id || message.referenceId;
          const hideAuthor = (
            index > 0
            && (
              messages[index - 1].authorId === message.authorId
              || (messages[index - 1].authorId === meId && !message.authorId)
            )
            && moment(message.createdAt).diff(messages[index - 1].createdAt, 'seconds') < 60
          );
          const showDay = (
            index === 0 || !moment(messages[index - 1].createdAt).isSame(message.createdAt, 'day')
          );

          return (
            <Fragment key={`messenger-${key}`}>
              {showDay && <Day date={message.createdAt} />}
              <DirectMessage
                key={`messenger-dm-${key}`}
                messageId={key}
                messengerId={id}
                showAvatar={!hideAuthor}
              />
            </Fragment>
          );
        })}
      </Container>
    </Wrapper>
  );
};

MessengerMessages.propTypes = {
  id: PropTypes.string.isRequired,
};

export default MessengerMessages;
