import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';
import * as appSelectors from 'state/app/selectors';
import * as appActions from 'state/app/actions';

import Button from 'components/Button';

import locales from '../i18n';

const SubmitButton = ({ publicationId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const id = `comment-${publicationId}`;

  const canSubmit = useSelector(state => appSelectors.selectIsComposerReadyToSubmit(state, id));
  const isSaving = useSelector(state => appSelectors.selectIsComposerSaving(state, id));

  const disabled = !canSubmit || isSaving;

  const submit = useCallback(async () => {
    try {
      dispatch(appActions.disableComposer(id));
      await dispatch(appActions.composerSendToServer(id));
      dispatch(appActions.enableComposer(id));
    } catch (error) {
      dispatch(appActions.addError(error));
      dispatch(appActions.enableComposer(id));
    }
  }, [id, dispatch]);

  return (
    <Button
      className="empty"
      onClick={submit}
      disabled={disabled}
      fontColor={disabled ? colors.grey : colors.red}
    >
      {t('global:Send')}
    </Button>
  );
};

SubmitButton.propTypes = {
  publicationId: PropTypes.string.isRequired,
};

SubmitButton.defaultProps = {
};

export default SubmitButton;
