import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import { useTitle } from 'hooks';
import * as threadSelectors from 'state/threads/selectors';

import MainHeader from 'components/Header';
import { ArrowBack, Lock } from 'components/Icons';

import Options from './Options';

const Header = ({ eventHeaderVisible }) => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const threadId = useSelector(threadSelectors.getIdBySlug(params.threadSlug));
  const title = useSelector(threadSelectors.getTitle(threadId));
  const isClosed = useSelector(threadSelectors.isClosed(threadId));

  useTitle(title);

  const goBack = useCallback(() => {
    const to = location.search.includes('showAll') ? '/communities' : `/+${params.communitySlug}`;
    history.push(to);
  }, [history, location.search, params.communitySlug]);

  const renderIcon = useCallback(() => (
    <ArrowBack color="black" />
  ), []);

  const renderActions = useCallback(() => (
    <Options threadId={threadId} eventHeaderVisible={eventHeaderVisible} />
  ), [threadId, eventHeaderVisible]);

  const renderTitle = useCallback(() => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {isClosed && <span style={{ marginRight: '4px' }}><Lock color="#A8A8A8" /></span>}
      <span>{title}</span>
    </div>
  ), [title, isClosed]);

  return (
    <MainHeader
      backgroundColor="#FFFFFF"
      foregroundColor={colors.red}
      renderTitle={renderTitle}
      renderIcon={renderIcon}
      renderActions={renderActions}
      onBackClick={goBack}
      negativeBadge
    />
  );
};

Header.propTypes = {
  eventHeaderVisible: PropTypes.bool.isRequired,
};

Header.defaultProps = {
};

export default Header;
