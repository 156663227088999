import styled from 'styled-components';

import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

const Wrapper = styled.div`
  display: flex;
  padding: 8px;

  > div:last-child {
    flex: 1;
    margin-left: 8px;

    .displayname {
      color: ${props => props.theme.colors.main};
      font-weight: 500;
      font-size: 14px;
    }

    .username {
      color: ${props => props.theme.colors.secondary};
      font-size: 12px;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

interface Props {
  user: {
    id: number,
    username: string,
  }
}

const SuggestionMention = ({ user }: Props) => (
  <Wrapper>
    <div><UserAvatar userId={user.id} size="32px" /></div>
    <div>
      <UserDisplayName userId={user.id} />
      <div className="username">{`@${user.username.toLowerCase()}`}</div>
    </div>
  </Wrapper>
);

export default SuggestionMention;
