import { useState, useCallback, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Button, ButtonGroup, ButtonProps, Icon, IconButton,
} from '@chakra-ui/react';
import { ChevronDown, UserPlus } from 'lucide-react';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as authActions from 'state/auth/actions';
import * as appActions from 'state/app/actions';

import UsersListsModal from './UsersListsModal';
import locales from './i18n';

interface Props extends ButtonProps {
  userId: number;
}

const UserFollowButton: React.FC<Props> = ({ userId, size, ...buttonProps }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  const history = useHistory();
  // const theme = useContext(ThemeContext);

  const myUserId = useSelector(authSelectors.selectUserId);
  // @ts-ignore
  const following = useSelector(state => authSelectors.selectAmIFollowing(state, userId));
  // @ts-ignore
  const inLists = useSelector(state => authSelectors.selectInListsIds(state, userId), shallowEqual);

  const [loading, setLoading] = useState(false);
  const [showingListsModal, setShowingListsModal] = useState(false);

  const openListsModal = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowingListsModal(true);
  }, []);
  const closeListsModal = useCallback(() => { setShowingListsModal(false); }, []);

  const onClick = useCallback(async () => {
    try {
      if (!myUserId) {
        dispatch(appActions.setMiniprofile(null));
        history.push('/login');
      } else {
        setLoading(true);
        if (following) {
          await dispatch(authActions.unfollow(userId));
        } else {
          await dispatch(authActions.follow(userId));
        }
      }
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    setLoading(false);
  }, [dispatch, userId, following, myUserId, history]);

  const saveFromLists = useCallback(async (lists: unknown) => {
    try {
      closeListsModal();
      setLoading(true);
      await dispatch(authActions.listsChange(userId, lists));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    setLoading(false);
  }, [dispatch, closeListsModal, userId]);

  if (!userId || userId === myUserId) return null;

  return (
    <>
      <ButtonGroup isAttached size={size}>
        <Button
          leftIcon={<Icon as={UserPlus} />}
          onClick={onClick}
          isLoading={loading}
          variant={following ? 'solid' : 'primary'}
          {...buttonProps}
        >
          {following ? t('global:Following') : t('global:Follow')}
        </Button>

        <IconButton
          aria-label="Lists"
          icon={<Icon as={ChevronDown} />}
          onClick={myUserId ? openListsModal : undefined}
          variant={following ? 'solid' : 'primary'}
          {...buttonProps}
        />
      </ButtonGroup>

      {showingListsModal && (
        <UsersListsModal
          close={closeListsModal}
          initialLists={inLists}
          onConfirm={saveFromLists}
        />
      )}
    </>
  );
};

export default UserFollowButton;
