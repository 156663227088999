import styled from 'styled-components';

const Layout = styled.div`
  @media(min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 364px;
    grid-template-areas: 'body sidebar';
    column-gap: 40px;
    padding: 0 50px;
  }

  > div {

    &:nth-child(2) {
      margin: 16px 0;
      grid-area: body;
    }

    &:nth-child(1) {
      margin: 32px 0;
      grid-area: sidebar;
    }
  }
`;
Layout.displayName = 'Layout';

export default Layout;
