import { schema } from 'normalizr';

const user = new schema.Entity('users');
user.define({
  relationships: [
    { relatesTo: user },
  ],
});

export default user;
