import PropTypes from 'prop-types';
import styled from 'styled-components';

import BotAvatar from 'components/BotAvatar';
import BotName from 'components/BotName';

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  z-index: 1;

  ${props => (props.selected ? '&' : '&:hover')} {
    background-color: #e0e0e0;
  }
`;

const Name = styled.div`
  align-self: center;
  margin-left: 8px;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ParticipantContent = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
`;

const Badge = styled.div`
  font-size: 10px;
  background: #ccc;
  color: #333;
  padding: 4px;
  line-height: 10px;
  margin-left: 4px;
`;
Badge.displayName = 'Badge';

const ParticipantBot = ({ botId, style }) => (
  <div style={style}>
    <Wrapper>
      <ParticipantContent>
        <BotAvatar botId={botId} size="24px" />
        <Name><BotName botId={botId} /></Name>
        <Badge>BOT</Badge>
      </ParticipantContent>
    </Wrapper>
  </div>
);

ParticipantBot.propTypes = {
  botId: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
};

ParticipantBot.defaultProps = {
};

export default ParticipantBot;
