import styled from 'styled-components';

import colors from 'utils/css/colors';

const Header = styled.div`
  display: flex;
  background-color: ${colors.red};
  padding: 20px 50px;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 767px) {
    svg {
      width: 75%;
      margin: 0 auto;
    }
  }
`;

export default Header;
