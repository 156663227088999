import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { HStack, Icon, Text } from '@chakra-ui/react';

import * as authSelectors from 'state/auth/selectors';
import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';
import ChannelType from 'state/channels/type';

import Card from 'components/Card';
import { Account, AccessPoint, ShieldStar } from 'components/Icons';
import MembershipRequestButton from 'components/ChannelMembershipRequestButton';

import locales from '../i18n';
import { CHANNEL_ROLES } from '../../../constants';

const Channel = ({ channel }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const userIsLoggedIn = useSelector(authSelectors.loggedIn);
  const participants = (channel.participants || []);
  const online = useSelector(userSelectors.onlineCount(participants.map(p => p.userId)));
  const ownerId = (participants.find(p => p.role === CHANNEL_ROLES.OWNER) || {}).userId;
  const owner = useSelector(userSelectors.getById(ownerId), shallowEqual);
  if (owner && owner.loading) dispatch(userActions.fetchData(ownerId));

  return (
    <Card
      avatar={{
        src: channel.avatar,
        alt: channel.name,
        type: 'rounded',
      }}
      title={channel.name}
      link={`/chat/channels/${channel.id}`}
      description={(
        <>
          <HStack fontSize="sm" color="gray.500" mt={-3} mb={3}>
            <HStack title={t('Members')} spacing={1}>
              <Icon as={Account} fill={colors.grey} boxSize={5} />
              <Text>{channel.participantCount}</Text>
            </HStack>

            {userIsLoggedIn && (
              <HStack title={t('Online')} spacing={1}>
                <Icon as={AccessPoint} fill={colors.grey} boxSize={5} />
                <Text>{online}</Text>
              </HStack>
            )}

            {owner && (
              <HStack title={t('Owner', { context: owner.pronoun })} spacing={1}>
                <Icon as={ShieldStar} fill={colors.grey} boxSize={5} />
                <Text>{owner.displayname}</Text>
              </HStack>
            )}
          </HStack>
          <span>{channel.description}</span>
        </>
      )}
      action={(
        <MembershipRequestButton channel={channel} />
      )}
    />
  );
};

Channel.propTypes = {
  channel: ChannelType.isRequired,
};

export default Channel;
