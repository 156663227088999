import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, useTheme } from '@chakra-ui/react';

import * as feedSelectors from 'state/feed/selectors';
import * as feedActions from 'state/feed/actions';
import * as appActions from 'state/app/actions';

import SpankIcon from 'components/Icons/Spank2';

interface Props {
  publicationId: string;
}

const Spank: React.FC<Props> = ({ publicationId }) => {
  const dispatch = useDispatch();
  const { colors } = useTheme();

  const spankCount = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectSpankCount(state, publicationId),
  );

  const userHasSpanked = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.userHasSpanked(state, publicationId),
  );

  const [spanking, setSpanking] = useState(false);
  const [unspanking, setUnspanking] = useState(false);
  const spanked = !unspanking && (spanking || userHasSpanked);

  const spankClick = useCallback(async () => {
    try {
      if (spanked) {
        setUnspanking(true);
        await dispatch(feedActions.deletePublicationReaction(publicationId));
        setUnspanking(false);
      } else {
        setSpanking(true);
        await dispatch(feedActions.createPublicationReaction(publicationId));
        setSpanking(false);
      }
    } catch (error) {
      setSpanking(false);
      setUnspanking(false);
      dispatch(appActions.addError(error));
    }
  }, [dispatch, spanked, publicationId]);

  return (
    <Button
      leftIcon={<SpankIcon fill={spanked ? colors.brand[500] : 'transparent'} borderColor={spanked ? colors.brand[900] : 'black'} w="24px" h="24px" />}
      onClick={spankClick}
    >
      {spankCount}
    </Button>
  );
};

export default Spank;
