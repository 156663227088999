import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as eventSelectors from 'state/events/selectors';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const RsvpBanned = ({ timestamp, eventId, read }) => {
  const { t } = useTranslation(locales);

  const flyer = useSelector(state => eventSelectors.selectFlyer(state, eventId));
  const name = useSelector(state => eventSelectors.selectName(state, eventId));
  const links = useSelector(state => eventSelectors.selectLinks(state, eventId), shallowEqual);

  const link = (links[0] || '').replace('https://mazmo.net', '');

  return (
    <AlertContainer timestamp={timestamp} image={<img src={flyer} alt="Event flyer" width="40px" />} to={link} read={read}>
      <Trans t={t} ns="alerts" i18nKey="rsvp.banned">
        An organizer of
        {' '}
        <strong>{name}</strong>
        {' '}
        banned you from it.
      </Trans>
    </AlertContainer>
  );
};

RsvpBanned.propTypes = {
  read: PropTypes.bool.isRequired,
  eventId: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

RsvpBanned.defaultProps = {
};

export default RsvpBanned;
