import styled from 'styled-components';
import PropTypes from 'prop-types';

const NavItem = styled.li`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 22px;
  position: relative;
  margin: 0;
  height: 100%;
  text-align: center;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;

  @media(max-width: 768px) {
    padding: 0 6px;
  }
  @media(min-width: 768px) {
    &:hover {
      border-color: white;
    }
  }

  .notification {
    width: 16px;
    height: 16px;
    background-color: ${props => props.theme.colors.warningBackground};
    border-radius: 100%;
    position: absolute;
    z-index: 1;
    top: 8px;
    right: 10px;
    border: 3px solid ${props => props.theme.colors.main};
  }

  &:hover {
    .notification {
      border-color: #f06153;
    }
  }
  ${props => props.pressed && `
    .notification {
      border-color: #f06153;
    }
  `}

  > div, > a {
    color: white;
    text-decoration: none;
    display: flex;
    height: 100%;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    width: 100%;

    padding: 0 16px;
    box-sizing: border-box;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
    ${props => props.pressed && `
      background-color: rgba(255, 255, 255, 0.1);
    `}

    > div > svg {
      width: 32px;
    }

    @media(min-width: 768px) {
      > svg {
        display: none;
      }
    }

    @media(max-width: 768px) {
      padding: 0;

      > svg {
        width: 32px;
      }

      > span {
        display: none;
      }
    }
  }

  .nav-dropdown {
    color: black;
    font-weight: 300;
    text-transform: none;
    font-size: 16px;
    text-align: left;
  }
`;

NavItem.propTypes = {
  pressed: PropTypes.bool,
};

NavItem.defaultProps = {
  pressed: false,
};

export default NavItem;
