import { useEffect, useRef, useState } from 'react';
import fastdom from 'fastdom';

const OFFSET = 60;

const useScrollAtBottomSensor = (condition: unknown[]) => {
  const ref = useRef<HTMLElement>(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const initialized = useRef(false);

  useEffect(() => {
    const el = ref.current;

    const elementScroll = (e: Event) => {
      fastdom.measure(() => {
        const target = e.target as HTMLElement;
        const sh = target.scrollHeight;

        if (!initialized.current) {
          initialized.current = true;
          target.scrollTo(0, sh);
        } else {
          setIsAtBottom(target.scrollTop + target.clientHeight + OFFSET >= sh);
        }
      });
    };

    el?.addEventListener('scroll', elementScroll);

    return () => el?.removeEventListener('scroll', elementScroll);
  }, []);

  useEffect(() => {
    if (isAtBottom) {
      const el = ref.current;
      if (el) {
        // Timeout is for send the scroll to the end of the event loop
        setTimeout(() => el?.scrollTo(0, el.scrollHeight), 0);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, condition);

  return [ref, isAtBottom];
};

export default useScrollAtBottomSensor;
