import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useTranslation } from 'hooks';

import Warning from 'components/Warning';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import Countries from 'components/ProfileForm/Countries';
import Regions from 'components/ProfileForm/Regions';

import * as authActions from 'state/auth/actions';
import * as authSelectors from 'state/auth/selectors';

import locales from '../i18n';

const Wrapper = styled.div`
  text-align: center;
  padding: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    margin-bottom: 12px;
  }
`;
Wrapper.displayName = 'Wrapper';

const LocationMissing = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const isOrganization = useSelector(authSelectors.isOrganization);

  const [country, setCountry] = useState(null);
  const [region, setRegion] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateLocation = useCallback(async () => {
    setLoading(true);
    await dispatch(authActions.updateProfile({
      country,
      region,
    }));
    setLoading(false);
  }, [country, region, dispatch]);

  if (isOrganization) return null;

  if (loading) return <Spinner color="#A8A8A8" />;

  return (
    <Warning fullMargin>
      <Wrapper>
        <div>{t('Your don\'t have a location set. Do you want to update it?')}</div>
        <Countries value={country} onChange={setCountry} solid />
        <Regions value={region} onChange={setRegion} countryId={country} solid />

        <Button
          onClick={updateLocation}
          color="white"
          fontColor="black"
          disabled={!country}
        >
          {t('Update')}
        </Button>
      </Wrapper>
    </Warning>
  );
};

LocationMissing.propTypes = {
};

LocationMissing.defaultProps = {
};

export default LocationMissing;
