/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';

import * as feedSelectors from 'state/feed/selectors';

const Context = createContext();

export class ClientSocket extends React.Component {
  constructor(props) {
    super(props);

    this.socket = io(props.url, { transports: ['websocket'], auth: { token: props.token } });
  }

  render() {
    const { children } = this.props;
    return (
      <Context.Provider value={this.socket}>
        {children}
      </Context.Provider>
    );
  }
}


ClientSocket.propTypes = {
  url: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const useSocket = (eventKey, callback) => {
  const socket = useContext(Context);
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (eventKey && socket) {
      // eslint-disable-next-line no-inner-declarations
      function socketHandler() {
        // eslint-disable-next-line
        callbackRef.current && callbackRef.current.apply(this, arguments);
      }

      socket.on(eventKey, socketHandler);
      return () => socket.removeListener(eventKey, socketHandler);
    }

    return () => null;
  }, ['eventKey']);

  return socket;
};

export const useFeedwatch = () => {
  const socket = useContext(Context);
  const inFeedOrQueuePublicationIds = useSelector(feedSelectors.selectInFeedOrQueuePublicationIds);

  useEffect(() => {
    if (socket) {
      socket.emit('watchPublications', inFeedOrQueuePublicationIds);
    }
  }, [inFeedOrQueuePublicationIds, socket]);
};
