import styled from 'styled-components';

const Columns = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
Columns.displayName = 'Columns';

export default Columns;
