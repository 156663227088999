import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { HStack } from '@chakra-ui/react';

import * as authSelectors from 'state/auth/selectors';

import UserFollowButton from 'components/UserFollowButton';
import UserBlockButton from 'components/UserBlockButton';

const NonFollowingButtons = ({ userId }) => {
  const following = useSelector(state => authSelectors.selectAmIFollowing(state, userId));

  if (following) return null;

  return (
    <HStack
      position={{ base: 'fixed', md: 'static' }}
      top={{ base: '64px', md: 'auto' }}
      width={{ base: '100%', md: 'auto' }}
      left={{ base: '0', md: 'auto' }}
      backgroundColor={{ base: 'brand.500', md: 'transparent' }}
      padding={{ base: '4px 0', md: '0' }}
      boxSizing={{ base: 'border-box', md: 'content-box' }}
      justifyContent={{ base: 'center', md: 'auto' }}
    >
      <UserFollowButton userId={userId} size="sm" />
      <UserBlockButton userId={userId} size="sm" />
    </HStack>
  );
};

NonFollowingButtons.propTypes = {
  userId: PropTypes.number.isRequired,
};

NonFollowingButtons.defaultProps = {
};

export default NonFollowingButtons;
