import PropTypes from 'prop-types';

export const IconType = {
  color: PropTypes.string,
  outline: PropTypes.bool,
  className: PropTypes.string,
};

export const IconDefaults = {
  color: 'black',
  outline: false,
  className: '',
};
