import PropTypes from 'prop-types';

import ChannelType from 'state/channels/type';

import Channel from './Channel';

const List = ({ data }) => data.map(channel => <Channel key={channel.id} channel={channel} />);

List.propTypes = {
  data: PropTypes.arrayOf(ChannelType).isRequired,
};

export default List;
