import styled from 'styled-components';

const Box = styled.div.attrs({
  className: 'modal',
})`
  /* :not(:last-child) {
    display: none;
  } */

  background-color: white;
  padding: 32px;
  border-radius: 8px;
  width: calc(100% - 16px * 2);
  max-height: calc(100% - 16px * 2);
  max-width: 600px;
  overflow: auto;
  box-sizing: border-box;
  z-index: ${props => props.zIndex};
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${props => props.fullHeight && `
    height: calc(100% - 16px * 2);
  `}
`;

export default Box;
