import { schema } from 'normalizr';

import user from 'state/users/schema';
import community from 'state/communities/schema';

const membership = new schema.Entity('memberships', {
  community,
  user,
}, {
  idAttribute: '_id',
});

export default membership;
