import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as authActions from 'state/auth/actions';
import * as appActions from 'state/app/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';

import locales from './i18n';

const BouncedAlert = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  const history = useHistory();

  const [closed, setClosed] = useState(false);
  const [resending, setResending] = useState(false);
  const hasBounced = useSelector(authSelectors.selectHasEmailBounced);
  const address = useSelector(authSelectors.selectEmail);

  const updateEmail = useCallback(() => {
    history.push('/user/edit/security');
    setClosed(true);
  }, [history]);

  const resendConfirmation = useCallback(async () => {
    try {
      setResending(true);

      await dispatch(authActions.resendConfirmation());
      dispatch(appActions.addToast(t('Confirmation email sent')));

      setClosed(true);
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    setResending(false);
  }, [dispatch, t]);

  if (!hasBounced || closed) return null;

  return (
    <Modal
      title={t('Your email is invalid')}
      actions={[
        <Button key="invalid-email-resend" onClick={resendConfirmation} className="empty" loading={resending}>{t('Resend confirmation')}</Button>,
        <Button key="invalid-email-button" onClick={updateEmail}>{t('Fix the problem')}</Button>,
      ]}
    >
      <Trans t={t} i18nKey="email.invalid" ns="App" values={{ address }}>
        <p>
          We sent you an email message to
          {' '}
          <strong>{address}</strong>
          {' '}
          that was bounced, so your account is now
          {' '}
          <strong>UNCONFIRMED</strong>
          .
        </p>
      </Trans>
      <p>{t('This means that you won\'t be able to use the site fully until you address this issue. User accounts need to be always confirmed with valid email addresses in order to keep your account reliable.')}</p>
      <p>{t('bounced.error')}</p>
    </Modal>
  );
};

BouncedAlert.propTypes = {
};

BouncedAlert.defaultProps = {
};

export default BouncedAlert;
