import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import usersToText from 'utils/usersToText';
import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import UserAvatar from 'components/UserAvatar';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';
import { SITE_URL } from '../../../../constants';

const RsvpCreated = ({
  alertId,
  url,
  authors: authorIds,
  eventName,
  read,
  timestamp,
}) => {
  const { t } = useTranslation(locales);

  // Remove duplications (this should be a service feature in the future)
  const idsSet = new Set();
  authorIds.forEach(id => idsSet.add(id));
  const authorsBatch = useSelector(userSelectors.getListByIds([...idsSet]), shallowEqual);

  const authors = Object.values(authorsBatch).reverse().filter(a => !!a);
  const authorsText = usersToText(authors, t, alertId);

  const path = url.replace(SITE_URL, '');

  return (
    <AlertContainer timestamp={timestamp} image={<UserAvatar userId={authors[0].id} size="40px" showOnline={false} />} to={path} read={read}>
      {authorsText}
      {' '}
      {t('rsvp\'d to', { context: authors.length > 1 && 'PLURAL' })}
      {' '}
      <strong>{eventName}</strong>
    </AlertContainer>
  );
};

RsvpCreated.propTypes = {
  alertId: PropTypes.string.isRequired,
  read: PropTypes.bool.isRequired,
  authors: PropTypes.arrayOf(PropTypes.number).isRequired,
  url: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default RsvpCreated;
