const colors = {
  main: '#ED4D3D',
  mainLight: '#F5EFEF',
  secondary: '#6F6666',
  h1: '#312626',
  approve: '#4caf50',
  secondaryListItem: '#fdfbfb',
  actionListItemBG: '#F5EFEF',
  mainDivider: '#FF6A6A',
  mainBackground: '#FAFAFA',
  actionIcon: '#A8A8A8',
  secondaryText: '#A8A8A8',
  disabledBackground: '#EAEAEA',
  warningBackground: '#FFEDA7',
  warningBorder: '#FFDD58',
  success: 'green',
};

const badges = [
  { value: 0, fill: '#CCCCCC', color: '#6F6666' },
  { value: 100, fill: '#ED4D3D', color: 'white' },
  { value: 500, fill: '#000000', color: 'white' },
];

export default {
  fontFamily: '\'Roboto\', sans-serif !important',
  header: {
    height: 64,
  },
  subheader: {
    height: 52,
  },
  colors,
  badges,
  css: {
    link: `
      color: ${colors.main};
    `,
    content: `
      padding: 16px;
    `,
  },
};
