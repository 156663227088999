import styled from 'styled-components';

interface Props extends React.HTMLProps<HTMLDivElement> {
  center?: boolean;
}

const ButtonsContainer = styled.div<Props>`
  display: flex;
  justify-content: ${props => (props.center ? 'center' : 'space-between')};
  margin: 32px 0 0;
`;

export default ButtonsContainer;
