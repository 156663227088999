import { createSelector } from 'reselect';
import moment from 'moment';

// Input selectors
const selectCurrentPeriod = state => state.bank.periods.current;
const selectAllCoupons = state => state.bank.coupons;
//

export const selectGeneralTax = createSelector(
  selectCurrentPeriod,
  period => period?.taxes?.general || 0,
);

export const selectBuyableItemPrice = createSelector(
  selectCurrentPeriod,
  (_, name) => name,
  (period, name) => period?.buyableItems?.[name]?.price || null,
);

export const selectBuyableItemsPriceList = createSelector(
  selectCurrentPeriod,
  (_, names) => names,
  (period, names) => names.reduce((acc, name) => {
    // eslint-disable-next-line no-param-reassign
    acc[name] = (period?.buyableItems?.[name]?.price || 0);
    return acc;
  }, {}),
);

export const selectBuyableItemPriceBulk = createSelector(
  selectCurrentPeriod,
  (_, names) => names,
  (period, names) => names.reduce((acc, cv) => (period?.buyableItems?.[cv]?.price || 0) + acc, 0),
);

export const selectCoupons = createSelector(
  selectAllCoupons,
  coupons => coupons.filter((c) => {
    if (c.maxUses <= (c.usedBy?.length || 0)) return false;
    if (c.expiresAt && moment(c.expiresAt).isBefore(new Date())) return false;
    return true;
  }),
);

export const hasCoupons = createSelector(
  selectCoupons,
  coupons => coupons.length > 0,
);

export const selectCoupon = createSelector(
  selectCoupons,
  (_, code) => code,
  (coupons, code) => coupons.find(c => c.code === code),
);

export const selectUnacknowledgedCoupon = createSelector(
  selectCoupons,
  coupons => coupons.find(c => !c.acknowledged),
);
