import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslation } from 'hooks';

import Modal from 'components/Modal';
import PurchaseModal from 'components/PurchaseButton/PurchaseModal';

import Award from './Award';
import { AWARDS } from './constants';
import locales from '../i18n';

const AwardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`;
AwardGrid.displayName = 'AwardGrid';

const AddModal = ({ entityId, type, close }) => {
  const { t } = useTranslation(locales);

  const [purchasing, setPurchasing] = useState(false);
  const openPurchase = useCallback(name => () => setPurchasing(name), []);
  const closePurchase = useCallback(() => setPurchasing(false), []);

  if (purchasing) {
    const payloadData = { type };
    if (type === 'reply') payloadData.replyId = entityId;
    if (type === 'thread') payloadData.threadId = entityId;

    return (
      <PurchaseModal
        itemName={`AWARD.${purchasing}`}
        close={closePurchase}
        payloadData={payloadData}
        afterPurchase={close}
      />
    );
  }

  return (
    <Modal title={t('Add award')} onCancel={close}>
      <AwardGrid>
        {Object.values(AWARDS).map(({ name }) => (
          <Award key={`award-${name}`} onClick={openPurchase(name)} name={name} />
        ))}
      </AwardGrid>
    </Modal>
  );
};

AddModal.propTypes = {
  entityId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['thread', 'reply']).isRequired,
  close: PropTypes.func.isRequired,
};

AddModal.defaultProps = {
};

export default AddModal;
