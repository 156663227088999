import {
  Icon, IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList,
} from '@chakra-ui/react';
import {
  Ban, ClipboardList, Image, Info, Settings, Shield, SquareUser,
  UserCog,
} from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';
import * as membershipSelectors from 'state/memberships/selectors';

import InviteMenuItem from './InviteMenuItem';
import locales from '../i18n';

const ModOptions: React.FC = () => {
  const { communitySlug } = useParams<{ communitySlug: string }>();
  const { t } = useTranslation(locales);

  // @ts-ignore
  const communityId = useSelector(state => communitySelectors.getIdBySlug(state, communitySlug));
  // @ts-ignore
  const isAdmin = useSelector(state => membershipSelectors.userIsAdminOf(state, communityId));
  // @ts-ignore
  const isOwner = useSelector(state => membershipSelectors.userIsOwnerOf(state, communityId));
  // @ts-ignore
  const isMod = useSelector(state => membershipSelectors.userIsModOf(state, communityId));

  if (!isMod) return null;

  return (
    <Menu>
      <MenuButton as={IconButton} icon={<Icon as={Settings} color="black" />} />
      <MenuList>
        <InviteMenuItem />
        <MenuDivider />
        {isOwner && (
          <>
            <MenuItem icon={<Icon as={Info} />} as={Link} to={`/+${communitySlug}/edit`}>{t('Edit information')}</MenuItem>
            <MenuItem icon={<Icon as={SquareUser} />} as={Link} to={`/+${communitySlug}/avatar`}>{t('Change avatar')}</MenuItem>
            <MenuItem icon={<Icon as={Image} />} as={Link} to={`/+${communitySlug}/cover`}>{t('Change cover')}</MenuItem>
            <MenuDivider />
          </>
        )}
        {isAdmin && (
          <MenuItem icon={<Icon as={Shield} />} as={Link} to={`/+${communitySlug}/mods`}>{t('Manage mods')}</MenuItem>
        )}
        <MenuItem icon={<Icon as={Ban} />} as={Link} to={`/+${communitySlug}/bans`}>{t('Manage bans')}</MenuItem>
        <MenuItem icon={<Icon as={ClipboardList} />} as={Link} to={`/+${communitySlug}/pending`}>{t('Pending memberships')}</MenuItem>
        {isOwner && (
          <>
            <MenuDivider />
            <MenuItem color="red.500" icon={<Icon as={UserCog} />} as={Link} to={`/+${communitySlug}/transfer`}>{t('Transfer ownership')}</MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

export default ModOptions;
