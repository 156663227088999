import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'react-dates/initialize';
import { SingleDatePicker, type SingleDatePickerShape } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import colors from 'utils/css/colors';

import MonthYearSelect from './MonthYearSelect';

const Wrapper = styled.div`
  text-align: left;
  margin-bottom: 22px;

  .DateInput {
    background: transparent;
  }

  .DateInput_input {
    padding: 0;
    border-bottom: 1px solid ${colors.redReactions};
  }

  .SingleDatePickerInput {
    background: transparent;
  }

  .DateInput_input, .DateInput_input__focused {
    background: transparent;
  }

  .SingleDatePickerInput__showClearDate {
    padding-right: 0;
  }

  .CalendarDay__selected {
    outline: none;
  }

  .CalendarDay__selected {
    background: ${colors.red};
    border-color: ${colors.red};
  }
`;


type Props = SingleDatePickerShape & {
  monthSelection?: boolean;
}

const Datepicker: React.FC<Props> = ({ monthSelection, ...params }) => (
  <Wrapper>
    {/* @ts-ignore */}
    <SingleDatePicker
      {...params}
      renderMonthElement={monthSelection ? MonthYearSelect : undefined}
    />
  </Wrapper>
);

Datepicker.propTypes = {
  monthSelection: PropTypes.bool,
};

Datepicker.defaultProps = {
  monthSelection: false,
};

export default Datepicker;
