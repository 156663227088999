import { memo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { HStack, Text } from '@chakra-ui/react';

import * as authSelectors from 'state/auth/selectors';
import * as communitySelectors from 'state/communities/selectors';

import { useTranslation } from 'hooks';
import colors from 'utils/css/colors';

import { Search } from 'components/Icons';
import LeftColumnBottom from 'components/LeftColumnBottom';
import ColumnTitle from 'components/ColumnTitle';
import { SelectableListContainer, SelectableListHeader } from 'components/SelectableList';
import CloseColumn from 'components/CloseColumn';
import Overflow from 'components/Layout/Overflow';

import Community from './Community';
import locales from './i18n';

const Wrapper = styled.div`
  background-color: #F3F3F3;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;

  ${SelectableListHeader} {
    padding: 24px 16px;
  }

  .selectable-list-container, .os-host {
    height: 100%;
  }

  ul {
    @media(max-width: 767px) {
      line-height: 18px;
    }
  }
`;

const CommunityList = memo(() => {
  const { t } = useTranslation(locales);

  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const myCommunities = useSelector(communitySelectors.selectMyCommunityIdsSorted, shallowEqual);

  const title = isLoggedIn ? t('My communities') : t('global:Communities');

  return (
    <Wrapper>
      <SelectableListHeader>
        <ColumnTitle>{title}</ColumnTitle>
        <CloseColumn />
      </SelectableListHeader>
      <SelectableListContainer backgroundColor="#F3F3F3">
        <Overflow>
          <ul>
            {myCommunities.map(communityId => (
              <Community
                key={`list-community-${communityId}`}
                communityId={communityId}
              />
            ))}
          </ul>
        </Overflow>
      </SelectableListContainer>
      <LeftColumnBottom>
        <Link to="/communities/explore">
          <HStack>
            <Search color={colors.redReactions} />
            <Text>{t('Explore more communities')}</Text>
          </HStack>
        </Link>
      </LeftColumnBottom>
    </Wrapper>
  );
});

CommunityList.propTypes = {
};

CommunityList.defaultProps = {
};

export default CommunityList;
