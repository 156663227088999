import styled from 'styled-components';

const ThreeColumns = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'middle';

  @media(min-width: 768px) {
    grid-template-columns: 360px 1fr;
    grid-template-areas: 'middle right';
  }

  @media(min-width: 1280px) {
    grid-template-columns: 300px 360px minmax(200px, 1fr);
    grid-template-areas: 'left middle right';
  }

  &.left-column-active {
    .modal-opacity {
      opacity: 1;
      pointer-events: all;
    }
    > div {
      &:nth-child(1) {
        @media(max-width: 1279px) {
          transform: translate3d(0, 0, 0) !important;
        }
      }
    }
  }

  &.right-column-active {
    > div {
      &:nth-child(3) {
        @media(max-width: 767px) {
          transform: translate3d(0, 0, 0) !important;
        }
      }
    }
  }

  .modal-opacity {
    display: none;
    @media(max-width: 1279px) {
      display: block;
      background-color: rgba(0, 0, 0, 0.6);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      pointer-events: none;
      opacity: 0;
    }
  }

  > div {
    @media(min-width: 767px) {
      height: calc(100vh - 64px);
    }

    &:nth-child(1) {
      grid-area: left;

      @media(max-width: 1279px) {
        width: 100%;
        position: fixed;
        top: 64px;
        left: 0;
        bottom: 0;
        z-index: 1000;
        transform: translate3d(-100%, 0, 0);
        transition: all 250ms ease-out;
      }
    }

    &:nth-child(2) {
      grid-area: middle;
    }

    &:nth-child(3) {
      &:not(.modal-opacity) {
        grid-area: right;

        @media(max-width: 767px) {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 999;
          transform: translate3d(100%, 0, 0);
          transition: all 250ms ease-out;
        }
      }
    }
  }
`;

export default ThreeColumns;
