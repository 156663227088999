import styled from 'styled-components';

import { useTranslation } from 'hooks';

import locales from '../i18n';

const Wrapper = styled.div`
  text-align: center;
  padding: 64px 32px;
  background: ${props => props.theme.colors.mainLight};

  .buttons {
    margin-top: 64px;

    button {
      margin: 0 16px;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

const NotConfirmed = () => {
  const { t } = useTranslation(locales);

  return (
    <Wrapper>
      <h2>{t('Do you want to get in the conversation?')}</h2>
      <p>{t('In order to be an active participant in the communities, you need to confirm your email address. If you didn\'t receive a message with instructions please check your spam folder or check your address is correct.')}</p>
    </Wrapper>
  );
};

NotConfirmed.propTypes = {
};

NotConfirmed.defaultProps = {
};

export default NotConfirmed;
