import PropTypes from 'prop-types';
import styled from 'styled-components';

const CollectionWrapper = styled.div.attrs(params => ({
  role: 'button',
  tabIndex: -1,
  onKeyDown: params.onClick,
}))`
  text-align: center;
  font-size: 12px;
  width: 84px;
  color: ${props => props.theme.colors.main};
  cursor: pointer;
  opacity: .5;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;
  outline: none;
  transition: all 250ms ease-out;

  ${props => props.active && `
    opacity: 1;
  `}

  &:hover {
    opacity: 1;
  }

  &:active {
    outline: none;
  }

  .image {
    background-image: url(${props => props.src});
    background-color: #E8E8E8;
    background-size: cover;
    background-position: center;
    border: 3px solid ${props => props.theme.colors.main};
    border-radius: 16px;
    width: 64px;
    height: 64px;

    ${props => props.dashed && `
      border: 3px dashed ${props.theme.colors.secondary};
    `}
  }

  .label {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
  }
`;
CollectionWrapper.displayName = 'CollectionWrapper';

const Collection = ({
  src, onClick, label, active, dashed,
}) => (
  <CollectionWrapper onClick={onClick} active={active} src={src} dashed={dashed}>
    <div className="image" />
    <div className="label">{label}</div>
  </CollectionWrapper>
);

Collection.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  dashed: PropTypes.bool,
};

Collection.defaultProps = {
  active: false,
  dashed: false,
  src: null,
};

export default Collection;
