import { useState, useCallback } from 'react';

type UseOpenCloseResult = [
  boolean,
  (e?: any) => void,
  () => void,
  () => void,
];

const useOpenclose = (initial = false): UseOpenCloseResult => {
  const [isOpen, setIsOpen] = useState(initial);
  const open = (e?: MouseEvent) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setIsOpen(true);
  };
  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen(currentValue => !currentValue);
  }, []);

  return [
    isOpen,
    open,
    close,
    toggle,
  ] as UseOpenCloseResult;
};

export default useOpenclose;
