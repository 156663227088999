import styled from 'styled-components';
import { Link } from 'react-router-dom';

import CSS from 'utils/css/mainCSS';
import colors from 'utils/css/colors';

import LockCircled from 'components/Icons/LockCircled';

import { COMMUNITY_PRIVACIES } from '../../constants';

const Box = styled.div`
  color: ${colors.blackRed};
  background-color: white;
  border-radius: 16px;
  box-shadow: ${CSS.boxShadow};
  display: flex;
  flex-direction: column;
  position: relative;

  a {
    display: flex;
    align-items: center;
  }
`;

const BoxContent = styled.div`
  padding: 0 32px 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex-grow: 1;
`;

interface ImageProps {
  src: string;
  alt: string;
  type: string;
}

const Image = styled.img.attrs<ImageProps>(props => ({
  src: props.src,
  alt: `avatar de la comunidad ${props.alt}`,
}))<ImageProps>`
  width: 96px;
  height: 96px;
  margin: -48px auto 24px;
  border-radius: 4px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.16);
  flex-shrink: 0;
  align-self: center;

  ${props => props.type === 'rounded' && `
    border-radius: 100%;
  `}
`;

const Title = styled.h2`
  font-size: 18px;
  margin: 0 0 16px;
  a {
    color: ${colors.blackRed};
    text-decoration: none;
  }
`;

const Description = styled.span`
  color: ${colors.blackRed};
  font-size: 16px;
  margin: 0 0 24px;
  display: block;
`;

const Actions = styled.div`
  margin-top: auto;
`;

const LockWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 12px;
  right: 12px;
`;

interface ConditionalLinkProps {
  to: string | null;
  children: React.ReactNode;
}

const ConditionalLink = ({ to = null, children }: ConditionalLinkProps) => {
  if (to) return <Link to={to}>{children}</Link>;
  return <>{children}</>;
};

interface CardProps {
  title: string;
  description: React.ReactNode;
  avatar: ImageProps;
  privacy?: string;
  action?: React.ReactNode;
  link: string | null;
}

const Card = ({
  title,
  description,
  avatar,
  privacy = COMMUNITY_PRIVACIES.PUBLIC,
  action = null,
  link = null,
}: CardProps) => (
  <Box>
    {privacy === COMMUNITY_PRIVACIES.PRIVATE && (
      <LockWrapper>
        <LockCircled />
      </LockWrapper>
    )}
    <ConditionalLink to={link}>
      <Image {...avatar} />
    </ConditionalLink>
    <BoxContent>
      <ConditionalLink to={link}>
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Content>
      </ConditionalLink>
      {action && <Actions>{action}</Actions>}
    </BoxContent>
  </Box>
);

export default Card;
