import styled from 'styled-components';

const InputsWrapper = styled.div`
  overflow: auto;
  padding: 42px 32px 16px;
  display: flex;
  flex-direction: column;

  @media(max-width: 767px) {
    padding: 16px;
  }
`;

export default InputsWrapper;
