import styled from 'styled-components';

const TwoColumns = styled.div`
  background-color: #fff;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'left';
  overflow: hidden;

  @media(min-width: 768px) {
    grid-template-columns: 300px 1fr;
    grid-template-areas: 'left right';

    .close-menu, .mobile-menu-button {
      display: none;
    }
  }

  @media(max-width: 768px) {
    grid-template-areas: 'right';

    &.left-column-active {
      .modal-opacity {
        opacity: 1;
        pointer-events: all;
      }

      > div {
        &:nth-child(1) {
          transform: translate3d(0, 0, 0);
          width: 100%;
          background: white;
          height: calc(100vh - 64px);
        }
      }
    }
  }

  .modal-opacity {
    display: none;

    @media(max-width: 1279px) {
      display: block;
      background-color: rgba(0, 0, 0, 0.6);
      position: fixed;
      top: 64px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      pointer-events: none;
      opacity: 0;
    }
  }

  > div {
    &:nth-child(1) {
      grid-area: left;

      @media(max-width: 1279px) {
        left: 0;
        bottom: 0;
        z-index: 1000;
      }

      @media(max-width: 768px) {
          position: fixed;
          top: 64px;
          transform: translate3d(-100%, 0, 0);
          transition: all 250ms ease-out;
        }
    }

    &:nth-child(2) {
      grid-area: right;
    }
  }
`;

export default TwoColumns;
