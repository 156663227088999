import { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @ts-ignore
import ismobile from 'ismobilejs';
import {
  Box, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Stack, Text, Button,
  ButtonGroup, useMediaQuery,
  DrawerOverlay,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerBody,
} from '@chakra-ui/react';

import * as authSelectors from 'state/auth/selectors';
import { useTranslation } from 'hooks';

import GenderAgeLocation from 'containers/Profiles/Profile/GenderAgeLocation';
import UserTags from 'containers/Profiles/Profile/UserTags';
import profileLocales from 'containers/Profiles/i18n';
import UserAvatar from 'components/UserAvatar';
import UserFollowButton from 'components/UserFollowButton';
import Loading from 'components/Loading';
import EmptyState from 'components/EmptyState';
import ChatButton from 'containers/Profiles/Profile/Actions/ChatButton';
import GiftButton from 'containers/Profiles/Profile/Actions/GiftButton';

import { User } from 'types';

import Counter from './Counter';
import locales from './i18n';


interface Props {
  user: User | null
  onClose: () => void;
  isOpen: boolean;
}

const avatarStyle: React.CSSProperties = {
  position: 'absolute',
  top: '0',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const MiniProfile: React.FC<Props> = ({ user: userProp, onClose, isOpen }) => {
  const { t } = useTranslation(locales);
  const { t: tP } = useTranslation(profileLocales);
  const [isMobile] = useMediaQuery('(max-width: 768px)', { ssr: false });
  const [user, setUserState] = useState<User | null>(userProp);

  useEffect(() => {
    if (userProp) {
      setUserState(userProp);
    }
  }, [userProp]);

  const meId = useSelector(authSelectors.selectId);

  if (!user) return null;

  const cantAccessProfile = user.suspended || user.blocked || user.banned;

  let userInfo = null;

  if (user.loading) {
    userInfo = <Loading />;
  } else if (user.banned) {
    userInfo = (
      <EmptyState
        uiLeftColumn
        title={tP('This account has been banned')}
        subtitle={tP('The account has been terminated automatically by the system due to multiple user\'s reports of missconduct or violations to terms of service.')}
      />
    );
  } else if (user.suspended) {
    userInfo = (
      <EmptyState
        uiLeftColumn
        title={tP('This account has been suspended')}
        subtitle={tP('The owner of this account has decided to suspend it')}
      />
    );
  } else if (user.blocked) {
    userInfo = (
      <EmptyState
        title={tP('You can\'t view this profile')}
        subtitle={tP('This person has blocked you')}
      />
    );
  } else {
    userInfo = (
      <>
        {!user.isOrganization && (
          <Stack>
            <GenderAgeLocation userId={user.id} />
            <UserTags userId={user.id} dark showUpToAmount={ismobile.phone ? 5 : undefined} />
          </Stack>
        )}

        <Flex justifyContent="space-around" mt={6} textAlign="center">
          {!user.isOrganization && <Counter label={t('global:Known by')} number={user.knowedCount} />}
          {!user.isOrganization && <Counter label={t('global:Events')} number={user.eventCount} />}
          <Counter label={t('global:Followers')} number={user.followersCount} />
          <Counter label={t('global:Following')} number={user.followingCount} />
        </Flex>
      </>
    );
  }

  const Content = isMobile ? DrawerContent : ModalContent;
  const Body = isMobile ? DrawerBody : ModalBody;
  const Footer = isMobile ? DrawerFooter : ModalFooter;

  const content = (
    <Content>
      <ModalCloseButton />
      <Body>
        <UserAvatar userId={user.id} size="150px" style={avatarStyle} showOnline={false} />
        <Box mb={4}>
          <Text mt="75px" textAlign="center" fontSize="4xl" fontWeight="bold">{user.displayname}</Text>
        </Box>
        {userInfo}
      </Body>

      <Footer mt={4} justifyContent={(meId === user.id || cantAccessProfile) ? 'space-around' : 'space-between'}>
        {meId && <Link to={`/@${user.username}`} onClick={onClose}><Button className="empty" size={{ base: 'sm', md: 'md' }}>{t('View Profile')}</Button></Link>}
        {meId !== user.id && !cantAccessProfile && (
          <ButtonGroup size={{ base: 'sm', md: 'md' }} spacing={{ base: 1, md: 2 }}>
            {!user.isOrganization && <ChatButton userId={user.id} />}
            {!user.isOrganization && <GiftButton userId={user.id} />}
            <UserFollowButton userId={user.id} size={{ base: 'sm', md: 'md' }} />
          </ButtonGroup>
        )}
      </Footer>
    </Content>
  );

  return isMobile ? (
    <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} size="lg">
      <DrawerOverlay />
      {content}
    </Drawer>
  ) : (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      {content}
    </Modal>
  );
};

// @ts-ignore
export default withRouter(MiniProfile);
