import { MenuItem, useDisclosure } from '@chakra-ui/react';

import { useTranslation } from 'hooks';

import SpankListModal from 'components/SpankListModal';
import Spank from 'components/Icons/Spank2';

import locales from '../i18n';

interface Props {
  publicationId: string;
}

const MenuItemSpanks: React.FC<Props> = ({ publicationId }) => {
  const { t } = useTranslation(locales);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuItem
        icon={<Spank fill="transparent" />}
        onClick={onOpen}
      >
        {t('Show spanks')}
      </MenuItem>

      {isOpen && (
        // @ts-ignore
        <SpankListModal type="publication" entityId={publicationId} close={onClose} />
      )}
    </>
  );
};

export default MenuItemSpanks;
