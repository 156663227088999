import { normalize } from 'normalizr';
import ReactGA from 'react-ga4';
import { batch } from 'react-redux';

import AppError from 'utils/AppError';
import Api from 'state/api';

import * as communitySelectors from 'state/communities/selectors';
import * as userActions from 'state/users/actions';
import membershipSchema from 'state/memberships/schema';
import banSchema from 'state/bans/schema';

import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  CREATE,
  CREATE_SUCCESS,
  CREATE_FAIL,
  REMOVE,
  APPEND_THREADS,
  PREPEND_THREADS,
  UPDATE_MOD_ROLE,
  REMOVE_MOD,
  ADD_MEMBERSHIP_COUNT,
  WIPE,
} from './constants';
import communitySchema from './schema';

// TODO: Remove - Only needed for in between entity refactors
export const get = id => (dispatch, getState) => {
  const state = getState().communities;
  return state.data[id];
};
export const getBySlug = slug => (dispatch, getState) => {
  const state = getState().communities;
  return Object.values(state.data).find(c => c.slug === slug);
};
//

export const load = (data) => (dispatch) => {
  dispatch({
    type: LOAD_SUCCESS,
    data,
    fullyLoaded: false,
  });
};

export const create = payload => async (dispatch) => {
  try {
    dispatch({ type: CREATE });
    const { data } = await Api.req.post('/communities', payload);
    // TODO: Add to memberships

    ReactGA.event({
      category: 'Communities',
      action: 'Community created',
      label: data.name,
    });

    dispatch({ type: CREATE_SUCCESS, data });

    return data;
  } catch (err) {
    let error = err.message;
    if (err.response) {
      // eslint-disable-next-line
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }

    dispatch({ type: CREATE_FAIL, error });
    throw err;
  }
};

export const edit = (communityId, payload) => async (dispatch, getState) => {
  try {
    const { slug } = getState().communities.data[communityId];

    const { data } = await Api.req.put(`/communities/${slug}`, payload);

    ReactGA.event({
      category: 'Communities',
      action: 'Community edited',
      label: data.name,
    });

    dispatch({ type: CREATE_SUCCESS, data });
    return data;
  } catch (err) {
    throw new AppError(err);
  }
};

export const changeAvatar = (communityId, blob) => async (dispatch, getState) => {
  try {
    const { slug } = getState().communities.data[communityId];

    const formData = new FormData();
    formData.append('avatar', blob, blob.name);

    const { data } = await Api.req.put(`/communities/${slug}`, formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
      timeout: 30000,
    });

    ReactGA.event({
      category: 'Communities',
      action: 'Community avatar changed',
      label: data.name,
    });

    dispatch({ type: CREATE_SUCCESS, data });
    return data;
  } catch (err) {
    throw new AppError(err);
  }
};

export const changeCover = (communityId, blob) => async (dispatch, getState) => {
  try {
    const { slug } = getState().communities.data[communityId];

    const formData = new FormData();
    formData.append('cover', blob, blob.name);

    const { data } = await Api.req.put(`/communities/${slug}`, formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
      timeout: 30000,
    });

    ReactGA.event({
      category: 'Communities',
      action: 'Community cover changed',
      label: data.name,
    });

    dispatch({ type: CREATE_SUCCESS, data });
    return data;
  } catch (err) {
    throw new AppError(err);
  }
};

export const del = communityId => async (dispatch, getState) => {
  try {
    const { slug } = getState().communities.data[communityId];

    ReactGA.event({
      category: 'Communities',
      action: 'Community deleted',
      label: slug,
    });

    await Api.req.delete(`/communities/${slug}`);
    dispatch({ type: REMOVE, id: communityId });
  } catch (err) {
    throw new AppError(err);
  }
};

export const fetchAll = () => async (dispatch, getState) => {
  const communities = getState().communities.data;

  const { data } = await Api.req.get('/communities');

  batch(() => {
    data.forEach((community) => {
      if (!communities[community.id] || !communities[community.id].fullyLoaded) {
        const normalizedData = normalize(community, communitySchema);

        dispatch(userActions.add(normalizedData.entities.users));
        dispatch({
          type: LOAD_SUCCESS,
          data: normalizedData.entities.communities,
        });
      }
    });
  });
};

export const fetch = slug => async (dispatch, getState) => {
  try {
    const community = communitySelectors.selectBySlug(getState(), slug);

    if (!community || !community.fullyLoaded) {
      dispatch({ type: LOAD, slug });

      const { data } = await Api.req.get(`/communities/${slug}`);
      const normalizedData = normalize(data, communitySchema);

      batch(() => {
        dispatch(userActions.add(normalizedData.entities.users));
        dispatch({
          type: LOAD_SUCCESS,
          data: normalizedData.entities.communities,
          fullyLoaded: true,
        });
      });
    }
  } catch (err) {
    const error = new AppError(err);
    dispatch({ type: LOAD_FAIL, slug, error: error.message });
  }
};

export const fetchMemberships = (slug, limit, offset) => async (dispatch) => {
  try {
    const params = {
      limit,
      offset,
    };
    const { data } = await Api.req.get(`/communities/${slug}/memberships`, { params });
    const normalizedData = normalize(data, [membershipSchema]);
    await dispatch(userActions.add(normalizedData.entities.users));

    return normalizedData.result.map(id => normalizedData.entities.memberships[id]);
  } catch (err) {
    throw new AppError(err);
  }
};

export const fetchUnapprovedMemberships = slug => async (dispatch) => {
  try {
    const { data } = await Api.req.get(`/communities/${slug}/memberships`, {
      params: { unapproved: true },
    });
    const normalizedData = normalize(data, [membershipSchema]);
    await dispatch(userActions.add(normalizedData.entities.users));

    return normalizedData.result.map(id => normalizedData.entities.memberships[id]);
  } catch (err) {
    throw new AppError(err);
  }
};

export const fetchBans = slug => async (dispatch) => {
  try {
    const { data } = await Api.req.get(`/communities/${slug}/bans`);
    const normalizedData = normalize(data, [banSchema]);
    await dispatch(userActions.add(normalizedData.entities.users));


    return normalizedData.result.map(id => normalizedData.entities.bans[id]);
  } catch (err) {
    throw new AppError(err);
  }
};

export const ban = (communityId, userId) => async (dispatch, getState) => {
  try {
    const { slug } = getState().communities.data[communityId];

    const { data } = await Api.req.post(`/communities/${slug}/bans`, {
      userId,
    });

    ReactGA.event({
      category: 'Communities',
      action: 'Ban created',
      label: data.name,
    });

    dispatch({ type: REMOVE_MOD, communityId, userId });
    return { ...data, user: userId };
  } catch (error) {
    throw new AppError(error);
  }
};

export const unban = (communityId, banId) => async (dispatch, getState) => {
  try {
    const { slug } = getState().communities.data[communityId];

    await Api.req.delete(`/communities/${slug}/bans/${banId}`);

    ReactGA.event({
      category: 'Communities',
      action: 'Ban removed',
      label: slug,
    });
  } catch (error) {
    throw new AppError(error);
  }
};

export const approveMembership = (communityId, membershipId) => async (dispatch, getState) => {
  try {
    const { slug } = getState().communities.data[communityId];

    await Api.req.put(`/communities/${slug}/memberships/${membershipId}`, {
      approved: true,
    });

    ReactGA.event({
      category: 'Communities',
      action: 'Membership approved',
      label: communityId,
    });
  } catch (error) {
    throw new AppError(error);
  }
};

export const rejectMembership = (communityId, membershipId) => async (dispatch, getState) => {
  try {
    const { slug } = getState().communities.data[communityId];

    await Api.req.delete(`/communities/${slug}/memberships/${membershipId}`);

    ReactGA.event({
      category: 'Communities',
      action: 'Membership rejected',
      label: communityId,
    });
  } catch (error) {
    throw new AppError(error);
  }
};

export const incMembershipCount = community => (dispatch) => {
  dispatch({
    type: ADD_MEMBERSHIP_COUNT,
    communityId: community.id,
    q: 1,
  });
};

export const decMembershipCount = community => (dispatch) => {
  dispatch({
    type: ADD_MEMBERSHIP_COUNT,
    communityId: community.id,
    q: -1,
  });
};

export const sendInvites = (communityId, userIds) => async (dispatch, getState) => {
  try {
    const { slug } = getState().communities.data[communityId];
    await Api.req.post(`/communities/${slug}/invite`, {
      userIds,
    });

    ReactGA.event({
      category: 'Communities',
      action: 'Sent invitations',
      label: slug,
    });
  } catch (error) {
    throw new AppError(error);
  }
};

export const prependThreads = (id, data) => dispatch => (
  dispatch({ type: PREPEND_THREADS, id, data })
);

export const appendThreads = (id, data) => dispatch => dispatch({ type: APPEND_THREADS, id, data });

export const updateModRole = (communityId, membership, role) => (dispatch) => {
  dispatch({
    type: UPDATE_MOD_ROLE,
    communityId,
    membership,
    role,
  });
};

export const loadSuccess = data => dispatch => dispatch({ type: LOAD_SUCCESS, data });
// export const loadFail =: async error => dispatch({ type: LOAD_FAIL, error }),

export const searchByName = (search, max) => (dispatch, getState) => {
  const { data } = getState().communities;

  const results = Object.values(data)
    .filter(community => community.name.toLowerCase().includes(search.toLowerCase()))
    .slice(0, max);

  return results;
};

export const wipe = () => dispatch => dispatch({ type: WIPE });

export const handleCommunityJoined = ({ community }) => (dispatch) => {
  dispatch({ type: CREATE_SUCCESS, data: community });
};

export const handleCommunityUpdated = data => (dispatch) => {
  dispatch({ type: CREATE_SUCCESS, data });
};

export const transferCommunityOwnership = (communityId, userId) => async (dispatch, getState) => {
  try {
    const { slug } = getState().communities.data[communityId];
    await
    Api.req.put(`/communities/${slug}/owner`, { userId });
    await dispatch(fetch(slug));
  } catch (error) {
    throw new AppError(error);
  }
};
