import { Link } from 'react-router-dom';

import { useTranslation } from 'hooks';

import { Calendar } from 'components/Icons';

import NavItem from './Item';
import locales from '../i18n';

const EventsLink = () => {
  const { t } = useTranslation(locales);

  return (
    <NavItem>
      <Link to="/events">
        <Calendar color="white" outline />
        <span>{t('global:Events')}</span>
      </Link>
    </NavItem>
  );
};

EventsLink.propTypes = {
};

export default EventsLink;
