import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';
import { SITE_URL } from '../../../../constants';

const EventInvite = ({
  url,
  eventName,
  read,
  timestamp,
}) => {
  const { t } = useTranslation(locales);

  const path = url.replace(SITE_URL, '');

  return (
    <AlertContainer timestamp={timestamp} to={path} read={read}>
      {t('You\'ve been invited to')}
      {' '}
      <strong>{eventName}</strong>
    </AlertContainer>
  );
};

EventInvite.propTypes = {
  read: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default EventInvite;
