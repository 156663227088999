import styled from 'styled-components';

import colors from 'utils/css/colors';

const ActionsWrapper = styled.div`
  color: ${colors.blackRed};
  margin-top: 32px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media(max-width: 768px) {
    button {
      margin-bottom: 16px;
    }
  }
`;

export default ActionsWrapper;
