import { memo, useState } from 'react';
import styled from 'styled-components';

interface WrapperProps extends React.HTMLProps<HTMLDivElement> {
  cap: boolean;
}

const TitleWrapper = styled.div<WrapperProps>`
  font-size: 20px;

  ${props => props.cap && `
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `}

  @media(max-width: 767px) {
    font-weight: 500;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

interface Props {
  children: React.ReactNode;
}


const Title: React.FC<Props> = memo(({ children }) => {
  const [overflow, setOverflow] = useState(false);

  const toggleOverflow = () => {
    setOverflow(o => !o);
  };

  return (
    <TitleWrapper onClick={toggleOverflow} cap={!overflow}>{children}</TitleWrapper>
  );
});

Title.displayName = 'Title';

export default Title;
