import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const PublicationMention = ({
  author,
  publicationId,
  read,
  timestamp,
}) => {
  const { t } = useTranslation(locales);

  return (
    <AlertContainer timestamp={timestamp} image={<UserAvatar userId={author.id} size="40px" showOnline={false} />} to={`/publications/${publicationId}`} read={read}>
      <strong><UserDisplayName userId={author.id} /></strong>
      {' '}
      {t('has mentioned you in a publication')}
    </AlertContainer>
  );
};

PublicationMention.propTypes = {
  read: PropTypes.bool.isRequired,
  author: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    displayname: PropTypes.string.isRequired,
  }).isRequired,
  publicationId: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

PublicationMention.defaultProps = {
};

export default PublicationMention;
