import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';

import * as messengerSelectors from 'state/messengers/selectors';

const Time = ({ messageId }) => {
  const time = useSelector(state => messengerSelectors.getMessageCreatedAt(state, messageId));

  if (!time) return null;
  return <span>{moment(time).format('h:mm a')}</span>;
};

Time.propTypes = {
  messageId: PropTypes.string.isRequired,
};

Time.defaultProps = {
};

export default Time;
