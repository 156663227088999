export const toCurrency = (value: number, currency: string) => {
  if (currency === 'ARS') {
    return (new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
      currencyDisplay: 'code',
      maximumFractionDigits: 0,
    })).format(value);
  }

  if (currency === 'EUR') {
    return (new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
    })).format(value);
  }

  return (new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  })).format(value);
};
