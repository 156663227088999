import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import useHomeLinkClick, { HOME_URL } from 'hooks/useHomeLinkClick';
import * as feedSelectors from 'state/feed/selectors';

import { Home } from 'components/Icons';
import Badge from 'components/Badge';

import NavItem from './Item';
import locales from '../i18n';

const HomeLink = () => {
  const { t } = useTranslation(locales);
  const queueCount = useSelector(feedSelectors.getFeedQueueCount());

  const onClick = useHomeLinkClick();

  return (
    <NavItem>
      <Link to={HOME_URL} onClick={onClick}>
        <Home color="white" />
        <span>{t('Home')}</span>
        {queueCount > 0 && <Badge value={queueCount} />}
      </Link>
    </NavItem>
  );
};

HomeLink.propTypes = {
};

export default HomeLink;
