import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as userSelectors from 'state/users/selectors';
import * as messengerSelectors from 'state/messengers/selectors';
import * as authSelectors from 'state/auth/selectors';
import * as userActions from 'state/users/actions';

import Avatar from 'components/UserAvatar';
import { SelectableListItem } from 'components/SelectableList';

import Unread from './Unread';

const Handle = styled.span`
  font-size: 12px;
  color: #999;
`;

const Request = ({
  userId,
  history,
  rejected,
  messengerId,
}) => {
  const dispatch = useDispatch();

  const user = useSelector(userSelectors.getById(userId), shallowEqual);
  if (user.loading) dispatch(userActions.fetchData(userId));
  const messengerIsBlocked = useSelector(state => messengerSelectors.isBlocked(state, messengerId));
  const userIsBlocked = useSelector(state => authSelectors.selectIsBlocked(state, userId));

  const onSelect = () => history.push(`/chat/messengers/${messengerId}`);

  const isBlocked = messengerIsBlocked || userIsBlocked;

  const style = {};
  if (rejected) style.opacity = '0.5';

  return (
    <SelectableListItem
      avatar={<Avatar userId={user.id} />}
      title={user.displayname}
      onClick={onSelect}
      style={style}
    >
      <Handle>{`@${user.username}`}</Handle>
      {!rejected && !isBlocked && <Unread>!</Unread>}
    </SelectableListItem>
  );
};

Request.propTypes = {
  userId: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  rejected: PropTypes.bool.isRequired,
  messengerId: PropTypes.string.isRequired,
};

export default withRouter(Request);
