import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as threadSelectors from 'state/threads/selectors';

import * as images from 'containers/Communities/Thread/Awards/img';
import locales from 'containers/Communities/Thread/i18n';

const Wrapper = styled.div`
  > div {
    font-size: 12px;
    margin-left: 4px;

    img {
      width: 16px;
      height: 16px;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

const Awards = ({ threadId }) => {
  const { t } = useTranslation(locales);

  const awards = useSelector(state => threadSelectors.getAwards(state, threadId), shallowEqual);

  const awardTypes = {};
  awards.forEach(({ label }) => {
    if (typeof awardTypes[label] === 'undefined') awardTypes[label] = 0;
    awardTypes[label] += 1;
  });

  return (
    <Wrapper>
      {Object.keys(awardTypes).map(label => (
        <div key={`threadlist-award-${threadId}-${label}`}>
          <img src={images[label]} alt={t(`AWARD.${label}`)} title={t(`AWARD.${label}`)} />
        </div>
      ))}
    </Wrapper>
  );
};

Awards.propTypes = {
  threadId: PropTypes.string.isRequired,
};

Awards.defaultProps = {
};

export default Awards;
