import produce from 'immer';

import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  CREATE,
  CREATE_SUCCESS,
  CREATE_FAIL,
  REMOVE,
  PREPEND_THREADS,
  APPEND_THREADS,
  UPDATE_MOD_ROLE,
  REMOVE_MOD,
  ADD_MEMBERSHIP_COUNT,
  WIPE,
} from './constants';
import { MEMBERSHIP_ROLES } from '../../constants';

export const initialState = {
  data: {},
  creating: false,
  creatingError: null,
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOAD: {
      const community = Object.values(state.data).find(c => c.slug === action.slug);

      if (!community) draft.data[action.slug] = { loading: true };
      else draft.data[community._id] = { ...draft.data[community._id], loading: true };

      break;
    }

    case LOAD_SUCCESS:
      Object.values(action.data || {}).forEach((community) => {
        delete draft.data[community.slug];

        // Preserve threads for pre-existing communities
        const threads = state.data[community._id]
          ? state.data[community._id].threads
          : [];
        draft.data[community._id] = {
          ...community,
          threads,
          loading: false,
          fullyLoaded: !!action.fullyLoaded,
        };
      });
      break;

    case LOAD_FAIL: {
      draft.data[action.slug] = {
        loading: false,
        notFound: action.error !== 'BANNED',
        banned: action.error === 'BANNED',
      };

      const community = Object.values(state.data).find(c => c.slug === action.slug);
      if (community) {
        draft.data[community._id] = { ...draft.data[community._id], loading: true };
      }
      break;
    }

    case CREATE:
      draft.creating = true;
      draft.creatingError = null;
      break;

    case CREATE_SUCCESS:
      draft.data[action.data._id] = {
        ...state.data[action.data._id],
        ...action.data,
      };

      draft.creating = false;
      draft.creatingError = null;
      break;

    case CREATE_FAIL:
      draft.creating = false;
      draft.creatingError = action.error;
      break;

    case REMOVE:
      delete draft.data[action.id];
      break;

    case PREPEND_THREADS: {
      if (draft.data[action.id]) {
        const threadIds = (draft.data[action.id].threads || [])
          .filter(id => !action.data.includes(id));

        draft.data[action.id].threads = [
          ...action.data,
          ...threadIds,
        ];
      }

      break;
    }

    case APPEND_THREADS: {
      // Avoid duplication
      const threads = action.data.filter(id => !(draft.data[action.id].threads || []).includes(id));
      draft.data[action.id].threads = [
        ...(state.data[action.id].threads || []),
        ...threads,
      ];
      break;
    }

    case UPDATE_MOD_ROLE:
      if (action.role !== MEMBERSHIP_ROLES.USER) {
        const membershipExist = state.data[action.communityId].mods
          .some(mbsp => mbsp._id === action.membership._id);

        if (membershipExist) {
          draft.data[action.communityId].mods = state.data[action.communityId].mods.map(mbsp => (
            mbsp._id === action.membership._id
              ? {
                ...mbsp,
                role: action.role,
              }
              : mbsp
          ));
        } else {
          draft.data[action.communityId].mods.push({
            ...action.membership,
            role: action.role,
          });
        }
      } else {
        draft.data[action.communityId].mods = state.data[action.communityId].mods
          .filter(mbsp => mbsp._id !== action.membership._id);
      }
      break;

    case REMOVE_MOD:
      draft.data[action.communityId].mods = state.data[action.communityId].mods
        .filter(mbsp => mbsp.user !== action.userId);
      break;

    case ADD_MEMBERSHIP_COUNT:
      draft.data[action.communityId].membershipCount += action.q;
      break;

    case WIPE:
      draft.data = initialState.data;
      draft.loading = initialState.loading;
      draft.creating = initialState.creating;
      draft.creatingError = initialState.creatingError;
      break;

    default:
  }
});

export default reducer;
