import produce from 'immer';

import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  CREATE,
  CREATE_SUCCESS,
  CREATE_FAIL,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAIL,
  WIPE,
} from './constants';

export const initialState = {
  data: null,
  loading: [],
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOAD:
      break;

    case LOAD_SUCCESS:
      draft.data = {
        ...(draft.data || {}),
        ...action.data,
      };
      break;

    case LOAD_FAIL:
      draft.loading = [];
      break;

    case CREATE:
    case DELETE:
      draft.loading.push(action.communitySlug);
      break;

    case CREATE_SUCCESS:
    case CREATE_FAIL:
    case DELETE_FAIL:
      draft.loading = state.loading.filter(slug => action.communitySlug !== slug);
      break;

    case DELETE_SUCCESS:
      draft.loading = state.loading.filter(slug => action.communitySlug !== slug);
      delete draft.data[action.membershipId];
      break;

    case WIPE:
      draft.data = initialState.data;
      draft.loading = initialState.loading;
      break;

    default:
  }
});

export default reducer;
