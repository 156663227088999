import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Api from 'state/api';
import useTranslation from 'hooks/useTranslation';

import Box from 'containers/Sidebar/Box';
import Loading from 'components/Loading';

import BoxWrapper from './UI/BoxWrapper';
import List from './UI/List';
import locales from './i18n';

const LastThreads = ({ landing }) => {
  const { t } = useTranslation(locales);

  const [threads, setThreads] = useState(null);

  useEffect(() => {
    const load = async () => {
      const { data } = await Api.req.get('/communities/threads');
      setThreads(data);
    };

    load();
  }, []);

  return (
    <BoxWrapper landing={landing}>
      <Box title={t('global:Last Threads')}>
        {threads === null
          ? <Loading />
          : (
            <List>
              {threads.map(thread => (
                <li key={`landing-thread-${thread.id}`}>
                  <Link to={`/+${thread.community.slug}/${thread.slug}`}>
                    <img src={thread.community.avatar} alt={thread.community.name} />
                    <span title={thread.title}>{thread.title}</span>
                  </Link>
                </li>
              ))}
            </List>
          )
        }
      </Box>
    </BoxWrapper>
  );
};

LastThreads.propTypes = {
  landing: PropTypes.bool.isRequired,
};

LastThreads.defaultProps = {
};

export default LastThreads;
