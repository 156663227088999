import {
  Box, Flex, Icon, Tab, TabList, TabPanel, TabPanels, Tabs,
  Text,
} from '@chakra-ui/react';
import {
  Camera, Flame, Images, LayoutDashboard, Link, Pencil, Sparkles,
  Vote,
} from 'lucide-react';

import { useTranslation } from 'hooks';

import FloatingButton from 'components/FloatingButton';

import locales from './i18n';
import WarningBoxes from './WarningBoxes';
import LatestHashtags from './LatestHashtags';
import MainFeed from './Main';
import TrendingFeed from './Trending';
import MediaFeed from './Media';
import ExclusiveFeed from './Exclusive';


const Feed2: React.FC = () => {
  const { t } = useTranslation(locales);

  return (
    <Box position="relative">
      <WarningBoxes />

      <Flex
        p={{ base: '8px', md: '16px' }}
        justifyContent="space-between"
        alignItems="center"
        maxW="100vw"
        boxSizing="border-box"
        direction="column"
      >
        <LatestHashtags />
      </Flex>

      <Tabs isLazy w="full" p={0}>
        <TabList p={0} m={0}>
          <Tab flex={1}>
            <Icon as={LayoutDashboard} mr={2} />
            <Text display={{ base: 'none', md: 'block' }}>{t('Feed')}</Text>
          </Tab>
          <Tab flex={1}>
            <Icon as={Flame} mr={2} />
            <Text display={{ base: 'none', md: 'block' }}>{t('Trending')}</Text>
          </Tab>
          <Tab flex={1}>
            <Icon as={Camera} mr={2} />
            <Text display={{ base: 'none', md: 'block' }}>{t('Last photos')}</Text>
          </Tab>
          <Tab flex={1}>
            <Icon as={Sparkles} mr={2} />
            <Text display={{ base: 'none', md: 'block' }}>{t('Exclusive')}</Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}><MainFeed /></TabPanel>
          <TabPanel p={0}><TrendingFeed /></TabPanel>
          <TabPanel p={0}><MediaFeed /></TabPanel>
          <TabPanel p={0}><ExclusiveFeed /></TabPanel>
        </TabPanels>
      </Tabs>

      <FloatingButton
        actions={[
          { text: t('Poll'), to: '/new/poll', icon: <Icon as={Vote} /> },
          { text: t('Photos'), to: '/new/photos', icon: <Icon as={Images} /> },
          { text: t('URL (image/gif/link)'), to: '/new/url', icon: <Icon as={Link} /> },
          { text: t('Text'), to: '/new/text', icon: <Icon as={Pencil} /> },
        ]}
      />
    </Box>
  );
};

export default Feed2;
