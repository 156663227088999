import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import UserAvatar from 'components/UserAvatar';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const PublicationSingleUnlock = ({
  publication,
  userId,
  read,
  timestamp,
}) => {
  const { t } = useTranslation(locales);

  return (
    <AlertContainer timestamp={timestamp} image={<UserAvatar userId={userId} size="40px" showOnline={false} />} to={`/publications/${publication.id}`} read={read}>
      {t('Your crowdfunded publication was unblocked')}
      {publication.payload.rawContent && ': '}
      {publication.payload.rawContent}
    </AlertContainer>
  );
};

PublicationSingleUnlock.propTypes = {
  publication: PropTypes.shape({
    id: PropTypes.string.isRequired,
    payload: {
      rawContent: PropTypes.string,
    }.isRequired,
  }).isRequired,
  userId: PropTypes.number.isRequired,
  read: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default PublicationSingleUnlock;
