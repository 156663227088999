import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import colors from 'utils/css/colors';
import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import { SelectableListItem } from 'components/SelectableList';
import UserAvatar from 'components/UserAvatar';

const Username = styled.div`
  color: ${colors.grey};
  font-size: 14px;
  margin-top: 2px;
`;
Username.displayName = 'Username';

const areRowsEqual = (prevProps, nextProps) => (
  prevProps.userId === nextProps.userId && prevProps.loading === nextProps.loading
);

const Member = memo(({
  userId,
  style,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.getById(userId), shallowEqual);
  if (user.loading) dispatch(userActions.fetchData(user.id));

  const renderAvatar = useCallback(() => <UserAvatar userId={userId} />, [userId]);
  const renderContent = useCallback(() => <Username>{`@${user.username}`}</Username>, [user.username]);

  return (
    <SelectableListItem
      renderAvatar={renderAvatar}
      renderContent={renderContent}
      title={user.displayname}
      to={`/@${user.username}`}
      linkClassName="userlink"
      style={style}
    />
  );
}, areRowsEqual);

Member.propTypes = {
  userId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
};

Member.defaultProps = {
};

export default memo(Member);
