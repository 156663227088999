import styled from 'styled-components';

import colors from 'utils/css/colors';

const ColumnTitle = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  color: ${colors.redReactions};
  flex: 1;
  font-size: 16px;
  text-transform: uppercase;
`;

export default ColumnTitle;
