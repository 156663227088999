import { useSelector, shallowEqual } from 'react-redux';
import { VStack } from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as messengerSelectors from 'state/messengers/selectors';

import EmptyState from 'components/EmptyState';

import locales from '../i18n';
import LinkPublication from '../Content/Bubble/Messenger/LinkPublication';
import RequestBubble from '../Content/Bubble/RequestBubble';

interface Props {
  messengerId: string;
}

const WaitingForApproval: React.FC<Props> = ({ messengerId }) => {
  const { t } = useTranslation(locales);

  const me = useSelector(authSelectors.selectMe, shallowEqual);
  // @ts-ignore
  const message = useSelector(state => messengerSelectors.getRequestMessage(state, messengerId));
  // @ts-ignore
  const publication = useSelector(state => messengerSelectors.getRequestPublication(state, messengerId));

  return (
    <EmptyState
      title={t('Waiting for approval')}
      subtitle={(
        <>
          {t('You sent a request for chatting with this person but there was not approved yet.')}
          <VStack mt={6}>
            {publication && <LinkPublication publication={publication} />}
            <RequestBubble author={me} mt={0}>
              {message}
            </RequestBubble>
          </VStack>
        </>
      )}
    />
  );
};

export default WaitingForApproval;
