import { useSelector } from 'react-redux';
import { MenuItem, useDisclosure } from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';

import VotesModal from '../Modals/Votes';
import locales from '../i18n';

interface Props {
  publicationId: string;
}

const MenuItemShowVotes: React.FC<Props> = ({ publicationId }) => {
  const { t } = useTranslation(locales);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const userIsAuthor = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.userIsAuthor(state, publicationId),
  );

  const isPoll = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.isPoll(state, publicationId),
  );

  if (!userIsAuthor || !isPoll) return null;

  return (
    <>
      <MenuItem onClick={onOpen}>{t('Votes')}</MenuItem>

      {isOpen && (
        <VotesModal publicationId={publicationId} close={onClose} />
      )}
    </>
  );
};

export default MenuItemShowVotes;
