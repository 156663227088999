export const LOAD = 'mazmo/threads/LOAD';
export const LOAD_SUCCESS = 'mazmo/threads/LOAD_SUCCESS';
export const LOAD_FAIL = 'mazmo/threads/LOAD_FAIL';

export const CREATE = 'mazmo/threads/CREATE';
export const CREATE_SUCCESS = 'mazmo/threads/CREATE_SUCCESS';
export const CREATE_FAIL = 'mazmo/threads/CREATE_FAIL';

export const FULLY_LOADED = 'mazmo/threads/FULLY_LOADED';
export const PREPEND_RECENT = 'mazmo/threads/PREPEND_RECENT';
export const APPEND_RECENT = 'mazmo/threads/APPEND_RECENT';

export const SLUG_LOADING_START = 'mazmo/threads/SLUG_LOADING_START';
export const SLUG_LOADING_STOP = 'mazmo/threads/SLUG_LOADING_STOP';
export const SLUG_NOT_FOUND = 'mazmo/threads/SLUG_NOT_FOUND';

export const REMOVE = 'mazmo/threads/REMOVE';
export const EDIT = 'mazmo/threads/EDIT';

export const APPEND_REACTION = 'mazmo/threads/APPEND_REACTION';
export const REMOVE_REACTION = 'mazmo/threads/REMOVE_REACTION';
export const APPEND_DISLIKE = 'mazmo/threads/APPEND_DISLIKE';
export const REMOVE_DISLIKE = 'mazmo/threads/REMOVE_DISLIKE';

export const MARK_AS_READ = 'mazmo/threads/MARK_AS_READ';

export const WIPE = 'mazmo/threads/WIPE';
