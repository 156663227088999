import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;

  > div {
    flex: 1;
  }

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
TitleWrapper.displayName = 'TitleWrapper';

export default TitleWrapper;
