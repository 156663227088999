import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';
import * as channelActions from 'state/channels/actions';
import * as appActions from 'state/app/actions';

import OptionsModalComponent from '../OptionsModal';

const OptionsModal = ({ channelId, messageId, close }) => {
  const dispatch = useDispatch();

  const reactions = useSelector(
    state => channelSelectors.getMessageReactions(state, messageId),
    shallowEqual,
  );
  const outgoing = useSelector(state => channelSelectors.isMessageOutgoing(state, messageId));

  const addReaction = name => dispatch(channelActions.addReaction(messageId, name));
  const replyTo = () => {
    close();
    dispatch(channelActions.addReplyTo(channelId, messageId));
  };

  const remove = async () => {
    try {
      await dispatch(channelActions.removeMessage(messageId));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    close();
  };

  const edit = () => {
    dispatch(channelActions.addEditComposer(channelId, messageId));
    close();
  };

  return (
    <OptionsModalComponent
      close={close}
      reactions={reactions}
      addReaction={addReaction}
      replyTo={replyTo}
      onEdit={outgoing ? edit : null}
      onRemove={outgoing ? remove : null}
    />
  );
};

OptionsModal.propTypes = {
  channelId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

OptionsModal.defaultProps = {
};

export default OptionsModal;
