import PropTypes from 'prop-types';

import { useTranslation, useUploadMedia } from 'hooks';

import CommunityUploadMedia, { DropZone } from 'components/CommunityUploadMedia';

import locales from '../i18n';

const Media = ({ children }) => {
  const { t } = useTranslation(locales);

  const id = 'publication';
  const {
    media,
    getInputProps,
    isDragActive,
    open,
    removeImage,
  } = useUploadMedia(id, '/feed/upload');

  return (
    <div>
      {isDragActive && (
        <DropZone>{t('Drop the files here...')}</DropZone>
      )}
      <CommunityUploadMedia
        id={id}
        media={media}
        removeImage={removeImage}
        open={open}
        getInputProps={getInputProps}
        alwaysShowAddButton
      />
      {children}
    </div>
  );
};

Media.propTypes = {
  children: PropTypes.node.isRequired,
};

Media.defaultProps = {
};

export default Media;
