import {
  memo, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';
import fastdom from 'fastdom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation, useElementHeight } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';
import * as communityActions from 'state/communities/actions';
import * as appActions from 'state/app/actions';

import CommunityHeader from 'containers/Communities/Header';
import Spinner from 'components/Spinner';
import { SelectableList } from 'components/SelectableList';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import {
  FlexWrapper,
  FlexInnerWrapper,
  FlexContainer,
  ActionsFooter,
} from 'components/FlexWrapper';

import Member from './Member';
import locales from './i18n';

const PAGINATION = 100;

const SpinnerWrapper = styled.div`
  margin: 16px auto;
  text-align: center;
`;
SpinnerWrapper.displayName = 'SpinnerWrapper';

const areRowsEqual = (prevProps, nextProps) => (
  prevProps.data.members.length === nextProps.data.members.length
);

const ListRow = memo(({ data: { members }, index, style }) => {
  if (index < members.length) {
    return (
      <Member
        key={`community-member-${members[index].user}`}
        userId={members[index].user}
        style={style}
      />
    );
  }

  return (
    <SpinnerWrapper style={style}>
      <Spinner color="#999" />
    </SpinnerWrapper>
  );
}, areRowsEqual);

ListRow.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    members: PropTypes.arrayOf(PropTypes.object).isRequired,
    loadMoreVisibilityChanged: PropTypes.func.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.shape({}).isRequired,
};

const Content = ({ communityId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const fetchingMemberships = useRef(false);
  const isFullyLoaded = useRef(false);
  const loadedMembersCount = useRef(0);

  const slug = useSelector(state => communitySelectors.selectSlugById(state, communityId));
  const [members, setMembers] = useState([]);

  const [listHeight, selectableListEl] = useElementHeight();

  useEffect(() => {
    loadedMembersCount.current = members.length;
  }, [members]);

  useEffect(() => {
    const el = selectableListEl.current.children[0];
    const loadMore = async () => {
      if (!fetchingMemberships.current) {
        fetchingMemberships.current = true;

        try {
          const data = await dispatch(
            communityActions.fetchMemberships(slug, PAGINATION, loadedMembersCount.current),
          );
          isFullyLoaded.current = data.length < PAGINATION;
          setMembers(prevMembers => [
            ...prevMembers,
            ...data,
          ]);
        } catch (e) {
          dispatch(appActions.addError(e));
        }

        fetchingMemberships.current = false;
      }
    };

    const scrolled = () => {
      fastdom.measure(() => {
        if ((el.scrollTop + el.offsetHeight) >= (el.scrollHeight - 500)) {
          loadMore();
        }
      });
    };

    if (el) el.addEventListener('scroll', scrolled);
    loadMore();

    return () => {
      el.removeEventListener('scroll', scrolled);
    };
  }, [dispatch, selectableListEl, slug]);

  return (
    <FlexWrapper>
      <FlexInnerWrapper>
        <CommunityHeader communityId={communityId} />
        <PageTitle>{t('Members')}</PageTitle>

        <FlexContainer>
          <SelectableList ref={selectableListEl}>
            <List
              height={listHeight}
              itemCount={members.length + (isFullyLoaded.current ? 0 : 1)}
              itemSize={72}
              width="100%"
              itemData={{ members }}
            >
              {ListRow}
            </List>
          </SelectableList>
        </FlexContainer>
      </FlexInnerWrapper>

      <ActionsFooter>
        <div>
          <Button className="empty" to={`/+${slug}`}>{t('global:Back')}</Button>
        </div>
      </ActionsFooter>
    </FlexWrapper>
  );
};

Content.propTypes = {
  communityId: PropTypes.string.isRequired,
};

Content.defaultProps = {
};

export default Content;
