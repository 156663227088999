import styled from 'styled-components';

import colors from 'utils/css/colors';

const Title = styled.h1`
  color: ${colors.h1};
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 10px;
`;

export default Title;
