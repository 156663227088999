import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';
import * as appActions from 'state/app/actions';
import * as membershipActions from 'state/memberships/actions';

import CommunityHeader from 'containers/Communities/Header';
import { SelectableList } from 'components/SelectableList';
import Button from 'components/Button';
import SearchUsersModal from 'components/SearchUsersModal';
import PageTitle from 'components/PageTitle';
import {
  FlexWrapper,
  FlexInnerWrapper,
  FlexContainer,
  ActionsFooter,
} from 'components/FlexWrapper';
import ToggleButtonGroup from 'components/ToggleButtonGroup';

import locales from './i18n';
import Mod from './Mod';

const Content = ({ communityId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const slug = useSelector(state => communitySelectors.selectSlugById(state, communityId));
  const mods = useSelector(
    state => communitySelectors.selectMods(state, communityId),
    shallowEqual,
  );

  const [removing, setRemoving] = useState([]);
  const [type, setType] = useState('mod');

  const [showingNewModModal, setShowingNewModModal] = useState(false);
  const openNewModModal = useCallback(() => { setShowingNewModModal(true); }, []);
  const closeNewModModal = useCallback(() => { setShowingNewModModal(false); }, []);

  const [addingMod, setAddingMod] = useState(false);

  const add = useCallback(async (userId) => {
    try {
      if (!userId) throw new Error('Unexpected error');

      setAddingMod(true);
      if (type === 'admin') {
        await dispatch(membershipActions.makeAdminByUserId(communityId, userId));
      } else {
        await dispatch(membershipActions.makeModByUserId(communityId, userId));
      }
      setAddingMod(false);
      closeNewModModal();
    } catch (error) {
      setAddingMod(false);
      closeNewModModal();
      dispatch(appActions.addError(error));
    }
  }, [dispatch, communityId, closeNewModModal, type]);

  const remove = useCallback(async (membershipId) => {
    try {
      setRemoving([
        ...removing,
        membershipId,
      ]);
      await dispatch(membershipActions.unprivilege(communityId, membershipId));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    setRemoving(removing.filter(id => id !== membershipId));
  }, [removing, dispatch, communityId]);

  const onTypeChange = useCallback((key) => {
    setType(key);
  }, []);

  const toggleButtons = [
    { key: 'mod', label: t('Mod') },
    { key: 'admin', label: t('Admin') },
  ];

  return (
    <>
      <FlexWrapper fullHeight mobileHasHeader>
        <FlexInnerWrapper>
          <CommunityHeader communityId={communityId} />
          <PageTitle>{t('Mods')}</PageTitle>

          <FlexContainer framed>
            <SelectableList>
              {mods.map(({ _id: membershipId, user, role }) => (
                <Mod
                  key={`community-ban-${user}`}
                  userId={user}
                  membershipId={membershipId}
                  communityId={communityId}
                  userRole={role}
                  remove={remove}
                  removing={removing.includes(membershipId)}
                />
              ))}
            </SelectableList>
          </FlexContainer>
        </FlexInnerWrapper>

        <ActionsFooter>
          <div>
            <Button className="empty" to={`/@${slug}`}>{t('global:Cancel')}</Button>
          </div>
          <div>
            <Button onClick={openNewModModal}>{t('global:Add')}</Button>
          </div>
        </ActionsFooter>
      </FlexWrapper>

      {showingNewModModal && (
        <SearchUsersModal
          title={t('Add mod/admin')}
          confirm={add}
          close={closeNewModModal}
          confirmLoading={addingMod}
        >
          <ToggleButtonGroup buttons={toggleButtons} onChange={onTypeChange} selected={type} />
        </SearchUsersModal>
      )}
    </>
  );
};

Content.propTypes = {
  communityId: PropTypes.string.isRequired,
};

Content.defaultProps = {
};

export default Content;
