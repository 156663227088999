import { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useOpenClose, useTranslation } from 'hooks';
import * as feedActions from 'state/feed/actions';
import * as feedSelectors from 'state/feed/selectors';

import PoundBox from 'components/Icons/PoundBox';
import Modal from 'components/Modal';
import { SelectableList, SelectableListItem } from 'components/SelectableList';

import locales from './i18n';

const Wrapper = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;

  > button {
    border: 0;
    background: transparent;
    margin: 0;
    padding: 0;
    cursor: pointer;
    outline: none;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  div {
    overflow: hidden;
    flex: 1;
    height: 24px;
    line-height: 24px;
    margin-left: 8px;
    font-size: 14px;
  }
`;
Wrapper.displayName = 'LatestHashtagsWrapper';

const Hashtag = styled.span`
  a {
    color: ${props => props.theme.colors.main};

    ${props => props.promoted && `
      font-weight: bold;
    `}
  }
`;
Hashtag.displayName = 'Hashtag';

const LatestHashtags = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const hashtags = useSelector(feedSelectors.getLatestHashtags(), shallowEqual);

  useEffect(() => {
    dispatch(feedActions.fetchLatestHashtags());
  }, [dispatch]);

  return (
    <Wrapper>
      <button onClick={openModal} type="button"><PoundBox color="#999" /></button>

      <div>
        {hashtags.map(hashtag => (
          <Hashtag key={`ht-${hashtag.hashtag}`} promoted={hashtag.promoted}>
            <Link to={`/hashtags/${hashtag.hashtag}${!hashtag.in.includes('feed') ? '?threads' : ''}`}>{`#${hashtag.hashtag}`}</Link>
            {' '}
          </Hashtag>
        ))}
      </div>

      {isModalOpen && (
        <Modal onClose={closeModal} title={t('Latest hashtags')}>
          <SelectableList>
            {hashtags.map(hashtag => (
              <SelectableListItem key={`modal-ht-${hashtag.hashtag}`} to={`/hashtags/${hashtag.hashtag}${!hashtag.in.includes('feed') ? '?threads' : ''}`}>
                {`#${hashtag.hashtag}`}
              </SelectableListItem>
            ))}
          </SelectableList>
        </Modal>
      )}
    </Wrapper>
  );
};

LatestHashtags.propTypes = {
};

LatestHashtags.defaultProps = {
};

export default LatestHashtags;
