const composers = {};

export default {
  setRef: (id, ref) => {
    if (!composers[id]) composers[id] = {};
    composers[id].ref = ref;
  },

  setValue: (id, value) => {
    if (!composers[id]) composers[id] = {};
    composers[id].value = value;
  },

  clear: (id) => {
    if (composers[id]) {
      composers[id].ref.setValue('');
      composers[id].ref.clearHistory();
    }
  },

  disable: (id) => {
    if (composers[id]) {
      composers[id].ref.setOption('readOnly', 'nocursor');
      composers[id].ref.readOnly = true;
    }
  },

  enable: (id) => {
    if (composers[id]) {
      composers[id].ref.setOption('readOnly', false);
      composers[id].ref.readOnly = false;
    }
  },

  getRef: id => (composers[id] ? composers[id].ref : null),
};
