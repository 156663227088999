import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';
import * as authActions from 'state/auth/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Loading from 'components/Loading';

import MuteToggles from './MuteToggles';
import locales from '../../i18n';

const MuteModal = ({ userId, close }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const mutedInServices = useRef({ feed: false });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [feed, setFeed] = useState(false);

  useEffect(() => {
    const load = async () => {
      try {
        const data = await dispatch(authActions.getInFeedMuted());
        if (data.includes(userId)) {
          mutedInServices.current.feed = true;
          setFeed(true);
        }
        setLoading(false);
      } catch (error) {
        dispatch(appActions.addError(error));
        close();
      }
    };

    load();
  }, [userId, dispatch, close]);

  const onConfirm = async () => {
    try {
      setSaving(true);

      if (feed && !mutedInServices.current.feed) {
        await dispatch(authActions.muteInFeed(userId));
      }
      if (!feed && mutedInServices.current.feed) {
        await dispatch(authActions.unmuteInFeed(userId));
      }

      dispatch(appActions.addToast(t('Muting updated')));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    close();
  };

  const toggleFeed = () => {
    setFeed(f => !f);
  };

  return (
    <Modal
      title={t('Mute/Unmute')}
      onCancel={close}
      actions={[(
        <Button
          key="mute-confirm"
          onClick={onConfirm}
          loading={saving}
          disabled={loading}
        >
          {t('global:Confirm')}
        </Button>
      )]}
    >
      {loading
        ? <Loading />
        : (
          <>
            <p>
              {t('In which sections do you want to stop seeing this person\'s publications?')}
            </p>
            <p>
              <em>{t('The user will not be notified of your action.')}</em>
            </p>

            <br />

            <MuteToggles feed={feed} toggleFeed={toggleFeed} />
          </>
        )
      }
    </Modal>
  );
};

MuteModal.propTypes = {
  userId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};

MuteModal.defaultProps = {
};

export default MuteModal;
