import {
  Alert, AlertDescription, AlertIcon, AlertTitle, Button, Text,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import * as eventSelectors from 'state/events/selectors';
import { useTranslation } from 'hooks';

import locales from '../i18n';

interface Props {
  eventId: string;
}

const NoPaymentWarning: React.FC<Props> = ({ eventId }) => {
  const { t } = useTranslation(locales);

  // @ts-ignore
  const hasPendingPayment = useSelector(state => eventSelectors.hasPendingPayment(state, eventId));
  // @ts-ignore
  const eventAlreadyStarted = useSelector(state => eventSelectors.eventAlreadyStarted(state, eventId));
  // @ts-ignore
  const paymentsLink = useSelector(state => eventSelectors.paymentLink(state, eventId));

  if (!hasPendingPayment || eventAlreadyStarted || !paymentsLink) return null;

  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
      mb={8}
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        {t('Payment pending!')}
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        <Text mb={4} fontSize="small" lineHeight="short">
          {t('You have a pending payment for this event. Please complete the payment to unluck all premium features.')}
        </Text>
        <Button variant="primary" as="a" href={paymentsLink} target="_blank">{t('Pay')}</Button>
      </AlertDescription>
    </Alert>
  );
};

export default NoPaymentWarning;
