export const LOAD = 'mazmo/replies/LOAD';
export const LOAD_SUCCESS = 'mazmo/replies/LOAD_SUCCESS';
export const LOAD_FAIL = 'mazmo/replies/LOAD_FAIL';

export const THREAD_PREPEND = 'mazmo/replies/THREAD_PREPEND';
export const THREAD_APPEND = 'mazmo/replies/THREAD_APPEND';
export const FULLY_LOADED = 'mazmo/replies/FULLY_LOADED';

export const APPEND_REACTION = 'mazmo/replies/APPEND_REACTION';
export const REMOVE_REACTION = 'mazmo/replies/REMOVE_REACTION';
export const APPEND_DISLIKE = 'mazmo/replies/APPEND_DISLIKE';
export const REMOVE_DISLIKE = 'mazmo/replies/REMOVE_DISLIKE';

export const NEW_REPLY = 'mazmo/replies/NEW_REPLY';
export const REMOVE_REPLY = 'mazmo/replies/REMOVE_REPLY';
export const EDIT_REPLY = 'mazmo/replies/EDIT_REPLY';

export const WIPE = 'mazmo/replies/WIPE';
