import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { HStack, Text } from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const Follow = ({ timestamp, read, ...params }) => {
  const { t } = useTranslation(locales);

  const userId = params.user ? params.user.id : params.userId;

  const username = useSelector(userSelectors.getUsername(userId));
  const isLoaded = useSelector(userSelectors.isLoaded(userId));

  const url = isLoaded ? `/@${username}` : undefined;

  return (
    <AlertContainer timestamp={timestamp} image={<UserAvatar userId={userId} size="40px" showOnline={false} />} to={url} read={read}>
      <HStack spacing={1}>
        <strong><UserDisplayName userId={userId} /></strong>
        <Text>{t('is now following you')}</Text>
      </HStack>
    </AlertContainer>
  );
};

Follow.propTypes = {
  read: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default Follow;
