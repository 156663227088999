import { Trans } from 'react-i18next';
import { Box, Icon } from '@chakra-ui/react';
import { Gift } from 'lucide-react';

import { useTranslation } from 'hooks';

import UserDisplayName from 'components/UserDisplayName';
import UserAvatar from 'components/UserAvatar';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

interface Props {
  timestamp: string;
  read: boolean;
  tags: string[];
  fromUserId: number;
}

const TagIcon: React.FC<{ userId: number }> = ({ userId }) => (
  <Box position="relative">
    {/* @ts-ignore */}
    <UserAvatar userId={userId} size="40px" showOnline={false} />
    <Icon as={Gift} bgColor="brand.500" color="brand.200" rounded="full" boxSize={4} p={0.5} w="auto" position="absolute" right="-2px" bottom="-2px" />
  </Box>
);

const TagsGifted: React.FC<Props> = ({
  timestamp, read, tags, fromUserId,
}) => {
  const { t } = useTranslation(locales);

  const tagnames = tags.map((tagName) => t(`global:TAG.${tagName}`)).join(', ');
  const params = new URLSearchParams();
  tags.forEach((tag) => params.append('tags', tag));
  params.append('giftFromUserId', fromUserId.toString());
  const toUrl = `/user/edit/tags?${params.toString()}`;

  if (!tags.length) return null;
  if (tags.length === 1) {
    return (
      <AlertContainer timestamp={timestamp} image={<TagIcon userId={fromUserId} />} read={read} to={toUrl}>
        <Trans t={t} ns="alerts" i18nKey="tagGifted" values={{ tagnames }}>
          <strong><UserDisplayName userId={fromUserId} /></strong>
          {' '}
          gifted you a tag:
          {' '}
          <strong>{'{{tagnames}}'}</strong>
        </Trans>
      </AlertContainer>
    );
  }

  return (
    <AlertContainer timestamp={timestamp} image={<TagIcon userId={fromUserId} />} read={read} to={toUrl}>
      <Trans t={t} ns="alerts" i18nKey="tagsGifted" values={{ tagnames }}>
        <strong><UserDisplayName userId={fromUserId} /></strong>
        {' '}
        gifted you some tags:
        {' '}
        <strong>{'{{tagnames}}'}</strong>
      </Trans>
    </AlertContainer>
  );
};

export default TagsGifted;
