import { normalize } from 'normalizr';
import ReactGA from 'react-ga4';
import { batch } from 'react-redux';

import Api from 'state/api';
import threadSchema from 'state/threads/schema';

import * as userActions from 'state/users/actions';
import * as eventActions from 'state/events/actions';
import * as communityActions from 'state/communities/actions';

import { THREADS_LIST_LIMIT, MEMBERSHIP_ROLES } from '../../constants';
import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  CREATE_SUCCESS,
  CREATE_FAIL,
  FULLY_LOADED,
  PREPEND_RECENT,
  APPEND_RECENT,
  SLUG_LOADING_START,
  SLUG_LOADING_STOP,
  SLUG_NOT_FOUND,
  REMOVE,
  EDIT,
  APPEND_REACTION,
  REMOVE_REACTION,
  APPEND_DISLIKE,
  REMOVE_DISLIKE,
  MARK_AS_READ,
  WIPE,
} from './constants';

const adminRoles = [
  MEMBERSHIP_ROLES.OWNER,
  MEMBERSHIP_ROLES.ADMIN,
];
const modRoles = [
  ...adminRoles,
  MEMBERSHIP_ROLES.MODERATOR,
];

const doCreate = thread => (dispatch) => {
  try {
    const normalizedData = normalize(thread, threadSchema);

    const threads = {
      [normalizedData.result]: {
        ...normalizedData.entities.threads[normalizedData.result],
        reactions: [],
      },
    };

    batch(() => {
      dispatch({ type: CREATE_SUCCESS, data: threads });
      dispatch({ type: PREPEND_RECENT, data: [normalizedData.result] });

      const { users, events } = normalizedData.entities;
      if (users && Object.keys(users).length > 0) {
        dispatch(userActions.add(users));
      }
      if (events && Object.keys(events).length > 0) {
        dispatch(eventActions.load(events));
      }
    });

    return normalizedData;
  } catch (err) {
    let error = err.message;
    if (err.response) {
      error = err.response.data;
    } else if (err.request) {
      error = err.request;
    }

    dispatch({ type: CREATE_FAIL, error });
    return error;
  }
};

const processNormalizedData = (normalizedData, id) => (dispatch) => {
  batch(() => {
    if (normalizedData.result.length < THREADS_LIST_LIMIT) {
      dispatch({ type: FULLY_LOADED, id });
    }

    const { users, events, threads } = normalizedData.entities;
    if (users && Object.keys(users).length > 0) {
      dispatch(userActions.add(users));
    }
    if (events && Object.keys(events).length > 0) {
      dispatch(eventActions.load(events));
    }
    if (threads && Object.keys(threads).length > 0) {
      dispatch({ type: LOAD_SUCCESS, id, data: threads });
    }
  });
};

const loadList = (id, before) => async (dispatch, getState) => {
  const { memberships, auth } = getState();
  const community = dispatch(communityActions.get(id));
  const url = id === 'recent' ? '/communities/threads' : `/communities/${community.slug}/threads`;
  dispatch({ type: LOAD, id });

  const params = {
    limit: THREADS_LIST_LIMIT,
  };
  if (before) params.before = before;

  const isMod = auth.me && Object.values(memberships.data)
    .some(m => m.community === id && modRoles.includes(m.role));
  if (isMod) params.withDeleted = true;

  const { data } = await Api.req.get(url, { params });
  const normalizedData = normalize(data, [threadSchema]);

  dispatch(processNormalizedData(normalizedData, id));

  return normalizedData;
};

const loadError = err => (dispatch) => {
  let error = err.message;
  if (err.response) {
    // eslint-disable-next-line
    error = err.response.data.error;
  } else if (err.request) {
    error = err.request;
  }

  dispatch({ type: LOAD_FAIL, error });
};

export const getBySlug = slug => (dispatch, getState) => (
  Object.values(getState().threads.data).find(t => t.slug === slug)
);

export const loadByCommunity = (id, before) => async (dispatch) => {
  try {
    const { result } = await dispatch(loadList(id, before));
    await dispatch(communityActions.appendThreads(id, result));
  } catch (err) {
    dispatch(loadError(err));
  }
};

export const loadOne = (communitySlug, threadSlug) => async (dispatch) => {
  try {
    dispatch({ type: SLUG_LOADING_START, slug: threadSlug });

    const { data } = await Api.req.get(`/communities/${communitySlug}/threads/${threadSlug}`);
    const normalizedData = normalize(data, threadSchema);

    batch(() => {
      const { users, events, threads } = normalizedData.entities;
      if (users && Object.keys(users).length > 0) {
        dispatch(userActions.add(users));
      }
      if (events && Object.keys(events).length > 0) {
        dispatch(eventActions.load(events));
      }
      if (threads && Object.keys(threads).length > 0) {
        dispatch({
          type: LOAD_SUCCESS,
          id: data.community,
          data: (normalizedData.entities.threads || {}),
        });
      }

      dispatch({ type: SLUG_LOADING_STOP, slug: threadSlug });
    });

    return `/+${communitySlug}/${data.slug}`;
  } catch (error) {
    dispatch({ type: SLUG_NOT_FOUND, slug: threadSlug });
    return null;
  }
};

const processRecent = (result, threads) => (dispatch) => {
  batch(() => {
    dispatch({ type: APPEND_RECENT, data: result });
    result.forEach((thread) => {
      const communityId = threads[thread].community;
      dispatch(communityActions.appendThreads(communityId, [thread]));
    });
  });
};

export const addRecent = data => (dispatch) => {
  const normalizedData = normalize(data, [threadSchema]);

  batch(() => {
    dispatch(processNormalizedData(normalizedData, 'recent'));

    const { result, entities: { threads } } = normalizedData;
    dispatch(processRecent(result, threads));
  });
};

export const loadRecent = before => async (dispatch) => {
  try {
    const { result, entities: { threads } } = await dispatch(loadList('recent', before));

    dispatch(processRecent(result, threads));
  } catch (err) {
    dispatch(loadError(err));
  }
};

export const load = (communitySlug, threadId) => async (dispatch) => {
  const { data } = await Api.req.get(`/communities/${communitySlug}/threads/${threadId}`);
  const normalizedData = normalize(data, threadSchema);

  const { users, events, threads } = normalizedData.entities;

  batch(() => {
    if (users && Object.keys(users).length > 0) {
      dispatch(userActions.add(users));
    }
    if (events && Object.keys(events).length > 0) {
      dispatch(eventActions.load(events));
    }
  });

  if (threads && Object.keys(threads).length > 0) {
    const result = normalizedData.entities.threads[normalizedData.result];
    dispatch({ type: LOAD_SUCCESS, id: result.community, data: normalizedData.entities.threads });

    return result;
  }

  return null;
};

export const create = (communitySlug, payload) => async (dispatch) => {
  const { data } = await Api.req.post(`/communities/${communitySlug}/threads`, payload);

  ReactGA.event({
    category: 'Communities',
    action: 'Thread created',
    label: communitySlug,
  });

  const {
    result: threadId,
    entities: { threads },
  } = await dispatch(doCreate(data));

  const communityId = threads[threadId].community;
  dispatch(communityActions.prependThreads(communityId, [threadId]));

  return data;
};

export const remove = threadId => async (dispatch, getState) => {
  const thread = getState().threads.data[threadId];
  const community = getState().communities.data[thread.community];

  await Api.req.delete(`/communities/${community.slug}/threads/${thread.slug}`);

  ReactGA.event({
    category: 'Communities',
    action: 'Thread deleted',
    label: threadId,
  });

  dispatch({ type: REMOVE, id: threadId });
};

export const edit = (thread, payload) => async (dispatch) => {
  const community = dispatch(communityActions.get(thread.community));

  const { data } = await Api.req.put(`/communities/${community.slug}/threads/${thread.slug}`, payload);
  const normalizedData = normalize(data, threadSchema);

  dispatch({
    type: EDIT,
    id: thread._id,
    data: normalizedData.entities.threads[normalizedData.result],
  });
};

export const pin = threadId => async (dispatch, getState) => {
  const thread = getState().threads.data[threadId];
  const community = getState().communities.data[thread.community];

  const { data } = await Api.req.put(`/communities/${community.slug}/threads/${thread.slug}/pin`, { pin: true });
  const normalizedData = normalize(data, threadSchema);

  ReactGA.event({
    category: 'Communities',
    action: 'Thread pinned',
    label: threadId,
  });

  dispatch({
    type: EDIT,
    id: thread._id,
    data: normalizedData.entities.threads[normalizedData.result],
  });
};

export const unpin = threadId => async (dispatch, getState) => {
  const thread = getState().threads.data[threadId];
  const community = getState().communities.data[thread.community];

  const { data } = await Api.req.put(`/communities/${community.slug}/threads/${thread.slug}/pin`, { pin: false });
  const normalizedData = normalize(data, threadSchema);

  ReactGA.event({
    category: 'Communities',
    action: 'Thread unpinned',
    label: threadId,
  });

  dispatch({
    type: EDIT,
    id: threadId,
    data: normalizedData.entities.threads[normalizedData.result],
  });
};

export const close = threadId => async (dispatch, getState) => {
  const thread = getState().threads.data[threadId];
  const community = getState().communities.data[thread.community];

  const { data } = await Api.req.put(`/communities/${community.slug}/threads/${thread.slug}/close`, { close: true });
  const normalizedData = normalize(data, threadSchema);

  ReactGA.event({
    category: 'Communities',
    action: 'Thread close',
    label: threadId,
  });

  dispatch({
    type: EDIT,
    id: thread._id,
    data: normalizedData.entities.threads[normalizedData.result],
  });
};

export const reopen = threadId => async (dispatch, getState) => {
  const thread = getState().threads.data[threadId];
  const community = getState().communities.data[thread.community];

  const { data } = await Api.req.put(`/communities/${community.slug}/threads/${thread.slug}/close`, { close: false });
  const normalizedData = normalize(data, threadSchema);

  ReactGA.event({
    category: 'Communities',
    action: 'Thread reopened',
    label: threadId,
  });

  dispatch({
    type: EDIT,
    id: threadId,
    data: normalizedData.entities.threads[normalizedData.result],
  });
};

export const createReaction = threadId => async (dispatch, getState) => {
  const { me } = getState().auth;

  if (!me) {
    document.location.href = '/login';
  } else {
    const meId = me.id;
    const thread = getState().threads.data[threadId];
    const community = getState().communities.data[thread.community];

    await Api.req.post(`/communities/${community.slug}/threads/${thread.slug}/reactions`);

    ReactGA.event({
      category: 'Communities',
      action: 'Thread spank',
      label: threadId,
    });

    dispatch({ type: APPEND_REACTION, threadId, userId: meId });
  }
};

export const deleteReaction = threadId => async (dispatch, getState) => {
  const meId = getState().auth.me.id;
  const thread = getState().threads.data[threadId];
  if (!thread) throw new Error('Couldn\'t find the thread');
  const community = getState().communities.data[thread.community];

  await Api.req.delete(`/communities/${community.slug}/threads/${thread.slug}/reactions`);

  ReactGA.event({
    category: 'Communities',
    action: 'Thread unspank',
    label: threadId,
  });

  dispatch({ type: REMOVE_REACTION, threadId, userId: meId });
};

export const createDislike = threadId => async (dispatch, getState) => {
  const { me } = getState().auth;

  if (!me) {
    document.location.href = '/login';
  } else {
    const meId = me.id;
    const thread = getState().threads.data[threadId];
    const community = getState().communities.data[thread.community];

    await Api.req.post(`/communities/${community.slug}/threads/${thread.slug}/dislikes`);

    ReactGA.event({
      category: 'Communities',
      action: 'Thread dislike',
      label: threadId,
    });

    dispatch({ type: APPEND_DISLIKE, threadId, userId: meId });
  }
};

export const deleteDislike = threadId => async (dispatch, getState) => {
  const meId = getState().auth.me.id;
  const thread = getState().threads.data[threadId];
  if (!thread) throw new Error('Couldn\'t find the thread');
  const community = getState().communities.data[thread.community];

  await Api.req.delete(`/communities/${community.slug}/threads/${thread.slug}/dislikes`);

  ReactGA.event({
    category: 'Communities',
    action: 'Thread undislike',
    label: threadId,
  });

  dispatch({ type: REMOVE_DISLIKE, threadId, userId: meId });
};

export const markThreadAsRead = (threadId, silent = false) => (dispatch, getState) => {
  if (getState().auth.me) {
    // Optimisticly mark threads as read
    dispatch({ type: MARK_AS_READ, threadIds: [threadId], silent });
    if (!silent) Api.req.put('/communities/read', null, { params: { threadId } });
  }
};

export const markCommunityAsRead = communityId => (dispatch, getState) => {
  const threadIds = getState().communities.data[communityId].threads;
  // Optimisticly mark threads as read
  dispatch({ type: MARK_AS_READ, threadIds });
  Api.req.put('/communities/read', null, { params: { communityId } });
};

export const markMembershipsAsRead = () => (dispatch, getState) => {
  const { memberships, communities } = getState();

  const myMemberships = Object.values(memberships.data)
    .filter(membership => membership.approved)
    .map(m => m.community);

  const threadIds = myMemberships.map(cId => communities.data[cId].threads).flat();

  // Optimisticly mark threads as read
  dispatch({ type: MARK_AS_READ, threadIds });
  Api.req.put('/communities/read');
};

export const handleThreadCreated = data => async (dispatch) => {
  const threadWithReactions = { ...data, reactions: [] };
  let event = null;

  if (data.eventId) {
    const { data: eventdata } = await Api.req.get(`/events/${data.eventId}`);
    event = eventdata;
  }

  batch(() => {
    if (event) {
      dispatch(eventActions.load({ [event.id]: event }));

      threadWithReactions.event = data.eventId;
    }

    dispatch(userActions.add(data.author.id));
    const {
      result: threadId,
      entities: { threads },
    } = dispatch(doCreate(threadWithReactions));
    const communityId = threads[threadId].community;
    dispatch(communityActions.prependThreads(communityId, [threadId]));
  });
};

export const handleThreadUpdated = data => async (dispatch, getState) => {
  const currentThread = getState().threads.data[data.id];
  const nextThread = {
    ...data,
    reactions: currentThread ? currentThread.reactions : [],
    readAt: currentThread ? currentThread.readAt : [],
  };

  if (currentThread) nextThread.community = currentThread.community;
  else nextThread.community = typeof data.community === 'object' ? data.community.id : data.community;

  batch(() => {
    const {
      result: threadId,
      entities: { threads },
    } = dispatch(doCreate(nextThread));

    const communityId = threads[threadId].community;
    dispatch(communityActions.prependThreads(communityId, [threadId]));
  });
};

export const wipe = () => dispatch => dispatch({ type: WIPE });
