import { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import NotFound from 'containers/NotFound';
import Loading from 'components/Loading';

const AuthedRoute = lazy(() => import('./AuthedRoute' /* webpackChunkName: "authedroute" */));
const EventsLanding = lazy(() => import('containers/Events/Landing' /* webpackChunkName: "eventslanding" */));
const Create = lazy(() => import('containers/Events/Create' /* webpackChunkName: "eventscreate" */));
const Admin = lazy(() => import('containers/Events/Administration' /* webpackChunkName: "eventsadministration" */));
const PayPalCapture = lazy(() => import('containers/Events/PayPalCapture' /* webpackChunkName: "eventspaypalcapture" */));

const EventsSwitch = () => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <AuthedRoute
        path="/events"
        exact
        component={EventsLanding}
      />
      <AuthedRoute
        path="/events/create"
        exact
        component={Create}
      />
      <AuthedRoute
        path="/events/:eventId/paypal/capture"
        exact
        component={PayPalCapture}
      />
      <AuthedRoute
        path="/events/:eventId"
        component={Admin}
      />

      <Route component={NotFound} />
    </Switch>
  </Suspense>
);

EventsSwitch.propTypes = {
};

EventsSwitch.defaultProps = {
};

export default EventsSwitch;
