import {
  Fragment, useState, useRef, useCallback, useEffect,
} from 'react';
import fastdom from 'fastdom';
import { useDispatch } from 'react-redux';

import * as feedActions from 'state/feed/actions';

import Spinner from 'components/Spinner';
import SpinnerWrapper from 'components/Spinner/Wrapper';
import Publication from 'components/Publication';
import Ad from 'components/Ad';

const TrendingFeed = () => {
  const dispatch = useDispatch();

  const [publicationIds, setPublicationIds] = useState([]);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const isLoading = useRef(false);
  const publicationsCount = useRef(0);

  const load = useCallback(async () => {
    if (!isLoading.current && !fullyLoaded) {
      isLoading.current = true;
      const ids = await dispatch(feedActions.loadTrending(publicationsCount.current));
      if (!ids.length) setFullyLoaded(true);
      setPublicationIds(prevIds => [...prevIds, ...ids]);
      isLoading.current = false;
      publicationsCount.current += ids.length;
    }
  }, [dispatch, fullyLoaded]);

  useEffect(() => {
    const el = document.documentElement;

    const loadMoreScrollChanged = () => {
      fastdom.measure(() => {
        if (el.scrollHeight - el.scrollTop < 2500) {
          load();
        }
      });
    };

    document.addEventListener('scroll', loadMoreScrollChanged);
    load();

    return () => {
      document.removeEventListener('scroll', loadMoreScrollChanged);
    };
  }, [load]);

  return (
    <>
      {publicationIds.map((id, index) => (
        <Fragment key={`pub-${id}`}>
          <Publication publicationId={id} />
          {!((index - 1) % 8) && <Ad id="In Feed" />}
        </Fragment>
      ))}

      {!fullyLoaded && (
      <SpinnerWrapper>
        <Spinner color="#999" />
      </SpinnerWrapper>
      )}
    </>
  );
};

TrendingFeed.propTypes = {
};

TrendingFeed.defaultProps = {
};

export default TrendingFeed;
