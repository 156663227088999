const customemojis = () => [
  {
    name: 'Mazmo',
    short_names: ['mazmo'],
    keywords: ['mazmo'],
    imageUrl: 'https://mazmo.net/images/isologo.png',
  },
];

export default customemojis;
