import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';

import Own from './Own';
import Others from './Others';

const Actions = ({ userId }) => {
  const meId = useSelector(authSelectors.selectId);

  if (!userId || !meId) return null;
  if (userId === meId) return <Own userId={userId} />;
  return <Others userId={userId} />;
};

Actions.propTypes = {
  userId: PropTypes.number.isRequired,
};

Actions.defaultProps = {
};

export default Actions;
