import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
  cursor: pointer;
  width: 40px !important;
  height: 40px !important;
  justify-content: center;

  > svg {
    width: 32px;
    height: 32px;
    display: block !important;

    path {
      fill: ${props => props.theme.colors.main};
    }
  }

  @media(max-width: 767px) {
    display: none;
  }
`;

export default Wrapper;
