import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;

  .composer {
    border-top: 1px solid ${props => props.theme.colors.mainLight};
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
    margin-top: auto;
    background-color: white;
    max-height: 40vh;
    padding: 13px 16px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    > div:first-child {
      display: flex;
    }

    > div:nth-child(2) {
      flex: 1;
      margin: 0 8px;
    }

    .cm-header {
      font-size: 1em;
      font-weight: normal;
    }

    .edit-buttons {
      button {
        border: 0;
        background: none;
        cursor: pointer;
        outline: none;
        margin: 0 4px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .community-upload-media-container {
    padding: 16px;
    margin: 0;
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
