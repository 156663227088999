import styled from 'styled-components';

const ToastContainer = styled.div`
  position: fixed;
  bottom: 0;
  padding: 15px;
  left: 0;
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  box-sizing: border-box;
`;

export default ToastContainer;
