import produce from 'immer';

import {
  ERRORS_ADD,
  ERRORS_CLEAR,
  TOASTS_ADD,
  TOASTS_REMOVE,
  ADINFO_OPEN,
  ADINFO_CLOSE,
  MINIPROFILE_SET,
  NAVLEFT_ACTIVE,
  UI_LEFT_COLUMN,
  UI_RIGHT_COLUMN,
  UI_CHANNELS_SHOWING_PARTICIPANTS,
  NEW_LOCATION,
  MEDIA_SET,
  MEDIA_ADD,
  MEDIA_REMOVE,
  MEDIA_CLEAR,
  MEDIA_UPLOAD_COMPLETE,
  WIPE,
  TOGGLE_SAFE_FOR_WORK,
  COMPOSER_HAS_CONTENT,
  COMPOSER_SAVING,
  COMPOSER_READONLY,
  CHAT_LIST_SET,
  CHAT_LIST_UPDATE,
  CHAT_LIST_REMOVE,
  RESOURCES_LOADED,
  SW_REGISTERED,
  SW_WAITING,
} from './constants';

export const initialState = {
  errors: [],
  toasts: [],
  safeForWork: (localStorage.getItem('safeForWork') === 'true'),
  miniprofile: null,
  ui: {
    channels: {
      showingParticipants: false,
    },
    leftColumn: false,
    rightColumn: false,
    adInfo: false,
  },
  chatList: [],
  locationHistory: [],
  editors: {},
  composers: {},
  navLeftActive: false,
  resourcesLoaded: false,
  sw: {
    registration: null,
    waiting: null,
  },
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case ERRORS_ADD:
      draft.errors.push(action.data);
      break;

    case ERRORS_CLEAR:
      draft.errors = initialState.errors;
      break;

    case TOASTS_ADD:
      draft.toasts.push(action.data);
      break;

    case TOASTS_REMOVE:
      draft.toasts.splice(action.index, 1);
      break;

    case ADINFO_OPEN:
      draft.ui.adInfo = true;
      break;

    case ADINFO_CLOSE:
      draft.ui.adInfo = false;
      break;

    case MINIPROFILE_SET:
      draft.miniprofile = action.data;
      break;

    case NAVLEFT_ACTIVE:
      draft.navLeftActive = action.active;
      break;

    case UI_LEFT_COLUMN:
      draft.ui.leftColumn = action.active;
      break;

    case UI_RIGHT_COLUMN:
      draft.ui.rightColumn = action.active;
      break;

    case UI_CHANNELS_SHOWING_PARTICIPANTS:
      draft.ui.channels.showingParticipants = action.show;
      break;

    case TOGGLE_SAFE_FOR_WORK:
      draft.safeForWork = !state.safeForWork;
      localStorage.setItem('safeForWork', draft.safeForWork);
      break;

    case NEW_LOCATION:
      draft.locationHistory.unshift(action.location);
      break;

    // File upload handling
    case MEDIA_SET:
      if (!state.composers[action.id]) draft.composers[action.id] = {};
      draft.composers[action.id].media = action.data;
      break;

    case MEDIA_ADD:
      if (!state.composers[action.id]) draft.composers[action.id] = { media: [] };
      else if (!state.composers[action.id].media) draft.composers[action.id].media = [];
      draft.composers[action.id].media.push({
        ...action.data,
        uploaded: false,
      });
      break;

    case MEDIA_REMOVE: {
      if (state.composers[action.id] && state.composers[action.id].media) {
        draft.composers[action.id].media.splice(action.index, 1);
      }
      break;
    }

    case MEDIA_CLEAR: {
      if (state.composers[action.id]) {
        draft.composers[action.id].media = [];
      }
      break;
    }

    case MEDIA_UPLOAD_COMPLETE: {
      const index = state.composers[action.id].media.findIndex(m => m.file.name === action.name);
      draft.composers[action.id].media.splice(index, 1, {
        ...state.composers[action.id].media[index],
        uploaded: true,
        filename: action.filename,
      });
      break;
    }

    // Composers
    case COMPOSER_HAS_CONTENT:
      if (!state.composers[action.id]) draft.composers[action.id] = {};
      draft.composers[action.id].hasContent = action.hasContent;
      break;

    case COMPOSER_SAVING:
      if (!state.composers[action.id]) draft.composers[action.id] = {};
      draft.composers[action.id].saving = action.saving;
      break;

    case COMPOSER_READONLY:
      if (!state.composers[action.id]) draft.composers[action.id] = {};
      draft.composers[action.id].readOnly = action.readOnly;
      break;

    case CHAT_LIST_SET:
      draft.chatList = action.list;
      break;

    case CHAT_LIST_UPDATE:
      draft.chatList = [
        { type: action.entityType, id: action.entityId },
        ...state.chatList.filter(({ type, id }) => (
          type !== action.entityType || id !== action.entityId
        )),
      ];
      break;

    case CHAT_LIST_REMOVE:
      draft.chatList = state.chatList.filter(({ type, id }) => (
        type !== action.entityType || id !== action.entityId
      ));
      break;

    case RESOURCES_LOADED:
      draft.resourcesLoaded = true;
      break;

    case SW_REGISTERED:
      draft.sw.registration = action.registration;
      break;

    case SW_WAITING:
      draft.sw.waiting = action.waiting;
      break;

    case WIPE:
      draft.errors = initialState.errors;
      draft.toasts = initialState.toasts;
      break;

    default:
  }
});

export default reducer;
