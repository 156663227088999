import { schema } from 'normalizr';

import user from 'state/users/schema';
import thread from 'state/threads/schema';
import community from 'state/communities/schema';

const reply = new schema.Entity('replies', {
  author: user,
  thread,
  community,
}, {
  idAttribute: '_id',
});

export default reply;
