import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import locales from '../i18n';

const ParticipantGenderSuffixWrapper = styled.div`
  margin-left: auto;
  align-self: center;
  font-size: 0.75rem;
  color: #999;
`;

const ParticipantGenderSuffix = ({ userId }) => {
  const { t } = useTranslation(locales);

  const gender = useSelector(userSelectors.getGender(userId));
  const age = useSelector(userSelectors.getAge(userId));
  const city = useSelector(userSelectors.getCityName(userId));
  const region = useSelector(userSelectors.getRegionName(userId));
  const country = useSelector(userSelectors.getCountryName(userId));
  const isOrganization = useSelector(userSelectors.isOrganization(userId));

  if (isOrganization) return null;

  let genderSuffix = null;
  if (gender && age) genderSuffix = `${age}${t(`GENDER_SUFFIX.${gender}`)}`;
  else if (gender) genderSuffix = `${t(`GENDER_SUFFIX.${gender}`)}`;
  else if (age) genderSuffix = `${age}`;

  const description = [];
  if (gender) description.push(t(`global:GENDER.${gender}`));
  if (age) description.push(`${age} ${t('years')}`);
  if (city) description.push(city);
  if (region) description.push(region);
  if (country) description.push(country);

  const genderSuffixHover = description.join(', ');

  return (
    <ParticipantGenderSuffixWrapper title={genderSuffixHover}>
      {genderSuffix}
    </ParticipantGenderSuffixWrapper>
  );
};

ParticipantGenderSuffix.propTypes = {
  userId: PropTypes.number.isRequired,
};

ParticipantGenderSuffix.defaultProps = {
};

export default ParticipantGenderSuffix;
