import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import usersToText from 'utils/usersToText';
import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import UserAvatar from 'components/UserAvatar';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const ReplyAwarded = ({
  alertId,
  threadUrl,
  authors: authorIds,
  read,
  timestamp,
}) => {
  const { t } = useTranslation(locales);

  // Remove duplications (this should be a service feature in the future)
  const idsSet = new Set();
  authorIds.forEach(id => idsSet.add(id));
  const authorsBatch = useSelector(userSelectors.getListByIds([...idsSet]), shallowEqual);

  const authors = Object.values(authorsBatch).reverse();
  const authorsText = usersToText(authors, t, alertId);

  return (
    <AlertContainer
      timestamp={timestamp}
      image={<UserAvatar userId={authors[0].id} size="40px" showOnline={false} />}
      to={threadUrl.replace('https://mazmo.net', '')}
      read={read}
    >
      {authorsText}
      {' '}
      {t('awarded you for a reply', { context: authors.length > 1 && 'PLURAL' })}
    </AlertContainer>
  );
};

ReplyAwarded.propTypes = {
  alertId: PropTypes.string.isRequired,
  threadUrl: PropTypes.string.isRequired,
  authors: PropTypes.arrayOf(PropTypes.number).isRequired,
  read: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default ReplyAwarded;
