import { CircularProgress, HStack, Text } from '@chakra-ui/react';
import { UseFormWatch } from 'react-hook-form';
import { z } from 'zod';

import { checklistSelectionsFormSchema } from './ChecklistForm';

interface Props {
  watch: UseFormWatch<z.infer<typeof checklistSelectionsFormSchema>>;
}

const Progress: React.FC<Props> = ({ watch }) => {
  const selections = watch('selections');

  const total = Object.keys(selections).length;

  if (!total) return null;

  const completed = Object.values(selections).filter((selection) => (
    selection.experience !== ''
    && selection.interest !== ''
    && typeof selection.interest !== 'undefined'
    && typeof selection.experience !== 'undefined'
  )).length;
  const progress = (completed / total) * 100;

  return (
    <HStack>
      <CircularProgress color="gray.900" value={progress} size="24px" />
      <Text color="gray.500" fontSize="sm">{`${Math.floor(progress)}%`}</Text>
    </HStack>
  );
};

export default Progress;
