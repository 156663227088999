import { FunctionComponent, memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import * as appSelectors from 'state/app/selectors';
import * as userSelectors from 'state/users/selectors';
import { User } from 'types';

import Online from './Online';
import Badges from './Badges';
import defaultAvatar from './default.png';

interface AvatarWrapperProps extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  size: string,
}

const AvatarWrapper = styled.div.attrs({
  className: 'avatar',
}) <AvatarWrapperProps>`
  position: relative;
  width: ${props => props.size};
  height: ${props => props.size};

  ${props => props.onClick && `
    cursor: pointer;
  `}
`;

interface AvatarImgProps extends Omit<React.HTMLProps<HTMLImageElement>, 'size'> {
  size: string,
  src: string,
}

const AvatarImg = styled.img.attrs<AvatarImgProps>(props => ({
  width: props.size,
  height: props.size,
})) <AvatarImgProps>`
  border-radius: 100%;
  width: ${props => props.size};
  height: ${props => props.size};
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
  flex-shrink: 0;
  vertical-align: middle;
`;

const userEqual = (prevUser: User, nextUser: User) => (
  prevUser.id === nextUser.id
  && prevUser.avatar === nextUser.avatar
  && prevUser.loading === nextUser.loading
);

interface Props extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  userId: number,
  size?: string,
  showOnline?: boolean,
  onlineBorderColor?: string,
}

const UserAvatar: FunctionComponent<Props> = ({
  userId,
  size = '50px',
  showOnline = true,
  onlineBorderColor = 'white',
  ...props
}) => {
  const user: User = useSelector(userSelectors.getById(userId), userEqual);
  const isSafeForWork = useSelector(appSelectors.selectIsSafeForWork);

  const src = !user || user.loading || isSafeForWork || !user.avatar || !user.avatar['150w'] ? defaultAvatar : user.avatar['150w'].jpeg;

  return (
    <AvatarWrapper size={size} {...props}>
      <AvatarImg src={src} size={size} />
      {showOnline && <Online size={size} userId={userId} borderColor={onlineBorderColor} />}
      {showOnline && <Badges size={size} userId={userId} />}
    </AvatarWrapper>
  );
};

const equality = (prevProps: Props, nextProps: Props) => (
  prevProps.userId === nextProps.userId
  && prevProps.size === nextProps.size
  && prevProps.showOnline === nextProps.showOnline
  && prevProps.onlineBorderColor === nextProps.onlineBorderColor
);
export default memo<Props>(UserAvatar, equality);
