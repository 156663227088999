import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Hashtag = ({ value }) => {
  const hashtag = value
    .trim()
    .substr(1)
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  return (
    <>
      {value.substr(0, 1) === ' ' && ' '}
      <Link to={`/hashtags/${hashtag}`}>{value.trim()}</Link>
    </>
  );
};

Hashtag.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Hashtag;
