import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'hooks';
import * as eventActions from 'state/events/actions';

import Modal from 'components/Modal';
import Loading from 'components/Loading';
import EmptyState from 'components/EmptyState';

import locales from '../../i18n';

const CoversWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  > div {
    box-sizing: border-box;
    padding: 4px;

    img {
      width: 100%;
    }
  }
`;
CoversWrapper.displayName = 'CoversWrapper';

const Events = ({ userId, close }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [events, setEvents] = useState(null);

  useEffect(() => {
    const load = async () => {
      const data = await dispatch(eventActions.listByUserId(userId));
      setEvents(data);
    };

    load();
  }, [dispatch, userId]);

  return (
    <Modal
      title={t('Assisted Events')}
      onClose={close}
    >
      {events === null ? (
        <Loading />
      ) : (
        <div>
          {events.length > 0 ? (
            <CoversWrapper>
              {events.map(event => (
                <div key={`event-${event.id}`}>
                  <img src={event.cover} alt={event.name} />
                </div>
              ))}
            </CoversWrapper>
          ) : (
            <EmptyState title={t('Haven\'t assisted to any Event')} />
          )}
        </div>
      )}
    </Modal>
  );
};

Events.propTypes = {
  userId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};

Events.defaultProps = {
};

export default Events;
