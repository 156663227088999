import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as appActions from 'state/app/actions';
import * as messengerSelectors from 'state/messengers/selectors';
import * as messengerActions from 'state/messengers/actions';

import colors from 'utils/css/colors';

import { SelectableListItem } from 'components/SelectableList';
import Avatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';
import Spinner from 'components/Spinner';

// const Handle = styled.span`
//   font-size: 12px;
//   color: #999;
// `;

const LoadingAvatar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.8);
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 100%;
  line-height: 40px;
  display: flex;
  align-items: center;

  div {
    margin: 0 auto;
  }
`;

const UserAvatar = ({ userId, loading }) => (
  <>
    <Avatar userId={userId} />
    {loading && (
      <LoadingAvatar>
        <Spinner color={colors.red} />
      </LoadingAvatar>
    )}
  </>
);

UserAvatar.propTypes = {
  loading: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

const Contact = ({ userId, style }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const messengerId = useSelector(state => messengerSelectors.selectByUserId(state, userId));

  const [creating, setCreating] = useState(null);

  const onSelect = useCallback(async () => {
    if (!creating) {
      if (messengerId) history.push(`/chat/messengers/${messengerId}`);
      else {
        setCreating(userId);
        try {
          const data = await dispatch(messengerActions.create([userId]));
          history.push(`/chat/messengers/${data.id}`);
        } catch (error) {
          dispatch(appActions.addError(error));
          setCreating(false);
        }
      }
    }
  }, [creating, dispatch, history, messengerId, userId]);

  const renderAvatar = useCallback(() => (
    <UserAvatar userId={userId} loading={creating === userId} />
  ), [userId, creating]);

  const title = useMemo(() => (
    <UserDisplayName userId={userId} />
  ), [userId]);

  // const renderContent = useCallback(() => (
  //   <Handle>{`@${user.username}`}</Handle>
  // ), [user.username]);

  return (
    <SelectableListItem
      renderAvatar={renderAvatar}
      title={title}
      onClick={onSelect}
      style={style}
      // renderContent={renderContent}
    />
  );
};

Contact.propTypes = {
  userId: PropTypes.number.isRequired,
  style: PropTypes.shape({}).isRequired,
};

export default Contact;
