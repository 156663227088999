import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'utils/css/colors';

import { CHANNEL_MESSAGES_TYPES } from '../../../../constants';

const TOUCH_TIME = 500;

const ContentWrapper = styled.div`
  ${props => (props.outgoing
    ? `
      margin-left: 0px;
      margin-right: ${props.hasAvatar ? '6' : '38'}px;
      background-color: ${colors.borderRed};
      border-radius: 9px 0 9px 9px;
    ` : `
      margin-left: ${props.hasAvatar ? '6' : '38'}px;
      margin-right: 0px;
      background-color: #eee;
      border-radius: 0 9px 9px 9px;
    `
  )}

  ${props => props.type === CHANNEL_MESSAGES_TYPES.NOTICE && `
    border: 1px solid ${props.theme.colors.warningBorder};
    background-color: ${props.theme.colors.warningBackground};
  `}

  padding: 6px 9px;
  position: relative;

  ${props => props.bordered && `
    border: 1px solid ${props.theme.colors.main};
  `}

  @media(max-width: 768px) {
    padding: 4px 9px 0;
    user-select: none;
  }

  .message-header {
    position: relative;
    display: flex;
  }

  &:hover {
    @media(min-width: 768px) {
      .options {
        visibility: visible;
      }
    }
  }
`;
ContentWrapper.displayName = 'BubbleContentWrapper';

const Content = ({
  outgoing, hasAvatar, showMobileOptions, children, bordered, type,
}) => {
  const element = useRef(null);

  useEffect(() => {
    const el = element.current;
    let timer;
    let initialX;
    let initialY;
    let currentX;
    let currentY;

    const trigger = () => {
      if (Math.abs(currentX - initialX) < 25 && Math.abs(currentY - initialY) < 25) {
        showMobileOptions();
      }
    };

    const touchstart = (e) => {
      initialX = e.changedTouches[0].clientX;
      initialY = e.changedTouches[0].clientY;
      currentX = initialX;
      currentY = initialY;
      timer = setTimeout(trigger, TOUCH_TIME);
    };

    const touchend = () => {
      if (timer) clearTimeout(timer);
    };

    const touchmove = (e) => {
      currentX = e.changedTouches[0].clientX;
      currentY = e.changedTouches[0].clientY;
    };

    if (el && showMobileOptions) {
      el.addEventListener('touchstart', touchstart);
      el.addEventListener('touchmove', touchmove);
      el.addEventListener('touchend', touchend);
    }

    return () => {
      if (el) {
        el.removeEventListener('touchstart', touchstart);
        el.removeEventListener('touchmove', touchmove);
        el.removeEventListener('touchend', touchend);
      }
    };
  }, [showMobileOptions]);

  return (
    <>
      <ContentWrapper
        ref={element}
        outgoing={outgoing}
        hasAvatar={hasAvatar}
        bordered={bordered}
        className="message-bubble"
        type={type}
      >
        {children}
      </ContentWrapper>
    </>
  );
};

Content.propTypes = {
  outgoing: PropTypes.bool,
  hasAvatar: PropTypes.bool,
  showMobileOptions: PropTypes.func,
  children: PropTypes.node.isRequired,
  bordered: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(CHANNEL_MESSAGES_TYPES)),
};

Content.defaultProps = {
  outgoing: false,
  hasAvatar: false,
  showMobileOptions: null,
  bordered: false,
  type: CHANNEL_MESSAGES_TYPES.MESSAGE,
};

export default Content;
