import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';

import Modal from 'components/Modal';
import Button from 'components/Button';
import { SelectableList, SelectableListItem } from 'components/SelectableList';

import locales from './i18n';

const UsersListsModal = ({
  close, onConfirm, initialLists, confirmLoading,
}) => {
  const { t } = useTranslation(locales);
  const [selectedLists, setSelectedLists] = useState(initialLists);
  const lists = useSelector(authSelectors.selectUserLists, shallowEqual);

  const toggleSelectedList = id => () => {
    if (selectedLists.includes(id)) setSelectedLists(selectedLists.filter(listId => listId !== id));
    else setSelectedLists([...selectedLists, id]);
  };

  const onConfirmButtonClick = () => {
    onConfirm(selectedLists);
  };

  return (
    <Modal
      title={t('Users lists')}
      onCancel={close}
      zIndex={10000}
      actions={[
        <Button
          key="users-lists-confirm"
          onClick={onConfirmButtonClick}
          loading={confirmLoading}
        >
          {t('global:Confirm')}
        </Button>,
      ]}
    >
      {t('Select the lists you want this user to be in')}

      <SelectableList>
        {lists.map(list => (
          <SelectableListItem
            key={`users-list-${list.id}`}
            title={list.name}
            onClick={toggleSelectedList(list.id)}
            active={selectedLists.includes(list.id)}
          />
        ))}
      </SelectableList>
    </Modal>
  );
};

UsersListsModal.propTypes = {
  close: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  initialLists: PropTypes.arrayOf(PropTypes.string).isRequired,
  confirmLoading: PropTypes.bool,
};

UsersListsModal.defaultProps = {
  confirmLoading: false,
};

export default UsersListsModal;
