import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  Flex, HStack, Icon, Stack, Text,
  useTheme,
} from '@chakra-ui/react';
import { X } from 'lucide-react';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import UserDisplayName from 'components/UserDisplayName';
import UserAvatar from 'components/UserAvatar';
import UserCountryFlag from 'components/UserCountryFlag';
import UserLink from 'components/UserLink';
import UserFollowButton from 'components/UserFollowButton';

import locales from '../i18n';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;

  .userlink {
    display: flex;
    flex: 1;

    .avatar {
      margin-right: 8px;
    }

    > div:not(.avatar) {
      flex: 1;

      > div {
        display: flex;
        font-size: 12px;
        color: ${props => props.theme.colors.secondary};

        .country-flag {
          width: 16px;
          height: 11px;
          margin-right: 4px;
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  > button {
    background-color: white;
    padding-right: 36px;

    svg path {
      fill: black;
    }

    > div {
      width: 0;
    }
  }

  > svg {
    width: 16px;
    height: 16px;
    opacity: 0.5;
    cursor: pointer;
    margin-left: 4px;

    path {
      fill: ${props => props.theme.colors.secondary};
    }
  }
`;
Wrapper.displayName = 'Wrapper';

const User = ({ userId, remove }) => {
  const { t } = useTranslation(locales);
  const theme = useTheme();

  const gender = useSelector(userSelectors.getGender(userId));
  const age = useSelector(userSelectors.getAge(userId));

  const removeUser = () => remove(userId);

  return (
    <Wrapper>
      <Flex justifyContent="space-between" w="full">
        <UserLink userId={userId}>
          <HStack spacing={0}>
            <UserAvatar userId={userId} size="32px" />
            <Stack spacing={0}>
              <Text noOfLines={1} color="black" fontSize="md"><UserDisplayName userId={userId} /></Text>
              {gender && (
                <HStack spacing={0}>
                  <UserCountryFlag userId={userId} />
                  <Text fontSize="xs" color="gray.500">
                    {t(`global:GENDER.${gender}`)}
                    {' '}
                    {t('{{age}} years old', { age })}
                  </Text>
                </HStack>
              )}
            </Stack>
          </HStack>
        </UserLink>
        <HStack spacing={1}>
          <UserFollowButton userId={userId} size="xs" variant="outline" leftIcon={null} />
          <Icon
            as={X}
            boxSize="12px"
            onClick={removeUser}
            color={theme.colors.gray['400']}
            _hover={{ cursor: 'pointer', color: theme.colors.gray['600'] }}
          />
        </HStack>
      </Flex>
    </Wrapper>
  );
};

User.propTypes = {
  userId: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
};

User.defaultProps = {
};

export default User;
