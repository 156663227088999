import PropTypes from 'prop-types';

import colors from 'utils/css/colors';
import styled from 'styled-components';

export const Title = styled.h1`
  color: ${colors.blackRed};
  font-size: 24px;
  margin: 0;
  @media(min-width: 768px) {
    font-size: 28px;
  }
  @media(min-width: 1280px) {
    font-size: 40px;
  }
`;

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Title;
