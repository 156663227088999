import { Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import * as feedSelectors from 'state/feed/selectors';

import Publication from 'components/Publication';
import Ad from 'components/Ad';

const PublicationsList = () => {
  const publicationIds = useSelector(feedSelectors.selectFeed, shallowEqual);

  return (
    <>
      {publicationIds.map((id, index) => (
        <Fragment key={`pub-${id}`}>
          <Publication key={`publication-${id}`} publicationId={id} />
          {!((index - 1) % 8) && <Ad id="In Feed" />}
        </Fragment>
      ))}
    </>
  );
};

PublicationsList.propTypes = {
};

PublicationsList.defaultProps = {
};

export default PublicationsList;
