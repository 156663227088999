import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';
import * as replySelectors from 'state/replies/selectors';

import locales from '../i18n';

const DeletedHeaderWrapper = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${colors.red};
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  padding: 2px 0;
  border-top: 1px solid white;
  position: absolute;
  top: 0;
`;

const DeletedHeader = ({ replyId }) => {
  const { t } = useTranslation(locales);
  const isDeleted = useSelector(replySelectors.isDeleted(replyId));

  if (!isDeleted) return null;

  return <DeletedHeaderWrapper>{t('Reply removed')}</DeletedHeaderWrapper>;
};

DeletedHeader.propTypes = {
  replyId: PropTypes.string.isRequired,
};

DeletedHeader.defaultProps = {
};

export default DeletedHeader;
