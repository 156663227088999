import styled, { keyframes } from 'styled-components';

import CSS from 'utils/css/mainCSS';

const Item = styled.li`
  background-color: white;
  padding: 0 32px 32px;
  border-radius: 16px;
  box-shadow: ${CSS.boxShadow};
  display: flex;
  flex-direction: column;
  position: relative;
`;

const animation = keyframes`
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
`;

const Background = styled.div`
  animation: ${animation} 1.25s forwards infinite linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  position: relative;
`;

const Avatar = styled(Background)`
  width: 96px;
  height: 96px;
  margin: -48px auto 24px;
  border-radius: 4px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.16);
  flex-shrink: 0;
  align-self: center;
`;

const Title = styled(Background)`
  height: 12px;
  width: 100%;
  background-color: red;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Button = styled(Background)`
  height: 40px;
`;

const Loader = () => (
  <>
    <Item>
      <Avatar />
      <Title />
      <Title />
      <Button />
    </Item>
    <Item>
      <Avatar />
      <Title />
      <Title />
      <Button />
    </Item>
    <Item>
      <Avatar />
      <Title />
      <Title />
      <Button />
    </Item>
    <Item>
      <Avatar />
      <Title />
      <Title />
      <Button />
    </Item>
    <Item>
      <Avatar />
      <Title />
      <Title />
      <Button />
    </Item>
    <Item>
      <Avatar />
      <Title />
      <Title />
      <Button />
    </Item>
    <Item>
      <Avatar />
      <Title />
      <Title />
      <Button />
    </Item>
    <Item>
      <Avatar />
      <Title />
      <Title />
      <Button />
    </Item>
  </>
);

export default Loader;
