import { useState, useCallback } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { useTranslation } from 'hooks';

import Warning from 'components/Warning';
import Button from 'components/Button';
import PurchaseButton from 'components/PurchaseButton';
import { Close } from 'components/Icons';

import locales from '../i18n';

const Wrapper = styled.div`
  text-align: center;
  padding: 8px;
  position: relative;

  .close-button {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -4px;
    top: -4px;
    cursor: pointer;

    svg path {
      fill: ${props => props.theme.colors.secondary};
    }
  }

  .buttons {
    margin-top: 16px;
    display: flex;
    justify-content: space-evenly;

    @media(max-width: 768px) {
      flex-direction: column;

      button {
        margin-bottom: 8px;
      }
    }
  }
`;
Wrapper.displayName = 'Wrapper';

const ChatRequestsExhausted = () => {
  const { t } = useTranslation(locales);

  const dismissed = localStorage.getItem('exhaustedchatswarning');
  const daysdismissed = dismissed ? moment().diff((new Date(0)).setUTCSeconds(parseInt(dismissed, 10)), 'days') : 7;

  const [closed, setClosed] = useState(daysdismissed < 7);

  const close = useCallback(() => {
    localStorage.setItem('exhaustedchatswarning', ((new Date()).getTime() / 1000));
    setClosed(true);
  }, []);

  if (closed) return null;

  return (
    <Warning fullMargin>
      <Wrapper>
        <div
          className="close-button"
          role="button"
          onClick={close}
          tabIndex={-1}
          onKeyDown={close}
        >
          <Close />
        </div>
        <div>{t('You\'ve exhausted all your private chat requests. Get more to continue meeting other users.')}</div>
        <div className="buttons">
          <PurchaseButton itemName="CHAT_REQUESTS_5" component={<Button color="white" fontColor="black">{t('Add 5 more')}</Button>} />
          <PurchaseButton itemName="CHAT_REQUESTS_20" component={<Button>{t('Add 20 more')}</Button>} />
          <PurchaseButton itemName="CHAT_REQUESTS_50" component={<Button color="white" fontColor="black">{t('Add 50 more')}</Button>} />
        </div>
      </Wrapper>
    </Warning>
  );
};

ChatRequestsExhausted.propTypes = {
};

ChatRequestsExhausted.defaultProps = {
};

export default ChatRequestsExhausted;
