import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { HStack, Text } from '@chakra-ui/react';

import * as channelSelectors from 'state/channels/selectors';

import { useTranslation } from 'hooks';

import { AccessPoint } from 'components/Icons';

import locales from '../i18n';

const colors = ['gray.100', 'gray.400'];

const Online = ({ channelId }) => {
  const { t } = useTranslation(locales);
  const online = useSelector(state => channelSelectors.onlineCount(state, channelId));

  return (
    <HStack title={t('Online')}>
      <AccessPoint fill={colors} boxSize={5} />
      <Text fontSize="xs" color={colors}>{online}</Text>
    </HStack>
  );
};

Online.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default Online;
