import { memo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';
import * as appSelectors from 'state/app/selectors';
import { type Bot } from 'types';

import defaultAvatar from './default.png';

interface AvatarWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  size: string;
  onClick?: () => void;
}

const AvatarWrapper = styled.div.attrs({
  className: 'avatar',
})<AvatarWrapperProps>`
  position: relative;
  width: ${props => props.size};
  height: ${props => props.size};

  ${props => props.onClick && `
    cursor: pointer;
  `}
`;

interface AvatarImgProps {
  bot: Bot;
  size: string;
  isSafeForWork: boolean;
}

const AvatarImg = styled.img.attrs(({ bot, isSafeForWork }: AvatarImgProps) => ({
  src: (!bot || bot.loading || isSafeForWork || !bot.avatar) ? defaultAvatar : bot.avatar,
}))<AvatarImgProps>`
  border-radius: 100%;
  width: ${props => props.size};
  height: ${props => props.size};
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
  flex-shrink: 0;
  vertical-align: middle;
`;

const botEqual = (prevBot: Bot, nextBot: Bot) => (
  prevBot.id === nextBot.id
  && prevBot.avatar === nextBot.avatar
  && prevBot.loading === nextBot.loading
);

interface BotAvatarProps {
  userId: string;
  botId: string;
  size: string;
}

const BotAvatar = ({ size = '50px', botId, ...props }: BotAvatarProps) => {
  // @ts-ignore
  const bot = useSelector(state => channelSelectors.getBotById(state, botId), botEqual) as Bot;
  const isSafeForWork = useSelector(appSelectors.selectIsSafeForWork);

  return (
    <AvatarWrapper size={size} {...props}>
      <AvatarImg bot={bot} size={size} isSafeForWork={isSafeForWork} />
    </AvatarWrapper>
  );
};

const equality = (prevProps: BotAvatarProps, nextProps: BotAvatarProps) => (
  prevProps.userId === nextProps.userId
  && prevProps.size === nextProps.size
);
export default memo(BotAvatar, equality);
