import { memo } from 'react';
import PropTypes from 'prop-types';

import { Alerts } from 'components/Icons';
import Badge from 'components/Badge';

const AlertIcon = memo(({ unread }) => {
  const badge = unread > 0 ? unread : null;

  return (
    <div>
      {badge && <Badge value={badge} />}
      <Alerts color="white" />
    </div>
  );
});

AlertIcon.propTypes = {
  unread: PropTypes.number.isRequired,
};

export default AlertIcon;
