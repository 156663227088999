import { useState, useRef } from 'react';
import {
  MenuItem, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button,
} from '@chakra-ui/react';
import { LogOut } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useTranslation } from 'hooks';
import useToast from 'hooks/useToast';
import * as channelsActions from 'state/channels/actions';

import locales from '../../../i18n';

const PartChannelAction: React.FC = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useToast();
  const { chatId: channelId } = useParams<{ chatId: string }>();

  const [isOpen, setIsOpen] = useState(false);
  const [parting, setParting] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const onClose = () => setIsOpen(false);

  const onConfirm = async () => {
    try {
      setParting(true);
      history.push('/chat');
      await dispatch(channelsActions.part(channelId));
      toast.success(t('You left the channel'));
    } catch (error) {
      toast.error(error);
      setParting(false);
    }
  };

  return (
    <>
      <MenuItem icon={<LogOut size={16} />} onClick={() => setIsOpen(true)}>
        {t('Part channel')}
      </MenuItem>

      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('Part channel')}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t('Are you sure you want to leave this channel?')}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>{t('global:Cancel')}</Button>
              <Button colorScheme="red" onClick={onConfirm} ml={3} isLoading={parting}>
                {t('global:Confirm')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default PartChannelAction;
