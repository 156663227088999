import { normalize } from 'normalizr';
import ReactGA from 'react-ga4';
import { batch } from 'react-redux';

import Api from 'state/api';
import replySchema from 'state/replies/schema';
import * as userActions from 'state/users/actions';
import * as communityActions from 'state/communities/actions';
import * as threadActions from 'state/threads/actions';
import * as threadSelectors from 'state/threads/selectors';
import * as communitySelectors from 'state/communities/selectors';

import { REPLIES_LIST_LIMIT, MEMBERSHIP_ROLES } from '../../constants';
import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  THREAD_PREPEND,
  THREAD_APPEND,
  FULLY_LOADED,
  APPEND_REACTION,
  REMOVE_REACTION,
  APPEND_DISLIKE,
  REMOVE_DISLIKE,
  NEW_REPLY,
  REMOVE_REPLY,
  EDIT_REPLY,
  WIPE,
} from './constants';

const adminRoles = [
  MEMBERSHIP_ROLES.OWNER,
  MEMBERSHIP_ROLES.ADMIN,
];
const modRoles = [
  ...adminRoles,
  MEMBERSHIP_ROLES.MODERATOR,
];

export const load = (threadId, before) => async (dispatch, getState) => {
  try {
    const { memberships, auth } = getState();

    const threadSlug = threadSelectors.getSlug(threadId)(getState());
    const communityId = threadSelectors.getCommunityId(threadId)(getState());
    const communitySlug = communitySelectors.selectSlugById(getState(), communityId);

    dispatch({ type: LOAD, id: threadId });

    const params = {
      limit: REPLIES_LIST_LIMIT,
      sort: 'desc',
    };
    if (before) params.before = before;

    const isMod = auth.me && Object.values(memberships.data)
      .some(m => m.community === communityId && modRoles.includes(m.role));
    if (isMod) params.withDeleted = true;

    const { data } = await Api.req.get(`/communities/${communitySlug}/threads/${threadSlug}/replies`, { params });
    const normalizedData = normalize(data, [replySchema]);

    batch(() => {
      dispatch(userActions.add(normalizedData.entities.users || {}));
      dispatch({ type: LOAD_SUCCESS, id: threadId, data: normalizedData.entities.replies });
      dispatch({ type: THREAD_PREPEND, id: threadId, data: normalizedData.result.reverse() });
    });

    if (normalizedData.result.length < REPLIES_LIST_LIMIT) {
      dispatch({ type: FULLY_LOADED, id: threadId });
    }
  } catch (err) {
    let error = err.message;
    if (err.response) {
      // eslint-disable-next-line
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }

    dispatch({ type: LOAD_FAIL, id: threadId, error });
  }
};

export const create = (threadId, payload) => async (dispatch, getState) => {
  const thread = getState().threads.data[threadId];
  const community = dispatch(communityActions.get(thread.community));

  const { data } = await Api.req.post(`/communities/${community.slug}/threads/${thread.slug}/replies`, payload);
  const replyWithReactions = { ...data, reactions: [] };
  const normalizedData = normalize(replyWithReactions, replySchema);

  ReactGA.event({
    category: 'Communities',
    action: 'Reply created',
    label: `${community.name} - ${thread.title}`,
  });

  batch(() => {
    dispatch({ type: LOAD_SUCCESS, id: threadId, data: normalizedData.entities.replies });
    dispatch({ type: THREAD_APPEND, id: threadId, data: [normalizedData.result] });
    dispatch(threadActions.markThreadAsRead(threadId, true));
  });
};

export const remove = replyId => async (dispatch, getState) => {
  const { replies, threads, communities } = getState();
  const threadId = replies.data[replyId].thread;
  const thread = threads.data[threadId];
  const community = communities.data[thread.community];

  await Api.req.delete(`/communities/${community.slug}/threads/${thread.slug}/replies/${replyId}`);

  ReactGA.event({
    category: 'Communities',
    action: 'Reply deleted',
    label: `${community.name} - ${thread.title}`,
  });

  dispatch({ type: REMOVE_REPLY, id: replyId });
};

export const edit = (reply, payload) => async (dispatch, getState) => {
  const community = dispatch(communityActions.get(reply.community));
  const thread = getState().threads.data[reply.thread];

  const { data } = await Api.req.put(`/communities/${community.slug}/threads/${thread.slug}/replies/${reply._id}`, payload);
  const normalizedData = normalize(data, replySchema);

  ReactGA.event({
    category: 'Communities',
    action: 'Reply edited',
    label: `${community.name} - ${thread.title}`,
  });

  dispatch({
    type: EDIT_REPLY,
    id: reply._id,
    data: normalizedData.entities.replies[normalizedData.result],
  });
};

export const createReaction = replyId => async (dispatch, getState) => {
  const {
    replies, threads, communities, auth,
  } = getState();

  const meId = auth.me.id;
  const threadId = replies.data[replyId].thread;
  const thread = threads.data[threadId];
  const community = communities.data[thread.community];

  await Api.req.post(`/communities/${community.slug}/threads/${thread.slug}/replies/${replyId}/reactions`);

  ReactGA.event({
    category: 'Communities',
    action: 'Reply spank',
    label: replyId,
  });

  dispatch({ type: APPEND_REACTION, replyId, userId: meId });
};

export const deleteReaction = replyId => async (dispatch, getState) => {
  const {
    replies, threads, communities, auth,
  } = getState();

  const meId = auth.me.id;
  const threadId = replies.data[replyId].thread;
  const thread = threads.data[threadId];
  const community = communities.data[thread.community];

  await Api.req.delete(`/communities/${community.slug}/threads/${thread.slug}/replies/${replyId}/reactions`);

  ReactGA.event({
    category: 'Communities',
    action: 'Reply unspank',
    label: replyId,
  });

  dispatch({ type: REMOVE_REACTION, replyId, userId: meId });
};

export const createDislike = replyId => async (dispatch, getState) => {
  const {
    replies, threads, communities, auth,
  } = getState();

  const meId = auth.me.id;
  const threadId = replies.data[replyId].thread;
  const thread = threads.data[threadId];
  const community = communities.data[thread.community];

  await Api.req.post(`/communities/${community.slug}/threads/${thread.slug}/replies/${replyId}/dislikes`);

  ReactGA.event({
    category: 'Communities',
    action: 'Reply dislike',
    label: replyId,
  });

  dispatch({ type: APPEND_DISLIKE, replyId, userId: meId });
};

export const deleteDislike = replyId => async (dispatch, getState) => {
  const {
    replies, threads, communities, auth,
  } = getState();

  const meId = auth.me.id;
  const threadId = replies.data[replyId].thread;
  const thread = threads.data[threadId];
  const community = communities.data[thread.community];

  await Api.req.delete(`/communities/${community.slug}/threads/${thread.slug}/replies/${replyId}/dislikes`);

  ReactGA.event({
    category: 'Communities',
    action: 'Reply undisliked',
    label: replyId,
  });

  dispatch({ type: REMOVE_DISLIKE, replyId, userId: meId });
};

export const handleReplyCreated = payload => (dispatch) => {
  const replyWithReactions = { ...payload, reactions: [] };
  const {
    result: replyId,
    entities: { replies },
  } = normalize(replyWithReactions, replySchema);
  const reply = replies[replyId];

  batch(() => {
    dispatch(userActions.add(reply.author.id));
    dispatch(communityActions.prependThreads(reply.community, [reply.thread]));
    dispatch({ type: NEW_REPLY, data: replies });
    dispatch({ type: THREAD_APPEND, id: reply.thread, data: [replyId] });
  });
};

export const wipe = () => dispatch => dispatch({ type: WIPE });
