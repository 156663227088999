import styled from 'styled-components';

const TabsContent = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  z-index: 1;
`;

export default TabsContent;
