import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { useTranslation, useTitle } from 'hooks';
import * as appActions from 'state/app/actions';

import Sidebar from 'containers/Sidebar';
import Layout from 'components/Layout';
import { FlexWrapper, FlexContainer } from 'components/FlexWrapper';
import PageHeader from 'components/PageHeader';

import Content from './Content';
import locales from './i18n';

const KinkyTest: React.FC = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  useTitle(t('KinkyTest'));

  useEffect(() => {
    dispatch(appActions.uiLeftColumn(true));
  }, [dispatch]);

  return (
    <Layout columns={2} feed leftColumnOpen={false} rightColumnOpen={false}>
      {/* @ts-ignore */}
      <FlexWrapper canOverflow>
        {/* @ts-ignore */}
        <FlexContainer framed>
          <Box position="relative">
            <PageHeader title={t('KinkyTest')} />

            <Content />
          </Box>
        </FlexContainer>
      </FlexWrapper>

      <Sidebar />
    </Layout>
  );
};

export default KinkyTest;
