import PropTypes from 'prop-types';
import moment from 'moment';

import UserType from 'state/users/type';

import ChatStateIcon from 'components/ChatStateIcon';

import Wrapper from './Wrapper';
import Content from './Content';
import AvatarLink from './AvatarLink';
import AuthorLink from './AuthorLink';
import Message from './Message';
import Info from './Info';

const Bubble = ({
  children,
  outgoing,
  author,
  state,
  time,
  showAuthor,
  showAvatar,
  big,
  bordered,
}) => (
  <Wrapper outgoing={outgoing} hasAvatar={showAvatar}>
    {showAvatar && <AvatarLink authorId={author.id} />}
    <Content outgoing={outgoing} hasAvatar={showAvatar} bordered={bordered}>
      {showAuthor && <AuthorLink authorId={author.id} />}
      <Message big={big}>{children}</Message>
      {time && (
        <Info>
          <span>{moment(time).format('h:mm a')}</span>
          {state && outgoing && <ChatStateIcon state={state} />}
        </Info>
      )}
    </Content>
  </Wrapper>
);

Bubble.propTypes = {
  children: PropTypes.node.isRequired,
  outgoing: PropTypes.bool,
  author: UserType.isRequired,
  state: PropTypes.oneOf([
    'SENDING',
    'SENT',
    'RECEIVED',
    'READ',
  ]),
  time: PropTypes.string,
  showAuthor: PropTypes.bool,
  showAvatar: PropTypes.bool,
  big: PropTypes.bool,
  bordered: PropTypes.bool,
};

Bubble.defaultProps = {
  outgoing: false,
  state: null,
  time: null,
  showAuthor: false,
  showAvatar: true,
  big: false,
  bordered: false,
};

export default Bubble;
