import { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import removeComments from 'remark-remove-comments';
import remarkGfm from 'remark-gfm';
import shortid from 'shortid';
import {
  chakra, Divider, Image, Text,
} from '@chakra-ui/react';

import H2 from 'ui/H2';
import H3 from 'ui/H3';

import Wrapper from './Wrapper';
import Emoji from './Emoji';
import Hashtag from './Hashtag';
import Mention from './Mention';
import Community from './Community';
import Channel from './Channel';
import Link from './Link';

import specialCharMap from './specialCharMap';

const EMOJI_RE = /:\+1:|:-1:|:[\w-]+:(:skin-tone-(\d):)?/;
const HASHTAG_RE = /(^|\s)#([a-zA-Z0-9\u00C0-\u00ff][\u00C0-\u00ff\w-]*){2,}/;
const MENTION_RE = /(^|\s)(@([a-zA-Z0-9_-]){3,})/;
const COMMUNITY_RE = /(^|\s)(\+([a-zA-Z0-9_-]){3,})/;
const CHANNEL_RE = /(^|\s)(%([a-zA-Z0-9_-]){3,})/;

const ALL_RE = new RegExp([
  EMOJI_RE.source,
  HASHTAG_RE.source,
  MENTION_RE.source,
  COMMUNITY_RE.source,
  CHANNEL_RE.source,
].join('|'), 'gi');

const types = [
  'em', 'strong', 'blockquote', 'a', 'img', 'table', 'thead', 'tbody', 'tr', 'td', 'th',
  'ul', 'li', 'ol', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre', 'code', 'del', 'hr',
];

const isEmojiOnly = content => content.replace(/^(:([a-zA-Z0-9+-][\w-]*):)(:skin-tone-(\d):)?/, '').trim() === '';

const ParsedContent = ({
  content, emojis, hashtags, mentions, communities, channels,
  markdown, emojiSize, disallowed, emojiOnlySize,
}) => {
  const eSize = emojiOnlySize && isEmojiOnly(content) ? emojiOnlySize : emojiSize;
  const EmojiComponent = ({ value }) => <Emoji value={value} size={eSize} />;
  EmojiComponent.propTypes = { value: PropTypes.string.isRequired };

  const disallowedElements = useMemo(() => (markdown ? disallowed : types), [markdown, disallowed]);

  const keybase = shortid.generate();

  const sanitizedContent = useMemo(() => {
    if (markdown) return content;
    return Array.from(content).map(char => specialCharMap[char] || char).join('');
  }, [content, markdown]);

  return (
    <Wrapper emojiSize={eSize}>
      <ReactMarkdown
        remarkPlugins={[
          remarkGfm,
          removeComments,
        ]}
        components={{
          a: Link,
          h1: chakra(H2, { baseStyle: { mt: 8 } }),
          h2: chakra(H2, { baseStyle: { mt: 8 } }),
          h3: chakra(H3, { baseStyle: { mt: 8 } }),
          h4: chakra(H3, { baseStyle: { mt: 6 } }),
          h5: chakra(H3, { baseStyle: { mt: 4 } }),
          h6: chakra(H3, { baseStyle: { mt: 4 } }),
          hr: chakra(Divider, { baseStyle: { my: 4 } }),
          img: chakra(Image, { baseStyle: { display: 'initial' } }),
          ol: chakra('ol', { baseStyle: { ml: 5 }, shouldForwardProp: (prop) => prop !== 'ordered' }),
          ul: chakra('ul', { baseStyle: { ml: 5 }, shouldForwardProp: (prop) => prop !== 'ordered' }),
          p: ({ node, ...props }) => {
            const result = [];

            // eslint-disable-next-line implicit-arrow-linebreak, react/prop-types
            (props.children || []).forEach((child, index) => {
              if (child && (typeof child === 'string')) {
                let match;
                let lastIndex = 0;

                // eslint-disable-next-line no-cond-assign
                while ((match = ALL_RE.exec(child)) !== null) {
                  const value = match[0];
                  // eslint-disable-next-line max-len
                  const key = `${keybase}-${node.position.start.line}-${node.position.start.column}-${node.position.end.line}-${node.position.end.column}-${lastIndex}-${match.index}-${index}`;

                  if (match.index !== lastIndex) {
                    result.push(<span key={`${key}-head`}>{child.slice(lastIndex, match.index)}</span>);
                  }

                  if (emojis && EMOJI_RE.test(value)) {
                    result.push(<EmojiComponent key={`${key}-${lastIndex}-${match.index}`} value={value} />);
                  } else if (hashtags && HASHTAG_RE.test(value)) {
                    result.push(<Hashtag key={`${key}-${lastIndex}-${match.index}`} value={value} />);
                  } else if (mentions && MENTION_RE.test(value)) {
                    result.push(<Mention key={`${key}-${lastIndex}-${match.index}`} value={value} />);
                  } else if (communities && COMMUNITY_RE.test(value)) {
                    result.push(<Community key={`${key}-${lastIndex}-${match.index}`} value={value} />);
                  } else if (channels && CHANNEL_RE.test(value)) {
                    result.push(<Channel key={`${key}-${lastIndex}-${match.index}`} value={value} />);
                  } else {
                    result.push(<span key={`${key}-${lastIndex}-${match.index}`}>{value}</span>);
                  }

                  lastIndex = match.index + value.length;
                }

                if (lastIndex !== child.length) {
                  // eslint-disable-next-line react/no-array-index-key,max-len
                  result.push(<span key={`${keybase}-${node.position.start.line}-${node.position.start.column}-${node.position.end.line}-${index}${node.position.end.column}-tail`}>{child.slice(lastIndex, child.length)}</span>);
                }
              } else {
                result.push(child);
              }
            });

            return <Text mb={2}>{result}</Text>;
          },
        }}
        disallowedElements={disallowedElements}
        unwrapDisallowed
      >
        {(sanitizedContent || '').replace(/\n/gi, '  \n')}
      </ReactMarkdown>
    </Wrapper>
  );
};

ParsedContent.propTypes = {
  content: PropTypes.string.isRequired,
  emojis: PropTypes.bool,
  mentions: PropTypes.bool,
  communities: PropTypes.bool,
  channels: PropTypes.bool,
  hashtags: PropTypes.bool,
  markdown: PropTypes.bool,
  emojiSize: PropTypes.number,
  disallowed: PropTypes.arrayOf(PropTypes.oneOf(types)),
  emojiOnlySize: PropTypes.number,
};

ParsedContent.defaultProps = {
  emojis: true,
  mentions: true,
  communities: true,
  channels: true,
  hashtags: true,
  markdown: true,
  emojiSize: 20,
  disallowed: [],
  emojiOnlySize: null,
};

export default ParsedContent;
