import { memo, useCallback, useEffect } from 'react';
import shortid from 'shortid';
import { useLocation } from 'react-router-dom';
import {
  useDispatch, useSelector, shallowEqual, batch,
} from 'react-redux';

import { useTranslation } from 'hooks';

import * as appSelectors from 'state/app/selectors';
import * as appActions from 'state/app/actions';

import ErrorModal from 'components/Modal/Error';
import ToastContainer from 'components/Toast/Container';
import Toast from 'components/Toast';
import MiniProfile from 'components/MiniProfile';

const GlobalInformation = memo(() => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const errors = useSelector(appSelectors.selectErrors, shallowEqual);
  const toasts = useSelector(appSelectors.selectToasts, shallowEqual);
  const miniProfile = useSelector(appSelectors.selectMiniprofile, shallowEqual);

  const onErrorModalClose = useCallback(() => dispatch(appActions.clearErrors()), [dispatch]);
  const hideMiniProfile = useCallback(() => dispatch(appActions.setMiniprofile(null)), [dispatch]);

  useEffect(() => {
    batch(() => {
      dispatch(appActions.newLocation(location));
      dispatch(appActions.uiLeftColumn(false));
      dispatch(appActions.uiRightColumn(false));
    });
  }, [dispatch, location]);

  return (
    <>
      {/* Mini Profile */}
      <MiniProfile user={miniProfile} onClose={hideMiniProfile} isOpen={!!miniProfile} />


      {/* Modals */}
      {errors.length > 0 && (
        <ErrorModal
          title={t('global:There was a problem')}
          onClose={onErrorModalClose}
        >
          {errors}
        </ErrorModal>
      )}

      {/* Toasts */}
      <ToastContainer>
        {toasts.map((content, index) => (
          <Toast index={index} key={`toast-${shortid.generate()}`}>{content}</Toast>
        ))}
      </ToastContainer>
    </>
  );
});

export default GlobalInformation;
