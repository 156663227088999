import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { HStack, Text } from '@chakra-ui/react';

import * as channelSelectors from 'state/channels/selectors';

import { useTranslation } from 'hooks';

import { Account } from 'components/Icons';

import locales from '../i18n';

const colors = ['gray.100', 'gray.400'];

const Members = ({ channelId }) => {
  const { t } = useTranslation(locales);
  const membersCount = useSelector(state => channelSelectors.membersCount(state, channelId));

  return (
    <HStack title={t('Members')} spacing={1}>
      <Account fill={colors} boxSize={5} />
      <Text fontSize="xs" color={colors}>{membersCount}</Text>
    </HStack>
  );
};

Members.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default Members;
