import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { Frame } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';

import locales from '../../../i18n';
import EmbedModal from '../../Modals/EmbedModal';

interface Props {
}

const EmbedFrameAction: React.FC<Props> = () => {
  const { t } = useTranslation(locales);
  const { chatId: channelId } = useParams<{ chatId: string }>();

  const { isOpen: isEmbedModalOpen, onOpen: openEmbedModal, onClose: closeEmbedModal } = useDisclosure();

  const isOwner = useSelector((state) => (
    // @ts-ignore
    channelSelectors.isOwnerOfChannel(state, channelId)
  ));

  if (!isOwner) return null;

  return (
    <>
      <MenuItem icon={<Frame size={16} />} onClick={openEmbedModal}>{t('Embed frame')}</MenuItem>

      {isEmbedModalOpen && (
        <EmbedModal channelId={channelId} close={closeEmbedModal} />
      )}
    </>
  );
};

export default EmbedFrameAction;
