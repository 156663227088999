import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as alertsSelectors from 'state/alerts/selectors';
import * as messengerSelectors from 'state/messengers/selectors';

import { DEFAULT_TITLE, POST_TITLE } from '../constants';

const useTitle = (title?: string) => {
  const unreadAlerts = useSelector(alertsSelectors.selectTotalUnread);
  const unreadMessengers = useSelector(messengerSelectors.totalUnread);

  const unread = unreadMessengers + unreadAlerts;

  useEffect(() => {
    let unreadNumber = '';
    if (unread > 0) unreadNumber = `(${unread}) `;
    if (unread > 99) unreadNumber = '(99+) ';

    if (title) {
      document.title = `${unreadNumber}${title}${POST_TITLE}`;
    } else {
      document.title = `${unreadNumber}${DEFAULT_TITLE}`;
    }
  }, [title, unread]);
};

export default useTitle;
