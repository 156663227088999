import { memo } from 'react';
import { IconType, IconDefaults } from './type';

const SpankOutline = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M2.81176 5.29097C2.72825 5.62186 2.8218 5.94386 3.07952 6.20157C3.55964 6.67825 4.32502 7.06424 5.07663 7.20916C5.76998 7.3429 6.88807 7.42383 8.27822 7.04558C8.53909 6.9747 8.80742 7.01803 9.00084 7.15808C8.96296 7.19711 8.91877 7.24073 8.87744 7.28206C8.78848 7.37102 8.68258 7.47635 8.56979 7.59659L2.21742 13.949C1.58433 14.5821 1.58433 15.6117 2.21742 16.2448C2.8505 16.8779 3.8802 16.8779 4.51328 16.2448L8.96153 11.7966C9.04045 11.7177 9.16959 11.7177 9.24851 11.7966C9.32743 11.8755 9.32743 12.0047 9.24851 12.0836L2.79138 18.5407C2.1583 19.1738 2.1583 20.2035 2.79138 20.8366C3.42447 21.4697 4.45417 21.4697 5.08725 20.8366L11.5444 14.3794C11.6233 14.3005 11.7524 14.3005 11.8314 14.3794C11.9103 14.4584 11.9103 14.5875 11.8314 14.6664L6.66566 19.8321C6.11178 20.386 6.11178 21.2871 6.66566 21.841C7.21954 22.3949 8.12066 22.3949 8.67454 21.841L14.4142 16.1013C14.4931 16.0224 14.6223 16.0224 14.7012 16.1013C14.7801 16.1803 14.7801 16.3094 14.7012 16.3883L11.1139 19.9756C10.56 20.5295 10.56 21.4306 11.1168 21.9874C11.6706 22.5412 12.5718 22.5412 13.1257 21.9874L20.9302 14.1817C24.6653 9.89102 22.745 6.88832 20.3737 4.51697C18.409 2.55229 15.6698 1.76222 13.2419 2.44983C12.9492 2.51871 12.1706 2.69176 11.2473 2.89695C8.93971 3.40922 7.00315 3.84199 6.68144 3.94157C5.96485 4.16284 5.322 4.25295 4.82265 4.20244C4.37065 4.15681 3.95941 4.26386 3.60814 4.36774C3.42619 4.42227 2.93574 4.80137 2.81176 5.29097Z" stroke="#ED4D3D" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="transparent" />
      </clipPath>
    </defs>
  </svg>
);

const SpankFilled = ({ color }) => (
  <svg viewBox="0 0 16 16">
    <path
      d="M1.8745 3.52721C1.81882 3.7478 1.88119 3.96247 2.053 4.13428C2.37308 4.45206 2.88334 4.70939 3.38441 4.80601C3.84665 4.89516 4.59204 4.94912 5.5188 4.69695C5.69271 4.6497 5.8716 4.67859 6.00055 4.77195C5.9753 4.79797 5.94583 4.82705 5.91828 4.8546C5.85897 4.91391 5.78837 4.98413 5.71318 5.06429L1.47827 9.29921C1.05621 9.72127 1.05621 10.4077 1.47827 10.8298C1.90032 11.2518 2.58679 11.2518 3.00885 10.8298L5.97434 7.86429C6.02695 7.81168 6.11305 7.81168 6.16566 7.86429C6.21828 7.91691 6.21828 8.003 6.16566 8.05562L1.86091 12.3604C1.43886 12.7824 1.43886 13.4689 1.86091 13.8909C2.28297 14.313 2.96943 14.313 3.39149 13.8909L7.69624 9.58619C7.74885 9.53358 7.83495 9.53358 7.88756 9.58619C7.94018 9.63881 7.94018 9.7249 7.88756 9.77752L4.44376 13.2213C4.07451 13.5906 4.07451 14.1913 4.44376 14.5606C4.81301 14.9298 5.41377 14.9298 5.78302 14.5606L9.60946 10.7341C9.66208 10.6815 9.74817 10.6815 9.80078 10.7341C9.8534 10.7867 9.8534 10.8728 9.80078 10.9254L7.40926 13.317C7.04 13.6862 7.04 14.287 7.41117 14.6581C7.78042 15.0274 8.38117 15.0274 8.75043 14.6581L13.9534 9.45437C16.4435 6.59392 15.1634 4.59211 13.5825 3.01122C12.2727 1.70142 10.4465 1.17471 8.82791 1.63312C8.63276 1.67904 8.1137 1.79441 7.49822 1.9312C5.9598 2.27271 4.66876 2.56122 4.45428 2.62761C3.97655 2.77512 3.54799 2.8352 3.21509 2.80153C2.91376 2.77111 2.63959 2.84247 2.40541 2.91173C2.28412 2.94808 1.95715 3.20081 1.8745 3.52721Z"
      fill={color}
    />
  </svg>
);

const Spank = memo(({ outline, color }) => {
  if (outline) return <SpankOutline color={color} />;
  return <SpankFilled color={color} />;
});

Spank.propTypes = IconType;
Spank.defaultProps = IconDefaults;
SpankFilled.propTypes = IconType;
SpankFilled.defaultProps = IconDefaults;

export default Spank;
