import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTitle, useTranslation } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';
import * as membershipSelectors from 'state/memberships/selectors';
import * as communityActions from 'state/communities/actions';

import Info from 'containers/Communities/Info';
import NotFound from 'containers/NotFound';
import NotAuthorized from 'containers/Communities/NotAuthorized';
import Layout from 'components/Layout';
import Loading from 'components/Loading';

import locales from './i18n';
import Content from './Content';

const Mods = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  const params = useParams();

  const communityId = useSelector(
    state => communitySelectors.selectIdBySlug(state, params.communitySlug),
  );
  const isFetching = useSelector(
    state => communitySelectors.selectIsFetching(state, params.communitySlug),
  );
  const isAdmin = useSelector(state => membershipSelectors.userIsAdminOf(state, communityId));

  useEffect(() => {
    dispatch(communityActions.fetch(params.communitySlug));
  }, [dispatch, params.communitySlug]);

  useTitle(t('Mods'));

  if (!communityId && !isFetching) return <NotFound />;
  if (isFetching) return <Loading />;
  if (!isAdmin) return <NotAuthorized communityId={communityId} />;

  return (
    <Layout columns={2}>
      <Info communitySlug={params.communitySlug} />
      <Content communityId={communityId} />
    </Layout>
  );
};

Mods.propTypes = {
};

Mods.defaultProps = {
};

export default Mods;
