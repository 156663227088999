import { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as authActions from 'state/auth/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';

import locales from '../i18n';

const OnboardingModal = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const onboarding = useSelector(authSelectors.getOnboardingChatRequest);

  const dismiss = useCallback(() => {
    dispatch(authActions.dismissOnboardingChatRequest());
  }, [dispatch]);

  if (!onboarding) return null;

  return (
    <Modal
      title={t('How to send a good chat request')}
      actions={[
        <Button key="chat-request-onboarding" onClick={dismiss}>{t('Understood!')}</Button>,
      ]}
    >
      <Trans t={t} i18nKey="onboarding.chatRequest" ns="chatDashboard">
        <p>
          You should think deeply about what to say if you want the other one accepts your request.
        </p>

        <p>
          Some advice:
        </p>

        <ul>
          <li>
            Check for the pronoun the other person choose to be addressed as. Show consideration!
          </li>
          <li>
            {'Talk to the person, no to the role, bacause you don\'t have consent to do so. Avoid dirty talking. Show respect!'}
          </li>
          <li>
            If you are looking for someone compatible in role and/or sexual orientation,
            {' '}
            be sure the person is a match to that. Show interest!
          </li>
          <li>
            Remember you have only one chat request shot per user.
            {' '}
            If it gets rejected, you can no longer contact that person this way. Be smart!
          </li>
        </ul>
      </Trans>
    </Modal>
  );
};

OnboardingModal.propTypes = {
};

OnboardingModal.defaultProps = {
};

export default OnboardingModal;
