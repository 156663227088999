import { shallowEqual, useSelector } from 'react-redux';

import * as feedSelectors from 'state/feed/selectors';
import * as userSelectors from 'state/users/selectors';
import * as authSelectors from 'state/auth/selectors';

import SpankListModal from 'components/SpankListModal';
import { Button, Flex, Skeleton } from '@chakra-ui/react';

import { useOpenClose, useTranslation } from 'hooks';

import locales from './i18n';

interface Props {
  publicationId: string;
}

const Likers: React.FC<Props> = ({ publicationId }) => {
  const { t } = useTranslation(locales);

  const [showingSpanksModal, showSpanksModal, hideSpanksModal] = useOpenClose();

  const likers: number[] = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectReactionsList(state, publicationId),
    shallowEqual,
  );

  const followingUsers = useSelector(authSelectors.selectFollowingUsers, shallowEqual) || [];

  const sortedLikersByFollowing = likers.sort((likerA, likerB) => {
    if (followingUsers.includes(likerA)) {
      if (followingUsers.includes(likerB)) {
        return 0;
      }
      return -1;
    }

    if (followingUsers.includes(likerB)) {
      return 1;
    }

    return 0;
  });


  const [firstUser, secondUser] = useSelector(
    userSelectors.getListByIds([sortedLikersByFollowing[0], sortedLikersByFollowing[1]]),
  );

  (firstUser || {}).isLoaded = useSelector(userSelectors.isLoaded(likers[0]));
  (secondUser || {}).isLoaded = useSelector(userSelectors.isLoaded(likers[1]));

  if (!likers || !likers.length) return null;

  let content = null;

  if (likers.length === 1) {
    content = (
      <>
        {firstUser.isLoaded ? firstUser.displayname : <Skeleton w="100px" h="12px" mr={2} />}
        {' '}
        {t('spanked this', { count: likers.length })}
      </>
    );
  } else if (likers.length === 2) {
    content = (
      <>
        {firstUser.isLoaded ? firstUser.displayname : <Skeleton w="100px" h="12px" mr={2} />}
        {' '}
        {t('and')}
        {' '}
        {secondUser && secondUser.isLoaded ? secondUser.displayname : <Skeleton w="100px" h="12px" mr={2} />}
        {' '}
        {t('spanked this', { count: likers.length })}
      </>
    );
  } else if (likers.length > 2) {
    content = (
      <>
        {firstUser.isLoaded ? firstUser.displayname : <Skeleton w="100px" h="12px" mr={2} />}
        {' '}
        {t('more spanked this', { countLeft: likers.length - 1 })}
      </>
    );
  }


  return content && (
    <>
      <Flex justifyContent="flex-end">
        <Button onClick={showSpanksModal} variant="link" fontWeight="normal" fontSize="xs" color="gray.500">
          {content}
        </Button>
      </Flex>

      {showingSpanksModal && (
        // @ts-ignore
        <SpankListModal type="publication" entityId={publicationId} close={hideSpanksModal} />
      )}
    </>
  );
};

export default Likers;
