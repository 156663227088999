import {
  IconButton, Menu, MenuButton, MenuList, Icon,
} from '@chakra-ui/react';
import { EllipsisVertical } from 'lucide-react';

import MenuItemFollowPublication from './MenuItemFollowPublication';
import MenuItemAllowComments from './MenuItemAllowComments';
import MenuItemShowVotes from './MenuItemShowVotes';
import MenuItemEditPublication from './MenuItemEditPublication';
import MenuItemRemovePublication from './MenuItemRemovePublication';
import MenuItemReportPublication from './MenuItemReportPublication';
import MenuItemPromoted from './MenuItemPromoted';
import MenuItemSpanks from './MenuItemSpanks';

interface Props {
  publicationId: string;
  onRemove?: (publicationId: string) => void;
}

const Actions: React.FC<Props> = ({ publicationId, onRemove }) => (
  <Menu>
    <MenuButton
      variant="ghost"
      as={IconButton}
      size="xs"
      icon={<Icon as={EllipsisVertical} boxSize={4} />}
    />
    <MenuList>
      <MenuItemSpanks publicationId={publicationId} />
      <MenuItemFollowPublication publicationId={publicationId} />
      <MenuItemAllowComments publicationId={publicationId} />
      <MenuItemShowVotes publicationId={publicationId} />
      <MenuItemReportPublication publicationId={publicationId} />
      <MenuItemPromoted publicationId={publicationId} />
      <MenuItemEditPublication publicationId={publicationId} />
      <MenuItemRemovePublication publicationId={publicationId} onRemove={onRemove} />
    </MenuList>
  </Menu>
);

export default Actions;
