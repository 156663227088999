import CodeMirror from 'codemirror';

const { keyMap } = CodeMirror;

keyMap.macMazmo = {
  'Cmd-Shift-K': 'deleteLine',
  'Cmd-D': false,
  'Cmd-]': false,
  'Cmd-[': false,
  fallthrough: 'macDefault',
};
CodeMirror.normalizeKeyMap(keyMap.macMazmo);

keyMap.pcMazmo = {
  'Ctrl-Shift-K': 'deleteLine',
  'Ctrl-D': false,
  'Ctrl-]': false,
  'Ctrl-[': false,
  fallthrough: 'pcDefault',
};
CodeMirror.normalizeKeyMap(keyMap.pcMazmo);

const mac = keyMap.default === keyMap.macDefault;
keyMap.mazmo = mac ? keyMap.macMazmo : keyMap.pcMazmo;

export default keyMap;
