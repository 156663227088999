import { chakra } from '@chakra-ui/react';
import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const outlinePath = 'M21 11C21 16.55 17.16 21.74 12 23C6.84 21.74 3 16.55 3 11V5L12 1L21 5V11M12 21C15.75 20 19 15.54 19 11.22V6.3L12 3.18L5 6.3V11.22C5 15.54 8.25 20 12 21M15.05 16L11.97 14.15L8.9 16L9.71 12.5L7 10.16L10.58 9.85L11.97 6.55L13.37 9.84L16.95 10.15L14.23 12.5L15.05 16';
const normalPath = 'M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1M15.08 16L12 14.15L8.93 16L9.74 12.5L7.03 10.16L10.61 9.85L12 6.55L13.39 9.84L16.97 10.15L14.26 12.5L15.08 16Z';

const ShieldStar = memo(({ outline, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path d={outline ? outlinePath : normalPath} />
  </svg>
));

ShieldStar.propTypes = IconType;
ShieldStar.defaultProps = IconDefaults;

export default chakra(ShieldStar, { shouldForwardProp: (prop) => ['outline'].includes(prop) });
