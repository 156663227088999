import { useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import * as appActions from 'state/app/actions';

import { CloseThick } from 'components/Icons';

const CloseMenuWrapper = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  height: 16px;
  width: 16px;
  padding: 8px;
  border: 0;
  display: flex;
  position: absolute;
  cursor: pointer;
  right: 4px;
  top: 4px;
  z-index: 20;
  background: rgba(0, 0, 0, .1);
  border-radius: 100%;
  visibility: hidden;

  @media(max-width: 1279px) {
    visibility: visible;
  }
`;

const CloseColumn = () => {
  const dispatch = useDispatch();

  const hideLeftColumn = useCallback(() => {
    dispatch(appActions.uiLeftColumn(false));
  }, [dispatch]);

  return (
    <CloseMenuWrapper onClick={hideLeftColumn}>
      <CloseThick color="black" />
    </CloseMenuWrapper>
  );
};

CloseColumn.propTypes = {
};

CloseColumn.defaultProps = {
};

export default CloseColumn;
