import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation, useOpenClose } from 'hooks';
import * as threadSelectors from 'state/threads/selectors';
import * as replySelectors from 'state/replies/selectors';

import ListModal from './ListModal';
import locales from '../i18n';
import * as images from './img';

const AwardList = styled.div`
  display: flex;

  .awards {
    display: flex;

    > div {
      margin-right: 8px;
      display: flex;
      align-items: center;
      background-color: ${props => props.theme.colors.mainLight};
      border-radius: 8px;
      padding: 2px 4px;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        border-radius: 100%;
      }

      span {
        font-size: 14px;
        color: ${props => props.theme.colors.secondary};
      }
    }
  }

  button {
    border: 1px dashed ${props => props.theme.colors.secondary};
    border-radius: 4px;
    background-color: #EFEFEF;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;

      path {
        fill: ${props => props.theme.colors.secondary};
      }
    }

    &:hover {
      color: ${props => props.theme.colors.main};
      border-color: ${props => props.theme.colors.main};

      svg path {
        fill: ${props => props.theme.colors.main};
      }
    }
  }
`;
AwardList.displayName = 'AwardList';

const Awards = ({ type, entityId }) => {
  const { t } = useTranslation(locales);

  const selector = type === 'thread' ? threadSelectors : replySelectors;
  const awards = useSelector(state => selector.getAwards(state, entityId), shallowEqual);

  const [isListModalOpen, openListModal, closeListModal] = useOpenClose();

  const awardTypes = {};
  awards.forEach(({ label }) => {
    if (typeof awardTypes[label] === 'undefined') awardTypes[label] = 0;
    awardTypes[label] += 1;
  });

  return (
    <>
      <AwardList>
        <div className="awards">
          {Object.keys(awardTypes).map(label => (
            <div
              key={`${type}-award-${entityId}-${label}`}
              onClick={openListModal}
              role="button"
              tabIndex={0}
              onKeyDown={openListModal}
            >
              <img src={images[label]} alt={t(`AWARD.${label}`)} title={t(`AWARD.${label}`)} />
              <span>{awardTypes[label]}</span>
            </div>
          ))}
        </div>
      </AwardList>

      {isListModalOpen && <ListModal entityId={entityId} type={type} close={closeListModal} />}
    </>
  );
};

Awards.propTypes = {
  entityId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['thread', 'reply']).isRequired,
};

Awards.defaultProps = {
};

export default Awards;
