import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';

import AuthLayout from 'components/AuthLayout';

import locales from './i18n';

const StyledLink = styled(Link)`
  color: ${colors.red};
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;


const PasswordRecovered = () => {
  const { t } = useTranslation(locales);

  return (
    <AuthLayout
      title={t('We sent you an e-mail')}
      subtitle={t('If your information was correct, we sent you a message with instructions to restore your password. Check you e-mail and follow the instructions.')}
    >
      <StyledLink to="/">{t('Back to mazmo.net')}</StyledLink>
    </AuthLayout>
  );
};

export default PasswordRecovered;
