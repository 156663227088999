import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@chakra-ui/react';

import { useTranslation } from 'hooks';

import locales from '../i18n';

const Wrapper = styled.div`
  border: 3px solid ${props => (props.highlighted ? props.theme.colors.main : '#EEE')};
  border-radius: 8px;
  height: ${props => (props.highlighted ? 300 : 250)}px;
  display: flex;
  flex-direction: column;

  .header {
    background: ${props => (props.highlighted ? props.theme.colors.main : '#EEE')};
    width: 150px;
    height: 100px;
    color: ${props => (!props.highlighted ? props.theme.colors.main : 'white')};
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    strike {
      font-size: 16px;
    }

    @media(max-width: 767px) {
      width: max-content;
      font-size: 16px;
      padding: 0 8px;
      text-align: center;
    }
  }

  .price {
    text-align: center;
    padding: 16px 0;
    flex: 1;

    @media(max-width: 767px) {
      font-size: 14px;
    }
  }

  .action {
    padding: 16px 0 8px;
    text-align: center;

    @media(max-width: 767px) {
      button {
        font-size: 12px;
        padding: 4px 8px;
      }
    }
  }
`;
Wrapper.displayName = 'Wrapper';

const toCurrency = amount => amount.toFixed(2).replace('.00', '');

const PurchaseColumn = ({
  coupon, price, sades, highlighted, currency, onBuy,
}) => {
  const { t } = useTranslation(locales);

  const increment = (coupon && coupon.increment) || 0;
  const discount = (coupon && coupon.discount) || 0;

  return (
    <Wrapper highlighted={highlighted}>
      <div className="header">
        {!!increment && <strike>{t('{{amount}} Sades', { amount: sades })}</strike>}
        {t('{{amount}} Sades', { amount: sades * (1 + increment / 100) })}
      </div>
      <div className="price">
        {t('Price')}
        {!!discount && (
          <>
            <br />
            <strike>{t(`{{price}} ${currency}`, { price })}</strike>
          </>
        )}
        <br />
        <strong>{t(`{{price}} ${currency}`, { price: toCurrency(price * (1 - (discount) / 100)) })}</strong>
      </div>
      <div className="action">
        <Button variant="primary" onClick={onBuy}>{t('global:Buy §{{sades}}', { sades })}</Button>
      </div>
    </Wrapper>
  );
};

PurchaseColumn.propTypes = {
  coupon: PropTypes.shape({
    increment: PropTypes.number,
    discount: PropTypes.number,
  }),
  price: PropTypes.number.isRequired,
  sades: PropTypes.number.isRequired,
  highlighted: PropTypes.bool,
  currency: PropTypes.oneOf(['pesos', 'usd']).isRequired,
  onBuy: PropTypes.func.isRequired,
};

PurchaseColumn.defaultProps = {
  coupon: null,
  highlighted: false,
};

export default PurchaseColumn;
