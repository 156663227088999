import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as appSelectors from 'state/app/selectors';
import * as channelSelectors from 'state/channels/selectors';

import Avatar from 'components/ChannelAvatar';
import { SelectableListItem } from 'components/SelectableList';
import { Pencil, Lock } from 'components/Icons';

import UnreadIndicator from './UnreadIndicator';
import LastMessage from './LastMessage';
import Typing from './Typing';
import { CHANNEL_PRIVACIES } from '../../../../constants';

const Channel = ({ id }) => {
  const history = useHistory();

  const url = `/chat/channels/${id}`;

  const match = useRouteMatch(url);
  const isActive = !!match;

  const hasContentToSubmit = useSelector(state => appSelectors.selectIsComposerReadyToSubmit(state, `channel-${id}`));

  const name = useSelector(state => channelSelectors.getName(state, id));
  const avatar = useSelector(state => channelSelectors.getAvatar(state, id));
  const privacy = useSelector(state => channelSelectors.getPrivacy(state, id));

  const onSelect = useCallback(() => {
    history.push(url);
  }, [history, url]);

  const renderAvatar = useCallback(() => <Avatar image={avatar} />, [avatar]);

  const renderPrebadges = useCallback(() => (
    <>
      {hasContentToSubmit && !isActive && <Pencil color="#AAA" />}
      {privacy === CHANNEL_PRIVACIES.PRIVATE && <Lock color="#AAA" />}
    </>
  ), [hasContentToSubmit, isActive, privacy]);

  const renderContent = useCallback(() => (
    <>
      <LastMessage id={id} />
      <Typing id={id} />
      <UnreadIndicator id={id} />
    </>
  ), [id]);

  return (
    <SelectableListItem
      renderAvatar={renderAvatar}
      title={name}
      onClick={onSelect}
      active={isActive}
      renderPrebadges={renderPrebadges}
      renderContent={renderContent}
    />
  );
};

Channel.propTypes = {
  id: PropTypes.string.isRequired,
};

Channel.defaultProps = {
};

export default memo(Channel);
