import { useState, useCallback, ChangeEvent } from 'react';

function useInputValue(initialValue: string) {
  const [value, setValue] = useState(initialValue);
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);
  const change = useCallback((val: string) => {
    setValue(val);
  }, []);

  return {
    value,
    onChange,
    change,
  };
}

export default useInputValue;
