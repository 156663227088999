import styled from 'styled-components';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  width?: number;
  height?: number;
  topDivider?: boolean;
  ref?: React.RefObject<HTMLDivElement>;
}

const BoxWrapper = styled.div<Props>`
  margin-bottom: 32px;
  background: white;
  padding: 16px 16px 24px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  max-width: 100vw;
  box-sizing: border-box;

  ${props => props.topDivider && `
    padding-top: 36px;
  `}

  .ad-content {
    width: ${props => (props.width ? `${props.width}px` : '300px')};
    height: ${props => (props.height ? `${props.height}px` : '250px')};
    margin: 0 auto;
    max-width: 100%;

    .amzn-native-header {
      display: none;
    }
  }

  iframe {
    max-width: 100%;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;
BoxWrapper.displayName = 'BoxWrapper';

export default BoxWrapper;
