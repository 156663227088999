import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import UserOnlineIndicator from 'components/UserOnlineIndicator';
import LovenseToy from 'components/LovenseToy';

import Busy from './Busy';

const DisplayName = styled.span.attrs({
  className: 'displayname',
})`
  position: relative;
`;
DisplayName.displayName = 'DisplayName';

const NameContainer = styled.span`
  display: inline-flex;
  align-items: center;

  *:not(:last-child) {
    margin-right: 8px;
  }
`;
NameContainer.displayName = 'NameContainer';

const UserDisplayName = ({ userId, onlineIndicator }) => {
  const dispatch = useDispatch();

  const displayname = useSelector(userSelectors.getDisplayName(userId));
  const isLoaded = useSelector(userSelectors.isLoaded(userId));
  const lovenseToys = useSelector(state => userSelectors.lovenseToys(state, userId));

  useEffect(() => {
    if (!isLoaded && userId) {
      dispatch(userActions.fetchData(userId));
    }
  }, [isLoaded, dispatch, userId]);

  if (!isLoaded) return <Busy className="displayname busy" />;

  return (
    <DisplayName>
      {onlineIndicator && <UserOnlineIndicator userId={userId} />}
      <NameContainer>
        <span>{displayname}</span>
        {lovenseToys.length > 0 && (
          <span>
            {lovenseToys.map(toy => <LovenseToy name={toy} key={`lovense-toy-${toy}`} />)}
          </span>
        )}
      </NameContainer>
    </DisplayName>
  );
};

UserDisplayName.propTypes = {
  userId: PropTypes.number.isRequired,
  onlineIndicator: PropTypes.bool,
};

UserDisplayName.defaultProps = {
  onlineIndicator: false,
};

export default UserDisplayName;
