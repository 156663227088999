import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';
import * as channelActions from 'state/channels/actions';

import BubbleReactions from '../Reactions';

const Reactions = ({ messageId }) => {
  const dispatch = useDispatch();

  const reactions = useSelector(
    state => channelSelectors.getMessageReactions(state, messageId),
    shallowEqual,
  );

  const add = useCallback((name) => {
    dispatch(channelActions.addReaction(messageId, name));
  }, [messageId, dispatch]);

  const remove = useCallback((name) => {
    dispatch(channelActions.removeReaction(messageId, name));
  }, [messageId, dispatch]);

  return (
    <BubbleReactions
      messageId={messageId}
      reactions={reactions}
      add={add}
      remove={remove}
    />
  );
};

Reactions.propTypes = {
  messageId: PropTypes.string.isRequired,
};

Reactions.defaultProps = {
};

export default Reactions;
