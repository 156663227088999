import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const Chats = memo(({ outline, color }) => {
  if (outline) {
    return (
      <svg viewBox="0 0 24 24">
        <path fill={color} d="M20,2A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H6L2,22V4C2,2.89 2.9,2 4,2H20M4,4V17.17L5.17,16H20V4H4M6,7H18V9H6V7M6,11H15V13H6V11Z" />
      </svg>
    );
  }

  return (
    <svg viewBox="0 0 24 24">
      <path fill={color} d="M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M6,9H18V11H6M14,14H6V12H14M18,8H6V6H18" />
    </svg>
  );
});

Chats.propTypes = IconType;
Chats.defaultProps = IconDefaults;

export default Chats;
