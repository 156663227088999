import { IconType, IconDefaults } from './type';

const UnfoldLessHorizontal = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M16.59,5.41L15.17,4L12,7.17L8.83,4L7.41,5.41L12,10M7.41,18.59L8.83,20L12,16.83L15.17,20L16.58,18.59L12,14L7.41,18.59Z"
      fill={color}
    />
  </svg>
);

UnfoldLessHorizontal.propTypes = IconType;
UnfoldLessHorizontal.defaultProps = IconDefaults;

export default UnfoldLessHorizontal;
