export const ADD = 'mazmo/users/ADD';

export const ADD_FAILED_USERNAME = 'mazmo/users/ADD_FAILED_USERNAME';

export const ONLINE_LIST = 'mazmo/users/ONLINE_LIST';
export const ONLINE = 'mazmo/users/ONLINE';
export const OFFLINE = 'mazmo/users/OFFLINE';

export const SUGGESTIONS_LOAD = 'mazmo/users/SUGGESTIONS_LOAD';
export const SUGGESTIONS_CLEAR = 'mazmo/users/SUGGESTIONS_CLEAR';
