import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import * as appActions from 'state/app/actions';

import { useOnMount } from 'hooks';

const SECONDS_TO_DISMISS = 6;

const ToastWrapper = styled.div`
  background: black;
  color: white;
  padding: 12px 25px;
  width: auto;
  pointer-events: visible;
  align-self: center;
  cursor: pointer;
  margin-bottom: 10px;
  z-index: 200;
`;

const Toast = ({ index, children }) => {
  const dispatch = useDispatch();
  const clear = () => dispatch(appActions.removeToast(index));

  useOnMount(() => {
    const timer = setTimeout(clear, SECONDS_TO_DISMISS * 1000);
    return () => clearTimeout(timer);
  });

  return (
    <ToastWrapper onClick={clear}>
      {children}
    </ToastWrapper>
  );
};

Toast.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default Toast;
