import { useSelector } from 'react-redux';
import {
  Icon, MenuDivider, MenuItem, useDisclosure,
} from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';
import * as authSelectors from 'state/auth/selectors';

import { Flag } from 'lucide-react';
import ReportModal from '../Modals/Report';
import locales from '../i18n';

interface Props {
  publicationId: string;
}

const MenuItemReportPublication: React.FC<Props> = ({ publicationId }) => {
  const { t } = useTranslation(locales);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const userIsAuthor = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.userIsAuthor(state, publicationId),
  );

  const isPromoted = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectIsPromoted(state, publicationId),
  );

  const canReportPublications = useSelector(authSelectors.canReportPublications);

  if (!canReportPublications || userIsAuthor || isPromoted) return null;

  return (
    <>
      <MenuDivider />
      <MenuItem
        icon={<Icon as={Flag} />}
        onClick={onOpen}
        color="red.600"
      >
        {t('Report')}
      </MenuItem>

      {isOpen && (
        <ReportModal publicationId={publicationId} close={onClose} />
      )}
    </>
  );
};

export default MenuItemReportPublication;
