import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { MoreActions } from 'components/Icons';
import Menu, { Item as MenuItem } from 'components/Menu';

const Wrapper = styled.div`
  margin-left: auto;
  position: relative;
`;

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  size: number;
}

const Button = styled.button<ButtonProps>`
  width: ${props => props.size * 8}px;
  height: ${props => props.size * 8}px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active, &:focus {
    outline: none;
  }

  svg {
    width: ${props => props.size}px;
  }
`;

interface ContextMenuItem {
  key: string;
  onClick?: () => void;
  component: React.ReactNode;
  danger?: boolean;
}

interface ContextMenuProps {
  items: ContextMenuItem[];
  size: number;
  color?: string;
}

const ContextMenu = ({ items, size, color }: ContextMenuProps) => {
  const [showingContextMenu, setShowingContextMenu] = useState(false);
  const show = (e: React.MouseEvent, setFunction: (param: boolean) => void) => {
    e.preventDefault();
    e.stopPropagation();
    setFunction(true);
  };
  const showContextMenu = (e: React.MouseEvent) => show(e, setShowingContextMenu);
  const hideContextMenu = () => setShowingContextMenu(false);

  return (
    <Wrapper>
      <Button onClick={showContextMenu} className="nouserlink" size={size}><MoreActions fill={color} /></Button>
      <Menu open={showingContextMenu} onClose={hideContextMenu} className="nouserlink" size={size}>
        {items.map(({
          key, onClick, component, danger,
        }) => (
          <MenuItem key={`contextmenu-${key}`} onClick={onClick} danger={danger}>
            {component}
          </MenuItem>
        ))}
      </Menu>
    </Wrapper>
  );
};

ContextMenu.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    component: PropTypes.node.isRequired,
    danger: PropTypes.bool,
  })).isRequired,
};

ContextMenu.defaultProps = {
  size: 4,
  color: '#999',
};

export default ContextMenu;
