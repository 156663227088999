import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';
import * as communityActions from 'state/communities/actions';
import * as appActions from 'state/app/actions';

import CommunityHeader from 'containers/Communities/Header';
import CommunityForm from 'components/CommunityForm';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import {
  FlexWrapper,
  FlexInnerWrapper,
  FlexContainer,
  ActionsFooter,
} from 'components/FlexWrapper';

import DeleteModal from './DeleteModal';
import locales from './i18n';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 48px);
  overflow: hidden;
`;
ContentWrapper.displayName = 'ContentWrapper';

const ContainerWrapper = styled.div`
  flex: 1;
  height: 100%;
  overflow: scroll;
`;
ContainerWrapper.displayName = 'ContainerWrapper';

const Container = styled.div`
  margin: 24px;
`;
Container.displayName = 'Container';

const ButtonWrapper = styled.div`
  flex-shrink: 0;
`;
ButtonWrapper.displayName = 'ButtonWrapper';

const DeleteWrapper = styled.div`
  margin: 64px 0;
`;
DeleteWrapper.displayName = 'DeleteWrapper';

const Content = ({ communityId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  const history = useHistory();

  const name = useSelector(state => communitySelectors.selectName(state, communityId));
  const slug = useSelector(state => communitySelectors.selectSlugById(state, communityId));
  const privacy = useSelector(state => communitySelectors.selectPrivacy(state, communityId));
  const description = useSelector(
    state => communitySelectors.selectDescription(state, communityId),
  );
  const hasEvents = useSelector(state => communitySelectors.hasEvents(state, communityId));

  const [data, setData] = useState(null);
  const [saving, setSaving] = useState(false);

  const [showingDeleteModal, setShowingDeleteModal] = useState(false);
  const openDeleteModal = useCallback(() => { setShowingDeleteModal(true); }, []);
  const closeDeleteModal = useCallback(() => { setShowingDeleteModal(false); }, []);

  const onSubmitClick = useCallback(async () => {
    try {
      setSaving(true);
      const payload = await dispatch(communityActions.edit(communityId, data));
      history.replace(`/+${payload.slug}`);
    } catch (e) {
      setSaving(false);
      dispatch(appActions.addError(e));
    }
  }, [communityId, data, dispatch, history]);

  return (
    <FlexWrapper fullHeight mobileHasHeader>
      <FlexInnerWrapper>
        <CommunityHeader communityId={communityId} />
        <PageTitle>{t('Edit information')}</PageTitle>

        <FlexContainer framed>
          <CommunityForm
            onDataChange={setData}
            initialData={{
              name,
              slug,
              description,
              privacy,
              hasEvents,
            }}
            initialAutoSlug={false}
          />

          <DeleteWrapper>
            <Button className="empty danger" onClick={openDeleteModal}>{t('Delete community')}</Button>
          </DeleteWrapper>
        </FlexContainer>

        <ActionsFooter>
          <div>
            <Button className="empty" to={`/@${slug}`}>{t('global:Cancel')}</Button>
          </div>
          <div>
            <Button onClick={onSubmitClick} loading={saving}>{t('global:Confirm')}</Button>
          </div>
        </ActionsFooter>
      </FlexInnerWrapper>

      {showingDeleteModal && (
        <DeleteModal
          communityId={communityId}
          close={closeDeleteModal}
        />
      )}
    </FlexWrapper>
  );
};

Content.propTypes = {
  communityId: PropTypes.string.isRequired,
};

Content.defaultProps = {
};

export default Content;
