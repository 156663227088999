import styled from 'styled-components';

const Title = styled.h1`
  color: white;
  font-style: normal;
  font-weight: 800;
  font-size: 62px;
  line-height: 62px;
  margin: 0 0 50px 0;

  @media(max-width: 767px) {
    font-size: 40px;
    line-height: 40px;
    margin: 0 0 30px 0;
  }
`;

export default Title;
