import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';
import * as replySelectors from 'state/replies/selectors';
import * as replyActions from 'state/replies/actions';

import { ChevronUp } from 'components/Icons';

import { REPLIES_LIST_LIMIT } from '../../../constants';
import locales from './i18n';

const LoadMoreWrapper = styled.div`
  text-align: center;
  color: ${colors.grey};
  cursor: pointer;
  padding: 0 24px 24px;

  svg {
    width: 64px;
    height: 64px;
  }

  > div:nth-child(2) {
    margin-top: -8px;
  }
`;

const LoadMore = ({ threadId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const loadBeforeDate = useSelector(replySelectors.getFirstLoadedReplyDate(threadId));
  const isFullyLoaded = useSelector(replySelectors.isFullyLoaded(threadId));
  const isLoading = useSelector(replySelectors.isLoading(threadId));
  const notLoadedCount = useSelector(replySelectors.getNotLoadedCount(threadId));

  if (isLoading || !loadBeforeDate || isFullyLoaded || !notLoadedCount) return null;

  const onClick = () => {
    dispatch(replyActions.load(threadId, loadBeforeDate));
  };

  const loadBatchCount = Math.min(REPLIES_LIST_LIMIT, notLoadedCount);

  return (
    <LoadMoreWrapper onClick={onClick}>
      <div>
        <ChevronUp fill={colors.grey} />
      </div>
      <div>
        {t('Load previous {{count}} replies', { count: loadBatchCount, context: loadBatchCount > 1 && 'PLURAL' })}
      </div>
    </LoadMoreWrapper>
  );
};

LoadMore.propTypes = {
  threadId: PropTypes.string.isRequired,
};

export default LoadMore;
