import { MenuDivider, MenuItem, useDisclosure } from '@chakra-ui/react';
import { MessageSquare } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';

import locales from '../../../i18n';
import AutoRespondersModal from '../../Modals/AutorespondersModal';

const AutorespondersAction: React.FC = () => {
  const { t } = useTranslation(locales);
  const { chatId: channelId } = useParams<{ chatId: string }>();

  const { isOpen: isAutorespondersModalOpen, onOpen: openAutorespondersModal, onClose: closeAutorespondersModal } = useDisclosure();

  const isMod = useSelector((state) => (
    // @ts-ignore
    channelSelectors.isModOfChannel(state, channelId)
  ));

  if (!isMod) return null;

  return (
    <>
      <MenuItem icon={<MessageSquare size={16} />} onClick={openAutorespondersModal}>{t('Autoresponders')}</MenuItem>
      <MenuDivider />

      {isAutorespondersModalOpen && (
        <AutoRespondersModal channelId={channelId} close={closeAutorespondersModal} />
      )}
    </>
  );
};

export default AutorespondersAction;
