import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import {
  Flex,
  HStack, Text, useTheme,
} from '@chakra-ui/react';
import { Cake, MapPin } from 'lucide-react';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';
import country2flag from 'utils/country2flag';

import { GenderTransgender } from 'components/Icons';

import locales from '../i18n';

const GenderAgeLocation = ({ userId }) => {
  const { t } = useTranslation(locales);
  const theme = useTheme();

  const isOrganization = useSelector(userSelectors.isOrganization(userId));
  const gender = useSelector(userSelectors.getGender(userId));
  const age = useSelector(userSelectors.getAge(userId));
  const user = useSelector(userSelectors.getById(userId), shallowEqual);

  if (isOrganization) return null;
  const location = [];
  if (user.city?.name) location.push(user.city.name);
  if (user.region?.name) location.push(user.region.name);
  if (user.country?.name) location.push(`${user.country.name} ${country2flag(user.country.isoCode)}`.trim());

  return (
    <Flex justifyContent="center" textAlign="left" flexDirection={{ base: 'column', md: 'row' }}>
      <HStack justifyContent="center" spacing={4} mb={{ base: 2, md: 0 }} flexShrink={0}>
        {gender && (
          <HStack justifyContent="center" spacing={1} flexShrink={0}>
            <GenderTransgender fill="gray.400" boxSize={4} mr={2} />
            <Text noOfLines={1} flexShrink={0}>{t(`global:GENDER.${gender}`)}</Text>
          </HStack>
        )}
        <HStack justifyContent="center" spacing={1} flexShrink={0}>
          <Cake color={theme.colors.gray['400']} size={20} />
          <Text noOfLines={1} flexShrink={0}>{t('{{age}} years old', { age })}</Text>
        </HStack>
      </HStack>
      {location.length > 0 && (
        <HStack justifyContent="center" spacing={1} ml={{ md: 4 }} flexShrink={1} minWidth={0}>
          <MapPin color={theme.colors.gray['400']} size={20} flexShrink={0} />
          <Text
            noOfLines={1}
            flexShrink={1}
            minWidth={0}
            title={location.join(', ')}
          >
            {location.join(', ')}
          </Text>
        </HStack>
      )}
    </Flex>
  );
};

GenderAgeLocation.propTypes = {
  userId: PropTypes.number.isRequired,
};

GenderAgeLocation.defaultProps = {
};

export default GenderAgeLocation;
