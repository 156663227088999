import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as messengerSelectors from 'state/messengers/selectors';

import Time from './Time';
import Reactions from './Reactions';
import ChatState from './ChatState';
import InfoComponent from '../Info';

const Info = ({ messageId }) => {
  const hasReactions = useSelector(
    state => messengerSelectors.messageHasReactions(state, messageId),
  );
  const isOutgoing = useSelector(state => messengerSelectors.isMessageOutgoing(state, messageId));

  return (
    <InfoComponent hasReactions={hasReactions}>
      <Reactions messageId={messageId} />
      <div>
        <Time messageId={messageId} />
        {isOutgoing && <ChatState messageId={messageId} />}
      </div>
    </InfoComponent>
  );
};

Info.propTypes = {
  messageId: PropTypes.string.isRequired,
};

Info.defaultProps = {
};

export default Info;
