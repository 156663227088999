import { useSelector, shallowEqual } from 'react-redux';

import * as messengerSelectors from 'state/messengers/selectors';
import * as authSelectors from 'state/auth/selectors';

import Bubble from '../Bubble';
import LinkPublication from '../Bubble/Messenger/LinkPublication';

interface Props {
  messengerId: string;
}

const RequestMessage: React.FC<Props> = ({ messengerId }) => {
  // @ts-ignore
  const message = useSelector(state => messengerSelectors.getRequestMessage(state, messengerId));
  // @ts-ignore
  const publication = useSelector(state => messengerSelectors.getRequestPublication(state, messengerId));

  const requester = useSelector(
    // @ts-ignore
    state => messengerSelectors.getRequester(state, messengerId),
    shallowEqual,
  );
  const meId = useSelector(authSelectors.selectUserId);

  if (!requester) return null;

  const outgoing = requester.id === meId;

  return (
    <Bubble bordered author={requester} outgoing={outgoing}>
      <LinkPublication publication={publication} />
      {message}
    </Bubble>
  );
};

export default RequestMessage;
