import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as messengerSelectors from 'state/messengers/selectors';

import TypingWrapper from '../../Content/Typing';
import locales from '../../i18n';

const Typing = ({ messengerId }) => {
  const { t } = useTranslation(locales);

  const isTyping = useSelector(state => messengerSelectors.getIsTyping(state, messengerId));

  if (!isTyping) return null;

  return <TypingWrapper>{t('typing')}</TypingWrapper>;
};

Typing.propTypes = {
  messengerId: PropTypes.string.isRequired,
};

Typing.defaultProps = {
};

export default Typing;
