import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { Box, HStack, Text } from '@chakra-ui/react';

import * as feedSelectors from 'state/feed/selectors';

import Avatar from './Avatar';
import AuthorDisplayName from './AuthorDisplayName';
import PrivacyIcon from './PrivacyIcon';
import Actions from './Actions';
import PromotedText from './PromotedText';

interface Props {
  publicationId: string;
  onRemove?: (publicationId: string) => void;
}

const Header: React.FC<Props> = ({ publicationId, onRemove }) => {
  const createdAt = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectCreatedAt(state, publicationId),
    shallowEqual,
  );

  return (
    <HStack p={3} borderBottom="1px solid" borderColor="gray.100">
      <HStack flex={1} spacing={3}>
        <Avatar publicationId={publicationId} />
        <Box flex={1} color="brand.500" fontWeight={500}>
          <AuthorDisplayName publicationId={publicationId} />

          <Link to={`/publications/${publicationId}`}>
            <HStack spacing={0.5} fontSize="xs" color="gray.500" fontWeight="normal">
              <Text noOfLines={1}>{moment(createdAt).fromNow()}</Text>
              <Text>・</Text>
              <PrivacyIcon publicationId={publicationId} />
              <PromotedText publicationId={publicationId} />
            </HStack>
          </Link>
        </Box>
      </HStack>

      <Actions publicationId={publicationId} onRemove={onRemove} />
    </HStack>
  );
};

export default Header;
