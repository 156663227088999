import { Box } from '@chakra-ui/react';

interface Props extends React.PropsWithChildren {
  dark?: boolean;
}

const UserTag: React.FC<Props> = ({ dark, children }) => {
  const backgroundColor = dark ? 'rgba(0, 0, 0, 0.15)' : 'rgba(255, 255, 255, 0.15)';
  const borderColor = dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)';

  return (
    <Box
      background={backgroundColor}
      borderRadius={16}
      padding="6px 12px"
      margin="4px"
      border={`1px solid ${borderColor}`}
    >
      {children}
    </Box>
  );
};

export default UserTag;
