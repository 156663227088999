import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import Toggle from 'components/Toggle';

import locales from '../../i18n';

const MuteToggles = ({ feed, toggleFeed }) => {
  const { t } = useTranslation(locales);

  return (
    <>
      <Toggle label={t('global:Home')} active={feed} position="left" onChange={toggleFeed} />
      <br />
      <Toggle label={`${t('global:Communities')} (${t('global:Soon')})`} active={false} disabled position="left" />
      <br />
      <Toggle label={`${t('global:Chat')} (${t('global:Soon')})`} active={false} disabled position="left" />
    </>
  );
};

MuteToggles.propTypes = {
  feed: PropTypes.bool.isRequired,
  toggleFeed: PropTypes.func.isRequired,
};

MuteToggles.defaultProps = {
};

export default MuteToggles;
