import { schema } from 'normalizr';

import user from 'state/users/schema';

const auth = new schema.Entity('auth');
auth.define({
});

export const relationships = new schema.Entity('relationships', {
  user,
  relatesTo: user,
});

export default auth;
