import { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import ToggleButtonGroup from 'components/ToggleButtonGroup';

import Threads from './Threads';
import Replies from './Replies';
import locales from '../i18n';

const CommunitiesWrapper = styled.div`
  ${props => props.theme.css.content}
`;
CommunitiesWrapper.displayName = 'CommunitiesWrapper';

const Communities = () => {
  const { t } = useTranslation(locales);
  const params = useParams();

  const userId = useSelector(userSelectors.getByUsername(params.username));

  const [selected, setSelected] = useState('threads');

  return (
    <CommunitiesWrapper>
      <ToggleButtonGroup
        buttons={[
          { key: 'threads', label: t('global:Threads') },
          { key: 'replies', label: t('global:Replies') },
        ]}
        selected={selected}
        onChange={setSelected}
      />

      {selected === 'threads'
        ? <Threads userId={userId} />
        : <Replies userId={userId} />
      }
    </CommunitiesWrapper>
  );
};

Communities.propTypes = {
};

Communities.defaultProps = {
};

export default Communities;
