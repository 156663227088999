import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Emoji, emojiIndex } from 'emoji-mart';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import {
  Box, HStack, Icon, Text,
} from '@chakra-ui/react';

import { useOpenClose, useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';

import isMobile from 'utils/isMobile';

import Modal from 'components/Modal';
import EmojiPicker from 'components/EmojiPicker';
import { ReactionAdd } from 'components/Icons';
import UserDisplayName from 'components/UserDisplayName';
import customemojis from 'components/Composer/customemojis';

import locales from './i18n';

const customEmojis = customemojis();

const Wrapper = styled.div`
  position: relative;
`;
Wrapper.displayName = 'Wrapper';

const PickerWrapper = styled.div`
  ${props => props.forMobile && `
    display: flex;
    align-items: center;
    flex-direction: column;
  `}

  ${props => !props.forMobile && `
    visibility: visible;
    position: fixed;
    z-index: 20;
  `}
`;
PickerWrapper.displayName = 'PickerWrapper';

const Reactions = ({
  messageId, reactions, add, remove,
}) => {
  const { t } = useTranslation(locales);

  const userId = useSelector(authSelectors.selectUserId);

  const picker = useRef(null);
  const selector = useRef(null);
  const [opened,, close, toggle] = useOpenClose(false);

  const [pickerOffset, setPickerOffset] = useState({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      try {
        if (!picker.current.contains(event.target) && !selector.current.contains(event.target)) {
          close();
        }
      } catch (error) {
        //
      }
    };

    if (opened) {
      document.addEventListener('mousedown', handleClickOutside, false);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [opened, close]);

  const addReaction = (emoji) => {
    add(emoji.id);
    close();
  };

  const toggleEmojiPicker = (e) => {
    setPickerOffset({ left: e.clientX, top: e.clientY });
    toggle(e);
  };

  return (
    <Wrapper>
      {opened && (isMobile
        ? (
          <Modal onClose={close}>
            <PickerWrapper ref={picker} forMobile>
              <EmojiPicker onSelect={addReaction} autoPositioning={false} />
            </PickerWrapper>
          </Modal>
        )
        : (
          <PickerWrapper style={pickerOffset} ref={picker}>
            <EmojiPicker onSelect={addReaction} onMouseLeave={close} />
          </PickerWrapper>
        ))
      }

      {reactions.map((reaction) => {
        if (!reaction.authorIds.length) return null;
        const onAdd = () => { add(reaction.name); };
        const onRemove = () => { remove(reaction.name); };
        const onClick = reaction.authorIds.includes(userId) ? onRemove : onAdd;
        const active = reaction.authorIds.includes(userId);
        const className = active ? 'active' : '';

        const custom = emojiIndex
          .search(reaction.name, { custom: customEmojis })
          .find(e => e.short_names.includes(reaction.name));

        const emoji = custom || reaction.name;
        const tooltipId = `reaction-tooltip-${messageId}-${reaction.name}`;

        return (
          <span key={`reaction-${messageId}-${reaction.name}`}>
            <ReactTooltip effect="solid" id={tooltipId}>
              {reaction.authorIds.map(authorId => (
                <div key={`reaction-user-${messageId}-${reaction.name}-${authorId}`}>
                  <UserDisplayName userId={authorId} />
                </div>
              ))}
            </ReactTooltip>
            <button
              className={className}
              onClick={onClick}
              type="button"
              ref={selector}
              data-tip
              data-for={tooltipId}
            >
              <HStack spacing={0.5}>
                <Box height="16px">
                  <Icon as={Emoji} emoji={emoji} size={16} set="apple" m={0} />
                </Box>
                <Text color={active ? 'brand.500' : 'regular'}>{reaction.authorIds.length}</Text>
              </HStack>
            </button>
          </span>
        );
      })}

      {reactions.length > 0 && (
        <>
          <button onClick={toggleEmojiPicker} type="button" ref={selector} data-tip data-for={`add-reaction-${messageId}`}>
            <ReactionAdd color="#AAA" />
          </button>
          <ReactTooltip effect="solid" id={`add-reaction-${messageId}`}>{t('Add reaction')}</ReactTooltip>
        </>
      )}
    </Wrapper>
  );
};

Reactions.propTypes = {
  messageId: PropTypes.string.isRequired,
  reactions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    authorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  })),
  add: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};

Reactions.defaultProps = {
  reactions: [],
};

export default Reactions;
