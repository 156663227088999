import {
  memo, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';
import * as alertSelectors from 'state/alerts/selectors';
import * as alertsActions from 'state/alerts/actions';
import { alertListEqual } from 'state/alerts/equalityFunctions';

import { useTranslation } from 'hooks';

import Loading from 'components/Loading';
import Menu from 'components/Menu';
import EmptyState from 'components/EmptyState';

import Alert from './Alert';
import Icon from './Icon';
import MobileAskNotifications from './MobileAskNotifications';
import NavItem from '../Nav/Item';
import locales from './i18n';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .nav-dropdown > div {
    @media(max-width: 767px) {
      max-height: none;
    }
  }
`;

const AlertsContent = ({ alerts }) => {
  const { t } = useTranslation(locales);

  const isFetching = useSelector(alertSelectors.isFetching);
  const hasFailed = useSelector(alertSelectors.hasFailed);

  if (isFetching) return <Loading />;
  if (hasFailed) return <EmptyState title="">{t('Error fetching alerts')}</EmptyState>;

  if (alerts.length === 0) {
    return <EmptyState title="">{t('You don\'t have any alerts yet')}</EmptyState>;
  }

  return (
    <>
      {alerts.map(alert => (
        <Alert key={alert.id} data={alert} />
      ))}
    </>
  );
};

AlertsContent.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
};

const Alerts = memo(() => {
  const dispatch = useDispatch();

  const loggedIn = useSelector(authSelectors.loggedIn);
  const alertList = useSelector(alertSelectors.getAll, alertListEqual);
  const unread = useSelector(alertSelectors.selectTotalUnread);

  const [alertListCopy, setAlertListCopy] = useState(alertList);
  const [unreadCopy, setUnreadCopy] = useState(unread);
  const [alertsMenuOpened, setAlertsMenuOpened] = useState(false);

  useEffect(() => {
    const closeNotifications = async () => {
      if ('serviceWorker' in navigator) {
        try {
          const registration = await navigator.serviceWorker.ready;
          const notifications = await registration.getNotifications();
          notifications.forEach((notification) => {
            if (notification.tag === 'alert') notification.close();
          });
        } catch (err) {
          // Do nothing
        }
      }
    };

    try {
      if (alertsMenuOpened && unread > 0) {
        closeNotifications();
      }
    } catch (e) {
      //
    }
  }, [alertsMenuOpened, unread]);

  useEffect(() => {
    if (!alertsMenuOpened) {
      setAlertListCopy(alertList);
      setUnreadCopy(unread);
    }
  }, [alertList, unread, alertsMenuOpened]);

  const openAlertsMenu = useCallback(() => {
    setAlertsMenuOpened(true);
    dispatch(alertsActions.markAsRead());
  }, [dispatch]);

  const closeAlertsMenu = useCallback(() => {
    setAlertListCopy(alertList);
    setUnreadCopy(unread);
    setAlertsMenuOpened(false);
  }, [alertList, unread]);

  if (!loggedIn) return null;

  return (
    <NavItem onClick={openAlertsMenu} pressed={alertsMenuOpened}>
      <Wrapper>
        <Icon unread={unreadCopy} />
        <Menu
          width="400px"
          open={alertsMenuOpened}
          onClose={closeAlertsMenu}
          className="nav-dropdown"
          maxHeight="470px"
        >
          <MobileAskNotifications />
          <AlertsContent alerts={alertListCopy} />
        </Menu>
      </Wrapper>
    </NavItem>
  );
});

export default Alerts;
