import styled from 'styled-components';

import { useTranslation } from 'hooks';

import Warning from 'components/Warning';
import Button from 'components/Button';

import locales from '../i18n';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;
Wrapper.displayName = 'Wrapper';

const Presentation = () => {
  const { t } = useTranslation(locales);

  return (
    <Warning fullMargin>
      <Wrapper>
        <strong>{t('You have unpaid invoices')}</strong>
        <Button to="/user/invoices">{t('Review & Pay')}</Button>
      </Wrapper>
    </Warning>
  );
};

Presentation.propTypes = {
};

Presentation.defaultProps = {
};

export default Presentation;
