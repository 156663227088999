import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'utils/css/colors';

import { Comment } from 'components/Icons';

const Button = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  width: 100%;
  border: 0;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;
  color: red;
  font-size: 12px;
  line-height: 21px;
  background-color: white;

  &:hover {
    background-color: #e0e0e0;
  }

  > div:first-child {
    margin-right: 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const ShowMoreButton = ({ onClick, children }) => (
  <Button onClick={onClick}>
    <div>
      <Comment color={colors.red} />
    </div>
    <div>
      {children}
    </div>
  </Button>
);

ShowMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

ShowMoreButton.defaultProps = {
};

export default ShowMoreButton;
