import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const Menu = memo(({ color }) => (
  <svg viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" fill={color} />
  </svg>
));

Menu.propTypes = IconType;
Menu.defaultProps = IconDefaults;

export default Menu;
