import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

import isEmojiOnly from 'utils/isEmojiOnly';
import keySelectorCombiner from 'utils/keySelectorCombiner';
import * as userSelectors from 'state/users/selectors';
import * as authSelectors from 'state/auth/selectors';

// Input selectors
const selectMessengers = state => state.messengers.messengers;

export const selectMessengersData = state => state.messengers.data.messengers;

const selectDirectMessages = state => state.messengers.directmessages;

const selectDirectMessagesData = state => (
  state.messengers.data ? state.messengers.data.directmessages : {}
);

const selectFullyLoaded = state => state.messengers.fullyLoaded;

const selectTyping = state => state.messengers.typing;

const selectReplyTo = state => state.messengers.replyTo;

const selectPublicationId = state => state.messengers.publicationId;

const selectEditing = state => state.messengers.editing;

export const isInitialized = state => state.messengers.initialized;

export const hasFailed = state => !!state.messengers.fetchError;
//

const selectMessageById = createCachedSelector(
  selectDirectMessagesData,
  (_, messageId) => messageId,
  (directmessages, messageId) => directmessages[messageId],
)(
  (_, messageId) => messageId || 'null',
);

export const getAll = createSelector(
  selectMessengers,
  selectMessengersData,
  authSelectors.selectId,
  (_, messengerId) => messengerId,
  (messengers, data, meId, messengerId) => messengers
    .map(m => data[m])
    .filter((m) => {
      if (m.id === messengerId || !!m.lastMessage) return true;

      const hasRequestMessage = m.participants.some(p => !!p.request?.message);
      const meParticipant = m.participants.find(p => p.userId === meId);
      if (!meParticipant) return false;
      const approvedByUser = meParticipant.approved;

      return hasRequestMessage && approvedByUser;
    }),
);


export const selectRequested = createSelector(
  selectMessengers,
  selectMessengersData,
  authSelectors.selectId,
  (messengers, data, meId) => messengers.map(m => data[m])
    .filter((m) => {
      const hasRequestMessage = m.participants.some(p => !!p.request?.message);
      const user = m.participants.find(p => p.userId === meId);
      if (!user) return false;

      return hasRequestMessage && !user.approved;
    }),
);

export const selectById = createSelector(
  selectMessengersData,
  (_, messengerId) => messengerId,
  (data, messengerId) => data[messengerId],
);

export const selectByUserId = createSelector(
  selectMessengers,
  selectMessengersData,
  (_, userId) => userId,
  (messengers, data, userId) => {
    const matches = messengers.filter((messengerId) => {
      const messenger = data[messengerId];
      const participantIds = messenger.participants.map(p => p.userId);
      return participantIds.length === 2 && participantIds.includes(userId);
    });

    return matches.length > 0 && matches[0];
  },
);

// TODO: Better memoization
export const selectMessagesByMessengerId = createSelector(
  selectDirectMessages,
  selectDirectMessagesData,
  (_, messengerId) => messengerId,
  (directmessages, data, messengerId) => (
    (directmessages[messengerId] || []).map(id => data[id])
  ),
);

export const selectLastMessageByMessengerId = createSelector(
  selectMessagesByMessengerId,
  messages => messages[messages.length - 1],
);

export const selectLastOutgoingMessageIdByMessengerId = createSelector(
  selectMessagesByMessengerId,
  authSelectors.selectId,
  (messages, myId) => {
    let offset = 1;
    let found = false;

    while (!found && offset <= messages.length) {
      const message = messages[messages.length - offset];
      if (message.authorId === myId) {
        found = true;
      } else {
        offset += 1;
      }
    }

    if (found) return messages[messages.length - offset].id;

    return null;
  },
);

export const isFullyLoaded = createSelector(
  selectFullyLoaded,
  (_, messengerId) => messengerId,
  (fullyLoaded, messengerId) => fullyLoaded.includes(messengerId),
);

export const totalUnread = createSelector(
  selectMessengers,
  selectMessengersData,
  (messengers, data) => messengers.filter(mId => data[mId].unreadCount > 0).length,
);

export const getIsTyping = createSelector(
  selectTyping,
  (_, messengerId) => messengerId,
  (typing, messengerId) => (typing[messengerId] || []).length > 0,
);

export const isMessageOutgoing = createSelector(
  selectMessageById,
  authSelectors.selectMe,
  (message, me) => {
    if (!message || !message.authorId) return true;
    return message.authorId === me.id;
  },
);

export const messageIsEdited = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return false;
    return message.edited;
  },
);

export const isMessageEmojiOnly = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return false;
    return isEmojiOnly(message);
  },
);

export const getMessageReactions = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return null;
    return message.reactions;
  },
);

export const getMessageAuthorId = createSelector(
  selectMessageById,
  authSelectors.selectMe,
  (message, me) => {
    if (!message) return me.id;
    return message.authorId || me.id;
  },
);


export const getMessageAuthorType = () => 'USER';

const selectMessengerByMessageId = createCachedSelector(
  selectMessengersData,
  selectMessageById,
  (messengers, message) => {
    if (!message) return null;

    const messengerId = typeof message.messenger === 'object' ? message.messenger.id : message.messenger;
    return messengers[messengerId];
  },
)(
  (_, messageId) => messageId || 'null',
);

export const getMessageCreatedAt = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return null;
    return message.createdAt;
  },
);

export const selectMessageState = createCachedSelector(
  selectMessageById,
  selectMessengerByMessageId,
  authSelectors.selectId,
  (message, messenger, meId) => {
    if (!message) return null;

    const outgoing = message.authorId === meId;

    if (!messenger) return 'SENDING';
    const otherParticipants = messenger.participants.filter(p => p.userId !== meId);

    const timeIsGreater = (a, b) => (new Date(a)).getTime() >= (new Date(b).getTime());

    let messageState = 'SENT';
    if (outgoing) {
      if (!message.id) messageState = 'SENDING';
      else if (otherParticipants.every(p => timeIsGreater(p.readAt, message.createdAt))) {
        messageState = 'READ';
      }

      if (messageState === 'SENT' && otherParticipants.every(p => timeIsGreater(p.receivedAt, message.createdAt))) {
        messageState = 'RECEIVED';
      }
    } else {
      messageState = null;
    }

    return messageState;
  },
)({
  keySelectorCreator: keySelectorCombiner,
});

export const getMessageGif = createSelector(
  selectMessageById,
  (message) => {
    if (!message || !message.media) return null;
    return message.media.gif;
  },
);

export const getMessageMedia = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return null;
    return message.media;
  },
);

export const messageHasAudio = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return false;
    return !!message.audio;
  },
);

export const messagePublication = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return null;
    return message.publication;
  },
);

export const getMessageAudioFile = createSelector(
  selectMessageById,
  (message) => {
    if (!message || !message.audio || message.audio.loading) return null;
    return message.audio.file;
  },
);

export const getReplyingTo = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return null;
    return message.replyingTo;
  },
);

export const getMessageContentForEditor = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return null;
    return message.editorContent;
  },
);

export const getMessageContent = createSelector(
  selectMessageById,
  (message) => {
    if (!message || !message.rawContent) return '';
    return message.rawContent;
  },
);

export const messageHasReactions = createSelector(
  getMessageReactions,
  reactions => (reactions || []).filter(r => r.authorIds.length > 0).length > 0,
);

export const messengerCount = state => state.messengers.length;

export const allLastMessages = createSelector(
  selectMessengers,
  selectDirectMessages,
  selectDirectMessagesData,
  (messengers, directmessages, dmdata) => {
    const lastMessagesDates = {};
    messengers.forEach((id) => {
      const messages = directmessages[id] || [];
      lastMessagesDates[id] = dmdata[messages[messages.length - 1]];
    });

    return lastMessagesDates;
  },
);

export const notRejectedRequestsCount = createSelector(
  selectRequested,
  requested => requested.filter(m => !m.rejected && !m.blocked).length,
);

const getStatusData = createSelector(
  selectById,
  authSelectors.selectId,
  (messenger, meId) => {
    const { participants } = messenger;

    const approvedByMe = participants.find(p => p.userId === meId)?.approved;
    const approvedByOthers = participants.filter(p => p.userId !== meId).every(p => p.approved);
    const requestMessage = participants.map(p => p.request?.message).find(Boolean);

    return {
      approvedByMe,
      approvedByOthers,
      requestMessage,
    };
  },
);

export const needsRequest = createSelector(
  getStatusData,
  ({ approvedByMe, approvedByOthers, requestMessage }) => (
    (!approvedByMe && !approvedByOthers)
    || (approvedByMe && !approvedByOthers && !requestMessage)
  ),
);

export const isWaitingForApproval = createSelector(
  getStatusData,
  ({ approvedByMe, approvedByOthers, requestMessage }) => (
    approvedByMe && !approvedByOthers && requestMessage
  ),
);

export const needsResolveRequest = createSelector(
  getStatusData,
  ({ approvedByMe, approvedByOthers, requestMessage }) => (
    !approvedByMe && approvedByOthers && requestMessage
  ),
);

export const getUserId = createSelector(
  selectById,
  authSelectors.selectId,
  (messenger, meId) => {
    const { userId } = messenger?.participants.find((p) => p?.userId !== meId);
    return userId;
  },
);

export const isArchived = createSelector(
  selectById,
  messenger => messenger.archived,
);

export const isBlocked = createSelector(
  selectById,
  messenger => messenger.blocked,
);

export const isRejected = createSelector(
  selectById,
  messenger => messenger.rejected,
);

export const rejectedReason = createSelector(
  selectById,
  messenger => messenger?.rejectedReason,
);

export const getUnreadCount = createSelector(
  selectById,
  messenger => messenger.unreadCount,
);

export const getRequestMessage = createSelector(
  selectById,
  messenger => messenger.participants.map(p => p.request?.message).find(Boolean),
);

export const getRequestPublication = createSelector(
  selectById,
  messenger => messenger.participants.map(p => p.request?.publication).find(Boolean),
);

export const getRequester = createSelector(
  selectById,
  userSelectors.selectData,
  (messenger, users) => {
    const { participants } = messenger;
    const request = participants.find(p => !!p.request?.message);
    if (!request) return null;

    return users[request.userId];
  },
);

export const getSortedArchived = createCachedSelector(
  getAll,
  allLastMessages,
  userSelectors.selectData,
  authSelectors.selectId,
  (messengers, lastMessages, users, meId) => {
    const myMessengers = messengers.filter(data => data.archived).map(data => ({ type: 'messenger', data }));

    const getEntityName = (entity) => {
      const { userId } = entity.data.participants.find(p => p.userId !== meId);
      const user = users[userId];
      return user.displayname;
    };

    const sortedEntities = myMessengers
      .sort((a, b) => {
        const lastMessageA = lastMessages[a.data.id];
        const lastMessageB = lastMessages[b.data.id];

        if (!lastMessageA && !lastMessageB) return 0;
        if (lastMessageA && !lastMessageB) return -1;
        if (!lastMessageA && lastMessageB) return 1;

        const createdAtA = (
          lastMessageA.createdAt
          || (lastMessageA.payload && lastMessageA.payload.createdAt)
        );
        const createdAtB = (
          lastMessageB.createdAt
          || (lastMessageB.payload && lastMessageB.payload.createdAt)
        );
        if (createdAtA > createdAtB) return -1;

        return 1;
      })
      .map(entity => ({ type: entity.type, id: entity.data.id, name: getEntityName(entity) }));

    return sortedEntities;
  },
)(
  () => 'sortedarchived',
);

export const getReplyTo = createSelector(
  selectReplyTo,
  (_, messageId) => messageId,
  (replyTo, messageId) => replyTo[messageId],
);

export const getPublicationId = createSelector(
  selectPublicationId,
  (_, messengerId) => messengerId,
  (publicationId, messengerId) => publicationId[messengerId],
);

export const isEditing = createSelector(
  selectEditing,
  (_, messengerId) => messengerId,
  (editing, messengerId) => !!editing[messengerId],
);

export const getEditingMessageId = createSelector(
  selectEditing,
  (_, messengerId) => messengerId,
  (editing, messengerId) => editing[messengerId],
);

export const messageIsBeingRemoved = createSelector(
  selectMessageById,
  message => message && message.removing,
);
