import styled from 'styled-components';

import colors from 'utils/css/colors';

import ActionIcon from 'components/ActionIcon';
import Button from 'components/Button';

export const Container = styled.div`
  background-color: white;
  margin: 16px 16px 32px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 1px #eee;

  @media(max-width: 768px) {
    margin: 16px 0;
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
`;

export const AvatarWrapper = styled.div`
  margin-right: 16px;
`;

export const UserInfo = styled.div`
  a.userlink {
    font-weight: 500;
    color: ${colors.red};
    display: block;
  }

  a.time {
    color: ${colors.redReactions};
    font-size: 12px;
    display: block;
    margin-right: 8px;

    svg {
      width: 12px;
      height: 12px;
      opacity: 0.5;
    }
  }
`;

export const ActionWrapper = styled.div`
  display: flex;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  ${ActionIcon} {
    line-height: 24px;
    font-size: 18px;
  }
`;

export const CommentContainer = styled.div`
  background-color: #f9f9f9;
  border-top: 1px solid #eee;
  font-size: 12px;
`;

export const Content = styled.div`
  padding: 16px;
  font-size: 18px;
  word-break: break-word;
  box-sizing: border-box;

  ${props => props.cap && `
    max-height: 250px;
    overflow: hidden;
    position: relative;
  `}

  div {
    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ContentShadow = styled.div`
  height: 150px;
  background: linear-gradient(0deg,rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 25%,rgba(255,255,255,0) 100%);
  position: absolute;
  width: 100%;
  top: 100px;
  pointer-events: none;
`;
ContentShadow.displayName = 'ContentShadow';

export const ShowAll = styled(Button).attrs({
  color: 'white',
  fontColor: 'black',
})`
  position: absolute;
  left: calc(50% - 100px);
  width: 200px;
  text-align: center;
  top: 200px;

  > div > div {
    width: 100%;
  }
`;
