import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as feedSelectors from 'state/feed/selectors';

import UserDisplayName from 'components/UserDisplayName';
import UserLink from 'components/UserLink';

const AuthorDisplayName = ({ publicationId }) => {
  const authorId = useSelector(
    state => feedSelectors.publications.selectAuthorId(state, publicationId),
  );

  return (
    <UserLink userId={authorId}>
      <UserDisplayName userId={authorId} />
    </UserLink>
  );
};

AuthorDisplayName.propTypes = {
  publicationId: PropTypes.string.isRequired,
};

AuthorDisplayName.defaultProps = {
};

export default AuthorDisplayName;
