export const messages = (objA, objB) => {
  if (objA.length !== objB.length) return false;

  let same = true;
  objA.forEach((mA, index) => {
    const mB = objB[index];
    if (mA.id !== mB.id || mA.updatedAt !== mB.updatedAt) same = false;
  });

  return same;
};

export const entityEqual = (listA, listB) => {
  if (listA.length !== listB.length) return false;

  let same = true;
  listA.forEach((mA, index) => {
    const mB = listB[index];
    if (mA.id !== mB.id || mA.type !== mB.type) same = false;
  });

  return same;
};
