/* eslint-disable no-unreachable */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';

import { useTranslation, useOpenClose, useCoupon } from 'hooks';

import Warning from 'components/Warning';

import mercadopago from './img/mercadopago.png';
import paypal from './img/paypal.png';
import Mercadopago from './Mercadopago';
import PayPal from './PayPal';
import SelectCoupon from './SelectCoupon';
import Processors from './UI/Processors';
import Title from './UI/Title';
import SuccessAnimation from './UI/SuccessAnimation';
import Coupon from './UI/Coupon';
import locales from './i18n';

const PurchaseSadesModal = ({
  insufficient,
  onClose,
  onCancel,
  ...props
}) => {
  const { t } = useTranslation(locales);
  const history = useHistory();
  const location = useLocation();
  const queryparams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const coupon = useCoupon();
  const [isSuccessModalOpen, openSuccessModal] = useOpenClose();

  const selectProcessor = useCallback(newProcessor => () => {
    const newQP = { ...queryparams, processor: newProcessor };
    history.push({
      pathname: '/user/sades/buy',
      search: qs.stringify(newQP),
    });
  }, [history, queryparams]);

  const clearCoupon = useCallback(() => {
    const { coupon: cp, ...newQP } = queryparams;
    history.push({
      pathname: '/user/sades/buy',
      search: qs.stringify(newQP),
    });
  }, [history, queryparams]);

  const done = useCallback((showSuccess) => {
    if (!showSuccess) {
      if (onCancel) onCancel();
      else if (onClose) onClose();
    } else {
      openSuccessModal();
    }
  }, [onCancel, onClose, openSuccessModal]);

  if (isSuccessModalOpen) {
    return (
      <Modal zIndex={1500} {...props} onClose={onCancel || onClose}>
        <SuccessAnimation />
      </Modal>
    );
  }

  const processor = queryparams.processor || false;

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Purchase Sades')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {insufficient && (
          <Warning>{t('You have insufficient Sades. Do you want to purchase more and be able to perform this action?')}</Warning>
          )}

          {processor && coupon && <Coupon onClear={clearCoupon}>{coupon.code}</Coupon>}
          {processor && !coupon && <SelectCoupon />}

          {processor === 'mercadopago' && <Mercadopago done={done} coupon={coupon} />}
          {processor === 'paypal' && <PayPal done={done} coupon={coupon} />}

          {!processor && (
            <div>
              <Title>{t('Payment options')}</Title>

              <Processors>
                <Flex
                  onClick={selectProcessor('mercadopago')}
                  role="button"
                  tabIndex={-1}
                  onKeyDown={selectProcessor('mercadopago')}
                  justifyContent="space-around"
                >
                  <img src={mercadopago} alt="Mercado Pago" />
                  <div className="flag">{t('Only argentine peso')}</div>
                </Flex>

                <Flex
                  onClick={selectProcessor('paypal')}
                  role="button"
                  tabIndex={-1}
                  onKeyDown={selectProcessor('paypal')}
                  justifyContent="space-around"
                >
                  <img src={paypal} alt="PayPal" />
                </Flex>
              </Processors>
            </div>
          )}
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

PurchaseSadesModal.propTypes = {
  insufficient: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};

PurchaseSadesModal.defaultProps = {
  insufficient: false,
  onClose: null,
  onCancel: null,
};

export default PurchaseSadesModal;
