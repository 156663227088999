import { createSelector } from 'reselect';
import moment from 'moment';

// Input selectors

const selectData = state => state.alerts.data;

export const isFetching = state => state.alerts.fetching;

export const hasFailed = state => !!state.alerts.fetchError;

//

const sortAlerts = (a, b) => moment(b.updatedAt).diff(a.updatedAt);

export const getAll = createSelector(
  selectData,
  data => [...data].sort(sortAlerts),
);

export const selectTotalUnread = createSelector(
  selectData,
  data => data.reduce((sum, alert) => (sum + (alert.read ? 0 : 1)), 0),
);
