import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

const useDisplayName = (userId: number) => {
  const dispatch = useDispatch();

  const displayname = useSelector(userSelectors.getDisplayName(userId));
  const isLoaded = useSelector(userSelectors.isLoaded(userId));

  useEffect(() => {
    if (!isLoaded && userId) {
      dispatch(userActions.fetchData(userId));
    }
  }, [isLoaded, dispatch, userId]);

  return displayname || '...';
};

export default useDisplayName;
