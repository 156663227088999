export default {
  variants: {
    primary: {
      _before: {
        color: 'gray.800',
        bgColor: 'gray.800',
        content: '""',
        zIndex: -1,
        position: 'absolute',
        px: 2,
        py: 2,
        left: -2,
        top: -0.5,
        bottom: -0.5,
        right: -2,
        transform: 'rotate(-0.5deg)',
      },
      position: 'relative',
      color: 'gray.50',
      px: 2,
      py: 1,
    },
  },

};
