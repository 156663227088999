import produce from 'immer';

import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAIL,
  ADD,
  UPDATE,
  MARK_AS_READ,
  WIPE,
} from './constants';

export const initialState = {
  data: [],
  fetching: false,
  fetchError: null,
  lastUpdate: (new Date()).getTime(),
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case FETCH:
      draft.fetching = true;
      draft.fetchError = null;
      break;

    case FETCH_SUCCESS:
      draft.data = action.data;
      draft.fetching = false;
      draft.fetchError = null;
      draft.lastUpdate = (new Date()).getTime();
      break;

    case FETCH_FAIL:
      draft.fetching = false;
      draft.fetchError = action.error;
      break;

    case ADD:
      draft.data.unshift(action.data);
      break;

    case UPDATE: {
      const index = state.data.findIndex(a => a.id === action.id);
      draft.data.splice(index, 1);
      draft.data.unshift(action.data);
      draft.lastUpdate = (new Date()).getTime();
      break;
    }

    case MARK_AS_READ:
      draft.data = state.data.map(alert => ({
        ...alert,
        read: action.ids.includes(alert.id) ? true : alert.read,
      }));
      draft.lastUpdate = (new Date()).getTime();
      break;

    case WIPE:
      draft.data = initialState.data;
      draft.fetching = initialState.fetching;
      draft.fetchError = initialState.fetchError;
      draft.lastUpdate = (new Date()).getTime();
      break;

    default:
  }
});

export default reducer;
