import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, HStack, Stack, Text,
} from '@chakra-ui/react';

import { useElementHeight } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import UserLink from 'components/UserLink';
import UserDisplayName from 'components/UserDisplayName';
import UserAvatar from 'components/UserAvatar';

import UserListSkeleton from './UserListSkeleton';

const areRowsEqual = (prevProps, nextProps) => {
  const prevUserId = prevProps.data.userIds[prevProps.index];
  const nextUserId = nextProps.data.userIds[nextProps.index];

  return prevUserId === nextUserId;
};

const UserRow = memo(({ data: { userIds, onUserClick }, index, style }) => {
  const uId = userIds[index];
  const username = useSelector(userSelectors.getUsername(uId));

  return (
    <UserLink key={`user-list-${uId}`} style={style} userId={uId} onClick={onUserClick}>
      <HStack spacing={2} _hover={{ textColor: 'brand.700' }}>
        <UserAvatar userId={uId} size="36px" />
        <Stack spacing={0}>
          <UserDisplayName userId={uId} />
          <Text fontSize="sm" color="gray.400">{`@${username}`}</Text>
        </Stack>
      </HStack>
    </UserLink>
  );
}, areRowsEqual);

UserRow.propTypes = {
  data: PropTypes.shape({
    userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onUserClick: PropTypes.func.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.shape({}).isRequired,
};

const UserList = ({ fetchAction, onUserClick }) => {
  const dispatch = useDispatch();

  const [listHeight, selectableListEl] = useElementHeight();
  const [userIds, setUserIds] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const ids = await dispatch(fetchAction());
      setUserIds(ids);
    };

    fetch();
  }, [dispatch, fetchAction]);

  return (
    <Box h="60vh" ref={selectableListEl}>
      {userIds === null
        ? <UserListSkeleton />
        : (
          <List
            height={listHeight}
            itemCount={userIds.length}
            itemSize={50}
            itemData={{ userIds, onUserClick }}
            width="100%"
          >
            {UserRow}
          </List>
        )
      }
    </Box>
  );
};

UserList.propTypes = {
  fetchAction: PropTypes.func.isRequired,
  onUserClick: PropTypes.func.isRequired,
};

UserList.defaultProps = {
};

export default UserList;
