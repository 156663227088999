import {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';

import Api from 'state/api';
import { useTranslation } from 'hooks';

import Select from 'components/Select';
import Spinner from 'components/Spinner';

import locales from './i18n';

const Countries = ({
  value,
  onChange,
  simple,
  solid,
}) => {
  const { t } = useTranslation(locales);

  const [countries, setCountries] = useState(null);

  useEffect(() => {
    const load = async () => {
      setCountries(null);
      const { data } = await Api.req.get('/users/geo/countries');
      setCountries(data);
    };

    load();
  }, []);

  const setCountry = useCallback((v) => {
    onChange(v.value);
  }, [onChange]);

  const options = useMemo(() => (
    (countries || []).map(c => ({
      label: c.name,
      value: c.id,
    }))
  ), [countries]);

  if (countries === null) return <Spinner color="#A8A8A8" />;

  return (
    <Select
      value={options.find(c => c.value === value)}
      onChange={setCountry}
      placeholder={t('Country')}
      options={options}
      simple={simple}
      solid={solid}
    />
  );
};

Countries.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  simple: PropTypes.bool,
  solid: PropTypes.bool,
};

Countries.defaultProps = {
  value: null,
  simple: false,
  solid: false,
};

export default Countries;
