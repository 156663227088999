import styled from 'styled-components';

const Action = styled.div`
  width: 24px;
  cursor: pointer;
  margin-right: 8px;

  &:hover {
    background-color: #f3f3f3;
  }
`;

export default Action;
