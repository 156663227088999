import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import getBadgeColor from 'utils/getBadgeColor';
import * as userSelectors from 'state/users/selectors';

import Decagram from 'components/Icons/Decagram';

interface WrapperProps extends React.HTMLProps<HTMLDivElement> {
  size: number,
  badgesValue: number,
}

const Wrapper = styled.div<WrapperProps>(props => `
  width: 16px;
  height: 16px;
  position: absolute;
  top: -6px;
  right: -6px;
  display: flex;
  align-items: center;
  color: ${getBadgeColor(props).color}
  z-index: 50;

  svg {
    position: absolute;

    path {
      fill: ${getBadgeColor(props).fill};
    }
  }

  span {
    position: absolute;
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    width: 16px;
    text-align: center;
  }

  ${(props.size >= 32) && `
    width: 20;
    height: 20;
    top: -4px;
    right: -4px;

    span {
      font-size: 10px;
      line-height: 10px;
      width: 20;
    }
  `}

  ${(props.size > 36) && `
    width: 24px;
    height: 24px;

    span {
      font-size: 12px;
      line-height: 12px;
      width: 24px;
    }
  `}
`);

interface Props {
  userId: number,
  size: string,
}

const Badges = ({ userId, size }: Props) => {
  // @ts-ignore
  const badgeCount: number = useSelector(state => userSelectors.getBadgeCount(state, userId));
  // @ts-ignore
  const badgesValue: number = useSelector(state => userSelectors.getBadgesValue(state, userId));

  if (!badgeCount) return null;

  return (
    <Wrapper badgesValue={badgesValue} size={parseInt(size.replace('px', ''), 10)}>
      <Decagram />
      <span>{badgeCount}</span>
    </Wrapper>
  );
};

Badges.propTypes = {
  userId: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
};

Badges.defaultProps = {
};

export default memo(Badges);
