import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Button } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';

import locales from './i18n';

const MotionButton = motion(Button);

const STICKY_HEADER_HEIGHT = 64;

const useScrollPosition = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > STICKY_HEADER_HEIGHT);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scrolled;
};

const QueueIndicator = ({ onFlush }) => {
  const { t } = useTranslation(locales);
  const queueCount = useSelector(feedSelectors.getFeedQueueCount());
  const scrolled = useScrollPosition();

  if (!queueCount) return null;

  return (
    <Box
      position="sticky"
      top={`${STICKY_HEADER_HEIGHT}px`}
      zIndex={100}
      width="full"
      padding="8px 0"
      marginBottom="-16px"
      backdropFilter="saturate(180%) blur(5px)"
    >
      <MotionButton
        onClick={onFlush}
        fontWeight={500}
        textTransform="none"
        margin="0 auto"
        display="block"
        variant="primary"
        animate={{
          width: scrolled ? 'auto' : '100%',
        }}
        transition={{
          duration: 0.3,
          ease: 'easeInOut',
        }}
        px={4}
        maxWidth={scrolled ? '400px' : '100%'}
      >
        {t('{{count}} new publications', { count: queueCount, context: (queueCount === 1 ? 'SINGULAR' : 'PLURAL') })}
      </MotionButton>
    </Box>
  );
};

QueueIndicator.propTypes = {
  onFlush: PropTypes.func.isRequired,
};

export default QueueIndicator;
