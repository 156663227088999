import { useSelector } from 'react-redux';

import * as feedSelectors from 'state/feed/selectors';

import { Icon } from '@chakra-ui/react';
import { BookMarked, Contact, Earth } from 'lucide-react';
import { PUBLICATION_PRIVACIES } from '../../../constants';

interface Props {
  publicationId: string;
}

const PrivacyIcon: React.FC<Props> = ({ publicationId }) => {
  const privacy = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectPrivacy(state, publicationId),
  );

  if (!privacy) return null;

  switch (privacy) {
    case PUBLICATION_PRIVACIES.CONTACTS:
      return <Icon as={Contact} color="gray.500" />;
    case PUBLICATION_PRIVACIES.LISTS:
      return <Icon as={BookMarked} color="gray.500" />;
    case PUBLICATION_PRIVACIES.PUBLIC:
    default:
      return <Icon as={Earth} color="gray.500" />;
  }
};

export default PrivacyIcon;
