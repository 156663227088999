import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@chakra-ui/react';

import * as appActions from 'state/app/actions';
import * as appSelectors from 'state/app/selectors';

import { AccountDetails } from 'components/Icons';
import { Action } from 'components/Header';

const ShowParticipantsButton = () => {
  const dispatch = useDispatch();
  const showing = useSelector(appSelectors.selectIsShowingParticipants);

  const toggleShowParticipants = () => dispatch(appActions.setShowingParticipants(!showing));

  return (
    <Action onClick={toggleShowParticipants} pressed={showing} mobileOnly>
      <Icon as={AccountDetails} fill={['white']} />
    </Action>
  );
};

export default ShowParticipantsButton;
