import {
  Box, BoxProps, Heading, HStack,
} from '@chakra-ui/react';

interface Props extends BoxProps {
  title: string;
}

const PageHeader: React.FC<Props> = ({ title, children, ...props }) => {
  return (
    <HStack justifyContent="space-between" alignItems="center" mb={6} zIndex={10} position="relative" {...props}>
      <Heading variant="primary" as="h1" size="lg" m={0}>{title}</Heading>
      <Box>
        {children}
      </Box>
    </HStack>
  );
};

export default PageHeader;
