import styled from 'styled-components';

const PrivacyWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  line-height: 1.8;
  @media(min-width: 640px) {
    grid-row-gap: 0;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default PrivacyWrapper;
