import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import * as channelActions from 'state/channels/actions';
import * as appActions from 'state/app/actions';

import { useTranslation, useInputValue } from 'hooks';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Input from 'components/Forms/Input';

import locales from '../../i18n';

const BanConfirmModal = ({ user, channelId, close }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const [banning, setBanning] = useState(false);
  const banReason = useInputValue('');

  const ban = async () => {
    try {
      setBanning(true);
      await dispatch(channelActions.ban(channelId, user.id, banReason.value));
      dispatch(appActions.addToast(t('User banned from channel')));
      setBanning(false);
      close();
    } catch (error) {
      dispatch(appActions.addError(error));
      setBanning(false);
      close();
    }
  };

  return (
    <Modal
      title={t('Ban')}
      onCancel={close}
      actions={[
        <Button key={`ban-user-item-${user.id}`} onClick={ban} loading={banning}>{t('global:Confirm')}</Button>,
      ]}
    >
      {t('Are you sure you want to ban {{user}} from this channel?', { user: user.displayname })}
      <Input placeholder={t('Reason')} {...banReason} style={{ marginTop: '16px' }} />
    </Modal>
  );
};

BanConfirmModal.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayname: PropTypes.string.isRequired,
  }).isRequired,
  channelId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default BanConfirmModal;
