import styled from 'styled-components';

const ThreadWrapper = styled.div`
  box-shadow: rgb(238, 238, 238) 0px 0px 5px 1px;
  margin-top: 40px;
  border-radius: 5px;

  .header {
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
  }
`;
ThreadWrapper.displayName = 'ThreadWrapper';

export default ThreadWrapper;
