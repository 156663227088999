import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';
import { FixedSizeGrid } from 'react-window';

import { useTranslation, useElementHeight } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import Box from '../Box';
import User from './User';
import locales from '../i18n';

const Grid = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
Grid.displayName = 'Grid';

const areRowsEqual = (prevProps, nextProps) => {
  const prevUserId = prevProps.data.onlineUserIds[prevProps.index];
  const nextUserId = nextProps.data.onlineUserIds[nextProps.index];

  return prevUserId === nextUserId;
};

const UserRow = memo(({
  data: { onlineUserIds }, columnIndex, rowIndex, style,
}) => {
  const userId = onlineUserIds[(rowIndex * 2) + columnIndex];
  return <User key={`sidebar-online-${userId}`} userId={userId} style={style} />;
}, areRowsEqual);

UserRow.propTypes = {
  data: PropTypes.shape({
    onlineUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  columnIndex: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  style: PropTypes.shape({}).isRequired,
};

const UsersOnline = () => {
  const { t } = useTranslation(locales);
  const onlineUserIds = useSelector(userSelectors.selectOnline, shallowEqual);
  const [gridHeight, gridElement] = useElementHeight();

  return (
    <Box title={t('global:Users Online')} height={150}>
      <Grid ref={gridElement}>
        <FixedSizeGrid
          height={gridHeight}
          columnCount={2}
          rowHeight={40}
          itemData={{ onlineUserIds }}
          columnWidth={135}
          width={300}
          rowCount={Math.ceil(onlineUserIds.length / 2)}
        >
          {UserRow}
        </FixedSizeGrid>
      </Grid>
    </Box>
  );
};

UsersOnline.propTypes = {
};

UsersOnline.defaultProps = {
};

export default UsersOnline;
