/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useBrowser from './useBrowser';

interface key {
  key: string;
  metaKey?: boolean;
  shiftKey?: boolean;
  ctrlKey?: boolean;
  actionKey?: boolean;
}

const useHotKeys = (keys: key[], cb: (event: KeyboardEvent) => void, el = window) => {
  const [position, setPosition] = useState(0);
  const browser = useBrowser();

  const actionKey = browser.os.mac ? 'metaKey' : 'ctrlKey';

  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      const currentPos = keys[position];
      if (
        e.key === currentPos.key
        && (!currentPos.metaKey || e.metaKey)
        && (!currentPos.shiftKey || e.shiftKey)
        && (!currentPos.ctrlKey || e.ctrlKey)
        && (!currentPos.actionKey || e[actionKey])
      ) {
        if (position === (keys.length - 1)) {
          cb(e);
          setPosition(0);
        } else {
          setPosition(pos => pos + 1);
        }
      } else if (position !== 0) {
        setPosition(0);
      }
    };

    el.addEventListener('keydown', keydown);
    return () => el.removeEventListener('keydown', keydown);
  }, [position]);
};

export default useHotKeys;
