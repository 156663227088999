/* eslint-disable react/no-array-index-key */

import { useState, useRef, useEffect } from 'react';

import getRandomInt from 'utils/getRandomInt';

import {
  Body as BodyWrapper,
  Avatar,
  Username,
  Message,
  Bubble,
  Content,
  Word,
} from './UI';
import generateParticipants from './generateParticipants';

const generateMessages = (qty) => {
  const messages = [];
  for (let i = 0; i < qty; i += 1) {
    const participant = generateParticipants(1)[0];
    messages.push({
      ...participant,
      words: [...Array(getRandomInt(1, 7))].map(() => ({ width: getRandomInt(25, 100) })),
    });
  }
  return messages;
};

const Body = () => {
  const timerRef = useRef(null);
  const lastMessage = useRef(null);

  const [messages, setMessages] = useState(generateMessages(30));

  useEffect(() => {
    const timer = timerRef.current;

    const addMessage = () => {
      setMessages((currentMessages) => {
        const newMessages = [
          ...currentMessages.slice(1),
          ...generateMessages(1),
        ];
        return newMessages;
      });

      timerRef.current = setTimeout(addMessage, getRandomInt(500, 5000));
    };

    addMessage();

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (lastMessage.current) lastMessage.current.scrollIntoView();
  }, [messages]);

  return (
    <BodyWrapper>
      {messages.map(({ avatarColor, usernameWidth, words }, index) => (
        <Message key={`message-${index}-${usernameWidth}`} ref={index === messages.length - 1 ? lastMessage : undefined}>
          <Avatar color={avatarColor} width={32} />
          <Bubble>
            <Username width={usernameWidth} />
            <Content>
              {words.map(({ width }, jndex) => (
                <Word key={`word-${index}-${jndex}-${width}`} width={width} />
              ))}
            </Content>
          </Bubble>
        </Message>
      ))}
    </BodyWrapper>
  );
};

Body.propTypes = {
};

Body.defaultProps = {
};

export default Body;
