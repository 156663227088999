import { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import * as userActions from 'state/users/actions';


import UserList from 'components/UserList';

import locales from '../../i18n';

const Following = ({ userId, close: onClose }) => {
  const { t } = useTranslation(locales);

  const fetchAction = useCallback(() => userActions.getFollowing(userId), [userId]);

  return (
    <Modal isOpen onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('Following')}</ModalHeader>
        <ModalBody>
          <UserList fetchAction={fetchAction} onUserClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

Following.propTypes = {
  userId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};

Following.defaultProps = {
};

export default Following;
