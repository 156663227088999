import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as userSelectors from 'state/users/selectors';

import Avatar from 'components/UserAvatar';

const style = { marginTop: '2px' };

const userEqual = (prevUser, nextUser) => (
  prevUser.id === nextUser.id
  && prevUser.username === nextUser.username
);

const AvatarLink = ({ authorId }) => {
  const author = useSelector(userSelectors.getById(authorId), userEqual);

  return (
    <Link to={`/@${author.username}`} className="userlink" style={style}>
      <Avatar userId={authorId} size="32px" />
    </Link>
  );
};

AvatarLink.propTypes = {
  authorId: PropTypes.number.isRequired,
};

AvatarLink.defaultProps = {
};

export default memo(AvatarLink);
