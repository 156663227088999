import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation, useUploadMedia } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as appSelectors from 'state/app/selectors';
import * as feedSelectors from 'state/feed/selectors';

import UserAvatar from 'components/UserAvatar';
import Composer from 'components/Composer';
import CommunityUploadMedia from 'components/CommunityUploadMedia';

import Actions from './Actions';
import Wrapper from './UI/Wrapper';
import Container from './UI/Container';
import ComposerWrapper from './UI/ComposerWrapper';
import FakeComposer from './UI/FakeComposer';
import locales from '../i18n';

const AddComment = ({ publicationId }) => {
  const { t } = useTranslation(locales);

  const id = `comment-${publicationId}`;

  const userId = useSelector(authSelectors.selectUserId);
  const userIsConfirmed = useSelector(authSelectors.isConfirmed);
  const closedComments = useSelector(
    state => feedSelectors.publications.commentsBlocked(state, publicationId),
  );
  const composerDisabled = useSelector(state => appSelectors.selectIsComposerDisabled(state, id));

  const [faking, setFaking] = useState(true);

  const {
    getRootProps,
    getInputProps,
    open,
    handlePastedFiles,
  } = useUploadMedia(id, '/feed/upload');

  const unfake = useCallback(() => { setFaking(false); }, []);

  if (closedComments || !userIsConfirmed) return null;

  return (
    <Wrapper {...getRootProps()}>
      <Container>
        <UserAvatar userId={userId} size="24px" />
        <ComposerWrapper disabled={composerDisabled}>
          {faking
            ? <FakeComposer onClick={unfake}>{t('Enter your message')}</FakeComposer>
            : (
              <Composer
                key={id}
                id={id}
                placeholder={t('Enter your message')}
                maxHeight="100px"
                handlePastedFiles={handlePastedFiles}
                emojiSize={16}
                markdown={false}
                autofocus
              />
            )
          }
        </ComposerWrapper>
      </Container>
      <CommunityUploadMedia
        id={id}
        open={open}
        getInputProps={getInputProps}
      />
      <Actions openMedia={open} publicationId={publicationId} />
    </Wrapper>
  );
};

AddComment.propTypes = {
  publicationId: PropTypes.string.isRequired,
};

export default AddComment;
