import { normalize } from 'normalizr';
import ReactGA from 'react-ga4';
import { Howl } from 'howler';
import { batch } from 'react-redux';

import Api from 'state/api';
import ComposerRef from 'utils/ComposerRef';
import imSndFile from 'utils/snd/im.mp3';
import * as appActions from 'state/app/actions';
import * as channelSelectors from 'state/channels/selectors';

import { channelSchema, channelmessageSchema } from './schema';
import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAIL,
  REMOVE_CHANNEL,
  ADD_MESSAGE,
  UPDATE_MESSAGE,
  UPDATE,
  SET_ACTIVE,
  UNMOUNT,
  SCROLL_AT_BOTTOM_CHANGE,
  LOAD_MESSAGES,
  LOAD_MESSAGES_SUCCESS,
  // LOAD_MESSAGES_FAIL,
  MESSAGE_REMOVING,
  MESSAGE_REMOVE,
  FULLY_LOADED,
  // TYPING_START,
  // TYPING_STOP,
  INC_UNREAD_MENTIONS,
  MARK_ALL_AS_READ,
  JOIN,
  PART,
  ADD_BOT,
  ADD_REPLYTO,
  CLEAR_REPLYTO,
  ADD_EDITCOMPOSER,
  REMOVE_EDITCOMPOSER,
  EMBED_UPDATED,
  WIPE,
} from './constants';
import { CHANNEL_MESSAGES_TYPES } from '../../constants';

// const imSnd = new Audio(imSndFile);
const imSnd = new Howl({
  src: [imSndFile],
});

const processChannelData = rawData => (dispatch) => {
  const data = normalize(rawData, channelSchema);
  dispatch({
    type: FETCH_SUCCESS,
    data: (data.entities.channels || {}),
    result: [data.result],
  });

  return data.entities.channels[data.result];
};

export const add = rawData => (dispatch, getState) => {
  const meId = getState().auth?.me?.id;
  const data = normalize(rawData, [channelSchema]);

  batch(() => {
    Object.values(data.entities.channelmessages || {}).forEach((cm) => {
      dispatch({
        type: ADD_MESSAGE,
        channelId: cm.channel,
        payload: cm,
      });
    });

    dispatch({
      type: FETCH_SUCCESS,
      data: (data.entities.channels || {}),
      result: data.result,
      userId: meId,
    });
    dispatch(appActions.computeChatList());
  });
};

export const fetch = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH });
    const { data } = await Api.req.get('/chat/channels');
    dispatch(add(data));
  } catch (err) {
    let error = err.message;
    if (err.response) {
      // eslint-disable-next-line
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }

    dispatch({ type: FETCH_FAIL, error: (error || err) });
  }
};

export const create = payload => async (dispatch) => {
  const { data } = await Api.req.post('/chat/channels', payload);

  ReactGA.event({
    category: 'Chat',
    action: 'Channel created',
    label: data.name,
  });

  const result = dispatch(processChannelData(data));
  dispatch(appActions.addToChatList('channel', data.id));

  return result;
};

export const edit = (id, payload) => async (dispatch) => {
  const { data } = await Api.req.put(`/chat/channels/${id}`, payload);
  return dispatch(processChannelData(data));
};

export const remove = channelId => async (dispatch) => {
  await Api.req.delete(`/chat/channels/${channelId}`);

  batch(() => {
    dispatch(appActions.removeFromChatList('channel', channelId));
    dispatch({ type: REMOVE_CHANNEL, channelId });
  });
};

export const join = channelId => async (dispatch) => {
  const { data } = await Api.req.post(`/chat/channels/${channelId}/memberships`);

  ReactGA.event({
    category: 'Chat',
    action: 'Channel join',
    label: `Channel ID: ${channelId}`,
  });

  const result = dispatch(processChannelData(data));
  dispatch(appActions.addToChatList('channel', channelId));

  return result;
};

export const part = channelId => async (dispatch) => {
  await Api.req.delete(`/chat/channels/${channelId}/memberships`);

  ReactGA.event({
    category: 'Chat',
    action: 'Channel part',
    label: `Channel ID: ${channelId}`,
  });

  batch(() => {
    dispatch(appActions.removeFromChatList('channel', channelId));
    dispatch({ type: REMOVE_CHANNEL, channelId });
  });
};

export const ban = (channelId, bannedUserId, reason) => async (dispatch) => {
  const { data } = await Api.req.post(`/chat/channels/${channelId}/bans`, { bannedUserId, reason });

  ReactGA.event({
    category: 'Chat',
    action: 'Channel ban',
    label: `Channel ID: ${channelId}`,
  });

  return dispatch(processChannelData(data));
};

export const unban = (channelId, unbannedUserId) => async (dispatch) => {
  const { data } = await Api.req.delete(`/chat/channels/${channelId}/bans/${unbannedUserId}`);

  ReactGA.event({
    category: 'Chat',
    action: 'Channel unban',
    label: `Channel ID: ${channelId}`,
  });

  return dispatch(processChannelData(data));
};

export const invite = (channelId, inviteeId) => async () => {
  await Api.req.post(`/chat/channels/${channelId}/invitations`, { inviteeId });

  ReactGA.event({
    category: 'Chat',
    action: 'Channel invitation created',
    label: `Channel ID: ${channelId}`,
  });
};

export const inviteMultiple = (channelId, inviteeIds) => async () => {
  await Api.req.post(`/chat/channels/${channelId}/invitations`, { inviteeIds });

  ReactGA.event({
    category: 'Chat',
    action: 'Channel invitation created',
    label: `Channel ID: ${channelId}`,
  });
};

export const removeInvitation = (channelId, invitationId) => async () => {
  await Api.req.delete(`/chat/channels/${channelId}/invitations/${invitationId}`);

  ReactGA.event({
    category: 'Chat',
    action: 'Channel invitation removed',
    label: `Channel ID: ${channelId}`,
  });
};

export const changeRole = (channelId, userId, role) => async (dispatch) => {
  const { data } = await Api.req.put(`/chat/channels/${channelId}/memberships/${userId}`, { role });
  return dispatch(processChannelData(data));
};

export const getEmbed = channelId => async (dispatch) => {
  const { data } = await Api.req.get(`/chat/channels/${channelId}/embed`);
  dispatch({ type: EMBED_UPDATED, channelId, data });
};

export const updateEmbed = (channelId, payload) => async (dispatch) => {
  const { data } = await Api.req.put(`/chat/channels/${channelId}/embed`, payload);
  dispatch({ type: EMBED_UPDATED, channelId, data });
};

export const createChannelMessage = (channelId, payload) => async (dispatch) => {
  const referenceId = String((new Date()).getTime());
  dispatch({
    type: ADD_MESSAGE,
    channelId,
    payload: { type: CHANNEL_MESSAGES_TYPES.MESSAGE, payload },
    referenceId,
  });

  const { data } = await Api.req.post(`/chat/channels/${channelId}/messages`, {
    ...payload,
    referenceId,
  });

  ReactGA.event({
    category: 'Chat',
    action: 'Channel message created',
    label: `Channel ID: ${channelId}`,
  });

  dispatch({
    type: ADD_MESSAGE,
    channelId,
    payload: data,
    referenceId,
  });
};

export const loadMoreMessages = channelId => async (dispatch, getState) => {
  try {
    const limit = 30;
    const messages = channelSelectors.getMessagesByChannelId(getState(), channelId);
    const lastMessage = messages[0];
    const beforeDate = lastMessage ? lastMessage.createdAt : null;

    dispatch({ type: LOAD_MESSAGES, channelId });
    const { data: rawData } = await Api.req.get(`/chat/channels/${channelId}/messages`, {
      params: { before: beforeDate, limit },
    });
    const data = normalize(rawData, [channelmessageSchema]);

    batch(() => {
      dispatch({
        type: LOAD_MESSAGES_SUCCESS,
        channelId,
        channelmessages: data.entities.channelmessages,
        result: data.result,
      });

      if (data.result.length < limit) dispatch({ type: FULLY_LOADED, channelId });
    });
  } catch (error) {
    dispatch({ type: FETCH_FAIL, error });
  }
};

export const fetchSingleMessage = (channelId, messageId) => async (dispatch) => {
  const { data: rawData } = await Api.req.get(`/chat/channels/${channelId}/messages/${messageId}`);
  const data = normalize(rawData, channelmessageSchema);

  const cm = data.entities.channelmessages[data.result];
  dispatch({ type: UPDATE_MESSAGE, payload: cm });
};

export const setActive = active => (dispatch, getState) => {
  if (getState().channels.active !== active) {
    dispatch({ type: SET_ACTIVE, active });
  }
};

export const markAllAsRead = () => (dispatch, getState) => {
  const channelIds = channelSelectors.selectUnreadChannels(getState());

  // Optimistic
  dispatch({ type: MARK_ALL_AS_READ });

  channelIds.forEach((channelId) => {
    Api.req.put(`/chat/channels/${channelId}/read`);
  });
};

export const unmount = id => async (dispatch, getState) => {
  const haveUnread = channelSelectors.haveUnread(getState(), id);

  dispatch({ type: UNMOUNT, id });

  if (haveUnread) {
    Api.req.put(`/chat/channels/${id}/read`);
  }
};

export const scrollAtBottomChange = (id, isAtBottom) => (dispatch) => {
  dispatch({
    type: SCROLL_AT_BOTTOM_CHANGE,
    id,
    isAtBottom,
  });
};

export const sendTypingState = (/* id, typingState */) => () => {
  // Api.req.put(`/chat/channels/${id}/typing`, { state: typingState });
};

export const editChannelMessage = (channelId, messageId, payload) => async (dispatch) => {
  const { data: rawData } = await Api.req.put(`/chat/channels/${channelId}/messages/${messageId}`, payload);

  const data = normalize(rawData, channelmessageSchema);
  const cm = data.entities.channelmessages[data.result];

  dispatch({
    type: UPDATE_MESSAGE,
    payload: cm,
  });
};

export const removeMessage = messageId => async (dispatch, getState) => {
  const message = getState().channels.data.channelmessages[messageId];
  const channelId = typeof message.channel === 'object' ? message.channel.id : message.channel;

  await Api.req.delete(`/chat/channels/${channelId}/messages/${messageId}`);

  dispatch({ type: MESSAGE_REMOVING, messageId });
};

export const messageRemoved = messageId => (dispatch) => {
  dispatch({ type: MESSAGE_REMOVE, messageId });
};

export const addReaction = (messageId, name) => async (dispatch, getState) => {
  const message = getState().channels.data.channelmessages[messageId];
  const channelId = typeof message.channel === 'object' ? message.channel.id : message.channel;

  const { data: rawData } = await Api.req.post(`/chat/channels/${channelId}/messages/${messageId}/reactions`, { name });
  const data = normalize(rawData, channelmessageSchema);

  const cm = data.entities.channelmessages[data.result];

  dispatch({
    type: UPDATE_MESSAGE,
    payload: cm,
  });
};

export const removeReaction = (messageId, name) => async (dispatch, getState) => {
  const message = getState().channels.data.channelmessages[messageId];
  const channelId = typeof message.channel === 'object' ? message.channel.id : message.channel;

  const { data: rawData } = await Api.req.delete(`/chat/channels/${channelId}/messages/${messageId}/reactions`, { data: { name } });
  const data = normalize(rawData, channelmessageSchema);

  const cm = data.entities.channelmessages[data.result];

  dispatch({
    type: UPDATE_MESSAGE,
    payload: cm,
  });
};

export const addReplyTo = (channelId, messageId) => (dispatch) => {
  const ref = ComposerRef.getRef(`channel-${channelId}`);
  dispatch({ type: ADD_REPLYTO, channelId, messageId });

  if (ref) ref.focus();
};

export const addEditComposer = (channelId, messageId) => (dispatch) => {
  dispatch({ type: ADD_EDITCOMPOSER, channelId, messageId });
};

export const addEditComposerForLastOwnMessage = channelId => (dispatch, getState) => {
  const messageId = channelSelectors
    .selectLastOutgoingMessageIdByChannelId(getState(), channelId);
  if (messageId) {
    dispatch({ type: ADD_EDITCOMPOSER, channelId, messageId });
  }
};

export const cancelEditing = channelId => (dispatch) => {
  dispatch({ type: REMOVE_EDITCOMPOSER, channelId });
};

export const clearReplyTo = channelId => (dispatch) => {
  const ref = ComposerRef.getRef(`channel-${channelId}`);
  dispatch({ type: CLEAR_REPLYTO, channelId });

  if (ref) ref.focus();
};

export const searchByName = (search, max) => (dispatch, getState) => {
  const { data } = getState().channels;

  const results = Object.values(data.channels)
    .filter(channel => channel.name.toLowerCase().includes(search.toLowerCase()))
    .slice(0, max);

  return results;
};

export const handleChannelMessageCreated = ({ message, channelId, referenceId }) => (dispatch, getState) => {
  const { me } = getState().auth;
  const state = getState().channels;

  const data = normalize(message, channelmessageSchema);
  const cm = data.entities.channelmessages[data.result];

  batch(() => {
    dispatch({
      type: ADD_MESSAGE,
      channelId,
      payload: { ...cm, channel: channelId },
      referenceId,
    });
    dispatch(appActions.updateChatList('channel', channelId));

    switch (cm.type) {
      case CHANNEL_MESSAGES_TYPES.JOIN:
        dispatch({ type: JOIN, userId: cm.authorId, channelId });
        break;
      case CHANNEL_MESSAGES_TYPES.PART:
        dispatch({ type: PART, userId: cm.authorId, channelId });
        break;
      case CHANNEL_MESSAGES_TYPES.BAN:
        dispatch({ type: PART, userId: cm.authorId, channelId });
        break;
      default:
    }

    const mentionRE = new RegExp(`(@${me.username})\\b`, 'gi');
    if (cm.authorId !== me.id && cm.payload && mentionRE.test(cm.payload.rawContent)) {
      if (channelId !== state.active) {
        dispatch({ type: INC_UNREAD_MENTIONS, channelId });
      }

      imSnd.play();
    }
  });
};

export const handleChannelMessageUpdated = ({ message, channelId }) => (dispatch) => {
  const data = normalize(message, channelmessageSchema);
  const cm = data.entities.channelmessages[data.result];

  dispatch({
    type: UPDATE_MESSAGE,
    payload: { ...cm, channel: channelId },
  });
};

export const handleChannelMessageRemoved = ({ messageId }) => (dispatch) => {
  dispatch({ type: MESSAGE_REMOVING, messageId });
};

export const changeAvatar = (id, blob) => async (dispatch) => {
  const formData = new FormData();
  formData.append('avatar', blob, blob.name);

  const { data: rawData } = await Api.req.put(`/chat/channels/${id}`, formData, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
    timeout: 30000,
  });

  const data = normalize(rawData, channelSchema);
  const channelEdited = data.entities.channels[data.result];

  dispatch({ type: UPDATE, data: channelEdited });
  return data;
};

export const fetchBotData = botId => async (dispatch) => {
  const { data } = await Api.req.get(`/chat/bots/${botId}`);
  dispatch({ type: ADD_BOT, data });
};

export const handleChannelUpdated = rawData => (dispatch) => {
  const data = normalize(rawData, channelSchema);
  const channel = data.entities.channels[data.result];

  dispatch({ type: UPDATE, data: channel });
};

export const handleChannelDeleted = ({ channelId }) => (dispatch) => {
  batch(() => {
    dispatch(appActions.removeFromChatList('channel', channelId));
    dispatch({ type: REMOVE_CHANNEL, channelId });
  });
};

// TODO: handle typing in channels
export const handleChannelTyping = () => () => {};

// export const handleChannelTyping = ({
//   state: typingState,
//   channelId,
//   userId,
// }) => async (dispatch) => {
//   const {
//     [`${channelId}-${userId}`]: timerId,
//     ...rest
//   } = typingTimers;

//   if (timerId) {
//     clearTimeout(timerId);
//     setTypingTimers(rest);
//   }

//   if (typingState === 'STARTED') {
//     dispatcher.typingStart(channelId, userId);
//     typingTimers[`${channelId}-${userId}`] = setTimeout(() => (
//      dispatcher.typingStop(channelId, userId), 20000)
//     );
//   }
//   if (typingState === 'STOPPED') dispatcher.typingStop(channelId, userId);
// };

export const handleEmbedUpdated = ({ channelId, embed }) => (dispatch) => {
  dispatch({ type: EMBED_UPDATED, channelId, data: embed });
};

export const wipe = () => dispatch => dispatch({ type: WIPE });

export const transferChannelOwnership = (channelId, userId) => async (dispatch) => {
  const { data } = await Api.req.put(`/chat/channels/${channelId}/owner`, { newOwnerId: userId });
  dispatch({ type: UPDATE, data });
};
