import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ismobile from 'ismobilejs';

import * as appSelectors from 'state/app/selectors';
import * as channelSelectors from 'state/channels/selectors';

import Messages from '../Content/Messages';
import ComposeMessage from '../Content/ComposeMessage';
import EditMessage from '../Content/ComposeMessage/EditMessage';
import Participants from './Participants';
import Embed from './Embed';

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  overflow: auto;
`;

const ChatAreaContainer = styled.div`
  display: flex;
  height: calc(100vh - 128px);

  @media(max-width: 767px) {
    height: calc(100vh - 64px);
    margin-top: 64px;
    flex-direction: column;
    position: relative;
  }

  > .chat-content {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media(max-width: 767px) {
      height: 35%;

      ${props => props.keyboardOpen && `
        height: 100%;
      `}
    }
  }
`;
ChatAreaContainer.displayName = 'ChatAreaContainer';

const ChatArea = ({ id }) => {
  const showing = useSelector(appSelectors.selectIsShowingParticipants);
  const isEditing = useSelector(state => channelSelectors.isEditing(state, id));

  const [keyboardOpen, setKeyboardOpen] = useState(false);

  const toggleKeyboard = useCallback(() => {
    if (ismobile.phone || ismobile.tablet) {
      setKeyboardOpen(v => !v);
    }
  }, []);

  return (
    <ChatAreaContainer keyboardOpen={keyboardOpen}>
      <Embed channelId={id} />

      <div className="chat-content">
        <ContentWrapper>
          <Messages key={`chat-channelmessages-${id}`} type="channel" id={id} />
          {(showing || (!ismobile.phone && !ismobile.tablet)) && (
            <Participants key={`chat-participants-${id}`} id={id} />
          )}
        </ContentWrapper>

        {isEditing
          ? <EditMessage key={`compose-channel-edit-${id}`} type="channel" id={id} />
          : <ComposeMessage key={`compose-channel-${id}`} type="channel" id={id} onFocus={toggleKeyboard} onBlur={toggleKeyboard} />
        }
      </div>
    </ChatAreaContainer>
  );
};

ChatArea.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ChatArea;
