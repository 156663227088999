import { useState } from 'react';
import styled from 'styled-components';
import qs from 'qs';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as authActions from 'state/auth/actions';
import * as authSelectors from 'state/auth/selectors';

import colors from 'utils/css/colors';
import {
  useInputValue,
  useTranslation,
  useFocusOnMount,
  useTitle,
} from 'hooks';

import AuthLayout from 'components/AuthLayout';
import Input from 'components/Forms/InputSimple';
import Button from 'components/Button';

import Reactivate from './Reactivate';
import locales from './i18n';

const ForgotPassword = styled.div`
  margin: 20px 0;
  text-align: left;
  margin-top: 25px;

  a {
    color: ${colors.redReactions};
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.main};
`;
ErrorMessage.displayName = 'ErrorMessage';

const Login = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  useTitle(t('Enter your account'));

  const history = useHistory();

  const loginError = useSelector(authSelectors.getLoginError);

  const usernameEl = useFocusOnMount();
  const [loggingIn, setLoggingIn] = useState(false);
  const username = useInputValue('');
  const password = useInputValue('');

  const [reactivate, setReactivate] = useState(false);

  const login = async () => {
    try {
      setLoggingIn(true);

      const queryparams = qs.parse(document.location.search, { ignoreQueryPrefix: true });
      let callback = null;
      if (queryparams.redirect) {
        callback = () => history.push(queryparams.redirect);
      }

      await dispatch(authActions.login(username.value, password.value, false, callback));
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message === 'SUSPENDED') {
        setReactivate(true);
      } else {
        setLoggingIn(false);
      }
    }
  };

  if (reactivate) return <Reactivate username={username.value} password={password.value} />;

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  return (
    <AuthLayout
      title={t('Enter your account')}
      subtitle={(
        <>
          {t('You don\'t have an account?')}
          {' '}
          <Link to="/signup">{t('Sign up')}</Link>
        </>
      )}
    >
      <Input
        ref={usernameEl}
        placeholder={t('Username')}
        {...username}
        disabbled={loggingIn}
        onKeyPress={handleKeyPress}
        withBorder
      />
      <Input
        placeholder={t('Password')}
        type="password"
        {...password}
        disabbled={loggingIn}
        onKeyPress={handleKeyPress}
        withBorder
      />

      {loginError && (
        <ErrorMessage>
          {typeof loginError === 'object' ? loginError.message : t(`global:${loginError}`)}
        </ErrorMessage>
      )}

      <ForgotPassword>
        <Link to="/forgot-password">{t('I forgot my password')}</Link>
      </ForgotPassword>

      <Button
        onClick={login}
        loading={loggingIn}
        content={t('Enter')}
      />
    </AuthLayout>
  );
};

export default Login;
