import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';
import * as messengerSelectors from 'state/messengers/selectors';
import * as channelActions from 'state/channels/actions';
import * as messengerActions from 'state/messengers/actions';
import { mediaEqual, mediaGifEqual } from 'state/app/equalityFunctions';

import Close from 'components/Icons/Close';
import ParsedContent from 'components/ParsedContent';
import ContentMedia from 'components/ContentMedia';

import ReplyingTo from '../ReplyingTo';
import Gif from '../Gif';

const ReplyTo = ({ type, id }) => {
  const dispatch = useDispatch();

  const selectors = type === 'channel' ? channelSelectors : messengerSelectors;

  const messageId = useSelector(state => selectors.getReplyTo(state, id));
  const authorId = useSelector(state => selectors.getMessageAuthorId(state, messageId));
  const authorType = useSelector(state => selectors.getMessageAuthorType(state, messageId));
  const content = useSelector(state => selectors.getMessageContent(state, messageId));
  const gif = useSelector(state => selectors.getMessageGif(state, messageId), mediaGifEqual);
  const media = useSelector(
    state => selectors.getMessageMedia(state, messageId),
    mediaEqual,
  );

  const clear = useCallback(() => {
    const actions = type === 'channel' ? channelActions : messengerActions;
    dispatch(actions.clearReplyTo(id));
  }, [dispatch, id, type]);

  if (!content.length && !gif && !media) return null;

  return (
    <ReplyingTo authorType={authorType} authorId={authorId}>
      <div>
        {gif && <Gif data={gif} />}
        <ContentMedia media={media} />
        {content && <ParsedContent content={content} />}
      </div>
      <button onClick={clear} type="button"><Close /></button>
    </ReplyingTo>
  );
};

ReplyTo.propTypes = {
  type: PropTypes.oneOf(['channel', 'messenger']).isRequired,
  id: PropTypes.string.isRequired,
};

ReplyTo.defaultProps = {
};

export default ReplyTo;
