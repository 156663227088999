import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as threadSelectors from 'state/threads/selectors';
import * as replySelectors from 'state/replies/selectors';

import Modal from 'components/Modal';

import UserLink from 'components/UserLink';
import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import * as images from './img';
import locales from '../i18n';
import { AWARDS } from './constants';

const GroupWrapper = styled.div`
  margin-bottom: 32px;

  .label {
    font-weight: 500;
    font-size: 24px;
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }

  ul {
    list-style: none;
    color: ${props => props.theme.colors.main};

    .userlink {
      display: flex;
      align-items: center;

      .avatar {
        margin-right: 4px;
      }
    }
  }
`;
GroupWrapper.displayName = 'GroupWrapper';

const Group = ({ label, awards }) => {
  const awarded = awards.filter(a => a.label === label);
  const { t } = useTranslation(locales);

  if (!awarded.length) return null;

  return (
    <GroupWrapper>
      <div className="label">
        <img src={images[label]} alt={t(`AWARD.${label}`)} title={t(`AWARD.${label}`)} />
        <span>{t(`AWARD.${label}`)}</span>
      </div>
      <ul>
        {awarded.map(({ userId }) => (
          <li>
            <UserLink userId={userId}>
              <UserAvatar userId={userId} size="32px" />
              <UserDisplayName userId={userId} />
            </UserLink>
          </li>
        ))}
      </ul>
    </GroupWrapper>
  );
};

Group.propTypes = {
  label: PropTypes.oneOf(Object.keys(AWARDS)).isRequired,
  awards: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOf(Object.keys(AWARDS)).isRequired,
      userId: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
};

Group.defaultProps = {
};


const ListModal = ({ close, type, entityId }) => {
  const selector = type === 'thread' ? threadSelectors : replySelectors;
  const awards = useSelector(state => selector.getAwards(state, entityId), shallowEqual);

  return (
    <Modal onClose={close}>
      {Object.keys(AWARDS).map(label => <Group label={label} awards={awards} />)}
    </Modal>
  );
};

ListModal.propTypes = {
  close: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['thread', 'reply']).isRequired,
};

ListModal.defaultProps = {
};

export default ListModal;
