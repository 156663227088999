import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import UserList from 'components/UserList';

import locales from '../../i18n';

const Followers = ({ userId, close: onClose }) => {
  const { t } = useTranslation(locales);

  const pronoun = useSelector(userSelectors.getPronoun(userId));

  const fetchAction = useCallback(() => userActions.getFollowers(userId), [userId]);

  return (
    <Modal isOpen onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('Followed by', { context: pronoun })}</ModalHeader>
        <ModalBody>
          <UserList fetchAction={fetchAction} onUserClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

Followers.propTypes = {
  userId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};

Followers.defaultProps = {
};

export default Followers;
