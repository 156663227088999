import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  overflow: hidden;
  outline: 0px;
  position: relative;
`;

export default Wrapper;
