import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ResultItem from './ThreadResultItem';

const Results = styled.div`
  background-color: white;
  box-shadow: 1px 1px 5px 0px #aaa;
  max-height: 350px;
  overflow-y: auto;

  @media(max-width: 767px) {
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    width: auto;
    height: calc(100vh - 64px);
    max-height: none;
  }
`;

const UserSearchResults = ({ results }) => (
  <Results>
    {results.map(result => (
      <Link key={`search-thread-${result.slug}`} to={`/+${result.community.slug}/${result.slug}`}>
        <ResultItem data={result} />
      </Link>
    ))}
  </Results>
);

UserSearchResults.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string.isRequired,
    displayname: PropTypes.string.isRequired,
  })).isRequired,
};

UserSearchResults.defaultProps = {
};

export default UserSearchResults;
