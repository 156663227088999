import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useOpenClose, useTranslation } from 'hooks';

import { HelpCircle } from 'components/Icons';
import Modal from 'components/Modal';

import locales from './i18n';

const IconWrapper = styled.span<React.HTMLProps<HTMLSpanElement>>`
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${props => props.theme.colors.secondary};
    }
  }

  &:hover {
    svg path {
      fill: ${props => props.theme.colors.main};
    }
  }
`;
IconWrapper.displayName = 'IconWrapper';

interface Props {
  content: React.ReactNode;
}

const HelpHint: React.FC<Props> = ({ content }) => {
  const { t } = useTranslation(locales);

  const [isModalOpen, openModal, closeModal] = useOpenClose();

  return (
    <>
      <IconWrapper onClick={openModal}><HelpCircle /></IconWrapper>

      {/* Modals */}
      {isModalOpen && (
        <Modal title={t('Help')} onClose={closeModal}>{content}</Modal>
      )}
    </>
  );
};

HelpHint.propTypes = {
  content: PropTypes.node.isRequired,
};

HelpHint.defaultProps = {
};

export default HelpHint;
