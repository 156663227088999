import { useSelector } from 'react-redux';
import { Button, Icon } from '@chakra-ui/react';
import { Coins } from 'lucide-react';

import { useOpenClose } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';

import FundersModal from './FundersModal';

interface Props {
  publicationId: string;
}

const Collected: React.FC<Props> = ({ publicationId }) => {
  const hasFunding = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.hasFunding(state, publicationId),
  );
  const isFundedByMe = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.isFundedByMe(state, publicationId),
  );
  const collected = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.getFundingCollected(state, publicationId),
  );

  const [isModalOpen, openModal, closeModal] = useOpenClose();

  if (!hasFunding) return null;

  return (
    <>
      <Button
        leftIcon={<Icon as={Coins} boxSize="24px" fill={isFundedByMe ? 'brand.500' : 'transparent'} borderColor={isFundedByMe ? 'brand.900' : 'black'} strokeWidth={1} />}
        onClick={openModal}
      >
        {collected > 0 && <span>{(collected % 1) ? collected.toFixed(2) : collected}</span>}
      </Button>

      {isModalOpen && (
        <FundersModal publicationId={publicationId} onClose={closeModal} />
      )}
    </>
  );
};

export default Collected;
