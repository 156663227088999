import { IconDefaults } from './type';
import { IconProps } from './types';

const Img = ({ color }: IconProps) => (
  <svg viewBox="0 0 18 18">
    <path d="M18 16V2C18 0.9 17.1 0 16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16ZM5.5 10.5L8 13.51L11.5 9L16 15H2L5.5 10.5Z" fill={color} />
  </svg>
);

Img.defaultProps = IconDefaults;

export default Img;
