import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';
import * as membershipSelectors from 'state/memberships/selectors';

import CreateCommunity from 'containers/Communities/Create';
import Dashboard from 'containers/Communities/Dashboard';
import NewThread from 'containers/Communities/NewThread';
import Bans from 'containers/Communities/Bans';
import Pending from 'containers/Communities/Pending';
import Edit from 'containers/Communities/Edit';
import Avatar from 'containers/Communities/Avatar';
import Cover from 'containers/Communities/Cover';
import Members from 'containers/Communities/Members';
import Mods from 'containers/Communities/Mods';
import Explore from 'containers/Communities/Explore';
import TransferOwnership from 'containers/Communities/TransferOwnership';
import NotFound from 'containers/NotFound';
import Loading from 'components/Loading';

import AuthedRoute from './AuthedRoute';

const CommunitiesSwitch = ({ location }) => {
  const areMembershipsLoaded = useSelector(membershipSelectors.isLoaded);
  const isUserLoggedIn = useSelector(authSelectors.loggedIn);

  const [previousLocation, setPreviousLocation] = useState(location);

  useEffect(() => {
    if (!location || !location.state || !location.state.dashboard) {
      setPreviousLocation(location);
    }
  }, [location]);

  if (isUserLoggedIn && !areMembershipsLoaded) return <Loading />;

  const isCommunitiesDashboard = !!(
    location
    && location.state
    && location.state.dashboard
    && previousLocation !== location
  );

  return (
    <Switch location={isCommunitiesDashboard ? previousLocation : location}>
      <AuthedRoute
        path="/communities/create"
        component={CreateCommunity}
        loading={!areMembershipsLoaded}
      />
      <Route
        path="/communities"
        exact
        component={Dashboard}
      />
      <Route
        path="/communities/explore"
        component={Explore}
      />
      <Route
        path="/+:communitySlug([^/]+)/new"
        exact
        component={NewThread}
      />
      <Route
        path="/+:communitySlug([^/]+)/bans"
        exact
        component={Bans}
      />
      <Route
        path="/+:communitySlug([^/]+)/pending"
        exact
        component={Pending}
      />
      <Route
        path="/+:communitySlug([^/]+)/edit"
        exact
        component={Edit}
      />
      <Route
        path="/+:communitySlug([^/]+)/avatar"
        exact
        component={Avatar}
      />
      <Route
        path="/+:communitySlug([^/]+)/cover"
        exact
        component={Cover}
      />
      <Route
        path="/+:communitySlug([^/]+)/members"
        exact
        component={Members}
      />
      <Route
        path="/+:communitySlug([^/]+)/mods"
        exact
        component={Mods}
      />
      <Route
        path="/+:communitySlug([^/]+)/transfer"
        exact
        component={TransferOwnership}
      />
      <Route
        path="/+:communitySlug([^/]+)/:threadSlug"
        exact
        component={Dashboard}
      />
      <Route
        path="/+:communitySlug([^/]+)"
        exact
        component={Dashboard}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

CommunitiesSwitch.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      dashboard: PropTypes.bool,
    }),
  }),
};

CommunitiesSwitch.defaultProps = {
  location: null,
};

export default CommunitiesSwitch;
