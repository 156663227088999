import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';
import * as membershipSelectors from 'state/memberships/selectors';
import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import { SelectableListItem } from 'components/SelectableList';
import UserAvatar from 'components/UserAvatar';
import Button from 'components/Button';
import { TrashCan } from 'components/Icons';

import { MEMBERSHIP_ROLES } from '../../../constants';
import locales from './i18n';

const Username = styled.div`
  color: ${colors.grey};
  font-size: 14px;
  margin-top: 2px;
`;
Username.displayName = 'Username';

const Mod = ({
  userId,
  communityId,
  membershipId,
  userRole,
  remove,
  removing,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const myRole = useSelector(state => membershipSelectors.selectUserRole(state, communityId));
  const user = useSelector(userSelectors.getById(userId), shallowEqual);
  if (user.loading) dispatch(userActions.fetchData(user.id));

  const renderAvatar = useCallback(id => () => <UserAvatar userId={id} />, []);
  const onClick = useCallback(() => { remove(membershipId); }, [membershipId, remove]);

  const roleIndex = role => Object.values(MEMBERSHIP_ROLES).indexOf(role);
  const roleIsInferiorThanMine = (
    roleIndex(userRole) > roleIndex(myRole)
  );

  const actions = roleIsInferiorThanMine
    ? [
      <Button onClick={onClick} className="empty" loading={removing}>
        <TrashCan color={colors.red} outline />
      </Button>,
    ]
    : [];

  return (
    <SelectableListItem
      renderAvatar={renderAvatar(userId)}
      title={user.displayname}
      actions={actions}
    >
      <Username>{t(`global:${userRole}`, { context: user.pronoun })}</Username>
    </SelectableListItem>
  );
};

Mod.propTypes = {
  userId: PropTypes.number.isRequired,
  membershipId: PropTypes.string.isRequired,
  communityId: PropTypes.string.isRequired,
  userRole: PropTypes.oneOf(Object.values(MEMBERSHIP_ROLES)).isRequired,
  remove: PropTypes.func.isRequired,
  removing: PropTypes.bool.isRequired,
};

Mod.defaultProps = {
};

export default memo(Mod);
