export const PREPEND_TO_FEED_QUEUE = 'mazmo/feed/PREPEND_TO_FEED_QUEUE';
export const REMOVE_FROM_FEED = 'mazmo/feed/REMOVE_FROM_FEED';

export const ADD_PUBLICATIONS = 'mazmo/feed/ADD_PUBLICATIONS';
export const ADD_COMMENTS = 'mazmo/feed/ADD_COMMENTS';
export const REMOVE_COMMENT = 'mazmo/feed/REMOVE_COMMENT';

export const FLUSH_FEED_QUEUE = 'mazmo/feed/FLUSH_FEED_QUEUE';

export const SET_LATEST_HASHTAGS = 'mazmo/feed/SET_LATEST_HASHTAGS';

export const POLL_UPDATED = 'mazmo/feed/POLL_UPDATED';

export const WIPE = 'mazmo/feed/WIPE';

export const SET_SCROLL = 'mazmo/feed/SET_SCROLL';
