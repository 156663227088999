import ReactGA from 'react-ga4';

import Api from 'state/api';

import {
  LOAD_PERIOD, LOAD_COUPONS, UPDATE_COUPON, DELETE_COUPON,
} from './constants';

export const loadCurrentPeriod = data => (dispatch) => {
  dispatch({ type: LOAD_PERIOD, data });
};

export const loadCoupons = data => (dispatch) => {
  dispatch({ type: LOAD_COUPONS, data });
};

export const acknowledgeCoupon = code => async (dispatch) => {
  const { data } = await Api.req.put(`/bank/coupons/${code}`);
  dispatch({ type: UPDATE_COUPON, data });
};

export const removeCoupon = (codeId) => async (dispatch) => {
  await Api.req.delete(`/bank/coupons/${codeId}`);
  dispatch({ type: DELETE_COUPON, codeId });
};

export const createTransaction = async ({
  to, amount, concept = undefined, data,
}) => {
  const { data: transaction } = await Api.req.post('/bank/transactions', {
    to, amount, concept, data,
  });

  ReactGA.event({
    category: 'Bank',
    action: 'New Transaction',
    label: `Total: ${transaction.amount + transaction.tax}`,
  });

  return transaction;
};

export const createPurchase = async ({ items, payload }) => {
  const { data: transaction } = await Api.req.post('/bank/transactions/purchase', { items, data: payload });

  items.forEach((item) => {
    ReactGA.event({
      category: 'Bank',
      action: 'New Purchase',
      label: item,
    });
  });

  return transaction;
};

export const createOrder = async (itemName, itemData, paymentMethod, coupon) => {
  const { data } = await Api.req.post(
    '/bank/orders',
    {
      itemName, itemData, paymentMethod, coupon,
    },
  );

  ReactGA.event({
    category: 'Bank',
    action: 'New Order',
    label: data.item.name,
    value: data.invoice.total,
  });

  return data;
};

export const capturePaypalOrder = async (orderId) => {
  await Api.req.post(`/bank/orders/paypal/capture/${orderId}`);
};

export const handleCouponUpdated = data => async (dispatch) => {
  dispatch({ type: UPDATE_COUPON, data });
};
