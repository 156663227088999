import { useParams, useLocation } from 'react-router-dom';

import { useOpenClose } from 'hooks';

import { Plus } from 'components/Icons';
import FloatingButton from 'components/Button/Floating';

import SelectCommunityModal from './SelectCommunityModal';

const CreateThreadButton = () => {
  const params = useParams();
  const location = useLocation();

  const [showingModal, openModal, closeModal] = useOpenClose(false);

  const communitySlug = params.communitySlug && !location.search.includes('showAll') ? params.communitySlug : null;

  const props = communitySlug
    ? { to: `/+${communitySlug}/new` }
    : { onClick: openModal };

  return (
    <>
      <FloatingButton {...props}>
        <Plus color="white" />
      </FloatingButton>

      {/* Modals */}
      {showingModal && <SelectCommunityModal onCancel={closeModal} />}
    </>
  );
};

CreateThreadButton.propTypes = {
};

CreateThreadButton.defaultProps = {
};

export default CreateThreadButton;
