import { useTranslation } from 'hooks';

import EmptyState from 'components/EmptyState';
import Ad from 'components/Ad';

import ChecklistWrapper from './Wrapper';

import locales from './i18n';

const NoAccess = () => {
  const { t } = useTranslation(locales);

  return (
    <ChecklistWrapper>
      <EmptyState title={t('You don\'t have access to this checklist')} />

      <Ad id="Page Bottom" />
    </ChecklistWrapper>
  );
};

NoAccess.propTypes = {
};

NoAccess.defaultProps = {
};

export default NoAccess;
