import { useState } from 'react';
import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Heading,
  Box,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import * as eventSelectors from 'state/events/selectors';
import * as eventActions from 'state/events/actions';
import useTranslation from 'hooks/useTranslation';
import useToast from 'hooks/useToast';

import locales from '../../i18n';

interface Props {
  eventId: string;
}

const ButtonEnrollment: React.FC<Props> = ({ eventId }) => {
  const { t } = useTranslation(locales);
  const toast = useToast();
  const dispatch = useDispatch();

  // @ts-ignore
  const enrolled = useSelector(state => eventSelectors.hasEnrolled(state, eventId));

  const [enrolling, setEnrolling] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const enroll = async () => {
    try {
      setEnrolling(true);

      await dispatch(eventActions.enroll(eventId));
      onOpen();
    } catch (error) {
      toast.error(error);
    } finally {
      setEnrolling(false);
    }
  };

  return (
    <>
      {enrolled
        ? <Text color="gray.500" fontSize="sm" fontWeight="bold">{t('You are enrolled, wait for an admin to approve your request')}</Text>
        : <Button variant="primary" isLoading={enrolling} onClick={enroll}>{t('Enroll')}</Button>
      }

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Enrollment')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" size="lg" mb="4">{t('You are now enrolled!')}</Heading>

            <Box color="gray.700">
              <Text>{t('An event admin will review your request and you will be notified once approved')}</Text>
              <Text>{t('Then, you will be able to buy the ticket for the event')}</Text>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>{t('global:Accept')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ButtonEnrollment;
