import styled from 'styled-components';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;

  @media(max-width: 767px) {
    margin-bottom: 16px;
  }
`;
FlexRow.displayName = 'FlexRow';

export default FlexRow;
