import PropTypes from 'prop-types';
import { HStack, Icon, Text } from '@chakra-ui/react';

import { ChevronRight, ChevronDown } from 'components/Icons';

const Toggle = ({ title, expanded, onClick }) => {
  const Chevron = expanded ? ChevronDown : ChevronRight;
  return (
    <HStack onClick={onClick} cursor="pointer" p={3} justifyContent="center">
      <Icon as={Chevron} fill="gray.400" />
      <Text color="gray.400">{title}</Text>
    </HStack>
  );
};

Toggle.propTypes = {
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Toggle;
