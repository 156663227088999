import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import usersToText from 'utils/usersToText';
import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import UserAvatar from 'components/UserAvatar';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const ReactionPublication = ({
  alertId,
  publication,
  authors: authorIds,
  read,
  timestamp,
}) => {
  const { t } = useTranslation(locales);

  // Remove duplications (this should be a service feature in the future)
  const idsSet = new Set();
  authorIds.forEach(id => idsSet.add(id));
  const authorsBatch = useSelector(userSelectors.getListByIds([...idsSet]), shallowEqual);

  const authors = Object.values(authorsBatch).reverse().filter(a => !!a);
  const authorsText = usersToText(authors, t, alertId);

  return (
    <AlertContainer timestamp={timestamp} image={<UserAvatar userId={authors[0].id} size="40px" showOnline={false} />} to={`/publications/${publication.id}`} read={read}>
      {authorsText}
      {' '}
      {t('spanked you a publication', { context: authors.length > 1 && 'PLURAL' })}
      {': '}
      {publication.payload.rawContent}
    </AlertContainer>
  );
};

ReactionPublication.propTypes = {
  alertId: PropTypes.string.isRequired,
  publication: PropTypes.shape({
    id: PropTypes.string.isRequired,
    payload: {
      rawContent: PropTypes.string,
    }.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.number).isRequired,
  read: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default ReactionPublication;
