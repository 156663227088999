import {
  useCallback, useRef, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Api from 'state/api';
import { useTranslation, useOpenClose } from 'hooks';
import * as eventSelectors from 'state/events/selectors';
import * as authSelectors from 'state/auth/selectors';

import Button from 'components/Button';

import ButtonBuy from './ButtonBuy';
import ButtonRSVP from './ButtonRSVP';
import ButtonEnrollment from './ButtonEnrollment';
import Instructions from '../Instructions';
import locales from '../../i18n';

const EventActionButton = ({
  eventId, startListening, stopListening, onlyBuy, size,
}) => {
  const earlyTickets = useSelector(state => (
    eventSelectors.hasEarlyTicketsFeatureEnabled(state, eventId)
  ));
  const requiresEnrollment = useSelector(state => (
    eventSelectors.hasEnrollmentsFeatureEnabled(state, eventId)
  ));
  const enrollmentApproved = useSelector(state => (
    // @ts-ignore
    eventSelectors.hasEnrollmentApproved(state, eventId)
  ));
  // @ts-ignore
  const eventAlreadyStarted = useSelector(state => eventSelectors.eventAlreadyStarted(state, eventId));
  const userId = useSelector(authSelectors.selectId);

  const [hasBeenInvited, setHasBeenInvited] = useState(false);

  useEffect(() => {
    const load = async () => {
      try {
        const invitation = await Api.req.get(`/events/${eventId}/invites/${userId}`);
        if (invitation) setHasBeenInvited(true);
      } catch (error) {
        // Do nothing
      }
    };

    if (earlyTickets && userId) load();
  }, [earlyTickets, eventId, userId]);

  if (eventAlreadyStarted) return null;

  if (earlyTickets && !hasBeenInvited && (!requiresEnrollment || enrollmentApproved)) {
    return (
      <ButtonBuy eventId={eventId} startListening={startListening} stopListening={stopListening} size={size} />
    );
  }

  if (onlyBuy) return null;

  if (requiresEnrollment && !enrollmentApproved) {
    return <ButtonEnrollment eventId={eventId} />;
  }

  return (
    <ButtonRSVP eventId={eventId} startListening={startListening} />
  );
};

EventActionButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  startListening: PropTypes.func.isRequired,
  stopListening: PropTypes.func.isRequired,
  onlyBuy: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['normal', 'big']).isRequired,
};

const ActionButton = ({ eventId, onlyBuy, size }) => {
  const { t } = useTranslation(locales);

  const suggestShare = useRef(false);
  const listening = useRef(false);

  const rsvpd = useSelector(state => eventSelectors.hasRSVPd(state, eventId));
  const [instrModalOpened, openInstructionsModal, closeInstructionsModal] = useOpenClose(false);

  const startListening = useCallback(() => { listening.current = true; }, []);
  const stopListening = useCallback(() => { listening.current = false; }, []);

  const afterAction = useCallback(() => {
    suggestShare.current = true;
    listening.current = false;
    openInstructionsModal();
  }, [openInstructionsModal]);

  useEffect(() => {
    if (rsvpd && listening.current) {
      afterAction();
    }
  }, [rsvpd, afterAction]);

  return (
    <>
      {rsvpd ? (
        <Button onClick={openInstructionsModal} color="white" fontColor="black">{t('Instructions')}</Button>
      ) : (
        <EventActionButton
          eventId={eventId}
          startListening={startListening}
          stopListening={stopListening}
          onlyBuy={onlyBuy}
          size={size}
        />
      )}

      <Instructions
        eventId={eventId}
        onClose={closeInstructionsModal}
        suggestShare={suggestShare.current}
        isOpen={instrModalOpened}
      />
    </>
  );
};

ActionButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  onlyBuy: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'big']),
};

ActionButton.defaultProps = {
  onlyBuy: false,
  size: 'normal',
};

export default ActionButton;
