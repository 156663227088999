import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import { Emoji, emojiIndex } from 'emoji-mart';
import { useDispatch } from 'react-redux';
import CodeMirror from 'codemirror';
import { useBrowser } from 'hooks';

import 'codemirror/lib/codemirror.css';
import 'emoji-mart/css/emoji-mart.css';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/display/placeholder';

import ComposerRef from 'utils/ComposerRef';
import isMobile from 'utils/isMobile';
import * as appActions from 'state/app/actions';
import * as userActions from 'state/users/actions';
import * as communityActions from 'state/communities/actions';
import * as channelActions from 'state/channels/actions';

import Suggestions from './Suggestions';
import SuggestionMention from './SuggestionMention';
import SuggestionCommunity from './SuggestionCommunity';
import SuggestionChannel from './SuggestionChannel';
import Wrapper from './Wrapper';
import customemojis from './customemojis';

window.CodeMirror = CodeMirror;

require('./mfm');
require('./keymap');

const SUGGESTIONS_MAX = 10;

const folders = {
  emoji: (token, codemirror) => {
    const value = token.string;
    const name = value.substr(1, value.length - 2);
    const custom = emojiIndex
      .search(name, { custom: codemirror.getOption('customEmojis') })
      .find(e => e.short_names.includes(name));

    const emoji = custom || value;
    const emojiSize = codemirror.getOption('emojiSize');

    const reactEl = <Emoji emoji={emoji} size={emojiSize} set="apple" fallback={() => token} />;
    const html = ReactDOMServer.renderToStaticMarkup(reactEl);

    const el = document.createElement('span');
    el.innerHTML = html;

    return el;
  },
};

const options = {
  theme: 'mazmo',
  mode: 'mfm',
  autoRefresh: true,
  keyMap: 'mazmo',
  lineWrapping: true,
  inputStyle: 'textarea',
  spellcheck: isMobile,
  autocapitalize: isMobile,
};

const Composer = ({
  id, mentions, communities, channels, emojis, autofocus, placeholder, height, maxHeight, afterSend,
  submitOnEnter, handlePastedFiles, emojiSize, markdown, submitOnCmdReturn, onTypingState,
  onUpKeyPress, onEscKeyPress, onFocus, onBlur, onChange, initialValue,
}) => {
  const dispatch = useDispatch();

  const [suggestions, setSuggestions] = useState(null);
  const element = useRef(null);
  const cm = useRef(null);
  const initialized = useRef(false);
  const hasContent = useRef(false);
  const lastKeystrokeTime = useRef(0);
  const browser = useBrowser();

  const onSuggestionSelect = useCallback(suggestion => () => {
    const { line, start, end } = suggestions;
    cm.current.replaceRange(suggestion.text, { line, ch: start }, { line, ch: end });

    const html = ReactDOMServer.renderToStaticMarkup(suggestion.mark);
    const el = document.createElement('span');
    el.innerHTML = html;

    cm.current.markText(
      { line, ch: start },
      { line, ch: start + suggestion.text.length },
      { replacedWith: el },
    );

    setSuggestions(null);
    cm.current.focus();
  }, [suggestions]);

  useEffect(() => {
    if (element.current && !cm.current) {
      const customEmojis = customemojis(id);
      cm.current = CodeMirror.fromTextArea(element.current, {
        ...options,
        mentions,
        communities,
        channels,
        emojis,
        autofocus,
        placeholder,
        customEmojis,
        emojiSize,
        markdown,
      });
      ComposerRef.setRef(id, cm.current);

      cm.current.on('update', () => {
        if (!initialized.current && autofocus && !isMobile) {
          initialized.current = true;
          cm.current.focus();
        }
      });

      if (onFocus) {
        cm.current.on('focus', onFocus);
      }

      if (onBlur) {
        cm.current.on('blur', onBlur);
      }

      if (onChange) {
        cm.current.on('change', onChange);
      }

      cm.current.on('change', (codemirror) => {
        const value = cm.current.getValue();
        ComposerRef.setValue(id, value);

        if (value.length && !hasContent.current) {
          hasContent.current = true;
          dispatch(appActions.composerHasContent(id, true));
        } else if (!value.length && hasContent.current) {
          hasContent.current = false;
          dispatch(appActions.composerHasContent(id, false));
        }

        if (onTypingState) {
          const isEmpty = value.length === 0;
          const keystrokeReportEnlapsed = (
            (new Date()).getTime() - lastKeystrokeTime.current > (15 * 1000)
          );

          if (!isEmpty) {
            if (keystrokeReportEnlapsed) onTypingState('STARTED');
            lastKeystrokeTime.current = (new Date()).getTime();
          } else {
            if (!keystrokeReportEnlapsed) onTypingState('STOPPED');
            lastKeystrokeTime.current = 0;
          }
        }

        codemirror.eachLine((handle) => {
          const line = codemirror.getLineNumber(handle);
          const tokens = codemirror.getLineTokens(line).filter(t => !!folders[t.type]);

          tokens.forEach((token) => {
            const el = folders[token.type](token, codemirror);
            const lineNo = codemirror.getLineNumber(line);
            const marks = codemirror.findMarks(
              { line: lineNo, ch: token.start },
              { line: lineNo, ch: token.end },
            );

            if (el && !marks.length) {
              cm.current.markText(
                { line, ch: token.start },
                { line, ch: token.end },
                { replacedWith: el },
              );
              setSuggestions(null);
            }
          });
        });
      });

      cm.current.on('cursorActivity', (codemirror) => {
        const cursor = codemirror.getCursor();
        const token = codemirror.getTokenAt(cursor);
        const coords = codemirror.charCoords({ line: cursor.line, ch: token.start }, 'local');
        const lineHeight = codemirror.defaultTextHeight();
        const dimensions = {
          ...codemirror.getScrollInfo(),
          element: cm.current.getWrapperElement(),
        };

        const common = {
          line: cursor.line,
          start: token.start,
          end: token.end,
          coords,
          lineHeight,
          dimensions,
          selectedIndex: 0,
        };

        const hasMarks = codemirror.findMarksAt(cursor).length > 0;

        if (!hasMarks && token.type === 'mention') {
          const results = dispatch(
            userActions.searchByName(token.string.trim().substr(1), SUGGESTIONS_MAX),
          );

          if (!results || !results.length) {
            setSuggestions(null);
          } else {
            const data = results.slice(0, SUGGESTIONS_MAX).map(user => ({
              suggest: <SuggestionMention user={user} />,
              text: `@${user.username}`,
              mark: (
                <span className="mention">
                  <span>@</span>
                  {user.displayname}
                </span>
              ),
            }));

            setSuggestions({
              type: 'mention',
              data,
              ...common,
            });
          }
        } else if (!hasMarks && token.type === 'community') {
          const results = dispatch(
            communityActions.searchByName(token.string.trim().substr(1), SUGGESTIONS_MAX),
          );

          if (!results || !results.length) {
            setSuggestions(null);
          } else {
            const data = results.slice(0, SUGGESTIONS_MAX).map(community => ({
              suggest: <SuggestionCommunity community={community} />,
              text: `+${community.slug}`,
              mark: (
                <span className="community">
                  <span>+</span>
                  {community.name}
                </span>
              ),
            }));

            setSuggestions({
              type: 'community',
              data,
              ...common,
            });
          }
        } else if (!hasMarks && token.type === 'channel') {
          const results = dispatch(
            channelActions.searchByName(token.string.trim().substr(1), SUGGESTIONS_MAX),
          );

          if (!results || !results.length) {
            setSuggestions(null);
          } else {
            const data = results.slice(0, SUGGESTIONS_MAX).map(channel => ({
              suggest: <SuggestionChannel channel={channel} />,
              text: `%${channel.id}`,
              mark: (
                <span className="channel">
                  <span>%</span>
                  {channel.name}
                </span>
              ),
            }));

            setSuggestions({
              type: 'channel',
              data,
              ...common,
            });
          }
        } else if (!hasMarks && token.type === 'partial-emoji') {
          const name = token.string.substr(0, 1) === ':' ? token.string.substr(1) : token.string;
          const results = emojiIndex.search(name, { custom: codemirror.getOption('customEmojis') });

          if (!results || !results.length) {
            setSuggestions(null);
          } else {
            const data = results.slice(0, SUGGESTIONS_MAX).map(emoji => ({
              suggest: (
                <span className="emoji">
                  <Emoji emoji={emoji} size={emojiSize} set="apple" />
                  {' '}
                  {emoji.colons}
                </span>
              ),
              text: emoji.colons,
              mark: <Emoji emoji={emoji} size={emojiSize} set="apple" />,
            }));

            const rest = token.start === 1 && token.string.substr(1, 1) !== ':' ? 1 : 0;
            setSuggestions({
              type: 'emoji',
              data,
              ...common,
              start: token.start - rest, // FIX: conflict with markdown mode
            });
          }
        } else if (!token.type) {
          setSuggestions(null);
        }
      });

      cm.current.on('paste', (codemirror, event) => {
        if (handlePastedFiles && event.clipboardData && event.clipboardData.items) {
          const files = [...event.clipboardData.items]
            .filter(item => item.type.includes('image/'))
            .map(item => item.getAsFile());

          handlePastedFiles(files);
        }
      });
    }
  }, [
    element, cm, emojis, mentions, communities, channels, autofocus, placeholder,
    id, dispatch, handlePastedFiles, emojiSize, markdown, onTypingState, onFocus, onBlur,
    onChange,
  ]);

  useEffect(() => {
    if (initialValue) {
      cm.current.setValue(initialValue);
      cm.current.setCursor(Infinity, initialValue.length);
    }
  }, []);

  const macHotKeys = {
    'Cmd-Enter': async () => {
      if (submitOnCmdReturn) {
        await dispatch(appActions.composerSendToServer(id));
        if (afterSend) afterSend();
      }
    },
  };

  const nonMacHotKeys = {
    'Ctrl-Enter': async () => {
      if (submitOnCmdReturn) {
        await dispatch(appActions.composerSendToServer(id));
        if (afterSend) afterSend();
      }
    },
  };

  const actionKeyHotKeys = browser.os.mac ? macHotKeys : nonMacHotKeys;

  useEffect(() => {
    cm.current.setOption('extraKeys', {
      Down: () => {
        let result = true;
        setSuggestions((currentValue) => {
          if (!currentValue) {
            result = CodeMirror.Pass;
            return currentValue;
          }

          const selectedIndex = typeof currentValue.selectedIndex === 'undefined'
            ? 0
            : (currentValue.selectedIndex + 1) % currentValue.data.length;

          return {
            ...currentValue,
            selectedIndex,
          };
        });

        return result;
      },

      Up: () => {
        if (onUpKeyPress && !hasContent.current) onUpKeyPress();
        let result = true;
        setSuggestions((currentValue) => {
          if (!currentValue) {
            result = CodeMirror.Pass;
            return currentValue;
          }

          const { length } = currentValue.data;
          const selectedIndex = typeof currentValue.selectedIndex === 'undefined'
            ? (length - 1)
            : (currentValue.selectedIndex - 1 + length) % length;

          return {
            ...currentValue,
            selectedIndex,
          };
        });

        return result;
      },

      Enter: () => {
        let result = CodeMirror.Pass;
        if (suggestions && typeof suggestions.selectedIndex !== 'undefined') {
          result = true;
          onSuggestionSelect(suggestions.data[suggestions.selectedIndex])();
        } else if (submitOnEnter) {
          result = true;
          dispatch(appActions.composerSendToServer(id));
          if (afterSend) afterSend();
        }

        return result;
      },

      Tab: () => {
        if (suggestions && typeof suggestions.selectedIndex !== 'undefined') {
          onSuggestionSelect(suggestions.data[suggestions.selectedIndex])();
        }
      },

      Esc: () => {
        if (onEscKeyPress) onEscKeyPress();
        if (suggestions) {
          setSuggestions(null);
        }
      },
      ...actionKeyHotKeys,
    });
  }, [onSuggestionSelect, suggestions, submitOnEnter, id, dispatch, afterSend, submitOnCmdReturn,
    onUpKeyPress, onEscKeyPress, actionKeyHotKeys]);

  return (
    <Wrapper height={height} maxHeight={maxHeight}>
      <textarea ref={element} />

      {suggestions && (
        <Suggestions
          data={suggestions.data}
          selectedIndex={suggestions.selectedIndex}
          coords={suggestions.coords}
          lineHeight={suggestions.lineHeight}
          dimensions={suggestions.dimensions}
          onSelect={onSuggestionSelect}
        />
      )}
    </Wrapper>
  );
};

Composer.propTypes = {
  id: PropTypes.string.isRequired,
  mentions: PropTypes.bool,
  communities: PropTypes.bool,
  channels: PropTypes.bool,
  emojis: PropTypes.bool,
  autofocus: PropTypes.bool,
  placeholder: PropTypes.string,
  height: PropTypes.string,
  maxHeight: PropTypes.string,
  submitOnEnter: PropTypes.bool,
  handlePastedFiles: PropTypes.func,
  emojiSize: PropTypes.number,
  markdown: PropTypes.bool,
  afterSend: PropTypes.func,
  submitOnCmdReturn: PropTypes.bool,
  initialValue: PropTypes.string,
  onTypingState: PropTypes.func,
  onUpKeyPress: PropTypes.func,
  onEscKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

Composer.defaultProps = {
  mentions: true,
  communities: true,
  channels: true,
  emojis: true,
  autofocus: false,
  placeholder: null,
  height: null,
  maxHeight: null,
  submitOnEnter: false,
  handlePastedFiles: null,
  emojiSize: 20,
  markdown: true,
  afterSend: null,
  submitOnCmdReturn: true,
  initialValue: undefined,
  onTypingState: null,
  onUpKeyPress: null,
  onEscKeyPress: null,
  onFocus: null,
  onBlur: null,
  onChange: null,
};

export default Composer;
