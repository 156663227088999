import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import useOpenClose from 'hooks/useOpenClose';
import colors from 'utils/css/colors';

import OptionsCaret from './OptionsCaret';
import MoreOptions from './MoreOptions';


const TextOnHover = styled.span`
  display: none;
`;

interface MainContentProps extends React.HTMLProps<HTMLDivElement> {
  isLink?: boolean;
}

const MainContent = styled.div<MainContentProps>`
  ${(props: any) => !props.isLink && `
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
  `}

  > div {
    margin: 0 auto;
  }
`;

const Loading = styled.div`
  display: inline-block;
  width: 40px !important;
  height: 20px;

  &:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 1px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface GroupOption {
  label: string;
  value: string;
  icon: React.ReactNode;
  selected: boolean;
}

interface ButtonComponentProps {
  children?: React.ReactNode;
  className?: string;
  color?: string;
  content?: React.ReactNode;
  disabled?: boolean;
  fontColor?: string;
  full?: boolean;
  groupOptions?: GroupOption[];
  hasIcon?: boolean;
  hasMoreOptions?: boolean;
  light?: boolean;
  loading?: boolean;
  ontColor?: string;
  size?: string;
  style?: React.CSSProperties;
  textonhover?: string;
  to?: string;
}

export const ButtonComponent = styled.button.attrs((props: ButtonComponentProps) => ({
  disabled: props.loading || props.disabled,
  type: 'button',
  children: props.content ? props.content : props.children,
  className: props.className,
  loading: props.loading ? 'true' : undefined,
  color: props.color || colors.red,
  fontColor: props.fontColor || 'white',
}))`
  ${(props: ButtonComponentProps) => `
    color: ${(props.loading ? '#aaa' : props.fontColor)};
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    white-space: nowrap;
    text-transform: uppercase;
    text-align: center;
    height: 35px;
    width: ${(props.full ? '100%' : 'auto')};

    ${!props.light && `
      background-color: ${props.disabled ? 'grey' : props.color};
      padding: 4px 24px;
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
      border-radius: 40px;
    `}
    ${props.light && `
      background-color: transparent;
    `}
    ${props.groupOptions && `
      padding-right: 4px;
    `}

    ${props.size === 'big' && `
      font-size: 24px;
      height: 60px;
      max-width: 100%;

      @media(max-width: 767px) {
        font-size: 20px;
        height: 52px;
      }
    `}

    ${props.hasIcon && !props.loading && `
      position: relative;
      padding-left: 40px;
    `}

    ${props.hasMoreOptions && `
      position: relative;
      padding-right: 52px;
    `}

    border: 0;
    user-select: none;
    transition: all 250ms ease-out;
    cursor: pointer;
    text-decoration: none;

    ${props.to && `
      display: inline-block;
    `}

    &:focus {
      outline: none;
    }

    &:hover {
      ${!props.light && `
        box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.20);
      `}

      ${TextOnHover} {
        display: ${(props.textonhover ? 'flex' : 'none')};
      }
      ${MainContent} {
        display: ${(props.textonhover ? 'none' : 'flex')};
      }
    }

    &.border {
      color: ${props.color};
      background-color: white;
      border: 1px solid ${props.color};

      &:hover {
        color: white;
        background-color: ${props.color};
      }
    }

    &.empty {
      color: ${(props.fontColor === 'white' ? colors.grey : props.fontColor)};
      background-color: transparent;
      box-shadow: none !important;
      padding: 0;

      ${props.loading && `
        svg {
          display: none;
        }
      `}

      ${Loading} {
        &:after {
          border: 3px solid #999;
          border-color: #999 transparent #999 transparent;
        }
      }

      &:hover {
        color: ${colors.red};
        transform: none;
      }
    }

    &.danger {
      color: ${colors.red};
    }

    &.small {
      font-size: 14px;
    }

    &.round {
      line-height: 23px;
      padding: 0;
      width: 40px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &:focus {
      outline: none;
    }

    svg {
      width: 32px;
      height: 32px;
    }

    .icon {
      position: absolute;
      left: 12px;
      top: 6px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  `}
`;

interface Props extends ButtonComponentProps {
  children?: React.ReactNode;
  className?: string;
  color?: string;
  content?: React.ReactNode;
  disabled?: boolean;
  fontColor?: string;
  groupOptionChange?: (option: string) => void;
  icon?: React.ReactNode;
  light?: boolean;
  loading?: boolean;
  onClick?: () => void;
  onMoreOptions?: () => void;
  size?: 'normal' | 'big';
  textOnHover?: string;
  to?: string;
}

const Button: React.FC<Props> = ({
  to,
  loading,
  children,
  content,
  textOnHover,
  groupOptionChange,
  onMoreOptions,
  icon,
  ...props
}) => {
  const { disabled, groupOptions, color } = props;
  const [menuOpen, openMenu, closeMenu] = useOpenClose();

  const hasMoreOptions = !!onMoreOptions || !!groupOptions;
  const activeIcon = groupOptions ? groupOptions.find(o => o.selected)?.icon : icon;

  const Content: React.FC = () => (
    <>
      <span className="icon">{!loading && activeIcon}</span>
      <MainContent isLink={!!to}>
        {loading && <Loading />}
        <div>{(content || children)}</div>
        {hasMoreOptions && (
          <MoreOptions onClick={onMoreOptions || openMenu} disabled={!!disabled} negative={color === 'white'} />
        )}
        {menuOpen && (
          <OptionsCaret
            options={groupOptions}
            onChange={groupOptionChange}
            close={closeMenu}
          />
        )}
      </MainContent>
      <TextOnHover>{textOnHover}</TextOnHover>
    </>
  );

  if (!to) {
    return (
      <ButtonComponent
        loading={loading}
        textonhover={textOnHover}
        hasIcon={!!activeIcon}
        hasMoreOptions={hasMoreOptions}
        {...props}
      >
        <Content />
      </ButtonComponent>
    );
  }

  if (to.startsWith('http')) {
    return (
      <a href={to}>
        <ButtonComponent
          to={to}
          textonhover={textOnHover}
          hasIcon={!!activeIcon}
          hasMoreOptions={hasMoreOptions}
          {...props}
        >
          <Content />
        </ButtonComponent>
      </a>
    );
  }

  return (
    <Link to={to}>
      <ButtonComponent
        to={to}
        textonhover={textOnHover}
        hasIcon={!!activeIcon}
        hasMoreOptions={hasMoreOptions}
        {...props}
      >
        <Content />
      </ButtonComponent>
    </Link>
  );
};

export default Button;
