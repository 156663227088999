import PropTypes from 'prop-types';
import styled from 'styled-components';

const WarningWrapper = styled.div`
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: .25rem;
  margin: 16px 0px;
  padding: 8px;

  ${props => props.fullMargin && `
    margin: 16px;
  `}

  .actions {
    margin-top: 16px;

    > a:not(:last-child), button:not(:last-child) {
      margin-right: 16px;
    }
  }
`;
WarningWrapper.displayName = 'WarningWrapper';

const Warning = ({ children, fullMargin }) => (
  <WarningWrapper fullMargin={fullMargin}>
    {children}
  </WarningWrapper>
);

Warning.propTypes = {
  children: PropTypes.node.isRequired,
  fullMargin: PropTypes.bool,
};

Warning.defaultProps = {
  fullMargin: false,
};

export default Warning;
