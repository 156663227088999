import styled from 'styled-components';

import colors from 'utils/css/colors';

const ActionIcon = styled.div`
  color: ${colors.red};
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  line-height: 24px;
  height: 24px;
  margin: 0 2px;
  text-align: center;
  text-transform: uppercase;

  ${props => props.separate && `
    margin-right: 16px;
  `}

  svg {
    width: 24px;
    height: 24px;

    ${props => props.animate && `
      &:hover {
        animation: spankIt 500ms ease-out infinite;
      }
    `}

    @keyframes spankIt {
      0%, 100% {
        transform: skew(0deg);
      }
      50% {
        transform: skew(15deg);
      }
    }
  }

  @media(max-width: 767px) {
    font-size: 14px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export default ActionIcon;
