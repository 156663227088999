import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';

import ParsedContent from 'components/ParsedContent';

const Message = ({ messageId }) => {
  const content = useSelector(state => channelSelectors.getMessageContent(state, messageId));

  return <ParsedContent content={content} disallowed={['h1', 'h2', 'h3', 'h4', 'h5', 'h6']} emojiOnlySize={40} />;
};

Message.propTypes = {
  messageId: PropTypes.string.isRequired,
};

Message.defaultProps = {
};

export default Message;
