import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';
import * as authSelectors from 'state/auth/selectors';

import locales from './i18n';

const FollowingMeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FollowingMe = styled.span`
  font-size: 12px;

  background-color: #ffffff66;
  color: ${props => props.theme.colors.mainLight};
  padding: 4px 8px;
  border-radius: 24px;
`;

const UserFollowsMe = ({ userId }) => {
  const { t } = useTranslation(locales);

  const myUserId = useSelector(authSelectors.selectUserId);
  const isFollowingMe = useSelector(userSelectors.isFollowingMe(userId));


  if (!userId || userId === myUserId) return null;

  return (
    isFollowingMe
      ? <FollowingMeWrapper><FollowingMe>{t('is following me')}</FollowingMe></FollowingMeWrapper>
      : null
  );
};

UserFollowsMe.propTypes = {
  userId: PropTypes.number.isRequired,
};

UserFollowsMe.defaultProps = {
};

export default UserFollowsMe;
