import { Button, Text } from '@chakra-ui/react';

interface Props {
  label: string;
  value: number | string;
  onClick?: () => void;
}

const Counter: React.FC<Props> = ({ label, value, onClick }) => (
  <Button
    flexDirection="column"
    variant="ghost"
    flex={1}
    onClick={onClick}
    _hover={{
      md: { transform: 'scale(1.2)' },
    }}
    _active={{
      background: 'none',
    }}
  >
    <Text fontSize={{ base: 20, md: 24 }} fontWeight={500} color="white">{value}</Text>
    <Text textTransform="uppercase" fontSize={{ base: 10, md: 12 }} color="gray.400" fontWeight={400}>{label}</Text>
  </Button>
);

export default Counter;
