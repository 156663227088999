import PropTypes from 'prop-types';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';

import Loading from 'components/Loading';

const AuthedRoute = ({ component: Component, loading, ...rest }) => {
  const loggedIn = useSelector(authSelectors.loggedIn, shallowEqual);

  if (loading) return <Loading />;

  const path = document.location.pathname;
  const redirect = path !== '/logout' ? `?redirect=${path}` : '';

  return (
    <Route
      {...rest}
      render={props => (
        loggedIn
          ? <Component {...props} />
          : <Redirect to={`/login${redirect}`} />
      )}
    />
  );
};

AuthedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  loading: PropTypes.bool,
};

AuthedRoute.defaultProps = {
  loading: false,
};

export default AuthedRoute;
