import { memo } from 'react';
import styled from 'styled-components';

import Spinner from 'components/Spinner';

const Container = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const LoadingMore = memo(() => (
  <Container>
    <Spinner color="#999" />
  </Container>
));

LoadingMore.propTypes = {
};

export default LoadingMore;
