import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

interface Props {
  placeholder?: string;
}

const Filter = styled.input.attrs({
  type: 'text',
})<Props>`
  background-color: transparent;
  font-weight: bold;
  font-size: 14px;
  line-height: 36px;
  width: 100%;
  height: 36px;
  padding: 0 16px 0 0;
  border: 0;
  box-sizing: border-box;
  flex-grow: 1;
  -webkit-appearance: none;

  &:focus {
    outline: 0;
  }
  &::placeholder {
    color: #A8A8A8;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
  }
`;

Filter.propTypes = {
  placeholder: PropTypes.string,
};

Filter.defaultProps = {
  placeholder: 'Search',
};

export default memo(Filter);
