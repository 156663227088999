import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as messengerSelectors from 'state/messengers/selectors';
import { mediaEqual, mediaGifEqual } from 'state/app/equalityFunctions';

import ContentMedia from 'components/ContentMedia';

import Message from './Message';
import InReplyTo from './InReplyTo';
import Audio from './Audio';
import MessageWrapper from '../Message';
import Gif from '../../Gif';
import LinkPublication from './LinkPublication';

const Content = ({ messengerId, messageId }) => {
  const isEmojiOnly = useSelector(state => messengerSelectors.isMessageEmojiOnly(state, messageId));
  const gif = useSelector(
    state => messengerSelectors.getMessageGif(state, messageId),
    mediaGifEqual,
  );
  const media = useSelector(
    state => messengerSelectors.getMessageMedia(state, messageId),
    mediaEqual,
  );
  const inReplyTo = useSelector(state => messengerSelectors.getReplyingTo(state, messageId));
  const hasAudio = useSelector(state => messengerSelectors.messageHasAudio(state, messageId));
  // @ts-ignore
  const publication = useSelector(state => messengerSelectors.messagePublication(state, messageId));

  return (
    <MessageWrapper big={isEmojiOnly} hasAudio={hasAudio}>
      {inReplyTo && <InReplyTo messengerId={messengerId} messageId={inReplyTo} />}
      {publication && <LinkPublication publication={publication} />}
      {gif
        ? <Gif data={gif} />
        : (
          <>
            <ContentMedia media={media} />
            {hasAudio && <Audio messageId={messageId} />}
            <Message messageId={messageId} />
          </>
        )
      }
    </MessageWrapper>
  );
};

Content.propTypes = {
  messengerId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
};

Content.defaultProps = {
};

export default Content;
