import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Api from 'state/api';
import * as channelSelectors from 'state/channels/selectors';

import Busy from 'components/UserDisplayName/Busy';

const Percent = styled.span`
  color: #aaa;
`;
Percent.displayName = 'Percent';

const Channel = ({ value }) => {
  const dispatch = useDispatch();

  const channelId = value.trim().substr(1);

  const initialName = useSelector(state => channelSelectors.getName(state, channelId));
  const [name, setName] = useState(initialName);

  const [error, setError] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await Api.req.get(`/chat/channels/${channelId}`);
        setName(data.name);
      } catch (e) {
        setError(true);
      }
    };

    if (!name) fetch();
  }, [dispatch, channelId, name]);

  const renderContent = () => {
    if (error) return value.trim();
    if (!name) return <Busy />;

    return (
      <Link to={`/chat/channels/${channelId}`} className="channellink">
        <Percent>%</Percent>
        {name}
      </Link>
    );
  };

  return (
    <>
      {value.substr(0, 1) === ' ' && ' '}
      {renderContent()}
    </>
  );
};
Channel.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Channel;
