import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import * as userActions from 'state/users/actions';
import { useTranslation } from 'hooks';
import CommunityType from 'state/communities/type';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const CommunityInvite = ({
  community, author: authorId, read, timestamp,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const author = typeof authorId === 'number' ? dispatch(userActions.fetchData(authorId)) : authorId;

  return (
    <AlertContainer timestamp={timestamp} image={community.avatar} to={`/+${community.slug}`} read={read}>
      {t('community.invite', { user: author.displayname, community: community.name })}
    </AlertContainer>
  );
};

CommunityInvite.propTypes = {
  community: CommunityType.isRequired,
  author: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
  read: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default CommunityInvite;
