import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';
import * as replySelectors from 'state/replies/selectors';
import * as replyActions from 'state/replies/actions';
import * as threadSelectors from 'state/threads/selectors';
import * as threadActions from 'state/threads/actions';

import Hoverable from 'components/Hoverable';
import { ThumbDown, Spank } from 'components/Icons';

import AddAward from './Awards/AddButton';
import ActionIcon from './ActionIcon';
import Share from './Share';
import Awards from './Awards';
import locales from './i18n';

const ActionWrapper = styled.div`
  flex-shrink: 0;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 16px 12px;

  @media(max-width: 767px) {
    margin: 24px 0 12px;

    ${props => (props.eType === 'reply') && `
      margin: 16px 0 8px;
    `}
  }

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media(max-width: 767px) {
      flex-direction: column;
    }

    > div {
      display: flex;
      flex: 1;

      &:last-child {
        justify-content: flex-end;

        > div {
          @media(min-width: 768px) {
            margin-left: 24px;
          }
        }
      }

      @media(max-width: 767px) {
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      > div {
        display: flex;
        align-items: center;

        @media(max-width: 767px) {
          margin-left: 0;
          flex: 1;
          flex-direction: column;
        }
      }
    }

  }

  ${props => (props.eType === 'reply') && `
    ${ActionIcon} {
      svg {
        width: 16px;
        height: 16px;
      }

      span {
        font-size: 16px;
      }

      @media(max-width: 767px) {
        line-height: 14px;
        height: 14px;

        span {
          font-size: 12px;
        }
      }
    }
  `}
`;

const Actions = ({ entityId, type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const selectors = type === 'reply' ? replySelectors : threadSelectors;
  const actions = type === 'reply' ? replyActions : threadActions;

  const isLikedByUser = useSelector(selectors.isLikedByUser(entityId));
  const isDislikedByUser = useSelector(selectors.isDislikedByUser(entityId));
  const likesCount = useSelector(selectors.getLikesCount(entityId));
  const dislikesCount = useSelector(selectors.getDislikesCount(entityId));
  const isDeleted = useSelector(selectors.isDeleted(entityId));

  const [liking, setLiking] = useState(false);
  const [unliking, setUnliking] = useState(false);
  const [disliking, setDisliking] = useState(false);
  const [undisliking, setUndisliking] = useState(false);

  const like = async () => {
    try {
      setLiking(true);
      await dispatch(actions.createReaction(entityId));
      setLiking(false);
    } catch (e) {
      setLiking(false);
    }
  };

  const unlike = async () => {
    try {
      setUnliking(true);
      await dispatch(actions.deleteReaction(entityId));
      setUnliking(false);
    } catch (e) {
      setUnliking(false);
    }
  };

  const dislike = async () => {
    try {
      setDisliking(true);
      await dispatch(actions.createDislike(entityId));
      setDisliking(false);
    } catch (e) {
      setDisliking(false);
    }
  };

  const undislike = async () => {
    try {
      setUndisliking(true);
      await dispatch(actions.deleteDislike(entityId));
      setUndisliking(false);
    } catch (e) {
      setUndisliking(false);
    }
  };

  if (isDeleted) return null;

  const unliked = (!isLikedByUser && !liking) || unliking;
  const undisliked = (!isDislikedByUser && !disliking) || undisliking;
  const onLikeClick = unliked ? like : unlike;
  const onDislikeClick = undisliked ? dislike : undislike;

  return (
    <ActionsWrapper eType={type}>
      <div>
        <div>
          <Awards type={type} entityId={entityId} />
        </div>

        <div>
          <ActionWrapper>
            <AddAward type={type} entityId={entityId} />
          </ActionWrapper>

          <ActionWrapper>
            <ActionIcon onClick={onLikeClick} animate>
              <Hoverable
                normal={<Spank color={colors.red} outline={unliked} />}
                hover={<Spank color={colors.red} outline={!unliked} />}
              />
            </ActionIcon>
            <ActionIcon onClick={onLikeClick}>
              <span>{t('global:Spank')}</span>
              {likesCount > 0 && <span>{` • ${likesCount}`}</span>}
            </ActionIcon>
          </ActionWrapper>

          <ActionWrapper>
            <ActionIcon onClick={onDislikeClick}>
              <Hoverable
                normal={<ThumbDown color={colors.red} outline={undisliked} />}
                hover={<ThumbDown color={colors.red} outline={!undisliked} />}
              />
            </ActionIcon>
            <ActionIcon onClick={onDislikeClick}>
              {dislikesCount > 0 && <span>{dislikesCount}</span>}
            </ActionIcon>
          </ActionWrapper>

          {type === 'thread' && (
            <ActionWrapper>
              <Share threadId={entityId} />
            </ActionWrapper>
          )}
        </div>
      </div>
    </ActionsWrapper>
  );
};

Actions.propTypes = {
  entityId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['thread', 'reply']).isRequired,
};

Actions.defaultProps = {
};

export default Actions;
