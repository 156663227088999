import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as threadSelectors from 'state/threads/selectors';

import Loading from 'containers/Communities/ThreadList/Loading';
import Overflow from 'components/Layout/Overflow';

import Box from '../Box';
import Thread from './Thread';
import locales from '../i18n';

const List = styled.ul`
  margin: 0;
  padding: 0;
`;
List.displayName = 'List';

const LastThreads = () => {
  const { t } = useTranslation(locales);
  const threads = useSelector(threadSelectors.getList(null), shallowEqual);
  const isLoading = useSelector(threadSelectors.isLoading(null));

  return (
    <Box title={t('global:Last Threads')}>
      <Overflow>
        <List>
          {threads.map(threadId => (
            <Thread
              key={`sidebar-thread-list-${threadId}`}
              threadId={threadId}
            />
          ))}
          {isLoading && <Loading />}
        </List>
      </Overflow>
    </Box>
  );
};

LastThreads.propTypes = {
};

LastThreads.defaultProps = {
};

export default LastThreads;
