import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'state/users/actions';

const useDebouncedUserFetching = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const DEBOUNCE_SECONDS = 2;
    const debounceTimer = setInterval(() => {
      dispatch(userActions.fetchFromQueue());
    }, DEBOUNCE_SECONDS * 1000);

    return () => clearInterval(debounceTimer);
  }, [dispatch]);
};

export default useDebouncedUserFetching;
