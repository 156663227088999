import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as replySelectors from 'state/replies/selectors';
import * as threadSelectors from 'state/threads/selectors';
import * as feedSelectors from 'state/feed/selectors';

import Modal from 'components/Modal';
import { Spank, ThumbDown } from 'components/Icons';
import UserLink from 'components/UserLink';
import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import SpankListWrapper from './SpankListWrapper';
import locales from './i18n';

const getSelector = (type) => {
  switch (type) {
    case 'publication':
      return feedSelectors.publications;
    case 'comment':
      return feedSelectors.comments;
    case 'reply':
      return replySelectors;
    case 'thread':
    default:
      return threadSelectors;
  }
};

const SpankListModal = ({ close, entityId, type }) => {
  const { t } = useTranslation(locales);

  const selector = getSelector(type);
  let spanks = useSelector(state => selector.selectReactionsList(state, entityId), shallowEqual);
  let dislikes = useSelector(state => selector.selectDislikesList(state, entityId), shallowEqual);

  // Array.reverse() modifica el valor del array, por lo que cada vez que se muestra el modal el
  // orden de la lista se modifica, lo cual confunde al user si abre el modal mas de una vez sin
  // recargar la publicación, slice() crea una copia del array sobre el cual se aplica reverse()
  // sin modificar el array guardado en el store
  if (spanks.length) {
    spanks = spanks.slice().reverse();
  }
  if (dislikes.length) {
    dislikes = dislikes.slice().reverse();
  }

  return (
    <Modal
      title={t('global:Reactions')}
      onClose={close}
    >
      <SpankListWrapper>
        <div>
          <div className="reaction-type">
            <Spank />
          </div>
          <div className="reactioners">
            {spanks.map(userId => (
              <UserLink userId={userId} key={`spank-${entityId}-${userId}`}>
                <UserAvatar size="32px" userId={userId} />
                <UserDisplayName userId={userId} />
              </UserLink>
            ))}
            {!spanks.length && (
              <div className="empty-list">{t('Empty list')}</div>
            )}
          </div>
        </div>

        {['thread', 'reply'].includes(type) && (
          <div>
            <div className="reaction-type">
              <ThumbDown />
            </div>
            <div className="reactioners">
              {dislikes.map(userId => (
                <UserLink userId={userId} key={`dislike-${entityId}-${userId}`}>
                  <UserAvatar size="32px" userId={userId} />
                  <UserDisplayName userId={userId} />
                </UserLink>
              ))}
              {!dislikes.length && (
                <div className="empty-list">{t('Empty list')}</div>
              )}
            </div>
          </div>
        )}
      </SpankListWrapper>
    </Modal>
  );
};

SpankListModal.propTypes = {
  close: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['thread', 'reply', 'publication', 'comment']).isRequired,
};

export default withRouter(SpankListModal);
