import { memo } from 'react';
import { chakra } from '@chakra-ui/react';

import { IconType, IconDefaults } from './type';

const AccountDetails = memo(({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path d="M7.5,15C8.63,15 9.82,15.26 11.09,15.77C12.35,16.29 13,16.95 13,17.77V20H2V17.77C2,16.95 2.65,16.29 3.91,15.77C5.18,15.26 6.38,15 7.5,15M13,13H22V15H13V13M13,9H22V11H13V9M13,5H22V7H13V5M7.5,8A2.5,2.5 0 0,1 10,10.5A2.5,2.5 0 0,1 7.5,13A2.5,2.5 0 0,1 5,10.5A2.5,2.5 0 0,1 7.5,8Z" />
  </svg>
));

AccountDetails.propTypes = IconType;
AccountDetails.defaultProps = IconDefaults;

export default chakra(AccountDetails);
