import { useRef, useEffect, useState } from 'react';
import fastdom from 'fastdom';

const useElementHeight = () => {
  const selectableEl = useRef<HTMLElement>(null);
  const [elHeight, setElHeight] = useState(0);

  useEffect(() => {
    fastdom.measure(() => {
      if (selectableEl.current) {
        setElHeight(selectableEl.current.clientHeight);
      }
    });
  }, [selectableEl]);

  return [elHeight, selectableEl];
};

export default useElementHeight;
