import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const Send = memo(({ color }) => (
  <svg viewBox="0 0 24 24">
    <path
      d="M23.5029 11.161L1.21768 0.0832369C0.862841 -0.0903207 0.439421 0.0130744 0.192566 0.341724C-0.0559995 0.670357 -0.0645645 1.13747 0.172001 1.47533L7.50039 11.9991L0.172001 22.5231C-0.0645645 22.8609 -0.0559989 23.3299 0.190856 23.6567C0.357131 23.88 0.605696 24 0.857696 24C0.979406 24 1.10112 23.9724 1.21597 23.9152L23.5011 12.8374C23.8064 12.686 24 12.3592 24 11.9991C24 11.6392 23.8063 11.3123 23.5029 11.161Z"
      fill={color}
    />
  </svg>
));

Send.propTypes = IconType;
Send.defaultProps = IconDefaults;

export default Send;
