const formatNumber = (number) => {
  if (!number) return '0';
  if (number < 1000) return String(number);
  if (number < 100000) return `${Math.floor(number / 100) / 10}K`;
  if (number < 1000000) return `${Math.floor(number / 1000)}K`;

  return `${Math.floor(number / 100000) / 10}M`;
};

export default formatNumber;
