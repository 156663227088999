const specials = [
  'Mazmo',
  'Amos',
  'Amas',
  'BDSM',
  { s: 'BDMS', r: 'BDSM' },
];

const replaceRegex = specials.map((s) => {
  if (typeof s === 'object') return { r: new RegExp(`\\b${s.s}\\b`, 'gi'), s: s.r };
  return { r: new RegExp(`\\b${s}\\b`, 'gi'), s };
});

const title = (str) => {
  let result = str.toLowerCase().replace(/([^ \r\n][^!?.\r\n]+[\w!?.]+)/gu, (match) => {
    const words = match.split(' ');
    const firstWord = words[0].replace(/[A-zÀ-ú]/u, m => m.toUpperCase());

    let sentence = [firstWord, ...words.slice(1)].join(' ');

    // Open and/or close exclamation and question marks
    if (sentence.includes('¿') && !sentence.includes('?')) sentence += '?';
    if (sentence.includes('¡') && !sentence.includes('!')) sentence += '!';
    if (sentence.includes('?') && !sentence.includes('¿')) sentence = `¿${sentence}`;
    if (sentence.includes('!') && !sentence.includes('¡')) sentence = `¡${sentence}`;

    // Remove repeating signs
    sentence = sentence.replace(/(\?)\1+/g, () => '?');
    sentence = sentence.replace(/(¿)\1+/g, () => '¿');
    sentence = sentence.replace(/(!)\1+/g, () => '!');
    sentence = sentence.replace(/(¡)\1+/g, () => '¡');
    sentence = sentence.replace(/(,)\1+/g, () => ',');

    replaceRegex.forEach(({ r, s }) => {
      sentence = sentence.replace(r, s);
    });

    return sentence;
  });

  // Replace more than 3 dots into 3
  result = result.replace(/(\.){4,}/g, () => '...');

  // Replace more than 1 commas into 1
  result = result.replace(/(,){1,}/g, () => ',');

  // Remove trailing periods if not ellipsis
  if (result[result.length - 1] === '.' && (result[result.length - 2] !== '.' || result[result.length - 3] !== '.')) {
    while (result[result.length - 1] === '.') {
      result = result.slice(0, -1);
    }
  }

  // Remove trailing commas
  while (result[result.length - 1] === ',') {
    result = result.slice(0, -1);
  }

  // Remove multiple spaces
  result = result.replace(/ +(?= )/g, '');

  // Remove spaces before punctuation
  result = result.replace(/\s+(\.|,|!|\?)/g, '$1');

  // Replace common ortographic errors
  result = result.replace(/(¿Que)\s/g, '¿Qué ');
  result = result.replace(/(¿Como)\s/g, '¿Cómo ');
  result = result.replace(/(¿Donde)\s/g, '¿Dónde ');

  return result;
};

export default title;
