import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as userActions from 'state/users/actions';
import * as authSelectors from 'state/auth/selectors';
import * as messengerSelectors from 'state/messengers/selectors';

import { useTranslation } from 'hooks';

import { SelectableListContainer, SelectableListToggle } from 'components/SelectableList';
import Overflow from 'components/Layout/Overflow';

import Request from './Request';
import locales from '../i18n';

const Requests = ({ filter }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const meId = useSelector(authSelectors.selectId);
  const myMessengers = useSelector(messengerSelectors.selectRequested, shallowEqual);

  const [showingRejected, setShowingRejected] = useState(false);

  const toggleShowRejected = () => setShowingRejected(state => !state);

  const sortAndFilter = messengers => messengers
    .filter((m) => {
      const participants = m.participants.filter((p) => {
        if (p.userId === meId) return false;
        const user = dispatch(userActions.fetchData(p.userId));
        return user.username.includes(filter) || user.displayname.includes(filter);
      });

      return participants.length > 0;
    })
    .sort((a) => {
      if (a.approved) return 1;
      return -1;
    });

  const rejectedMessengers = sortAndFilter(myMessengers.filter(m => m.rejected || m.blocked));
  const filteredMessengers = sortAndFilter(myMessengers.filter(m => !m.rejected && !m.blocked));

  return (
    <SelectableListContainer>
      <Overflow>
        <ul>
          {filteredMessengers.map((messenger) => {
            const { userId } = messenger.participants.filter(p => p.userId !== meId)[0];
            return (
              <Request
                key={`request-${messenger.id}`}
                messengerId={messenger.id}
                userId={userId}
              />
            );
          })}
          {rejectedMessengers.length > 0 && (
            <SelectableListToggle
              title={t('rejected')}
              expanded={showingRejected}
              onClick={toggleShowRejected}
            />
          )}
          {showingRejected && rejectedMessengers.map((messenger) => {
            const { userId } = messenger.participants.filter(p => p.userId !== meId)[0];
            return (
              <Request
                key={`request-${messenger.id}`}
                messengerId={messenger.id}
                userId={userId}
                rejected
              />
            );
          })}
        </ul>
      </Overflow>
    </SelectableListContainer>
  );
};

Requests.propTypes = {
  filter: PropTypes.string,
};

Requests.defaultProps = {
  filter: '',
};

export default Requests;
