import styled from 'styled-components';

const ComposerWrapper = styled.div`
  background: white;
  position: relative;
  width: 100%;
  margin-left: 8px;
  padding: 4px 8px;

  ${props => props.disabled && `
    background: ${props.theme.colors.disabledBackground};

    .CodeMirror {
      background: ${props.theme.colors.disabledBackground};
    }
  `}
`;
ComposerWrapper.displayName = 'AddCommentComposerWrapper';

export default ComposerWrapper;
