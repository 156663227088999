import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';

import EmptyState from 'components/EmptyState';
import Header from 'components/Header';
import {
  FlexWrapper,
  FlexInnerWrapper,
  FlexContainer,
} from 'components/FlexWrapper';

import locales from './i18n';

const NotFound = () => {
  const { t } = useTranslation(locales);

  return (
    <FlexWrapper>
      <FlexInnerWrapper>
        <Header
          title=""
          backgroundColor="#FFFFFF"
          foregroundColor={colors.red}
          mobileOnly
        />

        <FlexContainer wide>
          <EmptyState
            title={t('Thread not found')}
          />
        </FlexContainer>
      </FlexInnerWrapper>
    </FlexWrapper>
  );
};

NotFound.propTypes = {
};

NotFound.defaultProps = {
};

export default NotFound;
