import styled from 'styled-components';

import Menu, { Item } from 'components/Menu';

const ItemContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 16px !important;
    margin-right: 8px !important;

    path {
      fill: #666;
    }
  }
`;

interface Props {
  options?: Array<{
    label: string;
    value: string;
    icon: React.ReactNode;
    selected?: boolean;
  }>;
  onChange?: (value: string) => void;
  close: () => void;
}

const OptionsCaret: React.FC<Props> = ({ options = [], onChange = () => {}, close }) => {
  const onItemSelect = (value: any) => (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(value);
    close();
  };

  return (
    <Menu open onClose={close}>
      {options.map(option => (
        // @ts-ignore
        <Item key={option.value} onClick={onItemSelect(option.value)}>
          <ItemContainer>
            {option.icon}
            <span>{option.label}</span>
          </ItemContainer>
        </Item>
      ))}
    </Menu>
  );
};

export default OptionsCaret;
