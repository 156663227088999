import {
  Box,
  Icon,
  IconButton, Menu, MenuButton, MenuList,
} from '@chakra-ui/react';
import { Gift } from 'lucide-react';
import ReactGA from 'react-ga4';

import MenuItemSades from './MenuItemSades';
import MenuItemTags from './MenuItemTags';

interface Props {
  userId: number;
}

const GiftButton: React.FC<Props> = ({ userId }) => {
  const onOpen = () => {
    ReactGA.event({
      category: 'Users',
      action: 'Gift Button Clicked',
      label: `User id: ${userId}`,
    });
  };

  return (
    <Box>
      <Menu onOpen={onOpen}>
        <MenuButton as={IconButton} aria-label="Gift" icon={<Icon as={Gift} transform="scale(1.5)" />} />
        <MenuList>
          <MenuItemSades userId={userId} />
          <MenuItemTags userId={userId} />
        </MenuList>
      </Menu>
    </Box>
  );
};

export default GiftButton;
