import { memo, useCallback } from 'react';
import { Box } from '@chakra-ui/react';

import { useTranslation } from 'hooks';

import { Infinite, Menu } from 'components/Icons';
import Header from 'components/Header';

import MarkAllAsSeen from './MarkAllAsSeen';
import locales from './i18n';

const NoCommunityHeader = memo(() => {
  const { t } = useTranslation(locales);

  const renderAvatar = useCallback(() => (
    <Box w={10} h={10} rounded={2} p={2} boxShadow="md">
      <Infinite />
    </Box>
  ), []);

  const renderIcon = useCallback(() => (
    <Menu color="white" />
  ), []);

  const renderActions = useCallback(() => (
    <MarkAllAsSeen communityId="recent" />
  ), []);

  return (
    <Header
      title={t('All threads')}
      renderAvatar={renderAvatar}
      renderIcon={renderIcon}
      renderActions={renderActions}
    />
  );
});

NoCommunityHeader.propTypes = {
};

NoCommunityHeader.defaultProps = {
  renderActions: null,
};

export default NoCommunityHeader;
