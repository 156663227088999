import produce from 'immer';

import {
  ADD_PUBLICATIONS,
  ADD_COMMENTS,
  REMOVE_COMMENT,
  PREPEND_TO_FEED_QUEUE,
  REMOVE_FROM_FEED,
  FLUSH_FEED_QUEUE,
  SET_LATEST_HASHTAGS,
  POLL_UPDATED,
  WIPE,
  SET_SCROLL,
} from './constants';

export const initialState = {
  publications: {},
  comments: {},
  feed: [],
  feedQueue: [],
  feedFullyLoaded: false,
  latestHashtags: [],
  latestHashtagsFetchedAt: null,
  scrollTop: 0,
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case ADD_PUBLICATIONS: {
      // This is done to preserve previous publication properties
      const publications = {};
      Object.values(action.publications).forEach((publication) => {
        // Conciliate `authorId` and `author` fields
        const author = publication.author || publication.authorId;
        const oldPub = state.publications[publication.id];
        const newComments = (publication.comments || []).filter((comment) => {
          if (!oldPub) return true;
          return !oldPub.comments.includes(comment);
        });
        const oldComments = oldPub ? oldPub.comments : [];

        // When publication gets updated, it comes in the bus in generic version.
        // This means that information might contain blurred images,
        // even if user payed to unblur them.
        // This is why we ensure than once unblurred, always unblurred.
        if (
          publication.isBlurred
          && state.publications[publication.id]
          && !state.publications[publication.id].isBlurred
        ) {
          // eslint-disable-next-line no-param-reassign
          publication.isBlurred = oldPub.isBlurred;
          // eslint-disable-next-line no-param-reassign
          publication.payload.media = oldPub.payload.media;
        }

        publications[publication.id] = {
          ...oldPub,
          ...publication,
          comments: [
            ...oldComments,
            ...newComments,
          ],
          author,
        };
      });
      //

      draft.publications = {
        ...state.publications,
        ...publications,
      };

      if (action.comments) {
        const comments = {};
        Object.values(action.comments).forEach((comment) => {
          // Conciliate `authorId` and `author` fields
          const author = comment.author || comment.authorId;

          comments[comment.id] = {
            ...state.comments[comment.id],
            ...comment,
            author,
          };
        });

        draft.comments = {
          ...state.comments,
          ...comments,
        };
      }

      if (action.feed) {
        const ids = action.feed.filter(id => !state.feed.includes(id));

        draft.feed = [
          ...state.feed,
          ...ids,
        ];

        draft.feedFullyLoaded = !action.feed.length;
      }

      break;
    }

    case PREPEND_TO_FEED_QUEUE: {
      const ids = action.ids.filter(
        id => !state.feed.includes(id) && !state.feedQueue.includes(id),
      );

      draft.feedQueue = [
        ...ids,
        ...state.feedQueue,
      ];

      break;
    }

    case FLUSH_FEED_QUEUE: {
      const ids = state.feedQueue.filter(id => !state.feed.includes(id));

      if (ids.length > 0) {
        draft.scrollTop = 0;
      }

      draft.feed = [
        ...ids,
        ...state.feed,
      ];

      draft.feedQueue = [];

      break;
    }

    case REMOVE_FROM_FEED:
      draft.feed = state.feed.filter(id => id !== action.id);
      draft.feedQueue = state.feedQueue.filter(id => id !== action.id);
      delete draft.publications[action.id];
      break;

    case ADD_COMMENTS: {
      draft.comments = {
        ...state.comments,
        ...action.data,
      };

      const ids = action.result.filter(id => (
        !draft.publications[action.publicationId].comments.includes(id)
      ));
      draft.publications[action.publicationId].comments = [
        ...draft.publications[action.publicationId].comments,
        ...ids,
      ];
      break;
    }

    case REMOVE_COMMENT:
      delete draft.comments[action.commentId];
      draft.publications[action.publicationId].comments = (
        state.publications[action.publicationId].comments.filter(id => id !== action.commentId)
      );
      break;

    case SET_LATEST_HASHTAGS:
      draft.latestHashtags = action.data;
      draft.latestHashtagsFetchedAt = new Date();
      break;

    case POLL_UPDATED:
      if (state.publications[action.publicationId]) {
        draft.publications[action.publicationId].payload.poll = action.payload;
      }
      break;

    case WIPE:
      draft.publications = initialState.publications;
      draft.comments = initialState.comments;
      draft.feed = initialState.feed;
      draft.feedQueue = initialState.feedQueue;
      break;

    case SET_SCROLL:
      draft.scrollTop = action.scrollTop;
      break;

    default:
  }
});

export default reducer;
