export default {
  baseStyle: {
    control: {
      _checked: {
        bg: 'gray.900',
        borderShadow: 'none',
        borderColor: 'black',
        _hover: {
          bg: 'gray.800',
          borderShadow: 'none',
          borderColor: 'black',
        },
      },
    },
  },
};
