import {
  useEffect, useCallback, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import * as messengerSelectors from 'state/messengers/selectors';

import { TabsList, TabsWrapper } from 'components/Tabs';
import {
  Chats, Notebook, ChatRequest, FilterVariant, Close,
} from 'components/Icons';

import Filter from './Filter';

const FilterButton = styled.button.attrs({
  type: 'button',
})`
  background-color: transparent;
  border: 0;
  border-bottom: 4px solid transparent;
  outline: none;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`;
FilterButton.displayName = 'FilterButton';

const messengersTab = {
  key: 'messengers',
  icon: <Chats outline color={colors.grey} />,
  iconSelected: <Chats color={colors.red} />,
};
const contactsTab = {
  key: 'contacts',
  icon: <Notebook outline color={colors.grey} />,
  iconSelected: <Notebook color={colors.red} />,
};
const requestsTab = {
  key: 'chatrequests',
  icon: <ChatRequest outline color={colors.grey} />,
  iconSelected: <ChatRequest color={colors.red} />,
};
const baseTabs = [
  messengersTab,
  contactsTab,
  requestsTab,
];

const Tabs = ({
  tabIndex, filter, setFilter, setTabIndex,
}) => {
  const location = useLocation();
  const filterRef = useRef(null);
  const initialized = useRef(false);

  const requestCount = useSelector(messengerSelectors.notRejectedRequestsCount);

  const [showingFilter, setShowingFilter] = useState(false);
  const [tabs, setTabs] = useState(baseTabs);

  useEffect(() => {
    // If to avoid unnecessary re-render on mount
    if (initialized.current || requestCount !== 0) {
      let requestsBadge = '';
      if (requestCount > 9) requestsBadge = '9+';
      else if (requestCount > 0) requestsBadge = String(requestCount);

      setTabs([
        messengersTab,
        contactsTab,
        {
          ...requestsTab,
          badge: requestsBadge,
        },
      ]);
    }

    if (!initialized.current) {
      initialized.current = true;
    }
  }, [requestCount]);

  const showFilter = useCallback(() => {
    filterRef.current.focus();
    setShowingFilter(true);
  }, []);

  const hideFilter = useCallback(() => {
    setFilter('');
    setShowingFilter(false);
  }, [setFilter]);

  useEffect(() => {
    setFilter('');
    setShowingFilter(false);
  }, [location.pathname, setFilter]);

  return (
    <>
      <Filter ref={filterRef} showing={showingFilter} onChange={setFilter} value={filter} />
      <TabsWrapper>
        <TabsList data={tabs} selected={tabIndex} onSelect={setTabIndex} />
        {tabIndex !== 1 && (
          <FilterButton onClick={showingFilter ? hideFilter : showFilter}>
            {showingFilter
              ? <Close color={colors.grey} />
              : <FilterVariant color={colors.grey} />
            }
          </FilterButton>
        )}
      </TabsWrapper>
    </>
  );
};

Tabs.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  setTabIndex: PropTypes.func.isRequired,
};

Tabs.defaultProps = {
};

export default Tabs;
