import { IconType, IconDefaults } from './type';

const ViewDashboard = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M13,3V9H21V3M13,21H21V11H13M3,21H11V15H3M3,13H11V3H3V13Z"
      fill={color}
    />
  </svg>
);

ViewDashboard.propTypes = IconType;
ViewDashboard.defaultProps = IconDefaults;

export default ViewDashboard;
