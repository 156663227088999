import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';

import Modal from 'components/Modal';
import { SelectableList } from 'components/SelectableList';

import Community from './Community';
import locales from '../i18n';

const SelectCommunityModal = ({ onCancel }) => {
  const { t } = useTranslation(locales);
  const myCommunities = useSelector(communitySelectors.selectMyCommunityIdsSorted, shallowEqual);

  return (
    <Modal onCancel={onCancel} title={t('Select a Community in where post your Thread')}>
      <SelectableList>
        {myCommunities.map(communityId => (
          <Community
            key={`languages-${communityId}`}
            communityId={communityId}
            closeModal={onCancel}
          />
        ))}
      </SelectableList>
    </Modal>
  );
};

SelectCommunityModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

SelectCommunityModal.defaultProps = {
};

export default SelectCommunityModal;
