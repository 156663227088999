/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import moment from 'moment';

import AuthLayout from 'components/AuthLayout';

const TOS = () => (
  <AuthLayout
    title="Términos y Condiciones"
    wide
  >
    <p><strong>MAZMO</strong> lo autoriza a ver, descargar, e interactuar con los materiales, servicios y foros de este sitio web. A no ser que se especifique lo contrario, los servicios y descargas provistos por <strong>MAZMO</strong> son para su uso personal, siempre y cuando conserve las licencias y otros avisos propietarios contenidos en los materiales originales.</p>

    <p>Los materiales en <strong>MAZMO</strong> se encuentran bajo derechos de autor, y cualquier uso no autorizado de estos materiales podran violar licencias y/o marcas registradas.</p>

    <p>Estos documentos son para nuestra protección, así como también para la suya. Por favor léalos cuidadosamente.</p>


    <h2>Descargo</h2>

    <p><strong>MAZMO</strong> no es responsable por ninguno de los Artículos, Comentarios, Revisiones, Nombres de Usuario, etc. El autor que envía un particular Artículo, Comentario, Revisión, o Nombre de Usuario en <strong>MAZMO</strong> asume la total responsabilidad del mismo.</p>

    <p><strong>MAZMO</strong> rechaza cualesquiera y toda la responsabilidad por la exactitud, el contenido, lo completo, la legalidad, la confiabilidad, la operabilidad, o la disponibilidad de la información o del material exhibido en este sitio. <strong>MAZMO</strong> rechaza cualquier responsabilidad por cualquier tipo de daño producido por descargar o acceder a cualquier información o material en <strong>MAZMO</strong>.</p>

    <p><strong>MAZMO</strong> contiene enlaces a otros sitios de Internet. Estos enlaces son provistos sólo para su conveniencia y no son un endoso de ningn producto o servicio en tales sitios, y no se ha endosado ni se ha aprobado ninguna información en tal sitio por <strong>MAZMO</strong>. Estos sitios de terceros pueden también contener opiniones y puntos de vista de terceros que no coinciden necesariamente con nuestras opiniones y puntos de vista. Esos sitios pueden también tener Políticas de Privacidad diferentes que nuestra propia Política de Privacidad.</p>

    <p>Todos los materiales, información, productos y servicios incluídos en <strong>MAZMO</strong> son provistos &quot;COMO ESTAN&quot;, con ninguna garantía en cualquier forma. <strong>MAZMO</strong> expresamente rechaza al grado más completo permitido por la ley las garantías expresas, implicadas, y estatutarias todas. MAZMO niega cualquier garantía para los servicios o bienes recibidos a través o anunciadas en <strong>MAZMO</strong>.</p>

    <p>Todos los logos e imágenes son propiedad de sus respectivos autores. Todos los HTML y bases de datos en <strong>MAZMO</strong> son  Copyright 2005 - {moment().format('YYYY')} <strong>MAZMO</strong>.</p>


    <h2>Privacidad</h2>

    <p>Respetamos y nos comprometemos a respetar su privacidad. Esta Política de Privacidad le hace saber cómo su información personal es procesada y usada en <strong>MAZMO</strong>. Nos esforzamos en todo momento para usar su información personal sólo de manera que no altere esta Poltica de Privacidad.</p>

    <h3>Recolección de Información</h3>
    <p><strong>MAZMO</strong> es el dueño exclusivo de la información recolectada en este sitio. No vendemos, ni compartimos, ni alquilamos, ni venderemos, compartiremos, o alquilaremos esta información a terceros de ninguna manera diferente a lo que la siguiente Declaración de Privacidad expresa.</p>

    <h3>Cookies</h3>
    <p>Una cookie es un pedazo de informacin almacenado en el disco duro del visitante, conteniendo datos del visitante. Los anunciantes de <strong>MAZMO</strong> a veces necesitan cookies para obtener estadísticas sobre productos y locaciones, pero en ningn momento, éstas están relacionadas con identificación personal. En este momento, <strong>MAZMO</strong> usa cookies sólo para facilitar el login y recordar mensajes leídos en el foro.</p>

    <h3>Log Files</h3>
    <p><strong>MAZMO</strong> guarda el historial de uso del sitio para analizar tendencias, movimientos de usuarios, y obtener datos de locaciones demográficas. Esto no implica de ninguna manera información personal del visitante.</p>

    <h3>Compartiendo</h3>
    <p>En este momento, <strong>MAZMO</strong> no comparte ninguna información con los anunciantes, comerciantes, o terceras partes.</p>

    <h3>Enlaces</h3>
    <p>Los enlaces a otros sitios fuera de <strong>MAZMO</strong> no se aplican a esta declaración de privacidad. No somos responsables por las políticas de privacidad de otros websites enlazados a <strong>MAZMO</strong>. Por favor lea la Declaración de Privacidad de cada sitio enlazado a <strong>MAZMO</strong> para el uso de información, datos e identificación personal del visitante. Nuestra Declaración de Privacidad sólo se aplica a <strong>MAZMO</strong>.</p>

    <h3>Concursos</h3>
    <p>En cualquier momento particular, <strong>MAZMO</strong> puede proponer concursos para nuestros visitantes. La participación de los visitantes es voluntaria, y de ninguna manera requiere que el visitante ceda información personal cuando elija no entrar al concurso. La información recolectada puede incluir información de contacto y demográfica, como nombre, e-mail, dirección, provincia, etc. Esta información ser usada para notificar los premios y ganadores.</p>

    <h3>Boletín de Noticias</h3>
    <p>Al registrar un usuario en <strong>MAZMO</strong> usted formará parte de nuestra lista de correo. Puede en cualquier momento des suscribirse e incluso volver a suscribirse. La información almacenada en nuestras bases de datos no se vende ni se comparte con terceras partes.</p>

    <h3>Cambios en nuestras polticas</h3>
    <p><strong>MAZMO</strong> se reserva el derecho a modificar sus políticas en cualquier momento y sin aviso previo.</p>


    <h2>Términos de servicio</h2>
    <ol>
      <li>
        <h3>Aceptación de los términos de uso y enmiendas</h3>
        Cada vez que usted use o acceda a este sitio web, acordará estar sujeto a estos <em>Términos de uso</em>, y a las potenciales enmiendas que podrán o no serles informadas. Además, si está utilizando un servicio particular en o desde este sitio web, estará sujeto a cualquier regla o guía aplicable a esos servicios.
      </li>

      <li>
        <h3>Nuestros servicios</h3>
        Nuestro sitio web y sus servicios son provistos a usted en y desde este sitio web &quot;COMO ESTÁN&quot;. Usted estará de acuerdo que los dueños de este sitio web exclusivamente se reservan el derecho y pueden, en cualquier momento y sin anoticiarlo, modificar o discontinuar este sitio web y sus servicios o eliminar los datos que usted ha provisto ya sea temporal o permanentemente. No nos hacemos responsables por la eliminación, fallos, inexactitud o envíos impropios de cualquier dato o información.
      </li>

      <li>
        <h3>Sus responsabilidades y obligaciones de registro</h3>
        Para poder usar completamente ese sitio web, usted debe registrarse en el mismo. Usted se compromete en enviar información verdadera cuando se lo requiera, y garantiza que tiene por lo menos dieciocho (18) años de edad. Al registrarse, explícitamente estará de acuerdo con nuestros Términos de uso y que pueden ser modificados una cantidad indefinida de veces.
      </li>

      <li>
        <h3>Política de privacidad</h3>
        Los datos de registro y otros datos de identificación personal que puedan ser recolectados están sujetos a los términos de nuestra Declaración de Privacidad.
      </li>

      <li>
        <h3>Registro y Contraseña</h3>
        Usted se hace responsable de mantener la confidencialidad de su contraseña y se hará responsable de todos sus usos vía registración y/o identificación, autorizado o no por usted. Está de acuerdo en notificar a la administración de este sitio web inmediatamente ante cualquier uso no autorizado de su registro, cuenta o contraseña.
      </li>

      <li>
        <h3>Su conducta</h3>
        <p>Usted acepta que toda informacin o dato de cualquier tipo, sea texto, software, código, música o sonido, fotografías o gráficos, video u otro material (Contenido), provisto pública o privadamente, será única responsabilidad de la persona que lo provee o de la persona correspondiente a la cuenta de usuario usada. Usted acepta que este sitio web puede exponerlo a usted ante Contenido que puede ser objetable u ofensivo. <strong>MAZMO</strong> no se hace responsable ante usted en ningn sentido por el Contenido que aparezca en este sitio web ni por ningn error u omisión.</p>

        <p>Usted explícitamente acepta, al hacer uso de este sitio web o cualquier servicio provisto, que usted no:

          <ol type="a">
            <li>proveerá ningún Contenido o tendrá ninguna conducta que pueda ser ilegal, desleal, atemorizante, amenazante, dañina, abusiva, acosadora, tortuosa, difamatoria, vulgar, obscena, ofensiva, objetable, o que sea diseñada para o que interfiera o interrumpa este sitio web o cualquiera de los servicios provistos, infectando con virus u otras rutinas de programación destructivas, o que puedan violar una ley local, nacional o internacional;</li>

            <li>se hará pasar o representar fraudulentamente su asociación con cualquier persona o entidad, o forjar o buscar conciliar o representar fraudulentamente el origen de cualquier Contenido provisto por usted;</li>

            <li>coleccionará cualquier dato sobre otros usuarios;</li>

            <li>proveerá o usará este sitio web ni cualquier Contenido o servicio en forma comercial o en cualquier otra forma que involucre correo basura, spam, correos en cadena, o cualquier otra forma de publicidad no autorizada sin nuestro previo consentimiento;</li>

            <li>proveerá cualquier Contenido que pueda derivar en nestras responsabilidades civiles o criminales o que pueda ser considerada una violación de cualquier ley local, nacional o internacional, incluyendo pero no limitada a leyes relacionadas a copyright copyright, trademark (marcas registradas), patentes, o intercambio de secretos.</li>
          </ol>
        </p>
      </li>

      <li>
        <h3>Envo de Contenido en este sitio web</h3>
        Al proveer cualquier contenido a este sitio web:

        <ol type="a">
          <li>usted acepta garantizarnos derechos y licencias mundiales, libre de regalías, perpetuas, (incluyendo cualquier derecho moral u otro derecho necesario) para usar, emitir, reproducir, modificar, adaptar, publicar, distribuir, ejecutar, promocionar, archivar, traducir, y crear trabajos derivados y compilaciones, total o parcialmente. Tal licencia aplicará con respecto a cualquier forma, medio, tecnlogía conocida o desarrollada en un futuro.;</li>

          <li>usted afirma y garantiza que tiene las capacidades legales, morales, y otros derechos que puedan ser necesarios para asegurarnos con la licencia descripta provista en esta sección 7;</li>

          <li>usted reconoce y acepta que <strong>MAZMO</strong> tiene el derecho (pero no la obligación), a discreción, de negarse a publicar o eliminar o bloquear el acceso a Contenido provisto por usted en cualquier momento y por cualquier razón, y sin la obligación de anoticiarlo.</li>
        </ol>
      </li>

      <li>
        <h3>Servicios de terceras partes</h3>
        Bienes y servicios de terceras partes pueden ser publicitados y/o puestos a disposición en o a través de este sitio web. Representationes hechas a través de productos y servicios provistos por terceras partes están sujetos a las políticas y representaciones hechas por dicha tercera parte. <strong>MAZMO</strong> no es responsable de ninguna manera por ninguna interacción suya con terceras partes.
      </li>

      <li>
        <h3>Indemnización</h3>
        Usted se compromete a indemnizar y sacar en paz y a salvo, libre de daños, a <strong>MAZMO</strong>, sus subsidiarios, afiliados, oficiales, directores, empleados, agentes, proveedores independientes, u otros socios de cualquier reclamo o demanda, incluyendo honorarios razonables de los abogados, hecha por una tercera parte debido al Contenido, o surgido del Contenido que Usted presente, anuncie o transmita por medio del Servicio, su uso del Servicio, su violación a los Términos de Servicio o su violación a los derechos de un tercero.
      </li>

      <li>
        <h3>LÍMITE DE LAS GARANTAS</h3>
        <p>USTED EXPRESAMENTE COMPRENDE Y CONVIENE EN QUE EL USO DEL SERVICIO Y CUALQUIER CONTENIDO O SERVICIO OFRECIDO ES PROVISTO BAJO SU PROPIO RIESGO. EL SERVICIO ES PROVISTO EN LAS CONDICIONES EN QUE SE ENCUENTRA POR LO QUE MAZMO NO OTORGA GARANTÍAS DE NINGÚN TIPO, YA SEA EXPLÍCITA O IMPÍLCITA, INCLUYENDO SIN LIMITACIÓN LAS GARANTAS IMPLÍCITAS DE COMERCIABILIDAD, APTITUD PARA UN PROPÓSITO EN PARTICULAR Y NO VIOLACIÓN DE LOS USUARIOS.</p>

        <p><strong>MAZMO</strong> NO GARANTIZA, EN FORMA EXPLÍCITA O IMPÍLCITA, QUE EL SERVICIO SERÁ ININTERRUMPIDO, LIBRE DE ERROR, LIBRE DE VIRUS, PUNTUAL O SEGURO; QUE LOS RESULTADOS QUE PUEDAN SER OBTENIDOS DEL USO DEL SERVICIO SEAN VERACES O CONFIABLES; QUE CUALQUIER MATERIAL DESCARGADO (&quot;DOWNLOADED&quot;) SEA SEGURO. USTED COMPRENDE Y CONVIENE EN QUE NINGN CONSEJO O INFORMACIÓN, YA SEA ORAL O ESCRITO, OBTENIDO DE MAZMO POR USTED, POR MEDIO DEL SERVICIO CONSTITUIR GARANTÍA ALGUNA, Y ES PROVISTO BAJO SU PROPIO RIESGO.</p>
      </li>

      <li>
        <h3>LÍMITE DE RESPONSABILIDAD</h3>
        <p>USTED COMPRENDE Y ACUERDA EXPRESAMENTE QUE <strong>MAZMO</strong> NO SERÁ RESPONSABLE POR NINGÚN DAÑO O PERJUICIO DIRECTO INDIRECTO, COMO RESULTADO DE: (I) EL USO O IMPOSIBILIDAD DE USAR EL SERVICIO; (ii) EL COSTO DE OBTENCIÓN DE BIENES SUSTITUIBLES Y SERVICIOS RESULTANTES DE CUALQUIER BIEN, DATO, INFORMACIÓN O SERVICIO ADQUIRIDO U OBTENIDO, O MENSAJE RECIBIDO, O TRANSACCIONES REALIZADAS POR MEDIO DEL SERVICIO, O DESDE EL SERVICIO (iii) ACCESO NO AUTORIZADO A SUS TRANSMISIONES, O ALTERACIÓN DE LAS MISMAS, O DE SUS DATOS; (iv) DECLARACIONES O CONDUCTAS DE ALGUNA TERCERA PERSONA EN EL SERVICIO; O (v) CUALQUIER OTRO ASUNTO RELACIONADO AL SERVICIO.</p>
      </li>

      <li>
        <h3>Reserva de Derechos</h3>
        Nos reservamos todos los derechos, incluyendo pero no limitado a cualquiera de los derechos de autor o copyrights, marcas registradas, patentes, secretos comerciales y cualquier otro derecho de propiedad sobre nuestro sitio web, sus contenidos y los bienes y servicios que podamos ofrecer. El uso de nuestros derechos y nuestra propiedad requiere nuestro consentimiento explícito por escrito por adelantado. No ofrecemos ninguna licencia implícita o explícita o los derechos por el hecho de proveer servicios, y usted no tendrá el derecho de hacer uso comercial de nuestro sitio web o servicio sin nuestro consentimiento previo por escrito.
      </li>

      <li>
        <h3>Ley Aplicable</h3>
        Usted concuerda que estos Términos de Servicio y cualquier problema que surja de su uso de este sitio web o de nuestros productos o servicios serán gobernados por e interpretados de acuerdo con las leyes locales aplicables donde se encuentran las oficinas del propietario de este sitio, sin la consideracin a conflicto de provisiones de ley. Al registrarse o usar este sitio web y servicios, usted consiente y se somete a la jurisdicción exclusiva del estado o ciudad donde se hallan las oficinas del propietario de este sitio.
      </li>

      <li>
        <h3>Información Adicional</h3>
        <ol type="i">
          <li>Si una o más clásulas de las presentes Condiciones son nulas o declaradas como tales en aplicación de una ley, de un reglamento o tras una decisión definitiva de una jurisdicción competente, las otras clásulas guardarán toda su fuerza y alcance. Las clásulas declaradas nulas y no válidas entonces serán sustituídas por clásulas que más se acercarán en cuanto a su contenido de las clásulas inicialmente decididas;</li>

          <li>La imposibilidad de una u otra parte por aseverar cualquier derecho establecido en estos Términos de Servicio no será considerada una renuncia de ningn derecho de la parte y ese derecho permanecer con toda su fuerza y efecto;</li>

          <li>Usted acepta que sin contemplar cualquier estatuto o ley contraria, cualquier reclamación o causa proveniente de este sitio o sus servicios debe ser archivada dentro de un (1) año despus de que tal reclamación o causa surja, o la reclamación será excluída en forma permanente;</li>

          <li>Podemos asignar nuestros derechos y obligaciones bajo estos Términos de Servicio y seremos relevados de cualquier obligación adicional.</li>
        </ol>
      </li>
    </ol>
  </AuthLayout>
);

export default TOS;
