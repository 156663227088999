import PropTypes from 'prop-types';
import styled from 'styled-components';

interface MediaItemWrapperProps extends React.HTMLProps<HTMLLIElement> {
  threshold?: boolean;
}

const MediaItemWrapper = styled.li<MediaItemWrapperProps>`
  position: relative;
  cursor: pointer;

  ${props => props.threshold && `
    max-width: 33%;
    padding: 8px;
    box-sizing: border-box;
  `}

  @media(max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
  }
  &:not(:last-child) {
    @media(min-width: 768px) {
      margin-right: 12px;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    display: inline-block;
    vertical-align: middle;
  }
`;

interface Props {
  image: {
    filename: string;
  };
  onClick?: () => void;
  threshold?: boolean;
}

const MediaItem = ({ image, onClick, threshold }: Props) => (
  <MediaItemWrapper onClick={onClick} threshold={threshold}>
    {/* What is going on with these types?
      @ts-ignore */}
    <img src={image.filename || image} alt="" />
  </MediaItemWrapper>
);

MediaItem.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.shape({
      filename: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]).isRequired,
  onClick: PropTypes.func,
  threshold: PropTypes.bool,
};

MediaItem.defaultProps = {
  onClick: null,
  threshold: false,
};

export default MediaItem;
