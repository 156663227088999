import { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import SiteError from 'components/SiteError';

import Theme from './Theme';


class Boundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.object,
    ]).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, name: error.name };
  }

  componentDidCatch(error, errorInfo) {
    if (error.name !== 'ChunkLoadError' && error.name !== 'NotFoundError') {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    const { hasError, eventId, name } = this.state;
    const { children } = this.props;

    return (
      <Theme>
        {hasError
          ? <SiteError eventId={eventId} name={name} />
          : children
        }
      </Theme>
    );
  }
}

export default Boundary;
