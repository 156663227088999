import PropTypes from 'prop-types';

import LinkBox from 'components/LinkBox';
import LinkComponent from 'components/ParsedContent/Link';

const Link = ({ data }) => {
  const card = data.meta && data.meta.twitterCard === 'summary_large_image';

  return (
    <LinkComponent href={data.url}>
      <LinkBox style={{ margin: '16px 16px 4px' }} card={card}>
        {data.icon && (
          <img
            className={card ? 'fullImage' : 'linkImage'}
            src={data.icon}
            alt={data.title}
          />
        )}
        <div>
          <h3>{data.title}</h3>
          <p>{data.description}</p>
        </div>
      </LinkBox>
    </LinkComponent>
  );
};

Link.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    url: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      twitterCard: PropTypes.string,
    }),
  }).isRequired,
};

export default Link;
