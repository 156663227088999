import styled from 'styled-components';

import colors from 'utils/css/colors';

const normalBG = '255, 255, 255';
const negativeBG = '237, 77, 61';

interface Props {
  value?: number;
  negative?: boolean;
}

const Badge = styled.div.attrs(({ value }: Props) => ({
  children: (value ?? 0) > 99 ? '99+' : value,
}))<Props>`
  border: 2px solid ${props => (props.negative ? 'white' : colors.red)};
  background-color: rgba(${props => (props.negative ? negativeBG : normalBG)}, ${props => (props.value ? '1' : '0.6')});
  color: ${props => (props.negative ? 'white' : colors.red)};
  position: absolute;
  font-size: 11px;
  padding: 2px;
  border-radius: 100%;
  line-height: 14px;
  text-align: center;
  min-width: ${props => (props.value ? '20' : '16')}px;
  height: ${props => (props.value ? '20' : '16')}px;
  right: 4px;
  top: 12px;
  font-weight: bold;
`;

export default Badge;
