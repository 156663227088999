import styled from 'styled-components';
import colors from 'utils/css/colors';

const LeftColumnBottom = styled.div`
  background-color: #FAFAFA;
  color: ${colors.blackRed};
  font-weight: bold;
  line-height: 70px;
  height: 56px;
  padding: 0 15px;
  cursor: pointer;
  user-select: none;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 9px #0000001f;

  ${props => props.mobileOnly && `
    @media(min-width: 767px) {
      display: none;
    }
  `}

  &:hover {
    color: ${colors.red};
  }

  a {
    display: block;
    color: ${colors.redReactions};
    text-decoration: none;
    margin: 0 auto;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: ${colors.redReactions};
  }
`;

export default LeftColumnBottom;
