import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';

const UnauthedRoute = ({ component: Component, ...rest }) => {
  const loggedIn = useSelector(authSelectors.loggedIn);

  return (
    <Route
      {...rest}
      render={props => (
        !loggedIn
          ? <Component {...props} />
          : <Redirect to="/" />
      )}
    />
  );
};

UnauthedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default UnauthedRoute;
