import { IconType, IconDefaults } from './type';

const ArrowLeftBoldBox = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M7,12L12,17V14H16V10H12V7L7,12Z"
      fill={color}
    />
  </svg>
);

ArrowLeftBoldBox.propTypes = IconType;
ArrowLeftBoldBox.defaultProps = IconDefaults;

export default ArrowLeftBoldBox;
