import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';

import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import UserDisplayName from 'components/UserDisplayName';

const userEqual = (prevUser, nextUser) => (
  prevUser.id === nextUser.id
  && prevUser.username === nextUser.username
  && prevUser.displayname === nextUser.displayname
  && prevUser.loading === nextUser.loading
);

const AuthorLink = ({ authorId }) => {
  const dispatch = useDispatch();
  const author = useSelector(userSelectors.getById(authorId), userEqual);

  if (author.loading) dispatch(userActions.fetchData(authorId));

  return (
    <Link to={`/@${author.username}`} className="userlink">
      <Box color="brand.500" fontWeight="bold" fontSize="sm">
        <UserDisplayName userId={authorId} />
      </Box>
    </Link>
  );
};

AuthorLink.propTypes = {
  authorId: PropTypes.number.isRequired,
};

AuthorLink.defaultProps = {
};

export default memo(AuthorLink);
