import styled from 'styled-components';

const ImageBox = styled.div`
  width: 100%;
  text-align: center;
  position: relative;

  img {
    max-width: 100%;
    max-height: 600px;
    height: auto;
    margin: 0 auto;
    object-fit: contain;
  }
`;

export default ImageBox;
