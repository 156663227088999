import styled from 'styled-components';
import Select from 'react-select';

import colors from 'utils/css/colors';

const container = (base, state) => {
  let backgroundColor = 'transparent';
  if (state.isDisabled) {
    backgroundColor = '#eaeaea';
  } else if (state.selectProps.solid) {
    backgroundColor = '#ffffff';
  }

  return {
    ...base,
    backgroundColor,
  };
};

const control = (base, state) => ({
  ...base,
  border: 0,
  boxShadow: 0,
  padding: 0,
  backgroundColor: state.selectProps.solid ? base.backgroundColor : 'transparent',
});

const indicatorSeparator = base => ({
  ...base,
  display: 'none',
});

const option = (base, state) => ({
  ...base,
  textAlign: 'left',
  backgroundColor: state.isSelected ? colors.borderRed : 'transparent',
  color: colors.redReactions,
  minHeight: '32px',
  '&:hover': {
    backgroundColor: colors.borderRed,
  },
});

const singleValue = base => ({
  ...base,
  color: 'black',
  fontWeight: 300,
});

const valueContainer = base => ({
  ...base,
  padding: 0,
});

const menu = base => ({
  ...base,
  left: '0',
  whiteSpace: 'nowrap',
  width: 'auto',
  zIndex: 21,
});

const placeholder = base => ({
  ...base,
  color: '#545454',
});

const StyledSelect = styled(Select).attrs({
  styles: {
    container,
    control,
    indicatorSeparator,
    option,
    singleValue,
    valueContainer,
    menu,
    placeholder,
  },
  control: {
    className: 'select-control',
  },
})`
  border: 0;
  background: transparent;
  display: block;
  width: 100%;

  border: 1px solid ${props => (props.hasError ? colors.red : '#F5F0F0')};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 0 24px;
  margin-bottom: 10px;

  ${props => props.simple && `
    border: 0;
    border-bottom: 1px solid ${colors.redReactions};
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  `}

  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.redReactions};
  outline: none;

  ${props => props.small && `
    padding: 0 4px;
    font-size: 14px;
  `}

  svg {
    width: 20px;
    height: 20px;
  }
`;

export default StyledSelect;
