import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Box, VStack, HStack, Text, IconButton, Button,
  AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader,
  AlertDialogContent, AlertDialogOverlay,
  ButtonGroup,
  Input,
  Stack,
  InputGroup,
  InputRightAddon,
  Icon,
  Spinner,
} from '@chakra-ui/react';
import { X } from 'lucide-react';

import * as channelActions from 'state/channels/actions';
import { useTranslation, useSearchUsers } from 'hooks';
import useToast from 'hooks/useToast';

import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import locales from '../../i18n';

interface Props {
  channelId: string;
  isOpen: boolean;
  onClose: () => void;
}

interface User {
  id: number;
  displayname: string;
  username: string;
}

const AddInviteModal: React.FC<Props> = ({ channelId, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  const toast = useToast();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const [searchProps, results, reset] = useSearchUsers();
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [inviting, setInviting] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setIsDropdownOpen(results.length > 0);
  }, [results]);

  const addInvite = (user: User) => {
    if (!selectedUsers.some(u => u.id === user.id)) {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const removeInvite = (userId: number) => {
    setSelectedUsers(selectedUsers.filter(u => u.id !== userId));
  };

  const clearSelectedUsers = () => {
    reset();
    setSelectedUsers([]);
  };

  const invite = async () => {
    try {
      setInviting(true);
      dispatch(channelActions.inviteMultiple(channelId, selectedUsers.map(u => u.id)));
      toast.success(t('Invitations sent'));
      onClose();
    } catch (error) {
      toast.error(error);
      onClose();
    }
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t('Send invitation')}
          </AlertDialogHeader>

          <AlertDialogBody>
            <VStack spacing={4} align="stretch">
              <InputGroup>
                <Input
                  value={searchProps.value}
                  placeholder={t('Search users')}
                  onChange={(e) => {
                    searchProps.contentChange(e);
                    setIsDropdownOpen(e.target.value.length > 0);
                  }}
                  w="full"
                />
                <InputRightAddon>
                  <IconButton
                    icon={searchProps.loading ? <Spinner size="sm" /> : <Icon as={X} />}
                    onClick={searchProps.loading ? undefined : reset}
                    aria-label="Clear selected users"
                    variant="ghost"
                    size="xs"
                  />
                </InputRightAddon>
              </InputGroup>
              {isDropdownOpen && (
                <Box
                  borderWidth={1}
                  borderColor="gray.200"
                  borderRadius="md"
                  boxShadow="sm"
                  maxHeight="200px"
                  overflowY="auto"
                >
                  {results.map((user: User) => (
                    <HStack
                      key={user.id}
                      p={2}
                      _hover={{ bg: 'gray.100' }}
                      cursor="pointer"
                      role="option"
                      onClick={() => {
                        addInvite(user);
                        setIsDropdownOpen(false);
                        reset();
                      }}
                    >
                      <UserAvatar userId={user.id} size="32px" />
                      <VStack align="start" spacing={0}>
                        <Text fontWeight="bold">{user.displayname}</Text>
                        <Text fontSize="sm" color="gray.500">
                          @
                          {user.username}
                        </Text>
                      </VStack>
                    </HStack>
                  ))}
                </Box>
              )}
              {selectedUsers.length > 0 && (
                <VStack spacing={2} align="stretch">
                  {selectedUsers.map(user => (
                    <HStack key={user.id} spacing={4} p={2} bg="gray.100" borderRadius="md">
                      <UserAvatar userId={user.id} />
                      <Stack flex={1} spacing={0}>
                        <UserDisplayName userId={user.id} />
                        <Text fontSize="sm" color="gray.500">{`@${user.username}`}</Text>
                      </Stack>
                      <IconButton
                        icon={<X />}
                        onClick={() => removeInvite(user.id)}
                        aria-label="Remove selected user"
                        variant="ghost"
                        size="sm"
                      />
                    </HStack>
                  ))}
                </VStack>
              )}
            </VStack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <ButtonGroup size={{ base: 'sm', md: 'md' }} justifyContent="space-between" w="full">
              <Button
                onClick={clearSelectedUsers}
                variant="outline"
                isDisabled={selectedUsers.length === 0}
              >
                {t('Clear')}
              </Button>

              <HStack>
                <Button ref={cancelRef} onClick={onClose}>
                  {t('global:Cancel')}
                </Button>
                <Button
                  onClick={invite}
                  isLoading={inviting}
                  variant="primary"
                  isDisabled={selectedUsers.length === 0}
                >
                  {t('global:Confirm')}
                </Button>
              </HStack>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

AddInviteModal.propTypes = {
  channelId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddInviteModal;
