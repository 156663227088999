import { batch } from 'react-redux';

import Api from 'state/api';
import ComposerRef from 'utils/ComposerRef';
import * as alertActions from 'state/alerts/actions';
import * as membershipActions from 'state/memberships/actions';
import * as messengerActions from 'state/messengers/actions';
import * as channelActions from 'state/channels/actions';
import * as replyActions from 'state/replies/actions';
import * as threadActions from 'state/threads/actions';
import * as feedActions from 'state/feed/actions';
import * as communitySelectors from 'state/communities/selectors';
import * as threadSelectors from 'state/threads/selectors';
import * as replySelectors from 'state/replies/selectors';
import * as channelSelectors from 'state/channels/selectors';
import * as messengerSelectors from 'state/messengers/selectors';
import * as bankActions from 'state/bank/actions';
import * as authActions from 'state/auth/actions';

import {
  ERRORS_ADD,
  ERRORS_CLEAR,
  TOASTS_ADD,
  TOASTS_REMOVE,
  ADINFO_OPEN,
  ADINFO_CLOSE,
  MINIPROFILE_SET,
  NAVLEFT_ACTIVE,
  NEW_LOCATION,
  UI_LEFT_COLUMN,
  UI_RIGHT_COLUMN,
  UI_CHANNELS_SHOWING_PARTICIPANTS,
  TOGGLE_SAFE_FOR_WORK,
  MEDIA_SET,
  MEDIA_ADD,
  MEDIA_REMOVE,
  MEDIA_CLEAR,
  MEDIA_UPLOAD_COMPLETE,
  COMPOSER_HAS_CONTENT,
  COMPOSER_SAVING,
  COMPOSER_READONLY,
  CHAT_LIST_SET,
  CHAT_LIST_UPDATE,
  CHAT_LIST_REMOVE,
  RESOURCES_LOADED,
  SW_REGISTERED,
  SW_WAITING,
} from './constants';

export const sendActivity = (action, data) => async (dispatch, getState) => {
  try {
    const userIsLoggedIn = !!getState().auth.me;
    if (userIsLoggedIn) await Api.req.post('/users/activities', { action, data });
  } catch (error) {
    //
  }
};

export const addError = error => (dispatch) => {
  if (error === 'NOT_LOGGED_IN') {
    document.location.href = '/login';
  } else {
    dispatch({ type: ERRORS_ADD, data: error });
  }
};

export const clearErrors = () => dispatch => dispatch({ type: ERRORS_CLEAR });

export const addToast = toast => dispatch => dispatch({ type: TOASTS_ADD, data: toast });

export const removeToast = index => dispatch => dispatch({ type: TOASTS_REMOVE, index });

export const openAdInformation = () => dispatch => dispatch({ type: ADINFO_OPEN });

export const closeAdInformation = () => dispatch => dispatch({ type: ADINFO_CLOSE });

export const toggleNavLeft = () => (dispatch, getState) => {
  const isActive = getState().app.navLeftActive;
  dispatch({ type: NAVLEFT_ACTIVE, active: !isActive });
};

export const uiLeftColumn = active => dispatch => dispatch({ type: UI_LEFT_COLUMN, active });

export const uiRightColumn = active => dispatch => dispatch({ type: UI_RIGHT_COLUMN, active });

export const setMiniprofile = (data) => async (dispatch) => {
  dispatch({ type: MINIPROFILE_SET, data });
  if (data) dispatch(sendActivity('mini_profile_open'));
};

export const setMiniprofileByUserId = userId => (dispatch, getState) => {
  const user = getState().users.data[userId];
  if (!user) return null;

  return dispatch(setMiniprofile(user.username));
};

export const setShowingParticipants = show => dispatch => dispatch({
  type: UI_CHANNELS_SHOWING_PARTICIPANTS,
  show,
});

export const toggleSafeForWork = () => dispatch => dispatch({ type: TOGGLE_SAFE_FOR_WORK });

export const newLocation = location => (dispatch, getState) => {
  const history = getState().app.locationHistory;
  const prevLocation = history[history.length - 1];
  if (!prevLocation || location.key !== prevLocation.key) {
    dispatch({ type: NEW_LOCATION, location });
  }
};

// Service worker
export const swRegistered = registration => (dispatch) => {
  dispatch({ type: SW_REGISTERED, registration });
};

export const swWaiting = waiting => (dispatch) => {
  dispatch({ type: SW_WAITING, waiting });
};

// Resources
export const fetchResources = () => async (dispatch) => {
  const {
    data: {
      alerts, channels, messengers, memberships, threads,
      bankPeriod, chatRequests, coupons,
    },
  } = await Api.req.get('/resources');

  batch(() => {
    dispatch(authActions.fetchOrganizations());
    dispatch(alertActions.add(alerts));
    dispatch(channelActions.add(channels));
    dispatch(messengerActions.add(messengers));
    dispatch(membershipActions.add(memberships));
    dispatch(threadActions.addRecent(threads));
    dispatch(bankActions.loadCurrentPeriod(bankPeriod));
    dispatch(bankActions.loadCoupons(coupons));
    dispatch(authActions.loadChatRequests(chatRequests));
    dispatch({ type: RESOURCES_LOADED });
  });
};

export const fetchNewResouces = after => async (dispatch) => {
  const { data } = await Api.req.get('/resources/new', { params: { after } });

  batch(() => {
    data.alerts.forEach((alert) => {
      dispatch(alertActions.handleAlertCreated(alert));
    });

    data.channelmessages.forEach((channelmessage) => {
      dispatch(channelActions.handleChannelMessageCreated({
        ...channelmessage, channel: { id: channelmessage.channel },
      }));
    });

    data.directmessages.forEach((directmessage) => {
      dispatch(messengerActions.handleDirectMessageCreated({
        ...directmessage, messenger: { id: directmessage.messenger },
      }));
    });
  });
};

// Chat list
const sortChats = (state) => {
  const channels = channelSelectors.getAll(state);
  const messengers = messengerSelectors.getAll(state);

  const myMessengers = messengers.filter(data => !data.archived).map(data => ({ type: 'messenger', data }));
  const myChannels = channels.map(data => ({ type: 'channel', data }));

  const entities = myMessengers.concat(myChannels);
  const lastMessagesByChannel = channelSelectors.allLastMessages(state);
  const lastMessagesByMessenger = messengerSelectors.allLastMessages(state);

  const sortedEntities = entities
    .sort((a, b) => {
      const lastMessageA = a.type === 'messenger' ? lastMessagesByMessenger[a.data.id] : lastMessagesByChannel[a.data.id];
      const lastMessageB = b.type === 'messenger' ? lastMessagesByMessenger[b.data.id] : lastMessagesByChannel[b.data.id];

      if (!lastMessageA && !lastMessageB) return 0;
      if (lastMessageA && !lastMessageB) return -1;
      if (!lastMessageA && lastMessageB) return 1;

      const createdAtA = (
        lastMessageA.createdAt
        || (lastMessageA.payload && lastMessageA.payload.createdAt)
      );
      const createdAtB = (
        lastMessageB.createdAt
        || (lastMessageB.payload && lastMessageB.payload.createdAt)
      );
      if (createdAtA > createdAtB) return -1;

      return 1;
    })
    .map(entity => ({ type: entity.type, id: entity.data.id }));

  return sortedEntities;
};

export const computeChatList = () => (dispatch, getState) => {
  const list = sortChats(getState());
  dispatch({ type: CHAT_LIST_SET, list });
};

export const updateChatList = (entityType, entityId) => (dispatch, getState) => {
  const firstChat = getState().app.chatList[0];

  if (firstChat && firstChat !== entityId) {
    dispatch({ type: CHAT_LIST_UPDATE, entityType, entityId });
  }
};

export const removeFromChatList = (entityType, entityId) => (dispatch) => {
  dispatch({ type: CHAT_LIST_REMOVE, entityType, entityId });
};

export const addToChatList = (entityType, entityId) => (dispatch) => {
  dispatch({ type: CHAT_LIST_UPDATE, entityType, entityId });
};

// Media upload
export const mediaUploadSet = (id, value) => (dispatch) => {
  const data = value.map(v => ({
    ...v,
    uploaded: true,
    file: { path: v.id },
    preview: v.filename,
  }));
  dispatch({ type: MEDIA_SET, id, data });
};

export const mediaUploadAdd = (id, data) => (dispatch) => {
  dispatch({ type: MEDIA_ADD, id, data });
};

export const mediaUploadRemove = (id, index) => (dispatch) => {
  dispatch({ type: MEDIA_REMOVE, id, index });
};

export const mediaUploadClear = id => (dispatch) => {
  dispatch({ type: MEDIA_CLEAR, id });
};

export const mediaUploadComplete = (id, name, filename) => (dispatch) => {
  dispatch({
    type: MEDIA_UPLOAD_COMPLETE,
    id,
    name,
    filename,
  });
};

// Composers
export const clearComposer = id => (dispatch) => {
  ComposerRef.clear(id);
  dispatch(mediaUploadClear(id));
};

export const composerHasContent = (id, hasContent) => (dispatch) => {
  dispatch({ type: COMPOSER_HAS_CONTENT, id, hasContent });
};

export const disableComposer = id => (dispatch) => {
  ComposerRef.disable(id);
  dispatch({ type: COMPOSER_READONLY, id, readOnly: true });
};

export const enableComposer = id => (dispatch) => {
  ComposerRef.enable(id);
  dispatch({ type: COMPOSER_READONLY, id, readOnly: false });
};

export const composerSendToServer = (key, data) => async (dispatch, getState) => {
  try {
    const { saving, media = [] } = (getState().app.composers[key] || {});
    const ref = ComposerRef.getRef(key);
    const value = ref.getValue(); // We don't use state's because in mobile is not always up to date

    if (!saving && (value || media.length > 0 || data)) {
      const [type, id] = key.split('-');
      const optimistic = ['messenger', 'channel'].includes(type);

      dispatch({ type: COMPOSER_SAVING, id: key, saving: true });
      if (optimistic) dispatch(clearComposer(key));

      let result;
      if (type === 'messenger') {
        const replyingTo = getState().messengers.replyTo[id];
        const publicationId = getState().messengers.publicationId[id];
        dispatch(messengerActions.clearReplyTo(id));
        dispatch(messengerActions.clearPublicationId(id));

        const payload = data || {
          rawContent: value,
          media: {
            images: media.map(m => m.filename),
          },
          createdAt: (new Date()).toISOString(),
          replyingTo,
          publicationId,
        };

        ref.focus();
        result = await dispatch(messengerActions.createDirectMessage(id, payload));
        dispatch(messengerActions.sendTypingState(id, 'STOPPED'));
      } else if (type === 'messengeredit') {
        const messageId = getState().messengers.editing[id];
        const payload = data || {
          rawContent: value,
        };
        result = await dispatch(messengerActions.editDirectMessage(id, messageId, payload));
        const messengerRef = ComposerRef.getRef(`messenger-${id}`);
        messengerRef.focus();
        dispatch(messengerActions.cancelEditing(id));
      } else if (type === 'channel') {
        const replyingTo = getState().channels.replyTo[id];
        dispatch(channelActions.clearReplyTo(id));

        const payload = data || {
          rawContent: value,
          media: {
            images: media.map(m => m.filename),
          },
          createdAt: (new Date()).toISOString(),
          replyingTo,
        };
        ref.focus();
        result = await dispatch(channelActions.createChannelMessage(id, payload));
      } else if (type === 'channeledit') {
        const messageId = getState().channels.editing[id];
        const payload = data || {
          rawContent: value,
        };
        result = await dispatch(channelActions.editChannelMessage(id, messageId, payload));
        const channelRef = ComposerRef.getRef(`channel-${id}`);
        channelRef.focus();
        dispatch(channelActions.cancelEditing(id));
      } else if (type === 'publication') {
        const payload = {
          type: data.type,
          payload: {
            rawContent: value,
            ...data.payload,
            media: media.map(m => m.filename),
          },
          privacy: data.privacy,
          privacyLists: data.privacyLists,
        };
        if (data.funding) payload.funding = data.funding;
        if (data.lovense) payload.lovense = data.lovense;

        await dispatch(feedActions.publish(payload));
      } else if (type === 'publicationedit') {
        const publication = getState().feed.publications[id];
        const payload = {
          type: publication.type,
          payload: {
            ...publication.payload,
            rawContent: value,
          },
          privacy: data.privacy,
          privacyLists: data.privacyLists,
        };

        await dispatch(feedActions.editPublication(id, payload));
      } else if (type === 'comment') {
        const { followPublicationOnComment } = getState().auth.me;
        const payload = {
          rawContent: value,
          media: media.map(m => m.filename),
          followPublication: followPublicationOnComment,
        };
        await dispatch(feedActions.addComment(id, payload));
      } else if (type === 'createthread') {
        if (!id) throw new Error('Debes seleccionar una comunidad');
        const allSlugs = communitySelectors.selectAllSlugs(getState());
        const communitySlug = allSlugs[id];

        const payload = {
          title: data.title,
          rawContent: value,
          media: {
            images: media.map(m => m.filename),
          },
        };

        result = await dispatch(threadActions.create(communitySlug, payload));
        result.communitySlug = communitySlug;
      } else if (type === 'thread') {
        const payload = data || {
          rawContent: value,
          media: {
            images: media.map(m => m.filename),
          },
        };
        result = await dispatch(replyActions.create(id, payload));
      } else if (type === 'editthread') {
        const thread = threadSelectors.getById(id)(getState());
        const payload = {
          ...data,
          rawContent: value,
          media: {
            images: media.map(m => m.filename),
          },
        };

        await dispatch(threadActions.edit(thread, payload));
      } else if (type === 'editreply') {
        const reply = replySelectors.getById(id)(getState());
        const payload = {
          ...data,
          rawContent: value,
          media: {
            images: media.map(m => m.filename),
          },
        };

        await dispatch(replyActions.edit(reply, payload));
      } else {
        throw new Error('Type not valid');
      }

      dispatch({ type: COMPOSER_SAVING, id: key, saving: false });
      if (!optimistic) dispatch(clearComposer(key));

      return result;
    }
  } catch (error) {
    dispatch(addError(error));
    dispatch({ type: COMPOSER_SAVING, id: key, saving: false });
  }

  return null;
};
