/* eslint-disable import/prefer-default-export */
export const userlistEqual = (listA, listB) => {
  if (listA.length !== listB.length) return false;

  // eslint-disable-next-line consistent-return
  listA.forEach((mA, index) => {
    const mB = listB[index];
    if (mA.id !== mB.id || mA.online !== mB.online) return false;
  });

  return true;
};
