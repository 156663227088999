import { memo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import { useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';
import { userlistEqual } from 'state/auth/equalityFunctions';

import { useElementHeight } from 'hooks';

import { SelectableListContainer } from 'components/SelectableList';

import Contact from './Contact';

const areRowsEqual = (prevProps, nextProps) => {
  const prevUserId = prevProps.data.followingUserIds[prevProps.index];
  const nextUserId = nextProps.data.followingUserIds[nextProps.index];

  return prevUserId === nextUserId;
};

const ContactRow = memo(({ data: { followingUserIds }, index, style }) => {
  const userId = followingUserIds[index];
  return <Contact key={`contact-${userId}`} userId={userId} style={style} />;
}, areRowsEqual);

ContactRow.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    followingUserIds: PropTypes.array.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.shape({}).isRequired,
};

const Contacts = () => {
  const followingUserIds = useSelector(authSelectors.selectFollowingUserIds, userlistEqual);

  const [listHeight, selectableListEl] = useElementHeight();

  // const contacts = followingUsers
  //   .filter(u => u.username.includes(filter) || u.displayname.includes(filter));

  return (
    <SelectableListContainer>
      <ul ref={selectableListEl}>
        <List
          height={listHeight}
          itemCount={followingUserIds.length}
          itemSize={82}
          itemData={{ followingUserIds }}
          width="100%"
        >
          {ContactRow}
        </List>
      </ul>
    </SelectableListContainer>
  );
};

Contacts.propTypes = {
};

Contacts.defaultProps = {
};

export default withRouter(Contacts);
