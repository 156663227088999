import {
  useState, useCallback, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import fixTitle from 'utils/fixTitle';
import ComposerRef from 'utils/ComposerRef';
import { useUploadMedia, useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as appActions from 'state/app/actions';

import CommunityHeader from 'containers/Communities/Header';
import Input from 'components/Forms/InputSimple';
import Button from 'components/Button';
import Composer from 'components/Composer';
import { TrashCan, AutoFix } from 'components/Icons';
import CommunityUploadMedia, { DropZone, UploadMediaButton } from 'components/CommunityUploadMedia';
import Modal from 'components/Modal';
import EmptyState from 'components/EmptyState';

import CreateThreadSubmit from './Submit';
import Wrapper from './UI/Wrapper';
import InputsWrapper from './UI/InputsWrapper';
import TitleWrapper from './UI/TitleWrapper';
import ComposerWrapper from './UI/ComposerWrapper';
import CommunityUploadMediaContainer from './UI/CommunityUploadMediaContainer';
import ActionsWrapper from './UI/ActionsWrapper';

import getTemplate from './getTemplate';
import locales from './i18n';

const NewThreadContent = ({ communityId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const queryparams = qs.parse(document.location.search, { ignoreQueryPrefix: true });

  const userIsLoggedIn = useSelector(authSelectors.loggedIn);
  const userIsConfirmed = useSelector(authSelectors.isConfirmed);

  const [autofix, setAutofix] = useState(!queryparams.autofix);
  const [showingDiscardModal, setShowingDiscardModal] = useState(false);
  const openDiscardModal = useCallback(() => { setShowingDiscardModal(true); }, []);
  const closeDiscardModal = useCallback(() => { setShowingDiscardModal(false); }, []);

  const titleInputEl = useRef(null);

  const composerId = `createthread-${communityId}`;

  const [title, setTitle] = useState(queryparams.title || '');
  const onTitleChange = useCallback((e) => { setTitle(e.target.value); }, []);
  const onTitleBur = useCallback(() => {
    if (autofix) setTitle(currentTitle => fixTitle(currentTitle));
  }, [autofix]);

  const {
    getInputProps,
    isDragActive,
    open,
    handlePastedFiles,
  } = useUploadMedia(composerId, '/communities/upload');

  useEffect(() => {
    const ref = ComposerRef.getRef(composerId);
    if (ref) {
      const template = getTemplate(queryparams.template);
      ref.setValue(template);
    }

    if (titleInputEl.current) titleInputEl.current.focus();
  }, [composerId, queryparams.template]);

  const discard = useCallback(() => {
    dispatch(appActions.clearComposer(composerId));
    history.push(`/+${params.communitySlug}`);
  }, [history, params.communitySlug, dispatch, composerId]);

  const toggleAutofix = useCallback(() => {
    setAutofix(currentValue => !currentValue);
  }, []);

  if (!userIsLoggedIn) return <EmptyState title={t('You can\'t create a new Thread')} subtitle={t('You need to have an account to do this')} />;
  if (!userIsConfirmed) return <EmptyState title={t('You can\'t create a new Thread')} subtitle={t('You need to confirm your email address first')} />;

  const autofixcolor = autofix ? '#333' : '#aaa';

  return (
    <>
      <Wrapper>
        <CommunityHeader communityId={communityId} />
        <InputsWrapper>
          <TitleWrapper>
            <Input
              ref={titleInputEl}
              value={title}
              label={t('global:Title')}
              maxChars={255}
              placeholder={t('What do you want to talk about?')}
              onChange={onTitleChange}
              onBlur={onTitleBur}
            />
            <AutoFix color={autofixcolor} onClick={toggleAutofix} />
          </TitleWrapper>

          <ComposerWrapper>
            <Composer
              id={composerId}
              placeholder={t('Enter thread content here')}
              height="100%"
              handlePastedFiles={handlePastedFiles}
              submitOnCmdReturn={false}
            />

            <CommunityUploadMediaContainer>
              <CommunityUploadMedia
                id={composerId}
                open={open}
                getInputProps={getInputProps}
              />
            </CommunityUploadMediaContainer>
          </ComposerWrapper>
        </InputsWrapper>

        <ActionsWrapper>
          {isDragActive && (
            <DropZone>{t('global:Drop the files here...')}</DropZone>
          )}

          <div>
            <UploadMediaButton open={open} />
          </div>

          <div>
            <Button className="empty" onClick={openDiscardModal}>
              <span className="discard-text">{t('global:Discard')}</span>
              <TrashCan className="discard-icon" outline color={colors.grey} />
            </Button>
            <CreateThreadSubmit id={composerId} title={title} />
          </div>
        </ActionsWrapper>
      </Wrapper>

      {showingDiscardModal && (
        <Modal
          title={t('Discard message')}
          onCancel={closeDiscardModal}
          actions={[
            <Button key="discard-thread-button" onClick={discard}>{t('global:Confirm')}</Button>,
          ]}
        >
          {t('Are you sure you want to discard this message?')}
        </Modal>
      )}
    </>
  );
};

NewThreadContent.propTypes = {
  communityId: PropTypes.string.isRequired,
};

NewThreadContent.defaultProps = {
};

export default NewThreadContent;
