import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';
import useDisplayName from 'hooks/useDisplayName';

import UserLink from 'components/UserLink';
import Busy from 'components/UserDisplayName/Busy';

const At = styled.span`
  color: #aaa;
`;
At.displayName = 'At';

const Mention = ({ value }) => {
  const dispatch = useDispatch();

  const username = value.trim().substr(1);
  const userId = useSelector(userSelectors.getByUsername(username), shallowEqual);
  const displayname = useDisplayName(userId);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        await dispatch(userActions.fetchByUsername(username));
      } catch (e) {
        setError(true);
      }
    };

    if (!userId) fetch();
  }, [userId, dispatch, username]);

  const renderContent = () => {
    if (error) return value.trim();
    if (!userId) return <Busy />;
    return (
      <UserLink userId={userId}>
        <At>@</At>
        {displayname}
      </UserLink>
    );
  };

  return (
    <>
      {value.substr(0, 1) === ' ' && ' '}
      {renderContent()}
    </>
  );
};
Mention.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Mention;
