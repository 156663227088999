import styled from 'styled-components';

const DropZone = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  align-items: center;
`;

export default DropZone;
