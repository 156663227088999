import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import {
  HStack, Icon, Text, VStack,
} from '@chakra-ui/react';

import useTranslation from 'hooks/useTranslation';
import useHotKeys from 'hooks/useHotKeys';

import Logo from 'components/Logo';
import Button from 'components/Button';
import Check from 'components/Icons/Check';
import LanguageSelector from 'components/LanguageSelector';

import Header from './UI/Header';
import Title from './UI/Title';
import Buttons from './UI/Buttons';
import Description from './UI/Description';
import Keywords from './UI/Keywords';
import Layout from './UI/Layout';
import Copy from './UI/Copy';
import Footer from './UI/Footer';
import Welcome from './Welcome';
import SignupForm from './SignupForm';
import Communities from './Communities';
import Sidebar from './Sidebar';
import locales from './i18n';

const facts = [
  'Communities of BDSM, sado, mashochism, fetish and all free sexualities',
  'Contacts, chat and private messaging',
  'More than 150,000 registered users',
  'More than 220,000 uploaded pictures',
  'Free',
];

const Landing = () => {
  const { t } = useTranslation(locales);
  const history = useHistory();

  useHotKeys([{ key: '.', metaKey: true }], () => history.push('/login'));

  return (
    <div style={{ backgroundColor: 'white' }}>
      <Header>
        <Logo width="300px" />
        <Buttons>
          <Button color="white" fontColor="black" to="/signup">{t('Sign up')}</Button>
          <Button color="white" fontColor="black" to="/login">{t('Log in')}</Button>
        </Buttons>
      </Header>

      <HStack bgColor="gray.800" justifyContent="center" spacing={8} p={12}>
        <Description>
          <Title>
            <Welcome />
            {' '}
            {t('to the free sexuality social network')}
          </Title>

          <VStack alignItems="flex-start" color="white" fontSize={[16, 22]} spacing={5}>
            {facts.map((fact, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <HStack key={`fact-${index}`}>
                <Icon as={Check} fill="gray.400" boxSize={6} />
                <Text>{t(fact)}</Text>
              </HStack>
            ))}
          </VStack>

          <Buttons mobile>
            <Button color="white" fontColor="black" to="/signup">{t('Sign up')}</Button>
            <Button light to="/login" fontColor="#EEE">{t('Log in')}</Button>
          </Buttons>
        </Description>

        <SignupForm />
      </HStack>

      <Keywords>
        {t('keywords')}
      </Keywords>

      <Layout>
        <div>
          <Sidebar />
        </div>
        <div>
          <Copy>
            <h2>{t('Why you will find what you are looking for')}</h2>
            <p>
              {t('At Mazmo kinsters around the world gather together both virtually and away from keyboard. It\'s a community under constant development, where all members engages with each other in the most diverse ways. Everyone who encouraged to have an active participation found what they were looking for.')}
            </p>
          </Copy>

          <Communities />

          <Copy>
            <h2>{t('The better source of information and discussion about BDSM')}</h2>
            <p>
              {t('You will find all the information you need about all kinks, thechniques, concepts and security about domination and submission, fetish resources, pictures and videos and a lot more inside the more than 65,000 threads in our communities that are being created and updated by the minute. You will be able to join private themed groups or create your own as well.')}
            </p>

          </Copy>

          <Copy>
            <Link to="/signup"><Button size="big">{t('Start your journey now')}</Button></Link>
          </Copy>
        </div>
      </Layout>

      <Footer>
        <div>
          <div>
            {`Mazmo © ${moment().format('YYYY')}`}
          </div>
          <div>
            <Link to="/info/tos">{t('Terms and conditions')}</Link>
            <Link to="/info/about">{t('About Mazmo')}</Link>
            <a href="https://blog.mazmo.net">{t('Blog')}</a>
            <a href="https://nerds.mazmo.net">{t('Nerds')}</a>
          </div>
        </div>
        <div>
          <LanguageSelector select />
        </div>
      </Footer>
    </div>
  );
};

export default Landing;
