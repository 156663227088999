export default {
  defaultProps: {
    focusBorderColor: 'gray.500',
  },
  baseStyle: {
    field: {
      bgColor: 'white',
      color: 'black',
    },
  },
};
