import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';
import * as communitySelectors from 'state/communities/selectors';

import Stats from 'containers/Communities/CommunityList/Stats';
import Card from 'components/Card';
import MembershipRequestButton from 'components/MembershipRequestButton';

const Wrapper = styled.div`
  > div:first-child {
    margin: -12px 0 16px;
  }
`;
Wrapper.displayName = 'Wrapper';

const Community = ({ communityId }) => {
  const userIsLoggedIn = useSelector(authSelectors.loggedIn);
  const avatar = useSelector(state => communitySelectors.selectAvatar(state, communityId));
  const name = useSelector(state => communitySelectors.selectName(state, communityId));
  const slug = useSelector(state => communitySelectors.selectSlugById(state, communityId));
  const description = useSelector(
    state => communitySelectors.selectDescription(state, communityId),
  );
  const privacy = useSelector(state => communitySelectors.selectPrivacy(state, communityId));

  const action = userIsLoggedIn
    ? <MembershipRequestButton communityId={communityId} />
    : null;

  return (
    <Card
      avatar={{
        src: avatar,
        alt: name,
      }}
      link={`/+${slug}`}
      title={name}
      description={(
        <Wrapper>
          <Stats communityId={communityId} />
          <span>{description}</span>
        </Wrapper>
      )}
      privacy={privacy}
      action={action}
    />
  );
};

Community.propTypes = {
  communityId: PropTypes.string.isRequired,
};

export default Community;
