import styled from 'styled-components';

const CreateWrapper = styled.div`
  margin: 0 auto 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media(min-width: 768px) {
    flex-direction: row;
  }
`;

export default CreateWrapper;
