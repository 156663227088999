import PropTypes from 'prop-types';

import Messenger from './Messenger';
import Channel from './Channel';

const Messages = ({ type, id }) => {
  if (type === 'messenger') return <Messenger id={id} />;
  return <Channel id={id} />;
};

Messages.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['channel', 'messenger']).isRequired,
};

export default Messages;
