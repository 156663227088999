import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';
import imageCompression from 'browser-image-compression';
import 'react-image-crop/dist/ReactCrop.css';

import Api from 'state/api';
import { useTranslation } from 'hooks';

import Modal from 'components/Modal';
import Button from 'components/Button';

import locales from '../../i18n';

const onSubmit = async (image) => {
  await imageCompression.getExifOrientation(image);

  const compressedFile = await imageCompression(image, {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
  });

  // preview: URL.createObjectURL(compressedFile),

  const formData = new FormData();
  formData.append('image', compressedFile, compressedFile.name);

  const file = await Api.req({
    method: 'post',
    url: '/feed/upload',
    data: formData,
  });

  return file.data.filename;
};

const CropWrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .ReactCrop__image {
    max-height: inherit;
  }
`;

const ImageCropModal = ({ setLocalImage, image, setImage }) => {
  const { t } = useTranslation(locales);
  // const dispatch = useDispatch();\

  const [uploading, setUploading] = useState(false);
  const [imageDOM, setImageDOM] = useState(null);
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 167,
    height: 200,
    aspect: 0.835,
  });

  const getCroppedImg = (img, c, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    canvas.width = c.width;
    canvas.height = c.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      img,
      c.x * scaleX,
      c.y * scaleY,
      c.width * scaleX,
      c.height * scaleY,
      0,
      0,
      c.width,
      c.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(t('Something went wrong'));
        } else {
          // eslint-disable-next-line no-param-reassign
          blob.name = fileName;
          resolve(blob);
        }
      }, 'image/jpeg');
    });
  };

  const submitHandle = async () => {
    try {
      setUploading(true);
      const croppedImg = await getCroppedImg(imageDOM, crop, 'temp.jpg');
      const img = await onSubmit(croppedImg);
      setImage(img, image.index);
    } catch (e) {
      // dispatch(appActions.addError(e));
    }

    setUploading(false);
    setLocalImage(null);
  };

  const imageLoaded = img => setImageDOM(img);
  const onCropChange = data => setCrop(data);

  return (
    <Modal
      title={t('Crop image')}
      onCancel={() => setLocalImage(null)}
      actions={[
        <Button
          key="poll-image-confirm"
          onClick={submitHandle}
          loading={uploading}
        >
          {t('global:Confirm')}
        </Button>,
      ]}
    >
      <CropWrapper>
        <ReactCrop
          src={image.src}
          onChange={onCropChange}
          onImageLoaded={imageLoaded}
          crop={crop}
          minWidth={100}
          minHeight={100}
          keepSelection
        />
      </CropWrapper>
    </Modal>
  );
};

ImageCropModal.propTypes = {
  setLocalImage: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  }).isRequired,
};

ImageCropModal.defaultProps = {
};

export default ImageCropModal;
