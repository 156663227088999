import { chakra } from '@chakra-ui/react';
import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const Check = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
  </svg>
);

Check.propTypes = IconType;
Check.defaultProps = IconDefaults;

export default memo(chakra(Check));
