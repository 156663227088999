import { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import isMobile from 'utils/isMobile';
import { useTranslation, useHotKeys } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as threadActions from 'state/threads/actions';

import { MarkAsRead } from 'components/Icons';
import Button from 'components/Button';
import Modal from 'components/Modal';

import locales from './i18n';

const MarkAllAsSeen = ({ communityId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  const theme = useContext(ThemeContext);

  const isLoggedIn = useSelector(authSelectors.loggedIn);

  const [showingMarkAsReadModal, setShowingMarkAsReadModal] = useState(false);
  const openMarkAsReadModal = useCallback(() => { setShowingMarkAsReadModal(true); }, []);
  const closeMarkAsReadModal = useCallback(() => { setShowingMarkAsReadModal(false); }, []);

  const markAllAsRead = useCallback(() => {
    if (communityId !== 'recent') {
      dispatch(threadActions.markCommunityAsRead(communityId));
    } else {
      dispatch(threadActions.markMembershipsAsRead());
    }
    closeMarkAsReadModal();
  }, [communityId, closeMarkAsReadModal, dispatch]);

  useHotKeys([{ key: 'Escape', shiftKey: true }], markAllAsRead);

  if (!isLoggedIn) return null;

  return (
    <>
      <Button
        onClick={openMarkAsReadModal}
        className="small round"
        color="white"
        light
      >
        <MarkAsRead color={isMobile ? theme.colors.mainLight : theme.colors.actionIcon} />
      </Button>

      {showingMarkAsReadModal && (
        <Modal
          title={t('Mark all as read')}
          onCancel={closeMarkAsReadModal}
          actions={[
            <Button key="mark-as-read-button" onClick={markAllAsRead}>{t('global:Confirm')}</Button>,
          ]}
        >
          {communityId !== 'recent'
            ? t('Are you sure you want to mark all threads in this community as read?')
            : t('Are you sure you want to mark all threads as read?')
          }
        </Modal>
      )}
    </>
  );
};

MarkAllAsSeen.propTypes = {
  communityId: PropTypes.string,
};

MarkAllAsSeen.defaultProps = {
  communityId: null,
};

export default MarkAllAsSeen;
