import styled from 'styled-components';

interface Props extends React.HTMLProps<HTMLDivElement> {
  hasTitle?: boolean;
}

const Container = styled.div<Props>`
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
  padding: 16px 20px 48px;
  box-sizing: border-box;
  overflow: auto;

  @media(max-width: 768px) {
    padding: 0;
    ${props => props.hasTitle && `
      margin-top: 64px;
    `}
  }

  @media(min-width: 768px) {
    max-width: 768px;
  }

  @media(min-width: 992px) {
    max-width: 992px;
  }

  @media(min-width: 1280px) {
    max-width: 1280px;
  }
`;

export default Container;
