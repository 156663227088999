import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  List,
  ListItem,
  Flex,
  Text,
  IconButton,
  Stack,
} from '@chakra-ui/react';
import { X } from 'lucide-react';

import * as appActions from 'state/app/actions';
import * as channelActions from 'state/channels/actions';
import { useTranslation, useSearchUsers } from 'hooks';

import { UserSearchInput, UserSearchResults } from 'components/UserSearch';
import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import locales from '../../i18n';

interface Props {
  channelId: string;
  close: () => void;
  isOpen: boolean;
}

const AddBanModal: React.FC<Props> = ({ channelId, close, isOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const [searchProps, results, reset] = useSearchUsers();
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [banning, setBanning] = useState(false);

  const addBan = (user: any) => setSelectedUser(user);
  const clearSelectedUser = () => {
    reset();
    setSelectedUser(null);
  };

  const onConfirm = async () => {
    try {
      if (!selectedUser) return;
      setBanning(true);
      await dispatch(channelActions.ban(channelId, selectedUser.id));
      dispatch(appActions.addToast(t('User banned')));
      close();
    } catch (error) {
      dispatch(appActions.addError(error));
      close();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Add ban')}</ModalHeader>
        <ModalBody>
          {!selectedUser ? (
            <>
              <Box position="relative" width="100%">
                <UserSearchInput
                  {...searchProps}
                  // @ts-ignore
                  onBlurTriggered={reset}
                  color="secondary"
                />
              </Box>
              <Box position="relative" width="100%" mt={4}>
                {results.length > 0 && <UserSearchResults results={results} onSelect={addBan} />}
              </Box>
            </>
          ) : (
            <List>
              <ListItem>
                <Flex alignItems="center">
                  <Box mr={2}>
                    <UserAvatar userId={selectedUser.id} />
                  </Box>
                  <Stack flex={1} spacing={0}>
                    <UserDisplayName userId={selectedUser.id} />
                    <Text fontSize="sm" color="gray.500">{`@${selectedUser.username}`}</Text>
                  </Stack>
                  <IconButton
                    icon={<X size={20} />}
                    onClick={clearSelectedUser}
                    aria-label="Remove selected user"
                    variant="ghost"
                  />
                </Flex>
              </ListItem>
            </List>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={close} mr={3}>
            {t('global:Cancel')}
          </Button>
          {selectedUser && (
            <Button variant="primary" onClick={onConfirm} isLoading={banning}>
              {t('global:Confirm')}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddBanModal;
