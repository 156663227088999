import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HStack } from '@chakra-ui/react';

import * as channelSelectors from 'state/channels/selectors';


import Avatar from 'components/ChannelAvatar';
import { Lock } from 'components/Icons';
import MainHeader from 'components/Header';

import Online from '../Online';
import Members from '../Members';
import { CHANNEL_PRIVACIES } from '../../../../constants';
import ChatHeaderActions from './Actions';

interface Props {
  channelId: string;
}

const Header: React.FC<Props> = ({ channelId }) => {
  const history = useHistory();

  const channelName = useSelector((state) => (
    // @ts-ignore
    channelSelectors.getName(state, channelId)
  ));
  const channelAvatar = useSelector((state) => (
    // @ts-ignore
    channelSelectors.getAvatar(state, channelId)
  ));
  const channelPrivacy = useSelector((state) => (
    // @ts-ignore
    channelSelectors.getPrivacy(state, channelId)
  ));

  const close = useCallback(() => {
    history.push('/chat');
  }, [history]);

  const renderAvatar = useCallback(() => (
    <Avatar image={channelAvatar} size="50px" />
  ), [channelAvatar]);

  const renderInfo = useCallback(() => (
    <HStack spacing={3}>
      <Members channelId={channelId} />
      <Online channelId={channelId} />
    </HStack>
  ), [channelId]);

  const renderActions = useCallback(() => <ChatHeaderActions />, []);

  const renderTitle = useCallback(() => (
    <HStack spacing={0}>
      {channelPrivacy === CHANNEL_PRIVACIES.PRIVATE && <Lock color="#AAA" />}
      <span>{channelName}</span>
    </HStack>
  ), [channelPrivacy, channelName]);

  return (
    <MainHeader
      renderTitle={renderTitle}
      renderAvatar={renderAvatar}
      renderInfo={renderInfo}
      renderActions={renderActions}
      onBackClick={close}
    />
  );
};

export default Header;
