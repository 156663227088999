export default {
  defaultProps: {
    colorScheme: 'black',
  },
  baseStyle: (props: any) => {
    if (!props.isIndeterminate) {
      return {
        filledTrack: {
          bg: 'gray.800',
        },
      };
    }
    return {};
  },
};
