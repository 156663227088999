import { useState } from 'react';
import {
  RadioGroup, Stack, Radio, Card,
  CardHeader,
  CardBody, Heading,
  Text,
  Spinner,
  Kbd,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import useToast from 'hooks/useToast';
import * as messengerSelectors from 'state/messengers/selectors';
import * as messengerActions from 'state/messengers/actions';

import { CHAT_REJECT_REASON } from '../../../constants';
import locales from '../i18n';

interface Props {
  messengerId: string;
}

const RejectReason: React.FC<Props> = ({ messengerId }) => {
  const { t } = useTranslation(locales);
  const toast = useToast();
  const dispatch = useDispatch();

  // @ts-ignore
  const rejectedReason = useSelector((state) => messengerSelectors.rejectedReason(state, messengerId));

  const [isSaving, setIsSaving] = useState(false);

  const handleRejectReason = async (reason: keyof typeof CHAT_REJECT_REASON) => {
    try {
      setIsSaving(true);

      await dispatch(messengerActions.rejectReason(messengerId, reason));

      toast.success(t('Thanks for your feedback!'));
    } catch (error) {
      toast.error(error);
    }

    setIsSaving(false);
  };

  return (
    <Card px={4} py={2} maxW="4xl" mx="auto" mt={6}>
      <CardHeader>
        <Heading size="sm">{t('Optional. Tell us why you decided to reject this chat request')}</Heading>
        <Text fontSize="sm" color="gray.500">{t('This information won\'t be published in any way and it will be only used to build a proper reputation of the user.')}</Text>
      </CardHeader>

      <CardBody>
        <RadioGroup onChange={handleRejectReason} value={rejectedReason} colorScheme="blackAlpha" isDisabled={isSaving}>
          <Stack direction="column">
            {Object.values(CHAT_REJECT_REASON).map((reason) => (
              <Radio value={reason} key={reason}>
                {t(reason)}
                {reason === CHAT_REJECT_REASON.GATOR && (
                  <a href="https://mazmo.net/+tecnicas-y-precauciones/dominante-pejelagarto-wziut07ru3" target="_blank" rel="noreferrer" style={{ marginLeft: '8px' }}>
                    <Kbd>?</Kbd>
                  </a>
                )}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
        {isSaving && <Spinner size="sm" color="gray.300" position="absolute" bottom={2} right={2} />}
      </CardBody>
    </Card>
  );
};

export default RejectReason;
