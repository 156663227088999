import styled from 'styled-components';

const SpankListWrapper = styled.div`
  display: flex;
  max-height: calc(100vh - 220px);
  overflow-y: auto;
  overflow-x: hidden;

  > div {
    flex: 1;

    &:nth-child(2) {
      .reaction-type {
        text-align: right;
      }

      .reactioners {
        .userlink {
          flex-direction: row-reverse;
        }
      }
    }

    .empty-list {
      color: ${props => props.theme.colors.secondary};
      margin: 4px 0;
      text-align: center;
    }

    .reaction-type {
      margin-bottom: 16px;

      svg {
        width: 32px;
        height: 32px;

        path {
          fill: ${props => props.theme.colors.main};
        }
      }
    }

    .reactioners {
      display: flex;
      flex-direction: column;

      .userinactive {
        opacity: .5;
      }

      .userlink, .userinactive {
        display: flex;
        align-items: center;
        margin: 0 8px 8px;

        &:hover {
          color: ${props => props.theme.colors.main};
        }

        .displayname {
          margin-left: 8px;
          margin-right: 8px;
        }
      }

      @media(max-width: 768px) {
        font-size: 12px;
        margin: 8px;

        .avatar {
          width: 24px;
          height: 24px;

          img {
            width: 24px;
            height: 24px;
          }
        }

        .userlink {
          .displayname {
            margin-left: 4px;
            margin-right: 4px;
          }
        }
      }
    }
  }
`;
SpankListWrapper.displayName = 'SpankListWrapper';

export default SpankListWrapper;
