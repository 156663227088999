import { createSelector } from 'reselect';

// Input selectors
export const selectInvoices = state => state.invoices.invoices;
export const selectData = state => state.invoices.data;

//

export const hasInvoices = createSelector(
  selectInvoices,
  ids => ids.length > 0,
);

export const unpaidInvoicesCount = createSelector(
  selectData,
  data => Object.values(data).filter(i => i.status === 'GENERATED').length,
);

const selectInvoice = createSelector(
  selectData,
  (_, id) => id,
  (data, id) => data[id],
);

export const getTotal = createSelector(
  selectInvoice,
  invoice => invoice?.total || 0,
);

export const getSubtotal = createSelector(
  selectInvoice,
  invoice => invoice?.subtotal || 0,
);

export const getItems = createSelector(
  selectInvoice,
  invoice => invoice?.items || [],
);

export const getCoupon = createSelector(
  selectInvoice,
  invoice => invoice?.coupon || null,
);

export const isPayed = createSelector(
  selectInvoice,
  invoice => invoice?.status === 'PAYED' || false,
);

export const selectPreferenceInitPoint = createSelector(
  selectInvoice,
  invoice => invoice?.preference?.init_point || null,
);
