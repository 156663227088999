import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { useTranslation } from 'hooks';

import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const RelationshipRequest = ({ timestamp, userId, read }) => {
  const { t } = useTranslation(locales);

  return (
    <AlertContainer timestamp={timestamp} image={<UserAvatar userId={userId} size="40px" showOnline={false} />} to="/user/relationships" read={read}>
      <Trans t={t} ns="alerts" i18nKey="relationship.request">
        Do you have a relationship with
        {' '}
        <strong><UserDisplayName userId={userId} /></strong>
        ?
      </Trans>
    </AlertContainer>
  );
};

RelationshipRequest.propTypes = {
  read: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default RelationshipRequest;
