import styled from 'styled-components';

import { useTranslation } from 'hooks';

import Button from 'components/Button';

import locales from '../i18n';

const Wrapper = styled.div`
  text-align: center;
  padding: 64px 32px;
  background: ${props => props.theme.colors.mainLight};

  .buttons {
    margin-top: 64px;

    button {
      margin: 0 16px;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

const NotLoggedIn = () => {
  const { t } = useTranslation(locales);

  return (
    <Wrapper>
      <h2>{t('Do you want to get in the conversation?')}</h2>
      <p>{t('In order to be an active participant in the communities, you need to have a valid user and be logged in. Don\'t worry, registering is free, fast and easy!')}</p>
      <div className="buttons">
        <Button to="/login" color="white" fontColor="black">{t('global:Log in')}</Button>
        <Button to="/signup">{t('global:Sign up')}</Button>
      </div>
    </Wrapper>
  );
};

NotLoggedIn.propTypes = {
};

NotLoggedIn.defaultProps = {
};

export default NotLoggedIn;
