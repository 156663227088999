import styled from 'styled-components';

import colors from 'utils/css/colors';

const Content = styled.div`
  color: ${colors.blackRed};
  display: flex;
  flex-direction: column;
  ${props => props.fullHeight && `
    height: 100%;
  `}
`;

export default Content;
