import { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as membershipSelectors from 'state/memberships/selectors';
import * as communitySelectors from 'state/communities/selectors';
import * as communityActions from 'state/communities/actions';

import Info from 'containers/Communities/Info';
import ThreadList from 'containers/Communities/ThreadList';
import CommunityList from 'containers/Communities/CommunityList';
import Thread from 'containers/Communities/Thread';
import NotFound from 'containers/NotFound';
import PrivateAndNotMember from 'containers/Communities/NotAuthorized/PrivateAndNotMember';
import Layout from 'components/Layout';
import Loading from 'components/Loading';

import Empty from './Empty';
import locales from './i18n';

const Dashboard = () => {
  const { t } = useTranslation(locales);
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const userIsLoggedIn = useSelector(authSelectors.loggedIn);
  const approvedCount = useSelector(membershipSelectors.approvedCount);
  const isNotFound = useSelector(
    state => communitySelectors.selectIsNotFound(state, params.communitySlug),
  );
  const userIsBanned = useSelector(
    state => communitySelectors.selectUserIsBanned(state, params.communitySlug),
  );
  const communityId = useSelector(
    state => communitySelectors.selectIdBySlug(state, params.communitySlug),
  );
  const hasApprovedMembership = useSelector(
    state => membershipSelectors.hasApprovedMembership(state, communityId),
  );
  const privacy = useSelector(state => communitySelectors.selectPrivacy(state, communityId));
  const isEmpty = useSelector(state => communitySelectors.isEmpty(state, communityId));

  useEffect(() => {
    if (params.communitySlug) {
      dispatch(communityActions.fetch(params.communitySlug));
    }
  }, [dispatch, params.communitySlug]);

  if (params.communitySlug) {
    if (userIsBanned) return <NotFound text={t('global:BANNED')} />;
    if (isNotFound) return <NotFound />;
    if (!communityId) return <Loading />;

    if (!hasApprovedMembership && privacy === 'PRIVATE') {
      // Community is private and user has no access
      return <PrivateAndNotMember communityId={communityId} />;
    }

    if (isEmpty) return <Empty communityId={communityId} />;
  }

  if (!approvedCount && !params.communitySlug) {
    history.push('/communities/explore');
    return null;
  }

  const showingCommunity = !userIsLoggedIn || (params.communitySlug && !location.search.includes('showAll'));

  return (
    <Layout columns={3}>
      {showingCommunity
        ? <Info communitySlug={params.communitySlug} />
        : <CommunityList />
      }
      <ThreadList />
      <Thread />
    </Layout>
  );
};

Dashboard.propTypes = {
};

Dashboard.defaultProps = {
};

export default Dashboard;
