import styled from 'styled-components';
import colors from 'utils/css/colors';

interface WrapperProps {
  width: string;
  maxHeight?: string;
  children: React.ReactNode;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: white;
  width: ${props => props.width};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.08);
  max-height: ${props => (props.maxHeight ? props.maxHeight : '292px')};
  overflow: auto;
  text-transform: none;
  font-weight: normal;

  @media(max-width: 767px) {
    width: 100%;
  }

  > div:not(.nav-dd-content) {
    color: black;
    line-height: 1.2;
    min-height: 48px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    cursor: pointer;

    &:hover {
      color: ${colors.red};
    }
  }
`;

const DropdownMenu: React.FC<WrapperProps> = ({ children, width, maxHeight }) => (
  <Wrapper width={width} maxHeight={maxHeight}>
    {children}
  </Wrapper>
);

export default DropdownMenu;
