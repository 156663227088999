import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import * as appSelectors from 'state/app/selectors';
import * as appActions from 'state/app/actions';

import { Send } from 'components/Icons';

const ButtonComponent = styled.button.attrs(props => ({
  onClick: props.onClick,
  loading: undefined,
}))`
  background-color: transparent;
  color: ${colors.grey};
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  transition: all 250ms ease-out;
  cursor: pointer;
  flex-shrink: 0;

  &:focus {
    outline: none;
  }

  &:hover:not([disabled]) {
    color: ${colors.red};
    svg {
      fill: ${colors.red};
      transform: translate3d(5px, 0, 0);
    }
  }
`;

const SendWrapper = styled.div`
  width: 16px;
  margin-left: 8px;
  svg {
    fill: ${colors.grey};
    transition: all 250ms ease-out;
  }
`;

const SubmitText = styled.span`
  @media(max-width: 768px) {
    display: none;
  }
`;

const SubmitButton = ({ id, text }) => {
  const dispatch = useDispatch();

  const canSubmit = useSelector(state => appSelectors.selectIsComposerReadyToSubmit(state, id));

  const onButtonClick = async () => {
    await dispatch(appActions.composerSendToServer(id));
  };

  const Component = ButtonComponent;

  return (
    <Component
      onClick={canSubmit ? onButtonClick : undefined}
      disabled={!canSubmit}
    >
      <>
        <SubmitText>{text}</SubmitText>
        <SendWrapper>
          <Send color={!canSubmit ? colors.grey : 'black'} />
        </SendWrapper>
      </>
    </Component>
  );
};

SubmitButton.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
};

SubmitButton.defaultProps = {
  text: 'Send',
};

export default SubmitButton;
