import PropTypes from 'prop-types';
import shortid from 'shortid';

const Image = ({
  optimized, src, path, alt, width, height, ...rest
}) => {
  if (!optimized || !optimized.length) {
    return <img src={src} width={width} height={height} alt={alt} {...rest} />;
  }

  const data = { src, width, height };

  return (
    <picture>
      {optimized.reverse().map((o) => {
        const filename = o.filename || o.blurred;

        if (!filename) return <div key={shortid.generate()} />;

        const parts = filename.split('-');
        const w = parseInt(parts[parts.length - 1].split('.')[0].replace('w', ''), 10);
        if (w === 668 && o.type === 'jpeg') {
          data.src = `${path}/${filename}`;
          data.width = o.metadata.width;
          data.height = o.metadata.height;
        }

        return (
          <source
            key={filename}
            type={`image/${o.type}`}
            srcSet={`${path}/${filename} ${o.metadata.width}w`}
            media={w < 668 ? `(max-width: ${o.metadata.width}px)` : undefined}
          />
        );
      })}

      {data.src && (
        <img
          src={data.src}
          width={data.width}
          height={data.height}
          alt={alt}
          {...rest}
        />
      )}
    </picture>
  );
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  optimized: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['webp', 'jpeg']).isRequired,
    filename: PropTypes.string,
    blurred: PropTypes.string,
    metadata: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
  })),
  path: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Image.defaultProps = {
  src: null,
  alt: 'Image',
  optimized: null,
  path: '',
  width: null,
  height: null,
};

export default Image;
