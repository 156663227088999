import React from 'react';
import styled from 'styled-components';

import colors from 'utils/css/colors';

interface SelectorProps extends React.HTMLProps<HTMLDivElement> {
  selected?: boolean;
}

const Selector = styled.div<SelectorProps>`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  margin-bottom: 32px;
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    background: white;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 1px solid ${colors.grey};
    position: absolute;
    top: 4px;
    left: 0;
    transition: all 250ms ease-out;
  }
`;

const SelectorChecked = styled(Selector)`
  &:before {
    background: ${colors.red};
    border: 1px solid ${colors.red};
  }
`;

interface LabelProps extends React.HTMLProps<HTMLParagraphElement> {
  disabled?: boolean;
}

const Label = styled.p<LabelProps>`
  position: relative;
  font-weight: bold;

  ${props => props.disabled && `
    color: #999;
  `}
`;

const Description = styled.p<LabelProps>`
  margin: 0;
  color: ${props => (props.disabled ? '#aaa' : colors.redReactions)}
`;

interface Props {
  label: string;
  selected: boolean;
  description?: string;
  onSelect: (evnt: React.MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
}

const Radio: React.FC<Props> = ({
  label,
  selected,
  description,
  onSelect,
  disabled,
}) => {
  const SelectorComponent = selected ? SelectorChecked : Selector;

  return (
    <SelectorComponent onClick={(event: React.MouseEvent<HTMLDivElement>) => !disabled && onSelect(event)}>
      <Label disabled={disabled}>{label}</Label>
      <Description disabled={disabled}>{description}</Description>
    </SelectorComponent>
  );
};

export default Radio;
