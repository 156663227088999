import { useSelector } from 'react-redux';

import Api from 'state/api';
import * as authSelectors from 'state/auth/selectors';

const useAuth = () => {
  const userJWT = useSelector(authSelectors.selectJWT);
  const userId = useSelector(authSelectors.selectId);

  return {
    switchToUser: () => {
      const jwt = localStorage.getItem('userJWT');

      Api.unsetJWT();
      const { notificationToken } = window.localStorage;
      localStorage.removeItem('jwt');
      localStorage.removeItem('userJWT');
      localStorage.removeItem('userId');
      if (notificationToken !== null) {
        localStorage.removeItem('notificationToken');
      }

      localStorage.setItem('jwt', jwt ?? '');

      window.location.href = '/';
    },

    switchToOrganization: (jwt: string) => {
      Api.unsetJWT();
      const { notificationToken } = window.localStorage;
      localStorage.removeItem('jwt');
      if (notificationToken !== null) {
        localStorage.removeItem('notificationToken');
      }

      localStorage.setItem('userJWT', userJWT);
      localStorage.setItem('userId', userId);
      localStorage.setItem('jwt', jwt);

      window.location.href = '/';
    },
  };
};

export default useAuth;
