import { useEffect } from 'react';
import {
  BoxProps,
  Card, CardBody, CloseButton, Flex, Icon, Skeleton, Spinner, Text,
} from '@chakra-ui/react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { LinkIcon } from 'lucide-react';

import * as feedSelectors from 'state/feed/selectors';
import * as feedActions from 'state/feed/actions';
import * as messengerSelectors from 'state/messengers/selectors';
import * as messengerActions from 'state/messengers/actions';

import Image from 'components/Publication/MoreContent/Image';

import Url from 'components/Publication/MoreContent/Url';
import { PUBLICATION_TYPES } from '../../../../constants';

interface Props extends BoxProps {
  messengerId: string;
}

const LinkPublication: React.FC<Props> = ({ messengerId, ...rest }) => {
  const dispatch = useDispatch();

  // @ts-ignore
  const publicationId = useSelector(state => messengerSelectors.getPublicationId(state, messengerId));

  const rawContent = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectRawContent(state, publicationId),
  );
  // @ts-ignore
  const type = useSelector(state => feedSelectors.publications.selectType(state, publicationId));
  const media = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectMedia(state, publicationId),
    shallowEqual,
  );

  useEffect(() => {
    if (publicationId && !type) {
      dispatch(feedActions.loadPublication(publicationId));
    }

    return () => {
      dispatch(messengerActions.clearPublicationId(messengerId));
    };
  }, [publicationId, type, dispatch, messengerId]);

  if (!publicationId) return null;

  const removePublication = () => {
    dispatch(messengerActions.clearPublicationId(messengerId));
  };

  return (
    <Card w="200px" maxH="132px" overflow="hidden" {...rest}>
      <CloseButton position="absolute" right={0} top={0} onClick={removePublication} bgColor="white" zIndex={9} />
      <CardBody p={2}>
        {type === PUBLICATION_TYPES.MEDIA && media.length > 0 && (
          <Flex bgColor="black" justifyContent="center" mb={1}>
            <Image
              src={media[0].filename}
              alt={media[0].filename}
              width={media[0].metadata?.width}
              height={media[0].metadata?.height}
              optimized={media[0].optimized}
              path={media[0].path}
              style={{
                maxWidth: '180px', maxHeight: '100px', objectFit: 'contain',
              }}
            />
          </Flex>
        )}
        {type === PUBLICATION_TYPES.LINK && (
          <Url publicationId={publicationId} />
        )}
        {type !== PUBLICATION_TYPES.MEDIA && type !== PUBLICATION_TYPES.TEXT && !!type && (
          <Flex bgColor="gray.100" justifyContent="center" mb={1} h="100px" alignItems="center">
            <Icon as={LinkIcon} boxSize="24px" strokeWidth={1} />
          </Flex>
        )}
        {!type && (
          <>
            <Flex bgColor="gray.100" justifyContent="center" mb={1} h="100px" alignItems="center">
              <Spinner color="gray.500" />
            </Flex>
            <Skeleton height="16px" />
          </>
        )}
        <Text
          fontSize="sm"
          noOfLines={type === PUBLICATION_TYPES.TEXT ? undefined : 1}
          color="gray.500"
          textOverflow="ellipsis"
          lineHeight={1}
        >
          {rawContent}
        </Text>
      </CardBody>
    </Card>
  );
};

export default LinkPublication;
