import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';
import * as channelSelector from 'state/channels/selectors';

import { useTitle } from 'hooks';

import Loading from 'components/Loading';

import NotMember from './NotMember';
import ChatArea from './ChatArea';
import Header from './Header';
import Wrapper from '../Content/Wrapper';

const ChannelContent = ({ channelId }) => {
  const userIsLoggedIn = useSelector(authSelectors.loggedIn);
  const channelName = useSelector(state => channelSelector.getName(state, channelId));
  const isInitialized = useSelector(channelSelector.isInitialized);

  useTitle(`Chat ${channelName || ''}`);

  if (userIsLoggedIn && !isInitialized) return <Loading />;
  if (!userIsLoggedIn || !channelName) return <NotMember id={channelId} />;

  return (
    <Wrapper>
      <Header channelId={channelId} />
      <ChatArea id={channelId} />
    </Wrapper>
  );
};

ChannelContent.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default ChannelContent;
