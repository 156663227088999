import { memo } from 'react';
import { chakra } from '@chakra-ui/react';

import { IconType, IconDefaults } from './type';

const MessageReply = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M22,4C22,2.89 21.1,2 20,2H4A2,2 0 0,0 2,4V16A2,2 0 0,0 4,18H18L22,22V4Z"
    />
  </svg>
);

MessageReply.propTypes = IconType;
MessageReply.defaultProps = IconDefaults;

export default memo(chakra(MessageReply, { shouldForwardProp: (prop) => ['outline'].includes(prop) }));
