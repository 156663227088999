import { useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactCrop from 'react-image-crop';
import { useDispatch } from 'react-redux';

import 'react-image-crop/dist/ReactCrop.css';

import * as appActions from 'state/app/actions';
import { useTranslation } from 'hooks';

import Button from 'components/Button';
import Modal from 'components/Modal';

import locales from './i18n';

const CropWrapper = styled.div`
  width: 300px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .ReactCrop__image {
    max-height: inherit;
  }
`;

interface Props extends RouteComponentProps {
  onSubmit: (blob: Blob) => Promise<void>;
  image: {
    src: string;
  };
  close: () => void;
}

const AvatarModal: React.FC<Props> = ({ onSubmit, image, close }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const [uploading, setUploading] = useState(false);
  const [imageDOM, setImageDOM] = useState(null);
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    aspect: 1,
  });

  const getCroppedImg = (img: any, c: any, fileName: string) => {
    const canvas = document.createElement('canvas');
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    canvas.width = c.width;
    canvas.height = c.height;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      img,
      c.x * scaleX,
      c.y * scaleY,
      c.width * scaleX,
      c.height * scaleY,
      0,
      0,
      c.width,
      c.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob: Blob | null) => {
        if (!blob) {
          reject(t('Something went wrong'));
        } else {
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          blob.name = fileName;
          resolve(blob);
        }
      }, 'image/jpeg');
    });
  };

  const submitHandle = async () => {
    try {
      setUploading(true);
      const croppedImg = await getCroppedImg(imageDOM, crop, 'temp.jpg');
      await onSubmit(croppedImg as Blob);
    } catch (e) {
      dispatch(appActions.addError(e));
    }

    setUploading(false);
    close();
  };

  const imageLoaded = (img: any) => setImageDOM(img);
  const onCropChange = (data: any) => setCrop(data);

  return (
    <Modal
      title={t('Change avatar')}
      onCancel={close}
      actions={[
        <Button
          key="edit-community-confirm"
          onClick={submitHandle}
          loading={uploading}
        >
          {t('global:Confirm')}
        </Button>,
      ]}
    >
      <CropWrapper>
        <ReactCrop
          src={image.src}
          onChange={onCropChange}
          onImageLoaded={imageLoaded}
          crop={crop}
          minWidth={100}
          minHeight={100}
          keepSelection
        />
      </CropWrapper>
    </Modal>
  );
};

export default withRouter(AvatarModal);
