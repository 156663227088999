import { useState } from 'react';
import {
  Box, Icon, IconButton, Tooltip,
} from '@chakra-ui/react';
import { MessageSquareReply } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as messengerActions from 'state/messengers/actions';
import * as messengerSelectors from 'state/messengers/selectors';
import * as feedSelectors from 'state/feed/selectors';
import * as authSelectors from 'state/auth/selectors';
import { useTranslation } from 'hooks/index';

import locales from '../i18n';

interface Props {
  publicationId: string;
}

const ReplyPrivately: React.FC<Props> = ({ publicationId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(locales);

  const authorId = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectAuthorId(state, publicationId),
  );
  // @ts-ignore
  const messengerId = useSelector(state => messengerSelectors.selectByUserId(state, authorId));
  // @ts-ignore
  const userId = useSelector(authSelectors.selectId);

  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    dispatch(messengerActions.addPublicationId(messengerId, publicationId));
    if (messengerId) {
      history.push(`/chat/messengers/${messengerId}`);
    } else {
      setIsLoading(true);
      const messenger: any = await dispatch(messengerActions.create([authorId]));
      history.push(`/chat/messengers/${messenger.id}`);
    }
  };

  if (authorId === userId) return <Box />;

  return (
    <Tooltip label={t('Reply privately')} hasArrow>
      <IconButton
        aria-label="Reply private"
        icon={<Icon as={MessageSquareReply} boxSize="24px" strokeWidth={1} />}
        onClick={onClick}
        isLoading={isLoading}
      />
    </Tooltip>
  );
};

export default ReplyPrivately;
