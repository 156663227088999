import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const PublicationReaction = ({
  user,
  publication_text: publicationText,
  publication,
  read,
  timestamp,
}) => {
  const { t } = useTranslation(locales);

  return (
    <AlertContainer timestamp={timestamp} image={<UserAvatar userId={user.id} />} size="40px" showOnline={false} to={`/publications/${publication}`} read={read}>
      <strong><UserDisplayName userId={user.id} /></strong>
      {' '}
      {t('spanked you a publication')}
      {': '}
      {publicationText}
    </AlertContainer>
  );
};

PublicationReaction.propTypes = {
  read: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    displayname: PropTypes.string.isRequired,
  }).isRequired,
  publication_text: PropTypes.string,
  publication: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
};

PublicationReaction.defaultProps = {
  publication_text: '',
};

export default PublicationReaction;
