import PropTypes from 'prop-types';

const Gif = ({ data }) => (
  <img
    className="gif"
    alt={data.title}
    src={data.images.downsized.url}
    width={data.images.downsized.width}
    height={data.images.downsized.height}
    style={{ marginBottom: '8px' }}
  />
);

Gif.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    images: PropTypes.shape({
      downsized: PropTypes.shape({
        url: PropTypes.string.isRequired,
        width: PropTypes.string.isRequired,
        height: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

export default Gif;
