import { memo } from 'react';

import { IconDefaults } from './type';
import { IconProps } from './types';

const Close = ({ color, ...props }: IconProps) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill={color} />
  </svg>
);

Close.defaultProps = IconDefaults;

export default memo(Close);
