import {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';

import Api from 'state/api';
import { useTranslation } from 'hooks';

import Select from 'components/Select';
import Spinner from 'components/Spinner';

import locales from './i18n';

const Regions = ({
  value,
  onChange,
  countryId,
  simple,
  solid,
}) => {
  const { t } = useTranslation(locales);

  const [regions, setRegions] = useState(null);

  useEffect(() => {
    const load = async () => {
      setRegions(null);
      const { data } = await Api.req.get(`/users/geo/countries/${countryId}/regions`);
      setRegions(data);
    };

    if (countryId) {
      load();
    }
  }, [countryId]);

  const setRegion = useCallback((v) => {
    onChange(v.value);
  }, [onChange]);

  const options = useMemo(() => (
    (regions || []).map(c => ({
      label: c.name,
      value: c.id,
    }))
  ), [regions]);

  if (!countryId || (regions && !regions.length)) return null;
  if (regions === null) return <Spinner color="#A8A8A8" />;

  return (
    <Select
      value={options.find(c => c.value === value)}
      onChange={setRegion}
      placeholder={t('Region')}
      options={options}
      simple={simple}
      solid={solid}
    />
  );
};

Regions.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  countryId: PropTypes.string,
  simple: PropTypes.bool,
  solid: PropTypes.bool,
};

Regions.defaultProps = {
  value: null,
  countryId: null,
  simple: false,
  solid: false,
};

export default Regions;
