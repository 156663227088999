import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import * as userSelectors from 'state/users/selectors';
import * as messengerSelectors from 'state/messengers/selectors';

import Avatar from 'components/UserAvatar';
import { SelectableListItem } from 'components/SelectableList';

const Messenger = ({ id, active, onSelect }) => {
  const isArchived = useSelector(state => messengerSelectors.isArchived(state, id));
  const messengerUserId = useSelector(state => messengerSelectors.getUserId(state, id));
  const user = useSelector(userSelectors.getById(messengerUserId), shallowEqual);

  return (
    <SelectableListItem
      avatar={<Avatar userId={user.id} size="24px" />}
      title={user.displayname}
      onClick={onSelect(id)}
      active={active}
      style={{ opacity: !isArchived ? '1' : '0.5' }}
    />
  );
};

Messenger.propTypes = {
  id: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

Messenger.defaultProps = {
};

export default Messenger;
