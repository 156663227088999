import { createSelector } from 'reselect';
import moment from 'moment';

import * as threadSelectors from 'state/threads/selectors';
import * as membershipSelectors from 'state/memberships/selectors';

// Input selectors

const selectData = state => state.communities.data;

//

export const selectById = createSelector(
  selectData,
  (_, id) => id,
  (data, id) => data[id],
);

export const selectBySlug = createSelector(
  selectData,
  (_, slug) => slug,
  (data, slug) => {
    if (data[slug]) return data[slug];
    return Object.values(data).find(t => t.slug === slug);
  },
);

export const getIdBySlug = createSelector(
  selectData,
  (_, slug) => slug,
  (data, slug) => {
    if (data[slug]) return data[slug].id;
    return Object.values(data).find(t => t.slug === slug)?.id;
  },
);

export const selectEventableIds = createSelector(
  selectData,
  data => Object.values(data).filter(c => c.hasEvents).map(c => c.id),
);

export const selectAllSlugs = createSelector(
  selectData,
  (data) => {
    const slugs = {};
    Object.values(data).forEach((community) => {
      slugs[community._id] = community.slug;
    });

    return slugs;
  },
);

export const selectAllIdsSortedAndFiltered = createSelector(
  selectData,
  (_, filter) => filter || '',
  (data, filter) => (
    Object.values(data)
      .filter((community) => community?.name?.toLowerCase().includes(filter.toLowerCase()))
      .sort((a, b) => b.membershipCount - a.membershipCount)
      .map((community) => community.id)
  ),
);

export const selectSlugById = createSelector(
  selectById,
  community => (community ? community.slug : null),
);

export const selectIdBySlug = createSelector(
  selectBySlug,
  community => (community ? community._id : null),
);

export const selectIsFetching = createSelector(
  selectBySlug,
  community => (community ? community.loading : false),
);

export const selectIsNotFound = createSelector(
  selectBySlug,
  community => (community ? community.notFound : false),
);

export const selectUserIsBanned = createSelector(
  selectBySlug,
  community => (community ? community.banned : false),
);

export const selectPrivacy = createSelector(
  selectById,
  community => (community ? community.privacy : null),
);

export const selectName = createSelector(
  selectById,
  community => (community ? community.name : null),
);

export const selectDescription = createSelector(
  selectById,
  community => community.description,
);

export const selectAvatar = createSelector(
  selectById,
  community => community.avatar,
);

export const selectCover = createSelector(
  selectById,
  community => community.cover,
);
export const getCover = id => ({ communities: state }) => state.data[id].cover;

export const hasEvents = createSelector(
  selectById,
  community => !!community.hasEvents,
);

export const selectMembershipCount = createSelector(
  selectById,
  community => community.membershipCount,
);

export const selectThreadCount = createSelector(
  selectById,
  community => community.threadCount,
);

export const selectReplyCount = createSelector(
  selectById,
  community => community.replyCount,
);

export const selectMods = createSelector(
  selectById,
  community => (community && community.mods) || [],
);

export const isEmpty = createSelector(
  selectById,
  threadSelectors.isCommunityFullyLoaded,
  (community, isFullyLoaded) => (community ? isFullyLoaded && !community.threads.length : false),
);

const communitySort = (communityA, communityB) => {
  if (!communityA.lastUpdate && !communityB.lastUpdate) return 0;
  if (communityA.lastUpdate && !communityB.lastUpdate) return -1;
  if (!communityA.lastUpdate && communityB.lastUpdate) return 1;
  return moment(communityB.lastUpdate.at).diff(communityA.lastUpdate.at);
};

// TODO: Memoize
export const selectMyCommunityIdsSorted = createSelector(
  membershipSelectors.selectApproved,
  selectData,
  (memberships, data) => {
    const uniqueMemberships = new Set();
    memberships.forEach(mbsp => uniqueMemberships.add(mbsp.community));
    const myCommunities = [...uniqueMemberships]
      .map(id => data[id])
      .sort(communitySort)
      .map(c => c.id);

    return myCommunities;
  },
);
