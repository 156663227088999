import {
  useCallback, useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import fastdom from 'fastdom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';

import ParsedContent from 'components/ParsedContent';

import { Content as ContentWrapper, ContentShadow, ShowAll } from './UI';

import locales from './i18n';

const Content = ({ publicationId, full }) => {
  const { t } = useTranslation(locales);

  const elementRef = useRef(null);

  const [cap, setCap] = useState(!full);
  const [showShowAll, setShowShowAll] = useState(false);
  const rawContent = useSelector(
    state => feedSelectors.publications.selectRawContent(state, publicationId),
  );
  const markdownAllowed = useSelector(
    state => feedSelectors.publications.selectIsMarkdownAllowed(state, publicationId),
  );

  const showAll = useCallback(() => {
    setCap(false);
  }, []);

  useEffect(() => {
    fastdom.measure(() => {
      if (elementRef.current && elementRef.current.scrollHeight > 250) {
        setShowShowAll(true);
      }
    });
  }, []);

  if (!rawContent) return null;

  return (
    <ContentWrapper cap={cap} ref={elementRef}>
      <div>
        <ParsedContent content={rawContent} markdown={markdownAllowed} disallowed={['img', 'h1', 'h2', 'h3', 'table', 'hr']} />
      </div>
      {cap && showShowAll && (
        <>
          <ContentShadow />
          <ShowAll onClick={showAll}>{t('Show all')}</ShowAll>
        </>
      )}
    </ContentWrapper>
  );
};

Content.propTypes = {
  publicationId: PropTypes.string.isRequired,
  full: PropTypes.bool.isRequired,
};

Content.defaultProps = {
};

export default Content;
