import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';
import * as appActions from 'state/app/actions';
import * as communityActions from 'state/communities/actions';
import * as membershipActions from 'state/memberships/actions';

import Button from 'components/Button';
import Modal from 'components/Modal';
import DeleteConfirmation from 'components/DeleteConfirmation';

import locales from './i18n';

const DeleteModal = ({
  communityId,
  close,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(locales);

  const slug = useSelector(state => communitySelectors.selectSlugById(state, communityId));

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const onSubmitClick = async () => {
    try {
      setDeleting(true);
      await dispatch(communityActions.del(communityId));
      await dispatch(membershipActions.removeByCommunityId(communityId));
      history.push('/communities/explore');
      dispatch(appActions.addToast(t('Community deleted')));
    } catch (e) {
      dispatch(appActions.addError(e.message));
      setDeleting(false);
      close();
    }
  };

  return (
    <Modal
      title={t('Delete community')}
      onCancel={close}
      actions={[
        <Button key="edit-community-confirm" onClick={onSubmitClick} disabled={!submitEnabled} loading={deleting}>{t('global:Confirm')}</Button>,
      ]}
    >
      <DeleteConfirmation
        warning={t('Are you sure you want to delete this community?')}
        validString={slug}
        onEnabledChange={setSubmitEnabled}
      />
    </Modal>
  );
};

DeleteModal.propTypes = {
  communityId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default DeleteModal;
