import { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import colors from 'utils/css/colors';
import * as appActions from 'state/app/actions';

import MobileMenuButton from 'components/MobileMenuButton';

interface ContainerProps extends React.HTMLProps<HTMLDivElement> {
  full?: boolean;
  height?: number;
}

const Container = styled.div<ContainerProps>`
  margin: 16px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 64px);

  ${props => props.full && `
    height: 100%;
  `}

  ${props => (props.height && props.height > 150) && `
    height: ${props.height}px !important;
  `}
`;

const Wrapper = styled.div`
  text-align: center;
  flex: 1;
  display: flex;

  > div {
    align-self: center;
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 200;
  margin-bottom: 8px;
  margin-top: 16px;
`;

const Subtitle = styled.div`
  color: ${colors.redReactions};
  margin-bottom: 64px;
`;

const Content = styled.div``;

interface EmptyStateProps {
  title?: string;
  subtitle?: React.ReactNode;
  onMenuClick?: () => void;
  children?: React.ReactNode;
  uiLeftColumn?: boolean;
  full?: boolean;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  title = '',
  subtitle = '',
  children,
  onMenuClick,
  uiLeftColumn,
  full,
}) => {
  const dispatch = useDispatch();
  const height = useRef<number>(null);
  const element = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (uiLeftColumn) {
      dispatch(appActions.uiLeftColumn(true));
    }

    if (element.current) {
      // @ts-ignore
      height.current = document.body.offsetHeight - element.current.offsetTop - 64 - 20;
    }
  }, [uiLeftColumn, dispatch]);

  return (
    <Container className="emptystate" full={full} ref={element} height={height.current ? height.current : undefined}>
      {onMenuClick && <MobileMenuButton onClick={onMenuClick} />}
      <Wrapper>
        <div>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          {children && <Content>{children}</Content>}
        </div>
      </Wrapper>
    </Container>
  );
};

export default EmptyState;
