import { useSelector } from 'react-redux';
import { VStack } from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import * as messengerSelectors from 'state/messengers/selectors';

import EmptyState from 'components/EmptyState';
import LinkPublication from 'containers/Chat/Content/Bubble/Messenger/LinkPublication';

import RequestBubble from '../../Content/Bubble/RequestBubble';
import Actions from './actions';
import locales from '../../i18n';

interface Props {
  id: string;
  requester: {
    id: number,
    displayname: string,
    username: string,
    pronoun: string,
  };
  rejected: boolean;
  isBlocked?: boolean;
}

const ResolveRequest: React.FC<Props> = ({
  id,
  requester,
  rejected,
  isBlocked,
}) => {
  const { t } = useTranslation(locales);

  // @ts-ignore
  const publication = useSelector(state => messengerSelectors.getRequestPublication(state, id));
  // @ts-ignore
  const message = useSelector(state => messengerSelectors.getRequestMessage(state, id));

  return (
    <EmptyState
      title={t('Do you want to chat with {{username}}?', { username: requester.displayname })}
      subtitle={(
        <>
          {t('If you choose to accept, the user will be notified and you can start chatting stright away. If not, they will never be notified and won\'t be able to contact you again.', { context: requester.pronoun })}
          <VStack mt={6}>
            {publication && <LinkPublication publication={publication} />}
            <RequestBubble author={requester} mt={0}>
              {message}
            </RequestBubble>

            <Actions messengerId={id} isRejected={rejected} isBlocked={!!isBlocked} />
          </VStack>
        </>
      )}
    />
  );
};

export default ResolveRequest;
