import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as authActions from 'state/auth/actions';
import * as appActions from 'state/app/actions';

import { useInputValue, useTranslation, useFocusOnMount } from 'hooks';

import AuthLayout from 'components/AuthLayout';
import Input from 'components/Forms/InputSimple';
import Button from 'components/Button';

import locales from './i18n';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  const history = useHistory();
  const params = useParams();

  const passwordEl = useFocusOnMount();
  const [reseting, setReseting] = useState(false);
  const [resetError, setResetError] = useState(null);
  const password = useInputValue('');
  const passwordconfirm = useInputValue('');

  const reset = async () => {
    try {
      setReseting(true);
      setResetError(null);

      const hasError = passwordconfirm.value.length && passwordconfirm.value !== password.value;
      if (!hasError) {
        await dispatch(authActions.reset(params.hash, password.value));
        dispatch(appActions.addToast(t('Password reseted successfuly')));
        history.push('/');
      } else {
        setResetError(t('Passwords doesn\'t match'));
        setReseting(false);
      }
    } catch (err) {
      let error = err.message;
      if (err.response) {
        // eslint-disable-next-line
        error = Object.values(err.response.data.message).map(e => t(`global:${e}`)).join('\n');
      } else if (err.request) {
        error = err.request;
      }

      setResetError(error);
      setReseting(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') reset();
  };

  return (
    <AuthLayout
      title={t('Enter your new password')}
    >
      <Input
        ref={passwordEl}
        placeholder={t('New Password')}
        {...password}
        disabbled={reseting}
        withBorder
        type="password"
      />
      <Input
        placeholder={t('Confirm Password')}
        {...passwordconfirm}
        disabbled={reseting}
        onKeyPress={handleKeyPress}
        withBorder
        error={resetError}
        type="password"
      />

      <Button
        onClick={reset}
        loading={reseting}
        content={t('Reset password')}
      />
    </AuthLayout>
  );
};

ResetPassword.propTypes = {
};

export default ResetPassword;
