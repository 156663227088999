import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import 'overlayscrollbars/css/OverlayScrollbars.min.css';

import { useMetaDescription, useDebouncedUserFetching } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as appActions from 'state/app/actions';

import Nav from './Nav';
import NewVersionSnackBar from './NewVersionSnackBar';
import AgeConfirmationOverlay from './AgeConfirmationOverlay';
import AdInfoModal from './AdInfoModal';

require('moment/locale/es');

moment.locale('es');

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 64px;
  background-color: white;

  @media(max-width: 767px) {
    padding-top: 0;
  }
`;

const SiteLayout = ({ children }) => {
  const dispatch = useDispatch();
  const layoutEl = useRef(null);

  const userIsLoggedIn = useSelector(authSelectors.loggedIn);

  useMetaDescription();
  useDebouncedUserFetching();

  useEffect(() => {
    const handleClick = (e) => {
      const element = e.target.closest('a.mention, a.userlink, a.userinactive');
      const elementWithPreventUserlinkClass = e.target.closest('.nouserlink');

      if (element && !e.metaKey && !elementWithPreventUserlinkClass && userIsLoggedIn) {
        e.preventDefault();
        const username = element.getAttribute('href').substr(2);
        dispatch(appActions.setMiniprofile(username));
      }
    };

    const el = layoutEl.current;
    if (el) {
      el.addEventListener('click', handleClick);
    }
    return () => el.removeEventListener('click', handleClick);
  }, [layoutEl, dispatch, userIsLoggedIn]);
  //

  return (
    <Wrapper ref={layoutEl}>
      <Nav />

      {children}

      <NewVersionSnackBar />
      <AgeConfirmationOverlay />
      <AdInfoModal />
    </Wrapper>
  );
};

SiteLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SiteLayout;
