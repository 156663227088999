import styled from 'styled-components';

import { useTranslation } from 'hooks';

import locales from '../../i18n';

const NotConfirmedContainer = styled.div`
  background: #FAFAFA;
  padding: 18px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #666;
`;

const NotConfirmed = () => {
  const { t } = useTranslation(locales);

  return (
    <NotConfirmedContainer>{t('You need to confirm your email to send messages')}</NotConfirmedContainer>
  );
};

export default NotConfirmed;
