import { IconType, IconDefaults } from './type';

const CircleMultiple = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M15,4A8,8 0 0,1 23,12A8,8 0 0,1 15,20A8,8 0 0,1 7,12A8,8 0 0,1 15,4M15,18A6,6 0 0,0 21,12A6,6 0 0,0 15,6A6,6 0 0,0 9,12A6,6 0 0,0 15,18M3,12C3,14.61 4.67,16.83 7,17.65V19.74C3.55,18.85 1,15.73 1,12C1,8.27 3.55,5.15 7,4.26V6.35C4.67,7.17 3,9.39 3,12Z"
      fill={color}
    />
  </svg>
);

CircleMultiple.propTypes = IconType;
CircleMultiple.defaultProps = IconDefaults;

export default CircleMultiple;
