import { createSelector } from 'reselect';

import { MEMBERSHIP_ROLES } from '../../constants';

const adminRoles = [
  MEMBERSHIP_ROLES.OWNER,
  MEMBERSHIP_ROLES.ADMIN,
];
const modRoles = [
  ...adminRoles,
  MEMBERSHIP_ROLES.MODERATOR,
];

// Input selectors
const selectData = state => state.memberships.data;

const selectLoading = state => state.memberships.loading;
//

export const selectApproved = createSelector(
  selectData,
  data => Object.values(data || {}).filter(membership => membership.approved),
);

export const selectById = createSelector(
  selectData,
  (_, id) => id,
  (data, id) => data[id],
);

const selectMemberships = createSelector(
  selectData,
  (data) => {
    if (typeof data === 'undefined' || data === null) return [];
    return Object.values(data);
  },
);

export const selectByCommunityId = createSelector(
  selectMemberships,
  (_, communityId) => communityId,
  (memberships, communityId) => memberships.find(m => m.community === communityId),
);

export const isLoaded = createSelector(
  selectData,
  data => data !== null,
);

export const hasApprovedMembership = createSelector(
  selectMemberships,
  (_, communityId) => communityId,
  (memberships, communityId) => memberships.some(m => m.community === communityId && m.approved),
);

export const hasPendingMembership = createSelector(
  selectMemberships,
  (_, communityId) => communityId,
  (memberships, communityId) => memberships.some(m => m.community === communityId && !m.approved),
);

export const isLoading = createSelector(
  selectLoading,
  (_, slug) => slug,
  (loading, slug) => loading.includes(slug),
);

export const userIsModOf = createSelector(
  selectMemberships,
  (_, communityId) => communityId,
  (memberships, communityId) => (
    memberships.some(m => m.community === communityId && modRoles.includes(m.role))
  ),
);

export const userIsAdminOf = createSelector(
  selectMemberships,
  (_, communityId) => communityId,
  (memberships, communityId) => (
    memberships.some(m => m.community === communityId && adminRoles.includes(m.role))
  ),
);

export const userIsOwnerOf = createSelector(
  selectMemberships,
  (_, communityId) => communityId,
  (memberships, communityId) => (
    memberships.some(m => m.community === communityId && m.role === MEMBERSHIP_ROLES.OWNER)
  ),
);

export const approvedCount = createSelector(
  selectApproved,
  approved => approved.length,
);

export const selectUserRole = createSelector(
  selectByCommunityId,
  membership => (membership ? membership.role : null),
);
