export const LOAD = 'mazmo/events/LOAD';

export const EVENT_RSVP = 'mazmo/events/EVENT_RSVP';
export const EVENT_UNRSVP = 'mazmo/events/EVENT_UNRSVP';

export const ASSISTED = 'mazmo/events/ASSISTED';
export const UNASSISTED = 'mazmo/events/UNASSISTED';

export const ADD_BAN = 'mazmo/events/ADD_BAN';
export const REMOVE_BAN = 'mazmo/events/REMOVE_BAN';

export const LOAD_INVITES = 'mazmo/events/LOAD_INVITES';
export const ADD_INVITE = 'mazmo/events/ADD_INVITE';
export const REMOVE_INVITE = 'mazmo/events/REMOVE_INVITE';

export const ENROLLED = 'mazmo/events/ENROLLED';

export const WIPE = 'mazmo/events/WIPE';
