import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'hooks';
import * as eventActions from 'state/events/actions';
import * as appActions from 'state/app/actions';

import Button from 'components/Button';

import locales from '../../i18n';

const ButtonRSVP = ({ eventId, startListening }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [rsvping, setRsvping] = useState(false);

  const rsvp = useCallback(async () => {
    try {
      startListening();
      setRsvping(true);
      await dispatch(eventActions.rsvp(eventId));
    } catch (error) {
      dispatch(appActions.addError(error));
    }

    setRsvping(false);
  }, [dispatch, eventId, startListening]);

  return <Button onClick={rsvp} loading={rsvping}>{t('RSVP')}</Button>;
};

ButtonRSVP.propTypes = {
  eventId: PropTypes.string.isRequired,
  startListening: PropTypes.func.isRequired,
};

ButtonRSVP.defaultProps = {
};

export default ButtonRSVP;
