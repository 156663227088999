import { memo } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import { GMAPS_API_KEY } from '../../../../constants';

interface Props {
  lat: number;
  lng: number;
}

const containerStyle = {
  width: '100%',
  height: '300px',
};

const noPoi = [
  {
    featureType: 'poi',
    stylers: [
      { visibility: 'off' },
    ],
  },
];

const Map: React.FC<Props> = ({ lat, lng }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GMAPS_API_KEY,
  });

  const center = { lat, lng };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={17}
      clickableIcons={false}
      options={{
        disableDefaultUI: true,
        zoomControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        scaleControl: false,
        rotateControl: false,
        clickableIcons: false,
        styles: noPoi,
      }}
    >
      { /* Child components, such as markers, info windows, etc. */ }
      <Marker position={center} />
    </GoogleMap>
  ) : <></>;
};

export default memo(Map);
