import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import useTheme from 'hooks/useTheme';

const Theme = ({ children }) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

Theme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Theme;
