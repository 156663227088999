import { useState, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button, Icon, Stack, Text, Textarea,
} from '@chakra-ui/react';
import { Send } from 'lucide-react';

import * as authSelectors from 'state/auth/selectors';
import * as appActions from 'state/app/actions';
import * as messengerActions from 'state/messengers/actions';

import { useTranslation, useInputValue, useOnMount } from 'hooks';

import LinkPublication from 'containers/Chat/Content/ComposeMessage/LinkPublication';
import EmptyState from 'components/EmptyState';
import { FlexWrapper, FlexInnerWrapper } from 'components/FlexWrapper';

import OnboardingModal from '../OnboardingModal';
import { EMAILS } from '../../../../constants';
import locales from '../../i18n';

const RequestChat = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  const requestEl = useRef(null);
  const { chatId } = useParams();

  const userIsConfirmed = useSelector(authSelectors.isConfirmed);
  const email = useSelector(authSelectors.selectEmail);

  const requestsLeft = useSelector(authSelectors.getRequestsLeft);

  const [loading, setLoading] = useState(false);
  const requestMessage = useInputValue('');

  useOnMount(() => {
    if (requestEl.current) requestEl.current.focus();

    return () => {
      dispatch(messengerActions.clearPublicationId(chatId));
    };
  });

  const sendRequest = async () => {
    try {
      setLoading(true);
      await dispatch(messengerActions.request(chatId, requestMessage.value));
    } catch (error) {
      if (error.response.data.errorCode === 400) dispatch(appActions.addError(t('Message can\'t be empty')));
      else dispatch(appActions.addError(error));

      setLoading(false);
    }
  };

  if (!userIsConfirmed) {
    return (
      <EmptyState
        title={t('You need to confirm your account')}
        subtitle={(
          <Trans t={t} i18nKey="emailconfirm" ns="chatDashboard" values={{ email, ouremail: EMAILS.CONFIRMATIONS }}>
            <p>
              {'You can\'t contact other people in private until you e-mail is confirmed with us'}
            </p>
            <p>
              {'We\'ve '}
              sent you the instructions to
              {' '}
              <b>your address</b>
              . If you
              {' didn\'t '}
              receive our e-mail, please write us to
              {' '}
              <b>our address</b>
              .
            </p>
          </Trans>
        )}
      />
    );
  }

  return (
    <>
      <FlexWrapper mobileHasHeader canOverflow>
        <FlexInnerWrapper>
          <EmptyState
            title={t('Request for chat!')}
            subtitle={t('{{requestsLeft}} available requests left', { requestsLeft })}
          >
            <Box>
              <Stack spacing={4} mb={2} direction={{ base: 'column', md: 'row' }} alignItems="center">
                <LinkPublication messengerId={chatId} />
                <Textarea
                  ref={requestEl}
                  placeholder={t('Type a message')}
                  height="120px"
                  {...requestMessage}
                />
              </Stack>

              <Button
                leftIcon={<Icon as={Send} />}
                isLoading={loading}
                isDisabled={!requestMessage.value.length}
                onClick={sendRequest}
                size="lg"
                variant="primary"
                w="full"
              >
                {t('global:Send')}
              </Button>

              <Text mt={6} lineHeight={1}>
                <Trans t={t} i18nKey="chat.request.basicrules" ns="chatDashboard">
                  Remember the three basic rules to establish contact:
                  {' '}
                  Show consideration! Show respect! Show interest!
                </Trans>
              </Text>
            </Box>
          </EmptyState>
        </FlexInnerWrapper>
      </FlexWrapper>

      {/* Modals */}
      <OnboardingModal />
    </>
  );
};

RequestChat.propTypes = {
};

export default RequestChat;
