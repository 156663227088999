import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';

import * as eventSelectors from 'state/events/selectors';

import UserLink from 'components/UserLink';
import UserDisplayName from 'components/UserDisplayName';
import UserAvatar from 'components/UserAvatar';

const Wrapper = styled.div`
  .userlink {
    display: flex;
    align-items: center;
    font-size: 14px;

    .avatar {
      margin-right: 8px;
    }

    &:hover {
      color: ${props => props.theme.colors.main};
    }
  }
`;
Wrapper.displayName = 'Wrapper';

const Organizers = ({ eventId }) => {
  const organizerIds = useSelector(
    state => eventSelectors.selectOrganizerIds(state, eventId),
    shallowEqual,
  );

  return (
    <Wrapper>
      {organizerIds.map(userId => (
        <UserLink key={`event-${eventId}-organizer-${userId}`} userId={userId}>
          <UserAvatar userId={userId} size="24px" />
          <UserDisplayName userId={userId} />
        </UserLink>
      ))}
    </Wrapper>
  );
};

Organizers.propTypes = {
  eventId: PropTypes.string.isRequired,
};

Organizers.defaultProps = {
};

export default Organizers;
