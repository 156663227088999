import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';
import { mediaEqual, mediaGifEqual } from 'state/app/equalityFunctions';

import ContentMedia from 'components/ContentMedia';

import Message from './Message';
import InReplyTo from './InReplyTo';
import MessageWrapper from '../Message';
import Gif from '../../Gif';

const Content = ({ channelId, messageId }) => {
  const isEmojiOnly = useSelector(state => channelSelectors.isMessageEmojiOnly(state, messageId));
  const gif = useSelector(state => channelSelectors.getMessageGif(state, messageId), mediaGifEqual);
  const media = useSelector(
    state => channelSelectors.getMessageMedia(state, messageId),
    mediaEqual,
  );
  const inReplyTo = useSelector(state => channelSelectors.getReplyingTo(state, messageId));

  return (
    <MessageWrapper big={isEmojiOnly}>
      {inReplyTo && <InReplyTo messageId={inReplyTo} channelId={channelId} />}
      {gif
        ? <Gif data={gif} />
        : (
          <>
            <ContentMedia media={media} />
            <Message messageId={messageId} />
          </>
        )
      }
    </MessageWrapper>
  );
};

Content.propTypes = {
  channelId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
};

Content.defaultProps = {
};

export default Content;
