import styled from 'styled-components';

const InnerContainer = styled.div`
  @media(max-width: 768px) {
    padding: 64px 20px 48px;
  }
`;
InnerContainer.displayName = 'InnerContainer';

export default InnerContainer;
