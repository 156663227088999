import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import * as feedSelectors from 'state/feed/selectors';

import LinkPublication from './Link';
import VideoLinkPublication from './VideoLink';
import LinkImage from './LinkImage';

const hasVideo = (meta) => {
  if (!meta) return false;
  return meta.ogVideoUrl || meta.ogVideoSecureUrl || meta.twitterPlayer;
};

const Url = ({ publicationId }) => {
  const url = useSelector(
    state => feedSelectors.publications.selectUrl(state, publicationId),
    shallowEqual,
  );

  if (url.type === 'LINK' && hasVideo(url.meta)) return <VideoLinkPublication data={url.meta} />;
  if (url.type === 'LINK') return <LinkPublication data={url} />;
  if (url.type === 'IMAGE' || url.type === 'GIF') return <LinkImage data={url} />;

  return null;
};

Url.propTypes = {
  publicationId: PropTypes.string.isRequired,
};

Url.defaultProps = {
};

export default Url;
