import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useTitle } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';
import * as feedActions from 'state/feed/actions';
import * as appActions from 'state/app/actions';

import Sidebar from 'containers/Sidebar';
import NotFound from 'containers/NotFound';
import Layout from 'components/Layout';
import Publication from 'components/Publication';
import ErrorState from 'components/ErrorState';
import Ad from 'components/Ad';
import PublicationLoading from './PublicationLoading';

const PublicationPage = () => {
  const dispatch = useDispatch();

  const { publicationId } = useParams();
  const publication = useSelector(
    state => feedSelectors.selectPublication(state, publicationId),
    shallowEqual,
  );
  const [errorCode, setErrorCode] = useState(null);

  useTitle(null);

  useEffect(() => {
    dispatch(appActions.uiLeftColumn(true));
  }, [dispatch]);

  useEffect(() => {
    const loadPublication = async () => {
      try {
        await dispatch(feedActions.loadPublication(publicationId));
      } catch (error) {
        setErrorCode(error.response ? error.response.status : 500);
      }
    };

    if (!publication) loadPublication();
  }, [dispatch, publication, publicationId]);

  const renderContent = () => {
    if (errorCode === 404) return <NotFound />;
    if (errorCode) return <ErrorState />;
    if (!publication) return <PublicationLoading />;

    return <Publication publicationId={publicationId} full />;
  };

  return (
    <Layout columns={2} feed leftColumnOpen={false} rightColumnOpen={false}>
      <div>
        {renderContent()}

        <Ad id="In Feed" />
      </div>
      <Sidebar />
    </Layout>
  );
};

export default PublicationPage;
