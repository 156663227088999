import produce from 'immer';

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  EMAIL_CONFIRMED,
  ADD_TO_FOLLOW_LIST,
  REMOVE_FROM_FOLLOW_LIST,
  REMOVE_FROM_FOLLOWING,
  SET_TO_FOLLOW_LISTS,
  UPDATE_FOLLOW_LIST,
  CREATE_FOLLOW_LIST,
  REMOVE_FOLLOW_LIST,
  ADD_TO_KNOWING,
  REMOVE_FROM_KNOWING,
  ADD_TO_BLOCKS,
  REMOVE_FROM_BLOCKS,
  UPDATE_ME,
  ADD_RELATIONSHIPS,
  REMOVE_RELATIONSHIP,
  APPROVE_RELATIONSHIP,
  UPDATE_CHECKLIST,
  DISMISS_ONBOARDING_CHATREQUEST,
  CHAT_REQUESTS,
  ADD_ORGANIZATION_TOKEN,
  REMOVE_ORGANIZATION_TOKEN,
  ORGANIZATIONS_LOAD,
  MARKETPLACE_MERCADOPAGO_PUBLICKEY,
} from './constants';

export const initialState = {
  me: null,
  chatRequests: null,
  loggingIn: false,
  loginError: null,
  loginAuto: false,
  organizations: [],
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOGIN:
      draft.loggingIn = true;
      draft.loginError = null;
      draft.loginAuto = !!action.auto;
      break;

    case LOGIN_SUCCESS:
      draft.me = action.data;
      draft.loggingIn = false;
      draft.loginError = null;
      draft.loginAuto = false;
      break;

    case LOGIN_FAIL:
      draft.loggingIn = false;
      draft.loginError = action.error;
      draft.loginAuto = false;
      break;

    case LOGOUT:
      draft.loggingIn = false;
      draft.loginError = null;
      draft.loginAuto = false;
      draft.me = null;
      break;

    case EMAIL_CONFIRMED:
      draft.me.emailConfirmed = true;
      break;

    case ADD_TO_FOLLOW_LIST: {
      draft.me.following_users.push(action.userId);
      const index = draft.me.lists.findIndex(l => l.id === action.listId);
      draft.me.lists[index].users = [
        ...state.me.lists[index].users,
        action.userId,
      ];
      break;
    }

    case REMOVE_FROM_FOLLOW_LIST: {
      const index = draft.me.lists.findIndex(l => l.id === action.listId);
      draft.me.lists[index].users = state.me.lists[index].users
        .filter(uId => uId !== action.userId);

      if (!state.me.lists.some(l => l.id !== action.listId && l.users.includes(action.userId))) {
        draft.me.following_users = state.me.following_users.filter(uId => uId !== action.userId);
      }

      break;
    }

    case REMOVE_FROM_FOLLOWING:
      draft.me.following_users = state.me.following_users.filter(id => id !== action.userId);
      state.me.lists.forEach((list, index) => {
        draft.me.lists[index].users = list.users.filter(uId => uId !== action.userId);
      });
      break;

    case SET_TO_FOLLOW_LISTS:
      // We clean from the following users list (if it exists)
      draft.me.following_users = state.me.following_users.filter(id => id !== action.userId);
      if (action.lists.length) {
        // If should be in there, we add it
        draft.me.following_users.push(action.userId);
      }

      state.me.lists.forEach((list, index) => {
        const users = list.users.filter(uId => uId !== action.userId);

        if (action.lists.includes(list.id)) {
          users.push(action.userId);
        }

        draft.me.lists[index].users = users;
      });
      break;

    case UPDATE_FOLLOW_LIST:
      draft.me.lists = state.me.lists.map((list) => {
        if (list.id === action.listId) return action.data;
        return list;
      });
      break;

    case CREATE_FOLLOW_LIST:
      draft.me.lists.push(action.data);
      break;

    case REMOVE_FOLLOW_LIST: {
      const { users } = state.me.lists.find(l => l.id === action.listId);

      let lists = state.me.lists.filter(l => l.id !== action.listId);

      if (action.moveToListId) {
        const moveToList = state.me.lists.find(l => l.id === action.moveToListId);

        if (moveToList) {
          const nonDupUsers = users.filter(uId => !moveToList.users.includes(uId));

          lists = lists.map((list) => {
            if (list.id !== action.moveToListId) return list;
            return {
              ...list,
              users: [
                ...list.users,
                ...nonDupUsers,
              ],
            };
          });
        }

        draft.me.lists = lists;
      }

      break;
    }

    case ADD_TO_KNOWING:
      draft.me.knowing.push(action.userId);
      break;

    case REMOVE_FROM_KNOWING:
      draft.me.knowing = state.me.knowing.filter(id => id !== action.userId);
      break;

    case ADD_TO_BLOCKS:
      draft.me.blocks.push(action.userId);
      break;

    case REMOVE_FROM_BLOCKS:
      draft.me.blocks = state.me.blocks.filter(id => id !== action.userId);
      break;

    case UPDATE_ME:
      draft.me = {
        ...state.me,
        ...action.data,
      };
      break;

    case ADD_RELATIONSHIPS:
      draft.me.relationships = [
        ...state.me.relationships,
        ...action.relationships,
      ];
      break;

    case REMOVE_RELATIONSHIP:
      draft.me.relationships = state.me.relationships.filter(r => r.id !== action.relationshipId);
      break;

    case APPROVE_RELATIONSHIP:
      draft.me.relationships = state.me.relationships.map((r) => {
        if (r.id !== action.relationshipId) return r;
        return {
          ...r,
          approved: true,
        };
      });
      break;

    case UPDATE_CHECKLIST:
      draft.me.checklist = {
        privacy: action.privacy,
        lists: action.lists,
      };
      break;

    case DISMISS_ONBOARDING_CHATREQUEST:
      draft.me.onboarding.chatRequest = false;
      break;

    case CHAT_REQUESTS:
      draft.chatRequests = action.data;
      break;

    case ADD_ORGANIZATION_TOKEN:
      draft.me.organizationTokens = [
        ...state.me.organizationTokens,
        action.data,
      ];
      break;

    case REMOVE_ORGANIZATION_TOKEN:
      draft.me.organizationTokens = (state.me.organizationTokens || []).filter(t => (
        t.organizationTokenId !== action.id
      ));
      break;

    case ORGANIZATIONS_LOAD:
      draft.organizations = action.data;
      break;

    case MARKETPLACE_MERCADOPAGO_PUBLICKEY:
      draft.me.marketplace = {
        ...draft.me.marketplace,
        mercadopago: {
          publicKey: action.data,
        },
      };
      break;

    default:
  }
});

export default reducer;
