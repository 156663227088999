import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';

import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const CommentPublication = ({
  comment,
  author,
  read,
  timestamp,
}) => {
  const { t } = useTranslation(locales);
  const meId = useSelector(authSelectors.selectId);

  return (
    <AlertContainer
      timestamp={timestamp}
      image={<UserAvatar userId={author.id} size="40px" showOnline={false} />}
      to={`/publications/${comment.publication.id}`}
      read={read}
    >
      <strong><UserDisplayName userId={author.id} /></strong>
      {' '}
      {comment.publication.authorId === meId ? t('added a comment to your publication') : t('added a comment to a publication you are following')}
      {': '}
      {comment.rawContent}
    </AlertContainer>
  );
};

CommentPublication.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rawContent: PropTypes.string,
    publication: PropTypes.shape({
      id: PropTypes.string.isRequired,
      authorId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  author: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  read: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default CommentPublication;
