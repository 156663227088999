import { chakra } from '@chakra-ui/react';
import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const Infinite = memo(() => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path d="M18.6 6.62C17.16 6.62 15.8 7.17999 14.83 8.14999L12 10.66L10.48 12H10.49L7.8 14.39C7.16 15.03 6.31 15.38 5.4 15.38C3.53 15.38 2.01 13.87 2.01 12C2.01 10.13 3.53 8.62 5.4 8.62C6.31 8.62 7.16 8.96999 7.84 9.64999L8.97 10.65L10.48 9.31L9.22 8.2C8.2 7.18 6.84 6.62 5.4 6.62C2.42 6.62 0 9.04 0 12C0 14.96 2.42 17.38 5.4 17.38C6.84 17.38 8.2 16.82 9.17 15.85L12 13.35L12.01 13.36L13.52 12H13.51L16.2 9.60999C16.84 8.97 17.69 8.62 18.6 8.62C20.47 8.62 21.99 10.13 21.99 12C21.99 13.87 20.47 15.38 18.6 15.38C17.7 15.38 16.84 15.03 16.16 14.35L15.02 13.34L13.51 14.68L14.78 15.8C15.8 16.81 17.15 17.37 18.6 17.37C21.58 17.37 24 14.96 24 11.99C24 9.01999 21.58 6.62 18.6 6.62Z" />
  </svg>
));

Infinite.propTypes = IconType;
Infinite.defaultProps = IconDefaults;

export default chakra(Infinite);
