export const ERRORS_ADD = 'mazmo/app/ERRORS_ADD';
export const ERRORS_CLEAR = 'mazmo/app/ERRORS_CLEAR';

export const TOASTS_ADD = 'mazmo/app/TOASTS_ADD';
export const TOASTS_REMOVE = 'mazmo/app/TOASTS_REMOVE';

export const ADINFO_OPEN = 'mazmo/app/ADINFO_OPEN';
export const ADINFO_CLOSE = 'mazmo/app/ADINFO_CLOSE';

export const MINIPROFILE_SET = 'mazmo/app/MINIPROFILE_SET';

export const NAVLEFT_ACTIVE = 'mazmo/app/NAVLEFT_ACTIVE';

export const UI_LEFT_COLUMN = 'mazmo/app/UI_LEFT_COLUMN';
export const UI_RIGHT_COLUMN = 'mazmo/app/UI_RIGHT_COLUMN';
export const UI_CHANNELS_SHOWING_PARTICIPANTS = 'mazmo/app/UI_CHANNELS_SHOWING_PARTICIPANTS';

export const NEW_LOCATION = 'mazmo/app/NEW_LOCATION';

export const MEDIA_SET = 'mazmo/app/MEDIA_SET';
export const MEDIA_ADD = 'mazmo/app/MEDIA_ADD';
export const MEDIA_REMOVE = 'mazmo/app/MEDIA_REMOVE';
export const MEDIA_CLEAR = 'mazmo/app/MEDIA_CLEAR';
export const MEDIA_UPLOAD_COMPLETE = 'mazmo/app/MEDIA_UPLOAD_COMPLETE';

export const WIPE = 'mazmo/app/WIPE';

export const TOGGLE_SAFE_FOR_WORK = 'mazmo/app/TOGGLE_SAFE_FOR_WORK';

export const COMPOSER_HAS_CONTENT = 'mazmo/app/COMPOSER_HAS_CONTENT';
export const COMPOSER_SAVING = 'mazmo/app/COMPOSER_SAVING';
export const COMPOSER_READONLY = 'mazmo/app/COMPOSER_READONLY';

export const CHAT_LIST_SET = 'mazmo/app/CHAT_LIST_SET';
export const CHAT_LIST_UPDATE = 'mazmo/app/CHAT_LIST_UPDATE';
export const CHAT_LIST_REMOVE = 'mazmo/app/CHAT_LIST_REMOVE';

export const RESOURCES_LOADED = 'mazmo/app/RESOURCES_LOADED';

export const SW_REGISTERED = 'mazmo/app/SW_REGISTERED';
export const SW_WAITING = 'mazmo/app/SW_WAITING';
