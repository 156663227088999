import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';
import * as userSelectors from 'state/users/selectors';
import * as authActions from 'state/auth/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';

import locales from '../../i18n';

const UnknowModal = ({ userId, close }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const pronoun = useSelector(userSelectors.getPronoun(userId));

  const onConfirm = async () => {
    try {
      setLoading(true);
      await dispatch(authActions.unknow(userId));
      dispatch(appActions.addToast(t('Removed as known')));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    close();
  };

  return (
    <Modal
      title={t('Remove as known', { context: pronoun })}
      onCancel={close}
      actions={[(
        <Button
          key="unknow-confirm"
          onClick={onConfirm}
          loading={loading}
        >
          {t('global:Confirm')}
        </Button>
      )]}
    >
      {t('Are you sure you want to remove this person as known?')}
    </Modal>
  );
};

UnknowModal.propTypes = {
  userId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};

UnknowModal.defaultProps = {
};

export default UnknowModal;
