import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton, Menu, MenuButton, MenuItem, MenuList, Icon,
} from '@chakra-ui/react';
import { Archive, ArchiveRestore, EllipsisVertical } from 'lucide-react';

import { useTranslation } from 'hooks';
import * as messengerSelectors from 'state/messengers/selectors';
import * as messengerActions from 'state/messengers/actions';
import * as appActions from 'state/app/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';

import NonFollowingButtons from './NonFollowingButtons';
import locales from '../../i18n';

const Actions = ({ messengerId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const isArchived = useSelector(state => messengerSelectors.isArchived(state, messengerId));
  const needsRequest = useSelector(state => messengerSelectors.needsRequest(state, messengerId));
  const needsResolveRequest = useSelector(
    state => messengerSelectors.needsResolveRequest(state, messengerId),
  );
  const showArchiveOptions = !needsRequest && !needsResolveRequest;
  const userId = useSelector(state => messengerSelectors.getUserId(state, messengerId));

  const [archiving, setArchiving] = useState(false);
  const [unarchiving, setUnarchiving] = useState(false);
  const [showingArchiveModal, setShowingArchiveModal] = useState(false);
  const [showingUnarchiveModal, setShowingUnarchiveModal] = useState(false);

  const showArchiveModal = useCallback(() => {
    setShowingArchiveModal(true);
  }, []);
  const hideArchiveModal = useCallback(() => {
    setShowingArchiveModal(false);
  }, []);
  const showUnarchiveModal = useCallback(() => {
    setShowingUnarchiveModal(true);
  }, []);
  const hideUnarchiveModal = useCallback(() => {
    setShowingUnarchiveModal(false);
  }, []);

  const archive = useCallback(async () => {
    setArchiving(true);

    try {
      await dispatch(messengerActions.archive(messengerId));
      setArchiving(false);
      hideArchiveModal();
      dispatch(appActions.addToast(t('Conversation archived')));
    } catch (error) {
      setArchiving(false);
      hideArchiveModal();
      dispatch(appActions.addError(error));
    }
  }, [dispatch, hideArchiveModal, messengerId, t]);

  const unarchive = useCallback(async () => {
    setUnarchiving(true);

    try {
      await dispatch(messengerActions.unarchive(messengerId));
      setUnarchiving(false);
      hideUnarchiveModal();
      dispatch(appActions.addToast(t('Conversation unarchived')));
    } catch (error) {
      setUnarchiving(false);
      hideUnarchiveModal();
      dispatch(appActions.addError(error));
    }
  }, [dispatch, messengerId, hideUnarchiveModal, t]);

  if (!showArchiveOptions) return null;

  return (
    <>
      <NonFollowingButtons userId={userId} />

      <Menu>
        <MenuButton
          variant="ghost"
          as={IconButton}
          size="sm"
          icon={<Icon as={EllipsisVertical} boxSize={4} color={{ base: 'white', md: 'black' }} />}
          ml={4}
          mr={{ base: 2, md: 0 }}
        />
        <MenuList>
          {!isArchived
            ? <MenuItem onClick={showArchiveModal} icon={<Icon as={Archive} />}>{t('Archive conversation')}</MenuItem>
            : <MenuItem onClick={showUnarchiveModal} icon={<Icon as={ArchiveRestore} />}>{t('Unarchive conversation')}</MenuItem>
          }
        </MenuList>
      </Menu>

      {showingArchiveModal && (
        <Modal
          title={t('Archive conversation')}
          onCancel={hideArchiveModal}
          actions={[
            <Button key="archive-conversation-confirm" onClick={archive} loading={archiving}>{t('global:Confirm')}</Button>,
          ]}
        >
          {t('Are you sure you want to archive this conversation?')}
        </Modal>
      )}
      {showingUnarchiveModal && (
        <Modal
          title={t('Unarchive conversation')}
          onCancel={hideUnarchiveModal}
          actions={[
            <Button key="unarchive-conversation-confirm" onClick={unarchive} loading={unarchiving}>{t('global:Confirm')}</Button>,
          ]}
        >
          {t('Are you sure you want to unarchive this conversation?')}
        </Modal>
      )}
    </>
  );
};

Actions.propTypes = {
  messengerId: PropTypes.string.isRequired,
};

Actions.defaultProps = {
};

export default Actions;
