import { Box, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';

import locales from '../i18n';

interface Props {
  publicationId: string;
}

const PromotedText: React.FC<Props> = ({ publicationId }) => {
  const { t } = useTranslation(locales);

  const promoted = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectPromoted(state, publicationId),
  );

  if (!promoted) return null;

  return (
    <Box>
      <Text as="span">・</Text>
      <Text as="span">{t('Promoted')}</Text>
    </Box>
  );
};

export default PromotedText;
