import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useTranslation, useAuth } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as authActions from 'state/auth/actions';

import { Separator, Title } from 'components/Menu';
import UserAvatar from 'components/UserAvatar';
import Spinner from 'components/Spinner';

import locales from '../../i18n';

const Wrapper = styled.div.attrs({
  className: 'nav-dd-content',
})`
  display: flex;
  justify-content: space-between;
  margin: 4px 8px;
`;
Wrapper.displayName = 'Wrapper';

const Organizations = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const { switchToOrganization } = useAuth();
  const organizations = useSelector(authSelectors.selecOrganizations, shallowEqual);
  const [loading, setLoading] = useState(false);

  const login = useCallback(orgaId => async () => {
    setLoading(true);
    const { jwt } = await dispatch(authActions.getOrganizationAuth(orgaId));
    setLoading(false);
    switchToOrganization(jwt);
  }, [dispatch, switchToOrganization]);

  if (!organizations.length) return null;

  return (
    <>
      <Title>{t('Organizations')}</Title>

      {loading ? (
        <Spinner />
      ) : (
        <Wrapper>
          {organizations.map(orgaId => (
            <div
              key={`switch-orga-${orgaId}`}
              onClick={login(orgaId)}
              role="button"
              tabIndex={-1}
              onKeyDown={login(orgaId)}
            >
              <UserAvatar userId={orgaId} showOnline={false} />
            </div>
          ))}
        </Wrapper>
      )}

      <Separator />
    </>
  );
};

Organizations.propTypes = {
};

Organizations.defaultProps = {
};

export default Organizations;
