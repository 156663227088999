import PropTypes from 'prop-types';

import styled from 'styled-components';

import { Arrow, Close } from 'components/Icons';

const SlideshowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 50vw;
    height: auto;
    max-height: 90% !important;

    @media(max-width: 767px) {
      max-width: none;
      width: calc(100% - 30px * 4);
    }
  }
`;

const Overlay = styled.div`
  background: rgba(0,0,0,0.87);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
`;

const Button = styled.button`
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 50px;
  position: absolute;
  cursor: pointer;
  @media(max-width: 767px) {
    width: 30px;
  }
  &:focus {
    outline: none;
  }
`;

const CloseButton = styled(Button)`
  top: 24px;
  right: 24px;
  @media(max-width: 767px) {
    top: 16px;
    right: 16px;
  }
`;

const ArrowButton = styled(Button)`
  top: calc(50% - 50px / 2);
`;

const PrevButton = styled(ArrowButton)`
  left: 24px;
  transform: rotate(-180deg);
  @media(max-width: 767px) {
    left: 16px;
  }
`;

const NextButton = styled(ArrowButton)`
  right: 24px;
  @media(max-width: 767px) {
    right: 16px;
  }
`;

const Slideshow = ({
  images,
  index,
  close,
  goToIndex,
}) => {
  const next = () => goToIndex(index + 1);
  const prev = () => goToIndex(index - 1);

  return (
    <SlideshowWrapper>
      <Overlay onClick={close} />
      <CloseButton onClick={close}>
        <Close color="white" />
      </CloseButton>
      <img src={images[index]} alt="" />
      {index < (images.length - 1) && (
        <NextButton onClick={next}>
          <Arrow color="white" />
        </NextButton>
      )}
      {index > 0 && (
        <PrevButton onClick={prev}>
          <Arrow color="white" />
        </PrevButton>
      )}
    </SlideshowWrapper>
  );
};

Slideshow.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number,
  close: PropTypes.func.isRequired,
  goToIndex: PropTypes.func.isRequired,
};

Slideshow.defaultProps = {
  index: 0,
};

export default Slideshow;
