import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Img } from 'components/Icons';

const Button = styled.button<React.HTMLProps<HTMLButtonElement>>`
  background: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 12px 0 0;
  border: 0;
  cursor: pointer;
`;

interface Props {
  open?: () => void;
  className?: string;
}

const UploadMediaButton = ({ open, className }: Props) => (
  <Button onClick={open} className={className} type="button"><Img color="#6F6666" /></Button>
);

UploadMediaButton.propTypes = {
  open: PropTypes.func.isRequired,
  className: PropTypes.string,
};

UploadMediaButton.defaultProps = {
  className: '',
};

export default UploadMediaButton;
