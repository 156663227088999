import { forwardRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';

import {
  Account,
  Close,
  AccountGroup,
  ChevronDown,
} from 'components/Icons';
import Menu, { Item } from 'components/Menu';

import locales from './i18n';

const Icon = styled.div`
  display: flex;
  height: 18px;
  position: absolute;
  top: calc(50% - 18px / 2);
  left: 16px;

  svg:not(.chevron) {
    width: 20px;
    height: 20px;
  }

  svg.chevron {
    width: 12px;
    height: 12px;
    position: absolute;
    left: 18px;
    top: 4px;
  }
`;

const InputSearchContainer = styled.div`
  margin: 0;
  padding: 0 0 0 56px;
  border-radius: 8px;
  display: flex;
  ${({ color }) => (color === 'primary' ? 'background-color: #ec5f50' : '')}
  height: 45px;

  input {
    line-height: 48px;
    font-size: 16px;
    font-weight: 500;
    flex: 1;
    border: 0;
    background-color: transparent;
    color: white;

    &::placeholder {
      color: ${colors.borderRed};
    }

    &:focus {
      outline: none;
    }
  }


  ${({ color }) => (color === 'secondary'
    ? `
      background-color: white;
      box-shadow: 0px 0px 5px #9999;

      input {
        color: black;

        &::placeholder {
          color: #666;
        }
      }
    `
    : ''
  )}
  

  .nav-dropdown {
    position: fixed;
    top: 48px;
    right: 270px;
    width: 250px;
    left: auto;

    @media(max-width: 767px) {
      max-height: 100px;
      left: 26px;
      right: auto;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
`;

const Loading = styled.div`
  display: inline-block;
  width: 40px !important;
  margin-top: 10px;
  opacity: ${props => (props.hide ? 0 : 1)};

  &:after {
    content: " ";
    display: block;
    width: 15px;
    height: 15px;
    margin: 1px;
    border-radius: 50%;
    border: 3px solid #333;
    border-color: #333 transparent #333 transparent;
    animation: lds-dual-ring 0.8s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CloseWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 19px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const UserSearchInput = forwardRef(({
  value,
  loading,
  contentChange,
  hasResults,
  onBlurTriggered,
  type,
  changeType,
  color,
}, ref) => {
  const { t } = useTranslation(locales);

  const [showingTypeMenu, setShowingTypeMenu] = useState(false);
  const openTypeMenu = useCallback(() => {
    if (changeType) {
      setShowingTypeMenu(true);
    }
  }, [changeType]);
  const closeTypeMenu = useCallback(() => { setShowingTypeMenu(false); }, []);

  const onChangeType = useCallback(newType => () => {
    closeTypeMenu();
    changeType(newType);
  }, [closeTypeMenu, changeType]);

  const placeholder = type === 'users' ? t('Search users') : t('Search threads');

  return (
    <InputSearchContainer color={color} className="search-container">
      <Icon onClick={openTypeMenu} className="search-icon">
        {type === 'users' ? <Account /> : <AccountGroup />}
        <ChevronDown className="chevron" fill="white" />
      </Icon>
      <Menu
        maxHeight="auto"
        open={showingTypeMenu}
        onClose={closeTypeMenu}
        className="nav-dropdown"
        orientation="right"
      >
        <Item className="prevent-close" onClick={onChangeType('users')}>
          <Account />
          <span>{t('global:People')}</span>
        </Item>
        <Item className="prevent-close" onClick={onChangeType('threads')}>
          <AccountGroup />
          <span>{t('global:Threads')}</span>
        </Item>
      </Menu>
      <input ref={ref} onChange={contentChange} value={value} placeholder={placeholder} />
      <Loading hide={!loading} />
      {!loading && hasResults > 0 && (
        <CloseWrapper onClick={onBlurTriggered}>
          <Close color={colors.borderRed} />
        </CloseWrapper>
      )}
    </InputSearchContainer>
  );
});

UserSearchInput.propTypes = {
  value: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  contentChange: PropTypes.func.isRequired,
  hasResults: PropTypes.bool.isRequired,
  onBlurTriggered: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['users', 'threads']),
  color: PropTypes.oneOf(['primary', 'secondary']),
  changeType: PropTypes.func,
};

UserSearchInput.defaultProps = {
  value: '',
  type: 'users',
  changeType: null,
  color: 'primary',
};

export default UserSearchInput;
