import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import * as alertSelectors from 'state/alerts/selectors';
import * as messengerSelectors from 'state/messengers/selectors';
import * as channelSelectors from 'state/channels/selectors';
import * as threadSelectors from 'state/threads/selectors';

import { Menu } from 'components/Icons';
import Badge from 'components/Badge';

const Wrapper = styled.button.attrs({
  className: 'mobile-menu-button',
})`
  width: 52px;
  height: 100%;
  border: 0;
  background: none;
  padding: 0;
  margin: 0 4px 0 0;
  cursor: pointer;
  display: none;
  flex-shrink: 0;
  position: relative;

  > svg {
    width: 32px;
    height: 32px;
  }

  @media(max-width: 1279px) {
    display: block;
  }
`;

const MobileMenuButton = ({
  color,
  onClick,
  children,
  negative,
}) => {
  const unreadAlerts = useSelector(alertSelectors.selectTotalUnread);
  const unreadMessengers = useSelector(messengerSelectors.totalUnread);
  const unreadChannels = useSelector(channelSelectors.totalUnread);
  const communityThreadsUnread = useSelector(threadSelectors.hasUnread());

  const unread = unreadMessengers + unreadAlerts;
  const silentUnread = !unread && (unreadChannels || communityThreadsUnread);

  return (
    <Wrapper onClick={onClick}>
      {children || <Menu color={color} />}
      {unread > 0 && <Badge negative={negative} value={unread} />}
      {silentUnread && <Badge negative={negative} />}
    </Wrapper>
  );
};

MobileMenuButton.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  negative: PropTypes.bool,
};

MobileMenuButton.defaultProps = {
  color: colors.red,
  children: null,
  negative: false,
};

export default MobileMenuButton;
