import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as communitySelectors from 'state/communities/selectors';
import * as membershipSelectors from 'state/memberships/selectors';
import * as communityActions from 'state/communities/actions';

import Info from 'containers/Communities/Info';
import NotFound from 'containers/NotFound';
import Layout from 'components/Layout';
import Loading from 'components/Loading';

import Content from './Content';

const NewThread = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const communityId = useSelector(
    state => communitySelectors.selectIdBySlug(state, params.communitySlug),
  );
  const isFetching = useSelector(
    state => communitySelectors.selectIsFetching(state, params.communitySlug),
  );
  const hasApprovedMembership = useSelector(
    state => membershipSelectors.hasApprovedMembership(state, communityId),
  );
  const privacy = useSelector(state => communitySelectors.selectPrivacy(state, communityId));

  useEffect(() => {
    dispatch(communityActions.fetch(params.communitySlug));
  }, [dispatch, params.communitySlug]);

  if (!communityId) {
    if (isFetching) return <Loading />;
    return <NotFound />;
  }

  if (!hasApprovedMembership && privacy === 'PRIVATE') return <div />;

  return (
    <Layout columns={2}>
      <Info communitySlug={params.communitySlug} />
      <Content communityId={communityId} />
    </Layout>
  );
};

NewThread.propTypes = {
};

NewThread.defaultProps = {
};

export default NewThread;
