import styled from 'styled-components';

import colors from 'utils/css/colors';

const Row = styled.div`
  margin-bottom: 22px;

  a {
    color: ${colors.red};
    text-decoration: none;
  }

  input[type="checkbox"] {
    margin-right: 15px;
  }

  svg {
    width: 20px;
  }
`;
Row.displayName = 'SignupRow';

export default Row;
