import {
  ButtonGroup, Menu, MenuButton, MenuList, IconButton, Icon,
} from '@chakra-ui/react';
import { MoreVertical } from 'lucide-react';

import ChangeAvatarAction from './ChangeAvatar';
import EditChannelAction from './EditChannel';
import HandleBotsActions from './HandleBots';
import EmbedFrameAction from './EmbedFrame';
import AutorespondersAction from './Autoresponders';
import InvitesAction from './Invites';
import AddBanAction from './AddBan';
import ViewBansAction from './ViewBans';
import PartChannelAction from './PartChannel';
import TransferOwnershipAction from './TransferOwnership';
import ShowParticipantsButton from '../../ShowParticipantsButton';

interface Props {}

const ChatHeaderActions: React.FC<Props> = () => {
  return (
    <ButtonGroup>
      <ShowParticipantsButton />
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<Icon as={MoreVertical} size={20} color={{ base: 'white', md: 'black' }} />}
          variant="ghost"
          colorScheme="blackAlpha"
        />
        <MenuList>
          <ChangeAvatarAction />
          <EditChannelAction />
          <HandleBotsActions />
          <EmbedFrameAction />
          <AutorespondersAction />
          <InvitesAction />
          <ViewBansAction />
          <AddBanAction />
          <PartChannelAction />
          <TransferOwnershipAction />
        </MenuList>
      </Menu>
    </ButtonGroup>
  );
};

export default ChatHeaderActions;
