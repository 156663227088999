import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import colors from 'utils/css/colors';

import Title from './Title';
import Isologo from './Isologo';

const TopBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  border-top: 8px solid ${colors.red};
  padding: 16px;
  backdrop-filter: saturate(180%) blur(5px);
`;

const IsologoContainer = styled.div`
  z-index: 10;

  svg {
    width: 35px !important;
    height: 33px !important;
  }

  @media(max-height: 400px) {
    display: none;
  }
`;

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  wide?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: absolute;
  padding: 64px 30px;
  width: 100%;
  box-sizing: border-box;

  ${props => props.wide && `
    position: relative;
    margin: 40px 60px;
    width: auto;
  `}

  li h3 {
    text-align: left;
  }
`;

interface FormProps extends React.HTMLAttributes<HTMLDivElement> {
  wide?: boolean;
}

const Form = styled.div<FormProps>`
  ${props => !props.wide && 'width: 390px;'}
  margin: 0 auto;
  text-align: center;

  input {
    font-size: 20px;
    font-weight: 300;
    border-radius: 0;
    line-height: 40px;
  }
`;

const SubTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.redReactions};
  margin-bottom: 25px;

  a {
    color: ${colors.red};
    text-decoration: none;
  }
`;

interface AuthLayoutProps {
  title: string;
  subtitle?: React.ReactNode;
  wide?: boolean;
  children?: React.ReactNode;
}

const AuthLayout = ({
  title,
  subtitle,
  wide,
  children,
}: AuthLayoutProps) => (
  <>
    <TopBar>
      <IsologoContainer><Link to="/"><Isologo /></Link></IsologoContainer>
    </TopBar>

    <Container wide={wide}>
      <Form wide={wide}>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        {children}
      </Form>
    </Container>
  </>
);

AuthLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  wide: PropTypes.bool,
  children: PropTypes.node,
};

AuthLayout.defaultProps = {
  subtitle: null,
  wide: false,
  children: null,
};

export default AuthLayout;
