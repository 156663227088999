export const FETCH = 'mazmo/alerts/FETCH';
export const FETCH_SUCCESS = 'mazmo/alerts/FETCH_SUCCESS';
export const FETCH_FAIL = 'mazmo/alerts/FETCH_FAIL';

export const ADD = 'mazmo/alerts/ADD';
export const UPDATE = 'mazmo/alerts/UPDATE';

export const MARK_AS_READ = 'mazmo/alerts/MARK_AS_READ';

export const WIPE = 'mazmo/alerts/WIPE';
