import styled from 'styled-components';

const Buttons = styled.div`
  a {
    margin: 0 0 0 15px;
  }

  display: ${props => (props.mobile ? 'none' : 'block')};

  @media(max-width: 767px) {
    display: ${props => (props.mobile ? 'block' : 'none')};

    ${props => props.mobile && `
      text-align: center;
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      a {
        margin: 0;

        &:first-child {
          margin-bottom: 16px;
        }
      }
    `}
  }
`;

export default Buttons;
