import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const AddMedia = memo(() => (
  <svg viewBox="0 0 26 26">
    <path d="M21.8947 6.84211V10.9337C21.8947 10.9337 19.1716 10.9473 19.158 10.9337V6.84211H15.0527C15.0527 6.84211 15.0663 4.11894 15.0527 4.10526H19.158V0H21.8947V4.10526H26V6.84211H21.8947ZM17.7895 12.3157V8.21052H13.6842V4.10526H2.73685C1.23158 4.10526 0 5.33684 0 6.84211V23.2631C0 24.7684 1.23158 26 2.73685 26H19.158C20.6632 26 21.8947 24.7684 21.8947 23.2631V12.3157H17.7895ZM2.73685 23.2631L6.84212 17.7895L9.57896 21.8947L13.6842 16.421L19.158 23.2631H2.73685Z" fill="#FAFAFA" />
  </svg>
));

AddMedia.propTypes = IconType;
AddMedia.defaultProps = IconDefaults;

export default AddMedia;
