import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as messengerSelectors from 'state/messengers/selectors';

import EmptyState from 'components/EmptyState';
import Button from 'components/Button';

import locales from '../i18n';

const NoSelection = () => {
  const { t } = useTranslation(locales);
  const messengersCount = useSelector(messengerSelectors.messengerCount);

  if (messengersCount > 0) {
    return (
      <EmptyState
        uiLeftColumn
        title={t('Start a conversation!')}
        subtitle={(
          <div>
            <div>{t('Select a chat in the left bar, or create a new one')}</div>
            <Button color="white" fontColor="#666" to="/chat/channels" style={{ marginTop: '20px' }}>{t('Explore channels')}</Button>
          </div>
        )}
      />
    );
  }

  return (
    <EmptyState
      uiLeftColumn
      title={t('Start a conversation!')}
      subtitle={(
        <div>
          <div>
            {t('You didn\'t start a convesation yet')}
            <br />
            {t('Select a user in the left bar and start chatting!')}
            <br />
          </div>
          <Button color="white" fontColor="#666" to="/chat/channels" style={{ marginTop: '20px' }}>{t('Explore channels')}</Button>
        </div>
      )}
    />
  );
};

NoSelection.propTypes = {
};

NoSelection.defaultProps = {
};

export default NoSelection;
