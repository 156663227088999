import PropTypes from 'prop-types';
import styled from 'styled-components';

import formatNumber from 'utils/formatNumber';

const Wrapper = styled.div`
  flex: 1;
`;

const Label = styled.div`
  text-transform: uppercase;
  font-size: 14px;

  @media(max-width: 767px) {
    font-size: 10px;
  }
`;

const Number = styled.div`
  font-weight: bold;
  font-size: 24px;
`;

const Counter = ({ label, number }) => (
  <Wrapper>
    <Number>{formatNumber(number)}</Number>
    <Label>{label}</Label>
  </Wrapper>
);

Counter.propTypes = {
  label: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
};

export default Counter;
