import { useRef } from 'react';

interface Props {
  children: React.ReactElement;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileSelector: React.FC<Props> = ({ children, onChange }) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const selectFile = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  return (
    <>
      <children.type {...children.props} onClick={selectFile} />
      <input ref={inputFile} style={{ display: 'none' }} onChange={onChange} type="file" />
    </>
  );
};

export default FileSelector;
