import styled from 'styled-components';

const Error = styled.div`
  color: red;
  font-weight: bold;
  margin: 16px;
`;
Error.displayName = 'SignupError';

export default Error;
