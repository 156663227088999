import { useState, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as feedActions from 'state/feed/actions';
import * as appActions from 'state/app/actions';
import * as feedSelectors from 'state/feed/selectors';
import useTranslation from 'hooks/useTranslation';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Radio from 'components/Forms/Radio';
import Toggle from 'components/Toggle';

import { PUBREPORT_REASONS } from '../../../constants';

import locales from '../i18n';

interface Props {
  publicationId: string;
  close: () => void;
}

const Report: React.FC<Props> = ({ publicationId, close }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [block, setBlock] = useState(true);
  const [warn, setWarn] = useState(false);

  const publicationAuthorId = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectAuthorId(state, publicationId),
  );

  const onReasonChange = useCallback((reason: string) => () => {
    setSelectedReason(reason);
  }, []);

  const onBlockChange = useCallback(() => {
    setBlock(b => !b);
  }, []);

  const onConfirm = useCallback(async () => {
    try {
      if (selectedReason === PUBREPORT_REASONS.ABUSE && warn === false) {
        setWarn(true);
      } else {
        setLoading(true);
        const blockAndMuteUser = block ? publicationAuthorId : null;
        await dispatch(feedActions.report(publicationId, selectedReason, blockAndMuteUser));
        setLoading(false);
        close();
      }
    } catch (error) {
      dispatch(appActions.addError(error));
      setLoading(false);
      close();
    }
  }, [dispatch, close, publicationId, selectedReason, block, warn, publicationAuthorId]);

  const modalActions = [(
    <Button
      key="pubreport-confirm"
      onClick={onConfirm}
      loading={loading}
      disabled={selectedReason === null}
    >
      {t('global:Confirm')}
    </Button>
  )];

  if (warn) {
    return (
      <Modal
        title={t('Report')}
        onClose={close}
        actions={modalActions}
      >
        <Trans t={t} ns="publication" i18nKey="pubblock.abuse">
          <p>You are about to report this publication for abuse. If you are sure, please confirm it again.</p>
          <p>Mazmo takes this matter very seriously, so please take into account the following:</p>
          <ol>
            <li>False reports are not allowed and will be punished. Your account will be suspended if we find this publication is not abusive.</li>
            <li>If you are not the only one doing this report, the publication will be blocked and the author will be notified.</li>
          </ol>
        </Trans>
      </Modal>
    );
  }

  return (
    <Modal
      title={t('Report')}
      onCancel={close}
      actions={modalActions}
    >
      {Object.values(PUBREPORT_REASONS).map(reason => (
        <Radio
          key={`pubreport-reason-${reason}`}
          label={t(reason)}
          onSelect={onReasonChange(reason)}
          selected={selectedReason === reason}
          description={t(`${reason}.description`)}
        />
      ))}

      <Toggle
        label={t('Also block and silence this account')}
        onChange={onBlockChange}
        active={block}
        position="left"
      />
    </Modal>
  );
};

export default Report;
