import styled from 'styled-components';

const BoxWrapper = styled.div`
  @media(max-width: 768px) {
    padding: 0 16px;
  }

  > div {
    overflow: hidden;
    ${props => props.landing && `
      padding: 0;
    `}

    .inner {
      overflow-y: auto;
    }
  }
`;
BoxWrapper.displayName = 'BoxWrapper';

export default BoxWrapper;
