export const FETCH = 'mazmo/channels/FETCH';
export const FETCH_SUCCESS = 'mazmo/channels/FETCH_SUCCESS';
export const FETCH_FAIL = 'mazmo/channels/FETCH_FAIL';

export const REMOVE_CHANNEL = 'mazmo/channels/REMOVE_CHANNEL';

export const ADD_MESSAGE = 'mazmo/channels/ADD_MESSAGE';
export const UPDATE_MESSAGE = 'mazmo/channels/UPDATE_MESSAGE';
export const UPDATE = 'mazmo/channels/UPDATE';
export const SET_ACTIVE = 'mazmo/channels/SET_ACTIVE';
export const UNMOUNT = 'mazmo/channels/UNMOUNT';
export const SCROLL_AT_BOTTOM_CHANGE = 'mazmo/channels/SCROLL_AT_BOTTOM_CHANGE';
export const MARK_ALL_AS_READ = 'mazmo/channels/MARK_ALL_AS_READ';

export const JOIN = 'mazmo/channels/JOIN';
export const PART = 'mazmo/channels/PART';

export const LOAD_MESSAGES = 'mazmo/channels/LOAD_MESSAGES';
export const LOAD_MESSAGES_SUCCESS = 'mazmo/channels/LOAD_MESSAGES_SUCCESS';
export const LOAD_MESSAGES_FAIL = 'mazmo/channels/LOAD_MESSAGES_FAIL';

export const MESSAGE_REMOVING = 'mazmo/channels/MESSAGE_REMOVING';
export const MESSAGE_REMOVE = 'mazmo/channels/MESSAGE_REMOVE';

export const FULLY_LOADED = 'mazmo/channels/FULLY_LOADED';

export const TYPING_START = 'mazmo/channels/TYPING_START';
export const TYPING_STOP = 'mazmo/channels/TYPING_STOP';

export const INC_UNREAD_MENTIONS = 'mazmo/channels/INC_UNREAD_MENTIONS';

export const ADD_BOT = 'mazmo/channels/ADD_BOT';

export const ADD_REPLYTO = 'mazmo/channels/ADD_REPLYTO';
export const CLEAR_REPLYTO = 'mazmo/channels/CLEAR_REPLYTO';

export const ADD_EDITCOMPOSER = 'mazmo/channels/ADD_EDITCOMPOSER';
export const REMOVE_EDITCOMPOSER = 'mazmo/channels/REMOVE_EDITCOMPOSER';

export const EMBED_UPDATED = 'mazmo/channels/EMBED_UPDATED';

export const WIPE = 'mazmo/channels/WIPE';
