import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import ComposerRef from 'utils/ComposerRef';
import * as authSelectors from 'state/auth/selectors';
import * as appActions from 'state/app/actions';
import * as appSelectors from 'state/app/selectors';
import * as threadSelectors from 'state/threads/selectors';
import * as threadActions from 'state/threads/actions';

import { useTranslation, useUploadMedia, useInputValue } from 'hooks';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Composer, { EmojiPicker } from 'components/Composer';
import CommunityUploadMedia, { DropZone, UploadMediaButton } from 'components/CommunityUploadMedia';
import Input from 'components/Forms/InputSimple';

import locales from './i18n';

const ComposerWrapper = styled.div`
  max-width: 100%;
  min-height: 56px;
  display: flex;
  flex-direction: column;

  &:focus {
    outline: none;
  }

  .actions {
    margin-top: 16px;
    display: flex;

    > div, > button {
      margin-right: 8px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const EditThread = ({ close, threadId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const composerId = `editthread-${threadId}`;

  const titleInputEl = useRef(null);

  const meId = useSelector(authSelectors.selectId);
  const canSubmit = useSelector(
    state => appSelectors.selectIsComposerReadyToSubmit(state, composerId),
  );
  const isSaving = useSelector(state => appSelectors.selectIsComposerSaving(state, composerId));
  const thread = useSelector(threadSelectors.get(threadId), shallowEqual);

  const [saving, setSaving] = useState(false);
  const title = useInputValue(thread.title);

  const {
    getInputProps,
    isDragActive,
    open,
    handlePastedFiles,
  } = useUploadMedia(composerId, '/communities/upload');

  useEffect(() => {
    if (titleInputEl.current) titleInputEl.current.focus();
  }, [titleInputEl]);

  useEffect(() => {
    const ref = ComposerRef.getRef(composerId);
    if (ref) {
      ref.setValue(thread.rawContent);
      dispatch(appActions.mediaUploadSet(composerId, (thread.media?.images || [])));
    }
  }, [thread, dispatch, composerId]);

  const userIsAuthor = meId === thread.author;
  const submitDisabled = (
    title.value.length <= 0
    || (userIsAuthor && (!canSubmit || isSaving))
  );

  const edit = async () => {
    const payload = { title: title.value };
    setSaving(true);
    if (userIsAuthor) {
      await dispatch(appActions.composerSendToServer(composerId, payload));
    } else {
      await dispatch(threadActions.edit(thread, payload));
    }

    setSaving(false);
    close();
    dispatch(appActions.addToast(t('Thread edited')));
  };

  return (
    <Modal
      title={t('Edit thread')}
      onCancel={close}
      actions={[(
        <Button
          key="remove-thread"
          onClick={edit}
          loading={saving}
          disabled={submitDisabled}
        >
          {t('global:Confirm')}
        </Button>
      )]}
    >
      {isDragActive && (
        <DropZone>{t('global:Drop the files here...')}</DropZone>
      )}

      <Input
        ref={titleInputEl}
        label={t('global:Title')}
        maxChars={255}
        {...title}
      />

      {userIsAuthor && (
        <>
          <ComposerWrapper>
            <Composer
              id={composerId}
              height="250px"
              handlePastedFiles={handlePastedFiles}
              autofocus
            />

            <div className="actions">
              <EmojiPicker id={composerId} />
              <UploadMediaButton open={open} />
            </div>
          </ComposerWrapper>
          <br />
          <CommunityUploadMedia
            id={composerId}
            open={open}
            getInputProps={getInputProps}
          />
        </>
      )}
    </Modal>
  );
};

EditThread.propTypes = {
  close: PropTypes.func.isRequired,
  threadId: PropTypes.string.isRequired,
};

export default EditThread;
