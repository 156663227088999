import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import colors from 'utils/css/colors';
import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import { SelectableListItem } from 'components/SelectableList';
import UserAvatar from 'components/UserAvatar';
import Button from 'components/Button';
import { TrashCan } from 'components/Icons';

const Username = styled.div`
  color: ${colors.grey};
  font-size: 14px;
  margin-top: 2px;
`;
Username.displayName = 'Username';

const Ban = ({
  userId,
  banId,
  remove,
  removing,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.getById(userId), shallowEqual);
  if (user.loading) dispatch(userActions.fetchData(user.id));

  const renderAvatar = useCallback(id => () => <UserAvatar userId={id} />, []);
  const onClick = useCallback(() => { remove(banId); }, [banId, remove]);

  return (
    <SelectableListItem
      renderAvatar={renderAvatar(userId)}
      title={user.displayname}
      actions={[
        <Button onClick={onClick} className="empty" loading={removing}>
          <TrashCan color={colors.red} outline />
        </Button>,
      ]}
    >
      <Username>{`@${user.username}`}</Username>
    </SelectableListItem>
  );
};

Ban.propTypes = {
  userId: PropTypes.number.isRequired,
  banId: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  removing: PropTypes.bool.isRequired,
};

Ban.defaultProps = {
};

export default memo(Ban);
