import { IconType, IconDefaults } from './type';

const Image = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z"
      fill={color}
    />
  </svg>
);

Image.propTypes = IconType;
Image.defaultProps = IconDefaults;

export default Image;
