import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;

  button, a {
    margin: 0 2px;
    width: 24px;
    height: 24px;

    img, svg {
      width: 24px;
      height: 24px;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

const ShareIcons = ({ url }) => (
  <Wrapper>
    <Link to={`/new/url?url=${url}`}><img src="https://mazmo.net/images/isologo.png" alt="Mazmo" /></Link>
    <WhatsappShareButton url={url}><WhatsappIcon /></WhatsappShareButton>
    <TelegramShareButton url={url}><TelegramIcon /></TelegramShareButton>
    <FacebookShareButton url={url}><FacebookIcon /></FacebookShareButton>
    <TwitterShareButton url={url}><TwitterIcon /></TwitterShareButton>
    <PinterestShareButton url={url}><PinterestIcon /></PinterestShareButton>
    <RedditShareButton url={url}><RedditIcon /></RedditShareButton>
    <TumblrShareButton url={url}><TumblrIcon /></TumblrShareButton>
    <EmailShareButton url={url}><EmailIcon /></EmailShareButton>
  </Wrapper>
);

ShareIcons.propTypes = {
  url: PropTypes.string.isRequired,
};

ShareIcons.defaultProps = {
};

export default ShareIcons;
