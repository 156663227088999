import styled from 'styled-components';
import { Icon } from '@chakra-ui/react';

import ChevronDown from 'components/Icons/ChevronDown';

const CaretContainer = styled.div`
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 8px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  svg.chevron {
    width: 24px;
    height: 24px;
    margin: -1px 0 0 4px;
  }
`;

interface Props {
  onClick: () => void;
  disabled: boolean;
  negative: boolean;
}

const MoreOptions: React.FC<Props> = ({ onClick, disabled, negative }) => {
  let color = '#efefef';
  if (negative) color = 'black';
  if (disabled) color = '#aaa';

  return (
    // @ts-ignore
    <CaretContainer onClick={onClick} disabled={disabled}>
      <Icon as={ChevronDown} fill={color} className="chevron" />
    </CaretContainer>
  );
};

export default MoreOptions;
