import { useEffect } from 'react';

const useMetaRobots = (content) => {
  useEffect(() => {
    document.querySelector('meta[name="robots"]').content = content;

    return () => {
      document.querySelector('meta[name="robots"]').content = 'index, follow';
    };
  }, [content]);
};

export default useMetaRobots;
