import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import languages from 'i18n/languages';

const keys = Object.keys(languages);
const resources = {};

keys.forEach((key) => {
  try {
    // eslint-disable-next-line global-require, import/no-dynamic-require
    const content = require(`./${key}.json`);
    resources[key] = { global: content };
  } catch (error) {
    //
  }
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,

    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
    caches: ['localStorage', 'cookie'],

    fallbackLng: 'es',
    debug: process.env.NODE_ENV === 'development',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
