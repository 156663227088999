import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, HStack, Text } from '@chakra-ui/react';

import * as messengerSelectors from 'state/messengers/selectors';
import * as authSelectors from 'state/auth/selectors';

import ChatStateIcon from 'components/ChatStateIcon';
import { Gif, ImageMultiple } from 'components/Icons';

const LastMessage = ({ id }) => {
  const meId = useSelector(authSelectors.selectId);
  const lastMessage = useSelector(
    state => messengerSelectors.selectLastMessageByMessengerId(state, id),
  );
  const lastMessageState = useSelector(
    state => messengerSelectors.selectMessageState(state, (lastMessage ? lastMessage.id : null)),
  );
  const isTyping = useSelector(state => messengerSelectors.getIsTyping(state, id));

  const lastMessageOutgoing = lastMessage && lastMessage.authorId === meId;

  if (isTyping || !lastMessage) return null;

  const { media } = lastMessage;
  const renderLastMessageContent = () => {
    if (media && media.gif) return <Gif fill="#666" boxSize={6} backgroundColor="#EEE" />;
    if (media && media.images.length > 0) return <ImageMultiple className="images" fill="#666" backgroundColor="#EEE" boxSize={5} />;
    return lastMessage.rawContent;
  };

  return (
    <HStack fontWeight="normal" spacing={1} alignItems="center">
      {lastMessageOutgoing && (
        <Box boxSize={4}>
          <ChatStateIcon state={lastMessageState} />
        </Box>
      )}
      <Text noOfLines={1} color="gray.400" mt={1}>{renderLastMessageContent()}</Text>
    </HStack>
  );
};

LastMessage.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(LastMessage);
