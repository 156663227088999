import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { chakra, Box, Divider } from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import * as eventSelectors from 'state/events/selectors';

import H3 from 'ui/H3';

import locales from '../i18n';
import Rsvp from './Rsvp';

const RSVPs = ({ eventId }) => {
  const { t } = useTranslation(locales);

  const userIds = useSelector(
    state => eventSelectors.selectRsvpUserIds(state, eventId),
    shallowEqual,
  );
  const rsvpCount = useSelector(state => eventSelectors.selectRsvpCount(state, eventId));

  if (!rsvpCount) return null;

  return (
    <Box>
      <Divider mb={4} />

      <H3>{t('RSVPs ({{count}})', { count: rsvpCount })}</H3>

      <div className="rsvps">
        {userIds.map((userId, index) => (
          <chakra.span key={`event-${eventId}-rsvp-${userId}`} fontSize="sm" color="gray.500" _hover={{ color: 'brand.500' }}>
            <Rsvp userId={userId} />
            {index < (userIds.length - 1) && ', '}
          </chakra.span>
        ))}
      </div>
    </Box>
  );
};

RSVPs.propTypes = {
  eventId: PropTypes.string.isRequired,
};

RSVPs.defaultProps = {
};

export default RSVPs;
