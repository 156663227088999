import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import isMobile from 'utils/isMobile';
import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';

import ShowMoreButton from 'components/ShowMoreButton';

import Comment from './Comment';
import locales from './i18n';

const SHOWING_MAX = isMobile ? 1 : 3;

const Comments = ({ publicationId, full }) => {
  const { t } = useTranslation(locales);

  const commentIds = useSelector(
    state => feedSelectors.selectCommentIds(state, publicationId),
    shallowEqual,
  );
  const hiddenCount = commentIds.length - SHOWING_MAX;
  const [showingAll, setShowingAll] = useState(full || hiddenCount <= 0);
  const showAll = () => setShowingAll(true);

  const comments = showingAll ? commentIds : commentIds.slice(hiddenCount);

  return (
    <div>
      {!showingAll && (
        <ShowMoreButton onClick={showAll}>{t('show.all', { count: hiddenCount })}</ShowMoreButton>
      )}
      {comments.map(commentId => (
        <Comment key={`publication-comment-${commentId}`} id={commentId} />
      ))}
    </div>
  );
};

Comments.propTypes = {
  publicationId: PropTypes.string.isRequired,
  full: PropTypes.bool,
};

Comments.defaultProps = {
  full: false,
};

export default Comments;
