import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';
import * as channelActions from 'state/channels/actions';

import Busy from 'components/UserDisplayName/Busy';
import { Bot } from 'types';

const DisplayName = styled.span.attrs({
  className: 'displayname',
})<React.HTMLAttributes<HTMLSpanElement>>`
  position: relative;
`;
DisplayName.displayName = 'DisplayName';

const botEqual = (prevBot: Bot, nextBot: Bot) => (
  prevBot.id === nextBot.id
  && prevBot.name === nextBot.name
  && prevBot.loading === nextBot.loading
);

const BotName = ({ botId }: { botId: string }) => {
  const dispatch = useDispatch();

  // @ts-ignore
  const bot = useSelector(state => channelSelectors.getBotById(state, botId), botEqual);

  useEffect(() => {
    if (!bot) {
      dispatch(channelActions.fetchBotData(botId));
    }
  }, [bot, dispatch, botId]);

  if (!bot) return <Busy className="displayname" />;

  return (
    <DisplayName>
      <span>{bot.name}</span>
    </DisplayName>
  );
};

BotName.propTypes = {
  botId: PropTypes.string.isRequired,
};

BotName.defaultProps = {
};

export default BotName;
