import { IconType, IconDefaults } from './type';

const OfficeBuilding = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M19 3V21H13V17.5H11V21H5V3H19M15 7H17V5H15V7M11 7H13V5H11V7M7 7H9V5H7V7M15 11H17V9H15V11M11 11H13V9H11V11M7 11H9V9H7V11M15 15H17V13H15V15M11 15H13V13H11V15M7 15H9V13H7V15M15 19H17V17H15V19M7 19H9V17H7V19M21 1H3V23H21V1Z"
      fill={color}
    />
  </svg>
);

OfficeBuilding.propTypes = IconType;
OfficeBuilding.defaultProps = IconDefaults;

export default OfficeBuilding;
