import { memo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation, useOpenClose, useUploadMedia } from 'hooks';
import isMobile from 'utils/isMobile';
import * as authSelectors from 'state/auth/selectors';
import * as messengerSelectors from 'state/messengers/selectors';
import * as channelSelectors from 'state/channels/selectors';
import * as appActions from 'state/app/actions';
import * as channelActions from 'state/channels/actions';
import * as messengerActions from 'state/messengers/actions';

import Composer, { GifSearch, EmojiPicker } from 'components/Composer';
import { Gif } from 'components/Icons';
import CommunityUploadMedia, { DropZone, UploadMediaButton } from 'components/CommunityUploadMedia';

import Wrapper from './Wrapper';
import ReplyTo from './ReplyTo';
import Action from './Action';
import NotConfirmed from './NotConfirmed';
import ActionButton from './ActionButton';
import locales from '../../i18n';
import LinkPublication from './LinkPublication';

const ComposeMessage = memo(({
  type, id, onFocus, onBlur,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  const theme = useContext(ThemeContext);

  const key = `${type}-${id}`;
  const selectors = type === 'channel' ? channelSelectors : messengerSelectors;
  const actions = type === 'channel' ? channelActions : messengerActions;

  const userIsConfirmed = useSelector(authSelectors.isConfirmed);
  const [showingGifSearch, openGifSearch, closeGifSearch] = useOpenClose(false);
  const replyingTo = useSelector(state => selectors.getReplyTo(state, id));

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    handlePastedFiles,
  } = useUploadMedia(key, '/chat/upload');

  const onTypingState = useCallback((state) => {
    dispatch(actions.sendTypingState(id, state));
  }, [dispatch, id, actions]);

  const sendGif = useCallback(async (gif) => {
    closeGifSearch();
    dispatch(appActions.composerSendToServer(key, { media: { gif }, replyingTo }));
  }, [dispatch, closeGifSearch, key, replyingTo]);

  const handleEditLastMessage = () => {
    dispatch(actions.addEditComposerForLastOwnMessage(id));
  };

  if (!userIsConfirmed) return <NotConfirmed />;

  return (
    <Wrapper>
      <div className="composer" {...getRootProps()}>
        <div>
          <Action>
            <UploadMediaButton className="action left" open={open} />
          </Action>
          <Action onClick={openGifSearch}>
            <Gif fill={theme.colors.actionIcon} close={closeGifSearch} />
          </Action>
          {!isMobile && (
            <Action>
              <EmojiPicker id={key} />
            </Action>
          )}
        </div>
        <div>
          {isDragActive && (
            <DropZone>{t('global:Drop the files here...')}</DropZone>
          )}
          <ReplyTo type={type} id={id} />
          {type === 'messenger' && <LinkPublication messengerId={id} mb={2} />}
          <Composer
            key={`chat-${key}`}
            id={key}
            placeholder={t('Enter your message')}
            submitOnEnter
            onTypingState={onTypingState}
            autofocus={!isMobile}
            maxHeight="100px"
            handlePastedFiles={handlePastedFiles}
            onUpKeyPress={handleEditLastMessage}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
        <div>
          <ActionButton type={type} id={id} />
        </div>
      </div>

      {showingGifSearch && (
        <GifSearch close={closeGifSearch} onSelect={sendGif} />
      )}

      <CommunityUploadMedia
        id={key}
        open={open}
        getInputProps={getInputProps}
      />
    </Wrapper>
  );
});

ComposeMessage.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['channel', 'messenger']).isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

ComposeMessage.defaultProps = {
  onFocus: null,
  onBlur: null,
};

export default ComposeMessage;
