import qs from 'qs';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as bankSelectors from 'state/bank/selectors';

import { TicketPercent } from 'components/Icons';

import SelectCouponWrapper from './UI/SelectCouponWrapper';
import locales from './i18n';

const SelectCoupon = () => {
  const { t } = useTranslation(locales);
  const location = useLocation();

  const coupons = useSelector(bankSelectors.selectCoupons, shallowEqual);

  if (!coupons.length) return null;

  const queryparams = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <SelectCouponWrapper>
      <div className="title">{t('Apply coupon')}</div>

      <div className="coupons">
        {coupons.map((coupon) => {
          const newQP = { ...queryparams, coupon: coupon.code };
          return (
            <Link key={`apply-coupon-${coupon.code}`} to={`/user/sades/buy?${qs.stringify(newQP)}`}>
              <TicketPercent />
              <div className="code">{coupon.code}</div>
              <div className="detail">
                {coupon.discount > 0 && `${coupon.discount}% off`}
                {!coupon.discount && coupon.increment > 0 && `+ ${coupon.increment}%`}
              </div>
            </Link>
          );
        })}
      </div>
    </SelectCouponWrapper>
  );
};

SelectCoupon.propTypes = {
};

SelectCoupon.defaultProps = {
};

export default SelectCoupon;
