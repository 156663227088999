import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as eventSelectors from 'state/events/selectors';
import * as eventActions from 'state/events/actions';

import { FlexWrapper, FlexInnerWrapper, FlexContainer } from 'components/FlexWrapper';
import Layout from 'components/Layout';
import Sidebar from 'containers/Sidebar';
import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import ErrorState from 'components/ErrorState';
import EmptyState from 'components/EmptyState';

import locales from './i18n';

const Assistance = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  const { eventId, key } = useParams();

  const name = useSelector(state => eventSelectors.selectName(state, eventId));
  const flyer = useSelector(state => eventSelectors.selectFlyer(state, eventId));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      try {
        await dispatch(eventActions.fetch(eventId));
        await dispatch(eventActions.selfAssist(eventId, key));
      } catch (err) {
        setError(err.response.data.message);
      }

      setLoading(false);
    };

    fetch();
  }, [dispatch, eventId, key]);

  return (
    <Layout columns={2} feed leftColumnOpen={false} rightColumnOpen={false}>
      <FlexWrapper canOverflow>
        <FlexInnerWrapper>
          <PageTitle>{name}</PageTitle>

          <FlexContainer framed>
            {!loading ? (
              <div>
                {!error ? (
                  <div>
                    <EmptyState
                      title={t('Success!!')}
                      subtitle={t('This Event will now shows in your profile as assisted. Enjoy the Event.')}
                    />

                    <div style={{ textAlign: 'center' }}>
                      <img src={flyer} alt={name} />
                    </div>
                  </div>
                ) : (
                  <div>
                    <ErrorState message={t(error)} reload={false} />
                  </div>
                )}
              </div>
            ) : (
              <Loading />
            )}
            {/* <Content /> */}
          </FlexContainer>
        </FlexInnerWrapper>
      </FlexWrapper>

      <Sidebar />
    </Layout>
  );
};

Assistance.propTypes = {
};

Assistance.defaultProps = {
};

export default Assistance;
