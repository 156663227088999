import moment from 'moment';
import { useSelector } from 'react-redux';
import { Text } from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import useDisplayName from 'hooks/useDisplayName';
import * as threadSelectors from 'state/threads/selectors';

import locales from './i18n';

interface Props {
  threadId: string;
}

const LastReply: React.FC<Props> = ({ threadId }) => {
  const { t } = useTranslation(locales);

  const lastReplyAuthorId = useSelector(threadSelectors.getLastReplyAuthorId(threadId));
  const displayname = useDisplayName(lastReplyAuthorId);

  const lastReplyAt = useSelector(threadSelectors.getLastReplyAt(threadId));
  const hasReplies = useSelector(threadSelectors.hasReplies(threadId));

  return (
    <Text color="gray.400" fontWeight={400} fontSize="sm" mb={2} noOfLines={1}>
      {`${t(hasReplies ? 'Replied by' : 'Created by')} ${displayname} ${moment(lastReplyAt).fromNow()}`}
    </Text>
  );
};

export default LastReply;
