import styled from 'styled-components';

const Processors = styled.div`
  display: flex;
  justify-content: space-around;

  > div {
    width: 200px;
    height: 116px;
    border: 1px solid #CCC;
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    position: relative;

    &:hover {
      border-color: ${props => props.theme.colors.main};
    }

    img {
      max-width: 100%;
      max-height: calc(100% - 16px);
    }

    .flag {
      position: absolute;
      width: 100%;
      font-size: 12px;
      background: ${props => props.theme.colors.main};
      left: 0;
      color: white;
      bottom: 0px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;
Processors.displayName = 'Processors';

export default Processors;
