import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ComposerRef from 'utils/ComposerRef';
import * as appSelectors from 'state/app/selectors';

import { UploadMediaButton } from 'components/CommunityUploadMedia';

import SubmitButton from './SubmitButton';
import Wrapper from './UI/Actions';

const Actions = ({ openMedia, publicationId }) => {
  const id = `comment-${publicationId}`;

  const hasContent = useSelector(state => appSelectors.selectComposerHasContent(state, id));

  const [hasFocus, setHasFocus] = useState(false);

  const showActions = hasFocus || hasContent;

  useEffect(() => {
    const handlers = () => {
      const ref = ComposerRef.getRef(id);

      const handleFocus = () => { setHasFocus(true); };
      const handleBlur = () => { setTimeout(() => { setHasFocus(false); }, 100); };

      if (ref) {
        ref.on('focus', handleFocus);
        ref.on('blur', handleBlur);
      } else {
        setTimeout(handlers, 300);
      }
    };

    handlers();
  }, [id]);

  return (
    <Wrapper showing={showActions}>
      <div>
        <UploadMediaButton className="action left" open={openMedia} />
      </div>
      <SubmitButton publicationId={publicationId} />
    </Wrapper>
  );
};

Actions.propTypes = {
  publicationId: PropTypes.string.isRequired,
  openMedia: PropTypes.func.isRequired,
};

Actions.defaultProps = {
};

export default Actions;
