import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';

import Modal from 'components/Modal';
import UserLink from 'components/UserLink';
import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import locales from '../i18n';

const Funders = styled.ul`
  list-style: none !important;
  margin: 0 !important;

  li {
    display: flex;
    align-items: center;

    .userlink {
      display: flex;
      align-items: center;
      color: ${props => props.theme.colors.main};

      .avatar {
        margin-right: 8px;
      }
    }

    .payed {
      margin-left: 8px;
      color: ${props => props.theme.colors.secondary};
      text-transform: lowercase;

      strong {
        margin-right: 4px;
      }
    }
  }
`;
Funders.displayName = 'Funders';

const FundersModal = ({ publicationId, onClose }) => {
  const { t } = useTranslation(locales);

  const funders = useSelector(
    state => feedSelectors.publications.getFunders(state, publicationId),
    shallowEqual,
  );

  return (
    <Modal onClose={onClose}>
      <Funders>
        {funders.map(({ userId, payed }) => (
          <li>
            {userId ? (
              <UserLink userId={userId}>
                <UserAvatar userId={userId} size="32px" />
                <UserDisplayName userId={userId} />
              </UserLink>
            ) : (
              <em>{t('Anonymous')}</em>
            )}

            <div className="payed">
              <strong>{payed}</strong>
              <span>{t('global:Sades')}</span>
            </div>
          </li>
        ))}
      </Funders>
    </Modal>
  );
};

FundersModal.propTypes = {
  publicationId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

FundersModal.defaultProps = {
};

export default FundersModal;
