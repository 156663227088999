import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const Reply = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M10,9V5L3,12L10,19V14.9C15,14.9 18.5,16.5 21,20C20,15 17,10 10,9Z"
      fill={color}
    />
  </svg>
);

Reply.propTypes = IconType;
Reply.defaultProps = IconDefaults;

export default memo(Reply);
