import styled from 'styled-components';

import { FlexInnerWrapper } from 'components/FlexWrapper';

export const FlexInnerWrapperProfile = styled(FlexInnerWrapper)`
  @media(max-width: 768px) {
    position: absolute;
    left: 0;
    top: -64px;
    width: 100%;
  }
`;
FlexInnerWrapperProfile.displayName = 'FlexInnerWrapperProfile';

export const MobileCover = styled.div`
  ${props => props.cover && `background-image: url(${props.cover});`}
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: top center;

  @media(min-width: 768px) {
    display: none;
  }
`;
MobileCover.displayName = 'MobileCover';

export const DesktopCover = styled.div`
  ${props => props.cover && `background-image: url(${props.cover});`}
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: top center;

  @media(max-width: 768px) {
    display: none;
  }
`;
DesktopCover.displayName = 'DesktopCover';

export const CoverGradient = styled.div`
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 10%, rgba(51,51,51,1) 23%, rgba(0,0,0,0.15) 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;

  @media(max-width: 768px) {
    display: none;
  }
`;
CoverGradient.displayName = 'CoverGradient';

export const BasicInfoWrapper = styled.div`
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 10%, rgba(51,51,51,1) 23%, rgba(0,0,0,0.15) 100%);

  @media(min-width: 767px) {
    height: 340px;
    background: none;
  }
`;
BasicInfoWrapper.displayName = 'BasicInfoWrapper';

export const BasicInfoDesktopRelativeWrapper = styled.div`
  position: relative;
  @media(min-width: 767px) {
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 10%, rgba(51,51,51,1) 23%, rgba(0,0,0,0.15) 100%);
  }
`;
BasicInfoDesktopRelativeWrapper.displayName = 'BasicInfoDesktopRelativeWrapper';

export const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  transition: all 250ms ease-out;
  opacity: ${props => (props.showingHeader ? 0 : 1)};

  .displayname {
    font-weight: 500;
    font-size: 32px;
    color: white;
    margin-top: 16px;
  }
`;
BasicInfo.displayName = 'BasicInfo';

export const UserDescription = styled.div`
  color: white;
  position: relative;
  text-align: center;
  padding: 16px 4px;
  transition: all 250ms ease-out;

  @media(min-width: 767px) {
    background: none;
  }

  > div:not(:last-child) {
    display: flex;
    margin-bottom: 16px;
    justify-content: center;

    > span {
      margin: 0 8px;
    }
  }
`;
UserDescription.displayName = 'UserDescription';

export const Content = styled.div`
  background-color: white;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 64px);
`;
Content.displayName = 'Content';

export const ContentInner = styled.div`
  padding-bottom: 32px;
`;
ContentInner.displayName = 'ContentInner';

export const Title = styled.div`
  ${props => (props.showingHeader
    ? `
      transform: translate3d(0, 0, 0);
      transition: all 250ms ease-out;
    ` : 'transform: translate3d(0, 200%, 0);'
  )}

`;
Title.displayName = 'Title';

export const HeaderAvatar = styled.div`
  ${props => (props.showingHeader
    ? `
      transform: translate3d(0, 0, 0);
      transition: all 250ms ease-out;
    ` : 'transform: translate3d(0, 200%, 0);'
  )}
`;
HeaderAvatar.displayName = 'HeaderAvatar';
