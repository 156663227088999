import { useState, useCallback } from 'react';

import { Search as SearchIcon } from 'components/Icons';

import UserSearch from './UserSearch';
import ThreadSearch from './ThreadSearch';
import NavItem from '../Item';

const Search = () => {
  const [showingSearch, setShowingSearch] = useState(false);
  const showSearch = useCallback(() => {
    setShowingSearch(true);
  }, []);
  const hideSearch = useCallback(() => {
    setShowingSearch(false);
  }, []);

  const [type, setType] = useState('users');
  const changeType = useCallback((newType) => {
    setType(newType);
  }, []);

  // Awful div nesting - Necessary for css rules
  return (
    <>
      <NavItem onClick={showSearch} className="nav-item-search">
        <div>
          <div>
            <SearchIcon color="white" size="32px" />
            {type === 'users'
              ? <UserSearch showing={showingSearch} onBlur={hideSearch} changeType={changeType} />
              : <ThreadSearch showing={showingSearch} onBlur={hideSearch} changeType={changeType} />
            }
          </div>
        </div>
      </NavItem>
    </>
  );
};

export default Search;
