import styled from 'styled-components';

import { CloseCircle, TicketPercent } from 'components/Icons';

const Coupon = styled.div.attrs(({ onClear, ...props }) => ({
  children: (
    <div>
      <TicketPercent />
      <span>{props.children}</span>
      <button type="button" onClick={onClear}><CloseCircle /></button>
    </div>
  ),
}))`
  margin-bottom: 32px;
  font-size: 32px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    > button {
      border: 0;
      background: none;
      padding: 0;
      cursor: pointer;
      margin-left: 8px;

      > svg {
        width: 16px;
        height: 16px;

        path {
          fill: ${props => props.theme.colors.secondary};
        }
      }
    }
  }
`;
Coupon.displayName = 'Coupon';

export default Coupon;
