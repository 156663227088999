import {
  Fragment, useCallback, useState, useRef, useEffect,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import fastdom from 'fastdom';
import { Link, useParams } from 'react-router-dom';

import Api from 'state/api';
import { useTranslation } from 'hooks';

import ThreadWrapper from 'containers/Profiles/Communities/UI/ThreadWrapper';
import Title from 'containers/Profiles/Communities/UI/Title';
import Subtitle from 'containers/Profiles/Communities/UI/Subtitle';
import BodyWrapper from 'containers/Profiles/Communities/UI/BodyWrapper';
import BodyShadow from 'containers/Profiles/Communities/UI/BodyShadow';
import ParsedContent from 'components/ParsedContent';

import Spinner from 'components/Spinner';
import SpinnerWrapper from 'components/Spinner/Wrapper';
import EmptyState from 'components/EmptyState';
import Ad from 'components/Ad';

import { THREADS_LIST_LIMIT } from '../../../constants';
import locales from '../i18n';

const Wrapper = styled.div`
  ${props => props.theme.css.content}
`;
Wrapper.displayName = 'Wrapper';

const Threads = () => {
  const { t } = useTranslation(locales);
  const params = useParams();

  const [threads, setThreads] = useState([]);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const isLoading = useRef(false);
  const lastThreadDate = useRef(null);

  const load = useCallback(async () => {
    if (!isLoading.current && !fullyLoaded) {
      isLoading.current = true;
      const search = { hashtag: params.hashtag };
      if (lastThreadDate.current) {
        search.before = lastThreadDate.current;
      }

      const { data } = await Api.req.get('/communities/threads', { params: search });

      if (data.length < THREADS_LIST_LIMIT) {
        setFullyLoaded(true);
      }
      if (data.length > 0) {
        setThreads(prevThreads => [
          ...prevThreads,
          ...data,
        ]);
        lastThreadDate.current = data[data.length - 1].createdAt;
      }

      isLoading.current = false;
    }
  }, [params.hashtag, fullyLoaded]);

  useEffect(() => {
    const el = document.documentElement;

    const loadMoreScrollChanged = () => {
      fastdom.measure(() => {
        if (el.scrollHeight - el.scrollTop < 2500) {
          load();
        }
      });
    };

    document.addEventListener('scroll', loadMoreScrollChanged);
    load();

    return () => {
      document.removeEventListener('scroll', loadMoreScrollChanged);
    };
  }, [load]);

  return (
    <Wrapper>
      <div>
        {threads.map((thread, index) => (
          <Fragment key={`thread-${thread.id}`}>
            <Link to={`/+${thread.community.slug}/${thread.slug}`}>
              <ThreadWrapper>
                <div className="header">
                  <Title>{thread.title}</Title>
                  <Subtitle>{`${t('global:In')} ${thread.community.name} • ${t('global:At')} ${moment(thread.createdAt).format(t('date.format'))}`}</Subtitle>
                </div>
                <BodyWrapper>
                  <ParsedContent content={thread.rawContent} />
                  <BodyShadow />
                </BodyWrapper>
              </ThreadWrapper>
            </Link>

            {index > 0 && !(index % 8) && <Ad id="In Content" />}
          </Fragment>
        ))}
      </div>

      {!fullyLoaded && (
        <SpinnerWrapper>
          <Spinner color="#999" />
        </SpinnerWrapper>
      )}

      {fullyLoaded && threads.length === 0 && (
        <EmptyState subtitle={t('No threads found')} />
      )}
    </Wrapper>
  );
};

Threads.propTypes = {
};

Threads.defaultProps = {
};

export default Threads;
