import {
  memo, useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FixedSizeGrid } from 'react-window';

import Api from 'state/api';
import useElementHeight from 'hooks/useElementHeight';
import useTranslation from 'hooks/useTranslation';

import Box from 'containers/Sidebar/Box';
import Loading from 'components/Loading';
import Busy from 'components/UserDisplayName/Busy';
import defaultAvatar from 'components/UserAvatar/default.png';

import BoxWrapper from './UI/BoxWrapper';
import locales from './i18n';

const Grid = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
Grid.displayName = 'Grid';

const User = styled.div`
  a {
    display: flex;

    &:hover {
      color: ${props => props.theme.colors.main};
    }

    > div:first-child {
      margin-right: 4px;
      width: 32px;
      height: 32px;

      img {
        width: 32px;
        height: 32px;
        border-radius: 100%;
      }
    }

    > div:nth-child(2) {
      font-size: 12px;
      overflow: hidden;

      > div {
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 4px;
        white-space: nowrap;

        &:nth-child(2) {
          color: ${props => props.theme.colors.secondary};
        }
      }
    }
  }
`;
User.displayName = 'User';


const UsersProps = {
  onlines: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ])).isRequired,
  fetchUser: PropTypes.func.isRequired,
};

// UserRow
const areRowsEqual = (prevProps, nextProps) => {
  const prevUser = prevProps.data.onlines[prevProps.index];
  const nextUser = nextProps.data.onlines[nextProps.index];

  if (typeof prevUser !== typeof nextUser) return false;
  if (typeof prevUser === 'number') return prevUser === nextUser;
  if (typeof prevUser === 'undefined') return false;

  return prevUser.id === nextUser.id;
};

const UserRow = memo(({
  data: { onlines, fetchUser }, columnIndex, rowIndex, style,
}) => {
  const { t } = useTranslation(locales);

  const user = onlines[(rowIndex * 2) + columnIndex];

  if (!user) return null;

  if (typeof user === 'number') {
    fetchUser(user);
    return <div key={`landing-online-${user}`} style={style}><Busy /></div>;
  }

  const avatarFile = !user || !user.avatar || !user.avatar['150w'] ? defaultAvatar : user.avatar['150w'].jpeg;
  const tags = user.tags.map(tag => t(`global:TAG.${tag}`)).join(' ');

  return (
    <User key={`landing-online-${user.id}`} style={style}>
      <Link to={`@${user.username}`}>
        <div>
          <img src={avatarFile} alt={user.displayname} />
        </div>
        <div>
          <div>{user.displayname}</div>
          <div>{tags}</div>
        </div>
      </Link>
    </User>
  );
}, areRowsEqual);

UserRow.propTypes = {
  data: PropTypes.shape(UsersProps).isRequired,
  columnIndex: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  style: PropTypes.shape({}).isRequired,
};


// Users
const Users = ({ onlines, fetchUser, landing }) => {
  const [gridHeight, gridElement] = useElementHeight();

  const columnWidth = landing ? 170 : 135;
  const width = landing ? 364 : 300;

  return (
    <Grid ref={gridElement}>
      <FixedSizeGrid
        height={gridHeight}
        columnCount={2}
        rowHeight={40}
        itemData={{ onlines, fetchUser }}
        columnWidth={columnWidth}
        width={width}
        rowCount={Math.ceil(onlines.length / 2)}
      >
        {UserRow}
      </FixedSizeGrid>
    </Grid>
  );
};

Users.propTypes = {
  ...UsersProps,
  landing: PropTypes.bool.isRequired,
};

Users.defaultProps = {
};


// UsersOline
const UsersOnline = ({ landing }) => {
  const { t } = useTranslation(locales);

  const [onlines, setOnlines] = useState(null);
  const queue = useRef([]);
  const queueTimerRef = useRef(null);

  const fetchUser = (userId) => {
    queue.current.push(userId);
  };

  useEffect(() => {
    const queueTimer = queueTimerRef.current;

    const load = async () => {
      const { data } = await Api.req.get('/notifications/onlines');
      setOnlines(data);
    };

    const fetchData = async () => {
      if (queue.current.length > 0) {
        const excluded = queue.current.splice(50);

        const { data } = await Api.req.get('/users', {
          params: { ids: queue.current.join(',') },
        });

        queue.current = excluded;
        setOnlines(currVal => currVal.map((val) => {
          if (!Number.isNaN(val) && data[val]) return data[val];
          return val;
        }));
      }
    };

    load();
    queueTimerRef.current = setInterval(fetchData, 2000);

    return () => {
      if (queueTimer) clearInterval(queueTimer);
    };
  }, []);

  return (
    <BoxWrapper landing={landing}>
      <Box title={t('global:Users Online')}>
        {onlines === null
          ? <Loading />
          : <Users onlines={onlines} fetchUser={fetchUser} landing={landing} />
        }
      </Box>
    </BoxWrapper>
  );
};

UsersOnline.propTypes = {
  landing: PropTypes.bool.isRequired,
};

UsersOnline.defaultProps = {
};

export default UsersOnline;
