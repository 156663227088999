import styled from 'styled-components';

import { POLL_COLORS } from '../../constants';

const PollOption = styled.div.attrs(props => ({
  children: (
    <>
      {props.text && <div className="text">{props.text}</div>}
      {typeof props.percentage !== 'undefined' && <div className="result">{`${props.percentage}%`}</div>}
    </>
  ),
}))`
  ${props => props.color && `background-color: ${POLL_COLORS[props.color]};`}
  ${props => props.image && `background-image: url(${props.image});`}
  ${props => !props.showResults && 'opacity: .6;'}
  ${props => !props.showResults && 'cursor: pointer;'}
  background-size: cover;
  height: 400px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;

  @media(max-width: 768px) {
    height: calc(100vw / 2 * 1.198);
  }

  &:hover {
    opacity: 1;
  }

  div.text {
    color: white;
    background: rgba(0, 0, 0, .5);
    border-radius: 8px;
    padding: 8px;
    font-size: 12px;
    margin-top: -32px;
  }

  div.result {
    position: absolute;
    bottom: 16px;
    color: white;
    background: rgba(0, 0, 0, .5);
    border-radius: 8px;
    padding: 8px;
    font-size: 18px;
    font-weight: bold;

    &::before {
      content: 'Total: ';
    }
  }
`;
PollOption.displayName = 'PollOption';

export default PollOption;
