import { detect } from 'detect-browser';

const browserDetect = detect();

interface Browser {
  chrome?: boolean;
  firefox?: boolean;
  safari?: boolean;
  edge?: boolean;
  unkown?: boolean;
  os: {
    linux?: boolean;
    windows?: boolean;
    mac?: boolean;
    unkown?: boolean;
  };
}

const useBrowser = () => {
  const browser: Browser = { os: {} };
  switch (browserDetect && browserDetect.name) {
    case 'chrome':
      browser.chrome = true;
      break;
    case 'firefox':
      browser.firefox = true;
      break;
    case 'safari':
      browser.safari = true;
      break;
    case 'edge':
      browser.edge = true;
      break;
    default:
      browser.unkown = true;
      break;
  }

  switch (browserDetect && browserDetect.os) {
    case 'Linux':
      browser.os.linux = true;
      break;
    case 'Windows 7':
    case 'Windows 8':
    case 'Windows 8.1':
    case 'Windows 10':
      browser.os.windows = true;
      break;
    case 'Mac OS':
      browser.os.mac = true;
      break;
    default:
      browser.os.unkown = true;
      break;
  }
  return browser;
};

export default useBrowser;
