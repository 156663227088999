import { useCallback } from 'react';
import { Icon, IconButton } from '@chakra-ui/react';
import { X } from 'lucide-react';
import { useDispatch } from 'react-redux';

import * as appActions from 'state/app/actions';

interface Props {
}

const CloseButton: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const hideLeftColumn = useCallback(() => {
    dispatch(appActions.uiLeftColumn(false));
  }, [dispatch]);

  return (
    <IconButton
      aria-label="Close"
      icon={<Icon as={X} color="black" />}
      onClick={hideLeftColumn}
      display={{ base: 'inherit', md: 'none' }}
    />
  );
};

export default CloseButton;
