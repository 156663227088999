import {
  useEffect, useCallback, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import ComposerRef from 'utils/ComposerRef';
import { useTranslation, useOpenClose } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';
import * as appActions from 'state/app/actions';

import Modal from 'components/Modal';
import Composer from 'components/Composer';
import Button from 'components/Button';
import Select from 'components/Select';
import PrivacyListsModal from 'containers/Home/New/PrivacyListsModal';

import { PUBLICATION_PRIVACIES } from '../../../constants';
import locales from '../i18n';

const PrivacyWrapper = styled.div`
  hr {
    box-shadow: 0 0 3px 1px #dedede;
    border: 0;
  }

  > div {
    margin-top: 24px;

    > div {
      z-index: 10;
    }
  }
`;
PrivacyWrapper.displayName = 'PrivacyWrapper';

const Edit = ({ publicationId, close }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const id = `publicationedit-${publicationId}`;
  const rawContent = useSelector(
    state => feedSelectors.publications.selectRawContent(state, publicationId),
  );
  const initialPrivacy = useSelector(
    state => feedSelectors.publications.selectPrivacy(state, publicationId),
  );
  const initialPrivacyLists = useSelector(
    state => feedSelectors.publications.selectPrivacyLists(state, publicationId),
    shallowEqual,
  );

  const [privacy, setPrivacy] = useState(initialPrivacy);
  const [privacyLists, setPrivacyLists] = useState(initialPrivacyLists);
  const [saving, setSaving] = useState(false);
  const [isPrivacyListsOpen, openPrivacyLists, closePrivacyLists] = useOpenClose(false);

  useEffect(() => {
    const ref = ComposerRef.getRef(id);

    if (ref) {
      ref.setValue(rawContent);
      ref.setCursor(ref.lineCount(), 0);
    }
  }, [rawContent, id]);

  const save = useCallback(async () => {
    try {
      setSaving(true);
      await dispatch(appActions.composerSendToServer(id, { privacy, privacyLists }));
      setSaving(false);

      dispatch(appActions.addToast(t('Publication updated')));
      close();
    } catch (error) {
      dispatch(appActions.addError(error));
    }
  }, [dispatch, t, id, close, privacy, privacyLists]);

  const options = useMemo(() => (
    Object.values(PUBLICATION_PRIVACIES).map(p => ({
      label: t(p),
      value: p,
    }))
  ), [t]);

  const onPrivacyChange = useCallback(({ value }) => {
    if (value === PUBLICATION_PRIVACIES.LISTS) openPrivacyLists();
    else setPrivacy(value);
  }, [openPrivacyLists]);

  const onConfirmPrivacyLists = useCallback((lists) => {
    setPrivacy(PUBLICATION_PRIVACIES.LISTS);
    setPrivacyLists(lists);
    closePrivacyLists();
  }, [closePrivacyLists]);

  return (
    <>
      <Modal
        title={t('Edit Publication')}
        onCancel={close}
        actions={[
          <Button key={`pub-edit-${publicationId}`} loading={saving} onClick={save}>{t('global:Save')}</Button>,
        ]}
        boxStyle={{ visibility: isPrivacyListsOpen ? 'hidden' : 'visible' }}
      >
        <Composer
          id={id}
          placeholder={t('What\'s on your kinky mind?')}
          height="250px"
          markdown={false}
          autofocus
        />

        <PrivacyWrapper>
          <hr />

          <Select
            value={options.find(({ value }) => value === privacy)}
            onChange={onPrivacyChange}
            placeholder={t('Privacy')}
            options={options}
            menuPlacement="top"
          />
        </PrivacyWrapper>
      </Modal>

      {isPrivacyListsOpen && (
        <PrivacyListsModal
          close={closePrivacyLists}
          initialLists={privacyLists}
          onConfirm={onConfirmPrivacyLists}
        />
      )}
    </>
  );
};

Edit.propTypes = {
  publicationId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

Edit.defaultProps = {
};

export default Edit;
