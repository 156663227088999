import styled from 'styled-components';

const ComposerWrapper = styled.div`
  min-height: 56px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  margin-bottom: 16px;

  &:focus {
    outline: none;
  }
`;

export default ComposerWrapper;
