import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const MessengerApproved = ({
  timestamp, author, messengerId, read,
}) => {
  const { t } = useTranslation(locales);

  return (
    <AlertContainer
      timestamp={timestamp}
      image={<UserAvatar userId={author.id} size="40px" showOnline={false} />}
      to={`/chat/messengers/${messengerId}`}
      read={read}
    >
      <strong><UserDisplayName userId={author.id} /></strong>
      {' '}
      {t('has approved your chat request')}
    </AlertContainer>
  );
};

MessengerApproved.propTypes = {
  read: PropTypes.bool.isRequired,
  author: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    displayname: PropTypes.string.isRequired,
  }).isRequired,
  messengerId: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default MessengerApproved;
