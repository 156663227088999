import { normalize } from 'normalizr';
import { batch } from 'react-redux';

import Api from 'state/api';
import UserQueue from 'utils/UserQueue';

import userSchema from './schema';
import {
  ADD,
  ONLINE_LIST,
  ONLINE,
  OFFLINE,
  SUGGESTIONS_LOAD,
  SUGGESTIONS_CLEAR,
} from './constants';

export const fetchData = id => (dispatch, getState) => {
  const user = getState().users.data[id];
  if (!user) {
    if (!UserQueue.has(id)) UserQueue.add(id);

    return {
      id,
      username: 'loading...',
      displayname: 'loading...',
      loading: true,
      avatar: '',
    };
  }

  return {
    ...user,
    online: getState().users.online.includes(id),
  };
};

export const add = users => (dispatch, getState) => {
  if (typeof users === 'number') {
    const u = getState().users.data[users];
    if (!u && !UserQueue.has(users)) {
      UserQueue.add(users);
    }
  } else {
    dispatch({ type: ADD, data: users });
  }
};

export const loadFromIds = ids => (dispatch, getState) => {
  batch(() => {
    ids.forEach((id) => {
      const user = getState().users.data[id];
      if (!user && !UserQueue.has(id)) UserQueue.add(id);
    });
  });
};

let isFetchingFromQueue = false;
export const fetchFromQueue = () => async (dispatch, getState) => {
  const SEARCH_LIMIT = 250;

  const state = getState().users;
  const users = [...UserQueue].filter(id => !Object.keys(state.data).includes(id));

  if (!isFetchingFromQueue && users.length) {
    isFetchingFromQueue = true;
    const excluded = users.splice(SEARCH_LIMIT);

    UserQueue.clear();
    if (excluded) {
      excluded.forEach(i => UserQueue.add(i));
    }

    const { data } = await Api.req.get('/users', {
      params: { ids: users.join(',') },
    });

    batch(() => {
      dispatch({ type: ADD, data });
    });

    isFetchingFromQueue = false;
  }
};

const fetchedUsernames = [];
export const fetchByUsername = (username, force = false) => async (dispatch) => {
  if (!force && fetchedUsernames.includes(username)) throw new Error('ALREADY_FETCHED');

  fetchedUsernames.push(username);

  try {
    const { data: rawData } = await Api.req.get(`/users/${username.trim()}`, {
      params: {
        relationships: true,
        subscriptions: true,
        view: force ? true : undefined,
      },
    });
    const data = normalize(rawData, userSchema);

    batch(() => {
      dispatch({
        type: ADD,
        data: {
          ...data.entities.users,
          [data.result]: {
            ...data.entities.users[data.result],
            fullyLoaded: true,
          },
        },
      });
    });

    return { ...rawData };
  } catch (error) {
    throw error;
  }
};

export const searchByName = (search, max) => (dispatch, getState) => {
  const { online, data: users } = getState().users;

  const match = user => (
    user && search && (
      (user.displayname && user.displayname.toLowerCase().includes(search.toLowerCase()))
      || (user.username && user.username.toLowerCase().includes(search.toLowerCase()))
    )
  );

  const onlines = [];
  online.forEach((userId) => {
    const user = users[userId];
    if (user && match(user)) {
      onlines.push(user);
    }
  });

  if (onlines.length >= max) return onlines.slice(0, max);

  const offlines = Object.values(users)
    .filter(user => !online.includes(user.id))
    .filter(match)
    .slice(0, (max - onlines.length));

  return [...onlines, ...offlines];
};

export const getKnowing = userId => async () => {
  const { data } = await Api.req.get(`/users/${userId}/knows`);
  return data;
};

export const getFollowers = userId => async (dispatch, getState) => {
  const user = getState().users.data[userId];
  if (!user) return [];

  const { username } = user;
  const { data } = await Api.req.get(`/users/${username}/followers`);
  return data;
};

export const getFollowing = userId => async (dispatch, getState) => {
  const user = getState().users.data[userId];
  if (!user) return [];

  const { username } = user;
  const { data } = await Api.req.get(`/users/${username}/following`);
  return data;
};

export const loadNotificationsConfig = key => async () => {
  const params = {};
  if (key) params.key = key;
  const { data } = await Api.req.get('/notifications/config', { params });
  return data;
};

export const saveNotificationsConfigEmail = (body, key) => async () => {
  const params = {};
  if (key) params.key = key;

  const { data } = await Api.req.put('/notifications/config/email', body, { params });
  return data;
};

export const saveNotificationsConfigPush = (body, key) => async () => {
  const params = {};
  if (key) params.key = key;

  const { data } = await Api.req.put('/notifications/config/push', body, { params });
  return data;
};

export const loadSuggestions = () => async (dispatch) => {
  const { data } = await Api.req.get('/users/suggestions');
  dispatch({ type: SUGGESTIONS_LOAD, data });
};

export const removeSuggestion = userId => async () => {
  await Api.req.delete(`/users/suggestions/${userId}`);
};

export const clearSuggestions = () => async (dispatch) => {
  dispatch({ type: SUGGESTIONS_CLEAR });
};

export const handleOnlineList = ({ online }) => (dispatch, getState) => {
  const total = getState().users.online.length;

  // $FIXME: Hack to prevent big jumps in online list that freezes the
  // browser in old and mobile devices
  const diff = online.length - total;
  if (diff < 200 || !total) {
    dispatch({
      type: ONLINE_LIST,
      data: online,
    });
  }
};

export const handleOnline = ({ userId }) => dispatch => dispatch({ type: ONLINE, userId });

export const handleOffline = ({ userId }) => dispatch => dispatch({ type: OFFLINE, userId });
