import styled from 'styled-components';
import { useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import UserTag from 'components/UserTag';

import { Flex } from '@chakra-ui/react';
import locales from '../i18n';

const UserTagsWrapper = styled.div.attrs({
  className: 'usertags',
})`
  margin-bottom: 22px !important;
  flex-wrap: wrap;

  .more-tags {
    border-color: ${colors.red};
  }
`;
UserTagsWrapper.displayName = 'UserTagsWrapper';

interface Props {
  userId: number;
  dark?: boolean;
  showUpToAmount?: number;
}

const UserTags: React.FC<Props> = ({ userId, dark, showUpToAmount }) => {
  const { t } = useTranslation(locales);

  const isOrganization = useSelector(userSelectors.isOrganization(userId));
  const tags = useSelector(userSelectors.getTags(userId));
  const pronoun = useSelector(userSelectors.getPronoun(userId));

  if (isOrganization || !tags || !tags.length) return null;

  return (
    <Flex flexWrap="wrap" justifyContent="center">
      {showUpToAmount ? tags.map((tag: string, index: number) => {
        if (index < showUpToAmount - 1
          || (index === showUpToAmount - 1 && tags.length === showUpToAmount)) {
          return (
            // @ts-ignore
            <UserTag key={`usertag-${userId}-${tag}`} dark={dark}>
              {t(`global:TAG.${tag}`, { context: pronoun })}
            </UserTag>
          );
        }
        if (index === showUpToAmount - 1) {
          return (
            // @ts-ignore
            <UserTag key={`usertag-${userId}-${tag}`} dark={dark} className="more-tags">
              +
              {tags.length - showUpToAmount + 1}
              {' '}
              {t('tags')}
            </UserTag>
          );
        }

        return null;
      }) : tags.map((tag: string) => (
        // @ts-ignore
        <UserTag key={`usertag-${userId}-${tag}`} dark={dark}>
          {t(`global:TAG.${tag}`, { context: pronoun })}
        </UserTag>
      ))}
    </Flex>
  );
};

export default UserTags;
