import produce from 'immer';

import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  THREAD_PREPEND,
  THREAD_APPEND,
  FULLY_LOADED,
  APPEND_REACTION,
  REMOVE_REACTION,
  APPEND_DISLIKE,
  REMOVE_DISLIKE,
  NEW_REPLY,
  REMOVE_REPLY,
  EDIT_REPLY,
  WIPE,
} from './constants';

export const initialState = {
  data: {},
  threads: {},
  loading: [],
  fullyLoaded: [],
  creating: false,
  creatingError: null,
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOAD:
      draft.loading.push(action.id);
      break;

    case LOAD_SUCCESS:
      draft.loading = state.loading.filter(id => id !== action.id);
      draft.data = {
        ...state.data,
        ...action.data,
      };
      break;

    case LOAD_FAIL:
      draft.loading = state.loading.filter(id => id !== action.id);
      break;

    case NEW_REPLY:
      draft.data = {
        ...state.data,
        ...action.data,
      };
      break;

    case THREAD_PREPEND: {
      const ids = action.data.filter(id => !(state.threads[action.id] || []).includes(id));
      draft.threads[action.id] = [
        ...ids,
        ...(draft.threads[action.id] || []),
      ];
      break;
    }

    case THREAD_APPEND: {
      const repliesByThread = draft.threads[action.id] || [];
      action.data.forEach((id) => {
        const existReply = repliesByThread.some(replyId => replyId === id);
        if (!existReply) {
          repliesByThread.push(id);
        }
      });
      break;
    }

    case FULLY_LOADED:
      draft.fullyLoaded.push(action.id);
      break;

    case REMOVE_REPLY:
      draft.data[action.id] = {
        ...draft.data[action.id],
        deletedAt: new Date(),
      };
      break;

    case EDIT_REPLY:
      draft.data[action.id] = {
        ...state.data[action.id],
        ...action.data,
      };
      break;

    case APPEND_REACTION:
      draft.data[action.replyId].reactedByUserIds.push(action.userId);
      draft.data[action.replyId].dislikedByUserIds = state.data[action.replyId].dislikedByUserIds
        .filter(uId => uId !== action.userId);
      break;

    case REMOVE_REACTION: {
      const reactions = state.data[action.replyId].reactedByUserIds
        .filter(uId => uId !== action.userId);
      draft.data[action.replyId].reactedByUserIds = reactions;
      break;
    }

    case APPEND_DISLIKE:
      draft.data[action.replyId].dislikedByUserIds.push(action.userId);
      draft.data[action.replyId].reactedByUserIds = state.data[action.replyId].reactedByUserIds
        .filter(uId => uId !== action.userId);
      break;

    case REMOVE_DISLIKE: {
      const reactions = state.data[action.replyId].dislikedByUserIds
        .filter(uId => uId !== action.userId);
      draft.data[action.replyId].dislikedByUserIds = reactions;
      break;
    }

    case WIPE:
      draft.data = initialState.data;
      draft.threads = initialState.threads;
      draft.loading = initialState.loading;
      draft.fullyLoaded = initialState.fullyLoaded;
      draft.creating = initialState.creating;
      draft.creatingError = initialState.creatingError;
      break;

    default:
  }
});

export default reducer;
