import { createSelector } from 'reselect';

import * as userSelectors from 'state/users/selectors';

// Input selectors
export const selectChatList = state => state.app.chatList;

export const selectErrors = state => state.app.errors;

export const selectToasts = state => state.app.toasts;

export const selectIsSafeForWork = state => state.app.safeForWork;

export const selectShowingAdInfoModal = state => state.app.ui.adInfo;

export const selectIsUiLeftColumnActive = state => state.app.ui.leftColumn;

export const selectIsUiRightColumnActive = state => state.app.ui.rightColumn;

export const selectIsNavLeftActive = state => state.app.navLeftActive;

export const selectIsShowingParticipants = state => state.app.ui.channels.showingParticipants;

export const selectWaitingSW = state => state.app.sw.waiting;

export const selectAreResourcesLoaded = state => state.app.resourcesLoaded;

const selectMiniProfileData = state => state.app.miniprofile;

const selectLocationHistory = state => state.app.locationHistory;

const selectComposers = state => state.app.composers;
//

export const selectMiniprofile = createSelector(
  selectMiniProfileData,
  userSelectors.selectData,
  (profile, users) => {
    if (!profile || typeof profile === 'object') return profile;

    return Object.values(users).find(u => u.username === profile);
  },
);

export const selectUrlHistory = createSelector(
  selectLocationHistory,
  (_, match) => match,
  (locationHistory, match) => locationHistory.map(l => l.pathname).filter(l => l.match(match)),
);

export const selectHistoryCanGoBack = createSelector(
  selectLocationHistory,
  locationHistory => locationHistory.length > 1,
);

const selectComposer = createSelector(
  selectComposers,
  (_, id) => id,
  (composers, id) => composers[id],
);

export const selectMedia = createSelector(
  selectComposer,
  composer => ((!composer || !composer.media) ? [] : composer.media),
);

// Composers
export const selectIsComposerReadyToSubmit = createSelector(
  selectComposer,
  (composer) => {
    const { hasContent, media = [] } = (composer || {});

    const hasMediaReady = media.length === media.filter(m => m.uploaded).length;

    if (!hasMediaReady) return false;
    return media.length > 0 || !!hasContent;
  },
);

export const selectIsComposerSaving = createSelector(
  selectComposer,
  composer => (composer ? composer.saving : false),
);

export const selectIsComposerDisabled = createSelector(
  selectComposer,
  composer => (composer ? composer.readOnly : false),
);

export const selectComposerHasContent = createSelector(
  selectComposer,
  (composer) => {
    if (!composer) return false;

    const { hasContent, media = [] } = composer;

    const hasMedia = media.length > 0;

    return !!hasMedia || !!hasContent;
  },
);
