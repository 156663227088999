import styled from 'styled-components';

interface Props extends React.HTMLProps<HTMLDivElement> {
  canOverflow?: boolean;
  hasHeader?: boolean;
  mobileHasHeader?: boolean;
  fullHeight?: boolean;
}

const FlexWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  background-color: white;

  ${props => !props.canOverflow && `
    height: 100vh;
    overflow: auto;
  `}

  ${props => props.mobileHasHeader && `
    @media(max-width: 768px) {
      margin-top: 64px;
    }
  `}

  ${props => props.hasHeader && `
    margin-top: 64px;
  `}

  ${props => props.fullHeight && `
    @media(min-width: 768px) {
      height: 100%;
    }
    @media(max-width: 768px) {
      height: auto;
    }
  `}

  .emptystate {
    flex: 1;
    height: 100%;
  }
`;
FlexWrapper.displayName = 'FlexWrapper';

export default FlexWrapper;
