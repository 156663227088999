import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import moment from 'moment';

import isEmojiOnly from 'utils/isEmojiOnly';
import filterEntities from 'utils/filterEntities';
import * as userSelectors from 'state/users/selectors';
import * as authSelectors from 'state/auth/selectors';
import * as appSelectors from 'state/app/selectors';
import * as messengerSelectors from 'state/messengers/selectors';

import {
  CHANNEL_ROLES, CM_AUTHOR_TYPES, BADGES, CHANNEL_MESSAGES_TYPES,
} from '../../constants';

const MOD_ROLES = [CHANNEL_ROLES.OWNER, CHANNEL_ROLES.MOD];

// Input selectors

const selectChannels = state => state.channels.channels;

const selectData = state => state.channels.data;

const selectChannelsData = state => state.channels.data.channels;

const selectChannelMessages = state => state.channels.channelmessages;

const selectChannelMessagesData = state => state.channels.data.channelmessages;

const selectFullyLoaded = state => state.channels.fullyLoaded;

const selectTyping = state => state.channels.typing;

const selectUnreadMentions = state => state.channels.unreadMentions;

const selectActive = state => state.channels.active;

const selectBots = state => state.channels.data.bots;

const selectReplyTo = state => state.channels.replyTo;

const selectEditing = state => state.channels.editing;

const selectReads = state => state.channels.reads;

const selectLastMessages = state => state.channels.lastMessages;

export const isInitialized = state => state.channels.initialized;

//

const selectMessageById = createSelector(
  selectChannelMessagesData,
  (_, messageId) => messageId,
  (channelmessages, messageId) => channelmessages[messageId],
);

const selectChannelById = createSelector(
  selectChannelsData,
  (_, channelId) => channelId,
  (channels, channelId) => channels[channelId],
);

const selectReadByChannelId = createSelector(
  selectReads,
  (_, channelId) => channelId,
  (reads, channelId) => reads[channelId],
);

export const getAll = createSelector(
  selectData,
  selectChannels,
  (data, channels) => channels.map(c => data.channels[c]),
);

export const amISubscribed = createSelector(
  selectChannels,
  (_, id) => id,
  (channels, id) => channels.includes(id),
);

export const getMessagesByChannelId = createSelector(
  selectChannelMessagesData,
  selectChannelMessages,
  (_, channelId) => channelId,
  (data, channelmessages, channelId) => (channelmessages[channelId] || []).map(id => data[id]),
);

export const getLastMessageByChannelId = createSelector(
  getMessagesByChannelId,
  messages => messages[messages.length - 1],
);

export const selectLastOutgoingMessageIdByChannelId = createSelector(
  getMessagesByChannelId,
  authSelectors.selectId,
  (messages, myId) => {
    let offset = 1;
    let found = false;

    while (!found && offset <= messages.length) {
      const message = messages[messages.length - offset];
      if (message.authorId === myId) {
        found = true;
      } else {
        offset += 1;
      }
    }

    if (found) return messages[messages.length - offset].id;

    return null;
  },
);

export const getMessageAuthorId = createSelector(
  selectMessageById,
  authSelectors.selectId,
  (message, meId) => {
    if (!message) return meId;
    if (message.authorId) return message.authorId;
    if (message.author) {
      if (message.author.type === 'AUTORESPONDER') return message.channel;
      return message.author.id;
    }
    return meId;
  },
);

export const getMessageAuthorType = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return CM_AUTHOR_TYPES.USER;
    if (message.author) return message.author.type;
    return CM_AUTHOR_TYPES.USER;
  },
);

export const isMessageOutgoing = createSelector(
  selectMessageById,
  authSelectors.selectId,
  (message, meId) => {
    if (!message) return true;
    if (message.authorId) return message.authorId === meId;
    if (message.author) return message.author.id === meId;
    if (!message.authorId) return true;
    return message.authorId === meId;
  },
);

export const isNotice = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return false;
    return message.type === CHANNEL_MESSAGES_TYPES.NOTICE;
  },
);

export const messageIsEdited = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return false;
    return message.edited;
  },
);

export const getMessageCreatedAt = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return null;
    return message.createdAt;
  },
);

export const isMessageEmojiOnly = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return false;
    return isEmojiOnly(message);
  },
);

export const getMessageReactions = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return null;
    return message.reactions;
  },
);

export const getMessageGif = createSelector(
  selectMessageById,
  (message) => {
    if (!message || !message.payload || !message.payload.media) return null;
    return message.payload.media.gif;
  },
);

export const getMessageMedia = createSelector(
  selectMessageById,
  (message) => {
    if (!message || !message.payload) return null;
    return message.payload.media;
  },
);

export const getReplyingTo = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return null;
    return message.replyingTo;
  },
);

export const getMessageContentForEditor = createSelector(
  selectMessageById,
  (message) => {
    if (!message) return null;
    return message.editorContent;
  },
);

export const getMessageContent = createSelector(
  selectMessageById,
  (message) => {
    if (!message || !message.payload || !message.payload.rawContent) return '';
    return message.payload.rawContent;
  },
);

export const hasSadesAsk = createSelector(
  selectMessageById,
  message => !!message?.payload?.sadesAsk,
);

export const sadesAskData = createSelector(
  selectMessageById,
  message => message?.payload?.sadesAsk,
);

export const messageHasReactions = createSelector(
  getMessageReactions,
  reactions => (reactions || []).filter(r => r.authorIds.length > 0).length > 0,
);

export const isFullyLoaded = createSelector(
  selectFullyLoaded,
  (_, channelId) => channelId,
  (fullyLoaded, channelId) => fullyLoaded.includes(channelId),
);

export const getUnreadById = createSelector(
  selectChannelById,
  channel => channel.unreadCount,
);

export const totalUnread = createSelector(
  selectChannels,
  selectChannelsData,
  (channels, data) => channels.reduce((sum, mId) => (sum + data[mId].unreadCount), 0),
);

export const getIsTyping = createSelector(
  selectTyping,
  (_, channelId) => channelId,
  (typing, channelId) => (typing[channelId] || []).length > 0,
);

export const getUnreadMentions = createSelector(
  selectUnreadMentions,
  (_, channelId) => channelId,
  (unreadMentions, channelId) => unreadMentions[channelId] || 0,
);

export const channelCount = createSelector(
  selectChannels,
  channels => channels.length,
);

// TODO: Fix this state passing
export const allLastMessages = createSelector(
  selectChannels,
  state => state,
  (channelIds, state) => {
    const lastMessagesDates = {};
    channelIds.forEach((id) => {
      lastMessagesDates[id] = getLastMessageByChannelId(state, id);
    });

    return lastMessagesDates;
  },
);

export const onlineCount = createSelector(
  selectChannelById,
  userSelectors.selectOnline,
  (channel, online) => {
    if (!channel) return 'N/A';

    return channel
      .participants
      .filter(p => online.includes(p.userId))
      .length;
  },
);

export const membersCount = createSelector(
  selectChannelById,
  channel => channel.participantCount,
);

export const isOwnerOfChannel = createSelector(
  selectChannelById,
  authSelectors.selectId,
  (channel, meId) => channel.participants.find(p => p.role === CHANNEL_ROLES.OWNER).userId === meId,
);

export const isModOfChannel = createSelector(
  selectChannelById,
  authSelectors.selectId,
  (channel, meId) => {
    const myParticipation = channel.participants.find(p => p.userId === meId);
    return MOD_ROLES.includes(myParticipation.role);
  },
);

export const iAmSuperior = createSelector(
  selectChannelById,
  authSelectors.selectId,
  (_, __, userId) => userId,
  (channel, meId, userId) => {
    const roleIndex = (uId) => {
      const participant = channel.participants.find(p => p.userId === uId);
      if (!participant) return 999;
      return Object.values(CHANNEL_ROLES).indexOf(participant.role);
    };

    return roleIndex(meId) < roleIndex(userId);
  },
);

export const getName = createSelector(
  selectChannelById,
  channel => (channel ? channel.name : false),
);

export const getDescription = createSelector(
  selectChannelById,
  channel => (channel ? channel.description : false),
);

export const getPrivacy = createSelector(
  selectChannelById,
  channel => (channel ? channel.privacy : false),
);

export const getAvatar = createSelector(
  selectChannelById,
  channel => (channel ? channel.avatar : null),
);

const selectParticipantsByChannelId = createSelector(
  selectChannelById,
  channel => channel.participants,
);

const selectFilteredParticipantsByChannelId = createSelector(
  selectParticipantsByChannelId,
  userSelectors.selectOnline,
  (participants, online) => {
    const filtered = participants.length < 500
      ? participants
      : participants.filter(p => online.includes(p.userId));

    return filtered;
  },
);

const selectOnlineByChannelId = createSelector(
  selectParticipantsByChannelId,
  userSelectors.selectOnline,
  (participants, online) => (
    online.filter(userId => participants.findIndex(p => p.userId === userId) > -1)
  ),
);

const createParticipantsSelector = createSelectorCreator(
  defaultMemoize,
  (prev, next) => {
    // This is far from elegant - It's done like this to calculate only when online data changes
    if (!Array.isArray(prev) || !Array.isArray(next) || typeof next[0] !== 'number') return true;

    if (prev.length !== next.length) return false;

    return !prev.some(uId => !next.includes(uId));
  },
);

export const selectParticipantsUsersIdInOrder = createParticipantsSelector(
  selectOnlineByChannelId,
  selectFilteredParticipantsByChannelId,
  userSelectors.selectData,
  (online, participants, users) => participants
    .slice()
    .sort((a, b) => {
      const roleIndex = role => Object.values(CHANNEL_ROLES).indexOf(role);
      const badgesValue = userId => (users[userId]
        ? (users[userId].badges || [])
          .reduce((acc, badge) => (acc + (BADGES[badge]?.value || 0)), 0)
        : 0
      );
      const displayname = userId => (users[userId] ? users[userId].displayname : 'zzzz'); // Hack to send it to the bottom

      if (online.includes(a.userId) && !online.includes(b.userId)) return -1;
      if (!online.includes(a.userId) && online.includes(b.userId)) return 1;
      if (roleIndex(a.role) < roleIndex(b.role)) return -1;
      if (roleIndex(a.role) > roleIndex(b.role)) return 1;
      if (badgesValue(a.userId) < badgesValue(b.userId)) return 1;
      if (badgesValue(a.userId) > badgesValue(b.userId)) return -1;
      if (displayname(a.userId) < displayname(b.userId)) return -1;
      if (displayname(a.userId) > displayname(b.userId)) return 1;

      return 0;
    })
    .map(p => p.userId),
);

export const getRoleByUserId = createSelector(
  selectChannelById,
  (_, __, userId) => userId,
  (channel, userId) => {
    const participant = channel.participants.find(p => p.userId === userId);
    if (!participant) return null;
    return participant.role;
  },
);

export const getBansByChannelId = createSelector(
  selectChannelById,
  channel => channel.bans,
);

const filteredEntitiesSelector = createSelector(
  appSelectors.selectChatList,
  (_, filter) => filter,
  messengerSelectors.selectMessengersData,
  selectChannelsData,
  userSelectors.selectData,
  authSelectors.selectId,
  (list, filter, messengers, channels, users, meId) => {
    const getEntityName = (entity) => {
      if (entity.type === 'messenger') {
        const data = messengers[entity.id];
        const { userId } = data.participants.find(p => p.userId !== meId);
        const user = users[userId];
        return user.displayname;
      }

      const data = channels[entity.id];
      return data.name;
    };

    return filterEntities(
      list
        .map(entity => ({ type: entity.type, id: entity.id, name: getEntityName(entity) })),
      filter,
    );
  },
);

export const getSortedAndFilteredEntities = createSelector(
  (_, filter) => filter,
  filter => (filter ? filteredEntitiesSelector : appSelectors.selectChatList),
);

export const sortedAndFilteredEntitiesSelector = (state, filter) => (
  getSortedAndFilteredEntities(state, filter)(state, filter)
);

export const hasUnread = createSelector(
  selectReadByChannelId,
  getLastMessageByChannelId,
  (lastRead, lastMessage) => lastMessage && moment(lastRead).isBefore(lastMessage.createdAt),
);

export const selectUnreadChannels = createSelector(
  selectReads,
  selectLastMessages,
  selectActive,
  (reads, lastMessages, active) => Object.keys(lastMessages).filter((channelId) => {
    const lastMessage = lastMessages[channelId];
    const lastRead = reads[channelId];

    return channelId !== active && lastMessage && moment(lastRead).isBefore(lastMessage.createdAt);
  }),
);

export const haveUnread = createSelector(
  selectUnreadChannels,
  channelIds => channelIds.length > 0,
);

export const botIds = createSelector(
  selectChannelById,
  (channel) => {
    if (!channel || !channel.bots) return [];

    return channel.bots.map(b => (typeof b.bot === 'object' ? b.bot.id : b.bot));
  },
);

export const getBotById = createSelector(
  selectBots,
  (_, botId) => botId,
  (bots, botId) => bots[botId],
);

export const getBotName = createSelector(
  getBotById,
  bot => bot.name,
);

export const getReplyTo = createSelector(
  selectReplyTo,
  (_, channelId) => channelId,
  (replyTo, channelId) => replyTo[channelId],
);

export const isEditing = createSelector(
  selectEditing,
  (_, channelId) => channelId,
  (editing, channelId) => !!editing[channelId],
);

export const getEditingMessageId = createSelector(
  selectEditing,
  (_, channelId) => channelId,
  (editing, channelId) => editing[channelId],
);

export const messageIsBeingRemoved = createSelector(
  selectMessageById,
  message => message && message.removing,
);

export const hasEmbed = createSelector(
  selectChannelById,
  channel => !!channel?.embed?.url,
);

export const getEmbedUrl = createSelector(
  selectChannelById,
  channel => channel?.embed?.url,
);

export const hasEmbedToken = createSelector(
  selectChannelById,
  channel => channel?.embed?.withToken,
);

export const getEmbedToken = createSelector(
  selectChannelById,
  channel => channel?.embed?.token,
);
