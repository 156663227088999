import { FC } from 'react';

import UserLink from 'components/UserLink';
import useDisplayName from 'hooks/useDisplayName';


interface Props {
  userId: number;
}

const Rsvp: FC<Props> = ({ userId }) => {
  const displayname = useDisplayName(userId);
  return <UserLink userId={userId}>{displayname}</UserLink>;
};

export default Rsvp;
