import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;

  @media(max-width: 767px) {
    margin-top: 64px;
    height: calc(100vh - 64px);
  }
`;

export default Wrapper;
