import * as userActions from 'state/users/actions';
import { normalize } from 'normalizr';
import ReactGA from 'react-ga4';
import { batch } from 'react-redux';

import * as communitySelectors from 'state/communities/selectors';
import * as communityActions from 'state/communities/actions';

import Api from 'state/api';
import AppError from 'utils/AppError';

import membershipSchema from './schema';
import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  CREATE,
  CREATE_SUCCESS,
  CREATE_FAIL,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAIL,
  WIPE,
} from './constants';
import { MEMBERSHIP_ROLES } from '../../constants';

export const add = data => (dispatch) => {
  const normalizedData = normalize(data, [membershipSchema]);

  batch(() => {
    dispatch(userActions.add(normalizedData.entities.users));
    dispatch(communityActions.loadSuccess(normalizedData.entities.communities));
    dispatch({ type: LOAD_SUCCESS, data: normalizedData.entities.memberships });
  });
};

export const load = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD });
    const { data } = await Api.req.get('/communities/memberships');
    dispatch(add(data));
  } catch (err) {
    let error = err.message;
    if (err.response) {
      // eslint-disable-next-line
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }

    dispatch({ type: LOAD_FAIL, error });
  }
};

export const create = community => async (dispatch) => {
  try {
    dispatch({ type: CREATE, communitySlug: community.slug });
    const { data } = await Api.req.post(`/communities/${community.slug}/memberships`);
    const normalizedData = normalize(data, membershipSchema);

    batch(() => {
      dispatch({ type: LOAD_SUCCESS, data: normalizedData.entities.memberships });
      dispatch(communityActions.loadSuccess({
        [community._id]: community,
      }));
      dispatch({ type: CREATE_SUCCESS, communitySlug: community.slug });
    });

    ReactGA.event({
      category: 'Communities',
      action: 'Membership created',
      label: community.slug,
    });
  } catch (error) {
    dispatch({
      type: CREATE_FAIL,
      communitySlug: community.slug,
      error,
    });
    throw new AppError(error);
  }
};

export const del = membership => async (dispatch, getState) => {
  const community = getState().communities.data[membership.community];
  try {
    dispatch({ type: DELETE, communitySlug: community.slug });
    await Api.req.delete(`/communities/${community.slug}/memberships/${membership._id}`);

    ReactGA.event({
      category: 'Communities',
      action: 'Membership removed',
      label: community.slug,
    });

    batch(() => {
      if (membership.approved) {
        dispatch(communityActions.decMembershipCount(community));
      }

      dispatch({
        type: DELETE_SUCCESS,
        communitySlug: community.slug,
        membershipId: membership._id,
      });
    });
  } catch (error) {
    dispatch({
      type: DELETE_FAIL,
      communitySlug: community.slug,
      error,
    });
    throw new AppError(error);
  }
};

// TODO: Check if we can deprecate this one!!
export const removeByCommunity = community => (dispatch, getState) => {
  try {
    const membership = Object.values(getState().memberships.data)
      .find(m => m.community === community._id);

    dispatch({
      type: DELETE_SUCCESS,
      communitySlug: community.slug,
      membershipId: membership._id,
    });
  } catch (error) {
    throw new AppError(error);
  }
};

export const removeByCommunityId = communityId => (dispatch, getState) => {
  try {
    const { slug } = getState().communities.data[communityId];

    const membership = Object.values(getState().memberships.data)
      .find(m => m.community === communityId);

    dispatch({
      type: DELETE_SUCCESS,
      communitySlug: slug,
      membershipId: membership._id,
    });
  } catch (error) {
    throw new AppError(error);
  }
};

const editRole = (communityId, membershipId, role) => async (dispatch, getState) => {
  const slug = communitySelectors.selectSlugById(getState(), communityId);

  try {
    const { data } = await Api.req.put(`/communities/${slug}/memberships/${membershipId}`, {
      role,
    });

    ReactGA.event({
      category: 'Communities',
      action: 'Role changed',
      label: communityId,
    });

    data.user = data.user.id;
    dispatch(communityActions.updateModRole(communityId, data, role));
  } catch (error) {
    throw new AppError(error);
  }
};

// TODO: Remove!!
export const makeAdmin = (communityId, membershipId) => dispatch => (
  dispatch(editRole(communityId, membershipId, MEMBERSHIP_ROLES.ADMIN))
);

// TODO: Remove!!
export const makeMod = (communityId, membershipId) => dispatch => (
  dispatch(editRole(communityId, membershipId, MEMBERSHIP_ROLES.MODERATOR))
);

export const makeModByUserId = (communityId, userId) => async (dispatch, getState) => {
  const slug = communitySelectors.selectSlugById(getState(), communityId);

  const { data } = await Api.req.get(`/communities/${slug}/memberships`, {
    params: { userId },
  });

  if (!data.length) throw new Error('The user is not a community member');
  const { _id: membershipId } = data[0];

  await dispatch(editRole(communityId, membershipId, MEMBERSHIP_ROLES.MODERATOR));
};

export const makeAdminByUserId = (communityId, userId) => async (dispatch, getState) => {
  const slug = communitySelectors.selectSlugById(getState(), communityId);

  const { data } = await Api.req.get(`/communities/${slug}/memberships`, {
    params: { userId },
  });

  if (!data.length) throw new Error('The user is not a community member');
  const { _id: membershipId } = data[0];

  await dispatch(editRole(communityId, membershipId, MEMBERSHIP_ROLES.ADMIN));
};

export const unprivilege = (communityId, membershipId) => dispatch => (
  dispatch(editRole(communityId, membershipId, MEMBERSHIP_ROLES.USER))
);

export const handleCommunityJoined = payload => (dispatch) => {
  const { entities: { memberships: mms } } = normalize(payload, membershipSchema);
  dispatch({ type: LOAD_SUCCESS, data: mms });
};

export const wipe = () => dispatch => dispatch({ type: WIPE });
