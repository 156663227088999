import styled from 'styled-components';

import useOpenClose from 'hooks/useOpenClose';

import SelectCommunityModal from 'containers/Communities/ThreadList/SelectCommunityModal';

import Create from './Create';
import Search from './Search';
import Alerts from '../Alerts';
import UserMenu from './UserMenu';

const Wrapper = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
`;

const NavRight = () => {
  const [communityModal, openCommunityModal, closeCommunityModal] = useOpenClose();

  return (
    <>
      <Wrapper>
        <Create openCommunityModal={openCommunityModal} />
        <Search />
        <Alerts />
        <UserMenu />
      </Wrapper>

      {/* Modals */}
      {communityModal && <SelectCommunityModal onCancel={closeCommunityModal} />}
    </>
  );
};

NavRight.propTypes = {
};

NavRight.defaultProps = {
};

export default NavRight;
