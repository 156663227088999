import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';

import colors from 'utils/css/colors';

const AlertContainerWrapper = styled.div`
  border-left: 5px solid ${props => (props.read ? 'white' : colors.red)};
  min-height: 48px;
  border-radius: 0;
  padding: 2px 8px;
  border-bottom: 1px solid #eee;
  color: #333;
  width: 100%;
  box-sizing: border-box;
`;

const AlertLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;

  .avatar {
    border-radius: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
`;

const AlertContainerImage = styled.img`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

const AlertContainerBody = styled.div`
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  white-space: break-spaces;
  max-height: 32px;
`;

const Span = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: default;

  .avatar {
    border-radius: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  width: 100%;
  margin-right: 5px;
`;

const AlertContainerTimestamp = styled.span`
  font-size: 10px;
  color: ${colors.grey};
`;

const AlertContainer = ({
  to,
  image,
  children,
  read,
  timestamp,
}) => {
  const Wrapper = to ? AlertLink : Span;

  return (
    <AlertContainerWrapper read={read}>
      <Wrapper to={to}>
        {image && (
          <>
            {typeof image === 'string'
              ? <AlertContainerImage src={image} />
              : image
            }
          </>
        )}
        <Column>
          <AlertContainerBody>
            {children}
          </AlertContainerBody>
          {timestamp && (
            <AlertContainerTimestamp>
              {moment(timestamp).fromNow()}
            </AlertContainerTimestamp>
          )}
        </Column>
      </Wrapper>
    </AlertContainerWrapper>
  );
};

AlertContainer.propTypes = {
  to: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  read: PropTypes.bool.isRequired,
  timestamp: PropTypes.string,
};

AlertContainer.defaultProps = {
  image: null,
  to: null,
  timestamp: null,
};

export default AlertContainer;
