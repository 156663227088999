import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const normalPath = 'M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z';
const outlinePath = 'M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12M8.8,14L10,12.8V4H14V12.8L15.2,14H8.8Z';

const Pin = memo(({ color, outline }) => (
  <svg viewBox="0 0 24 24">
    <path
      d={outline ? outlinePath : normalPath}
      fill={color}
    />
  </svg>
));

Pin.propTypes = IconType;
Pin.defaultProps = IconDefaults;

export default Pin;
