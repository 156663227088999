import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const Plus = ({ color, style }) => (
  <svg viewBox="0 0 24 24" style={style}>
    <path
      d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
      fill={color}
    />
  </svg>
);

Plus.propTypes = IconType;
Plus.defaultProps = IconDefaults;

export default memo(Plus);
