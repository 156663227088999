import styled from 'styled-components';

import colors from 'utils/css/colors';

interface Props extends React.HTMLProps<HTMLDivElement> {
  fullHeight?: boolean;
}

const InnerWrapper = styled.div<Props>`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  ${props => props.fullHeight && `
    height: 100%;
  `}

  > h1 {
    margin: 32px 0 16px;
    text-align: center;
    color: ${colors.h1};
    font-weight: 500;
  }

  svg {
    width: auto;
  }

  .overflow-component {
    height: 100%;

    @media(max-width: 768px) {
      margin-top: 64px;
      height: calc(100% - 64px);
    }
  }
`;
InnerWrapper.displayName = 'InnerWrapper';

export default InnerWrapper;
