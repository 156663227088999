import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import colors from 'utils/css/colors';
import isMobile from 'utils/isMobile';

import { Plus } from 'components/Icons';

const style = { width: '32px' };
const position = isMobile
  ? { right: 0 }
  : { marginLeft: 610 };

interface Props {
  actions: {
    text: string;
    onClick?: () => void;
    to?: string;
    icon: React.ReactElement;
  }[];
}

const FloatingButton: React.FC<Props> = ({ actions }) => {
  const history = useHistory();
  return (
    <Fab
      mainButtonStyles={{
        backgroundColor: colors.red,
      }}
      position={{
        bottom: 0,
        // @ts-ignore
        position: 'fixed',
        zIndex: 25,
        ...position,
      }}
      icon={<Plus color="white" style={style} />}
      event="click"
    >
      {actions.map((action) => {
        const onClick = action.onClick || (() => { if (action.to) history.push(action.to); });
        return (
          <Action
            key={`floating-action-button-${action.text}`}
            text={action.text}
            onClick={onClick}
            style={{ backgroundColor: colors.mainDark }}
          >
            {action.icon}
          </Action>
        );
      })}
    </Fab>
  );
};

export default memo(FloatingButton);
