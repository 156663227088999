import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import shortid from 'shortid';

import colors from 'utils/css/colors';

const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  font-size: 18px;

  strong {
    font-weight: 500;
  }

  span {
    font-weight: 300;
  }
`;

const Item = styled.li`
  background-color: transparent;
  color: black;
  border-bottom: 1px solid rgba(0,0,0,0.04);
  transition: all 250ms ease-out;
  position: relative;
  ${props => (props.onClick || props.to) && 'cursor: pointer'};
  ${props => props.noChildren && 'align-items: center;'}
  overflow: hidden;

  ${Title} {
    font-size: ${props => (props.noChildren ? '16px' : '18px')};
    margin-bottom: 0;
    flex: 1;

    ${props => props.highlight && `
      strong {
        font-weight: 600;
        text-decoration: underline;
      }
    `}
  }

  &::before {
    content: '';
    background-color: ${colors.red};
    width: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 0 6px 6px 0;
    transform: translate3d(-100%, 0, 0);
    transition: all 250ms ease-out;
  }
  &:hover {
    &:before {
      transform: translate3d(0, 0, 0);
    }
  }
`;

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  margin-right: 15px;

  @media(max-width: 767px) {
    img {
      width: 40px;
      height: 40px;
    }
  }
`;

const ItemWrapper = styled.div`
  padding: 16px;
  display: flex;


  ${props => props.rowReverse && `
    flex-direction: row-reverse;

    ${AvatarWrapper} {
      margin: 0 !important;
    }
  `}
`;

const Content = styled.div`
  color: ${colors.blackRed};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ItemActive = styled(Item)`
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.08);

  &:before {
    transform: translate3d(0, 0, 0);
  }

  ${Title}, ${Content} {
    color: ${colors.red};
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;

const Lead = styled.div`
  display: flex;
  font-size: 14px;
  color: #888;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 4px;

  span:first-child {
    margin-right: 8px;
  }

  img {
    opacity: 0.75;
  }
`;

const Header = styled.div`
  display: flex;
`;

const BadgeContainer = styled.div`
  display: flex;

  svg {
    width: 16px;
    margin-right: 4px;
  }
`;

const Badge = styled.div`
  width: 16px;

  &:last-child {
    ${props => (props.pre
    ? 'margin-right: 8px;'
    : 'margin-left: 8px;'
  )}
  }

  &:not(:last-child) {
    ${props => (props.pre
    ? 'margin-right: 4px;'
    : 'margin-left: 4px;'
  )}
  }
`;

const ActionsContainer = styled.div.attrs({
  className: 'actions-container',
})`
  display: flex;
`;

const Action = styled.div`
  margin-left: 8px;
`;

const SelectableListItem = ({
  renderAvatar,
  renderPrebadges,
  renderContent,
  avatar,
  active,
  lead,
  leadIcon,
  title,
  subtitle,
  onClick,
  children,
  prebadges,
  badges,
  actions,
  to,
  computeStyle,
  linkClassName,
  rowReverse,
  ...rest
}) => {
  const ItemComponent = active ? ItemActive : Item;
  const htmlTitle = (typeof title === 'string') ? title : null;
  const renderItemContent = () => (
    <ItemWrapper rowReverse={rowReverse}>
      {renderAvatar && <AvatarWrapper>{renderAvatar()}</AvatarWrapper>}
      {avatar && <AvatarWrapper>{avatar}</AvatarWrapper>}
      <Wrapper>
        {lead && (
          <Lead>
            <span>{leadIcon}</span>
            <span>{lead}</span>
          </Lead>
        )}
        <Header>
          <BadgeContainer>
            {renderPrebadges && renderPrebadges()}
            {prebadges.map(badge => <Badge key={`badge-${shortid.generate()}`} pre>{badge}</Badge>)}
          </BadgeContainer>
          <Title>
            <strong title={htmlTitle}>{title}</strong>
            {subtitle && (
              <span>
                {' '}
                {subtitle}
              </span>
            )}
          </Title>
          <BadgeContainer>
            {badges.map(badge => <Badge key={`badge-${shortid.generate()}`}>{badge}</Badge>)}
          </BadgeContainer>
        </Header>
        <Content>
          {renderContent ? renderContent() : children}
        </Content>
      </Wrapper>
      {actions.length > 0 && (
        <ActionsContainer>
          {actions.map(action => <Action key={action.key || action}>{action}</Action>)}
        </ActionsContainer>
      )}
    </ItemWrapper>
  );

  const style = computeStyle ? computeStyle() : rest.style;
  return (
    <ItemComponent onClick={onClick} noChildren={!children} style={style} {...rest}>
      {to
        ? <Link to={to} className={linkClassName}>{renderItemContent()}</Link>
        : renderItemContent()
      }
    </ItemComponent>
  );
};

SelectableListItem.propTypes = {
  renderAvatar: PropTypes.func,
  renderPrebadges: PropTypes.func,
  renderContent: PropTypes.func,
  avatar: PropTypes.node,
  active: PropTypes.bool,
  lead: PropTypes.string,
  leadIcon: PropTypes.node,
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  prebadges: PropTypes.arrayOf(PropTypes.node),
  badges: PropTypes.arrayOf(PropTypes.node),
  actions: PropTypes.arrayOf(PropTypes.node),
  to: PropTypes.string,
  computeStyle: PropTypes.func,
  linkClassName: PropTypes.string,
  highlight: PropTypes.bool,
  rowReverse: PropTypes.bool,
};

SelectableListItem.defaultProps = {
  renderAvatar: null,
  renderPrebadges: null,
  renderContent: null,
  avatar: null,
  active: false,
  lead: null,
  leadIcon: null,
  subtitle: null,
  children: null,
  prebadges: [],
  badges: [],
  actions: [],
  onClick: null,
  to: null,
  computeStyle: null,
  linkClassName: null,
  highlight: false,
  rowReverse: false,
};

export default memo(SelectableListItem);
