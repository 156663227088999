import { forwardRef } from 'react';
import styled from 'styled-components';

import colors from 'utils/css/colors';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

interface ComponentProps extends InputProps {
  hasError: boolean;
}

const InputComponent = styled.input.attrs<ComponentProps>(props => ({
  type: props.type || 'text',
}))<ComponentProps>`
  font-size: 32px;
  font-weight: 600;
  margin: 0 0 ${props => (props.hasError ? '0' : '10px')};
  border: 0;
  background-color: transparent;

  ${props => props.withBorder && `
    border-bottom: 1px solid ${props.hasError ? colors.red : colors.redReactions};
  `}

  border-radius: 8px;
  transition: all 250ms ease-out;

  &:focus {
    outline: none;
  }

  @media(max-width: 767px) {
    font-size: 25px;
  }
`;

const Error = styled.div`
  color: ${colors.red};
  text-align: left;
  font-size: 12px;
  margin-bottom: 10px;
`;

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  value: string;
  maxChars?: number;
  error?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  withBorder?: boolean;
}

const Input = forwardRef(({
  value,
  maxChars,
  error,
  onChange,
  placeholder = '',
  ...rest
}: InputProps, ref: React.Ref<HTMLInputElement>) => {
  const hasError = !!(error || (maxChars && value.length > maxChars)) && value !== '';

  return (
    <InputWrapper>
      <InputComponent
        ref={ref}
        value={value}
        onChange={onChange}
        hasError={hasError}
        placeholder={placeholder}
        {...rest}
      />
      {error !== '' && <Error>{error}</Error>}
    </InputWrapper>
  );
});

Input.displayName = 'Input';

export default Input;
