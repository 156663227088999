import produce from 'immer';

import {
  LOAD,
  WIPE,
} from './constants';

export const initialState = {
  data: {},
  invoices: [],
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOAD:
      Object.keys(action.data || {}).forEach((id) => {
        const invoice = action.data[id];

        draft.data[id] = {
          ...state.data[id],
          ...invoice,
        };

        if (!state.invoices.includes(id)) {
          draft.invoices.push(id);
        }
      });

      break;

    case WIPE:
      draft.data = initialState.data;
      break;

    default:
  }
});

export default reducer;
