import { useState } from 'react';
import styled from 'styled-components';

import { useTranslation, useSearchUsers } from 'hooks';

import Modal from 'components/Modal';
import { UserSearchInput, UserSearchResults } from 'components/UserSearch';
import { SelectableList, SelectableListItem } from 'components/SelectableList';
import UserAvatar from 'components/UserAvatar';
import Button from 'components/Button';
import { Close } from 'components/Icons';

import locales from '../../i18n';
import ConfirmTransferOwnership from './ConfirmTransferOwnership';

const SearchContainer = styled.div`
  position: relative;
  width: 100%;

  .search-container {
    background-color: white;
    box-shadow: 0px 0px 5px #9999;

    input {
      color: black;

      &::placeholder {
        color: #666;
      }
    }
  }
`;

const ResultsContainer = styled.div`
  position: relative;
  width: 100%;
`;

interface Props {
  channelId: string;
  close: () => void;
}

interface User {
  id: number;
  displayname: string;
  pronoun: string;
}

const TransferOwnershipModal: React.FC<Props> = ({ channelId, close }) => {
  const { t } = useTranslation(locales);

  const [searchProps, results, reset] = useSearchUsers();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const clearSelectedUser = () => {
    // @ts-ignore
    reset();
    setSelectedUser(undefined);
  };

  const modalActions = [];
  if (selectedUser) modalActions.push(<Button key="add-ban-confirm" onClick={() => { setShowConfirmModal(true); }}>{t('global:Confirm')}</Button>);

  if (showConfirmModal) {
    if (!selectedUser) return null;
    return (
      <ConfirmTransferOwnership entityType="channel" entityId={channelId} user={selectedUser} onConfirm={close} close={closeConfirmModal} />
    );
  }

  return (
    <Modal
      title={t('Transfer ownership')}
      onCancel={close}
      actions={modalActions}
    >
      {!selectedUser
        ? (
          <>
            <SearchContainer>
              {/* @ts-ignore */}
              <UserSearchInput {...searchProps} onBlurTriggered={reset} />
            </SearchContainer>
            <ResultsContainer>
              {/* @ts-ignore */}
              {results.length > 0 && <UserSearchResults results={results} onSelect={setSelectedUser} />}
            </ResultsContainer>
          </>
        ) : (
          <SelectableList>
            {/* @ts-ignore */}
            <SelectableListItem
                  // @ts-ignore
              avatar={(<UserAvatar userId={selectedUser.id} />)}
              title={selectedUser.displayname}
                  // @ts-ignore
              actions={[<Button key="remove-ban-confirm" onClick={clearSelectedUser} color="white" light><Close /></Button>]}
            />
          </SelectableList>
        )
      }
    </Modal>
  );
};

// All the ts-ignores will dissappear with the types MRs😅

export default TransferOwnershipModal;
