import styled from 'styled-components';

const Author = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  display: inline-flex;
  padding: 0 16px;

  .avatar {
    margin-right: 8px;
  }

  .displayname {
    font-weight: 500;
  }
`;
Author.displayName = 'Author';

export default Author;
