import {
  Fragment, useState, useRef, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import fastdom from 'fastdom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';
import * as feedActions from 'state/feed/actions';

import Publication from 'components/Publication';
import Spinner from 'components/Spinner';
import SpinnerWrapper from 'components/Spinner/Wrapper';
import EmptyState from 'components/EmptyState';
import Ad from 'components/Ad';

import Collections from './Collections';
import locales from '../i18n';

const Publications = ({ onlyMedia }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  const params = useParams();
  const userId = useSelector(userSelectors.getByUsername(params.username));

  const [publicationIds, setPublicationIds] = useState([]);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const isLoading = useRef(false);
  const publicationsCount = useRef(0);
  const latestHashtag = useRef(params.hashtag);

  const load = useCallback(async () => {
    if (!isLoading.current && !fullyLoaded) {
      isLoading.current = true;
      const offset = latestHashtag.current !== params.hashtag ? 0 : publicationsCount.current;
      latestHashtag.current = params.hashtag;

      const ids = await dispatch(feedActions.loadByAuthor(
        userId, offset, onlyMedia, params.hashtag,
      ));
      publicationsCount.current = offset + ids.length;

      if (!ids.length) {
        setFullyLoaded(true);
      } else {
        setPublicationIds(prevPublications => [
          ...prevPublications,
          ...ids,
        ]);
      }

      isLoading.current = false;
    }
  }, [userId, dispatch, onlyMedia, params.hashtag, fullyLoaded]);

  useEffect(() => {
    const el = document.documentElement;

    const loadMoreScrollChanged = () => {
      fastdom.measure(() => {
        if (el.scrollHeight - el.scrollTop < 2500) {
          load();
        }
      });
    };

    document.addEventListener('scroll', loadMoreScrollChanged);
    load();

    return () => {
      document.removeEventListener('scroll', loadMoreScrollChanged);
    };
  }, [load]);

  useEffect(() => {
    setFullyLoaded(false);
    setPublicationIds([]);
  }, [params.hashtag]);

  const onRemovePublication = useCallback((removedPublicationId) => {
    const newPublicationIds = publicationIds.filter((publicationId) => publicationId !== removedPublicationId);
    setPublicationIds(newPublicationIds);
  }, [publicationIds, setPublicationIds]);

  return (
    <div>
      <Collections />

      <div>
        {publicationIds.map((publicationId, index) => (
          <Fragment key={`pub-${publicationId}`}>
            <Publication publicationId={publicationId} onRemove={onRemovePublication} />
            {index > 0 && !(index % 8) && <Ad id="In Feed" />}
          </Fragment>
        ))}
      </div>

      {!fullyLoaded && (
        <SpinnerWrapper>
          <Spinner color="#999" />
        </SpinnerWrapper>
      )}

      {fullyLoaded && publicationIds.length === 0 && (
        <EmptyState subtitle={t('No publications to show')} />
      )}
    </div>
  );
};

Publications.propTypes = {
  onlyMedia: PropTypes.bool,
};

Publications.defaultProps = {
  onlyMedia: false,
};

export default Publications;
