import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';
import Button from 'components/Button';

import locales from './i18n';
import ModalOpacity from './ModalOpacity';
import Box from './UI/Box';
import Title from './UI/Title';
import Content from './UI/Content';
import Wrapper from './UI/Wrapper';
import ActionsWrapper from './UI/ActionsWrapper';

const Modal = ({
  show,
  title,
  actions,
  onCancel,
  onClose,
  onOpenFocus,
  children,
  boxStyle,
  showActions,
  onlyActions,
  fullHeight,
  backgroundBlur,
  zIndex,
}) => {
  const { t } = useTranslation(locales);

  useEffect(() => {
    if (!!onOpenFocus && onOpenFocus.current) onOpenFocus.current.focus();
  }, [onOpenFocus]);

  return createPortal((
    <>
      <ModalOpacity
        show={show}
        onClick={onClose || onCancel}
        backgroundBlur={backgroundBlur}
        zIndex={zIndex}
      />
      <Box style={boxStyle} fullHeight={fullHeight} zIndex={zIndex + 100}>
        <Content fullHeight={fullHeight}>
          <Title>{title}</Title>
          <Wrapper fullHeight={fullHeight}>{children}</Wrapper>
          {showActions && (
            <ActionsWrapper>
              {onCancel && !onlyActions && <Button onClick={onCancel} className="empty">{t('global:Cancel')}</Button>}
              {onClose && !onlyActions && <Button onClick={onClose} className="empty">{t('Close')}</Button>}
              {actions}
            </ActionsWrapper>
          )}
        </Content>
      </Box>
    </>
  ), document.body);
};

Modal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.node),
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onOpenFocus: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  boxStyle: PropTypes.object,
  showActions: PropTypes.bool,
  onlyActions: PropTypes.bool,
  fullHeight: PropTypes.bool,
  backgroundBlur: PropTypes.bool,
  zIndex: PropTypes.number,
};

Modal.defaultProps = {
  show: true,
  title: '',
  actions: [],
  onCancel: null,
  onClose: null,
  onOpenFocus: null,
  children: null,
  boxStyle: {},
  showActions: true,
  onlyActions: false,
  fullHeight: false,
  backgroundBlur: false,
  zIndex: 1100,
};

export default Modal;
