import { alertAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

export default {
  defaultProps: {
    variant: 'left-accent',
  },
  variants: {
    default: definePartsStyle(() => {
      return {
        container: {
          bgColor: 'gray.200',
          borderLeft: '4px solid',
          borderColor: 'gray.700',
        },
        title: {
          color: 'black',
        },
        icon: {
          color: 'black',
        },
      };
    }),
  },
};
