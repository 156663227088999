/* eslint-disable no-param-reassign */
/* eslint-disable no-cond-assign */
import { emojiIndex } from 'emoji-mart';

const { CodeMirror } = window;

require('codemirror/addon/mode/overlay');
require('./markdown');

const emojiRE = /^(:([a-zA-Z0-9+-][\w-]*):)(:skin-tone-(\d):)?/;
const hashtagRE = /(^|\s)#([a-zA-Z0-9\u00C0-\u00ff][\u00C0-\u00ff\w-]*){2,}/;
const mentionRE = /(^|\s)(@([a-zA-Z0-9_]){2,})/;
const communityRE = /(^|\s)(\+([a-zA-Z0-9_]){2,})/;
const channelRE = /(^|\s)(%([a-zA-Z0-9_]){2,})/;
const commentRE = /<!--(.*?)-->/;

const isValidEmoji = (colons, custom) => {
  const name = colons.substr(1, colons.length - 2);
  return emojiIndex.search(name, { custom }).some(e => e.short_names.includes(name));
};

CodeMirror.defineMode('mfm', (params, parserConfig) => {
  const config = {
    customEmojis: [],
    ...params,
  };

  const mfmOverlay = {
    token: (stream) => {
      // :emoji-short-name:
      if (config.emojis && stream.match(emojiRE)) {
        const shortName = stream.string.substr(stream.start, (stream.pos - stream.start));
        const colons = shortName.trim().replace(/(:skin-tone-(\d):)/i, '');
        const isValid = isValidEmoji(colons, config.customEmojis);

        if (isValid) {
          return 'emoji';
        }
      }

      // partial emoji name (for suggestions)
      if (
        config.emojis
        && stream.match(/^(:([a-zA-Z0-9+-][\w-]*))/)
      ) {
        return 'partial-emoji';
      }

      // hashtags
      if (
        (stream.sol() || stream.string.substr(stream.start - 1, 1) === ' ')
        && stream.match(hashtagRE)
      ) {
        return 'hashtag';
      }

      // mentions
      if (
        config.mentions
        && (stream.sol() || stream.string.substr(stream.start - 1, 1) === ' ')
        && stream.match(mentionRE)
      ) {
        return 'mention';
      }

      // communities
      if (
        config.communities
        && (stream.sol() || stream.string.substr(stream.start - 1, 1) === ' ')
        && stream.match(communityRE)
      ) {
        return 'community';
      }

      // channels
      if (
        config.channels
        && (stream.sol() || stream.string.substr(stream.start - 1, 1) === ' ')
        && stream.match(channelRE)
      ) {
        return 'channel';
      }

      // comments
      if (stream.match(commentRE)) {
        return 'comment';
      }

      stream.next();
      return null;
    },
  };

  const markdownConfig = {
    taskLists: false,
    strikethrough: true,
    emoji: true,
    highlightFormatting: true,
    xml: false,
    allowAtxHeaderWithoutSpace: false,
    fencedCodeBlocks: false,
  };
  Object.keys(parserConfig).forEach((attr) => {
    markdownConfig[attr] = parserConfig[attr];
  });
  markdownConfig.name = config.markdown ? 'markdown' : 'text';

  return CodeMirror.overlayMode(CodeMirror.getMode(config, markdownConfig), mfmOverlay);
});
CodeMirror.defineMIME('text/x-mfm', 'mfm');
