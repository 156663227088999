import {
  memo, useRef, useEffect, useState, useCallback,
} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import isMobile from 'utils/isMobile';
import * as threadSelectors from 'state/threads/selectors';
import * as eventSelectors from 'state/events/selectors';
import * as threadActions from 'state/threads/actions';

import {
  FlexWrapper,
  FlexInnerWrapper,
  FlexContainer,
} from 'components/FlexWrapper';
import Overflow from 'components/Layout/Overflow';
import Ad from 'components/Ad';

import Header from './Header';
import Event from './Event';
import ThreadAuthorInfo from './ThreadAuthorInfo';
import Body from './Body';
import Sponsors from './Event/Sponsors';
import RSVPs from './Event/RSVPs';
import Disclaimer from './Event/Disclaimer';
import Actions from './Actions';
import ReplyList from './ReplyList';
import ReplyComposer from './ReplyComposer';
import ActionButton from './Event/AssistButton/Button';

const ThreadContentWrapper = styled.div`
  a.userinactive > div {
    opacity: .5;
  }

  @media(min-width: 767px) {
    padding: 16px 24px 0;
  }

  .event-buy-button {
    margin-top: 16px;
  }
`;
ThreadContentWrapper.displayName = 'ThreadContentWrapper';

const Content = memo(() => {
  const dispatch = useDispatch();
  const params = useParams();

  const threadId = useSelector(threadSelectors.getIdBySlug(params.threadSlug));
  const eventId = useSelector(threadSelectors.getEventId(threadId));
  const eventHighlight = useSelector(state => eventSelectors.isHighlighted(state, eventId));
  const replyCount = useSelector(threadSelectors.getCommentCount(threadId));

  const container = useRef(null);
  const [eventHeaderVisible, setEventHeaderVisible] = useState(true);

  useEffect(() => {
    dispatch(threadActions.markThreadAsRead(threadId));
  }, [dispatch, threadId]);

  const onScroll = useCallback((e) => {
    const el = e.target;
    const BOX_HEIGHT = 300;

    if (!isMobile) {
      if (el.scrollTop > BOX_HEIGHT && eventHeaderVisible) {
        setEventHeaderVisible(false);
      } else if (el.scrollTop <= BOX_HEIGHT && !eventHeaderVisible) {
        setEventHeaderVisible(true);
      }
    }
  }, [setEventHeaderVisible, eventHeaderVisible]);

  return (
    <FlexWrapper canOverflow>
      <FlexInnerWrapper fullHeight>
        <Header eventHeaderVisible={eventHeaderVisible} />

        <Overflow ref={container} onScroll={onScroll}>
          <FlexContainer padding="0">
            <ThreadContentWrapper>
              {eventId ? (
                <Event threadId={threadId} />
              ) : (
                <ThreadAuthorInfo threadId={threadId} />
              )}
              <Body threadId={threadId} />

              {eventId && (
                <>
                  <Sponsors eventId={eventId} />
                  <RSVPs eventId={eventId} />
                  <ActionButton eventId={eventId} onlyBuy size="big" />
                  <Disclaimer />
                </>
              )}
              <Actions entityId={threadId} type="thread" />
            </ThreadContentWrapper>

            {!eventHighlight && replyCount > 1 && <Ad id="In Content" key={`threadbody-ad-${threadId}`} divider="top" />}

            <ReplyList threadId={threadId} />
            <ReplyComposer threadId={threadId} />

            {!eventHighlight && <Ad id="Page Bottom" key={`replycomposer-ad-${threadId}`} />}
          </FlexContainer>
        </Overflow>
      </FlexInnerWrapper>
    </FlexWrapper>
  );
});

Content.propTypes = {
};

Content.defaultProps = {
};

export default Content;
