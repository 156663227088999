import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as authActions from 'state/auth/actions';
import * as appActions from 'state/app/actions';

import { useTranslation } from 'hooks';

import Button from 'components/Button';
import AuthLayout from 'components/AuthLayout';

import locales from './i18n';

const Reactivate = ({ username, password }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(locales);

  const [reactivating, setReactivating] = useState(false);

  const reactivate = async () => {
    try {
      setReactivating(true);
      await dispatch(authActions.reactivate(username, password));
      dispatch(appActions.addToast(t('Account successfuly reactivated')));
    } catch (error) {
      dispatch(appActions.addError(error));
      setReactivating(false);
    }
  };

  return (
    <AuthLayout
      title={t('You chose to suspend your account')}
      subtitle={t('If you want to resume your participation in the site, you need to re-activate your account. Old and new contacts are waiting for you!')}
    >
      <Button onClick={reactivate} loading={reactivating}>{t('Reactivate my account')}</Button>
      <br />
      <br />
      <Link to="/"><Button color="white" fontColor="#666" light>{t('global:Cancel')}</Button></Link>
    </AuthLayout>
  );
};

Reactivate.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

export default Reactivate;
