import {
  Fragment, useCallback, useState, useRef, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import moment from 'moment';
import fastdom from 'fastdom';
import { Link } from 'react-router-dom';

import Api from 'state/api';
import { useTranslation } from 'hooks';

import Spinner from 'components/Spinner';
import SpinnerWrapper from 'components/Spinner/Wrapper';
import { Reply } from 'components/Icons';
import EmptyState from 'components/EmptyState';
import Ad from 'components/Ad';
import ParsedContent from 'components/ParsedContent';

import ThreadWrapper from './UI/ThreadWrapper';
import Title from './UI/Title';
import Subtitle from './UI/Subtitle';
import BodyWrapper from './UI/BodyWrapper';
import BodyShadow from './UI/BodyShadow';
import locales from '../i18n';

const Replies = ({ userId }) => {
  const { t } = useTranslation(locales);
  const theme = useContext(ThemeContext);

  const [replies, setReplies] = useState([]);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const isLoading = useRef(false);
  const lastReplyDate = useRef(null);

  const load = useCallback(async () => {
    if (!isLoading.current && !fullyLoaded) {
      isLoading.current = true;
      const params = { authorId: userId };
      if (lastReplyDate.current) {
        params.before = lastReplyDate.current;
      }

      const { data } = await Api.req.get('/communities/replies', { params });

      if (!data.length) {
        setFullyLoaded(true);
      } else {
        setReplies(prevReplies => [
          ...prevReplies,
          ...data,
        ]);
        lastReplyDate.current = data[data.length - 1].createdAt;
      }

      isLoading.current = false;
    }
  }, [userId, fullyLoaded]);

  useEffect(() => {
    const el = document.documentElement;

    const loadMoreScrollChanged = () => {
      fastdom.measure(() => {
        if (el.scrollHeight - el.scrollTop < 2500) {
          load();
        }
      });
    };

    document.addEventListener('scroll', loadMoreScrollChanged);
    load();

    return () => {
      document.removeEventListener('scroll', loadMoreScrollChanged);
    };
  }, [load]);

  return (
    <div>
      <div>
        {replies.map((reply, index) => (
          <Fragment key={`reply-${reply.id}`}>
            <Link to={`/+${reply.community.slug}/${reply.thread.slug}`}>
              <ThreadWrapper>
                <div className="header">
                  <Title>
                    <Reply color={theme.colors.secondary} />
                    {reply.thread.title}
                  </Title>
                  <Subtitle>{`${t('In')} ${reply.community.name} • ${t('At')} ${moment(reply.createdAt).format(t('date.format'))}`}</Subtitle>
                </div>
                <BodyWrapper>
                  <ParsedContent content={reply.rawContent} />
                  <BodyShadow />
                </BodyWrapper>
              </ThreadWrapper>
            </Link>

            {index > 0 && !(index % 8) && <Ad id="In Content" />}
          </Fragment>
        ))}
      </div>

      {!fullyLoaded && (
        <SpinnerWrapper>
          <Spinner color="#999" />
        </SpinnerWrapper>
      )}

      {fullyLoaded && replies.length === 0 && (
        <EmptyState subtitle={t('No replies to show')} />
      )}
    </div>
  );
};

Replies.propTypes = {
  userId: PropTypes.number.isRequired,
};

Replies.defaultProps = {
};

export default Replies;
