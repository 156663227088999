import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';
import * as messengerSelectors from 'state/messengers/selectors';

import locales from './i18n';

const EditedWrapper = styled.div`
  font-size: 12px;
  margin-left: 4px;
  color: #999;
`;
EditedWrapper.displayName = 'EditedWrapper';

const Edited = ({ type, messageId }) => {
  const { t } = useTranslation(locales);

  const entitySelectors = type === 'channel' ? channelSelectors : messengerSelectors;
  const isEdited = useSelector(state => entitySelectors.messageIsEdited(state, messageId));

  if (!isEdited) return null;

  return (
    <EditedWrapper>{t('edited')}</EditedWrapper>
  );
};

Edited.propTypes = {
  type: PropTypes.oneOf(['channel', 'messenger']).isRequired,
  messageId: PropTypes.string.isRequired,
};

Edited.defaultProps = {
};

export default memo(Edited);
