import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const SadesAssignment = ({ timestamp, read }) => {
  const { t } = useTranslation(locales);

  return (
    <AlertContainer timestamp={timestamp} to="/bank" read={read}>
      {t('You have received the SADEs weekly assignment')}
    </AlertContainer>
  );
};

SadesAssignment.propTypes = {
  read: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default SadesAssignment;
