import PropTypes from 'prop-types';
import { Link as InternalLink } from 'react-router-dom';

const Link = ({ href, children }) => {
  let link = href;
  if (href.substr(0, 17) === 'https://mazmo.net') link = href.substr(17);
  if (href.substr(0, 16) === 'http://mazmo.net') link = href.substr(16);
  if (href.substr(0, 9) === 'mazmo.net') link = href.substr(9);
  if (link === '') link = '/';

  if (link !== href) {
    return <InternalLink to={link}>{children}</InternalLink>;
  }

  return <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>;
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Link.defaultProps = {
};

export default Link;
