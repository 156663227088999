import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const normalPath = 'M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z';
const outlinePath = 'M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z';

const Delete = memo(({ color, outline }) => (
  <svg viewBox="0 0 24 24">
    <path
      d={outline ? outlinePath : normalPath}
      fill={color}
    />
  </svg>
));

Delete.propTypes = IconType;
Delete.defaultProps = IconDefaults;

export default Delete;
