export const LOAD = 'mazmo/memberships/LOAD';
export const LOAD_SUCCESS = 'mazmo/memberships/LOAD_SUCCESS';
export const LOAD_FAIL = 'mazmo/memberships/LOAD_FAIL';

export const CREATE = 'mazmo/memberships/CREATE';
export const CREATE_SUCCESS = 'mazmo/memberships/CREATE_SUCCESS';
export const CREATE_FAIL = 'mazmo/memberships/CREATE_FAIL';

export const DELETE = 'mazmo/memberships/DELETE';
export const DELETE_SUCCESS = 'mazmo/memberships/DELETE_SUCCESS';
export const DELETE_FAIL = 'mazmo/memberships/DELETE_FAIL';

export const WIPE = 'mazmo/memberships/WIPE';
