import { memo } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';

import Unread from '../Unread';

const UnreadIndicator = ({ id }) => {
  const unreadMentions = useSelector(state => channelSelectors.getUnreadMentions(state, id));
  const hasUnread = useSelector(state => channelSelectors.hasUnread(state, id));
  const match = useRouteMatch(`/chat/channels/${id}`);
  const isActive = !!match;

  if (unreadMentions > 0) return <Unread>{unreadMentions}</Unread>;
  if (!isActive && hasUnread && !unreadMentions) return <Unread />;

  return null;
};

UnreadIndicator.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(UnreadIndicator);
