import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import * as appSelectors from 'state/app/selectors';

import Header from 'components/Header';
import UserAvatar from 'components/UserAvatar';
import EmptyState from 'components/EmptyState';
import Button from 'components/Button';

import RestrictedActions from '../Actions/Restricted';
import locales from '../../i18n';

interface Props {
  userId: number;
  username: string;
}

const Suspended: React.FC<Props> = ({ userId, username }) => {
  const history = useHistory();
  const { t } = useTranslation(locales);
  const historyCanGoBack = useSelector(appSelectors.selectHistoryCanGoBack);

  const renderAvatar = useCallback(() => (
    <UserAvatar userId={userId} onlineBorderColor="black" size="40px" showOnline={false} />
  ), [userId]);

  const renderActions = useCallback(() => (
    <RestrictedActions userId={userId} />
  ), [userId]);

  const onBackClick = useCallback(() => {
    if (historyCanGoBack) {
      history.goBack();
    } else {
      history.push('/');
    }
  }, [history, historyCanGoBack]);

  return (
    <Box position="relative" display="flex">
      <Header
        title={username}
        backgroundColor="white"
        foregroundColor="black"
        renderAvatar={renderAvatar}
        renderActions={renderActions}
        negativeBadge
        desktopAbsolute
        border="0"
        onBackClick={onBackClick}
      />

      <EmptyState
        uiLeftColumn
        title={t('This account has been suspended')}
        subtitle={t('The owner of this account has decided to suspend it')}
      >
        <Button to="/">{t('Go Home')}</Button>
      </EmptyState>
    </Box>
  );
};

export default Suspended;
