import { useCallback } from 'react';
import PropTypes from 'prop-types';

import ComposerRef from 'utils/ComposerRef';

import EmojiPicker from 'components/EmojiPicker';

const Picker = ({ id }: {id: string}) => {
  const cm = ComposerRef.getRef(id);

  const onSelect = useCallback((emoji: { colons: string }) => {
    cm.replaceSelection(emoji.colons);
    cm.refresh();
  }, [cm]);

  return (
    <EmojiPicker onSelect={onSelect} />
  );
};

Picker.propTypes = {
  id: PropTypes.string.isRequired,
};

Picker.defaultProps = {
};

export default Picker;
