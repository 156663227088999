import produce from 'immer';

import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAIL,
  REMOVE_CHANNEL,
  ADD_MESSAGE,
  UPDATE_MESSAGE,
  UPDATE,
  SET_ACTIVE,
  UNMOUNT,
  SCROLL_AT_BOTTOM_CHANGE,
  LOAD_MESSAGES,
  LOAD_MESSAGES_SUCCESS,
  LOAD_MESSAGES_FAIL,
  MESSAGE_REMOVING,
  MESSAGE_REMOVE,
  FULLY_LOADED,
  TYPING_START,
  TYPING_STOP,
  INC_UNREAD_MENTIONS,
  MARK_ALL_AS_READ,
  JOIN,
  PART,
  ADD_BOT,
  ADD_REPLYTO,
  CLEAR_REPLYTO,
  ADD_EDITCOMPOSER,
  REMOVE_EDITCOMPOSER,
  EMBED_UPDATED,
  WIPE,
} from './constants';
import { CHANNEL_ROLES } from '../../constants';

const MESSAGE_LIMIT = 30;

const capMessages = (draft, state, id, limit = MESSAGE_LIMIT) => {
  const messages = draft.channelmessages[id];

  if (messages) {
    const deletedIds = messages.splice(0, messages.length - limit);
    deletedIds.forEach(i => delete draft.data.channelmessages[i]);

    if (deletedIds.length > 0) {
      const flIndex = state.fullyLoaded.findIndex(i => i === id);
      if (flIndex > -1) {
        draft.fullyLoaded.splice(flIndex, 1);
      }
    }
  }
};

export const initialState = {
  data: {
    channels: {},
    channelmessages: {},
    bots: {},
  },
  channels: [],
  channelmessages: {},
  unreadMentions: {},
  loadingCMs: [],
  fullyLoaded: [],
  typing: {},
  replyTo: {},
  editing: {},
  reads: {},
  lastMessages: {},
  scrollAtBottom: [],
  active: null,
  fetching: false,
  fetchError: null,
  initialized: false,
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case FETCH:
      draft.fetching = true;
      draft.fetchError = null;
      break;

    case FETCH_SUCCESS:
      draft.data.channels = {
        ...state.data.channels,
        ...action.data,
      };

      draft.channels = [
        ...state.channels,
        ...action.result.filter(mId => !state.channels.includes(mId)),
      ];

      Object.values(action.data).forEach((channel) => {
        if (action.userId) {
          const participantIndex = channel.participants.findIndex(p => p.userId === action.userId);
          if (participantIndex > -1) {
            draft.reads[channel.id] = channel.participants[participantIndex].readAt;
          }
        } else {
          draft.reads[channel.id] = (new Date()).toISOString();
        }
      });

      draft.fetching = false;
      draft.fetchError = null;
      draft.initialized = true;

      break;

    case FETCH_FAIL:
      draft.fetching = false;
      draft.fetchError = action.error;
      break;

    case REMOVE_CHANNEL:
      draft.channels = state.channels.filter(cId => cId !== action.channelId);
      delete draft.data.channels[action.channelId];
      draft.channelmessages[action.channelId].forEach((cmId) => {
        delete draft.data.channelmessages[cmId];
      });

      delete draft.channelmessages[action.channelId];
      break;

    case ADD_MESSAGE: {
      if (action.payload.id && action.referenceId) {
        // Message has been persisted
        delete draft.data.channelmessages[action.referenceId];
        if (draft.channelmessages[action.channelId]) {
          const index = draft.channelmessages[action.channelId]
            .findIndex(id => id === action.referenceId);
          if (index > -1) {
            draft.channelmessages[action.channelId].splice(index, 1);
          }
        }
      }

      if ((
        !state.data.channels[action.channelId]
        || !state.channels.includes(action.channelId)
      ) && typeof action.payload.channel === 'object') {
        // New channel - Add it
        draft.data.channels[action.channelId] = action.payload.channel;
        draft.channels.push(action.channelId);
      }

      const id = action.payload.id || action.referenceId;
      draft.data.channelmessages[id] = action.payload;

      if (action.referenceId && !action.payload.id) {
        draft.data.channelmessages[id].referenceId = action.referenceId;
      }
      if (!draft.channelmessages[action.channelId]) {
        draft.channelmessages[action.channelId] = [];
      }

      if (!draft.channelmessages[action.channelId].includes(id)) {
        draft.channelmessages[action.channelId].push(id);
        draft.lastMessages[action.channelId] = draft.data.channelmessages[id];
      }

      // Capping stored messages for memory management
      if (action.channelId !== state.active) capMessages(draft, state, action.channelId);
      else if (state.scrollAtBottom.includes(action.channelId)) {
        capMessages(draft, state, action.channelId, MESSAGE_LIMIT * 2);
      }

      break;
    }

    case UPDATE_MESSAGE:
      draft.data.channelmessages[action.payload.id] = action.payload;
      break;

    case UPDATE:
      draft.data.channels[action.data.id] = {
        ...draft.data.channels[action.data.id],
        ...action.data,
      };
      break;

    case SET_ACTIVE:
      capMessages(draft, state, state.active);
      draft.active = action.active;
      draft.unreadMentions[action.active] = 0;
      break;

    case UNMOUNT: {
      draft.active = null;
      capMessages(draft, state, action.id);

      draft.reads[action.id] = (new Date()).toISOString();

      break;
    }

    case SCROLL_AT_BOTTOM_CHANGE:
      draft.scrollAtBottom = state.scrollAtBottom.filter(id => id !== action.id);
      if (action.isAtBottom) draft.scrollAtBottom.push(action.id);
      break;

    case LOAD_MESSAGES:
      if (!state.loadingCMs.includes(action.channelId)) draft.loadingCMs.push(action.channelId);
      break;

    case LOAD_MESSAGES_SUCCESS: {
      const index = state.loadingCMs.indexOf(action.channelId);
      if (index > -1) draft.loadingCMs.splice(index, 1);

      draft.data.channelmessages = { ...state.data.channelmessages, ...action.channelmessages };
      const result = action.result.filter(id => (
        !state.channelmessages[action.channelId]
        || !state.channelmessages[action.channelId].includes(id)
      ));

      draft.channelmessages[action.channelId] = [
        ...result,
        ...(draft.channelmessages[action.channelId] || []),
      ];

      break;
    }

    case LOAD_MESSAGES_FAIL: {
      const index = state.loadingCMs.indexOf(action.channelId);
      if (index > -1) draft.loadingCMs.splice(index, 1);
      break;
    }

    case MESSAGE_REMOVING:
      draft.data.channelmessages[action.messageId] = {
        ...draft.data.channelmessages[action.messageId],
        removing: true,
      };
      break;

    case MESSAGE_REMOVE: {
      const message = state.data.channelmessages[action.messageId];
      const channelId = typeof message.channel === 'object' ? message.channel.id : message.channel;
      delete draft.data.channelmessages[action.messageId];

      const filteredIds = state.channelmessages[channelId].filter(mId => mId !== action.messageId);
      draft.channelmessages[channelId] = filteredIds;

      break;
    }

    case FULLY_LOADED:
      draft.fullyLoaded.push(action.channelId);
      break;

    case TYPING_START:
      if (!draft.typing[action.channelId]) draft.typing[action.channelId] = [];
      if (!draft.typing[action.channelId].includes(action.userId)) {
        draft.typing[action.channelId].push([action.userId]);
      }

      break;

    case TYPING_STOP:
      if (draft.typing[action.channelId]) {
        const index = draft.typing[action.channelId].indexOf(action.userId);
        draft.typing[action.channelId].splice(index, 1);
      }

      break;

    case ADD_REPLYTO:
      draft.replyTo[action.channelId] = action.messageId;
      break;

    case CLEAR_REPLYTO:
      delete draft.replyTo[action.channelId];
      break;

    case ADD_EDITCOMPOSER:
      draft.editing[action.channelId] = action.messageId;
      break;

    case REMOVE_EDITCOMPOSER:
      delete draft.editing[action.channelId];
      break;

    case INC_UNREAD_MENTIONS: {
      const currentState = state.unreadMentions[action.channelId] || 0;
      draft.unreadMentions[action.channelId] = currentState + 1;
      break;
    }

    case MARK_ALL_AS_READ: {
      const now = (new Date()).toISOString();
      Object.keys(state.reads).forEach((channelId) => {
        draft.reads[channelId] = now;
      });

      break;
    }

    case JOIN: {
      const { participants } = draft.data.channels[action.channelId];
      if (participants) {
        const index = participants.findIndex(p => p.userId === action.userId);

        if (index < 0) {
          draft.data.channels[action.channelId].participants.push({
            userId: action.userId,
            user: action.userId,
            role: CHANNEL_ROLES.USER,
            joinedAt: (new Date()).toISOString(),
            readAt: (new Date()).toISOString(),
          });
        }
      }

      draft.reads[action.channelId] = (new Date()).toISOString();

      break;
    }

    case PART: {
      const { participants } = state.data.channels[action.channelId];
      if (participants) {
        draft.data.channels[action.channelId].participants = participants
          .filter(p => p.userId !== action.userId);
      }
      break;
    }

    case ADD_BOT:
      draft.data.bots[action.data.id] = action.data;
      break;

    case EMBED_UPDATED:
      draft.data.channels[action.channelId].embed = action.data;
      break;

    case WIPE:
      draft.data = initialState.data;
      draft.channels = initialState.channels;
      draft.fetching = initialState.fetching;
      draft.fetchError = initialState.fetchError;
      break;

    default:
  }
});

export default reducer;
