import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslation } from 'hooks';

import { ChatRequest } from 'components/Icons';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const ChatIcon = styled.div.attrs({
  children: <ChatRequest />,
})`
  background-color: ${props => props.theme.colors.main};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${props => props.theme.colors.mainLight};
    }
  }
`;
ChatIcon.displayName = 'ChatIcon';

const ChatRequestsRefilled = ({ read, timestamp }) => {
  const { t } = useTranslation(locales);

  return (
    <AlertContainer timestamp={timestamp} image={<ChatIcon />} read={read}>
      {t('Your free chat requests has been refilled')}
    </AlertContainer>
  );
};

ChatRequestsRefilled.propTypes = {
  read: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default ChatRequestsRefilled;
