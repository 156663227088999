import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as communitySelectors from 'state/communities/selectors';
import CommunityAvatar from 'components/CommunityAvatar';

const ImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .avatar {
    z-index: 10;
    margin: calc(300px * 2 / 5 / 2) auto 0;

    @media(max-width: 768px) {
      margin: calc(100vw * 2 / 5 / 2) auto 0;
    }
  }
`;

const CoverImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(300px * 2 / 5);
  z-index: 1;

  @media(max-width: 768px) {
    height: calc(100vw * 2 / 5);
  }

  ${props => (props.cover ? `
    background-image: url(${props.cover});
    background-size: cover;
  ` : `
    background-color: #DDD;
  `)}
`;

const ImagesHeader = ({ communityId }) => {
  const cover = useSelector(communitySelectors.getCover(communityId));

  return (
    <ImageWrapper>
      <CoverImage cover={cover} />
      <CommunityAvatar communityId={communityId} size="120px" className="avatar" />
    </ImageWrapper>
  );
};

ImagesHeader.propTypes = {
  communityId: PropTypes.string.isRequired,
};

ImagesHeader.defaultProps = {
};

export default ImagesHeader;
