import produce from 'immer';

import {
  LOAD,
  EVENT_RSVP,
  EVENT_UNRSVP,
  ASSISTED,
  UNASSISTED,
  ADD_BAN,
  REMOVE_BAN,
  LOAD_INVITES,
  ADD_INVITE,
  REMOVE_INVITE,
  ENROLLED,
  WIPE,
} from './constants';

export const initialState = {
  data: {},
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOAD:
      Object.keys(action.data).forEach((id) => {
        const event = action.data[id];

        draft.data[id] = {
          ...state.data[id],
          ...event,
        };
      });

      break;

    case EVENT_RSVP:
      if (state.data[action.eventId]) {
        if (state.data[action.eventId].rsvps.every(r => r.userId !== action.userId)) {
          draft.data[action.eventId].rsvpCount = state.data[action.eventId].rsvpCount + 1;
          draft.data[action.eventId].rsvps.unshift({ userId: action.userId });
        }

        if (action.rsvpd) {
          draft.data[action.eventId].rsvpd = true;
        }
      }

      break;

    case EVENT_UNRSVP: {
      if (state.data[action.eventId]) {
        draft.data[action.eventId].rsvps = state.data[action.eventId]
          .rsvps.filter(r => r.userId !== action.userId);

        draft.data[action.eventId].rsvpCount = state.data[action.eventId].rsvpCount - 1;
      }

      break;
    }

    case ASSISTED: {
      const rsvp = draft.data[action.eventId].rsvps.find(r => r.userId === action.userId);
      rsvp.assistedAt = new Date();

      break;
    }

    case UNASSISTED: {
      const rsvp = draft.data[action.eventId].rsvps.find(r => r.userId === action.userId);
      rsvp.assistedAt = null;

      break;
    }

    case ADD_BAN:
      draft.data[action.eventId].bans = [
        ...state.data[action.eventId].bans,
        { userId: action.userId },
      ];
      break;

    case REMOVE_BAN:
      draft.data[action.eventId].bans = (state.data[action.eventId].bans || [])
        .filter(b => b.userId !== action.userId);
      break;

    case LOAD_INVITES:
      draft.data[action.eventId].invites = action.invites;
      break;

    case ADD_INVITE:
      draft.data[action.eventId].invites = [
        ...state.data[action.eventId].invites || [],
        action.invite,
      ];
      break;

    case REMOVE_INVITE:
      draft.data[action.eventId].invites = (
        state.data[action.eventId].invites.filter(i => i.id !== action.inviteId)
      );
      break;

    case ENROLLED:
      draft.data[action.eventId].enrolled = action.data;
      break;

    case WIPE:
      draft.data = initialState.data;
      break;

    default:
  }
});

export default reducer;
