import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appSelectors from 'state/app/selectors';
import * as appActions from 'state/app/actions';

import Modal from 'components/Modal';

import locales from './i18n';
import { EMAILS } from '../../constants';

const Wrapper = styled.div`
  a {
    color: ${props => props.theme.colors.main};
  }
`;
Wrapper.displayName = 'AdInfoModalWrapper';

const AdInfoModal = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const showing = useSelector(appSelectors.selectShowingAdInfoModal);

  const close = () => {
    dispatch(appActions.closeAdInformation());
  };

  if (!showing) return null;

  return (
    <Modal onClose={close}>
      <Wrapper>
        <h3>{t('Why am I seeing ads?')}</h3>

        <p>{t('Mazmo is a social network focused in meetings, discussions and opinions of people who wants to express their sexuality freely.')}</p>
        <p>{t('Thousands visit Mazmo every day; they engage, meet and share in diverse ways.')}</p>
        <p>{t('Showing ads allows Mazmo to be up to date, and have regular improvements. This way, thousands can keep enjoying, expressing themselves and finding everything they are looking for.')}</p>

        <h3>{t('I want to advertise here')}</h3>

        <Trans ns="layout" values={{ sales: EMAILS.SALES }}>
          <p>
            Mazmo will be glad to enabling you getting in touch with the
            {' site\'s '}
            audience. Send an email to
            {' '}
            <a href={`mailto:${EMAILS.SALES}`} target="_blank" rel="noopener noreferrer">{EMAILS.SALES}</a>
            {' '}
            and a sales representative will get in touch with you.
          </p>
        </Trans>
      </Wrapper>
    </Modal>
  );
};

AdInfoModal.propTypes = {
};

AdInfoModal.defaultProps = {
};

export default AdInfoModal;
