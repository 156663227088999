import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { Edit } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';

import locales from '../../../i18n';
import EditModal from '../../Modals/EditModal';
import DeleteModal from '../../Modals/DeleteModal';

interface Props {
}

const EditChannelAction: React.FC<Props> = () => {
  const { t } = useTranslation(locales);
  const { chatId: channelId } = useParams<{ chatId: string }>();

  const { isOpen: isEditModalOpen, onOpen: openEditModal, onClose: closeEditModal } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onOpen: openDeleteModal, onClose: closeDeleteModal } = useDisclosure();

  const isOwner = useSelector((state) => (
    // @ts-ignore
    channelSelectors.isOwnerOfChannel(state, channelId)
  ));

  if (!isOwner) return null;

  return (
    <>
      <MenuItem icon={<Edit size={16} />} onClick={openEditModal}>{t('Edit channel')}</MenuItem>

      {isEditModalOpen && (
        <EditModal
          channelId={channelId}
          close={closeEditModal}
          openDelete={openDeleteModal}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          channelId={channelId}
          close={closeDeleteModal}
        />
      )}
    </>
  );
};

export default EditChannelAction;
