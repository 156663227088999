import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { HStack, Text } from '@chakra-ui/react';

import formatNumber from 'utils/formatNumber';
import * as communitySelectors from 'state/communities/selectors';

import { Account, MessageReply, Forum } from 'components/Icons';

const StatsWrapper = styled.div`
  font-size: 12px;
  color: #CACACA;
  margin-top: 8px;

  @media(max-width: 767px) {
    margin-top: 4px;
  }
`;
StatsWrapper.displayName = 'StatsWrapper';

const Stats = ({ communityId }) => {
  const memberCount = useSelector(
    state => communitySelectors.selectMembershipCount(state, communityId),
  );
  const threadCount = useSelector(
    state => communitySelectors.selectThreadCount(state, communityId),
  );
  const replyCount = useSelector(state => communitySelectors.selectReplyCount(state, communityId));

  return (
    <HStack fontSize="xs" color="gray.300" spacing={3} mt={2}>
      <HStack spacing={1}>
        <Account fill="gray.300" boxSize={4} />
        <Text>{formatNumber(memberCount)}</Text>
      </HStack>
      <HStack spacing={1}>
        <Forum outline fill="gray.300" boxSize={4} />
        <Text>{formatNumber(threadCount)}</Text>
      </HStack>
      <HStack spacing={1}>
        <MessageReply fill="gray.300" boxSize={4} />
        <Text>{formatNumber(replyCount)}</Text>
      </HStack>
    </HStack>
  );
};

Stats.propTypes = {
  communityId: PropTypes.string.isRequired,
};

Stats.defaultProps = {
};

export default Stats;
