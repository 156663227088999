import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

const opts = {
  lastDay: '[Ayer]',
  sameDay: '[Hoy]',
  nextDay: '[Mañana]',
  lastWeek: '[Último] dddd',
  nextWeek: 'dddd',
  sameElse: 'L',
};

const DayWrapper = styled.div`
  margin: 24px 0;
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;

  .content {
    margin: 0 auto;
    border: 1px solid #ccc;
    text-align: center;
    width: fit-content;
    border-radius: 12px;
    color: #666;
    padding: 4px 16px;
    z-index: 10;
    background-color: white;
    box-sizing: border-box;
  }

  .line {
    height: 1px;
    background: #CCC;
    width: 100%;
    position: absolute;
  }
`;
DayWrapper.displayName = 'DayWrapper';

const Day = ({ date }) => (
  <DayWrapper>
    <div className="line" />
    <div className="content">{moment(date).calendar(null, opts)}</div>
  </DayWrapper>
);

Day.propTypes = {
  date: PropTypes.string.isRequired,
};

Day.defaultProps = {
};

export default memo(Day);
