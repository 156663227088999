import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as messengerSelectors from 'state/messengers/selectors';

import Spinner from 'components/Spinner';

const AudioWrapper = styled.div`
  width: 270px;

  audio {
    transform: scale(.8);
  }
`;
AudioWrapper.displayName = 'AudioWrapper';

const Audio = ({ messageId }) => {
  const hasAudio = useSelector(state => messengerSelectors.messageHasAudio(state, messageId));
  const file = useSelector(state => messengerSelectors.getMessageAudioFile(state, messageId));

  if (!hasAudio) return null;

  return (
    <AudioWrapper>
      {file
        // eslint-disable-next-line jsx-a11y/media-has-caption
        ? <audio src={file} controls="play" />
        : <Spinner color="#666" />
      }
    </AudioWrapper>
  );
};

Audio.propTypes = {
  messageId: PropTypes.string.isRequired,
};

Audio.defaultProps = {
};

export default Audio;
