/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import slugify from 'slugify';

import colors from 'utils/css/colors';
import { useOnMount, useInputValue, useTranslation } from 'hooks';

import Input from 'components/Forms/Input';
import Radio from 'components/Forms/Radio';
import InputWrapper from 'components/Forms/InputWrapper';
import PrivacyLabel from 'components/Forms/PrivacyLabel';
import PrivacyWrapper from 'components/Forms/PrivacyWrapper';
import Toggle from 'components/Toggle';

import { ERRORS } from './constants';
import { COMMUNITY_PRIVACIES, SITE_URL } from '../../constants';
import locales from './i18n';

const SlugWrapper = styled.div`
  margin-top: 8px;
`;

const SlugUrl = styled.span`
  color: ${colors.redReactions};
  font-size: 16px;
`;

const InputSlug = styled.input.attrs({
  type: 'text',
})<React.InputHTMLAttributes<HTMLInputElement>>`
  font-size: 16px;
  color: ${colors.blackRed};
  border: 0;
  border-bottom: 1px solid ${colors.blackRed};
  &:focus {
    outline: none;
  }
`;

interface CommunityFormProps {
  onDataChange: (data: any) => void;
  initialData: {
    name: string;
    slug: string;
    description: string;
    privacy: string;
    hasEvents: boolean;
  };
  initialAutoSlug: boolean;
}

const CommunityForm = ({
  onDataChange,
  initialData = {
    name: '',
    slug: '',
    description: '',
    privacy: COMMUNITY_PRIVACIES.PUBLIC,
    hasEvents: false,
  },
  initialAutoSlug = true,
}: CommunityFormProps) => {
  const { t } = useTranslation(locales);

  const firstInputEl = useRef<HTMLInputElement>(null);
  const [data, setData] = useState({ ...initialData });

  const [name, setName] = useState(initialData.name);
  const description = useInputValue(initialData.description);
  const [privacy, setPrivacy] = useState(initialData.privacy);

  const [slug, setSlug] = useState(initialData.slug);
  const [autoSlug, setAutoSlug] = useState(initialAutoSlug);
  const [slugErrors, setSlugErrors] = useState<string[]>([]);

  const [hasEvents, setHasEvents] = useState(initialData.hasEvents);

  useOnMount(() => {
    firstInputEl.current?.focus();
  });

  useEffect(() => {
    if (
      data.name !== name
      || data.slug !== slug
      || data.description !== description.value
      || data.privacy !== privacy
      || data.hasEvents !== hasEvents
    ) {
      const payload = {
        name,
        slug,
        description: description.value,
        privacy,
        hasEvents,
      };
      setData(payload);
      onDataChange(payload);
    }
  }, [name, slug, description, privacy, hasEvents]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    setName(newName);
    if (autoSlug) {
      setSlug(slugify(newName, {
        remove: /[*+~.()'"!:@]/g,
        lower: true,
      }));
    }
  };

  const onPrivacyChange = (newPrivacy: string) => () => setPrivacy(newPrivacy);
  const onSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSlug(e.target.value);
    setAutoSlug(false);
  };
  const onNameBlur = () => {
    const newErrors = [];

    if (!slug.match(/^[a-z0-9]+(?:-[a-z0-9]+)*$/)) {
      newErrors.push(ERRORS.INCORRECT_SLUG);
    }
    if (name.length < 3 || name.length > 40) {
      newErrors.push(ERRORS.INCORRECT_NAME);
    }

    setSlugErrors(newErrors);
  };

  const toggleHasEvents = () => {
    setHasEvents(cv => !cv);
  };

  return (
    <>
      <InputWrapper>
        <Input
          ref={firstInputEl}
          value={name}
          label={t('Community name')}
          maxChars={40}
          onChange={onNameChange}
          onBlur={onNameBlur}
        />
        <SlugWrapper>
          <SlugUrl>{`${SITE_URL}/+`}</SlugUrl>
          <InputSlug
            value={slug}
            onChange={onSlugChange}
            tabIndex={-1}
          />
          {slugErrors.length > 0 && (
            <div>
              {slugErrors.map((error, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`slugerror_${i}`}>{error}</div>
              ))}
            </div>
          )}
        </SlugWrapper>
      </InputWrapper>
      <InputWrapper>
        <Input
          value={description.value}
          label={t('Description')}
          maxChars={140}
          onChange={description.onChange}
        />
      </InputWrapper>

      <PrivacyLabel>{t('Privacy')}</PrivacyLabel>
      <PrivacyWrapper>
        <Radio
          label={t('Public')}
          onSelect={onPrivacyChange(COMMUNITY_PRIVACIES.PUBLIC)}
          selected={privacy === COMMUNITY_PRIVACIES.PUBLIC}
          description={t('Anyone can join and read the conversations. Threads are public and shows in search results.')}
        />
        <Radio
          label={t('Private')}
          onSelect={onPrivacyChange(COMMUNITY_PRIVACIES.PRIVATE)}
          selected={privacy === COMMUNITY_PRIVACIES.PRIVATE}
          description={t('Only members of the community can engage. Admin approval is required to join this community.')}
        />
      </PrivacyWrapper>

      <Toggle position="left" active={hasEvents} onChange={toggleHasEvents} label={t('Can have Events')} />
    </>
  );
};

export default CommunityForm;
