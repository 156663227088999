import { Box, Link } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import * as feedSelectors from 'state/feed/selectors';

import { API_URL } from '../../constants';

interface Props {
  publicationId: string;
}

const PromotedLink: React.FC<Props> = ({ publicationId }) => {
  const promoted = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectPromoted(state, publicationId),
  );

  if (!promoted) return null;

  return (
    <Box bgColor="brand.50">
      <Link
        href={`${API_URL}/feed/click/${publicationId}`}
        target="_blank"
        rel="noreferrer nofollow"
        color="brand.800"
        fontWeight="bold"
        display="block"
        p={2}
        _hover={{ bgColor: 'brand.100', textDecoration: 'none' }}
      >
        {`🔗 ${promoted.link.text}`}
      </Link>
    </Box>
  );
};

export default PromotedLink;
