const NOT_FOUND = 'NOT_FOUND';

class AppError extends Error {
  constructor(err) {
    super(err);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AppError);
    }

    let code = 500;
    let { message } = err.message;
    if (err.response) {
      // eslint-disable-next-line
      message = err.response.data.message;
      code = err.response.data.errorCode;

      if (!message && err.response.status === 404) {
        code = 404;
        message = NOT_FOUND;
      }
    } else if (err.request) {
      message = err.request;
    }

    this.message = message;
    this.code = code;
    this.date = new Date();
  }
}

export default AppError;
