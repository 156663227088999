import styled from 'styled-components';

const BodyShadow = styled.div`
  height: 100px;
  background: linear-gradient(0deg,rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 25%,rgba(255,255,255,0) 100%);
  position: absolute;
  width: 100%;
  top: 182px;
  left: 0;
`;
BodyShadow.displayName = 'BodyShadow';

export default BodyShadow;
