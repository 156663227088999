import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as channelSelectors from 'state/channels/selectors';
import * as messengerSelectors from 'state/messengers/selectors';

import Entities from './Entities';
import Contacts from './Contacts';
import Requests from './Requests';
import Tabs from './Tabs';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  position: relative;

  > .tabs-wrapper {
    flex-direction: row;
    justify-content: space-between;

    ul, button {
      margin: 0;
      height: 100%;
      box-sizing: border-box;
      padding-top: 4px;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

const ContentWrapper = styled.div`
  flex: 1;
  height: calc(100% - 53px);
  overflow: hidden;

  .selectable-list-container {
    height: 100%;
    position: relative;

    .overflow-component {
      height: 100%;
    }

    ul {
      flex: 1;
    }
  }
`;
ContentWrapper.displayName = 'ContentWrapper';

const Content = memo(({ selected, filter }) => {
  switch (selected) {
    case 0:
      return <Entities filter={filter} />;
    case 2:
      return <Requests filter={filter} />;
    case 1:
    default:
      return <Contacts filter={filter} />;
  }
});

Content.propTypes = {
  selected: PropTypes.oneOf([0, 1, 2]).isRequired,
  filter: PropTypes.string,
};

Content.defaultProps = {
  filter: '',
};


const ChatList = memo(() => {
  const params = useParams();

  const channelCount = useSelector(channelSelectors.channelCount);
  const messengerCount = useSelector(messengerSelectors.messengerCount);

  const emptyChats = messengerCount === 0 && channelCount === 0;
  const [tabIndex, setTabIndex] = useState(emptyChats ? 1 : 0);

  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (params.chatId) {
      setTabIndex(0);
    }
  }, [params.chatId]);

  return (
    <Wrapper>
      <Tabs
        tabIndex={tabIndex}
        filter={filter}
        setFilter={setFilter}
        setTabIndex={setTabIndex}
      />
      <ContentWrapper>
        <Content selected={tabIndex} filter={filter} />
      </ContentWrapper>
    </Wrapper>
  );
});

ChatList.propTypes = {
};

export default ChatList;
