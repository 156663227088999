import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';
import * as authActions from 'state/auth/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';

import locales from '../../i18n';
import MuteToggles from './MuteToggles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UnblockModal = ({ userId, close }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [feed, setFeed] = useState(false);

  const onConfirm = async () => {
    try {
      setLoading(true);
      const promises = [
        dispatch(authActions.unblock(userId)),
      ];

      if (feed) promises.push(dispatch(authActions.muteInFeed(userId)));
      else promises.push(dispatch(authActions.unmuteInFeed(userId)));

      await Promise.all(promises);
      dispatch(appActions.addToast(t('User unblocked')));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    close();
  };

  const toggleFeed = () => {
    setFeed(f => !f);
  };

  return (
    <Modal
      title={t('Unblock')}
      onCancel={close}
      actions={[(
        <Button
          key="unblock-confirm"
          onClick={onConfirm}
          loading={loading}
        >
          {t('Unblock')}
        </Button>
      )]}
    >
      <Wrapper>
        <b>{t('Are you sure you want to unblock this person?')}</b>
        <p>{`${t('You can also mute this person from the following sections of the site')}:`}</p>
        <div>
          <MuteToggles feed={feed} toggleFeed={toggleFeed} />
        </div>
      </Wrapper>
    </Modal>
  );
};

UnblockModal.propTypes = {
  userId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};

UnblockModal.defaultProps = {
};

export default UnblockModal;
