import styled from 'styled-components';

const ActionsWrapper = styled.div`
  padding: 16px 32px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);

  @media(max-width: 767px) {
    padding: 8px 16px;
  }

  > div {
    display: flex;
    align-items: center;

    .discard-icon {
      display: none;
    }

    @media(max-width: 767px) {
      .discard-icon {
        display: block;
      }

      .discard-text {
        display: none;
      }
    }

    > button:not(:first-child) {
      margin-left: 16px;
    }
  }
`;

export default ActionsWrapper;
