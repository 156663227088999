import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as feedSelectors from 'state/feed/selectors';

import * as feedActions from 'state/feed/actions';

export const HOME_URL = '/';

const useHomeLinkClick = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const queueCount = useSelector(feedSelectors.getFeedQueueCount());

  const onClick = () => {
    if (location.pathname === HOME_URL) {
      window.scrollTo(0, 0);

      if (queueCount > 0) {
        dispatch(feedActions.flushQueue());
      }
    }
  };

  return onClick;
};

export default useHomeLinkClick;
