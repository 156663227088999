import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import { SelectableListItem } from 'components/SelectableList';
import UserAvatar from 'components/UserAvatar';

const Role = styled.div`
  color: #666;
  font-size: 14px;
  margin-top: 6px;
`;

const userEqual = (prevUser, nextUser) => (
  prevUser.id === nextUser.id
  && Object.keys(prevUser.avatar).length === Object.keys(nextUser.avatar).length
  && prevUser.loading === nextUser.loading
);

const UserItem = ({
  userId, subtitle, actions, onClick,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.getById(userId), userEqual);
  if (user.loading) dispatch(userActions.fetchData(userId));

  const renderAvatar = useCallback(() => <UserAvatar userId={userId} />, [userId]);
  const renderContent = useCallback(() => <Role>{subtitle}</Role>, [subtitle]);

  return (
    <SelectableListItem
      renderAvatar={renderAvatar}
      title={user.displayname}
      actions={actions}
      onClick={onClick}
      renderContent={renderContent}
    />
  );
};

UserItem.propTypes = {
  userId: PropTypes.number.isRequired,
  subtitle: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.node),
  onClick: PropTypes.func,
};

UserItem.defaultProps = {
  subtitle: '',
  actions: [],
  onClick: null,
};

export default memo(UserItem);
