export const FETCH = 'mazmo/messengers/FETCH';
export const FETCH_SUCCESS = 'mazmo/messengers/FETCH_SUCCESS';
export const FETCH_FAIL = 'mazmo/messengers/FETCH_FAIL';

export const ADD_MESSAGE = 'mazmo/messengers/ADD_MESSAGE';
export const UPDATE_MESSAGE = 'mazmo/messengers/UPDATE_MESSAGE';
export const INC_UNREAD = 'mazmo/messengers/INC_UNREAD';
export const RESET_UNREAD = 'mazmo/messengers/RESET_UNREAD';
export const UPDATE = 'mazmo/messengers/UPDATE';
export const SET_ACTIVE = 'mazmo/messengers/SET_ACTIVE';

export const LOAD_MESSAGES = 'mazmo/messengers/LOAD_MESSAGES';
export const LOAD_MESSAGES_SUCCESS = 'mazmo/messengers/LOAD_MESSAGES_SUCCESS';
export const LOAD_MESSAGES_FAIL = 'mazmo/messengers/LOAD_MESSAGES_FAIL';

export const MESSAGE_REMOVING = 'mazmo/messengers/MESSAGE_REMOVING';
export const MESSAGE_REMOVE = 'mazmo/messengers/MESSAGE_REMOVE';

export const FULLY_LOADED = 'mazmo/messengers/FULLY_LOADED';

export const TYPING_START = 'mazmo/messengers/TYPING_START';
export const TYPING_STOP = 'mazmo/messengers/TYPING_STOP';

export const UNMOUNT = 'mazmo/messengers/UNMOUNT';
export const SCROLL_AT_BOTTOM_CHANGE = 'mazmo/messengers/SCROLL_AT_BOTTOM_CHANGE';

export const ADD_REPLYTO = 'mazmo/messengers/ADD_REPLYTO';
export const CLEAR_REPLYTO = 'mazmo/messengers/CLEAR_REPLYTO';
export const ADD_PUBLICATIONID = 'mazmo/messengers/ADD_PUBLICATIONID';
export const CLEAR_PUBLICATIONID = 'mazmo/messengers/CLEAR_PUBLICATIONID';

export const ADD_EDITCOMPOSER = 'mazmo/messengers/ADD_EDITCOMPOSER';
export const REMOVE_EDITCOMPOSER = 'mazmo/messengers/REMOVE_EDITCOMPOSER';

export const WIPE = 'mazmo/messengers/WIPE';
