import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ButtonGroup,
  ModalHeader,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import * as bankSelectors from 'state/bank/selectors';
import * as appActions from 'state/app/actions';
import * as bankActions from 'state/bank/actions';

import locales from './i18n';

interface Props {
  itemName: string;
  otherItems?: string[];
  close: () => void;
  afterPurchase?: (transaction: any) => void;
  payloadData?: Record<string, any>;
  showSuccessToast?: boolean;
}

const PurchaseModal: React.FC<Props> = ({
  itemName: mainItemName,
  close,
  afterPurchase,
  payloadData,
  otherItems = [],
  showSuccessToast = false,
}) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const itemsPrices = useSelector(
    state => bankSelectors.selectBuyableItemsPriceList(
      state,
      // @ts-ignore
      [mainItemName, ...otherItems],
    ),
  );

  const [purchasing, setPurchasing] = useState(false);

  const transfer = useCallback(async () => {
    try {
      setPurchasing(true);

      const items = [mainItemName, ...otherItems];

      const payload = { items, payload: payloadData };
      const transaction = await bankActions.createPurchase(payload);

      if (afterPurchase) await afterPurchase(transaction);

      if (showSuccessToast) dispatch(appActions.addToast(t('Purchase successful')));
    } catch (error) {
      dispatch(appActions.addError(error));
    } finally {
      setPurchasing(false);
      close();
    }
  }, [dispatch, t, mainItemName, close, afterPurchase, payloadData, otherItems, showSuccessToast]);

  const itemsSummary = useMemo(() => {
    const summary = {
      [mainItemName]: { amount: 1, unitPrice: itemsPrices[mainItemName], totalPrice: itemsPrices[mainItemName] },
    };
    otherItems.forEach((itemName) => {
      if (summary[itemName]) {
        summary[itemName].amount += 1;
        summary[itemName].totalPrice += itemsPrices[itemName];
      } else {
        summary[itemName] = { amount: 1, unitPrice: itemsPrices[itemName], totalPrice: itemsPrices[itemName] };
      }
    });
    return summary;
  }, [otherItems, mainItemName, itemsPrices]);

  const totalPrice = useMemo(() => Object.values(itemsSummary).reduce(
    (acc, item) => (acc + item.amount * item.unitPrice),
    0,
  ),
  [itemsSummary]);

  return (
    <Modal isOpen onClose={close} closeOnEsc closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent w="4xl">
        <ModalHeader>{t(`${mainItemName}.name`)}</ModalHeader>
        <ModalBody textAlign="center">
          <Text>{t(`${mainItemName}.description`)}</Text>

          <TableContainer my={6}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Item</Th>
                  <Th isNumeric>Precio</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.keys(itemsSummary).map((itemName) => (
                  <Tr key={itemName}>
                    <Td>{t(`${itemName}.name`)}</Td>
                    <Td isNumeric>{`§ ${itemsSummary[itemName].totalPrice}`}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup spacing={4}>
            <Button onClick={close}>{t('global:Cancel')}</Button>
            <Button isLoading={purchasing} onClick={transfer} isDisabled={!itemsPrices[mainItemName]} variant="primary">
              {t('Purchase for §{{totalPrice}}', { totalPrice })}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PurchaseModal;
