import styled from 'styled-components';

interface Props {
  pressed?: boolean;
  mobileOnly?: boolean;
}

const Action = styled.div<Props>`
  height: 40px;
  width: 40px;
  padding: 4px;
  line-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;

    /* @media(min-width: 767px) {
      width: 18px;
      height: 18px;
    } */
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }

  ${props => props.pressed && `
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 3px black;
  `}

  ${props => props.mobileOnly && `
    @media(min-width: 767px) {
      display: none;
    }
  `}

  > svg.more-actions {
    width: 20px;
    height: 20px;
  }
`;

export default Action;
