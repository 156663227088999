import {
  Card, CardBody, Flex, Text,
} from '@chakra-ui/react';
import Image from 'components/Publication/MoreContent/Image';
import { Link } from 'react-router-dom';

import { PUBLICATION_TYPES } from '../../../../../constants';

interface Props {
  publication: {
    id: string,
    type: keyof typeof PUBLICATION_TYPES,
    payload: {
      rawContent: string,
      media: {
        filename: string,
        optimized: boolean,
        metadata: { width: number, height: number },
        path: string,
      }[],
    }
  };
}

const LinkPublication: React.FC<Props> = ({ publication }) => {
  if (!publication) return null;

  return (
    <Card maxW="200px" mb={4} as={Link} to={`/publications/${publication.id}`}>
      <CardBody p={2}>
        {publication.type === PUBLICATION_TYPES.MEDIA && publication.payload.media.length > 0 && (
          <Flex bgColor="black" justifyContent="center" mb={1}>
            <Image
              src={publication.payload.media[0].filename}
              alt={publication.payload.media[0].filename}
              width={publication.payload.media[0].metadata?.width}
              height={publication.payload.media[0].metadata?.height}
              optimized={publication.payload.media[0].optimized}
              path={publication.payload.media[0].path}
              style={{
                maxWidth: '180px', maxHeight: '100px', objectFit: 'contain',
              }}
            />
          </Flex>
        )}
        <Text fontSize="sm" noOfLines={1} color="gray.500">{publication.payload.rawContent}</Text>
      </CardBody>
    </Card>
  );
};

export default LinkPublication;
