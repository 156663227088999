import styled from 'styled-components';

const BodyWrapper = styled.div`
  max-height: 250px;
  overflow: hidden;
  font-size: 16px;
  margin-bottom: 16px;
  position: relative;
  padding: 16px;
`;
BodyWrapper.displayName = 'BodyWrapper';

export default BodyWrapper;
