import styled from 'styled-components';

interface Props extends React.HTMLProps<HTMLDivElement> {
  danger?: boolean;
  warning?: boolean;
}

const MenuItem = styled.div.attrs({
  className: 'dd-item',
})<Props>`
  ${props => props.danger && `
    color: ${props.theme.colors.main};
  `}

  ${props => props.warning && `
    background-color: ${props.theme.colors.warningBackground};
  `}

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    line-height: 48px;
  }

  .counter {
    background-color: ${props => props.theme.colors.main};
    width: 24px;
    height: 24px;
    border-radius: 100%;
    line-height: 24px;
    text-align: center;
    color: white;
  }

  > svg {
    width: 20px !important;
    height: 20px;
    margin-right: 4px;
  }
`;

export default MenuItem;
