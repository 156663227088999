// eslint-disable-next-line import/prefer-default-export
export const AWARDS = {
  HOT: { name: 'HOT', image: 'orgasm.jpg', price: 100 },
  INLOVE: { name: 'INLOVE', image: 'orgasm.jpg', price: 100 },
  TREASURE: { name: 'TREASURE', image: 'orgasm.jpg', price: 100 },
  GIFT: { name: 'GIFT', image: 'orgasm.jpg', price: 100 },
  CUTE: { name: 'CUTE', image: 'orgasm.jpg', price: 100 },
  HUG: { name: 'HUG', image: 'orgasm.jpg', price: 100 },
  HARDCORE: { name: 'HARDCORE', image: 'orgasm.jpg', price: 100 },
  HEADEXPLODE: { name: 'HEADEXPLODE', image: 'orgasm.jpg', price: 100 },
  MASTERPIECE: { name: 'MASTERPIECE', image: 'orgasm.jpg', price: 100 },
  FUN: { name: 'FUN', image: 'orgasm.jpg', price: 100 },
  TAKEMYSADES: { name: 'TAKEMYSADES', image: 'orgasm.jpg', price: 100 },
  ORGASM: { name: 'ORGASM', image: 'orgasm.jpg', price: 100 },
  FETISH: { name: 'FETISH', image: 'orgasm.jpg', price: 100 },
  KISS: { name: 'KISS', image: 'orgasm.jpg', price: 100 },
};
