import languages from 'i18n/languages';

const keys = Object.keys(languages);
const resources: Record<string, Record<string, string>> = {};

keys.forEach((key) => {
  try {
    // eslint-disable-next-line global-require, import/no-dynamic-require
    const content = require(`./${key}.json`) as Record<string, string>;
    resources[key] = content;
  } catch (error) {
    //
  }
});

export default {
  namespace: 'ShareButton',
  resources,
};
