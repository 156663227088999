import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';
import { Community } from 'types';

import * as appSelectors from 'state/app/selectors';
import * as communitySelectors from 'state/communities/selectors';

import defaultAvatar from './default.jpg';

interface CommunityImgProps extends React.HTMLAttributes<HTMLImageElement> {
  isSafeForWork: boolean;
  community: Community
  size?: string;
  withShadow?: boolean;
}

const CommunityImg = styled.img.attrs<CommunityImgProps>(props => ({
  src: !props.isSafeForWork ? props.community.avatar : defaultAvatar,
  alt: `Avatar de ${props.community.name}`,
  title: props.community.name,
  width: props.size,
  height: props.size,
}))<CommunityImgProps>`
  display: block;
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 4px;
  ${props => props.withShadow && 'box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.16);'}
  flex-shrink: 0;
  color: white;
  overflow: hidden;
`;

interface CommunityAvatarProps {
  communityId: string;
  size: CommunityImgProps['size'];
}

const CommunityAvatar = ({
  communityId,
  size,
  ...props
}: CommunityAvatarProps) => {
  const community = useSelector(
    // @ts-ignore
    state => communitySelectors.selectById(state, communityId),
    shallowEqual,
  );
  const isSafeForWork = useSelector(appSelectors.selectIsSafeForWork);

  if (!community) return null;

  return (
    <CommunityImg
      size={size}
      community={community}
      isSafeForWork={isSafeForWork}
      {...props}
    />
  );
};

CommunityAvatar.propTypes = {
  communityId: PropTypes.string.isRequired,
  size: PropTypes.string,
  withShadow: PropTypes.bool,
};

CommunityAvatar.defaultProps = {
  size: '50px',
  withShadow: true,
};

export default CommunityAvatar;
