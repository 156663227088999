import { schema } from 'normalizr';

import user from 'state/users/schema';

const community = new schema.Entity('communities', {
  founder: user,
  mods: [{
    user,
  }],
}, {
  idAttribute: '_id',
});

export default community;
