import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from '@chakra-ui/react';
import { UserX } from 'lucide-react';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as authActions from 'state/auth/actions';
import * as appActions from 'state/app/actions';


import locales from './i18n';

const UserBlockButton = ({ userId, size }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const myUserId = useSelector(authSelectors.selectUserId);
  const blocked = useSelector(state => authSelectors.selectIsBlocked(state, userId));

  const [loading, setLoading] = useState(false);

  const onClick = useCallback(async () => {
    try {
      setLoading(true);
      if (blocked) {
        await dispatch(authActions.unblock(userId));
      } else {
        await dispatch(authActions.block(userId));
      }
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    setLoading(false);
  }, [dispatch, userId, blocked]);

  if (!userId || userId === myUserId) return null;

  let iconSize = 5;
  if (size === 'xs') iconSize = 3;
  if (size === 'sm') iconSize = 4;
  if (size === 'lg') iconSize = 6;
  if (size === 'xl') iconSize = 8;

  return (
    <>
      <Button
        leftIcon={<Icon as={UserX} color="black" boxSize={iconSize} />}
        onClick={onClick}
        isLoading={loading}
        size={size}
      >
        {blocked ? t('global:Unblock') : t('global:Block')}
      </Button>
    </>
  );
};

UserBlockButton.propTypes = {
  userId: PropTypes.number.isRequired,
  size: PropTypes.string,
};

UserBlockButton.defaultProps = {
  size: 'md',
};

export default UserBlockButton;
