import { IconType, IconDefaults } from './type';

const ChevronLeft = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
      fill={color}
    />
  </svg>
);

ChevronLeft.propTypes = IconType;
ChevronLeft.defaultProps = IconDefaults;

export default ChevronLeft;
