import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import qs from 'qs';
import { useDebouncedCallback } from 'use-debounce/lib';
import Axios from 'axios';

import { useTranslation } from 'hooks';

import Input from 'components/Forms/Input';
import Loading from 'components/Loading';
import { Close } from 'components/Icons';
import LinkBoxContainer from 'components/LinkBox';

import { PUBLICATION_TYPES } from '../../../constants';
import colors from '../../../utils/css/colors';

import locales from '../i18n';

const CloseButton = styled(Close).attrs({
  color: 'white',
})`
  width: 24px;
  height: 24px;
  background-color: rgba(0,0,0,0.3);
  border-radius: 100%;
  padding: 4px;
  cursor: pointer;
  position: absolute;
  right: 16px;
`;

const ErrorText = styled.div`
  color: ${colors.red};
  margin-bottom: 24px;
`;

const LinkBox = ({ data, clear }) => (
  <LinkBoxContainer>
    {data.icon && <img className="linkImage" src={data.icon} alt={data.title} />}
    <div>
      <h3>{data.title}</h3>
      <p>{data.description}</p>
    </div>
    <CloseButton onClick={clear} />
  </LinkBoxContainer>
);

LinkBox.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  clear: PropTypes.func.isRequired,
};

const ImageBox = ({ data, clear }) => (
  <LinkBoxContainer>
    <img className="fullImage" src={data.url} alt="Web" />
    <CloseButton onClick={clear} />
  </LinkBoxContainer>
);

ImageBox.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  clear: PropTypes.func.isRequired,
};

const NewUrl = ({ payload, setPayload, children }) => {
  const queryparams = qs.parse(document.location.search, { ignoreQueryPrefix: true });

  const initialValue = queryparams.url || '';
  const [value, setValue] = useState(initialValue);
  const [changed, setChanged] = useState(initialValue.length > 0);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation(locales);

  const fetchUrlContent = useCallback(async () => {
    if (!fetching && changed && value) {
      setChanged(false);

      try {
        setPayload(null);
        setFetching(true);
        const { data } = await Axios.get(`https://mazmo.net/api/extractor?url=${encodeURIComponent(value)}`);
        setPayload({ url: data });
        setFetching(false);
      } catch (e) {
        setFetching(false);
        setError('Error extracting content');
      }
    }
  }, [changed, setPayload, value, fetching]);

  const [debouncedFetch] = useDebouncedCallback(fetchUrlContent, 1000);

  const onChange = (e) => {
    setValue(e.target.value.trim());
    setChanged(true);
    setError(null);
    debouncedFetch();
  };

  useEffect(() => {
    setChanged(true);
    setError(null);
    debouncedFetch();
  }, [debouncedFetch]);

  const renderUrlBox = () => {
    if (!payload) return null;
    const clear = () => {
      setValue('');
      setChanged(false);
      setPayload(null);
    };

    if (payload.url?.type === 'LINK') return <LinkBox data={payload.url} clear={clear} />;
    if (payload.url?.type === 'IMAGE' || payload.url?.type === 'GIF') return <ImageBox data={payload.url} clear={clear} />;

    return null;
  };

  return (
    <div>
      <Input placeholder="URL" value={value} onChange={onChange} onBlur={fetchUrlContent} />
      {fetching ? <Loading /> : renderUrlBox()}
      {error && <ErrorText>{t(error)}</ErrorText>}
      {children}
    </div>
  );
};

NewUrl.propTypes = {
  children: PropTypes.node.isRequired,
  payload: PropTypes.shape({
    url: PropTypes.shape({
      type: PropTypes.oneOf(Object.values(PUBLICATION_TYPES)),
    }),
  }),
  setPayload: PropTypes.func.isRequired,
};

NewUrl.defaultProps = {
  payload: null,
};

export default NewUrl;
