import styled from 'styled-components';

import Button from 'components/Button';

interface Props {
  negative?: boolean;
  distance?: string;
}

const FloatingButton = styled(Button)<Props>`
  position: absolute;
  bottom: 32px;
  right: 32px;
  border-radius: 100%;
  width: 56px;
  height: 56px;
  box-shadow: 0px 0 6px #6d6d6d;
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 10;

  @media(max-width: 768px) {
    position: fixed;
  }

  ${props => props.negative && `
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.20);

    &:hover {
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
    }
  `}

  ${props => props.distance && `
    bottom: ${props.distance};
    right: ${props.distance};
  `}

  div {
    display: flex;
  }
`;
FloatingButton.displayName = 'FloatingButton';

export default FloatingButton;
