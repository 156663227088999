import { useTranslation } from 'hooks';

import locales from './i18n';

interface Props {
  message: string;
}

const LoadError: React.FC<Props> = ({ message }) => {
  const { t } = useTranslation(locales);

  return (
    <div>
      <span>{t('There was a problem')}</span>
      <span>{message}</span>
    </div>
  );
};

export default LoadError;
