import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import * as threadSelectors from 'state/threads/selectors';
import * as eventSelectors from 'state/events/selectors';

import {
  Comment, Spank, CalendarCursor, Calendar,
} from 'components/Icons';

import Awards from './Awards';

const Wrapper = styled.ul`
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
  }
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  &:not(:last-child) {
    margin-right: 16px;
  }
`;

const IconWrapper = styled.div`
  width: 16px;
`;

const Content = styled.span`
  color: ${colors.redReactions};
  font-size: 12px;
  font-weight: 400;
  margin-left: 4px;
`;

const Status = ({ threadId }) => {
  const commentCount = useSelector(threadSelectors.getCommentCount(threadId));
  const spankCount = useSelector(threadSelectors.getReactionsCount(threadId));
  const eventId = useSelector(threadSelectors.getEventId(threadId));
  const rsvpCount = useSelector(state => eventSelectors.selectRsvpCount(state, eventId));
  const eventHighlight = useSelector(state => eventSelectors.isHighlighted(state, eventId));
  const date = useSelector(state => eventSelectors.selectDate(state, eventId));

  return (
    <Wrapper>
      <div>
        {eventId && eventHighlight && (
          <>
            <Item>
              <IconWrapper>
                <Calendar color={colors.redReactions} width="16px" height="16px" />
              </IconWrapper>
              <Content>{moment(date).format('D MMM').replace('.', '')}</Content>
            </Item>
            <Item>
              <IconWrapper>
                <CalendarCursor color={colors.redReactions} width="16px" height="16px" />
              </IconWrapper>
              <Content>{rsvpCount}</Content>
            </Item>
          </>
        )}
        <Item>
          <IconWrapper>
            <Comment color={colors.redReactions} width="16px" height="16px" />
          </IconWrapper>
          <Content>{commentCount}</Content>
        </Item>
        <Item>
          <IconWrapper>
            <Spank color={colors.redReactions} width="16px" height="16px" />
          </IconWrapper>
          <Content>{spankCount}</Content>
        </Item>
      </div>

      <Awards threadId={threadId} />
    </Wrapper>
  );
};

Status.propTypes = {
  threadId: PropTypes.string.isRequired,
};

export default Status;
