import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from 'state/reducers';

import App from 'containers/App';
import ServiceWorkerWrapper from 'containers/App/ServiceWorkerWrapper';

import './i18n';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // release: `webapp@${process.env.npm_package_version}`,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,

    tracesSampleRate: 0.1,
  });
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
// @ts-ignore
const composeEnhancers = (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer),
);

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    // include: [/.*/],
    exclude: [/Boundary/, /Socket/, /Routines/, /styled/, /Styled/, /LinkAnchor/],
    logOwnerReasons: true,
    collapseGroups: true,
    trackExtraHooks: [
      // eslint-disable-next-line global-require
      [require('react-redux/lib'), 'useSelector'],
    ],
  });
}

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ServiceWorkerWrapper />
    <App />
  </Provider>,
);
