import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation, useOpenClose } from 'hooks';
import { toCurrency } from 'utils/toCurrency';
import * as threadSelectors from 'state/threads/selectors';
import * as eventSelectors from 'state/events/selectors';
import * as eventActions from 'state/events/actions';
import * as appActions from 'state/app/actions';

import Button from 'components/Button';
import Modal from 'components/Modal';
import {
  Tree, City, AccountGroup, CheckCircle, CloseCircle,
} from 'components/Icons';

import Wrapper from './Wrapper';
import Organizers from './Organizers';
import Flyer from './Flyer';
import ActionButton from './AssistButton/Button';
import NoPaymentWarning from './NoPaymentWarning';
import locales from '../i18n';

const Event = ({ threadId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const eventId = useSelector(threadSelectors.getEventId(threadId));
  const rsvpd = useSelector(state => eventSelectors.hasRSVPd(state, eventId));
  const isOrganizer = useSelector(state => eventSelectors.isOrganizer(state, eventId));
  const isPublic = useSelector(state => eventSelectors.isPublic(state, eventId));
  const price = useSelector(state => eventSelectors.selectPrice(state, eventId));
  const currency = useSelector(state => eventSelectors.selectCurrency(state, eventId));
  const address = useSelector(state => eventSelectors.selectAddressString(state, eventId));
  const url = useSelector(state => eventSelectors.selectUrl(state, eventId));
  const rsvpLimit = useSelector(
    state => eventSelectors.selectRsvpLimit(state, eventId),
  );
  const hasAssistanceFeatureEnabled = useSelector(
    state => eventSelectors.hasAssistanceFeatureEnabled(state, eventId),
  );
  const rawDate = useSelector(state => eventSelectors.selectDate(state, eventId));
  const date = moment(rawDate);

  const [unrsvpModalOpened, openUnRsvpModal, closeUnRsvpModal] = useOpenClose(false);
  const [rsvping, setRsvping] = useState(false);

  const unrsvp = useCallback(async () => {
    try {
      setRsvping(true);
      await dispatch(eventActions.unrsvp(eventId));
    } catch (error) {
      dispatch(appActions.addError(error));
    }

    closeUnRsvpModal();
    setRsvping(false);
    dispatch(appActions.addToast(t('Event RSVP cancelled')));
  }, [dispatch, t, eventId, closeUnRsvpModal]);

  return (
    <>
      <NoPaymentWarning eventId={eventId} />

      <Wrapper>
        <div>
          <time dateTime={date.format('YYYY-MM-DD')} className="calendar">
            <em>{date.format('dddd')}</em>
            <strong>{date.format('MMMM')}</strong>
            <span>{date.format('DD')}</span>
          </time>
        </div>

        <div>
          <table>
            <tbody>
              {address ? (
                <tr>
                  <td>{t('Where')}</td>
                  <td>{address}</td>
                </tr>
              ) : (
                <tr>
                  <td>{t('URL')}</td>
                  <td><a href={url} target="_blank" rel="noreferrer">{url}</a></td>
                </tr>
              )}
              <tr>
                <td>{t('Time')}</td>
                <td>{`${date.format('HH.mm')}hs`}</td>
              </tr>
              <tr>
                <td>{t('Ticket price')}</td>
                <td>{price > 0 ? toCurrency(price, currency) : t('Free')}</td>
              </tr>
              <tr>
                <td>{t('Organizers')}</td>
                <td><Organizers eventId={eventId} /></td>
              </tr>
            </tbody>
          </table>

          <div className="features">
            {isPublic ? (
              <div>
                <Tree color="black" />
                <span>{t('Public space')}</span>
              </div>
            ) : (
              <div>
                <City color="black" />
                <span>{t('Private space')}</span>
              </div>
            )}

            {rsvpLimit && (
              <div>
                <AccountGroup color="black" />
                <span>{t('RSVP max of {{rsvpLimit}} persons', { rsvpLimit })}</span>
              </div>
            )}

            <div className={!hasAssistanceFeatureEnabled ? 'disabled' : null}>
              {hasAssistanceFeatureEnabled ? <CheckCircle /> : <CloseCircle />}
              <span>{t('Has assistance enabled')}</span>
            </div>
          </div>

          <div className="actions">
            <ActionButton eventId={eventId} />
            {isOrganizer && <Button className="empty" to={`/events/${eventId}`}>{t('Manage')}</Button>}

            {rsvpd && (
              <div>
                <br />
                <br />
                <Button onClick={openUnRsvpModal} className="empty">{t('Cancel RSVP')}</Button>
              </div>
            )}
          </div>
        </div>

        <div>
          <Flyer eventId={eventId} />
        </div>
      </Wrapper>

      {/* Modals */}
      {unrsvpModalOpened && (
        <Modal
          title={t('Cancel RSVP')}
          onCancel={closeUnRsvpModal}
          actions={[
            <Button key={`event-${eventId}-cancel-rsvp`} onClick={unrsvp} loading={rsvping}>{t('global:Confirm')}</Button>,
          ]}
        >
          {t('Are you sure you want to cancel your RSVP to this Event?')}
        </Modal>
      )}
    </>
  );
};

Event.propTypes = {
  threadId: PropTypes.string.isRequired,
};

Event.defaultProps = {
};

export default Event;
