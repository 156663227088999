import { useState } from 'react';
import PropTypes from 'prop-types';

import Api from 'state/api';
import { useOnMount, useTitle } from 'hooks';

import NotFound from 'containers/NotFound';
import Loading from 'components/Loading';

import FakeChannel from './FakeChannel';

const NotMember = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [channel, setChannel] = useState(null);

  useTitle(`Chat ${channel ? channel.name : ''}`);

  const fetch = async () => {
    try {
      const { data } = await Api.req.get(`/chat/channels/${id}`);
      setChannel(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useOnMount(() => {
    fetch();
  });

  if (loading) return <Loading />;
  if (!loading && !channel) return <NotFound />;

  return <FakeChannel channel={channel} />;
};

NotMember.propTypes = {
  id: PropTypes.string.isRequired,
};

export default NotMember;
