import { normalize } from 'normalizr';
import ReactGA from 'react-ga4';
import { batch } from 'react-redux';
import moment from 'moment';

import Api from 'state/api';
import * as userActions from 'state/users/actions';
import * as authActions from 'state/auth/actions';

import { publicationSchema, commentSchema } from './schema';
import {
  ADD_PUBLICATIONS,
  ADD_COMMENTS,
  REMOVE_COMMENT,
  WIPE,
  PREPEND_TO_FEED_QUEUE,
  REMOVE_FROM_FEED,
  FLUSH_FEED_QUEUE,
  SET_LATEST_HASHTAGS,
  POLL_UPDATED,
  SET_SCROLL,
} from './constants';

export const load = () => async (dispatch, getState) => {
  const { feed, feedQueue } = getState().feed;
  const nin = [...feed, ...feedQueue];

  const { data: rawData } = await Api.req.get('/feed', { params: { nin } });

  const data = normalize(rawData, [publicationSchema]);

  batch(() => {
    dispatch(userActions.add(data.entities.users || {}));
    dispatch({
      type: ADD_PUBLICATIONS,
      publications: (data.entities.publications || {}),
      comments: (data.entities.comments || {}),
      feed: data.result,
    });
  });

  return data.entities.publications;
};

export const loadTrending = offset => async (dispatch) => {
  const { data: rawData } = await Api.req.get('/feed/trending', { params: { offset } });
  const data = normalize(rawData, [publicationSchema]);

  batch(() => {
    dispatch(userActions.add(data.entities.users || {}));
    dispatch({
      type: ADD_PUBLICATIONS,
      publications: (data.entities.publications || {}),
      comments: (data.entities.comments || {}),
    });
  });

  return data.result;
};

export const loadHashtag = (hashtag, offset) => async (dispatch) => {
  const { data: rawData } = await Api.req.get('/feed/trending', { params: { hashtag, offset } });
  const data = normalize(rawData, [publicationSchema]);

  batch(() => {
    dispatch(userActions.add(data.entities.users || {}));
    dispatch({
      type: ADD_PUBLICATIONS,
      publications: (data.entities.publications || {}),
      comments: (data.entities.comments || {}),
    });
  });

  return data.result;
};

export const loadOnlyMedia = offset => async (dispatch) => {
  const { data: rawData } = await Api.req.get('/feed/media', { params: { offset } });

  const data = normalize(rawData, [publicationSchema]);

  batch(() => {
    dispatch(userActions.add(data.entities.users || {}));
    dispatch({
      type: ADD_PUBLICATIONS,
      publications: (data.entities.publications || {}),
      comments: (data.entities.comments || {}),
    });
  });

  return data.result;
};

export const loadOnlyExclusive = offset => async (dispatch) => {
  const { data: rawData } = await Api.req.get('/feed/exclusive', { params: { offset } });

  const data = normalize(rawData, [publicationSchema]);

  batch(() => {
    dispatch(userActions.add(data.entities.users || {}));
    dispatch({
      type: ADD_PUBLICATIONS,
      publications: (data.entities.publications || {}),
      comments: (data.entities.comments || {}),
    });
  });

  return data.result;
};

export const loadPublication = (publicationId, prepend = false) => async (dispatch) => {
  const { data: rawData } = await Api.req.get(`/feed/publications/${publicationId}`);

  const data = normalize(rawData, publicationSchema);

  batch(() => {
    dispatch(userActions.add(data.entities.users || {}));
    dispatch({
      type: ADD_PUBLICATIONS,
      publications: (data.entities.publications || {}),
      comments: data.entities.comments,
    });

    if (prepend) {
      dispatch({ type: PREPEND_TO_FEED_QUEUE, ids: [data.result] });
    }
  });

  return data.entities.publications[data.result];
};

export const loadByAuthor = (authorId, offset = 0, onlyMedia, hashtag) => async (dispatch) => {
  const params = { authorId, offset, hashtag };
  if (onlyMedia) params.onlyMedia = true;

  const { data: rawData } = await Api.req.get('/feed/publications', {
    params,
  });
  const data = normalize(rawData, [publicationSchema]);

  batch(() => {
    dispatch(userActions.add(data.entities.users || {}));
    dispatch({
      type: ADD_PUBLICATIONS,
      publications: (data.entities.publications || {}),
      comments: data.entities.comments,
    });
  });

  return data.result;
};

export const publish = payload => async (dispatch) => {
  const { data: rawData } = await Api.req.post('/feed/publications', payload);

  const data = normalize({ ...rawData, author: rawData.authorId }, publicationSchema);

  batch(() => {
    dispatch({ type: ADD_PUBLICATIONS, publications: (data.entities.publications || {}) });
    dispatch({ type: PREPEND_TO_FEED_QUEUE, ids: [data.result] });
  });

  ReactGA.event({
    category: 'Publications',
    action: 'Create',
  });

  return data.entities.publications[data.result];
};

export const editPublication = (id, payload) => async (dispatch) => {
  const { data: rawData } = await Api.req.put(`/feed/publications/${id}`, payload);

  const data = normalize({ ...rawData, author: rawData.authorId }, publicationSchema);
  dispatch({ type: ADD_PUBLICATIONS, publications: (data.entities.publications || {}) });

  ReactGA.event({
    category: 'Publications',
    action: 'Edit',
  });

  return data.entities.publications[data.result];
};

export const vote = (publicationId, optionIndex) => async (dispatch) => {
  const { data: rawData } = await Api.req.post(`/feed/publications/${publicationId}/votes`, { optionIndex });

  const data = normalize({ ...rawData, author: rawData.authorId }, publicationSchema);
  dispatch({ type: ADD_PUBLICATIONS, publications: (data.entities.publications || {}) });

  ReactGA.event({
    category: 'Publications',
    action: 'Vote',
  });

  return data.entities.publications[data.result];
};

export const remove = publicationId => async (dispatch) => {
  await Api.req.delete(`/feed/publications/${publicationId}`);
  dispatch({ type: REMOVE_FROM_FEED, id: publicationId });

  ReactGA.event({
    category: 'Publications',
    action: 'Delete',
  });
};

export const report = (publicationId, reason, blockAndMuteUser) => async (dispatch) => {
  await Api.req.post(`/feed/pubreports/${publicationId}`, { reason });

  if (blockAndMuteUser) {
    dispatch(authActions.block(blockAndMuteUser));
    dispatch(authActions.muteInFeed(blockAndMuteUser));
  }

  ReactGA.event({
    category: 'Publications',
    action: 'Report ',
  });
};

export const addComment = (publicationId, payload) => async (dispatch) => {
  const { data: rawData } = await Api.req.post(`/feed/publications/${publicationId}/comments`, payload);
  const data = normalize(rawData, commentSchema);

  const { publication, authorId, ...comment } = data.entities.comments[data.result];

  dispatch({
    type: ADD_COMMENTS,
    data: {
      [data.result]: {
        ...comment,
        publication: publicationId,
        author: authorId,
      },
    },
    result: [data.result],
    publicationId,
  });

  ReactGA.event({
    category: 'Publications',
    action: 'Comment',
  });

  return data.entities.comments[data.result];
};

export const removeComment = (publicationId, commentId) => async (dispatch) => {
  await Api.req.delete(`/feed/publications/${publicationId}/comments/${commentId}`);
  dispatch({ type: REMOVE_COMMENT, publicationId, commentId });

  ReactGA.event({
    category: 'Publications',
    action: 'Delete Comment',
  });
};

export const createPublicationReaction = publicationId => async (dispatch) => {
  const { data: rawData } = await Api.req.post(`/feed/publications/${publicationId}/reactions`);

  const data = normalize(rawData, publicationSchema);
  dispatch({ type: ADD_PUBLICATIONS, publications: (data.entities.publications || {}) });

  ReactGA.event({
    category: 'Publications',
    action: 'Spank Created',
  });
};

export const deletePublicationReaction = publicationId => async (dispatch) => {
  const { data: rawData } = await Api.req.delete(`/feed/publications/${publicationId}/reactions`);

  const data = normalize(rawData, publicationSchema);
  dispatch({ type: ADD_PUBLICATIONS, publications: (data.entities.publications || {}) });

  ReactGA.event({
    category: 'Publications',
    action: 'Spank Removed',
  });
};

export const createCommentReaction = (publicationId, commentId) => async (dispatch) => {
  const { data: rawData } = await Api.req.post(`/feed/publications/${publicationId}/comments/${commentId}/reactions`);

  const data = normalize(rawData, commentSchema);
  const { publication, authorId, ...comment } = data.entities.comments[data.result];
  dispatch({
    type: ADD_COMMENTS,
    data: {
      [data.result]: {
        ...comment,
        publication: publicationId,
        author: authorId,
      },
    },
    result: [data.result],
    publicationId,
  });

  ReactGA.event({
    category: 'Publications',
    action: 'Comment Spank Created',
  });
};

export const deleteCommentReaction = (publicationId, commentId) => async (dispatch) => {
  const { data: rawData } = await Api.req.delete(`/feed/publications/${publicationId}/comments/${commentId}/reactions`);

  const data = normalize(rawData, commentSchema);
  const { publication, authorId, ...comment } = data.entities.comments[data.result];
  dispatch({
    type: ADD_COMMENTS,
    data: {
      [data.result]: {
        ...comment,
        publication: publicationId,
        author: authorId,
      },
    },
    result: [data.result],
    publicationId,
  });

  ReactGA.event({
    category: 'Publications',
    action: 'Comment Spank Removed',
  });
};

export const flushQueue = () => (dispatch) => {
  dispatch({ type: FLUSH_FEED_QUEUE });
};

export const handleUpdate = rawData => (dispatch, getState) => {
  // Check if the publication being updated is loaded - If not, do nothing
  const isLoaded = !!getState().feed.publications[rawData.id];

  if (isLoaded) {
    const data = normalize(rawData, publicationSchema);

    dispatch({
      type: ADD_PUBLICATIONS,
      publications: (data.entities.publications || {}),
      comments: data.entities.comments,
    });
  }
};

export const fetchLatestHashtags = () => async (dispatch, getState) => {
  const { latestHashtagsFetchedAt } = getState().feed;

  if (!latestHashtagsFetchedAt || moment(latestHashtagsFetchedAt).add(5, 'minutes').isBefore(new Date())) {
    const { data } = await Api.req.get('/hashtags');
    dispatch({ type: SET_LATEST_HASHTAGS, data });
  }
};

export const handleNewPublication = rawData => (dispatch) => {
  dispatch(loadPublication(rawData.id, true));
};

export const handlePublicationRemoved = ({ publicationId }) => (dispatch) => {
  dispatch({ type: REMOVE_FROM_FEED, id: publicationId });
};

export const handlePollUpdated = ({ payload, publicationId }) => (dispatch) => {
  dispatch({ type: POLL_UPDATED, payload, publicationId });
};

export const toggleFollowPublication = publicationId => async (dispatch, getState) => {
  const { following } = getState().feed.publications[publicationId];
  const method = following ? 'delete' : 'post';
  const { data: rawData } = await Api.req[method](`/feed/publications/${publicationId}/follow`);
  dispatch(handleUpdate(rawData));
};

export const toggleBlockedComments = publicationId => async (dispatch, getState) => {
  const { commentsBlocked } = getState().feed.publications[publicationId];
  const { data: rawData } = await Api.req.put(`/feed/publications/${publicationId}/commentsBlocked`, { blocked: !commentsBlocked });
  dispatch(handleUpdate(rawData));
};

export const loadCollections = userId => async () => Api.req.get(`/feed/collections/${userId}`);

export const createCollection = hashtag => async () => Api.req.post('/feed/collections', { hashtag });

export const sortCollections = collections => async () => Api.req.put('/feed/collections', { collections });

export const removeCollection = collectionId => async () => Api.req.delete(`/feed/collections/${collectionId}`);

export const wipe = () => dispatch => dispatch({ type: WIPE });

export const setScroll = scrollTop => dispatch => dispatch({ type: SET_SCROLL, scrollTop });
