import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div.attrs({
  className: 'modal-layout',
})`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => props.zIndex};
  justify-content: center;
  align-items: center;
  display: none;

  ${props => props.backgroundBlur && `
    backdrop-filter: saturate(180%) blur(20px);
  `}
`;

const WrapperActive = styled(Wrapper)`
  display: flex;
`;

const ModalOpacity = ({
  children, show, onClick, backgroundBlur, zIndex,
}) => {
  const WrapperComponent = show ? WrapperActive : Wrapper;
  return (
    <WrapperComponent onClick={onClick} backgroundBlur={backgroundBlur} zIndex={zIndex}>
      {children}
    </WrapperComponent>
  );
};

ModalOpacity.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func,
  backgroundBlur: PropTypes.bool,
  zIndex: PropTypes.number.isRequired,
};

ModalOpacity.defaultProps = {
  onClick: null,
  children: null,
  backgroundBlur: false,
};

export default ModalOpacity;
