import { FC } from 'react';
import { useSelector } from 'react-redux';

import * as bankSelectors from 'state/bank/selectors';

interface Props {}

const CouponNotification: FC<Props> = () => {
  const hasCoupons = useSelector(bankSelectors.hasCoupons);

  if (!hasCoupons) return null;
  return <div className="notification" />;
};

export default CouponNotification;
