import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';
import * as communitySelectors from 'state/communities/selectors';
import * as communityActions from 'state/communities/actions';

import CommunityHeader from 'containers/Communities/Header';
import Loading from 'components/Loading';
import { SelectableList } from 'components/SelectableList';
import EmptyState from 'components/EmptyState';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import {
  FlexWrapper,
  FlexInnerWrapper,
  FlexContainer,
  ActionsFooter,
} from 'components/FlexWrapper';

import locales from './i18n';
import Request from './Request';

const Pending = ({ communityId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const slug = useSelector(state => communitySelectors.selectSlugById(state, communityId));

  const [fetching, setFetching] = useState(false);
  const [pending, setPending] = useState([]);
  const [working, setWorking] = useState([]);

  useEffect(() => {
    const fetchPending = async () => {
      try {
        setFetching(true);
        const data = await dispatch(communityActions.fetchUnapprovedMemberships(slug));
        setFetching(false);
        setPending(data);
      } catch (e) {
        dispatch(appActions.addError(e));
        setFetching(false);
      }
    };

    fetchPending();
  }, [dispatch, slug]);

  const approve = useCallback(async (membershipId) => {
    try {
      setWorking([
        ...working,
        membershipId,
      ]);
      await dispatch(communityActions.approveMembership(communityId, membershipId));
      setPending(pending.filter(({ _id: id }) => id !== membershipId));
      dispatch(appActions.addToast(t('Membership approved')));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    setWorking(working.filter(id => id !== membershipId));
  }, [dispatch, working, communityId, pending, t]);

  const reject = useCallback(async (membershipId) => {
    try {
      setWorking([
        ...working,
        membershipId,
      ]);
      await dispatch(communityActions.rejectMembership(communityId, membershipId));
      setPending(pending.filter(({ _id: id }) => id !== membershipId));
      dispatch(appActions.addToast(t('Membership rejected')));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    setWorking(working.filter(id => id !== membershipId));
  }, [dispatch, working, communityId, pending, t]);

  if (fetching) return <Loading />;
  if (pending.length === 0) {
    return (
      <FlexWrapper>
        <CommunityHeader communityId={communityId} />

        <EmptyState
          title={t('Currently there are no pending requests for this community')}
          subtitle={(
            <Button className="empty" to={`/+${slug}`}>{t('global:Back')}</Button>
          )}
        />
      </FlexWrapper>
    );
  }

  return (
    <>
      <FlexWrapper fullHeight mobileHasHeader>
        <FlexInnerWrapper>
          <CommunityHeader communityId={communityId} />
          <PageTitle>{t('Pending')}</PageTitle>

          <FlexContainer framed>
            <SelectableList>
              {pending.map(({ _id: membershipId, user }) => (
                <Request
                  key={`community-pending-${membershipId}`}
                  userId={user}
                  membershipId={membershipId}
                  approve={approve}
                  reject={reject}
                  working={working.includes(membershipId)}
                />
              ))}
            </SelectableList>
          </FlexContainer>
          <ActionsFooter>
            <div>
              <Button className="empty" to={`/+${slug}`}>{t('global:Back')}</Button>
            </div>
            <div />
          </ActionsFooter>
        </FlexInnerWrapper>
      </FlexWrapper>
    </>
  );
};

Pending.propTypes = {
  communityId: PropTypes.string.isRequired,
};

Pending.defaultProps = {
};

export default Pending;
