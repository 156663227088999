import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const FilterVariant = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z"
      fill={color}
    />
  </svg>
);

FilterVariant.propTypes = IconType;
FilterVariant.defaultProps = IconDefaults;

export default memo(FilterVariant);
