import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Channel } from 'types';

import * as authSelectors from 'state/auth/selectors';
import * as channelSelectors from 'state/channels/selectors';
import * as appActions from 'state/app/actions';
import * as channelsActions from 'state/channels/actions';

import { useTranslation } from 'hooks';

import Button from 'components/Button';

import locales from './i18n';

interface Props {
  channel: Channel;
  full?: boolean;
  style?: React.CSSProperties;
}

const ChannelMembershipRequestButton: React.FC<Props> = ({ channel, full, style }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(locales);
  // @ts-ignore
  const isUserLoggedIn = useSelector(authSelectors.loggedIn);
  // @ts-ignore
  const isUserSubscribed = useSelector(state => channelSelectors.amISubscribed(state, channel.id));

  const [joining, setJoining] = useState(false);

  const isFull = typeof full === 'undefined' ? true : full;

  if (isUserSubscribed) {
    return <Button to={`/chat/channels/${channel.id}`} color="white" fontColor="#666" full={isFull} style={style}>{t('Already a member')}</Button>;
  }

  const join = async () => {
    if (isUserLoggedIn) {
      try {
        setJoining(true);
        await dispatch(channelsActions.join(channel.id));
        dispatch(appActions.addToast(t('You have joined the channel')));
        history.push(`/chat/channels/${channel.id}`);
      } catch (error) {
        dispatch(appActions.addError(error));
        setJoining(false);
      }
    } else {
      history.push(`/chat/channels/${channel.id}`);
    }
  };

  return <Button onClick={join} loading={joining} full={isFull} style={style}>{t('Join')}</Button>;
};

export default ChannelMembershipRequestButton;
