import { createSelector } from 'reselect';
import getUrls from 'get-urls';
import ReactPlayer from 'react-player';
import moment from 'moment';

// Input selectors
const selectData = state => state.replies.data;
//

export const selectById = createSelector(
  selectData,
  (_, id) => id,
  (data, id) => data[id],
);

export const get = id => ({ replies: state }) => state.data[id];

export const getById = id => ({ replies: state }) => state.data[id];

export const getByThreadId = threadId => ({ replies: state }) => {
  if (!state.threads[threadId]) return null;
  return state.threads[threadId].map(id => state.data[id]);
};

export const getIdsForThread = threadId => ({ replies: state }) => state.threads[threadId] || [];

export const isLoading = id => ({ replies: state }) => state.loading.includes(id);

export const isFullyLoaded = id => ({ replies: state }) => state.fullyLoaded.includes(id);

export const getFirstLoadedReplyDate = threadId => (state) => {
  const ids = getByThreadId(threadId)(state);
  if (!ids || !ids.length) return null;
  return ids[0].createdAt;
};

export const getNotLoadedCount = threadId => (state) => {
  const thread = state.threads.data[threadId];
  if (!thread) return 0;
  const total = thread.replyCount;

  const ids = getByThreadId(threadId)(state);
  if (!ids) return 0;
  const loaded = ids.length;

  return total - loaded;
};

export const getCommunityId = replyId => ({ replies, threads, communities }) => {
  const reply = replies.data[replyId];
  const thread = threads.data[reply.thread];
  const community = communities.data[thread.community];
  return community.id;
};

export const getCommunitySlug = replyId => ({ replies, threads, communities }) => {
  const reply = replies.data[replyId];
  const thread = threads.data[reply.thread];
  const community = communities.data[thread.community];
  return community.slug;
};

export const getAuthorId = replyId => ({ replies }) => replies.data[replyId].author;

export const getCreatedAt = replyId => ({ replies }) => replies.data[replyId].createdAt;

export const isDeleted = replyId => ({ replies }) => {
  const reply = replies.data[replyId];
  if (!reply) return false;
  return !!reply.deletedAt;
};

export const getContentForEditor = replyId => ({ replies }) => {
  const reply = replies.data[replyId];
  if (!reply) return null;
  return reply.editorContent;
};

export const getContent = replyId => ({ replies }) => {
  const reply = replies.data[replyId];
  if (!reply) return null;
  return reply.content;
};

export const hasOldContent = replyId => ({ replies }) => {
  const reply = replies.data[replyId];
  if (!reply) return false;
  return !!reply.content;
};

export const getRawContent = replyId => ({ replies }) => {
  const reply = replies.data[replyId];
  if (!reply) return null;
  return reply.rawContent;
};

export const getMedia = replyId => ({ replies }) => {
  const reply = replies.data[replyId];
  if (!reply) return null;
  return reply.media;
};

export const getVideoUrls = replyId => ({ replies }) => {
  const reply = replies.data[replyId];
  if (!reply) return null;
  return [...getUrls(reply.rawContent || '')].filter(url => ReactPlayer.canPlay(url));
};

export const isLikedByUser = replyId => ({ replies, auth }) => {
  const reply = replies.data[replyId];
  if (!reply || !auth.me) return false;

  return reply.reactedByUserIds.some(uId => uId === auth.me.id);
};

export const isDislikedByUser = replyId => ({ replies, auth }) => {
  const reply = replies.data[replyId];
  if (!reply || !reply.dislikedByUserIds || !auth.me) return false;

  return reply.dislikedByUserIds.some(uId => uId === auth.me.id);
};

export const getReactionsCount = replyId => ({ replies }) => {
  const reply = replies.data[replyId];
  if (!reply) return 0;

  return reply.reactedByUserIds.length - reply.dislikedByUserIds.length;
};

export const getLikesCount = replyId => ({ replies }) => {
  const reply = replies.data[replyId];
  if (!reply) return 0;

  return reply.reactedByUserIds.length;
};

export const getDislikesCount = replyId => ({ replies }) => {
  const reply = replies.data[replyId];
  if (!reply || !reply.dislikedByUserIds) return 0;

  return reply.dislikedByUserIds.length;
};

export const selectReactionsList = createSelector(
  selectById,
  reply => reply?.reactedByUserIds || [],
);

export const selectDislikesList = createSelector(
  selectById,
  reply => reply?.dislikedByUserIds || [],
);

export const getAwards = createSelector(
  selectById,
  reply => reply?.awards || [],
);

export const isUnread = replyId => (state) => {
  const reply = state.replies.data[replyId];
  const thread = state.threads.data[reply.thread];

  return moment(reply.createdAt).diff(thread.prevReadAt) > 0;
};
