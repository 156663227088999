import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';
import * as channelActions from 'state/channels/actions';

import Loading from 'components/Loading';
import TransferModal from 'components/TransferButton/TransferModal';

const Wrapper = styled.div`
  width: 40%;

  iframe {
    height: 100%;
    width: 100%;
  }

  @media(max-width: 767px) {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 52; // Above user badges and chat banners.
    pointer-events: none;

    iframe {
      position: absolute;
      width: 80%;
      top: 0;
      right: 0;
      transition: transform .7s, opacity .7s ease-in-out;
      transform: translateX(0);
      pointer-events: initial;
    }

    ${({ isMobileFrameShown }) => !isMobileFrameShown && `
      iframe {
        opacity: 50%
        transform: translateX(90%);
      }
    `}
  }
`;

const OpenCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: blue;
  z-index: 5;
  pointer-events: initial;
  background-color: ${props => props.theme.colors.main};
  color: ${props => props.theme.colors.mainLight};
  font-weight: 500;
  font-size: 18px;
  border: 0;
  border-radius: 6px;

  @media(min-width: 767px) {
    display: none;
  }
`;
const FrameBackdrop = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: initial;

  @media(min-width: 767px) {
    display: none;
  }
`;
const FramePeekOpener = styled.div`
  z-index: 2;
  width: 10%;
  height: 100%;
  position: absolute;
  pointer-events: initial;
  right: 0;
  top: 0;

  @media(min-width: 767px) {
    display: none;
  }
`;

Wrapper.displayName = 'Wrapper';

const Embed = ({ channelId }) => {
  const dispatch = useDispatch();

  const hasEmbed = useSelector(state => channelSelectors.hasEmbed(state, channelId));
  const embedUrl = useSelector(state => channelSelectors.getEmbedUrl(state, channelId));
  const hasEmbedToken = useSelector(state => channelSelectors.hasEmbedToken(state, channelId));
  const token = useSelector(state => channelSelectors.getEmbedToken(state, channelId));
  const [isMobileFrameShown, setMobileFrameShown] = useState(false);
  const [transferModalData, setTransferModalData] = useState({});


  const toggleMobileFrame = useCallback(
    () => setMobileFrameShown(currentValue => !currentValue),
    [],
  );
  const showMobileFrame = useCallback(() => setMobileFrameShown(true), []);
  const hideMobileFrame = useCallback(() => setMobileFrameShown(false), []);

  const hideTransferModal = () => {
    setTransferModalData({ isOpen: false });
  };

  useEffect(() => {
    if (hasEmbed && hasEmbedToken && !token) {
      dispatch(channelActions.getEmbed(channelId));
    }
  }, [channelId, hasEmbedToken, hasEmbed, token, dispatch]);

  useEffect(() => {
    const handleWindowMessage = (event) => {
      const eventData = JSON.parse(event.data);
      if (eventData && eventData.amount) {
        setTransferModalData({
          isOpen: true,
          afterTransfer: () => event.source.postMessage('transfer.complete', event.origin),
          ...eventData,
        });
      }
    };

    if (hasEmbed) {
      window.addEventListener('message', handleWindowMessage);
    }
    return () => {
      window.removeEventListener('message', handleWindowMessage);
    };
  }, [hasEmbed]);

  if (!hasEmbed) return null;

  const [url, querystring] = embedUrl.split('?');
  const qObj = qs.parse(querystring);
  if (hasEmbedToken && token) qObj.token = token;
  const src = Object.keys(qObj).length > 0 ? `${url}?${qs.stringify(qObj)}` : url;

  const { to: transferTo, afterTransfer, ...transferData } = transferModalData;
  return (
    <>
      {transferModalData && transferModalData.isOpen && (
        <TransferModal
          to={transferTo}
          data={transferData}
          close={hideTransferModal}
          afterTransfer={afterTransfer}
        />
      )}
      <Wrapper isMobileFrameShown={isMobileFrameShown}>
        {/* Just for mobile */}
        <OpenCloseButton onClick={toggleMobileFrame}>
          {isMobileFrameShown ? 'Cerrar' : 'Abrir'}
          <br />
          Frame
        </OpenCloseButton>
        {isMobileFrameShown
          ? <FrameBackdrop onClick={hideMobileFrame} />
          : <FramePeekOpener onClick={showMobileFrame} />
        }
        {/* Just for mobile */}

        {(hasEmbedToken && !token)
          ? <Loading />
          : <iframe src={src} frameBorder="0" title={embedUrl} onLoad={showMobileFrame} />
        }
      </Wrapper>
    </>
  );
};

Embed.propTypes = {
  channelId: PropTypes.string.isRequired,
};

Embed.defaultProps = {
};

export default Embed;
