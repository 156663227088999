import { memo, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { MagnifyPlus } from 'components/Icons';
import FloatingButton from 'components/Button/Floating';

const ExploreCreateButton = () => {
  const theme = useContext(ThemeContext);

  return (
    <FloatingButton
      to="/chat/channels"
      color={theme.colors.mainBackground}
      distance="16px"
      negative
    >
      <MagnifyPlus outline color={theme.colors.secondary} />
    </FloatingButton>
  );
};

ExploreCreateButton.propTypes = {
};

ExploreCreateButton.defaultProps = {
};

export default memo(ExploreCreateButton);
