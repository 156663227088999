import styled from 'styled-components';

import colors from 'utils/css/colors';

const CreateText = styled.span`
  color: ${colors.blackRed};
  font-size: 20px;
  margin: 0 0 16px 0;
  @media(min-width: 768px) {
    margin: 0 16px 0 0;
  }
`;

export default CreateText;
