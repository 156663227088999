import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { UserX } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'hooks';

import locales from '../../../i18n';
import BansModal from '../../Modals/BansModal';

const ViewBansAction: React.FC = () => {
  const { t } = useTranslation(locales);
  const { chatId: channelId } = useParams<{ chatId: string }>();

  const { isOpen: isBansModalOpen, onOpen: openBansModal, onClose: closeBansModal } = useDisclosure();

  return (
    <>
      <MenuItem icon={<UserX size={16} />} onClick={openBansModal}>{t('View bans')}</MenuItem>

      {isBansModalOpen && (
        <BansModal channelId={channelId} close={closeBansModal} />
      )}
    </>
  );
};

export default ViewBansAction;
