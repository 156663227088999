import { HStack, Skeleton, Stack } from '@chakra-ui/react';

interface Props {
}

const UserListSkeleton: React.FC<Props> = () => {
  return (
    <Stack>
      {Array.from({ length: 10 }).map(() => {
        const randomDisplaynameLength = Math.floor(Math.random() * (25 - 6 + 1)) + 6;
        const randomDisplaynameString = Math.random().toString(36).substring(2, 2 + randomDisplaynameLength);

        const randomUsernameLength = Math.floor(Math.random() * (25 - 6 + 1)) + 6;
        const randomUsernameString = Math.random().toString(36).substring(2, 2 + randomUsernameLength);

        return (
          <HStack spacing={2} key={randomDisplaynameString}>
            <Skeleton height="35px" width="35px" rounded="full" />
            <Stack spacing={1}>
              <Skeleton height="18px">{randomDisplaynameString}</Skeleton>
              <Skeleton height="14px">{randomUsernameString}</Skeleton>
            </Stack>
          </HStack>
        );
      })}
    </Stack>
  );
};

export default UserListSkeleton;
