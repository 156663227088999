import { useEffect, useRef } from 'react';
import {
  Box, Button, HStack, useDisclosure,
} from '@chakra-ui/react';

import { useTranslation } from 'hooks';

import SignUpModal from 'components/SignUpModal';

import locales from './i18n';
import ChecklistForm, { ChecklistItem } from '../Checklist/ChecklistForm';
import Progress from '../Checklist/Progress';
import LoadingChecklistContent from '../Checklist/LoadingChecklistContent';
import { useChecklistForm } from '../Checklist/useChecklistForm';

interface Props {
}

const ContentNonUser: React.FC<Props> = () => {
  const { t } = useTranslation(locales);
  const formRef = useRef<HTMLFormElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    form: {
      handleSubmit, register, watch, formState: {
        isDirty, isLoading, isSubmitting, isSubmitSuccessful,
      },
    },
    checklistStructure,
    typeFilters,
    // setTypeFilters,
    isLoadingStructure,
    onSubmit,
  } = useChecklistForm();

  useEffect(() => {
    if (isSubmitSuccessful) window.location.href = '/kinkytest';
  }, [isSubmitSuccessful]);

  const afterSignup = () => {
    formRef.current?.requestSubmit();
    onClose();
  };

  if (isLoadingStructure || !checklistStructure || isLoading) return <LoadingChecklistContent />;

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <ChecklistForm
          checklistStructure={checklistStructure}
          itemFilter={(item: ChecklistItem) => typeFilters.includes(item.type) && item.inShortVersion}
          register={register}
        />

        <HStack justifyContent="space-between" position="sticky" bottom={0} bgColor="white" py={4}>
          <Progress watch={watch} />
          <Button variant="primary" onClick={onOpen} isDisabled={!isDirty} isLoading={isSubmitting}>
            {t('global:Save')}
          </Button>
        </HStack>
      </form>

      <SignUpModal isOpen={isOpen} onClose={onClose} afterSignup={afterSignup} />
    </Box>
  );
};

export default ContentNonUser;
