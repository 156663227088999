import styled from 'styled-components';

const List = styled.ul`
  li a {
    display: flex;
    margin-bottom: 8px;
    align-items: center;

    &:hover {
      color: ${props => props.theme.colors.main};
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      margin-right: 8px;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;
List.displayName = 'List';

export default List;
