import { memo, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';
import * as threadSelectors from 'state/threads/selectors';
import * as appActions from 'state/app/actions';
import * as threadActions from 'state/threads/actions';

import { GUEST_MAX_THREADS } from '../../../constants';
import Loading from './Reply/Loading';
import Content from './Content';
import NotFound from './NotFound';
import LurkerModal from './LurkerModal';

const Thread = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();

  const url = '/+:communitySlug/:threadSlug';
  const match = useRouteMatch(url);

  const threadSlug = match ? match.params.threadSlug : null;
  const threadId = useSelector(threadSelectors.getIdBySlug(threadSlug));
  const isUserLoggedIn = useSelector(authSelectors.loggedIn);
  const isNotFound = useSelector(threadSelectors.slugIsNotFound(threadSlug));
  const isLoading = useSelector(threadSelectors.slugIsLoading(threadSlug));

  useEffect(() => {
    (async () => {
      dispatch(appActions.uiRightColumn(!!match));

      if (match && !threadId && !isLoading && !isNotFound) {
        const fullUrl = await dispatch(threadActions.loadOne(match.params.communitySlug, match.params.threadSlug));
        history.replace(fullUrl);
      }
    })();
  }, [dispatch, match, threadId, isLoading, isNotFound, history]);

  useEffect(() => {
    if (!isUserLoggedIn) {
      const threadsRead = Cookies.get('threadsRead') || 0;
      Cookies.set('threadsRead', parseInt(threadsRead, 10) + 1, { expires: 1 });
    }
  }, [isUserLoggedIn, threadId]);

  if (isNotFound) return <NotFound />;

  if (!match || !match.params.threadSlug || isLoading || !threadId) {
    return <Loading />;
  }

  const showLurkerModal = !isUserLoggedIn
    && parseInt(Cookies.get('threadsRead'), 10) === GUEST_MAX_THREADS
    && !/bot|crawler|spider|crawling/i.test(navigator.userAgent);

  return (
    <>
      <Content />
      {showLurkerModal && <LurkerModal />}
    </>
  );
});

Thread.propTypes = {
};

Thread.defaultProps = {
};

export default Thread;
