import { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';
import * as userSelectors from 'state/users/selectors';
import * as authActions from 'state/auth/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Warning from 'components/Warning';

import locales from '../../i18n';

const KnowModal = ({ userId, close }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const pronoun = useSelector(userSelectors.getPronoun(userId));

  const onConfirm = async () => {
    try {
      setLoading(true);
      await dispatch(authActions.know(userId));
      dispatch(appActions.addToast(t('Added as known')));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    close();
  };

  return (
    <Modal
      title={t('Mark as known', { context: pronoun })}
      onCancel={close}
      actions={[(
        <Button
          key="know-confirm"
          onClick={onConfirm}
          loading={loading}
        >
          {t('global:Confirm')}
        </Button>
      )]}
    >
      <Trans t={t} i18nKey="know.confirm" ns="Profiles">
        Do you confirm you know this person
        {' '}
        <b>in real life</b>
        ?
      </Trans>

      <Warning>
        <Trans t={t} i18nKey="know.warning" ns="Profiles">
          Real life means without any device acting as a communication channel.
          {' '}
          Just old fashioned face to face.

          <br />
          <br />

          When clicking in
          {' '}
          <i>Confirm</i>
          {' '}
          you testify that this person exists, is who they says to be,
          {' '}
          and you witness that away from keyboard.
          If this happens to be untrue
          {' '}
          <b>you will be sanctioned</b>
          .
        </Trans>
      </Warning>
    </Modal>
  );
};

KnowModal.propTypes = {
  userId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};

KnowModal.defaultProps = {
};

export default KnowModal;
