import { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as appActions from 'state/app/actions';
import * as communityActions from 'state/communities/actions';

import { useTranslation } from 'hooks';

import Button from 'components/Button';
import CommunityForm from 'components/CommunityForm';

import locales from './i18n';

const Wrapper = styled.form`
  background-color: white;
  width: 100%;
  padding: 20px;
  border-radius: 16px;
  box-sizing: border-box;
  @media(min-width: 992px) {
    padding: 48px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Form = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(locales);

  const [data, setData] = useState(null);
  const [creating, setCreating] = useState(false);

  const onSubmitClick = async () => {
    try {
      setCreating(true);
      const community = await dispatch(communityActions.create(data));

      dispatch(appActions.addToast(t('Community succesfully created!')));
      history.push(`/+${community.slug}`);
    } catch (error) {
      dispatch(appActions.addError(error));
      setCreating(false);
    }
  };

  const onCancel = () => {
    history.push('/communities/explore');
  };

  return (
    <Wrapper>
      <CommunityForm onDataChange={setData} />
      <ButtonsWrapper>
        <Button
          className="empty"
          onClick={onCancel}
        >
          {t('global:Cancel')}
        </Button>
        <Button
          onClick={onSubmitClick}
          loading={creating}
        >
          {t('Create community')}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};

Form.propTypes = {
};

export default Form;
