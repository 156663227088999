import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';

import MessengerContent from './MessengerContent';
import ChannelExplore from './ChannelExplore';
import ChannelCreate from './ChannelCreate';
import ChannelContent from './ChannelContent';
import NoSelection from './NoSelection';

const DashboardContent = () => {
  const { type, chatId } = useParams();
  const userIsLoggedIn = useSelector(authSelectors.loggedIn);

  if (type === 'messengers' && chatId) return <MessengerContent key={`messenger-content-${chatId}`} id={chatId} />;
  if (type === 'channels' && !chatId) return <ChannelExplore />;
  if (type === 'channels' && chatId === 'create') return <ChannelCreate />;
  if (type === 'channels' && chatId) return <ChannelContent key={`chat-content-${chatId}`} channelId={chatId} />;
  if (!userIsLoggedIn) return <ChannelExplore />;

  return <NoSelection />;
};

DashboardContent.propTypes = {
};

export default memo(DashboardContent);
