import styled from 'styled-components';

const Box = styled.form`
  background-color: white;
  width: 100%;
  padding: 20px;
  border-radius: 16px;
  box-sizing: border-box;
  @media(min-width: 992px) {
    padding: 48px;
  }
`;

export default Box;
