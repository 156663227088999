import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GiphyFetch } from '@giphy/js-fetch-api';

import colors from 'utils/css/colors';
import { useInputValue, useTranslation, useFocusOnMount } from 'hooks';

import Loading from 'components/Loading';
import InputSearch from 'components/Forms/InputSearch';

import { GIPHY_API_KEY } from '../../constants';
import giphy from './giphy.png';
import locales from './i18n';

const gf = new GiphyFetch(GIPHY_API_KEY);

const Overlay = styled.div<React.HTMLProps<HTMLDivElement>>`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

const Wrapper = styled.div`
  position: fixed;
  background: white;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 50%;
  max-height: 75%;
  box-shadow: 0px 3px 12px 0px black;
  z-index: 1;
  display: flex;
  flex-direction: column;
  z-index: 110;
`;

const InputWrapper = styled.div`
  text-align: right;
  padding: 10px;

  img {
    width: 120px;
  }
`;

const GifResults = styled.div`
  column-count: 4;
  column-gap: 15px;
  column-fill: auto;
  padding: 10px;
  overflow: auto;

  @media(max-width: 768px) {
    column-count: 2;
    padding: 0;
  }
`;

const Image = styled.div<React.HTMLProps<HTMLImageElement>>`
  display: block;
  padding: 10px;
  word-wrap: break-word;
  margin-bottom: 10px;
  -webkit-column-break-inside: avoid;
  cursor: pointer;

  &:hover {
    background-color: ${colors.borderRed};
  }

  img {
    width: 100%;
    height: auto;
  }
`;

interface Gif {
  id: string;
  title: string;
  images: {
    preview_gif: {
      url: string;
      width: number;
      height: number;
    };
  };
}

interface Props {
  onSelect: (gif: Gif) => void;
  close: () => void;
}

const GifSearch = ({ onSelect, close }: Props) => {
  const { t } = useTranslation(locales);
  const searchEl = useFocusOnMount();

  const [gifs, setGifs] = useState<Gif[]>([]);
  const [searching, setSearching] = useState(false);
  const search = useInputValue('');

  const onKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearching(true);
      const { data } = await gf.search(search.value, { limit: 25, rating: 'r' });
      setSearching(false);
      // @ts-ignore
      search.onChange({ target: { value: '' } });
      setGifs(data as Gif[]);
    }
  };

  const selectGif = (index: number) => () => onSelect(gifs[index]);

  return (
    <>
      <Overlay onClick={close} />
      <Wrapper>
        <InputWrapper>
          {/* @ts-ignore */}
          <InputSearch placeholder={t('global:Search')} {...search} onKeyPress={onKeyPress} ref={searchEl} />
          <img src={giphy} alt="Powered by giphy" />
        </InputWrapper>
        {searching
          ? <Loading />
          : (
            <GifResults>
              {gifs.map((gif, index) => (
                <Image key={gif.id} onClick={selectGif(index)}>
                  <img
                    src={gif.images.preview_gif.url}
                    alt={gif.title}
                    width={gif.images.preview_gif.width}
                    height={gif.images.preview_gif.height}
                  />
                </Image>
              ))}
            </GifResults>
          )
        }
      </Wrapper>
    </>
  );
};

GifSearch.propTypes = {
  onSelect: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default GifSearch;
