import { useDispatch } from 'react-redux';

import * as authActions from 'state/auth/actions';

import { useOnMount } from 'hooks';

const Logout = () => {
  const dispatch = useDispatch();

  useOnMount(() => {
    dispatch(authActions.logout());
  });

  return (
    <div>
      Please wait...
    </div>
  );
};

export default Logout;
