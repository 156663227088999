import { schema } from 'normalizr';

import user from 'state/users/schema';

const alert = new schema.Entity('alerts', {
  payload: {
    author: user,
    user,
    authors: [user],
    community: {
      founder: user,
    },
  },
});

export default alert;
