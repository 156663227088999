import styled from 'styled-components';

const Info = styled.div.attrs({
  className: 'message-info',
})`
  color: #666;
  font-size: 11px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;

  ${props => !props.hasReactions && `
    margin-top: -8px;
  `}

  svg {
    width: 16px;
    height: 16px;
  }

  > div:first-child {
    display: flex;

    ${props => !props.hasReactions && `
      visibility: hidden;
    `}

    button:not(.emoji-mart-anchor) {
      background: none;
      border: 1px solid transparent;
      border-radius: 8px;
      outline: none;
      cursor: pointer;
      background-color: rgba(255, 255, 255, .3);
      margin-right: 4px;
      font-size: 12px;
      display: flex;
      align-items: center;
      min-width: 38px;
      justify-content: center;
      padding: 2px 0;

      &:hover, &.active {
        border-color: #ec4e3e5c;
        background-color: rgba(255, 255, 255, .5);

        span {
          color: ${props => props.theme.colors.main};
        }
      }

      span {
        margin: 0 1px;
      }
    }
  }

  > div:last-child {
    display: flex;
    align-items: flex-end;

    svg {
      margin-left: 5px;
      margin-top: -3px;
    }
  }
`;
Info.displayName = 'BubbleInfo';

export default Info;
