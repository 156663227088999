import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';
import * as channelActions from 'state/channels/actions';
import * as appActions from 'state/app/actions';

import Modal from 'components/Modal';
import { SelectableList } from 'components/SelectableList';

import UserItem from '../UserItem';
import locales from '../../i18n';

const BansModal = ({ channelId, close }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const bans = useSelector(
    state => channelSelectors.getBansByChannelId(state, channelId),
    shallowEqual,
  );
  const isMod = useSelector(state => channelSelectors.isModOfChannel(state, channelId));

  const onDelete = async (userId) => {
    await dispatch(channelActions.unban(channelId, userId));
    dispatch(appActions.addToast(t('Ban removed')));
  };

  return (
    <Modal
      title={t('Bans')}
      onClose={close}
    >
      <SelectableList>
        {bans.map(p => (
          <UserItem
            key={`mod-user-${p.userId}`}
            userId={p.userId}
            onDelete={isMod ? onDelete : null}
          />
        ))}
      </SelectableList>
    </Modal>
  );
};

BansModal.propTypes = {
  channelId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default BansModal;
