import { useState } from 'react';
import styled from 'styled-components';

import useOnMount from 'hooks/useOnMount';
// import useTranslation from 'hooks/useTranslation';

// import locales from './i18n';

const INCLUSIVE_MILISECONDS = 500;

const WelcomeComponent = styled.span`
  display: inline-block;
  width: 350px;

  @media(max-width: 767px) {
    width: 100%;
  }
`;

const Welcome = () => {
  // const { t, i18n } = useTranslation(locales);

  const inclusiveLetters = ['a', 'o', 'x', 'e', '*', '@'];
  const [inclusiveIndex, setInclusiveIndex] = useState(0);

  const inclusiveLetter = inclusiveLetters[inclusiveIndex];

  const nextInclusiveIndex = () => {
    setInclusiveIndex(current => (current + 1) % inclusiveLetters.length);
  };

  useOnMount(() => {
    const inclusiveInterval = setInterval(nextInclusiveIndex, INCLUSIVE_MILISECONDS);

    return () => clearInterval(inclusiveInterval);
  });

  // if (i18n.language.substring(0, 2) !== 'es') return t('Welcome');

  return (
    <WelcomeComponent>
      Bienvenid
      {inclusiveLetter}
    </WelcomeComponent>
  );
};

Welcome.propTypes = {
};

Welcome.defaultProps = {
};

export default Welcome;
