import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appSelectors from 'state/app/selectors';

import AudioButton from './AudioButton';
import SubmitButton from './SubmitButton';
import locales from '../../i18n';

const ActionButton = ({ type, id }) => {
  const { t } = useTranslation(locales);

  const key = `${type}-${id}`;
  const hasContent = useSelector(state => appSelectors.selectComposerHasContent(state, key));

  if (type === 'messenger' && !hasContent) return <AudioButton messengerId={id} />;
  return <SubmitButton id={key} text={t('global:Send')} />;
};

ActionButton.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['channel', 'messenger']).isRequired,
};

ActionButton.defaultProps = {
};

export default ActionButton;
