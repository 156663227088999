export const LOGIN = 'mazmo/auth/LOGIN';
export const LOGIN_SUCCESS = 'mazmo/auth/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'mazmo/auth/LOGIN_FAIL';

export const LOGOUT = 'mazmo/auth/LOGOUT';

export const EMAIL_CONFIRMED = 'mazmo/auth/EMAIL_CONFIRMED';

export const ADD_TO_FOLLOW_LIST = 'mazmo/auth/ADD_TO_FOLLOW_LIST';
export const REMOVE_FROM_FOLLOW_LIST = 'mazmo/auth/REMOVE_FROM_FOLLOW_LIST';
export const REMOVE_FROM_FOLLOWING = 'mazmo/auth/REMOVE_FROM_FOLLOWING';
export const SET_TO_FOLLOW_LISTS = 'mazmo/auth/SET_TO_FOLLOW_LISTS';
export const UPDATE_FOLLOW_LIST = 'mazmo/auth/UPDATE_FOLLOW_LIST';
export const REMOVE_FOLLOW_LIST = 'mazmo/auth/REMOVE_FOLLOW_LIST';
export const CREATE_FOLLOW_LIST = 'mazmo/auth/CREATE_FOLLOW_LIST';

export const ADD_TO_KNOWING = 'mazmo/auth/ADD_TO_KNOWING';
export const REMOVE_FROM_KNOWING = 'mazmo/auth/REMOVE_FROM_KNOWING';

export const ADD_TO_BLOCKS = 'mazmo/auth/ADD_TO_BLOCKS';
export const REMOVE_FROM_BLOCKS = 'mazmo/auth/REMOVE_FROM_BLOCKS';

export const UPDATE_ME = 'mazmo/auth/UPDATE_ME';

export const ADD_RELATIONSHIPS = 'mazmo/auth/ADD_RELATIONSHIPS';
export const REMOVE_RELATIONSHIP = 'mazmo/auth/REMOVE_RELATIONSHIP';
export const APPROVE_RELATIONSHIP = 'mazmo/auth/APPROVE_RELATIONSHIP';

export const UPDATE_CHECKLIST = 'mazmo/auth/UPDATE_CHECKLIST';

export const DISMISS_ONBOARDING_CHATREQUEST = 'mazmo/auth/DISMISS_ONBOARDING_CHATREQUEST';

export const CHAT_REQUESTS = 'mazmo/auth/CHAT_REQUESTS';

export const ADD_ORGANIZATION_TOKEN = 'mazmo/auth/ADD_ORGANIZATION_TOKEN';
export const REMOVE_ORGANIZATION_TOKEN = 'mazmo/auth/REMOVE_ORGANIZATION_TOKEN';
export const ORGANIZATIONS_LOAD = 'mazmo/auth/ORGANIZATIONS_LOAD';

export const MARKETPLACE_MERCADOPAGO_PUBLICKEY = 'mazmo/auth/MARKETPLACE_MERCADOPAGO_PUBLICKEY';
