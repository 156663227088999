import produce from 'immer';

import {
  LOAD_PERIOD,
  LOAD_COUPONS,
  UPDATE_COUPON,
  DELETE_COUPON,
  WIPE,
} from './constants';

export const initialState = {
  periods: {
    current: null,
  },
  coupons: [],
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case LOAD_PERIOD:
      draft.periods.current = action.data;
      break;

    case LOAD_COUPONS:
      draft.coupons = action.data;
      break;

    case UPDATE_COUPON: {
      if (typeof action.data.acknowledged === 'undefined') break;
      let index = state.coupons.findIndex(c => c.code === action.data.code);
      if (index < 0) index = state.coupons.length;

      draft.coupons = [
        ...state.coupons.slice(0, index),
        action.data,
        ...state.coupons.slice(index + 1),
      ];
      break;
    }

    case DELETE_COUPON: {
      let index = state.coupons.findIndex(c => c.id === action.codeId);
      if (index < 0) index = state.coupons.length;

      draft.coupons = [
        ...state.coupons.slice(0, index),
        ...state.coupons.slice(index + 1),
      ];
      break;
    }

    case WIPE:
      draft.periods = initialState.periods;
      break;

    default:
  }
});

export default reducer;
