import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import UserLink from 'components/UserLink';
import UserDisplayName from 'components/UserDisplayName';

import { RELATIONSHIP_TYPES } from '../../../constants';
import locales from '../i18n';

const RelationshipWrapper = styled.div`
  a {
    ${props => props.theme.css.link};
  }
`;
RelationshipWrapper.displayName = 'RelationshipWrapper';

const Relationship = ({ data: { relatesTo: userId, type }, profileId }) => {
  const { t } = useTranslation(locales);

  const pronoun = useSelector(userSelectors.getPronoun(profileId));

  return (
    <RelationshipWrapper>
      <span>{t(`global:RELATION.${type}`, { context: pronoun })}</span>
      {` ${t('global:of')} `}
      <UserLink userId={userId}><UserDisplayName userId={userId} /></UserLink>
    </RelationshipWrapper>
  );
};

Relationship.propTypes = {
  data: PropTypes.shape({
    relatesTo: PropTypes.number.isRequired,
    type: PropTypes.oneOf(Object.values(RELATIONSHIP_TYPES)).isRequired,
  }).isRequired,
  profileId: PropTypes.number.isRequired,
};

Relationship.defaultProps = {
};

export default Relationship;
