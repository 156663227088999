import {
  Box, Heading, HStack, Image, Link, Skeleton, Stack, StackDivider, Text,
} from '@chakra-ui/react';
import { Link as ReactRouterLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import Relationhip from './Relationship';
import locales from '../i18n';

interface Props {
}

const Info: React.FC<Props> = () => {
  const { t } = useTranslation(locales);
  const params = useParams<{ username: string }>();

  const userId = useSelector(userSelectors.getByUsername(params.username));
  const isOrganization = useSelector(userSelectors.isOrganization(userId));
  const regdate = useSelector(userSelectors.getRegdate(userId));
  const lastlogin = useSelector(userSelectors.getLastLogin(userId));
  const job = useSelector(userSelectors.getJob(userId));
  const about: string = useSelector(userSelectors.getAboutMe(userId));
  const pronoun = useSelector(userSelectors.getPronoun(userId));
  const relationships: any[] = useSelector(userSelectors.getRelationships(userId, true));
  const isOnline = useSelector(userSelectors.isOnline(userId));
  const subscriptions: { communities: any[], channels: any[] } = useSelector(userSelectors.getSubscriptions(userId));
  const isFullyLoaded = useSelector(userSelectors.isFullyLoaded(userId));

  return (
    <Stack divider={<StackDivider />} spacing="4" p={4}>
      <HStack spacing={4} alignItems="flex-start">
        <Box flex={1}>
          <Heading size="xs" textTransform="uppercase">
            {t('Registered', { context: pronoun })}
          </Heading>
          <Text pt="2" fontSize="sm">
            {moment(regdate).format(t('date.format'))}
          </Text>
        </Box>
        <Box flex={1}>
          <Heading size="xs" textTransform="uppercase">
            {t('Last seen')}
          </Heading>
          <Text pt="2" fontSize="sm" color={isOnline ? 'green' : 'inherit'}>
            {isOnline ? 'Online' : moment(lastlogin).format(t('date.format'))}
          </Text>
        </Box>
      </HStack>
      <HStack spacing={4} alignItems="flex-start">
        <Box flex={1}>
          <Heading size="xs" textTransform="uppercase">
            {t('Pronoun')}
          </Heading>
          <Text pt="2" fontSize="sm">
            {t(`global:PRONOUN.${pronoun}`)}
          </Text>
        </Box>
        <Box flex={1}>
          <Heading size="xs" textTransform="uppercase">
            {t('Occupation')}
          </Heading>
          <Text pt="2" fontSize="sm">
            {job || '-'}
          </Text>
        </Box>
      </HStack>
      <Box>
        <Heading size="xs" textTransform="uppercase">
          {t(isOrganization ? 'About us' : 'About me')}
        </Heading>
        <Box pt="2">
          {/* eslint-disable-next-line react/no-array-index-key */}
          {(about || '-').trim().split('\n').map((par, i) => <Text key={`about-me-${i}`}>{par}</Text>)}
        </Box>
      </Box>

      {(!isFullyLoaded || relationships.length > 0) && (
        <Box>
          <Heading size="xs" textTransform="uppercase">
            {t('Relationships')}
          </Heading>
          <Box pt="2" fontSize="sm">
            {!isFullyLoaded
              ? <Skeleton height="16px" w="250px" />
              : relationships.map((relationship) => <Relationhip key={`relationship-${relationship.id}`} data={relationship} profileId={userId} />)
            }
          </Box>
        </Box>
      )}

      {(!subscriptions || subscriptions.communities.length > 0 || subscriptions.channels.length > 0) && (
        <HStack spacing={4} alignItems="flex-start">
          <Box flex={1}>
            <Heading size="xs" textTransform="uppercase">
              {t('global:Communities')}
            </Heading>
            <Box pt={2} fontSize="sm">
              {!subscriptions?.communities || !isFullyLoaded
                ? <Skeleton height="16px" w="250px" />
                : (
                  <Stack>
                    {subscriptions.communities.map((community) => (
                      <Link as={ReactRouterLink} key={community.id} to={`/+${community.slug}`} _hover={{ color: 'brand.500' }}>
                        <HStack key={community.id} spacing={2}>
                          <Image src={community.avatar} alt={community.name} boxSize="20px" borderRadius="md" />
                          <Text>{community.name}</Text>
                        </HStack>
                      </Link>
                    ))}
                  </Stack>
                )
              }

            </Box>
          </Box>
          <Box flex={1}>
            <Heading size="xs" textTransform="uppercase">
              {t('global:Channels')}
            </Heading>
            <Box pt={2} fontSize="sm">
              {!subscriptions?.channels || !isFullyLoaded
                ? <Skeleton height="16px" w="250px" />
                : (
                  <Stack>
                    {subscriptions.channels.map((channel) => (
                      <Link as={ReactRouterLink} key={channel.id} to={`/chat/channels/${channel.id}`} _hover={{ color: 'brand.500' }}>
                        <HStack spacing={2}>
                          <Image src={channel.avatar} alt={channel.name} boxSize="20px" borderRadius="full" />
                          <Text>{channel.name}</Text>
                        </HStack>
                      </Link>
                    ))}
                  </Stack>
                )
              }

            </Box>
          </Box>
        </HStack>
      )}
    </Stack>
  );
};

export default Info;
