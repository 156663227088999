import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import {
  Container,
  Header,
  AvatarWrapper,
  UserInfo,
} from 'components/Publication/UI';
import UserLink from 'components/UserLink';
import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';
import EmptyState from 'components/EmptyState';
import Button from 'components/Button';

import { Wrapper, Blurred, LoginBox } from './UI';

import locales from '../../i18n';

const Unlogged = ({ userId }) => {
  const { t } = useTranslation(locales);

  return (
    <Wrapper>
      <Blurred>
        <Container>
          <Header>
            <AvatarWrapper>
              <UserLink userId={userId}>
                <UserAvatar userId={userId} size="36px" />
              </UserLink>
            </AvatarWrapper>
            <UserInfo>
              <UserLink userId={userId}>
                <UserDisplayName userId={userId} />
              </UserLink>
            </UserInfo>
          </Header>
          <img
            className="sampleimage"
            src="https://www.publicdomainpictures.net/pictures/160000/velka/jeune-couple-damoureux.jpg"
            alt="Sample"
          />
        </Container>
      </Blurred>

      <LoginBox>
        <EmptyState title={t('You need to be logged in to access user\'s profiles')}>
          <Button to="/signup">{t('global:Sign up')}</Button>
        </EmptyState>
      </LoginBox>
    </Wrapper>
  );
};

Unlogged.propTypes = {
  userId: PropTypes.number.isRequired,
};

Unlogged.defaultProps = {
};

export default Unlogged;
