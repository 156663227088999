import styled from 'styled-components';

import colors from 'utils/css/colors';

const PrivacyLabel = styled.p`
  color: ${colors.blackRed};
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 12px;
`;
export default PrivacyLabel;
