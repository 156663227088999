import { schema } from 'normalizr';

import user from 'state/users/schema';

export const channelmessageSchema = new schema.Entity('channelmessages', {
  // author: user,
  channel: {
    participants: [{
      user,
    }],
  },
});

export const channelSchema = new schema.Entity('channels', {
  participants: [{
    user,
  }],
  lastMessage: channelmessageSchema,
});
