import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useOpenClose } from 'hooks';

import { Emoticon } from 'components/Icons';

import Wrapper from './Wrapper';
import Picker from './Picker';

const EmojiPicker = ({ id }: { id: string }) => {
  const [opened,, close, toggle] = useOpenClose(false);
  const picker = useRef<HTMLDivElement>(null);
  const selector = useRef<HTMLDivElement>(null);

  const PickerWrapper = styled.div<React.HTMLProps<HTMLDivElement>>`
    position: absolute;
    top: -24px;
    left: 24px;
  `;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      try {
        // @ts-ignore
        if (!picker.current?.contains(event.target) && !selector.current?.contains(event.target)) {
          close();
        }
      } catch (error) {
        //
      }
    };

    if (opened) {
      document.addEventListener('mousedown', handleClickOutside, false);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [opened, close]);

  return (
    <Wrapper>
      <div onClick={toggle} ref={selector} role="button" tabIndex={0} onKeyDown={toggle}>
        <Emoticon color="#666" />
      </div>

      {opened && (
        <PickerWrapper ref={picker}>
          <Picker id={id} />
        </PickerWrapper>
      )}
    </Wrapper>
  );
};

EmojiPicker.propTypes = {
  id: PropTypes.string.isRequired,
};

EmojiPicker.defaultProps = {
};

export default EmojiPicker;
