import { forwardRef, useState } from 'react';
import {
  InputRightElement, Button, InputGroup, Input,
  InputProps,
} from '@chakra-ui/react';

import { useTranslation } from 'hooks';

import locales from './i18n';

// eslint-disable-next-line react/display-name
const InputPassword: React.FC<InputProps> = forwardRef((props, ref) => {
  const { t } = useTranslation(locales);

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={show ? 'text' : 'password'}
        placeholder={t('Enter password')}
        ref={ref}
        {...props}
      />
      <InputRightElement width="4.5rem" mr={1}>
        <Button h="1.75rem" size="sm" onClick={handleClick} tabIndex={-1}>
          {show ? t('Hide') : t('Show')}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
});

export default InputPassword;
