const badges = [
  { value: 0, fill: '#CCCCCC', color: '#6F6666' },
  { value: 100, fill: '#ED4D3D', color: 'white' },
  { value: 500, fill: '#000000', color: 'white' },
];

const getBadgeColor = ({ badgesValue }: { badgesValue: number }) => {
  let slot = { value: 0, fill: '#AAAAAA', color: 'black' };
  badges.forEach((badge) => {
    if (badge.value <= badgesValue && badge.value >= slot.value) slot = badge;
  });

  return slot;
};

export default getBadgeColor;
