import { IconType, IconDefaults } from './type';

const City = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M19,15H17V13H19M19,19H17V17H19M13,7H11V5H13M13,11H11V9H13M13,15H11V13H13M13,19H11V17H13M7,11H5V9H7M7,15H5V13H7M7,19H5V17H7M15,11V5L12,2L9,5V7H3V21H21V11H15Z"
      fill={color}
    />
  </svg>
);

City.propTypes = IconType;
City.defaultProps = IconDefaults;

export default City;
