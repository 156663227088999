import PropTypes from 'prop-types';

export default PropTypes.shape({
  avatar: PropTypes.shape({}),
  name: PropTypes.string,
  age: PropTypes.number,
  description: PropTypes.string,
  postsQuantity: PropTypes.number,
  joinDate: PropTypes.number,
});
