import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  @media(min-width: 768px) {
    max-width: 768px;
  }
`;

export default Wrapper;
