import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as appSelectors from 'state/app/selectors';
import * as appActions from 'state/app/actions';

import Loading from 'components/Loading';

import { Close } from 'components/Icons';

const ImagesContainer = styled.ul.attrs<React.HTMLProps<HTMLElement>>({
  className: 'community-upload-media-container',
})`
  display: flex;
  margin: 20px 0 0;
  padding: 0;
`;

const IMAGE_SLOT_WITH = '150px';

const Image = styled.li<React.HTMLProps<HTMLElement>>`
  width: ${IMAGE_SLOT_WITH};
  height: ${IMAGE_SLOT_WITH};
  border: 1px solid #aaa;
  padding: 0;
  position: relative;
  &:not(:last-child) {
    margin: 0 10px 0 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const LoadingContainer = styled.div`
  text-align: center;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
`;

const AddImage = styled(Image).attrs({
  children: '+',
})`
  border: 1px dashed #aaa;
  text-align: center;
  color: #aaa;
  font-size: 78px;
  cursor: pointer;
`;

const RemoveImage = styled.button<React.HTMLProps<HTMLButtonElement>>`
  border: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  background: rgba(255, 255, 255, .15);
  border-radius: 100%;

  &:focus {
    outline: none;
  }
`;

interface Props {
  id: string;
  open: () => void;
  getInputProps: () => React.HTMLProps<HTMLInputElement>;
  alwaysShowAddButton?: boolean;
}

const CommunityUploadMedia = ({
  id,
  open,
  getInputProps,
  alwaysShowAddButton,
}: Props) => {
  const dispatch = useDispatch();
  // @ts-ignore
  const media = useSelector(state => appSelectors.selectMedia(state, id), shallowEqual);

  const removeImage = (index: number) => () => {
    dispatch(appActions.mediaUploadRemove(id, index));
  };

  return (
    <>
      <input {...getInputProps()} />

      {(media.length > 0 || alwaysShowAddButton) && (
        <ImagesContainer>
          {media.map((image: any, index: number) => (
            <Image key={image.filename || image.file.name}>
              {!image.uploaded && <LoadingContainer><Loading /></LoadingContainer>}
              <img src={image.preview} alt={image.file.path} />
              <RemoveImage onClick={removeImage(index)}>
                <Close color="black" />
              </RemoveImage>
            </Image>
          ))}
          <AddImage key="upload-media-add" onClick={open} />
        </ImagesContainer>
      )}
    </>
  );
};

CommunityUploadMedia.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired,
  alwaysShowAddButton: PropTypes.bool,
};

CommunityUploadMedia.defaultProps = {
  alwaysShowAddButton: false,
};

export default CommunityUploadMedia;
