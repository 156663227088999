import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';

import Mod from './Mod';
import { MEMBERSHIP_ROLES } from '../../../constants';
import locales from './i18n';

const Wrapper = styled.div`
  margin: 24px 0;
  padding: 24px 0;
  border-top: 1px solid #F5EFEF;
  flex: 1;
`;

const Top = styled.div`
  font-weight: 500;
  padding: 0 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

const List = styled.ul`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 16px;
`;

const Mods = ({ communityId }) => {
  const { t } = useTranslation(locales);

  const mods = useSelector(
    state => communitySelectors.selectMods(state, communityId),
    shallowEqual,
  );

  if (!mods.length) return null;

  const modsInOrder = mods.sort((modA, modB) => {
    const modAindexRole = Object.values(MEMBERSHIP_ROLES).indexOf(modA.role);
    const modBindexRole = Object.values(MEMBERSHIP_ROLES).indexOf(modB.role);

    return modAindexRole - modBindexRole;
  });

  return (
    <Wrapper>
      <Top>
        <span>{t('global:Mods')}</span>
      </Top>
      <List>
        {modsInOrder.map(mbsp => (
          <Mod key={`community-mod-${mbsp._id}`} membership={mbsp} />
        ))}
      </List>
    </Wrapper>
  );
};

Mods.propTypes = {
  communityId: PropTypes.string.isRequired,
};

Mods.defaultProps = {
};

export default Mods;
