import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as threadSelectors from 'state/threads/selectors';
import * as communitySelectors from 'state/communities/selectors';

import UserLink from 'components/UserLink';
import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';
import { Author, Description, More } from 'components/CommunityAuthor';

import locales from './i18n';

const ThreadAuthorInfo = ({ threadId }) => {
  const { t } = useTranslation(locales);

  const authorId = useSelector(threadSelectors.getAuthorId(threadId));
  const createdAt = useSelector(threadSelectors.getCreatedAt(threadId));
  const communityId = useSelector(threadSelectors.getCommunityId(threadId));
  const communityName = useSelector(state => communitySelectors.selectName(state, communityId));

  if (!authorId) return null;

  return (
    <UserLink userId={authorId}>
      <Author>
        <UserAvatar userId={authorId} size="32px" />
        <Description>
          <UserDisplayName userId={authorId} />
          <More>{`${t('At')} ${communityName} • ${moment(createdAt).fromNow()}`}</More>
        </Description>
      </Author>
    </UserLink>
  );
};

ThreadAuthorInfo.propTypes = {
  threadId: PropTypes.string.isRequired,
};

ThreadAuthorInfo.defaultProps = {
};

export default ThreadAuthorInfo;
