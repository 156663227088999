import { useState, useEffect } from 'react';
import styled from 'styled-components';
import qs from 'qs';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ComposerRef from 'utils/ComposerRef';
import {
  useTitle,
  useTranslation,
  useOpenClose,
  useUploadMedia,
} from 'hooks';
import * as appSelectors from 'state/app/selectors';
import * as authSelectors from 'state/auth/selectors';
import * as appActions from 'state/app/actions';

import Composer from 'components/Composer';
import Main from 'components/Forms/Main';
import Wrapper from 'components/Forms/Wrapper';
import Button from 'components/Button';
import Box from 'components/Forms/Box';
import ButtonsContainer from 'components/Forms/ButtonsContainer';
import {
  Earth, Contacts, AxisArrow, ImageMultiple, Pencil, Link as LinkIcon,
  PollBox,
} from 'components/Icons';
import { TabsList, TabsWrapper } from 'components/Tabs';

import Text from './Text';
import Url from './Url';
import Media from './Media';
import Poll from './Poll';
import PrivacyListsModal from './PrivacyListsModal';
import Funding from './Funding';
import Lovense from './Lovense';
import { PUBLICATION_TYPES, PUBLICATION_PRIVACIES } from '../../../constants';
import locales from '../i18n';

const TextWrapper = styled.div`
  padding: 16px 24px;
  border: 1px solid ${props => (props.hasError ? 'red' : '#F5F0F0')};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.08);
  transition: all 250ms ease-out;
  margin-bottom: 16px;
  min-height: 160px;
`;

const StyledBox = styled(Box)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const NewPublication = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  useTitle(t('New publication'));
  const { type } = useParams();
  const history = useHistory();

  const composerId = 'publication';

  const [payload, setPayload] = useState(null);
  const [privacy, setPrivacy] = useState(PUBLICATION_PRIVACIES.PUBLIC);
  const [funding, setFunding] = useState(null);
  const [lovense, setLovense] = useState(null);
  const [composerText, setComposerText] = useState('');

  const hasLovense = useSelector(authSelectors.hasLovense);
  const canSubmit = useSelector(
    state => appSelectors.selectIsComposerReadyToSubmit(state, composerId),
  );
  const isSaving = useSelector(state => appSelectors.selectIsComposerSaving(state, composerId));
  const disabled = !canSubmit || isSaving;

  const [isPrivacyListsOpen, openPrivacyLists, closePrivacyLists] = useOpenClose(false);
  const [privacyLists, setPrivacyLists] = useState([]);

  const {
    handlePastedFiles,
  } = useUploadMedia(composerId, '/feed/upload');

  useEffect(() => {
    dispatch(appActions.uiLeftColumn(true));

    const queryparams = qs.parse(document.location.search, { ignoreQueryPrefix: true });
    if (queryparams.content) {
      const ref = ComposerRef.getRef(composerId);
      if (ref) {
        ref.setValue(queryparams.content);
        ref.setCursor(ref.lineCount(), 0);
      }
    }
  }, [dispatch]);

  let TypeComponent;
  let publishDisabled = true;
  let dataType;
  switch (type) {
    case 'url':
      TypeComponent = Url;
      publishDisabled = !payload?.url;
      dataType = PUBLICATION_TYPES.LINK;
      break;

    case 'photos':
      TypeComponent = Media;
      publishDisabled = disabled;
      dataType = PUBLICATION_TYPES.MEDIA;
      break;

    case 'poll':
      TypeComponent = Poll;
      publishDisabled = (
        !payload
        || (!payload.poll.options[0].image && !payload.poll.options[0].text)
        || (!payload.poll.options[1].image && !payload.poll.options[1].text)
      );
      dataType = PUBLICATION_TYPES.POLL;
      break;

    default:
      TypeComponent = Text;
      publishDisabled = disabled;
      dataType = PUBLICATION_TYPES.TEXT;
  }

  const onSubmitClick = async () => {
    try {
      const data = {
        type: dataType,
        payload,
        privacy,
        privacyLists,
      };

      if (funding && type === 'photos') data.funding = funding;
      if (lovense) data.lovense = lovense;

      await dispatch(appActions.composerSendToServer(composerId, data));
      dispatch(appActions.addToast(t('Publication created!')));
      history.push('/?flushQueue=true');
    } catch (error) {
      dispatch(appActions.addError(error));
    }
  };

  const onPrivacyChange = (value) => {
    if (value === PUBLICATION_PRIVACIES.LISTS) openPrivacyLists();
    else setPrivacy(value);
  };

  const onConfirmPrivacyLists = (lists) => {
    setPrivacy(PUBLICATION_PRIVACIES.LISTS);
    setPrivacyLists(lists);
    closePrivacyLists();
  };

  const groupOptions = [
    {
      label: t('Public'),
      value: PUBLICATION_PRIVACIES.PUBLIC,
      icon: <Earth color="white" />,
      selected: privacy === PUBLICATION_PRIVACIES.PUBLIC,
    },
    {
      label: t('My Contacts'),
      value: PUBLICATION_PRIVACIES.CONTACTS,
      icon: <Contacts color="white" />,
      selected: privacy === PUBLICATION_PRIVACIES.CONTACTS,
    },
    {
      label: t('Selected Lists'),
      value: PUBLICATION_PRIVACIES.LISTS,
      icon: <AxisArrow color="white" />,
      selected: privacy === PUBLICATION_PRIVACIES.LISTS,
    },
  ];

  const tabs = [
    {
      key: 'text', label: t('Text'), icon: <Pencil />, href: '/new/text',
    },
    {
      key: 'photos', label: t('Photos'), icon: <ImageMultiple />, href: '/new/photos',
    },
    {
      key: 'url', label: t('Link'), icon: <LinkIcon />, href: '/new/url',
    },
    {
      key: 'poll', label: t('Poll'), icon: <PollBox />, href: '/new/poll',
    },
  ];

  const selected = tabs.findIndex(tab => tab.key === type);

  const onComposerChange = (value) => {
    window.localStorage.setItem('publicationTextDraft', value);
    setComposerText(value);
  };

  return (
    <Main hasTitle>
      <Wrapper>
        <TabsWrapper><TabsList data={tabs} selected={selected} /></TabsWrapper>
        <StyledBox>
          <TypeComponent payload={payload} setPayload={setPayload}>
            <TextWrapper>
              <Composer
                id={composerId}
                placeholder={t('What\'s on your kinky mind?')}
                height="250px"
                markdown={false}
                autofocus
                initialValue={composerText || window.localStorage.getItem('publicationTextDraft') || ''}
                handlePastedFiles={type === 'photos' ? handlePastedFiles : undefined}
                onChange={(cm) => { onComposerChange(cm.getValue()); }}
              />
            </TextWrapper>
          </TypeComponent>

          {type === 'photos' && <Funding funding={funding} setFunding={setFunding} />}
          {hasLovense && <Lovense lovense={lovense} setLovense={setLovense} />}

          <ButtonsContainer>
            <Button
              className="empty"
              to="/"
            >
              {t('global:Cancel')}
            </Button>
            <Button
              onClick={onSubmitClick}
              loading={isSaving}
              disabled={publishDisabled}
              groupOptions={groupOptions}
              groupOptionChange={onPrivacyChange}
            >
              {t('Publish')}
            </Button>
          </ButtonsContainer>
        </StyledBox>
      </Wrapper>

      {/* Modals */}
      {isPrivacyListsOpen && (
        <PrivacyListsModal
          close={closePrivacyLists}
          initialLists={privacyLists}
          onConfirm={onConfirmPrivacyLists}
        />
      )}
    </Main>
  );
};

export default NewPublication;
