import { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HStack, Text } from '@chakra-ui/react';

import { useTranslation, useOpenClose } from 'hooks';
import * as authSelectors from 'state/auth/selectors';

import {
  Plus,
  Pencil,
  Link as LinkIcon,
  ImageMultiple,
  PollBox,
  Forum,
  Calendar,
  Chats,
  Chat,
} from 'components/Icons';
import Menu from 'components/Menu';

import NavItem from '../Item';
import Wrapper from './UI/Wrapper';
import MenuWrapper from './UI/MenuWrapper';
import locales from './i18n';

const Create = ({ openCommunityModal }) => {
  const { t } = useTranslation(locales);
  const menu = useRef(null);

  const isLoggedIn = useSelector(authSelectors.loggedIn);

  const [menuOpened, openMenu, closeMenu] = useOpenClose();

  const toggle = useCallback((e) => {
    if (menuOpened && menu.current) {
      if (!menu.current.contains(e.target)) closeMenu();
    } else {
      openMenu();
    }
  }, [closeMenu, menuOpened, openMenu]);

  if (!isLoggedIn) return null;

  return (
    <NavItem onClick={toggle} pressed={menuOpened}>
      <div>
        <Wrapper>
          <Plus />

          <Menu
            width="400px"
            open={menuOpened}
            onClose={closeMenu}
            className="nav-dropdown"
            maxHeight="470px"
          >
            <MenuWrapper ref={menu}>
              <div>
                <div className="title">{t('global:Create')}</div>
                <div className="items">
                  <div className="item">
                    <div className="icon"><Pencil /></div>
                    <div className="item-content">
                      <div className="item-title">{t('Publication/Photos')}</div>
                      <div className="item-description">{t('Create a personal status in the feed')}</div>
                      <div className="item-options">
                        <Link to="/new/photos">
                          <HStack spacing={0}>
                            <ImageMultiple />
                            <Text>{t('Photos')}</Text>
                          </HStack>
                        </Link>
                        <Link to="/new/text">
                          <HStack spacing={0}>
                            <Pencil />
                            <Text>{t('Only text')}</Text>
                          </HStack>
                        </Link>
                        <Link to="/new/url">
                          <HStack spacing={0}>
                            <LinkIcon />
                            <Text>{t('URL (image/link/gif)')}</Text>
                          </HStack>
                        </Link>
                        <Link to="/new/poll">
                          <HStack spacing={0}>
                            <PollBox />
                            <Text>{t('Poll')}</Text>
                          </HStack>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="item link" onClick={openCommunityModal} role="button" tabIndex={0} onKeyDown={openCommunityModal}>
                    <div className="icon"><Forum /></div>
                    <div className="item-content">
                      <div className="item-title">{t('Thread')}</div>
                      <div className="item-description">{t('Open a conversation about a topic you care about')}</div>
                    </div>
                  </div>

                  <Link className="item" to="/events/create">
                    <div className="icon"><Calendar /></div>
                    <div className="item-content">
                      <div className="item-title">{t('Event')}</div>
                      <div className="item-description">{t('If you are organizing a meeting, event or party, you can publish and manage it here')}</div>
                    </div>
                  </Link>

                  <Link className="item" to="/chat/channels/create">
                    <div className="icon"><Chat /></div>
                    <div className="item-content">
                      <div className="item-title">{t('Channel')}</div>
                      <div className="item-description">{t('Chat space to talk about any topic or topics you like')}</div>
                    </div>
                  </Link>

                  <Link className="item" to="/communities/create">
                    <div className="icon"><Chats /></div>
                    <div className="item-content">
                      <div className="item-title">{t('Community')}</div>
                      <div className="item-description">{t('Space to discuss and host conversations in the topic-reply fashion')}</div>
                    </div>
                  </Link>
                </div>
              </div>
            </MenuWrapper>
          </Menu>
        </Wrapper>
      </div>
    </NavItem>
  );
};

Create.propTypes = {
  openCommunityModal: PropTypes.func.isRequired,
};

Create.defaultProps = {
};

export default Create;
