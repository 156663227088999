import styled from 'styled-components';

const Info = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  text-align: right;
  position: absolute;
  right: 4px;
  bottom: 8px;
  cursor: pointer;
  z-index: 10;
  opacity: 0.5;
  font-size: 12px;
  line-height: 16px;
  display: flex;

  svg {
    width: 16px;
    height: 16px;
    margin-left: 2px;
  }
`;
Info.displayName = 'Info';

export default Info;
