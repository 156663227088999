import styled from 'styled-components';

import colors from 'utils/css/colors';

const Copy = styled.div`
  text-align: center;

  h2 {
    font-size: 54px;
    color: ${colors.redReaction};
    font-weight: 300;
    letter-spacing: -1;
    margin: 0 20px 20px;

    @media(max-width: 767px) {
      font-size: 40px;
      line-height: 40px;
    }
  }

  p {
    font-size: 20px;
    margin: 0 20px 70px;
    color: #666;

    @media(max-width: 767px) {
      font-size: 16px;
    }
  }
`;

export default Copy;
