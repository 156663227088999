import { schema } from 'normalizr';

import user from 'state/users/schema';
import event from 'state/events/schema';

const thread = new schema.Entity('threads', {
  author: user,
  lastReply: {
    user,
  },
  event,
}, {
  idAttribute: '_id',
});

export default thread;
