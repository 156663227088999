import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as messengerSelectors from 'state/messengers/selectors';
import * as messengerActions from 'state/messengers/actions';
import * as appActions from 'state/app/actions';

import OptionsModalComponent from '../OptionsModal';

const OptionsModal = ({ messengerId, messageId, close }) => {
  const dispatch = useDispatch();

  const reactions = useSelector(
    state => messengerSelectors.getMessageReactions(state, messageId),
    shallowEqual,
  );
  const outgoing = useSelector(state => messengerSelectors.isMessageOutgoing(state, messageId));

  const addReaction = name => dispatch(messengerActions.addReaction(messageId, name));
  const replyTo = () => {
    close();
    dispatch(messengerActions.addReplyTo(messengerId, messageId));
  };

  const remove = async () => {
    try {
      await dispatch(messengerActions.removeMessage(messageId));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    close();
  };

  const edit = () => {
    dispatch(messengerActions.addEditComposer(messengerId, messageId));
    close();
  };

  return (
    <OptionsModalComponent
      close={close}
      reactions={reactions}
      addReaction={addReaction}
      replyTo={replyTo}
      onRemove={outgoing ? remove : null}
      onEdit={outgoing ? edit : null}
    />
  );
};

OptionsModal.propTypes = {
  messengerId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

OptionsModal.defaultProps = {
};

export default OptionsModal;
