import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as threadSelectors from 'state/threads/selectors';

import { AccountGroup } from 'components/Icons';
import Badge from 'components/Badge';

import NavItem from './Item';
import locales from '../i18n';

const CommunitiesLink = () => {
  const { t } = useTranslation(locales);

  const communityThreadsUnread = useSelector(threadSelectors.hasUnread());

  return (
    <NavItem>
      <Link to="/communities">
        <AccountGroup color="white" />
        <span>{t('Communities')}</span>
        {communityThreadsUnread && <Badge />}
      </Link>
    </NavItem>
  );
};

CommunitiesLink.propTypes = {
};

export default CommunitiesLink;
