import styled from 'styled-components';

const TabsWrapper = styled.div.attrs({
  className: 'tabs-wrapper',
})`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f1f1f1;
  background-color: white;
  position: relative;
  width: 100%;
  height: ${props => props.theme.subheader.height}px;
  justify-content: flex-end;
  z-index: 90;

  ${props => props.absolute && `
    position: absolute;
  `}

  ${props => props.sticky && `
    position: sticky;
    top: ${props.theme.header.height}px;
    left: 0;
  `}

  li {
    display: flex;
    align-items: center;
  }
`;

export default TabsWrapper;
