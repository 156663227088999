import styled from 'styled-components';

import { useTranslation } from 'hooks';

import locales from '../i18n';

const DeletedReplyWrapper = styled.div`
  font-style: italic;
  opacity: 0.5;
`;
DeletedReplyWrapper.displayName = 'DeletedReplyWrapper';

const DeletedReply = () => {
  const { t } = useTranslation(locales);

  return (
    <DeletedReplyWrapper>
      {t('This message was deleted')}
    </DeletedReplyWrapper>
  );
};

DeletedReply.propTypes = {
};

DeletedReply.defaultProps = {
};

export default DeletedReply;
