import { useSelector } from 'react-redux';
import {
  Icon, MenuDivider, MenuItem, useDisclosure,
} from '@chakra-ui/react';
import { CircleHelp } from 'lucide-react';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';

import PromotedModal from '../Modals/Promoted';
import locales from '../i18n';

interface Props {
  publicationId: string;
}

const MenuItemPromoted: React.FC<Props> = ({ publicationId }) => {
  const { t } = useTranslation(locales);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isPromoted = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectIsPromoted(state, publicationId),
  );

  if (!isPromoted) return null;

  return (
    <>
      <MenuDivider />
      <MenuItem
        icon={<Icon as={CircleHelp} />}
        onClick={onOpen}
      >
        {t('Why am I seeing this?')}
      </MenuItem>

      {isOpen && (
        <PromotedModal close={onClose} />
      )}
    </>
  );
};

export default MenuItemPromoted;
