import PropTypes from 'prop-types';

import { POLL_COLORS } from '../../../../constants';
import ColoredOption from './ColoredOption';

const PollOption = ({ image, color, text }) => (
  <ColoredOption color={color} image={image}>
    {text && <div>{text}</div>}
  </ColoredOption>
);

PollOption.propTypes = {
  image: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(POLL_COLORS)),
  text: PropTypes.string,
};

PollOption.defaultProps = {
  image: null,
  color: null,
  text: null,
};

export default PollOption;
