import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';
import * as replySelectors from 'state/replies/selectors';
import * as authSelectors from 'state/auth/selectors';
import * as membershipSelectors from 'state/memberships/selectors';

import { MoreActions } from 'components/Icons';
import Menu, { Item } from 'components/Menu';
import SpankListModal from 'components/SpankListModal';

import RemoveModal from './RemoveModal';
import EditModal from './EditModal';
import locales from '../i18n';

const OptionsWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 16px;
  width: 24px;
  height: 24px;
  padding: 2px;
  text-align: center;
  border-radius: 100%;
  line-height: 24px;
  cursor: pointer;

  ${props => props.pressed && `
    background: rgba(0, 0, 0, .1);
  `}

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${colors.grey} !important;
    }
  }
`;
OptionsWrapper.displayName = 'OptionsWrapper';

const Options = ({ replyId }) => {
  const { t } = useTranslation(locales);

  const authorId = useSelector(replySelectors.getAuthorId(replyId));
  const userId = useSelector(authSelectors.selectUserId);
  const communityId = useSelector(replySelectors.getCommunityId(replyId));
  const userIsMod = useSelector(state => membershipSelectors.userIsModOf(state, communityId));

  const [showingContextMenu, setShowingContextMenu] = useState(false);
  const openContextMenu = useCallback(() => { setShowingContextMenu(true); }, []);
  const closeContextMenu = useCallback(() => { setShowingContextMenu(false); }, []);

  const [showingSpanksModal, setShowingSpanksModal] = useState(false);
  const openSpanksModal = useCallback(() => { setShowingSpanksModal(true); }, []);
  const closeSpanksModal = useCallback(() => { setShowingSpanksModal(false); }, []);

  const [showingRemoveModal, setShowingRemoveModal] = useState(false);
  const openRemoveModal = useCallback(() => { setShowingRemoveModal(true); }, []);
  const closeRemoveModal = useCallback(() => { setShowingRemoveModal(false); }, []);

  const [showingEditModal, setShowingEditModal] = useState(false);
  const openEditModal = useCallback(() => { setShowingEditModal(true); }, []);
  const closeEditModal = useCallback(() => { setShowingEditModal(false); }, []);

  return (
    <>
      <OptionsWrapper onClick={openContextMenu} pressed={showingContextMenu}>
        <MoreActions />
        <Menu width="250px" open={showingContextMenu} onClose={closeContextMenu} orientation="left" maxHeight="100%">
          <Item onClick={openSpanksModal}>{t('See reactions')}</Item>
          {authorId === userId && (
            <Item onClick={openEditModal}>{t('global:Edit')}</Item>
          )}
          {(userIsMod || authorId === userId) && (
            <Item onClick={openRemoveModal} danger>{t('global:Remove')}</Item>
          )}
        </Menu>
      </OptionsWrapper>

      {/* Modals */}
      {showingSpanksModal && (
        <SpankListModal close={closeSpanksModal} entityId={replyId} type="reply" />
      )}

      {showingRemoveModal && (
        <RemoveModal close={closeRemoveModal} replyId={replyId} />
      )}

      {showingEditModal && (
        <EditModal close={closeEditModal} replyId={replyId} />
      )}
    </>
  );
};

Options.propTypes = {
  replyId: PropTypes.string.isRequired,
};

Options.defaultProps = {
};

export default Options;
