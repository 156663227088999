import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as userSelectors from 'state/users/selectors';

import colors from 'utils/css/colors';
import * as userActions from 'state/users/actions';
import { useTranslation } from 'hooks';

import UserAvatar from 'components/UserAvatar';

import { CHANNEL_MESSAGES_TYPES } from '../../../constants';
import locales from '../i18n';

const Wrapper = styled.div`
  display: flex;
  color: ${colors.grey};
`;

const ActionText = styled.span`
  font-size: 14px;
  line-height: 14px;
  flex: 1;
  align-self: center;
  margin-left: 10px;
`;

const Author = styled.span`
  ${props => props.busy && `
    animation: animation 1.25s forwards infinite linear;
    background: #F6F6F6;
    background: linear-gradient(to right,#DDD 8%,#F0F0F0 18%,#DFDFDF 33%);
    background-size: 800px 104px;
    position: relative;
    height: 12px;
    width: 100px;
    color: transparent;
    border-radius: 8px;

    @keyframes animation {
      0% {
        background-position: -468px 0
      }
      100% {
        background-position: 468px 0
      }
    }
  `}
`;

const ChannelActionMessage = memo(({ message }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  const author = useSelector(userSelectors.getById(message.authorId), shallowEqual);
  if (author.loading) dispatch(userActions.fetchData(message.authorId));
  const bannedBy = useSelector(userSelectors.getById(message.payload.bannedBy), shallowEqual);
  const unbannedBy = useSelector(userSelectors.getById(message.payload.unbannedBy), shallowEqual);

  const { banReason } = message.payload;

  const transText = useMemo(() => {
    let text = '';
    switch (message.type) {
      case CHANNEL_MESSAGES_TYPES.JOIN:
        text = t('has joined the channel');
        break;
      case CHANNEL_MESSAGES_TYPES.PART:
        text = t('has parted the channel');
        break;
      case CHANNEL_MESSAGES_TYPES.BAN:
        text = t('has been banned by {{bannedBy}}', { bannedBy: bannedBy.displayname, context: author.pronoun });
        if (banReason) text += `: ${banReason}`;
        break;
      case CHANNEL_MESSAGES_TYPES.UNBAN:
        text = t('has been unbanned by {{unbannedBy}}', { unbannedBy: unbannedBy.displayname, context: author.pronoun });
        break;
      default:
    }

    return text;
  }, [author.pronoun, banReason, bannedBy, message.type, t, unbannedBy]);

  return (
    <Wrapper>
      <Link to={`/@${author.username}`} className="userlink">
        <UserAvatar userId={author.id} size="30px" showOnline={false} />
      </Link>
      <ActionText>
        <Link to={`/@${author.username}`} className="userlink"><Author busy={!!author.loading}>{author.displayname}</Author></Link>
        {' '}
        {transText}
      </ActionText>
    </Wrapper>
  );
});

ChannelActionMessage.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    payload: PropTypes.shape({
      banReason: PropTypes.string,
      bannedBy: PropTypes.shape({
        displayname: PropTypes.string,
      }),
      unbannedBy: PropTypes.shape({
        displayname: PropTypes.string,
      }),
    }),
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    rawContent: PropTypes.string,
    referenceId: PropTypes.number,
    createdAt: PropTypes.string,
    authorId: PropTypes.number,
  }).isRequired,
};

export default ChannelActionMessage;
