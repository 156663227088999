import styled from 'styled-components';

import { useTranslation } from 'hooks';

import locales from '../i18n';

const BlockedContainer = styled.div`
  background: #FAFAFA;
  padding: 18px 0;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #666;
`;

const Blocked = () => {
  const { t } = useTranslation(locales);

  return (
    <BlockedContainer>{t('This chat has been blocked')}</BlockedContainer>
  );
};

export default Blocked;
