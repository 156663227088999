import {
  Box, BoxProps, HStack, useTheme,
} from '@chakra-ui/react';
import UserAvatar from 'components/UserAvatar';
import { Link } from 'react-router-dom';

interface Props extends BoxProps {
  author: { id: number, username: string };
}

const RequestBubble: React.FC<Props> = ({ children, author, ...props }) => {
  const { colors } = useTheme();

  return (
    <HStack mt={8} alignItems="flex-start" {...props}>
      <Link to={`/@${author.username}`} className="userlink">
        <UserAvatar userId={author.id} size="48px" showOnline onlineBorderColor={undefined} />
      </Link>
      <Box>
        <Box
          maxW="2xl"
          textAlign="left"
          fontSize="lg"
          bgColor="gray.100"
          p={4}
          rounded="md"
          borderTopLeftRadius={0}
          boxShadow="md"
          position="relative"
          _after={{
            content: '""',
            position: 'absolute',
            top: '0px',
            left: '-8px', // Adjust this value to move the tail left or right
            width: 0,
            height: 0,
            borderLeft: '10px solid transparent',
            borderBottom: `10px solid ${colors.gray['100']}`,
            transform: 'rotate(-90deg)',
            zIndex: 100,
          }}
        >
          {children}
        </Box>
      </Box>
    </HStack>
  );
};

export default RequestBubble;
