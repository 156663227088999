import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import usersToText from 'utils/usersToText';
import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import UserAvatar from 'components/UserAvatar';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const ReactionReply = ({
  timestamp, reply, authors: authorIds, read,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  // Remove duplications (this should be a service feature in the future)
  const authorIdsSet = new Set();
  authorIds.forEach(id => authorIdsSet.add(id));
  const authorsBatch = useSelector(userSelectors.getListByIds([...authorIdsSet]), shallowEqual);

  const authors = Object.values(authorsBatch).reverse();
  // Fetch all non loaded authors
  authors.forEach((author) => {
    if (author.loading) dispatch(userActions.fetchData(author.id));
  });

  const authorsText = usersToText(authors, t);

  return (
    <AlertContainer timestamp={timestamp} image={<UserAvatar userId={authors[0].id} size="40px" showOnline={false} />} to={`/+${reply.community.slug}/${reply.thread.slug}#${reply.id}`} read={read}>
      {authorsText}
      {' '}
      {t('spanked you a reply', { context: authors.length > 1 && 'PLURAL' })}
      {reply.rawContent && ': '}
      {reply.rawContent}
    </AlertContainer>
  );
};

ReactionReply.propTypes = {
  reply: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rawContent: PropTypes.string,
    community: PropTypes.shape({
      slug: PropTypes.string,
    }),
    thread: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.number).isRequired,
  read: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default ReactionReply;
