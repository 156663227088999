import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslation } from 'hooks';

import Modal from 'components/Modal';

import { POLL_COLORS } from '../../../../constants';
import locales from '../../i18n';

const Color = styled.button.attrs({
  type: 'button',
})`
  border: 2px solid white;
  background-color: ${props => POLL_COLORS[props.value]};
  width: 48px;
  height: 48px;
  margin: 8px;
  cursor: pointer;

  &:hover {
    border-color: #000000;
  }
`;
Color.displayName = 'Color';

const ColorPickerModal = ({ setPickingColor, selectColor }) => {
  const { t } = useTranslation(locales);

  return (
    <Modal
      title={t('Pick a background color')}
      onCancel={() => setPickingColor(null)}
    >
      <div>
        {Object.keys(POLL_COLORS).map(key => (
          <Color key={`color-${key}`} value={key} onClick={selectColor(key)} />
        ))}
      </div>
    </Modal>
  );
};

ColorPickerModal.propTypes = {
  setPickingColor: PropTypes.func.isRequired,
  selectColor: PropTypes.func.isRequired,
};

ColorPickerModal.defaultProps = {
};

export default ColorPickerModal;
