import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Sidebar from 'containers/Sidebar';

import ProfileContent from './Content';

const Profile = ({ children }) => (
  <Layout columns={2} feed leftColumnOpen={false} rightColumnOpen={false}>
    <ProfileContent>{children}</ProfileContent>
    <Sidebar />
  </Layout>
);

Profile.propTypes = {
  children: PropTypes.node.isRequired,
};

Profile.defaultProps = {
};

export default Profile;
