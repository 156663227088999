import { useState, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import Api from 'state/api';

const useSearchUsers = (bypassBlocks = false) => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const search = useCallback(async (content: string) => {
    if (content) {
      try {
        const params: { bypassBlocks?: boolean } = {};
        if (bypassBlocks) params.bypassBlocks = true;

        const { data } = await Api.req.get(`/users/search/${content}`, { params });
        setResults(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  }, [bypassBlocks]);

  const [debouncedCallback] = useDebouncedCallback(search, 1000);

  const contentChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const content = event.target.value;
    setValue(content);
    setResults([]);

    if (content.length > 2) {
      setLoading(!!content);
      debouncedCallback(content);
    }
  }, [debouncedCallback]);

  const reset = useCallback(() => {
    setResults([]);
    setValue('');
  }, []);

  return [
    {
      value,
      loading,
      contentChange,
      hasResults: (results.length > 0),
    },
    results,
    reset,
  ] as const;
};

export default useSearchUsers;
