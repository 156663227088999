import styled from 'styled-components';

const Description = styled.div`
  margin-right: 40px;

  @media(max-width: 767px) {
    margin-right: 0;
  }

  .fact {
    list-style: none;
    padding: 0;
    margin: 0 0 10px;
    font-size: 22px;
    color: white;
    line-height: 36px;
    display: flex;

    @media(max-width: 767px) {
      font-size: 16px;
      line-height: 18px;
    }

    span {
      flex: 1;
    }

    svg {
      width: 26px;
      height: 26px;
      margin-right: 15px;

      @media(max-width: 767px) {
        width: 16px;
        margin-right: 15px;
      }
    }
  }
`;

export default Description;
