import {
  useContext, useMemo, useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { Picker } from 'emoji-mart';
import fastdom from 'fastdom';

import isMobile from 'utils/isMobile';
import { useTranslation } from 'hooks';

import customemojis from 'components/Composer/customemojis';

import locales from './i18n';

const custom = customemojis();

interface WrapperProps extends React.HTMLProps<HTMLDivElement> {
  autoPositioning?: boolean;
  position: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  };
}

const Wrapper = styled.div<WrapperProps>`
  ${props => props.autoPositioning && `
    position: absolute;
    z-index: 10;

    // To do: add manual aligning through props (left-right-top-bottom)
    ${props.position.left && `left: ${props.position.left}`};
    ${props.position.right && `right: ${props.position.right}`};
    ${props.position.top && `top: ${props.position.top}`};
    ${props.position.bottom && `bottom: ${props.position.bottom}`};

    @media(max-width: 767px) {
      position: fixed;
      bottom: 0;
      left: 0;
    }
  `}

  // To avoid triggering mouseEnter and mouseLeave before autopositioning
  ${props => props.autoPositioning && !Object.keys(props.position || {}).length && `
    visibility: hidden;
  `}

  .emoji-mart-search-icon svg {
    width: 16px;
    height: 16px;
  }
`;
Wrapper.displayName = 'Wrapper';

interface Props {
  onSelect: (emoji: any) => void;
  onMouseLeave?: () => void;
  autoPositioning?: boolean;
}

const EmojiPicker = ({ onMouseLeave, onSelect, autoPositioning }: Props) => {
  const { t } = useTranslation(locales);
  const theme = useContext(ThemeContext);

  const element = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState({});

  useLayoutEffect(() => {
    if (element.current) {
      fastdom.measure(() => {
        const rect = element.current?.getBoundingClientRect();
        const newStyle: WrapperProps['position'] = {};

        if (rect) {
          // -1px to make the box appear below the cursor,
          // so anytime the user moves it away from the picker `mouseLeave` executes.
          if (rect.top < (document.body.offsetHeight / 2)) {
            newStyle.top = '-24px';
          } else {
            newStyle.bottom = '-24px';
          }

          if (rect.left < (document.body.offsetWidth / 2)) {
            newStyle.left = '-24px';
          } else {
            newStyle.right = '-24px';
          }
        }

        setStyle(newStyle);
      });
    }
  }, []);

  const i18n = useMemo(() => ({
    search: t('Search'),
    clear: t('Clear'),
    notfound: t('No Emoji Found'),
    skintext: t('Choose your default skin tone'),
    categories: {
      search: t('Search Results'),
      recent: t('Frequently Used'),
      smileys: t('Smileys & Emotion'),
      people: t('People & Body'),
      nature: t('Animals & Nature'),
      foods: t('Food & Drink'),
      activity: t('Activity'),
      places: t('Travel & Places'),
      objects: t('Objects'),
      symbols: t('Symbols'),
      flags: t('Flags'),
      custom: t('Custom'),
    },
    categorieslabel: t('Emoji categories'),
    skintones: {
      1: t('Default Skin Tone'),
      2: t('Light Skin Tone'),
      3: t('Medium-Light Skin Tone'),
      4: t('Medium Skin Tone'),
      5: t('Medium-Dark Skin Tone'),
      6: t('Dark Skin Tone'),
    },
  }), [t]);

  return (
    <Wrapper
      ref={element}
      position={style}
      autoPositioning={autoPositioning}
      onMouseLeave={onMouseLeave}
    >
      <Picker
        set="apple"
        color={theme.colors.main}
        onSelect={onSelect}
        i18n={i18n}
        custom={custom}
        showPreview={false}
        emoji="mazmo"
        title="Mazmo"
        perLine={isMobile ? 6 : 9}
      />
    </Wrapper>
  );
};

EmojiPicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func,
  autoPositioning: PropTypes.bool,
};

EmojiPicker.defaultProps = {
  autoPositioning: true,
  onMouseLeave: undefined,
};

export default EmojiPicker;
