import styled from 'styled-components';

import { POLL_COLORS, FEED_TEMP_PATH } from '../../../../constants';

const ColoredOption = styled.div`
  ${props => props.color && `background-color: ${POLL_COLORS[props.color]};`}
  ${props => props.image && `background-image: url(${FEED_TEMP_PATH}/${props.image});`}
  height: 400px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;

  @media(max-width: 768px) {
    height: calc(100vw / 2 * 1.198);
  }

  div {
    color: white;
    background: rgba(0, 0, 0, .5);
    border-radius: 8px;
    padding: 8px;
    font-size: 12px;
  }
`;
ColoredOption.displayName = 'ColoredOption';

export default ColoredOption;
