import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as eventSelectors from 'state/events/selectors';

import locales from '../i18n';

const Wrapper = styled.div`
  margin-bottom: 16px;

  h3 {
    font-size: 16px;
    color: #333;
  }

  .sponsors {
    line-height: 24px;

    a {
      color: ${props => props.theme.colors.main};
      font-weight: 500;
      font-size: 18px;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

const Sponsors = ({ eventId }) => {
  const { t } = useTranslation(locales);

  const sponsors = useSelector(
    state => eventSelectors.selectSponsors(state, eventId),
    shallowEqual,
  );

  if (!sponsors.length) return null;

  return (
    <Wrapper>
      <h3>{t('Sponsors')}</h3>
      <div className="sponsors">
        {sponsors.map(sponsor => (
          <div key={`sponsor-${sponsor.name}`}>
            <a href={sponsor.link} target="_blank" rel="noreferrer noopener">{sponsor.name}</a>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

Sponsors.propTypes = {
  eventId: PropTypes.string.isRequired,
};

Sponsors.defaultProps = {
};

export default Sponsors;
