import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const ChevronDown = memo(({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
    />
  </svg>
));

ChevronDown.propTypes = IconType;
ChevronDown.defaultProps = IconDefaults;

export default ChevronDown;
