/* eslint-disable react-hooks/exhaustive-deps */

import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import fastdom from 'fastdom';

import colors from 'utils/css/colors';
import isMobile from 'utils/isMobile';

const Tooltip = styled.div<React.HTMLProps<HTMLDivElement>>`
  color: ${colors.red};
  text-transform: uppercase;
  font-size: 10px;
  position: absolute;
  top: -16px;
  visibility: hidden;
  white-space: nowrap;
`;

const HoverableContainer = styled.div<React.HTMLProps<HTMLDivElement>>`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;

  &:hover {
    ${Tooltip} {
      visibility: visible;
    }
  }
`;

interface Props {
  normal: React.ReactNode;
  hover: React.ReactNode;
  tooltip?: React.ReactNode;
}

const Hoverable: React.FC<Props> = ({ normal, hover, tooltip }) => {
  const el = useRef<HTMLDivElement>(null);
  const elTooltip = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = () => {
    if (!isMobile) setIsHovered(true);
  };
  const onMouseLeave = () => {
    if (!isMobile) setIsHovered(false);
  };

  useEffect(() => {
    const element = el.current;

    if (element) {
      el.current.addEventListener('mouseenter', onMouseEnter);
      el.current.addEventListener('mouseleave', onMouseLeave);
    }

    return () => {
      if (element) {
        element.removeEventListener('mouseenter', onMouseEnter);
        element.removeEventListener('mouseleave', onMouseLeave);
      }
    };
  }, []);

  useEffect(() => {
    if (tooltip) {
      fastdom.measure(() => {
        if (elTooltip.current && el.current) {
          const tw = elTooltip.current.offsetWidth;
          const ew = el.current.offsetWidth;

          fastdom.mutate(() => {
            if (elTooltip.current) {
              elTooltip.current.style.left = `${(-tw / 2) + (ew / 2)}px`;
            }
          });
        }
      });
    }
  }, [tooltip]);

  return (
    <HoverableContainer ref={el}>
      {tooltip && <Tooltip ref={elTooltip}>{tooltip}</Tooltip>}
      {isHovered ? hover : normal}
    </HoverableContainer>
  );
};

export default Hoverable;
