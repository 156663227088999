import { useState, useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useTitle, useTranslation, useHotKeys } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as channelSelectors from 'state/channels/selectors';
import * as messengerSelectors from 'state/messengers/selectors';
import * as messengerActions from 'state/messengers/actions';
import * as channelActions from 'state/channels/actions';
import * as appActions from 'state/app/actions';

import ErrorState from 'components/ErrorState';
import Layout from 'components/Layout';

import List from './List';
import Content from './DashboardContent';
import QuickSearchModal from './QuickSearchModal';
import locales from './i18n';
import LoadingDashboard from './LoadingDashboard';

const ChatDashboard = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation(locales);

  const userIsLoggedIn = useSelector(authSelectors.loggedIn);
  const isChannelsInitialized = useSelector(channelSelectors.isInitialized);
  const isMessengerInitialized = useSelector(messengerSelectors.isInitialized);
  const messengerHasFailed = useSelector(messengerSelectors.hasFailed);

  const [showingQuickSearchModal, setShowingQuickSearchModal] = useState(false);

  useEffect(() => {
    if (!params.chatId || !userIsLoggedIn) {
      dispatch(appActions.uiLeftColumn(true));
    } else {
      dispatch(appActions.uiLeftColumn(false));
    }
  }, [params.chatId, dispatch, userIsLoggedIn]);

  const showQuickSearchModal = () => setShowingQuickSearchModal(true);
  const hideQuickSearchModal = () => setShowingQuickSearchModal(false);

  useHotKeys([{ key: 'k', actionKey: true }], (e) => { showQuickSearchModal(); e.preventDefault(); });
  useHotKeys([{ key: 'Escape', shiftKey: true }], (e) => {
    batch(() => {
      dispatch(messengerActions.markAllAsRead());
      dispatch(channelActions.markAllAsRead());
    });
    e.preventDefault();
  });

  useTitle(t('global:Chat'));

  if (userIsLoggedIn && (!isMessengerInitialized || !isChannelsInitialized)) return <LoadingDashboard />;
  if (messengerHasFailed) return <ErrorState message={t('error.fetch')} />;

  return (
    <>
      <Layout columns={userIsLoggedIn ? 2 : 1}>
        {userIsLoggedIn && <List />}
        <Content />
      </Layout>
      {showingQuickSearchModal && <QuickSearchModal close={hideQuickSearchModal} />}
    </>
  );
};

ChatDashboard.propTypes = {
};

export default ChatDashboard;
