import produce from 'immer';

import {
  ADD,
  ADD_FAILED_USERNAME,
  ONLINE_LIST,
  ONLINE,
  OFFLINE,
  SUGGESTIONS_LOAD,
  SUGGESTIONS_CLEAR,
} from './constants';

export const initialState = {
  data: {},
  usernames: {},
  online: [],
  userFetchQueue: new Set(),
  userFetching: false,
  failedUsernames: [],
  suggestions: null,
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case ADD:
      Object.values(action.data || {}).forEach((user) => {
        draft.usernames[user.username] = user.id;
        draft.data[user.id] = {
          ...state.data[user.id],
          ...user,
        };
      });
      break;

    case ADD_FAILED_USERNAME:
      draft.failedUsernames.push(action.username);
      break;

    case ONLINE_LIST:
      draft.online = action.data;
      break;

    case ONLINE:
      draft.online.push(action.userId);
      break;

    case OFFLINE:
      draft.online.splice(draft.online.findIndex(id => id === action.userId), 1);
      break;

    case SUGGESTIONS_LOAD:
      draft.suggestions = action.data.list;
      break;

    case SUGGESTIONS_CLEAR:
      draft.suggestions = initialState.suggestions;
      break;

    default:
  }
});

export default reducer;
