/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'utils/css/colors';

import Spinner from 'components/Spinner';

const GlobalContainer = styled.label`
  display: flex;
  align-items: center;
  flex: 1;
  cursor: pointer;
`;

const InputContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 37px;
  height: 22px;

  > input {
    display: none;
  }
`;

const Input = styled.input`
  &:checked + span {
    background-color: ${colors.red};
  }
  &:disabled + span {
    background-color: ${colors.grey};
    opacity: 0.4;
    cursor: not-allowed;
  }
  &:disabled:checked + span {
    background-color: ${colors.red};
    opacity: 0.4;
    cursor: not-allowed;
  }
  &:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }
  &:checked + span:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.grey};
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 34px;
  &:before {
    position: relative;
    border-radius: 50%;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    background-color: #fff;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }
`;

const Label = styled.div`
  ${params => `padding-${params.position}`}: 10px;
  flex: 1;
`;

const Toggle = ({
  label,
  active,
  onChange,
  disabled,
  loading,
  position,
}) => (
  <GlobalContainer className="prevent-close">
    {position === 'right' && <Label position={position}>{label}</Label>}
    <InputContainer>
      {loading
        ? (
          <Spinner color={colors.grey} />
        ) : (
          <>
            <Input
              type="checkbox"
              checked={active}
              disabled={disabled}
              onChange={onChange}
              className="prevent-close"
            />
            <Slider />
          </>
        )
      }
    </InputContainer>
    {position === 'left' && <Label position={position}>{label}</Label>}
  </GlobalContainer>
);

Toggle.propTypes = {
  label: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right']),
};

Toggle.defaultProps = {
  disabled: false,
  loading: false,
  position: 'right',
  onChange: null,
};

export default Toggle;
