import { memo } from 'react';
import styled from 'styled-components';

const Busy = styled.span`
  animation: animation 1.25s forwards infinite linear;
  background: #F6F6F6;
  background: linear-gradient(to right,#DDD 8%,#F0F0F0 18%,#DFDFDF 33%);
  background-size: 800px 104px;
  position: relative;
  height: 12px;
  width: 100px;
  color: transparent;
  border-radius: 8px;

  &:before {
    content: 'Loading...';
    opacity: 0;
  }

  @keyframes animation {
    0% {
      background-position: -468px 0
    }
    100% {
      background-position: 468px 0
    }
  }
`;
Busy.displayName = 'Busy';

export default memo(Busy);
