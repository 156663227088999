import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { useTranslation } from 'hooks';

import Button from 'components/Button';
import EmptyState from 'components/EmptyState';
import { FlexWrapper, FlexInnerWrapper } from 'components/FlexWrapper';
import PurchaseButton from 'components/PurchaseButton';

import locales from '../../i18n';

const Buttons = styled.div`
  display: flex;
  margin: 32px auto;
  justify-content: space-around;
  max-width: 700px;
`;
Buttons.displayName = 'Buttons';

const Exhausted = () => {
  const { t } = useTranslation(locales);

  return (
    <FlexWrapper mobileHasHeader canOverflow>
      <FlexInnerWrapper>
        <EmptyState
          title={t('Exhausted Requests')}
          subtitle={(
            <Trans
              t={t}
              i18nKey="chat.requests.exhausted"
              ns="chatDashboard"
            >
              {'It seems that you used all the requests available. You can wait until next Monday for a refill, or '}
              <strong>get more</strong>
              .

              <Buttons>
                <PurchaseButton itemName="CHAT_REQUESTS_5" component={<Button color="white" fontColor="black">{t('Add 5 more')}</Button>} />
                <PurchaseButton itemName="CHAT_REQUESTS_20" component={<Button>{t('Add 20 more')}</Button>} />
                <PurchaseButton itemName="CHAT_REQUESTS_50" component={<Button color="white" fontColor="black">{t('Add 50 more')}</Button>} />
              </Buttons>
            </Trans>
          )}
        />
      </FlexInnerWrapper>
    </FlexWrapper>
  );
};

Exhausted.propTypes = {
};

Exhausted.defaultProps = {
};

export default Exhausted;
