const colors = {
  red: '#ED4D3D',
  blackRed: '#331A1A',
  borderRed: '#F5EFEF',
  redReactions: '#6F6666',
  grey: '#A8A8A8',
  lightBackground: '#FAFAFA',
  h1: '#312626',
  approve: '#4caf50',
  secondaryListItem: '#fdfbfb',
  actionListItemBG: '#F5EFEF',
  mainDark: '#bb3c30',
};

export default colors;
