import PropTypes from 'prop-types';
import styled from 'styled-components';
import SafeForWorkLayer from './SafeForWorkLayer';

const VideoContainer = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;

  & > div {
    margin-top: -30px;
  }

  iframe, object, embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
VideoContainer.displayName = 'VideoContainer';

const VideoLink = ({ data }) => (
  <VideoContainer>
    <SafeForWorkLayer />
    <iframe
      title={data.ogTitle || data.title}
      src={data.ogVideoSecureUrl || data.twitterPlayer}
      frameBorder="0"
      allowFullScreen
    />
  </VideoContainer>
);

VideoLink.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    ogTitle: PropTypes.string,
    ogVideoWidth: PropTypes.string,
    twitterPlayerWidth: PropTypes.string,
    ogVideoHeight: PropTypes.string,
    twitterPlayerHeight: PropTypes.string,
    ogVideoSecureUrl: PropTypes.string,
    twitterPlayer: PropTypes.string,
  }).isRequired,
};

export default VideoLink;
