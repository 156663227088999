import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import * as channelActions from 'state/channels/actions';
import * as appActions from 'state/app/actions';

import { useTranslation } from 'hooks';

import Modal from 'components/Modal';
import Button from 'components/Button';

import { CHANNEL_ROLES } from '../../../../constants';
import locales from '../../i18n';

const UnmakeModConfirmModal = ({ user, channelId, close }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const [unmakingMod, setUnmakingMod] = useState(false);

  const unmakeMod = async () => {
    try {
      setUnmakingMod(true);
      await dispatch(channelActions.changeRole(channelId, user.id, CHANNEL_ROLES.USER));
      dispatch(appActions.addToast(t('User is not longer a mod', { context: user.pronoun })));
      setUnmakingMod(false);
      close();
    } catch (error) {
      dispatch(appActions.addError(error));
      setUnmakingMod(false);
      close();
    }
  };

  return (
    <Modal
      title={t('Unmake mod', { context: user.pronoun })}
      onCancel={close}
      actions={[
        <Button key={`unmakemod-${user.id}`} onClick={unmakeMod} loading={unmakingMod}>{t('global:Confirm')}</Button>,
      ]}
    >
      {t('Are you sure you want to unmake {{user}} a mod of this channel?', { user: user.displayname })}
    </Modal>
  );
};

UnmakeModConfirmModal.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayname: PropTypes.string.isRequired,
    pronoun: PropTypes.string,
  }).isRequired,
  channelId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default UnmakeModConfirmModal;
