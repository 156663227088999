import { useEffect } from 'react';

import { META_DESCRIPTION } from '../constants';

const useMetaDescription = (content = META_DESCRIPTION) => {
  useEffect(() => {
    document.querySelector('meta[name="description"]').content = content;

    return () => {
      document.querySelector('meta[name="description"]').content = META_DESCRIPTION;
    };
  }, [content]);
};

export default useMetaDescription;
