import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as messengerSelectors from 'state/messengers/selectors';
import * as userSelectors from 'state/users/selectors';

import Avatar from 'components/UserAvatar';
import MainHeader from 'components/Header';

import Typing from './Typing';
import Actions from './Actions';
import locales from '../../i18n';

const Archived = styled.span`
  color: #aaa;
  font-size: 14px;
  margin-left: 10px;
  font-style: italic;
`;

const MessengerHeader = ({ messengerId }) => {
  const history = useHistory();
  const { t } = useTranslation(locales);

  const isArchived = useSelector(state => messengerSelectors.isArchived(state, messengerId));
  const userId = useSelector(state => messengerSelectors.getUserId(state, messengerId));
  const user = useSelector(userSelectors.getById(userId), shallowEqual);

  const close = useCallback(() => {
    history.push('/chat');
  }, [history]);

  const renderAvatar = useCallback(() => (
    <Link to={`/@${user.username}`} className="userlink"><Avatar userId={userId} /></Link>
  ), [user.username, userId]);

  const renderTitle = useCallback(() => (
    <>
      <Link to={`/@${user.username}`} className="userlink">{user.displayname}</Link>
      {isArchived && <Archived>{t('(archived conversation)')}</Archived>}
    </>
  ), [user.displayname, user.username, isArchived, t]);

  const renderInfo = useCallback(() => (
    <Typing messengerId={messengerId} />
  ), [messengerId]);

  const renderActions = useCallback(() => (
    <Actions messengerId={messengerId} />
  ), [messengerId]);

  return (
    <MainHeader
      renderTitle={renderTitle}
      renderAvatar={renderAvatar}
      renderInfo={renderInfo}
      renderActions={renderActions}
      onBackClick={close}
    />
  );
};

MessengerHeader.propTypes = {
  messengerId: PropTypes.string.isRequired,
};

MessengerHeader.defaultProps = {
};

export default MessengerHeader;
