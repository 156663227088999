import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import * as appSelectors from 'state/app/selectors';
import { useTranslation, useFocusOnMount, useChatList } from 'hooks';

import Modal from 'components/Modal';
import Input from 'components/Forms/Input';
import { SelectableList } from 'components/SelectableList';

import Messenger from './Messenger';
import Channel from './Channel';
import locales from '../i18n';

const QuickSearchModal = ({ close }) => {
  const history = useHistory();
  const { t } = useTranslation(locales);
  const inputEl = useFocusOnMount();
  const [filter, setFilter] = useState('');
  const [selected, setSelected] = useState(0);
  const locationHistory = useSelector(appSelectors.selectUrlHistory, shallowEqual);

  const [filteredEntities, archivedMessengers] = useChatList(filter);
  const unsortedEntities = filteredEntities.concat(archivedMessengers);

  const entities = unsortedEntities.sort((a, b) => {
    const aIndex = locationHistory.findIndex(l => l.includes(`${a.type}s/${a.id}`));
    const bIndex = locationHistory.findIndex(l => l.includes(`${b.type}s/${b.id}`));

    if (aIndex < 1) return 1;
    if (bIndex < 1) return -1;

    return aIndex - bIndex;
  });

  const onFilterChange = (e) => {
    setSelected(0);
    setFilter(e.target.value);
  };
  const onSelect = id => () => {
    const entity = entities.find(data => data.id === id);
    history.push(`/chat/${entity.type}s/${id}`);
    close();
  };
  const onKeyDown = (e) => {
    if (e.key === 'Escape') {
      close();
    } else if (e.key === 'ArrowUp') {
      if (selected > 0) setSelected(state => state - 1);
    } else if (e.key === 'ArrowDown') {
      if (selected < entities.length - 1) setSelected(state => state + 1);
    } else if (e.key === 'Enter') {
      onSelect(entities[selected].id)();
    }
  };

  return (
    <Modal
      onClose={close}
      showActions={false}
    >
      <Input
        ref={inputEl}
        placeholder={t('Search in your chats')}
        onChange={onFilterChange}
        value={filter}
        onKeyDown={onKeyDown}
      />
      <SelectableList>
        {entities.map(({ type, id }, index) => {
          if (type === 'messenger') return <Messenger key={`quicksearch-messenger-${id}`} id={id} onSelect={onSelect} active={index === selected} />;
          return (
            <Channel key={`quicksearch-channel-${id}`} id={id} onSelect={onSelect} active={index === selected} />
          );
        })}
      </SelectableList>
    </Modal>
  );
};

QuickSearchModal.propTypes = {
  close: PropTypes.func.isRequired,
};

export default QuickSearchModal;
