import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as communitySelectors from 'state/communities/selectors';

import { SelectableListItem } from 'components/SelectableList';
import CommunityAvatar from 'components/CommunityAvatar';

const Community = ({ communityId, closeModal }) => {
  const name = useSelector(state => communitySelectors.selectName(state, communityId));
  const slug = useSelector(state => communitySelectors.selectSlugById(state, communityId));

  return (
    <SelectableListItem
      key={`languages-${communityId}`}
      title={name}
      to={`/+${slug}/new`}
      avatar={<CommunityAvatar communityId={communityId} />}
      onClick={closeModal}
    />
  );
};

Community.propTypes = {
  communityId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

Community.defaultProps = {
};

export default Community;
