import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'utils/css/colors';

const Wrapper = styled.div`
  background-color: ${colors.red};
  width: 48px;
  height: 48px;
  position: absolute;
  right: -24px;
  top: -24px;
  transform: rotate(45deg);

  ${props => !props.children && `
    background-color: #fdaaa1;
  `}
`;

const Content = styled.div`
  color: white;
  line-height: 78px;
  letter-spacing: -2px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  font-size: 14px;
  transform: rotate(-45deg);
`;

const Unread = memo(({ children }) => (
  <Wrapper hasContent={!!children}>
    {children && <Content>{children}</Content>}
  </Wrapper>
));

Unread.propTypes = {
  children: PropTypes.node,
};

Unread.defaultProps = {
  children: null,
};

export default Unread;
