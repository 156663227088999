import { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appSelectors from 'state/app/selectors';

import Button from 'components/Button';
import Spinner from 'components/Spinner';

import locales from './i18n';

const NewVersionSnackBarWrapper = styled.div`
  position: fixed;
  left: calc((100vw - 300px) / 2);
  bottom: 32px;
  z-index: 9999;
  background-color: ${props => props.theme.colors.main};
  width: 300px;
  box-sizing: border-box;
  padding: 16px;
  color: white;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0px 1px 9px 0px black;
`;
NewVersionSnackBarWrapper.displayName = 'NewVersionSnackBarWrapper';

const NewVersionSnackBar = () => {
  const { t } = useTranslation(locales);

  const [reloading, setReloading] = useState(false);
  const waitingSW = useSelector(appSelectors.selectWaitingSW);

  if (!waitingSW) return null;

  const reload = () => {
    waitingSW.postMessage({ type: 'SKIP_WAITING' });
    setReloading(true);
    window.location.reload(true);
  };

  return (
    <NewVersionSnackBarWrapper>
      <div>
        <span role="img" aria-label="New version">🎉</span>
        {t('New version available!')}
      </div>

      {reloading
        ? <Spinner color="white" />
        : <Button light onClick={reload}>{t('Reload')}</Button>
      }
    </NewVersionSnackBarWrapper>
  );
};

NewVersionSnackBar.propTypes = {
};

NewVersionSnackBar.defaultProps = {
};

export default NewVersionSnackBar;
