export const LOAD = 'mazmo/communitites/LOAD';
export const LOAD_SUCCESS = 'mazmo/communitites/LOAD_SUCCESS';
export const LOAD_FAIL = 'mazmo/communitites/LOAD_FAIL';

export const CREATE = 'mazmo/communitites/CREATE';
export const CREATE_SUCCESS = 'mazmo/communitites/CREATE_SUCCESS';
export const CREATE_FAIL = 'mazmo/communitites/CREATE_FAIL';

export const REMOVE = 'mazmo/communitites/REMOVE';

export const PREPEND_THREADS = 'mazmo/communitites/PREPEND_THREADS';
export const APPEND_THREADS = 'mazmo/communitites/APPEND_THREADS';

export const UPDATE_MOD_ROLE = 'mazmo/communitites/UPDATE_MOD_ROLE';
export const REMOVE_MOD = 'mazmo/communitites/REMOVE_MOD';

export const ADD_MEMBERSHIP_COUNT = 'mazmo/communitites/ADD_MEMBERSHIP_COUNT';

export const WIPE = 'mazmo/communitites/WIPE';
