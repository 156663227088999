import { useSelector } from 'react-redux';
import {
  IconButton, Menu, MenuButton, MenuList, MenuItem,
} from '@chakra-ui/react';
import { EllipsisVertical } from 'lucide-react';

import { useTranslation, useOpenClose } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as userSelectors from 'state/users/selectors';

import UnknowModal from '../Modals/Unknow';
import BlockModal from '../Modals/Block';
import UnblockModal from '../Modals/Unblock';
import ReportModal from '../Modals/Report';
import locales from '../../i18n';

interface Props {
  userId: number;
}

const RestrictedActions: React.FC<Props> = ({ userId }) => {
  const { t } = useTranslation(locales);

  const pronoun = useSelector(userSelectors.getPronoun(userId));
  // @ts-ignore
  const knowing = useSelector(state => authSelectors.selectDoIKnow(state, userId));
  // @ts-ignore
  const blocked = useSelector(state => authSelectors.selectIsBlocked(state, userId));

  const [showingUnknowModal, openUnknowModal, closeUnknowModal] = useOpenClose();
  const [showingBlockModal, openBlockModal, closeBlockModal] = useOpenClose();
  const [showingUnblockModal, openUnblockModal, closeUnblockModal] = useOpenClose();
  const [showingReportModal, openReportModal, closeReportModal] = useOpenClose();

  return (
    <>
      <Menu>
        <MenuButton variant="ghost" as={IconButton} icon={<EllipsisVertical style={{ width: '20px' }} />} />
        <MenuList>
          {knowing && <MenuItem onClick={openUnknowModal}>{t('Remove as known', { context: pronoun })}</MenuItem>}
          {!blocked && <MenuItem onClick={openBlockModal}>{t('Block')}</MenuItem>}
          {blocked && <MenuItem onClick={openUnblockModal}>{t('Unblock')}</MenuItem>}
          <MenuItem onClick={openReportModal}>{t('Report')}</MenuItem>
        </MenuList>
      </Menu>

      {/* Modals */}
      {showingUnknowModal && <UnknowModal userId={userId} close={closeUnknowModal} />}
      {showingBlockModal && <BlockModal userId={userId} close={closeBlockModal} />}
      {showingUnblockModal && <UnblockModal userId={userId} close={closeUnblockModal} />}
      {showingReportModal && <ReportModal userId={userId} close={closeReportModal} />}
    </>
  );
};

export default RestrictedActions;
