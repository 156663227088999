import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import isMobile from 'utils/isMobile';
import * as appActions from 'state/app/actions';

import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

const ServiceWorkerWrapper = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const onSWUpdate = (registration) => {
      dispatch(appActions.swWaiting(registration.waiting));
    };

    if (isMobile) {
      serviceWorkerRegistration.register({
        onUpdate: onSWUpdate,
      });
    } else {
      serviceWorkerRegistration.unregister();
    }
  }, [dispatch]);

  return null;
};

ServiceWorkerWrapper.propTypes = {
};

ServiceWorkerWrapper.defaultProps = {
};

export default ServiceWorkerWrapper;
