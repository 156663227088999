import { IconType, IconDefaults } from './type';

const normalPath = 'M11,7A2,2 0 0,0 9,9V11A2,2 0 0,0 11,13H13V15H9V17H13A2,2 0 0,0 15,15V13A2,2 0 0,0 13,11H11V9H15V7H11M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z';
const outlinePath = 'M11,7H15V9H11V11H13A2,2 0 0,1 15,13V15A2,2 0 0,1 13,17H9V15H13V13H11A2,2 0 0,1 9,11V9A2,2 0 0,1 11,7M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z';

const AlphaSCircle = ({ color, className, outline }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d={outline ? outlinePath : normalPath}
      fill={color}
    />
  </svg>
);

AlphaSCircle.propTypes = IconType;
AlphaSCircle.defaultProps = IconDefaults;

export default AlphaSCircle;
