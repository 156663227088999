import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const ChatRequest = memo(({ outline, color }) => {
  if (outline) {
    return (
      <svg viewBox="0 0 24 24">
        <path fill={color} d="M13,10H11V6H13V10M13,12H11V14H13V12M22,4V16A2,2 0 0,1 20,18H6L2,22V4A2,2 0 0,1 4,2H20A2,2 0 0,1 22,4M20,4H4V17.2L5.2,16H20V4Z" />
      </svg>
    );
  }

  return (
    <svg viewBox="0 0 24 24">
      <path fill={color} d="M13,10H11V6H13M13,14H11V12H13M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4C22,2.89 21.1,2 20,2Z" />
    </svg>
  );
});

ChatRequest.propTypes = IconType;
ChatRequest.defaultProps = IconDefaults;

export default ChatRequest;
