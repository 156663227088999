import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.outgoing ? 'row-reverse' : 'row')};
  align-items: flex-start;
  margin: ${props => (props.hasAvatar ? '8' : '-6')}px 0 8px;
  font-size: 15px;
  line-height: 18px;
  opacity: ${props => (props.sending ? 0.3 : 1)};
  max-width: 100vh;

  ${props => props.outgoing && `
    margin-left: auto;
  `}

  ${props => props.remove && `
    transition: transform 1.5s 0s, opacity 1.25s 0s;
    transform: translateX(${(props.outgoing ? 1 : -1) * 100}vw);
    opacity: 0;

    .message-bubble {
      background-color: ${props.theme.colors.main};
    }
  `}
`;
Wrapper.displayName = 'BubbleWrapper';

export default Wrapper;
