import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import Api from 'state/api';
import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';
import * as appActions from 'state/app/actions';

import Modal from 'components/Modal';
import Loading from 'components/Loading';
import Button from 'components/Button';
import UserDisplayName from 'components/UserDisplayName';

import locales from '../../i18n';

const BotWrapper = styled.div`
  display: flex;

  img {
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }

  .info {
    flex: 1;

    .name {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .createdby {
    font-size: 12px;
  }

  .actions {
    margin-left: 8px;
  }
`;
BotWrapper.displayName = 'BotWrapper';

const BotsModal = ({ channelId, close }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const botsInChannel = useSelector(
    state => channelSelectors.botIds(state, channelId),
    shallowEqual,
  );
  const [bots, setBots] = useState(null);
  const [loading, setLoading] = useState([]);

  const load = useCallback(async () => {
    setBots(null);
    const { data } = await Api.req.get('/chat/bots');
    setBots(data);
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const add = useCallback(id => async () => {
    try {
      setLoading(currentValue => [...currentValue, id]);
      await Api.req.post(`/chat/channels/${channelId}/bots`, { botId: id });
      dispatch(appActions.addToast(t('Bot added to channel')));
    } catch (error) {
      dispatch(appActions.addError(error));
    }

    close();
  }, [dispatch, t, close, channelId]);

  const remove = useCallback(id => async () => {
    try {
      setLoading(currentValue => [...currentValue, id]);
      await Api.req.delete(`/chat/channels/${channelId}/bots/${id}`);
      dispatch(appActions.addToast(t('Bot removed from channel')));
    } catch (error) {
      dispatch(appActions.addError(error));
    }

    close();
  }, [dispatch, t, close, channelId]);

  const renderContent = () => {
    if (bots === null) return <Loading />;

    return (
      <div>
        {bots.map(bot => (
          <BotWrapper key={`bot-modal-${bot.id}`}>
            <img src={bot.avatar} alt={bot.name} />
            <div className="info">
              <div className="name">{bot.name}</div>
              <div className="description">{bot.description}</div>
              <div className="createdby">
                {t('Created by')}
                {' '}
                <UserDisplayName userId={bot.ownerId} />
              </div>
            </div>
            <div className="actions">
              {botsInChannel.includes(bot.id)
                ? <Button onClick={remove(bot.id)} loading={loading.includes(bot.id)}>{t('global:Remove')}</Button>
                : <Button color="white" fontColor="black" onClick={add(bot.id)} loading={loading.includes(bot.id)}>{t('global:Add')}</Button>
              }
            </div>
          </BotWrapper>
        ))}
      </div>
    );
  };

  return (
    <Modal onClose={close}>
      {renderContent()}
    </Modal>
  );
};

BotsModal.propTypes = {
  channelId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

BotsModal.defaultProps = {
};

export default BotsModal;
