import { memo } from 'react';
import styled from 'styled-components';

export const Wrapper = memo(styled.div`
  display: flex;
  height: calc(100vh - 64px);
  position: relative;
  width: 100%;

  @media(max-width: 768px) {
    position: absolute;
  }
`);
Wrapper.displayName = 'Wrapper';

export const Body = memo(styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 16px;
  height: 100%;
  overflow: hidden;
  filter: blur(8px);
`);
Body.displayName = 'Body';

export const Participants = memo(styled.div`
  width: 200px;
  box-sizing: border-box;
  overflow: hidden;
  filter: blur(8px);

  @media(max-width: 768px) {
    display: none;
  }
`);
Participants.displayName = 'Participants';

export const Participant = memo(styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
`);
Participant.displayName = 'Participant';

export const Avatar = memo(styled.div`
  background: ${props => props.color};
  width: ${props => props.width}px;
  height: ${props => props.width}px;
  border-radius: 100%;
  margin-right: 8px;
`);
Avatar.displayName = 'Avatar';

export const Username = memo(styled.div`
  width: ${props => props.width}px;
  background-color: #ccc;
  height: 12px;
  margin-bottom: 8px;
`);
Username.displayName = 'Username';

export const Message = memo(styled.div`
  display: flex;
  margin: 8px 0;
`);
Message.displayName = 'Message';

export const Bubble = memo(styled.div`
  background-color: ${props => props.theme.colors.mainLight};
  padding: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  max-width: calc(100vw - 140px);
`);
Bubble.displayName = 'Bubble';

export const Content = memo(styled.div`
  display: flex;
`);
Content.displayName = 'Content';

export const Word = memo(styled.div`
  height: 10px;
  background-color: #ddd;
  width: ${props => props.width}px;
  margin-right: 4px;
`);
Word.displayName = 'Word';

export const Overlay = memo(styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .emptystate {
    height: 90%;

    .title {
      font-weight: 500;
    }

    .mobile-menu-button {
      display: none;
    }

    > div {
      margin: 0 32px;
    }
  }
`);
Overlay.displayName = 'Overlay';
