import { createSelector } from 'reselect';

import * as authSelectors from 'state/auth/selectors';
import * as userSelectors from 'state/users/selectors';

// Input selectors
const selectData = state => state.events.data;
//

export const selectAllIdsWithAssistance = createSelector(
  selectData,
  data => Object.keys(data).filter(id => data[id].features.assistance),
);

export const selectUpcomingIds = createSelector(
  selectData,
  data => Object.values(data).filter((event) => new Date(event.date) > new Date()),
);

export const selectById = createSelector(
  selectData,
  (_, id) => id,
  (data, eventId) => data[eventId],
);

export const isLoaded = createSelector(
  selectById,
  event => !!event,
);

export const selectName = createSelector(
  selectById,
  event => (event ? event.name : null),
);

export const selectFlyer = createSelector(
  selectById,
  event => (event ? event.cover : null),
);

export const selectAddressString = createSelector(
  selectById,
  (event) => {
    if (!event || !event.address) return null;

    const {
      streetNumber, intersection, adminArea, country, subdivision, route,
    } = event.address;
    const region = subdivision || adminArea;
    if (!streetNumber) {
      if (!intersection) return `${region}, ${country}`;
      return `${intersection}, ${region}, ${country}`;
    }
    return `${route} ${streetNumber}, ${region}, ${country}`;
  },
);

export const selectCityString = createSelector(
  selectById,
  (event) => {
    if (!event || !event.address) return null;

    const { adminArea, country } = event.address;
    return `${adminArea}, ${country}`;
  },
);

export const selectUrl = createSelector(
  selectById,
  (event) => {
    if (!event?.location) return null;
    return event.location.url;
  },
);

export const selectVerificationCode = createSelector(
  selectById,
  event => (event ? event.verificationCode : null),
);

export const selectInstructions = createSelector(
  selectById,
  event => (event ? event.instructions : null),
);

const selectLatLng = createSelector(
  selectById,
  event => event?.location?.coordinates || null,
);

export const selectLat = createSelector(
  selectLatLng,
  coordinates => (coordinates ? coordinates[0] : null),
);

export const selectLng = createSelector(
  selectLatLng,
  coordinates => (coordinates ? coordinates[1] : null),
);

export const selectDate = createSelector(
  selectById,
  event => (event ? event.date : null),
);

export const isPublic = createSelector(
  selectById,
  event => (event ? event.isPublic : false),
);

export const selectPrice = createSelector(
  selectById,
  event => (event ? event.price : null),
);

export const selectCurrency = createSelector(
  selectById,
  event => (event ? event.currency : null),
);

export const selectRsvpCount = createSelector(
  selectById,
  event => (event ? event.rsvpCount : null),
);

export const selectRsvps = createSelector(
  selectById,
  event => ((event && event.rsvps) ? event.rsvps : []),
);

export const selectRsvpsSorted = createSelector(
  selectById,
  userSelectors.selectData,
  (event, users) => {
    if (!event || !event.rsvps) return [];

    return event.rsvps.sort((a, b) => {
      const dnA = users[a.userId] ? users[a.userId].displayname.toLowerCase() : '';
      const dnB = users[b.userId] ? users[b.userId].displayname.toLowerCase() : '';

      if (dnA > dnB) return 1;
      if (dnB > dnA) return -1;
      return 0;
    });
  },
);

export const selectRsvpUserIds = createSelector(
  selectRsvps,
  rsvps => rsvps.map(r => r.userId),
);

export const selectBans = createSelector(
  selectById,
  event => ((event && event.bans) ? event.bans : []),
);

export const selectInvites = createSelector(
  selectById,
  event => ((event && event.invites) ? event.invites : []),
);

const selectOrganizers = createSelector(
  selectById,
  event => ((event && event.organizers) ? event.organizers : []),
);

export const selectOrganizerIds = createSelector(
  selectOrganizers,
  organizers => organizers.map(r => r.userId),
);

export const isOrganizer = createSelector(
  selectOrganizers,
  authSelectors.selectId,
  (organizers, meId) => meId && organizers.some(o => o.userId === meId),
);

export const hasRSVPd = createSelector(
  selectById,
  event => (event ? event.rsvpd : false),
);

export const hasEnrolled = createSelector(
  selectById,
  event => (event ? event.enrolled : false),
);

export const hasEnrollmentApproved = createSelector(
  selectById,
  event => (event ? event.enrolled && !!event.enrolled.approved : false),
);

export const selectRsvpLimit = createSelector(
  selectById,
  event => ((event && event.rsvpLimit) ? event.rsvpLimit : null),
);

export const selectIsEventRsvpFull = createSelector(
  selectById,
  event => ((event && event.rsvpLimit) ? event.rsvpLimit <= event.rsvpCount : false),
);

export const hasAssistanceFeatureEnabled = createSelector(
  selectById,
  event => ((!event || !event.features) ? false : event.features.assistance),
);

export const hasEarlyTicketsFeatureEnabled = createSelector(
  selectById,
  event => ((!event || !event.features) ? false : event.features.earlyTickets),
);

export const hasEnrollmentsFeatureEnabled = createSelector(
  selectById,
  event => ((!event || !event.features) ? false : event.features.enrollments),
);

export const selectQR = createSelector(
  selectById,
  event => (event ? event.qr : null),
);

export const selectSponsors = createSelector(
  selectById,
  event => (event ? event.sponsors : null),
);

export const selectLinks = createSelector(
  selectById,
  event => (event ? event.links : []),
);

export const selectAnnouncementLink = createSelector(
  selectLinks,
  links => links[0],
);

export const selectPreferenceInitPoint = createSelector(
  selectById,
  (event) => {
    if (!event || !event.payments || !event.payments.preference) return null;
    return event.payments.preference.init_point;
  },
);

export const isHighlighted = createSelector(
  selectById,
  event => ((event && event.features) ? event.features.highlight : false),
);

export const hasPendingPayment = createSelector(
  selectById,
  event => (!!event && !!event.payments && (event.payments.verified === false)),
);

export const paymentLink = createSelector(
  selectById,
  event => {
    if (!event || !event.payments || (!event.payments.preference && !event.payments.order)) return null;
    if (event.payments.preference) return event.payments.preference.init_point;
    return event.payments.order.links.find(l => l.rel === 'approve').href;
  },
);

export const eventAlreadyStarted = createSelector(
  selectDate,
  date => new Date(date) < new Date(),
);
