import {
  useState, useEffect, useCallback, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import * as messengerActions from 'state/messengers/actions';

import { Microphone, Check, Close } from 'components/Icons';

const MIMETYPE = 'audio/webm;codecs=opus';

const recordAudio = () => new Promise(async (resolve) => {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  const mediaRecorder = new MediaRecorder(stream, {
    audioBitsPerSecond: 128000, mimeType: MIMETYPE,
  });
  const audioChunks = [];

  mediaRecorder.addEventListener('dataavailable', (event) => {
    audioChunks.push(event.data);
  });

  const start = () => mediaRecorder.start();

  const stop = () => new Promise((res) => {
    mediaRecorder.addEventListener('stop', () => {
      const audioBlob = new Blob(audioChunks, { type: MIMETYPE });
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      const play = () => audio.play();
      res({ audioBlob, audioUrl, play });
    });

    mediaRecorder.stop();
    stream.getTracks().forEach(track => track.stop());
  });

  resolve({ start, stop, mediaRecorder });
});

const RecordButton = styled.button.attrs({
  type: 'button',
})`
  background: #eee;
  border: 0;
  border-radius: 100%;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  outline: none;

  svg {
    width: 24px;
    height: 24px;
  }
`;
RecordButton.displayName = 'RecordButton';

const RecordingWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 8px;
  top: 7px;
  z-index: 10;
  background-color: white;
`;
RecordingWrapper.displayName = 'RecordingWrapper';

const Clock = styled.div`
  display: flex;
  align-items: center;
  margin: 0 16px 0 0;
  color: #666;
`;
Clock.displayName = 'Clock';

const Recording = styled.div`
  width: 16px;
  height: 16px;
  font-size: 0;
  background-color: red;
  border: 0;
  border-radius: 16px;
  margin: 12px;

  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes pulse{
  0%{
    box-shadow: 0px 0px 5px 0px rgba(173,0,0,.3);
  }
  65%{
    box-shadow: 0px 0px 5px 6px rgba(173,0,0,.3);
  }
  90%{
    box-shadow: 0px 0px 5px 6px rgba(173,0,0,0);
  }
  }
`;
Recording.displayName = 'Recording';

const CancelButton = styled(RecordButton)`
  background: red;
`;
CancelButton.displayName = 'CancelButton';

const SubmitButton = styled(RecordButton)`
  background: green;
`;
SubmitButton.displayName = 'SubmitButton';

const AudioButton = ({ messengerId }) => {
  const dispatch = useDispatch();
  const recordingTimer = useRef(null);
  const recorderRef = useRef(null);

  const [recordingSeconds, setRecordingSeconds] = useState(null);
  const recording = recordingSeconds !== null;

  const startRecording = useCallback(() => setRecordingSeconds(0), []);
  const stopRecording = useCallback(async () => {
    if (recorderRef.current && recorderRef.current.mediaRecorder.state === 'recording') {
      await recorderRef.current.stop();
    }
    setRecordingSeconds(null);
  }, []);

  const submit = useCallback(async () => {
    const { audioBlob } = await recorderRef.current.stop();
    stopRecording();

    dispatch(messengerActions.createDirectMessage(messengerId, { hasAudio: true }, audioBlob));
  }, [stopRecording, dispatch, messengerId]);

  useEffect(() => {
    const timer = recordingTimer.current;
    const recorder = recorderRef.current;

    const initRecord = async () => {
      recorderRef.current = await recordAudio();
      recorderRef.current.start();

      recordingTimer.current = setInterval(() => {
        setRecordingSeconds(curVal => curVal + 1);
      }, 1000);
    };

    if (recording) {
      initRecord();
    } else {
      clearInterval(timer);
    }

    return () => {
      if (timer) clearInterval(timer);
      if (recorder && recorder.mediaRecorder.state === 'recording') recorder.stop();
    };
  }, [recording]);

  const cancelButton = useMemo(() => <CancelButton onClick={stopRecording}><Close color="white" /></CancelButton>, [stopRecording]);
  const submitButton = useMemo(() => <SubmitButton onClick={submit}><Check color="white" /></SubmitButton>, [submit]);
  const recordingBadge = useMemo(() => <Recording />, []);

  if (!recording) {
    return (
      <RecordingWrapper>
        <RecordButton onClick={startRecording}><Microphone color="#333" /></RecordButton>
      </RecordingWrapper>
    );
  }

  const clock = moment().startOf('day').seconds(recordingSeconds).format('mm:ss');
  return (
    <RecordingWrapper>
      {cancelButton}
      <Clock>
        {recordingBadge}
        <span>{clock}</span>
      </Clock>
      {submitButton}
    </RecordingWrapper>
  );
};

AudioButton.propTypes = {
  messengerId: PropTypes.string.isRequired,
};

AudioButton.defaultProps = {
};

export default AudioButton;
