import styled from 'styled-components';

const Grid = styled.div`
  padding-top: 48px;
  display: grid;
  grid-template-columns: minmax(100px, 1fr);
  grid-row-gap: calc(20px + 48px);
  grid-column-gap: 20px;
  @media(min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media(min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: calc(40px + 48px);
    grid-column-gap: 40px;
  }
  @media(min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export default Grid;
