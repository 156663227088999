import { IconType, IconDefaults } from './type';

const ArrowRightBoldBox = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M3,19V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19M17,12L12,7V10H8V14H12V17L17,12Z"
      fill={color}
    />
  </svg>
);

ArrowRightBoldBox.propTypes = IconType;
ArrowRightBoldBox.defaultProps = IconDefaults;

export default ArrowRightBoldBox;
