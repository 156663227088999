import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as appActions from 'state/app/actions';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as communityActions from 'state/communities/actions';
import * as channelActions from 'state/channels/actions';


import Modal from 'components/Modal';
import Button from 'components/Button';

import locales from '../../i18n';

interface Props {
  user: {
    id: number;
    displayname: string;
    pronoun: string;
  };
  entityType: 'community' | 'channel';
  entityId: string;
  onConfirm?: () => void;
  close: () => void;
}

const ConfirmTransferOwnership: React.FC<Props> = ({
  user,
  entityType,
  entityId,
  onConfirm,
  close,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  const me = useSelector(authSelectors.selectMe);

  const [transfering, setTransfering] = useState(false);

  const transferOwnership = async () => {
    try {
      setTransfering(true);
      if (entityType === 'community') {
        await dispatch(communityActions.transferCommunityOwnership(entityId, user.id));
        dispatch(appActions.addToast(t('{{user}} is now owner of this community', { context: user.pronoun, user: user.displayname })));
      }
      if (entityType === 'channel') {
        await dispatch(channelActions.transferChannelOwnership(entityId, user.id));
        dispatch(appActions.addToast(t('{{user}} is now owner of this channel', { context: user.pronoun, user: user.displayname })));
      }
      setTransfering(false);
      if (onConfirm) {
        onConfirm();
      }
      close();
    } catch (error) {
      dispatch(appActions.addError(error));
      setTransfering(false);
      close();
    }
  };

  return (
    <Modal
      title={t('Make owner', { context: user.pronoun })}
      onCancel={close}
      actions={[
        <Button key={`makeowner-${user.id}`} onClick={transferOwnership} loading={transfering}>{t('global:Confirm')}</Button>,
      ]}
    >
      {t(
        entityType === 'community'
          ? 'Are you sure you want to make {{user}} owner of this community?'
          : 'Are you sure you want to make {{user}} owner of this channel?',
        { context: me.pronoun, user: user.displayname },
      )}
    </Modal>
  );
};

export default ConfirmTransferOwnership;
