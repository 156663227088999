import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as userSelectors from 'state/users/selectors';

const Online = styled.span`
  background-color: green;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 100%;
  top: 16px;
  left: -20px;
`;
Online.displayName = 'Online';

const UserOnlineIndicator = ({ userId }) => {
  const isOnline = useSelector(userSelectors.isOnline(userId));

  if (!isOnline) return null;

  return <Online />;
};

UserOnlineIndicator.propTypes = {
  userId: PropTypes.number.isRequired,
};

UserOnlineIndicator.defaultProps = {
};

export default UserOnlineIndicator;
