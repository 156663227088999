import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';

import ChannelAvatar from 'components/ChannelAvatar';
import { Channel } from 'types';

const Wrapper = styled.div`
  display: flex;
  padding: 8px;

  > div:last-child {
    flex: 1;
    margin-left: 8px;

    .displayname {
      color: ${props => props.theme.colors.main};
      font-weight: 500;
      font-size: 14px;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

interface Props {
  channel: Channel
}

const SuggestionChannel = ({ channel }: Props) => {
  // @ts-ignore
  const avatar = useSelector(state => channelSelectors.getAvatar(state, channel.id));

  return (
    <Wrapper>
      <div><ChannelAvatar image={avatar} size="32px" /></div>
      <div>
        <div className="displayname">{channel.name}</div>
      </div>
    </Wrapper>
  );
};

export default SuggestionChannel;
