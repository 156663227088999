import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as userSelectors from 'state/users/selectors';
import * as feedActions from 'state/feed/actions';

import Collection from './Collection';
import configImg from './cog.png';
import locales from '../../i18n';

const Container = styled.div`
  padding: 16px 8px;
  max-width: 700px;
  overflow: hidden;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(7, 84px);
  grid-row-gap: 12px;
  grid-column-gap: 12px;
  justify-content: center;

  @media(max-width: 768px) {
    grid-template-columns: repeat(4,80px);
    grid-row-gap: 12px;
    grid-column-gap: 4px;
  }
`;
Container.displayName = 'Container';

const Collections = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const authedId = useSelector(authSelectors.selectUserId);
  const userId = useSelector(userSelectors.getByUsername(params.username));

  const [collections, setCollections] = useState(null);

  useEffect(() => {
    const loadCollections = async () => {
      const { data } = await dispatch(feedActions.loadCollections(userId));
      setCollections(data);
    };

    loadCollections();
  }, [dispatch, userId]);

  const goToEdit = useCallback(() => {
    history.push('/user/collections');
  }, [history]);

  const collectionSelected = useCallback(hashtag => async () => {
    if (hashtag === params.hashtag) {
      history.push(`/@${params.username}`);
    } else {
      history.push(`/@${params.username}/collections/${hashtag}`);
    }
  }, [history, params.username, params.hashtag]);

  const isOwner = userId === authedId;

  if (collections === null) return null;

  return (
    <>
      <Container>
        {isOwner && (
          <Collection src={configImg} label={t('Collections')} onClick={goToEdit} dashed />
        )}

        {collections.map(collection => (
          <Collection
            key={`user-collection-${collection.id}`}
            src={collection.cover}
            onClick={collectionSelected(collection.hashtag)}
            label={`#${collection.hashtag}`}
            active={collection.hashtag === params.hashtag}
          />
        ))}
      </Container>
    </>
  );
};

Collections.propTypes = {
};

Collections.defaultProps = {
};

export default Collections;
