import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';
import * as appActions from 'state/app/actions';

const Analytics = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userIsLoggedIn = useSelector(authSelectors.loggedIn);

  useEffect(() => {
    if (userIsLoggedIn) {
      ReactGA.set({ dimension1: 'yes' });
    } else {
      ReactGA.set({ dimension1: 'no' });
    }
  }, [userIsLoggedIn]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${location.pathname}${location.search}`,
      title: document?.title || '',
    });
  }, [location]);

  useEffect(() => {
    dispatch(appActions.sendActivity('page_view', { page: location.pathname }));
  }, [dispatch, location]);

  return null;
};

Analytics.propTypes = {
};

Analytics.defaultProps = {
};

export default Analytics;
