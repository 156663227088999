import styled from 'styled-components';

const Actions = styled.div`
  display: flex;
  box-sizing: border-box;
  margin: 0 16px 0 50px;
  justify-content: space-between;
  height: 0;
  transition: all 250ms ease-out;
  overflow: hidden;

  ${props => props.showing && `
    height: 32px;
  `}

  svg {
    width: 16px;
    height: 16px;
  }

  button.empty {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
  }
`;
Actions.displayName = 'AddCommentActions';

export default Actions;
