import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  margin: 32px auto;
  display: flex;
  justify-content: center;
`;
SpinnerWrapper.displayName = 'SpinnerWrapper';

export default SpinnerWrapper;
