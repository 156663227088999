import { useSelector } from 'react-redux';
import { Icon, MenuItem, useDisclosure } from '@chakra-ui/react';
import { Trash } from 'lucide-react';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';

import RemoveModal from '../Modals/Remove';
import locales from '../i18n';

interface Props {
  publicationId: string;
  onRemove?: (publicationId: string) => void;
}

const MenuItemRemovePublication: React.FC<Props> = ({ publicationId, onRemove }) => {
  const { t } = useTranslation(locales);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const userIsAuthor = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.userIsAuthor(state, publicationId),
  );

  if (!userIsAuthor) return null;

  return (
    <>
      <MenuItem
        icon={<Icon as={Trash} />}
        onClick={onOpen}
        color="red.600"
      >
        {t('Remove')}
      </MenuItem>

      {isOpen && (
        <RemoveModal publicationId={publicationId} close={onClose} onRemove={onRemove} />
      )}
    </>
  );
};

export default MenuItemRemovePublication;
