import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';

import Header from 'components/Header';
import EmptyState from 'components/EmptyState';

import { Wrapper, Overlay } from './UI';
import Body from './Body';
import Participants from './Participants';
import JoinButton from './JoinButton';

const FakeChannel = ({ channel }) => {
  const userIsLoggedIn = useSelector(authSelectors.loggedIn);

  return (
    <Wrapper userIsLoggedIn={userIsLoggedIn}>
      <Header mobileOnly backgroundColor="white" foregroundColor="black" />

      <Body />
      <Participants />

      <Overlay userIsLoggedIn={userIsLoggedIn}>
        <EmptyState
          title={<div className="title">{channel.name}</div>}
          subtitle={(
            <div>
              <div>{channel.description}</div>
              <JoinButton channel={channel} />
            </div>
          )}
        />
      </Overlay>
    </Wrapper>
  );
};

FakeChannel.propTypes = {
  channel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

FakeChannel.defaultProps = {
};

export default FakeChannel;
