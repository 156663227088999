import { memo } from 'react';
import styled from 'styled-components';

interface AvatarWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  size: string;
}

const AvatarWrapper = styled.div.attrs({
  className: 'avatar',
})<AvatarWrapperProps>`
  position: relative;
  width: ${props => props.size};
`;

const AvatarImg = styled.img.attrs<ChannelAvatarProps>(({ image, size }) => ({
  src: image,
  alt: 'Channel',
  width: size,
  height: size,
}))<ChannelAvatarProps>`
  border-radius: 100%;
  width: ${props => props.size};
  height: ${props => props.size};
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
  flex-shrink: 0;
  vertical-align: middle;
`;

interface ChannelAvatarProps {
  image: string;
  size: string;
}

const ChannelAvatar = memo(({
  image,
  size = '50px',
  ...props
}: ChannelAvatarProps) => (
  <AvatarWrapper size={size} {...props}>
    <AvatarImg image={image} size={size} />
  </AvatarWrapper>
));

ChannelAvatar.displayName = 'ChannelAvatar';

export default ChannelAvatar;
