import { useTranslation } from 'hooks';

import EmptyState from 'components/EmptyState';
import Button from 'components/Button';

import locales from '../../i18n';

const Banned = () => {
  const { t } = useTranslation(locales);

  return (
    <EmptyState
      uiLeftColumn
      title={t('This account has been banned')}
      subtitle={t('The account has been terminated automatically by the system due to multiple user\'s reports of missconduct or violations to terms of service.')}
    >
      <Button to="/">{t('Go Home')}</Button>
    </EmptyState>
  );
};

Banned.propTypes = {
};

Banned.defaultProps = {
};

export default Banned;
