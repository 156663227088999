import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BoxDivider from 'components/BoxDivider';

const Wrapper = styled.div`
  margin-bottom: 32px;
  background: white;
  padding: 16px;
  position: relative;
  border-radius: 4px;

  h3 {
    margin: 0 0 8px;
    font-weight: 500;
  }

  .inner {
    height: ${props => props.height}px;
    overflow: hidden;

    .os-host {
      height: 100%;
    }

    & > ul > li > a > div {
      line-height: 16px;
      padding: 8px 16px;

      strong {
        font-weight: 400;
      }
    }
  }

  &:after {
    content: "";
  }
`;
Wrapper.displayName = 'Box';

const Box = ({ title, height, children }) => (
  <Wrapper height={height}>
    {title && <h3>{title}</h3>}

    <div className="inner">
      {children}
    </div>

    <BoxDivider />
  </Wrapper>
);

Box.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
};

Box.defaultProps = {
  title: null,
  height: 275,
};

export default memo(Box);
