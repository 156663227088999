import { IconType, IconDefaults } from './type';

const ListStatus = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M16.5 11L13 7.5L14.4 6.1L16.5 8.2L20.7 4L22.1 5.4L16.5 11M11 7H2V9H11V7M21 13.4L19.6 12L17 14.6L14.4 12L13 13.4L15.6 16L13 18.6L14.4 20L17 17.4L19.6 20L21 18.6L18.4 16L21 13.4M11 15H2V17H11V15Z"
      fill={color}
    />
  </svg>
);

ListStatus.propTypes = IconType;
ListStatus.defaultProps = IconDefaults;

export default ListStatus;
