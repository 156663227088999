import { useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { Button, Icon } from '@chakra-ui/react';
import { MessageCircle } from 'lucide-react';

import * as feedSelectors from 'state/feed/selectors';

interface Props {
  publicationId: string;
}

const Comment: React.FC<Props> = ({ publicationId }) => {
  const history = useHistory();

  const commentIds = useSelector(
    // @ts-ignore
    state => feedSelectors.selectCommentIds(state, publicationId),
    shallowEqual,
  );

  const userHasCommented = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.userHasCommented(state, publicationId),
  );

  const goToComments = () => {
    history.push(`/publications/${publicationId}?comment`);
  };

  return (
    <Button
      leftIcon={<Icon as={MessageCircle} boxSize="24px" fill={userHasCommented ? 'brand.500' : 'transparent'} borderColor={userHasCommented ? 'brand.900' : 'black'} strokeWidth={1} />}
      onClick={goToComments}
    >
      {commentIds.length}
    </Button>
  );
};

export default Comment;
