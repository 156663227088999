import { useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';

import Request from './Request';
import Exhausted from './Exhausted';

const RequestChat = () => {
  const requestsLeft = useSelector(authSelectors.getRequestsLeft);

  if (requestsLeft <= 0) return <Exhausted />;
  return <Request />;
};

RequestChat.propTypes = {
};

export default RequestChat;
