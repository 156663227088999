import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as userSelectors from 'state/users/selectors';

const UserLink = ({
  userId, children, style, onClick,
}) => {
  const isLoaded = useSelector(userSelectors.isLoaded(userId));
  const username = useSelector(userSelectors.getUsername(userId));
  const isActive = useSelector(userSelectors.isActive(userId));

  if (!isLoaded) return <span style={style} className="userlink unloaded">{children}</span>;

  const cn = isActive ? 'userlink' : 'userinactive';
  return <Link to={`/@${username}`} className={cn} style={style} onClick={onClick}>{children}</Link>;
};

UserLink.propTypes = {
  userId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

UserLink.defaultProps = {
  style: null,
  onClick: null,
};

export default UserLink;
