import { useState } from 'react';
import {
  Button, Box, Icon, MenuItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  ButtonGroup,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
  IconButton,
} from '@chakra-ui/react';
import { Gift, Tag, Trash } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';

import { useOpenClose, useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';
import * as appActions from 'state/app/actions';
import * as bankActions from 'state/bank/actions';

import TagsList from 'components/TagsList';
import locales from 'components/PurchaseButton/i18n';

import { USERTAGS, TAG_SLOT } from '../../../../../constants';

interface Props {
  userId: number;
}

const MenuItemTags: React.FC<Props> = ({ userId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState<(keyof typeof USERTAGS)[]>([]);

  const pronoun = useSelector(userSelectors.getPronoun(userId));
  const displayname = useSelector(userSelectors.getDisplayName(userId));
  const { purchased, available, inUse } = useSelector(userSelectors.getTagsDescription(userId));

  const [isOpen, onOpen, onClose] = useOpenClose();

  const onModalOpen = () => {
    ReactGA.event({
      category: 'Users',
      action: 'Gift Tags Modal Opened',
      label: `User id: ${userId}`,
    });

    onOpen();
  };

  const onAddTag = (tag: keyof typeof USERTAGS) => () => {
    setSelected((cv) => [...cv, tag]);
  };

  const onRemoveTag = (tagKey: keyof typeof USERTAGS) => () => {
    setSelected((cv) => cv.filter((tag) => tag !== tagKey));
  };

  const extraSlots = Math.max((inUse.length + selected.length) - available, 0);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const items = [
        ...selected.map((tag) => `USERTAG.${tag}`),
        ...Array.from({ length: extraSlots }, () => 'TAG_SLOT'),
      ];

      await bankActions.createPurchase({ items, payload: { giftToUserId: userId } });
      setIsLoading(false);
      dispatch(appActions.addToast(t(t('Gift sent successfully'))));
      onClose();
    } catch (error) {
      dispatch(appActions.addError(error));
      setIsLoading(false);
    }
  };

  const amount = selected.reduce((acc, tag) => acc + USERTAGS[tag].price, 0) + (extraSlots * TAG_SLOT.price);

  return (
    <>
      <MenuItem icon={<Icon as={Tag} />} onClick={onModalOpen}>{t('Tags gift')}</MenuItem>

      <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={!isLoading} closeOnOverlayClick={!isLoading}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Gift tags to {{displayname}}', { displayname })}</ModalHeader>
          {!isLoading && <ModalCloseButton />}
          <ModalBody>
            <Box maxH="50vh" overflow="scroll">
              <TagsList
                withBorderColor={(tag) => tag.price > 0}
                filter={(tag) => tag.price > 0 && !selected.includes(tag.key) && !purchased.includes(tag.label)}
                onClick={onAddTag}
                pronoun={pronoun}
                showPrice
              />
            </Box>

            {selected.length > 0 && (
              <TableContainer my={6}>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>{t('Item')}</Th>
                      <Th isNumeric>{t('Price')}</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selected.map((tagKey) => (
                      <Tr key={tagKey}>
                        <Td>{t(`USERTAG.${tagKey}.name`)}</Td>
                        <Td isNumeric>{`§ ${USERTAGS[tagKey].price}`}</Td>
                        <Td isNumeric>
                          <IconButton aria-label="Remove tag" variant="link" size="sm" icon={<Icon as={Trash} />} onClick={onRemoveTag(tagKey)} />
                        </Td>
                      </Tr>
                    ))}
                    {extraSlots > 0 && (
                      <Tr>
                        <Td>{t('TAG_SLOT.name')}</Td>
                        <Td isNumeric>{`§ ${extraSlots * TAG_SLOT.price}`}</Td>
                        <Td />
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </ModalBody>

          <ModalFooter>
            <ButtonGroup spacing={4} isDisabled={isLoading}>
              <Button variant="outline" onClick={onClose}>{t('global:Cancel')}</Button>
              <Button
                variant="primary"
                isDisabled={!selected.length}
                leftIcon={<Icon as={Gift} />}
                onClick={onSubmit}
                isLoading={isLoading}
              >
                {t('Gift for §{{amount}}', { amount })}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MenuItemTags;
