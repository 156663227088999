import styled from 'styled-components';

import Grid from 'components/Explore/Grid';
import Card from 'components/Card';

const data = [
  {
    name: 'Dominación sumisión',
    slug: 'dominacionsumision',
    avatar: 'https://prod.mazmocdn.com/communities/avatars/5dc0e96ec779e00025253e72_dJ0YxEhaF.jpg',
    description: 'Debates, posiciones y puntos de vista sobre el BDSM',
  },
  {
    name: 'Género y Diversidad',
    slug: 'genero-diversidad',
    avatar: 'https://prod.mazmocdn.com/communities/avatars/5dc0e96ec779e00025253eed_gFUV__rK_.jpg',
    description: 'Un abordaje crítico, de género y diversidad, sobre las problemáticas que atraviesan a la Comunidad',
  },
  {
    name: 'Técnicas y Precauciones',
    slug: 'tecnicas-y-precauciones',
    avatar: 'https://prod.mazmocdn.com/communities/avatars/5dc0e96ec779e00025253e74_uRmDiw1an.jpg',
    description: 'Comparta lo que sabe y aprenda lo que no',
  },
  {
    name: 'Fetichismo',
    slug: 'fetichismo',
    avatar: 'https://prod.mazmocdn.com/communities/avatars/5dc0e96ec779e00025253e7a_IRKd0EWqf.jpg',
    description: 'Intercambio de material y opiniones de índole fetichista',
  },
  {
    name: 'Temas Generales',
    slug: 'temas-generales',
    avatar: 'https://prod.mazmocdn.com/communities/avatars/5dc0e96ec779e00025253e77_FBo6rwMaJ.jpg',
    description: 'Sexualidad y afines, que no son abarcados por los otros subforos',
  },
  {
    name: 'BDSM en el Arte',
    slug: 'bdsm-en-el-arte',
    avatar: 'https://prod.mazmocdn.com/communities/avatars/5dc0e96ec779e00025253e76_gdSKflTK4.jpg',
    description: 'Discusión sobre películas, libros y la incidencia del BDSM en el arte en general',
  },
];

const Wrapper = styled.div`
  overflow: hidden;
  padding: 16px 0 64px;
  font-size: 16px;
  box-sizing: border-box;

  @media(max-width: 767px) {
    padding: 16px 16px 96px;
  }

  h2 {
    font-size: 22px !important;
    margin: 0 0 20px !important;
    word-break: break-word;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
`;
Wrapper.displayName = 'Wrapper';

const Communities = () => (
  <Wrapper>
    <Grid>
      {data.map(community => (
        <Card
          key={`landing-communities-${community.slug}`}
          avatar={{
            src: community.avatar,
            alt: community.name,
          }}
          link={`/+${community.slug}`}
          title={community.name}
          description={(
            <>
              <span>{community.description}</span>
            </>
          )}
        />
      ))}
    </Grid>
  </Wrapper>
);

Communities.propTypes = {
};

Communities.defaultProps = {
};

export default Communities;
