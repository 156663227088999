import styled from 'styled-components';

const Title = styled.div.attrs({
  className: 'nav-dd-content',
})`
  text-transform: uppercase;
  color: ${props => props.theme.colors.secondary};
  font-size: 12px;
  padding: 8px 8px 0;
  cursor: auto;

  &:not(:first-child) {
    margin-top: 8px;
  }
`;
Title.displayName = 'Title';

export default Title;
