import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useTranslation, useTitle, useMetaRobots } from 'hooks';
import * as appActions from 'state/app/actions';

import EmptyState from 'components/EmptyState';
import Button from 'components/Button';

import locales from './i18n';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

const NotFound = ({ text, children, history }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const displayText = text || t('Page not found');
  useTitle(displayText);
  useMetaRobots('noindex, follow');

  useEffect(() => {
    dispatch(appActions.uiLeftColumn(true));
  }, [dispatch]);

  const goHome = () => history.push('/');

  const content = children || <Button onClick={goHome}>{t('Go home')}</Button>;

  return (
    <Wrapper>
      <EmptyState title={displayText}>
        {content}
      </EmptyState>
    </Wrapper>
  );
};

NotFound.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

NotFound.defaultProps = {
  text: null,
  children: null,
};

export default withRouter(NotFound);
