import { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import NotFound from 'containers/NotFound';
import Loading from 'components/Loading';

const AuthedRoute = lazy(() => import('./AuthedRoute' /* webpackChunkName: "authedroute" */));
const Integrations = lazy(() => import('containers/Integrations' /* webpackChunkName: "integrations" */));

const IntegrationsSwitch = () => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <AuthedRoute path="/integrations" component={Integrations} loading={false} />

      <Route component={NotFound} />
    </Switch>
  </Suspense>
);

export default IntegrationsSwitch;
