import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';
import * as userSelectors from 'state/users/selectors';

import Warning from 'components/Warning';

import locales from './i18n';

const Lovense = ({ publicationId }) => {
  const { t } = useTranslation(locales);

  const hasLovenseBySpank = useSelector(
    state => feedSelectors.hasLovenseBySpank(state, publicationId),
  );
  const authorId = useSelector(
    state => feedSelectors.publications.selectAuthorId(state, publicationId),
  );
  const lovenseToys = useSelector(state => userSelectors.lovenseToys(state, authorId));

  if (!hasLovenseBySpank || !lovenseToys.length) return null;

  let toyNames = '';
  lovenseToys.forEach((toy, index) => {
    if (index > 0) {
      if (index === (lovenseToys.length - 1)) toyNames += ' y ';
      else toyNames += ', ';
    }

    toyNames += toy.toUpperCase();
  });

  return (
    <Warning>
      {t(
        'This user is using a {{toyNames}} toy, which will vibrate each time someone spanks this pubication.',
        { toyNames, context: lovenseToys.length > 1 && 'PLURAL' },
      )}
    </Warning>
  );
};

Lovense.propTypes = {
  publicationId: PropTypes.string.isRequired,
};

Lovense.defaultProps = {
};

export default Lovense;
