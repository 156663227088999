import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation, useOpenClose } from 'hooks';
import * as authSelectors from 'state/auth/selectors';

import Button from 'components/Button';

import TransferModal from './TransferModal';
import locales from './i18n';
import { BANK_BOX_TYPES } from '../../constants';

const TransferButton = ({
  component, data, to, afterTransfer,
}) => {
  const { t } = useTranslation(locales);

  const myId = useSelector(authSelectors.selectId);

  const [isModalOpen, openModal, closeModal] = useOpenClose();

  const clickable = cloneElement(
    component || <Button>{t('Transfer')}</Button>,
    {
      onClick: openModal,
      disabled: !myId || (to.type === BANK_BOX_TYPES.USER && to.id === myId),
    },
  );

  return (
    <>
      {clickable}

      {/* Modal */}
      {isModalOpen && (
        <TransferModal
          to={to}
          data={data}
          close={closeModal}
          afterTransfer={afterTransfer}
        />
      )}
    </>
  );
};

TransferButton.propTypes = {
  component: PropTypes.element,
  data: PropTypes.shape({}),
  to: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(BANK_BOX_TYPES)).isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  afterTransfer: PropTypes.func,
};

TransferButton.defaultProps = {
  component: null,
  data: {},
  afterTransfer: null,
};

export default TransferButton;
