import styled from 'styled-components';

const SelectCouponWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
  align-items: center;

  .title {
    margin-right: 16px;
  }

  .coupons {
    display: flex;

    a {
      background-color: ${props => props.theme.colors.warningBackground};
      border: 1px solid ${props => props.theme.colors.warningBorder};
      font-size: 12px;
      padding: 8px 4px;
      border-radius: 4px;
      cursor: pointer;
      opacity: .7;
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-left: 8px;
      }

      &:hover {
        opacity: 1;
      }

      svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }

      .detail {
        margin-left: 8px;
        background-color: rgba(0, 0, 0, .1);
        padding: 2px 4px;
        border-radius: 2px;
      }
    }
  }
`;
SelectCouponWrapper.displayName = 'SelectCouponWrapper';

export default SelectCouponWrapper;
