import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useTranslation from 'hooks/useTranslation';

import locales from './i18n';

/*
 * NOTE: There are lots of redeclared styled components here.
 * We could just use EmptyState component, but that would mean
 * to import lots of chunks of the site that are not needed for example in Landing page
*/

const ErrorWrapper = styled.div`
  display: flex;
  height: 100vh;

  div:first-child {
    flex: 1;
  }
`;

const Container = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;

  ${props => props.full && `
    height: 100%;
  `}
`;

const Wrapper = styled.div`
  text-align: center;
  flex: 1;
  display: flex;

  div {
    align-self: center;
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 200;
  margin-bottom: 8px;
`;

const Subtitle = styled.div`
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 16px;
`;

const Button = styled.button`
  color: white;
  font-family: 'Roboto',sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;
  height: 35px;
  width: auto;
  background-color: ${props => props.theme.colors.main};
  padding: 4px 24px;
  box-shadow: 0px 2px 7px rgba(0,0,0,0.12);
  border-radius: 40px;
  border: 0;
  user-select: none;
  transition: all 250ms ease-out;
  cursor: pointer;
  text-decoration: none;
`;
Button.displayName = 'Button';

const getTitle = (name) => {
  if (name === 'ChunkLoadError') return 'There is a new version of the site';
  if (name === 'NotFoundError') return 'There is a problem with your browser';
  return 'Oops! Something went wrong';
};

const getSubtitle = (name) => {
  if (name === 'ChunkLoadError') return 'You need to reload the page to access this section';
  if (name === 'NotFoundError') return 'Most likely, an extension is breaking the site structure. Please try to disable extensions one by one until this does\'t happen anymore.';
  return 'reported';
};

const SiteError = ({ eventId, name }) => {
  const { t } = useTranslation(locales);
  const [refreshing, setRefreshing] = useState(false);

  const reload = () => {
    setRefreshing(true);
    document.location.reload();
  };

  const title = t(getTitle(name));
  const subtitle = t(getSubtitle(name), { eventId });

  return (
    <ErrorWrapper>
      <Container>
        <Wrapper>
          <div>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <Button loading={refreshing} disabled={refreshing} onClick={reload}>{t('Refresh site')}</Button>
          </div>
        </Wrapper>
      </Container>
    </ErrorWrapper>
  );
};

SiteError.propTypes = {
  eventId: PropTypes.string.isRequired,
  name: PropTypes.string,
};

SiteError.defaultProps = {
  name: null,
};

export default SiteError;
