import languages from 'i18n/languages';

const keys = Object.keys(languages);
const resources = {};

keys.forEach((key) => {
  try {
    // eslint-disable-next-line global-require, import/no-dynamic-require
    const content = require(`./${key}.json`);
    resources[key] = content;
  } catch (error) {
    //
  }
});

export default {
  namespace: 'Forms',
  resources,
};
