import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as feedSelectors from 'state/feed/selectors';
import * as feedActions from 'state/feed/actions';
import * as appActions from 'state/app/actions';

import PollOption from 'components/PollOption';

const Wrapper = styled.div`
  .options {
    display: flex;
  }
`;
Wrapper.displayName = 'Wrapper';

const Poll = ({ publicationId }) => {
  const dispatch = useDispatch();

  const [voted, setVoted] = useState(null);
  const poll = useSelector(
    state => feedSelectors.publications.selectPoll(state, publicationId),
    shallowEqual,
  );

  const onClick = useCallback(index => async () => {
    try {
      if (!poll.showResults && voted === null) {
        setVoted(index); // Optimistic
        await dispatch(feedActions.vote(publicationId, index));
      }
    } catch (error) {
      dispatch(appActions.addError(error));
    }
  }, [dispatch, publicationId, poll.showResults, voted]);

  return (
    <Wrapper>
      <div className="options">
        <PollOption
          {...poll.options[0]}
          showResults={voted === 0 || poll.showResults}
          onClick={onClick(0)}
        />
        <PollOption
          {...poll.options[1]}
          showResults={voted === 1 || poll.showResults}
          onClick={onClick(1)}
        />
      </div>
    </Wrapper>
  );
};

Poll.propTypes = {
  publicationId: PropTypes.string.isRequired,
};

Poll.defaultProps = {
};

export default Poll;
