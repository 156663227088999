import { useReducer, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Api from 'state/api';
import { useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';

import Modal from 'components/Modal';
import Loading from 'components/Loading';
import EmptyState from 'components/EmptyState';
import Button from 'components/Button';
import TrashCan from 'components/Icons/TrashCan';

import locales from '../../i18n';

const AutoresponderWrapper = styled.div`
  display: flex;
  align-items: center;

  textarea {
    resize: none;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    margin: 8px;
    width: 350px;
  }

  button {
    margin: 0 4px;

    .icon {
      position: relative;
      left: auto;
      top: auto
    }

    &.delete {
      padding: 4px 12px;
    }
  }
`;
AutoresponderWrapper.displayName = 'AutoresponderWrapper';

const Details = styled.div`
  margin-top: 32px;
  border-top: 1px solid #ccc;
  padding-top: 32px;
  color: #666;

  li {
    margin-bottom: 16px;
    padding-left: 16px;
  }
`;
Details.displayName = 'Details';

const initialState = { autoresponders: null };

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD':
      return { autoresponders: action.data };

    case 'ADD':
      return {
        autoresponders: [
          ...state.autoresponders,
          { unsaved: true, trigger: '', response: '' },
        ],
      };

    case 'REMOVED':
      return {
        autoresponders: [
          ...state.autoresponders.slice(0, action.index),
          ...state.autoresponders.slice(action.index + 1),
        ],
      };

    case 'TRIGGER_CHANGED':
      return {
        autoresponders: [
          ...state.autoresponders.slice(0, action.index),
          {
            ...state.autoresponders[action.index],
            unsaved: true,
            trigger: action.value,
          },
          ...state.autoresponders.slice(action.index + 1),
        ],
      };

    case 'RESPONSE_CHANGED':
      return {
        autoresponders: [
          ...state.autoresponders.slice(0, action.index),
          {
            ...state.autoresponders[action.index],
            unsaved: true,
            response: action.value,
          },
          ...state.autoresponders.slice(action.index + 1),
        ],
      };

    case 'SAVING':
      return {
        autoresponders: [
          ...state.autoresponders.slice(0, action.index),
          {
            ...state.autoresponders[action.index],
            saving: true,
          },
          ...state.autoresponders.slice(action.index + 1),
        ],
      };

    case 'REMOVING':
      return {
        autoresponders: [
          ...state.autoresponders.slice(0, action.index),
          {
            ...state.autoresponders[action.index],
            removing: true,
          },
          ...state.autoresponders.slice(action.index + 1),
        ],
      };

    case 'SAVED':
      return {
        autoresponders: [
          ...state.autoresponders.slice(0, action.index),
          {
            ...state.autoresponders[action.index],
            ...action.data,
            unsaved: false,
            saving: false,
          },
          ...state.autoresponders.slice(action.index + 1),
        ],
      };

    default:
      return state;
  }
};

const AutoRespondersModal = ({ channelId, close }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [state, lDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const load = async () => {
      try {
        const { data } = await Api.req.get(`/chat/channels/${channelId}/autoresponders`);
        lDispatch({ type: 'LOAD', data });
      } catch (error) {
        dispatch(appActions.addError(error));
        close();
      }
    };

    load();
  }, [dispatch, close, channelId]);

  const add = useCallback(() => {
    lDispatch({ type: 'ADD' });
  }, []);

  const triggerChanged = useCallback(index => (e) => {
    lDispatch({ type: 'TRIGGER_CHANGED', index, value: e.target.value });
  }, []);

  const responseChanged = useCallback(index => (e) => {
    lDispatch({ type: 'RESPONSE_CHANGED', index, value: e.target.value });
  }, []);

  const save = useCallback(index => async () => {
    const { _id, trigger, response } = state.autoresponders[index];

    lDispatch({ type: 'SAVING', index });

    if (_id) {
      const { data } = await Api.req.put(`/chat/channels/${channelId}/autoresponders/${_id}`, { trigger, response });
      const ar = data.find(e => e._id === _id);
      lDispatch({ type: 'SAVED', data: ar });
    } else {
      const { data } = await Api.req.post(`/chat/channels/${channelId}/autoresponders`, { trigger, response });
      lDispatch({ type: 'SAVED', data: data[data.length - 1] });
    }
  }, [state, channelId]);

  const remove = useCallback(index => async () => {
    lDispatch({ type: 'REMOVING', index });

    if (state.autoresponders[index]._id) {
      await Api.req.delete(`/chat/channels/${channelId}/autoresponders/${state.autoresponders[index]._id}`);
    }

    lDispatch({ type: 'REMOVED', index });
  }, [state, channelId]);

  const renderContent = () => {
    if (state.autoresponders === null) return <Loading />;

    if (!state.autoresponders.length) {
      return (
        <EmptyState title={t('There are no autoresponders yet')}>
          <Button onClick={add}>{t('Create one')}</Button>
        </EmptyState>
      );
    }

    return (
      <div>
        {state.autoresponders.map(({
          _id, trigger, response, unsaved, saving, removing,
        }, index) => {
          const color = unsaved ? 'black' : '#333';
          const key = _id || index;

          return (
            <AutoresponderWrapper key={`ar-${key}`}>
              <textarea onChange={triggerChanged(index)} value={trigger} />
              <textarea onChange={responseChanged(index)} value={response} />
              <Button onClick={save(index)} color="white" fontColor={color} disabled={!unsaved} loading={saving}>
                {t('global:Save')}
              </Button>
              <Button className="delete" onClick={remove(index)} icon={<TrashCan color="white" outline />} loading={removing} />
            </AutoresponderWrapper>
          );
        })}

        <Button onClick={add} color="white" fontColor="black">{t('global:Add')}</Button>

        <Details>
          <li>{t('autoresponder.firstcolumn')}</li>
          <li>{t('autoresponder.commas')}</li>
          <li>{t('autoresponder.secondcolumn')}</li>
          <li>{t('autoresponder.multiplelines')}</li>
        </Details>
      </div>
    );
  };

  return (
    <Modal title={t('Autoresponders')} onClose={close}>
      {renderContent()}
    </Modal>
  );
};

AutoRespondersModal.propTypes = {
  channelId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

AutoRespondersModal.defaultProps = {
};

export default AutoRespondersModal;
