import { Icon, MenuItem } from '@chakra-ui/react';
import { Coins } from 'lucide-react';
import ReactGA from 'react-ga4';

import { useTranslation, useOpenClose } from 'hooks';

import TransferModal from 'components/TransferButton/TransferModal';

import locales from '../../../i18n';

interface Props {
  userId: number;
}

const MenuItemSades: React.FC<Props> = ({ userId }) => {
  const { t } = useTranslation(locales);
  const [showingTransferModal, openTransferModal, closeTransferModal] = useOpenClose();

  const onModalOpen = () => {
    ReactGA.event({
      category: 'Users',
      action: 'Gift Sades Modal Opened',
      label: `User id: ${userId}`,
    });

    openTransferModal();
  };

  return (
    <>
      <MenuItem icon={<Icon as={Coins} />} onClick={onModalOpen}>{t('Sades gift')}</MenuItem>
      {showingTransferModal && <TransferModal to={{ type: 'USER', id: userId }} close={closeTransferModal} />}
    </>
  );
};

export default MenuItemSades;
