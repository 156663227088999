import styled from 'styled-components';

const Message = styled.div`
  margin-right: 72px;

  ${props => props.hasAudio && `
    margin: 0;
  `}

  ${props => props.big && `
    font-size: 40px;
    line-height: 40px;
  `}

  .gif, img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 150px;
  }
`;
Message.displayName = 'BubbleMessage';

export default Message;
