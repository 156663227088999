import produce from 'immer';

import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAIL,
  ADD_MESSAGE,
  UPDATE_MESSAGE,
  INC_UNREAD,
  RESET_UNREAD,
  UPDATE,
  SET_ACTIVE,
  UNMOUNT,
  SCROLL_AT_BOTTOM_CHANGE,
  LOAD_MESSAGES,
  LOAD_MESSAGES_SUCCESS,
  LOAD_MESSAGES_FAIL,
  MESSAGE_REMOVING,
  MESSAGE_REMOVE,
  FULLY_LOADED,
  TYPING_START,
  TYPING_STOP,
  ADD_REPLYTO,
  CLEAR_REPLYTO,
  ADD_PUBLICATIONID,
  CLEAR_PUBLICATIONID,
  ADD_EDITCOMPOSER,
  REMOVE_EDITCOMPOSER,
  WIPE,
} from './constants';

const MESSAGE_LIMIT = 30;

const capMessages = (draft, state, id, limit) => {
  const messages = draft.directmessages[id];
  if (messages) {
    const deletedIds = messages.splice(0, messages.length - limit);

    deletedIds.forEach(i => delete draft.data.directmessages[i]);

    if (deletedIds.length > 0) {
      const flIndex = state.fullyLoaded.findIndex(i => i === id);
      if (flIndex > -1) {
        draft.fullyLoaded.splice(flIndex, 1);
      }
    }
  }
};

export const initialState = {
  data: {
    messengers: {},
    directmessages: {},
  },
  messengers: [],
  directmessages: {},
  loadingDMs: [],
  fullyLoaded: [],
  typing: {},
  replyTo: {},
  publicationId: {},
  editing: {},
  scrollAtBottom: [],
  active: null,
  fetching: false,
  fetchError: null,
  initialized: false,
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case FETCH:
      draft.fetching = true;
      draft.fetchError = null;
      break;

    case FETCH_SUCCESS:
      draft.data.messengers = {
        ...state.data.messengers,
        ...action.data,
      };

      draft.messengers = [
        ...state.messengers,
        ...action.result.filter(mId => !state.messengers.includes(mId)),
      ];
      draft.fetching = false;
      draft.fetchError = null;
      draft.initialized = true;

      break;

    case FETCH_FAIL:
      draft.fetching = false;
      draft.fetchError = action.error;
      break;

    case ADD_MESSAGE: {
      if (action.payload.id && action.referenceId) {
        // Message has been persisted
        delete draft.data.directmessages[action.referenceId];
        if (draft.directmessages[action.messengerId]) {
          const index = draft.directmessages[action.messengerId]
            .findIndex(id => id === action.referenceId);
          if (index > -1) {
            draft.directmessages[action.messengerId].splice(index, 1);
          }
        }
      }

      if ((
        !state.data.messengers[action.messengerId]
        || !state.messengers.includes(action.messengerId)
      ) && typeof action.payload.messenger === 'object') {
        // New messenger - Add it
        draft.data.messengers[action.messengerId] = action.payload.messenger;
        draft.messengers.push(action.messengerId);
      }

      const id = action.payload.id || action.referenceId;
      draft.data.directmessages[id] = action.payload;

      if (action.referenceId && !action.payload.id) {
        draft.data.directmessages[id].referenceId = action.referenceId;
      }
      if (!draft.directmessages[action.messengerId]) {
        draft.directmessages[action.messengerId] = [];
      }

      if (!draft.directmessages[action.messengerId].includes(id)) {
        draft.directmessages[action.messengerId].push(id);
      }

      // Capping stored messages for memory management
      if (action.messengerId !== state.active) capMessages(draft, state, action.messengerId);
      else if (state.scrollAtBottom.includes(action.messengerId)) {
        capMessages(draft, state, action.messengerId, MESSAGE_LIMIT * 2);
      }

      break;
    }

    case UPDATE_MESSAGE:
      draft.data.directmessages[action.payload.id] = action.payload;
      break;

    case INC_UNREAD:
      draft.data.messengers[action.messengerId].unreadCount += 1;
      break;

    case RESET_UNREAD:
      draft.data.messengers[action.messengerId].unreadCount = 0;
      break;

    case UPDATE:
      if (
        !state.data.messengers[action.data.id]
        || !state.messengers.includes(action.data.id)
      ) {
        // New messenger - Add it
        draft.data.messengers[action.data.id] = { ...action.data, rejected: false };
        draft.messengers.push(action.data.id);
      } else {
        // We only need to update participants and blocked and rejected
        if (typeof action.data.participants !== 'undefined') draft.data.messengers[action.data.id].participants = action.data.participants;
        if (typeof action.data.blocked !== 'undefined') draft.data.messengers[action.data.id].blocked = action.data.blocked;
        if (typeof action.data.rejected !== 'undefined') draft.data.messengers[action.data.id].rejected = action.data.rejected;
        if (typeof action.data.archived !== 'undefined') draft.data.messengers[action.data.id].archived = action.data.archived;
      }

      break;

    case SET_ACTIVE:
      draft.active = action.active;
      break;

    case LOAD_MESSAGES:
      if (!state.loadingDMs.includes(action.messengerId)) draft.loadingDMs.push(action.messengerId);
      break;

    case LOAD_MESSAGES_SUCCESS: {
      const index = state.loadingDMs.indexOf(action.messengerId);
      if (index > -1) draft.loadingDMs.splice(index, 1);

      draft.data.directmessages = { ...state.data.directmessages, ...action.directmessages };
      draft.directmessages[action.messengerId] = [
        ...action.result,
        ...(draft.directmessages[action.messengerId] || []),
      ];

      break;
    }

    case LOAD_MESSAGES_FAIL: {
      const index = state.loadingDMs.indexOf(action.messengerId);
      if (index > -1) draft.loadingDMs.splice(index, 1);
      break;
    }

    case MESSAGE_REMOVING:
      draft.data.directmessages[action.messageId] = {
        ...draft.data.directmessages[action.messageId],
        removing: true,
      };
      break;

    case MESSAGE_REMOVE: {
      const message = state.data.directmessages[action.messageId];
      const messengerId = typeof message.messenger === 'object' ? message.messenger.id : message.messenger;
      delete draft.data.directmessages[action.messageId];

      const filteredIds = state.directmessages[messengerId].filter(mId => mId !== action.messageId);
      draft.directmessages[messengerId] = filteredIds;

      break;
    }

    case FULLY_LOADED:
      draft.fullyLoaded.push(action.messengerId);
      break;

    case TYPING_START:
      if (!draft.typing[action.messengerId]) draft.typing[action.messengerId] = [];
      if (!draft.typing[action.messengerId].includes(action.userId)) {
        draft.typing[action.messengerId].push([action.userId]);
      }

      break;

    case TYPING_STOP:
      if (draft.typing[action.messengerId]) {
        const index = draft.typing[action.messengerId].indexOf(action.userId);
        draft.typing[action.messengerId].splice(index, 1);
      }

      break;

    case ADD_REPLYTO:
      draft.replyTo[action.messengerId] = action.messageId;
      break;

    case CLEAR_REPLYTO:
      delete draft.replyTo[action.messengerId];
      break;

    case ADD_PUBLICATIONID:
      draft.publicationId[action.messengerId] = action.publicationId;
      break;

    case CLEAR_PUBLICATIONID:
      delete draft.publicationId[action.messengerId];
      break;

    case ADD_EDITCOMPOSER:
      draft.editing[action.messengerId] = action.messageId;
      break;

    case REMOVE_EDITCOMPOSER:
      delete draft.editing[action.messengerId];
      break;

    case UNMOUNT: {
      draft.active = null;
      capMessages(draft, state, action.id);

      break;
    }

    case SCROLL_AT_BOTTOM_CHANGE:
      draft.scrollAtBottom = state.scrollAtBottom.filter(id => id !== action.id);
      if (action.isAtBottom) draft.scrollAtBottom.push(action.id);
      break;

    case WIPE:
      draft.data = initialState.data;
      draft.messengers = initialState.messengers;
      draft.fetching = initialState.fetching;
      draft.fetchError = initialState.fetchError;
      break;

    default:
  }
});

export default reducer;
