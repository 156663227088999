import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';
import * as communitySelectors from 'state/communities/selectors';
import * as communityActions from 'state/communities/actions';

import CommunityHeader from 'containers/Communities/Header';
import Loading from 'components/Loading';
import { SelectableList } from 'components/SelectableList';
import EmptyState from 'components/EmptyState';
import Button from 'components/Button';
import SearchUsersModal from 'components/SearchUsersModal';
import PageTitle from 'components/PageTitle';
import {
  FlexWrapper,
  FlexInnerWrapper,
  FlexContainer,
  ActionsFooter,
} from 'components/FlexWrapper';

import locales from './i18n';
import Ban from './Ban';

const Content = ({ communityId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const slug = useSelector(state => communitySelectors.selectSlugById(state, communityId));

  const [fetching, setFetching] = useState(false);
  const [bans, setBans] = useState([]);
  const [removing, setRemoving] = useState([]);

  const [showingNewBanModal, setShowingNewBanModal] = useState(false);
  const openNewBanModal = useCallback(() => { setShowingNewBanModal(true); }, []);
  const closeNewBanModal = useCallback(() => { setShowingNewBanModal(false); }, []);

  const [addingBan, setAddingBan] = useState(false);

  useEffect(() => {
    const fetchBans = async () => {
      try {
        setFetching(true);
        const data = await dispatch(communityActions.fetchBans(slug));
        setFetching(false);
        setBans(data);
      } catch (e) {
        dispatch(appActions.addError(e));
        setFetching(false);
      }
    };

    fetchBans();
  }, [dispatch, slug]);

  const add = useCallback(async (userId) => {
    try {
      if (!userId) throw new Error('Unexpected error');

      setAddingBan(true);
      const newBan = await dispatch(communityActions.ban(communityId, userId));
      setAddingBan(false);
      setBans([
        newBan,
        ...bans,
      ]);
      closeNewBanModal();
    } catch (error) {
      setAddingBan(false);
      closeNewBanModal();
      dispatch(appActions.addError(error));
    }
  }, [dispatch, communityId, bans, closeNewBanModal]);

  const remove = useCallback(async (banId) => {
    try {
      setRemoving([
        ...removing,
        banId,
      ]);
      await dispatch(communityActions.unban(communityId, banId));
      setBans(bans.filter(({ _id: id }) => id !== banId));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    setRemoving(removing.filter(id => id !== banId));
  }, [removing, dispatch, communityId, bans]);

  if (fetching) return <Loading />;
  if (bans.length === 0) {
    return (
      <FlexWrapper fullHeight mobileHasHeader>
        <CommunityHeader communityId={communityId} />

        <EmptyState
          title={t('Currently there are no bans in this community')}
          subtitle={(
            <div>
              <Button onClick={openNewBanModal}>{t('Create new ban')}</Button>
              <br />
              <Button className="empty" to={`/@${slug}`}>{t('global:Cancel')}</Button>
            </div>
          )}
        />

        {showingNewBanModal && (
          <SearchUsersModal
            title={t('Add ban')}
            confirm={add}
            close={closeNewBanModal}
            confirmLoading={addingBan}
          />
        )}
      </FlexWrapper>
    );
  }

  return (
    <>
      <FlexWrapper fullHeight mobileHasHeader>
        <FlexInnerWrapper>
          <CommunityHeader communityId={communityId} />
          <PageTitle>{t('Bans')}</PageTitle>

          <FlexContainer framed>
            <SelectableList>
              {bans.map(({ _id: banId, user }) => (
                <Ban
                  key={`community-ban-${user}`}
                  userId={user}
                  banId={banId}
                  remove={remove}
                  removing={removing.includes(banId)}
                />
              ))}
            </SelectableList>
          </FlexContainer>
          <ActionsFooter>
            <div>
              <Button className="empty" to={`/@${slug}`}>{t('global:Cancel')}</Button>
            </div>
            <div>
              <Button onClick={openNewBanModal}>{t('global:Add')}</Button>
            </div>
          </ActionsFooter>
        </FlexInnerWrapper>
      </FlexWrapper>

      {showingNewBanModal && (
        <SearchUsersModal
          title={t('Add ban')}
          confirm={add}
          close={closeNewBanModal}
          confirmLoading={addingBan}
        />
      )}
    </>
  );
};

Content.propTypes = {
  communityId: PropTypes.string.isRequired,
};

Content.defaultProps = {
};

export default Content;
