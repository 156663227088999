import { ButtonGroup } from '@chakra-ui/react';

import CloseButton from './CloseButton';
import ModOptions from './ModOptions';

interface Props {
}

const HeaderActions: React.FC<Props> = () => {
  return (
    <ButtonGroup position="absolute" top={2} right={2} zIndex={50} colorScheme="whiteAlpha">
      <ModOptions />
      <CloseButton />
    </ButtonGroup>
  );
};

export default HeaderActions;
