import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';

import Warning from 'components/Warning';

import { EMAILS } from '../../../constants';
import locales from '../i18n';

const Unconfirmed = () => {
  const { t } = useTranslation(locales);

  const email = useSelector(authSelectors.selectEmail);

  return (
    <Warning fullMargin>
      <Trans t={t} i18nKey="warning.emailconfirm" ns="home" values={{ email, ouremail: EMAILS.CONFIRMATIONS }}>
        <b>Your account is not confirmed!</b>
        <p>
          {'You won\'t '}
          be able to upload your avatar, personalize your profile or post some publications
          {' '}
          in the site until your e-mail address has been confirmed.
        </p>
        <p>
          {'We\'ve '}
          sent you the instructions to
          {' '}
          <b>your address</b>
          . If you
          {' didn\'t '}
          receive our e-mail, please write us to
          {' '}
          <b>our address</b>
          .
        </p>
      </Trans>
    </Warning>
  );
};

Unconfirmed.propTypes = {
};

Unconfirmed.defaultProps = {
};

export default Unconfirmed;
