import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';
import { useDispatch } from 'react-redux';
import heic2any from 'heic2any';

import * as appActions from 'state/app/actions';

import Api from 'state/api';

const useUploadMedia = (id: string, url: string) => {
  const dispatch = useDispatch();

  const uploadImage = useCallback(async (image: File) => {
    try {
      await imageCompression.getExifOrientation(image);

      const conversionResult = (image.type === 'image/heic' || image.type === 'image/heif')
        ? await heic2any({
          blob: image,
          toType: 'image/jpeg',
        })
        : image;

      const compressedFile = await imageCompression(conversionResult as File, {
        maxSizeMB: 10,
        maxWidthOrHeight: 5712,
      });


      dispatch(appActions.mediaUploadAdd(id, {
        file: conversionResult,
        preview: URL.createObjectURL(compressedFile),
      }));

      //

      const formData = new FormData();
      formData.append('image', compressedFile, compressedFile.name);

      const file = await Api.req({
        method: 'post',
        url,
        data: formData,
      });

      dispatch(appActions.mediaUploadComplete(id, compressedFile.name, file.data.filename));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
  }, [dispatch, url, id]);

  const handlePastedFiles = useCallback((files: File[]) => {
    files.forEach((file) => {
      if (file.type.substr(0, 6) === 'image/') {
        uploadImage(file);
      }
    });
  }, [uploadImage]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      uploadImage(file);
    });
  }, [uploadImage]);

  const params = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/jpeg, image/png, image/gif, image/heic, image/heif',
    onDrop,
  });

  return {
    ...params,
    handlePastedFiles,
  };
};

export default useUploadMedia;
