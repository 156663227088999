import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as channelSelectors from 'state/channels/selectors';
import * as channelActions from 'state/channels/actions';
import * as appActions from 'state/app/actions';

import { useTranslation } from 'hooks';

import Modal from 'components/Modal';
import Button from 'components/Button';
import DeleteConfirmation from 'components/DeleteConfirmation';

import locales from '../../i18n';

const DeleteModal = ({ channelId, close }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  const history = useHistory();

  const name = useSelector(state => channelSelectors.getName(state, channelId));

  const [deleting, setDeleting] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const onConfirm = async () => {
    try {
      setDeleting(true);
      await dispatch(channelActions.remove(channelId));
      dispatch(appActions.addToast(t('Channel deleted')));
      history.push('/chat');
    } catch (error) {
      dispatch(appActions.addError(error));
      close();
    }
    //
  };

  return (
    <Modal
      title={t('Delete channel')}
      onCancel={close}
      actions={[
        <Button key="edit-channel-confirm" onClick={onConfirm} disabled={!submitEnabled} loading={deleting}>{t('global:Confirm')}</Button>,
      ]}
    >
      <DeleteConfirmation
        warning={t('Are you sure you want to delete this channel?')}
        validString={name}
        onEnabledChange={setSubmitEnabled}
      />
    </Modal>
  );
};

DeleteModal.propTypes = {
  channelId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default DeleteModal;
