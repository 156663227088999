import { useState, useEffect, useRef } from 'react';
import fastdom from 'fastdom';
import { useDispatch, useSelector } from 'react-redux';

import Api from 'state/api';
import { useTranslation, useTitle, useInputValue } from 'hooks';
import * as authSelectors from 'state/auth/selectors';
import * as appActions from 'state/app/actions';

import Button from 'components/Button';
import InputSearch from 'components/Forms/InputSearch';
import PageTitle from 'components/PageTitle';
import Header from 'components/Header';
import {
  Container,
  InnerContainer,
  CreateText,
  CreateWrapper,
  Grid,
  SearchWrapper,
  TitleWrapper,
  Loader,
  LoadError,
} from 'components/Explore';

import List from './List';
import locales from '../i18n';

const LIMIT = 30;

const Explore = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  useTitle(t('Explore channels'));

  const userIsLoggedIn = useSelector(authSelectors.loggedIn);

  const [channels, setChannels] = useState([]);
  const [loadError, setLoadError] = useState(null);
  const filter = useInputValue('');

  const container = useRef(null);
  const isLoading = useRef(false);
  const channelsCount = useRef(0);
  const fullyLoaded = useRef(false);

  useEffect(() => {
    const el = container.current;

    const load = async () => {
      if (!isLoading.current) {
        try {
          isLoading.current = true;

          const { data } = await Api.req.get('/chat/channels', {
            params: {
              explore: true,
              limit: LIMIT,
              skip: channelsCount.current,
            },
          });

          if (data.length < LIMIT) fullyLoaded.current = true;
          setChannels(currentChannels => [...currentChannels, ...data]);

          isLoading.current = false;
          channelsCount.current += data.length;
        } catch (error) {
          setLoadError(error);
        }
      }
    };

    const loadMoreScrollChanged = () => {
      fastdom.measure(() => {
        if (el.scrollHeight - el.scrollTop <= 2000) {
          load();
        }
      });
    };

    el.addEventListener('scroll', loadMoreScrollChanged);
    load();

    return () => {
      el.removeEventListener('scroll', loadMoreScrollChanged);
    };
  }, []);

  useEffect(() => {
    dispatch(appActions.uiLeftColumn(false));
  }, [dispatch]);

  const filteredChannels = channels
    .filter(channel => channel.name.toLowerCase().includes(filter.value.toLowerCase()));

  const createLink = userIsLoggedIn ? '/chat/channels/create' : '/signup';

  return (
    <Container ref={container}>
      <Header title={t('Find a channel for chat')} mobileOnly />
      <InnerContainer>
        <TitleWrapper>
          <PageTitle mobileOnly>{t('Find a channel for chat')}</PageTitle>
        </TitleWrapper>
        <SearchWrapper>
          <InputSearch placeholder={t('Search for a channel')} {...filter} />
        </SearchWrapper>
        <CreateWrapper>
          <CreateText>{t('Didn\'t find your kink? Create it!')}</CreateText>
          <Button to={createLink} className="small">{t('Create a channel')}</Button>
        </CreateWrapper>
        {loadError
          ? <LoadError message={loadError} />
          : (
            <Grid>
              <List data={filteredChannels} />

              {!fullyLoaded.current && (
                <Loader />
              )}
            </Grid>
          )
        }
      </InnerContainer>
    </Container>
  );
};

export default Explore;
