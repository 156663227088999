import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import colors from 'utils/css/colors';
import * as threadSelectors from 'state/threads/selectors';
import * as communitySelectors from 'state/communities/selectors';

import { ShareVariant } from 'components/Icons';
import ShareIcons from 'components/ShareIcons';
import Hoverable from 'components/Hoverable';

import ActionIcon from './ActionIcon';
import { COMMUNITY_PRIVACIES, SITE_URL } from '../../../constants';

const Share = ({ threadId }) => {
  const url = useSelector(threadSelectors.getUrl(threadId));
  const communityId = useSelector(threadSelectors.getCommunityId(threadId));
  const privacy = useSelector(state => communitySelectors.selectPrivacy(state, communityId));

  if (privacy !== COMMUNITY_PRIVACIES.PUBLIC) return null;

  const tooltipId = `thread-tooltip-${threadId}`;
  const canonical = encodeURIComponent(`${SITE_URL}/${url}`);

  return (
    <>
      <ReactTooltip effect="solid" id={tooltipId} clickable event="click">
        <ShareIcons url={canonical} />
      </ReactTooltip>

      <ActionIcon data-tip data-for={tooltipId}>
        <Hoverable
          normal={<ShareVariant color={colors.red} outline />}
          hover={<ShareVariant color={colors.red} />}
        />
      </ActionIcon>
    </>
  );
};

Share.propTypes = {
  threadId: PropTypes.string.isRequired,
};

Share.defaultProps = {
};

export default Share;
