import { useCallback, useMemo } from 'react';
import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';

import useTranslation from 'hooks/useTranslation';

const useToast = () => {
  const toast = useChakraToast();
  const { t } = useTranslation();

  const success = useCallback((
    title: string, options: (UseToastOptions | undefined) = {},
  ) => toast({
    title,
    duration: 2000,
    isClosable: true,
    position: 'bottom-left',
    ...options,
    status: 'success',
  }), [toast]);

  const error = useCallback((err: any, options: (UseToastOptions | undefined) = {}) => {
    let title = 'Oops! Something went wrong';

    if (typeof err === 'string') {
      title = err;
    } else if (err?.response?.data?.message) {
      title = err?.response?.data?.message;
      if (title === 'NOT_FOUND') title = 'Resource not found';
      if (title === 'NOT_LOGGED_IN') title = 'You need to be logged in';
      title = t(title);
    } else if (err?.response?.data?.error?.message) {
      title = err?.response?.data?.error?.message;
      if (typeof title !== 'string') {
        title = Object.values(title).map((m) => (m as string[]).join('\n')).join('\n');
      }

      title = t(title);
    } else if (err?.message) {
      title = t(err.message);
    } else {
      // Unexpected: report to Sentry
      Sentry.captureException(err);
    }

    return toast({
      title,
      duration: 5000,
      isClosable: true,
      position: 'bottom-left',
      ...options,
      status: 'error',
    });
  }, [toast, t]);

  return useMemo(() => ({
    success, error, instance: toast,
  }), [success, error, toast]);
};

export default useToast;
