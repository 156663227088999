import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  SkeletonCircle,
} from '@chakra-ui/react';
import { UserPlus, Users, X } from 'lucide-react';

import Api from 'state/api';
import * as channelActions from 'state/channels/actions';
import * as appActions from 'state/app/actions';
import { useTranslation } from 'hooks';

import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import locales from '../../i18n';

interface Props {
  channelId: string;
  close: () => void;
  openAddInvite: () => void;
}

interface Invitation {
  id: number;
  inviteeId: number;
}

const Invites: React.FC<Props> = ({ channelId, close, openAddInvite }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const [invites, setInvites] = useState<Invitation[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await Api.req.get(`/chat/channels/${channelId}/invitations`);
        setInvites(data);
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [channelId]);

  const onDelete = async (userId: number) => {
    const invitation = invites?.find(i => i.inviteeId === userId);
    if (!invitation) return;

    await dispatch(channelActions.removeInvitation(channelId, invitation.id));

    setInvites((prevInvites) => prevInvites.filter(i => i.inviteeId !== userId));
    dispatch(appActions.addToast(t('Invitation removed')));
  };

  const addInvite = useCallback(() => {
    openAddInvite();
    close();
  }, [openAddInvite, close]);

  return (
    <Modal isOpen onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Invites')}</ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {/* eslint-disable-next-line no-nested-ternary */}
            {loading ? (
              // Skeleton loading state
              Array.from({ length: 3 }).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Flex key={`skeleton-${index}`} justifyContent="space-between" alignItems="center">
                  <HStack spacing={3}>
                    <SkeletonCircle size="8" />
                    <Skeleton height="20px" width="150px" />
                  </HStack>
                  <Skeleton height="32px" width="32px" />
                </Flex>
              ))
            ) : !invites.length ? (
              // Existing empty state
              <VStack textAlign="center" py={8}>
                <Users size={48} />
                <Text mt={4} fontWeight="bold">{t('No invitations yet')}</Text>
                <Text mt={2}>{t('Invite people to join this channel')}</Text>
              </VStack>
            ) : (
              // Existing invites list
              invites.map((i) => (
                <Flex key={`invite-user-${i.inviteeId}`} justifyContent="space-between" alignItems="center">
                  <HStack spacing={3}>
                    <UserAvatar userId={i.inviteeId} size="sm" />
                    <UserDisplayName userId={i.inviteeId} />
                  </HStack>
                  <IconButton
                    aria-label="Remove invitation"
                    icon={<X size={18} />}
                    size="sm"
                    variant="ghost"
                    onClick={() => onDelete(i.inviteeId)}
                  />
                </Flex>
              ))
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup spacing={3} size={{ base: 'sm', md: 'md' }}>
            <Button onClick={close}>
              {t('global:Close')}
            </Button>
            <Button variant="primary" leftIcon={<UserPlus />} onClick={addInvite}>
              {t('Add Invite')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Invites;
