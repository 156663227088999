import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const Home = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z"
      fill={color}
    />
  </svg>
);

Home.propTypes = IconType;
Home.defaultProps = IconDefaults;

export default memo(Home);
