import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import languages from 'i18n/languages';
import { useTranslation, useOpenClose } from 'hooks';

import ChevronDown from 'components/Icons/ChevronDown';
import Modal from 'components/Modal';
import { SelectableList, SelectableListItem } from 'components/SelectableList';

import locales from './i18n';

const getLanguage = lang => languages[lang] || languages.es;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-left: 16px;
    display: none;
  }

  > div:nth-child(2) {
      flex: 1;
    }

  ${props => (props.select ? `
    border: 1px solid #aaa;
    padding: 6px 8px;
    border-radius: 4px;
    line-height: 16px;
    cursor: pointer;

    > div:first-child {
      margin-right: 8px;
      display: flex;
    }

    &:hover {
      background-color: #eee;
    }

    svg {
      display: block;
    }
  ` : `
    flex-direction: row-reverse;
    width: 100%;
    height: 48px;
  `)}

  img {
    height: 16px;
  }
`;
Wrapper.displayName = 'Wrapper';

const ListWrapper = styled.div`
  ul li {
    cursor: pointer;

    img.flag {
      height: 24px;
    }
  }
`;
ListWrapper.displayName = 'ListWrapper';

const LanguageSelector = ({ select }) => {
  const { t, i18n } = useTranslation(locales);
  const language = getLanguage(i18n.language);

  const [showingModal, openModal, closeModal] = useOpenClose(false);

  const selectLanguage = useCallback(key => async () => {
    await i18n.changeLanguage(key);
    localStorage.setItem('i18nextLng', key);
    closeModal();
  }, [closeModal, i18n]);

  return (
    <>
      <Wrapper select={select} onClick={openModal}>
        <div><img src={language.flag} alt={language.name} /></div>
        <div>{language.name}</div>
        <ChevronDown />
      </Wrapper>

      {/* Modals */}
      {showingModal && (
        <Modal
          title={t('Select language')}
          onClose={closeModal}
        >
          <ListWrapper>
            <SelectableList>
              {Object.keys(languages).map((key) => {
                const lang = languages[key];

                return (
                  <SelectableListItem
                    key={`languages-${key}`}
                    title={lang.name}
                    onClick={selectLanguage(key)}
                    active={i18n.language === key}
                    avatar={<img className="flag" src={lang.flag} alt={lang.name} />}
                  />
                );
              })}
            </SelectableList>
          </ListWrapper>
        </Modal>
      )}
    </>
  );
};

LanguageSelector.propTypes = {
  select: PropTypes.bool,
};

LanguageSelector.defaultProps = {
  select: false,
};

export default LanguageSelector;
