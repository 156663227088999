import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 32px;
  position: relative;

  .sampleimage {
    width: 100%;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Blurred = styled.div`
  filter: blur(10px);
`;
Blurred.displayName = 'Blurred';

export const LoginBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .emptystate {
    background: rgba(255,255,255,0.5);
    margin: 0 auto;
    height: 300px;
    width: 500px;
    flex: none;
    border: 4px solid #AAA;
    padding: 32px;
    box-sizing: border-box;
    max-width: 100%;

    button {
      margin-top: 16px;
    }
  }
`;
LoginBox.displayName = 'LoginBox';
