import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import Toggle from 'components/Toggle';

import locales from '../i18n';

const Lovense = ({ lovense, setLovense }) => {
  const { t } = useTranslation(locales);

  const changeLovense = useCallback(e => (
    setLovense(e.target.checked ? { spanks: true } : null)
  ), [setLovense]);

  return (
    <div>
      <Toggle position="left" label={t('Vibrate your Lovense toy on each spank received')} active={!!lovense} onChange={changeLovense} />
    </div>
  );
};

Lovense.propTypes = {
  lovense: PropTypes.shape({
    spanks: PropTypes.bool.isRequired,
  }),
  setLovense: PropTypes.func.isRequired,
};

Lovense.defaultProps = {
  lovense: null,
};

export default Lovense;
