import { chakra } from '@chakra-ui/react';
import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const DoubleCheck = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path d="M0.41,13.41L6,19L7.41,17.58L1.83,12M22.24,5.58L11.66,16.17L7.5,12L6.07,13.41L11.66,19L23.66,7M18,7L16.59,5.58L10.24,11.93L11.66,13.34L18,7Z" />
  </svg>
);

DoubleCheck.propTypes = IconType;
DoubleCheck.defaultProps = IconDefaults;

export default memo(chakra(DoubleCheck));
