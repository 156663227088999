import { ButtonGroup } from '@chakra-ui/react';

import ReplyPrivately from './ReplyPrivately';
import Spank from './Spank';
import Comment from './Comment';
import Collected from './Collected';
// import Share from './Share';

interface Props {
  publicationId: string;
}

const PublicationActions: React.FC<Props> = ({ publicationId }) => {
  return (
    <ButtonGroup justifyContent="space-between">
      <ReplyPrivately publicationId={publicationId} />
      <ButtonGroup>
        {/* <Share publicationId={publicationId} /> */}
        <Collected publicationId={publicationId} />
        <Comment publicationId={publicationId} />
        <Spank publicationId={publicationId} />
      </ButtonGroup>
    </ButtonGroup>
  );
};

export default PublicationActions;
