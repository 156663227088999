import ReactGA from 'react-ga4';
import { normalize } from 'normalizr';
import { Howl } from 'howler';
import { batch } from 'react-redux';

import Api from 'state/api';
import ComposerRef from 'utils/ComposerRef';
import imSndFile from 'utils/snd/im.mp3';
import * as appActions from 'state/app/actions';
import * as userActions from 'state/users/actions';
import * as messengerSelectors from 'state/messengers/selectors';

import { messengerSchema, directmessageSchema } from './schema';
import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAIL,
  ADD_MESSAGE,
  UPDATE_MESSAGE,
  INC_UNREAD,
  RESET_UNREAD,
  UPDATE,
  SET_ACTIVE,
  LOAD_MESSAGES,
  LOAD_MESSAGES_SUCCESS,
  MESSAGE_REMOVING,
  MESSAGE_REMOVE,
  FULLY_LOADED,
  TYPING_START,
  TYPING_STOP,
  UNMOUNT,
  SCROLL_AT_BOTTOM_CHANGE,
  ADD_REPLYTO,
  CLEAR_REPLYTO,
  ADD_PUBLICATIONID,
  CLEAR_PUBLICATIONID,
  ADD_EDITCOMPOSER,
  REMOVE_EDITCOMPOSER,
  WIPE,
} from './constants';

const imSnd = new Howl({
  src: [imSndFile],
});

const typingTimers = {};

const fetchSuccess = rawData => (dispatch) => {
  const data = normalize(rawData, messengerSchema);

  batch(() => {
    dispatch({
      type: FETCH_SUCCESS,
      data: (data.entities.messengers || {}),
      result: [data.result],
    });

    dispatch(appActions.computeChatList());
  });

  return data.entities.messengers[data.result];
};

export const unmount = id => (dispatch) => {
  dispatch({ type: UNMOUNT, id });
};

export const scrollAtBottomChange = (id, isAtBottom) => (dispatch) => {
  dispatch({
    type: SCROLL_AT_BOTTOM_CHANGE,
    id,
    isAtBottom,
  });
};

export const add = rawData => (dispatch) => {
  const data = normalize(rawData, [messengerSchema]);

  batch(() => {
    Object.values(data.entities.directmessages || {}).forEach((dm) => {
      dispatch({
        type: ADD_MESSAGE,
        messengerId: dm.messenger,
        payload: dm,
      });
    });

    if (data.entities.users) dispatch(userActions.add(data.entities.users));
    dispatch({
      type: FETCH_SUCCESS,
      data: (data.entities.messengers || {}),
      result: data.result,
    });
    dispatch(appActions.computeChatList());
  });
};

export const fetch = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH });
    const { data } = await Api.req.get('/chat/messengers');
    dispatch(add(data));
  } catch (err) {
    let error = err.message;
    if (err.response) {
      // eslint-disable-next-line
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }

    dispatch({ type: FETCH_FAIL, error: (error || err) });
  }
};

export const create = userIds => async (dispatch) => {
  const { data } = await Api.req.post('/chat/messengers', {
    userIds,
  });
  return dispatch(fetchSuccess(data));
};

export const request = (messengerId, message) => async (dispatch, getState) => {
  const publicationId = getState().messengers.publicationId[messengerId];

  const { data } = await Api.req.put(`/chat/messengers/${messengerId}/approve`, {
    request: {
      message,
      publicationId,
    },
  });

  if (message) {
    ReactGA.event({
      category: 'Chat',
      action: 'Messenger request',
      label: `Messenger ID: ${messengerId}`,
    });
  }

  return dispatch(fetchSuccess(data));
};

export const approve = messengerId => async (dispatch) => {
  await dispatch(request(messengerId));

  ReactGA.event({
    category: 'Chat',
    action: 'Messenger approved',
    label: `Messenger ID: ${messengerId}`,
  });
};

export const reject = messengerId => async (dispatch) => {
  const { data } = await Api.req.put(`/chat/messengers/${messengerId}/reject`);

  ReactGA.event({
    category: 'Chat',
    action: 'Messenger rejected',
    label: `Messenger ID: ${messengerId}`,
  });

  return dispatch(fetchSuccess(data));
};

export const rejectReason = (messengerId, reason) => async (dispatch) => {
  const { data } = await Api.req.put(`/chat/messengers/${messengerId}/reject/reason`, { reason });

  ReactGA.event({
    category: 'Chat',
    action: 'Messenger rejected with reason',
    label: `Messenger ID: ${messengerId}`,
  });

  return dispatch(fetchSuccess(data));
};

export const archive = messengerId => async (dispatch) => {
  const { data } = await Api.req.put(`/chat/messengers/${messengerId}/archive`);

  ReactGA.event({
    category: 'Chat',
    action: 'Messenger archived',
    label: `Messenger ID: ${messengerId}`,
  });

  return dispatch(fetchSuccess(data));
};

export const unarchive = messengerId => async (dispatch) => {
  const { data } = await Api.req.put(`/chat/messengers/${messengerId}/unarchive`);

  ReactGA.event({
    category: 'Chat',
    action: 'Messenger unarchived',
    label: `Messenger ID: ${messengerId}`,
  });

  return dispatch(fetchSuccess(data));
};

export const createDirectMessage = (messengerId, payload, audio = null) => async (dispatch) => {
  const referenceId = String((new Date()).getTime());
  dispatch({
    type: ADD_MESSAGE,
    messengerId,
    payload,
    referenceId,
  });

  const { data } = await Api.req.post(`/chat/messengers/${messengerId}/messages`, {
    ...payload,
    referenceId,
  });

  ReactGA.event({
    category: 'Chat',
    action: 'Messenger message created',
    label: `Messenger ID: ${messengerId}`,
  });

  dispatch({
    type: ADD_MESSAGE,
    messengerId,
    payload: data,
    referenceId,
  });

  if (payload.hasAudio && audio) {
    const formData = new FormData();
    formData.append('audio', audio);

    const { data: dm } = await Api.req({
      method: 'PUT',
      url: `/chat/messengers/${messengerId}/messages/${data.id}/audio`,
      data: formData,
    });


    ReactGA.event({
      category: 'Chat',
      action: 'Messenger audio created',
      label: `Messenger ID: ${messengerId}`,
    });

    dispatch({
      type: UPDATE_MESSAGE,
      payload: dm,
    });
  }
};

export const loadMoreMessages = messengerId => async (dispatch, getState) => {
  try {
    const limit = 30;
    const messages = messengerSelectors.selectMessagesByMessengerId(getState(), messengerId);
    const lastMessage = messages[0];
    const beforeDate = lastMessage ? lastMessage.createdAt : null;

    dispatch({ type: LOAD_MESSAGES, messengerId });
    const { data: rawData } = await Api.req.get(`/chat/messengers/${messengerId}/messages`, {
      params: { before: beforeDate, limit },
    });
    const data = normalize(rawData, [directmessageSchema]);

    batch(() => {
      dispatch({
        type: LOAD_MESSAGES_SUCCESS,
        messengerId,
        directmessages: data.entities.directmessages,
        result: data.result,
      });

      if (data.result.length < limit) dispatch({ type: FULLY_LOADED, messengerId });
    });
  } catch (error) {
    dispatch({ type: FETCH_FAIL, error });
  }
};

export const fetchSingleMessage = (messengerId, messageId) => async (dispatch) => {
  const { data: rawData } = await Api.req.get(`/chat/messengers/${messengerId}/messages/${messageId}`);
  const data = normalize(rawData, directmessageSchema);

  const cm = data.entities.directmessages[data.result];
  dispatch({ type: UPDATE_MESSAGE, payload: cm });
};

export const setActive = active => (dispatch, getState) => {
  const state = getState().messengers;

  batch(() => {
    if (state.active !== active) {
      dispatch({ type: SET_ACTIVE, active });
    }

    if (active && state.data.messengers[active].unreadCount > 0) {
      dispatch({ type: RESET_UNREAD, messengerId: active });
      Api.req.put(`/chat/messengers/${active}/read`);
    }
  });
};

export const markAllAsRead = () => (dispatch, getState) => {
  batch(() => {
    Object.values(getState().messengers.data.messengers).forEach((messenger) => {
      if (messenger.unreadCount > 0) {
        dispatch({ type: RESET_UNREAD, messengerId: messenger.id });
        Api.req.put(`/chat/messengers/${messenger.id}/read`);
      }
    });
  });
};

export const sendTypingState = (id, typingState) => () => {
  Api.req.put(`/chat/messengers/${id}/typing`, { state: typingState });
};

export const editDirectMessage = (messengerId, messageId, payload) => async (dispatch) => {
  const { data: rawData } = await Api.req.put(`/chat/messengers/${messengerId}/messages/${messageId}`, payload);

  const data = normalize(rawData, directmessageSchema);
  const dm = data.entities.directmessages[data.result];

  dispatch({
    type: UPDATE_MESSAGE,
    payload: dm,
  });
};

export const removeMessage = messageId => async (dispatch, getState) => {
  const message = getState().messengers.data.directmessages[messageId];
  const messengerId = typeof message.messenger === 'object' ? message.messenger.id : message.messenger;

  await Api.req.delete(`/chat/messengers/${messengerId}/messages/${messageId}`);

  dispatch({ type: MESSAGE_REMOVING, messageId });
};

export const messageRemoved = messageId => (dispatch) => {
  dispatch({ type: MESSAGE_REMOVE, messageId });
};

export const addReaction = (messageId, name) => async (dispatch, getState) => {
  const message = getState().messengers.data.directmessages[messageId];
  const messengerId = typeof message.messenger === 'object' ? message.messenger.id : message.messenger;

  const { data: rawData } = await Api.req.post(`/chat/messengers/${messengerId}/messages/${messageId}/reactions`, { name });
  const data = normalize(rawData, directmessageSchema);

  const dm = data.entities.directmessages[data.result];

  dispatch({
    type: UPDATE_MESSAGE,
    payload: dm,
  });
};

export const removeReaction = (messageId, name) => async (dispatch, getState) => {
  const message = getState().messengers.data.directmessages[messageId];
  const messengerId = typeof message.messenger === 'object' ? message.messenger.id : message.messenger;

  const { data: rawData } = await Api.req.delete(`/chat/messengers/${messengerId}/messages/${messageId}/reactions`, { data: { name } });
  const data = normalize(rawData, directmessageSchema);

  const cm = data.entities.directmessages[data.result];

  dispatch({
    type: UPDATE_MESSAGE,
    payload: cm,
  });
};

export const addReplyTo = (messengerId, messageId) => (dispatch) => {
  const ref = ComposerRef.getRef(`messenger-${messengerId}`);
  dispatch({ type: ADD_REPLYTO, messengerId, messageId });

  if (ref) ref.focus();
};

export const addPublicationId = (messengerId, publicationId) => (dispatch) => {
  const ref = ComposerRef.getRef(`messenger-${messengerId}`);
  dispatch({ type: ADD_PUBLICATIONID, messengerId, publicationId });

  if (ref) ref.focus();
};

export const addEditComposer = (messengerId, messageId) => (dispatch) => {
  dispatch({ type: ADD_EDITCOMPOSER, messengerId, messageId });
};

export const addEditComposerForLastOwnMessage = messengerId => (dispatch, getState) => {
  const messageId = messengerSelectors
    .selectLastOutgoingMessageIdByMessengerId(getState(), messengerId);
  if (messageId) {
    dispatch({ type: ADD_EDITCOMPOSER, messengerId, messageId });
  }
};

export const cancelEditing = messengerId => (dispatch) => {
  dispatch({ type: REMOVE_EDITCOMPOSER, messengerId });
};

export const clearReplyTo = messengerId => (dispatch) => {
  const ref = ComposerRef.getRef(`messenger-${messengerId}`);
  dispatch({ type: CLEAR_REPLYTO, messengerId });

  if (ref) ref.focus();
};

export const clearPublicationId = (messengerId) => (dispatch) => {
  const ref = ComposerRef.getRef(`messenger-${messengerId}`);
  dispatch({ type: CLEAR_PUBLICATIONID, messengerId });

  if (ref) ref.focus();
};

export const handleDirectMessageCreated = rawData => (dispatch, getState) => {
  const state = getState();

  const data = normalize(rawData, directmessageSchema);
  const dm = data.entities.directmessages[data.result];

  batch(() => {
    dispatch({
      type: ADD_MESSAGE,
      messengerId: dm.messenger.id,
      payload: dm,
      referenceId: dm.referenceId,
    });
    dispatch(appActions.updateChatList('messenger', dm.messenger.id));

    if (dm.authorId !== state.auth.me.id) {
      imSnd.play();

      if (state.messengers.active === dm.messenger.id && document.hasFocus()) {
        Api.req.put(`/chat/messengers/${dm.messenger.id}/read`);
      } else {
        dispatch({ type: INC_UNREAD, messengerId: dm.messenger.id });
        Api.req.put(`/chat/messengers/${dm.messenger.id}/received`);
      }
    }
  });
};

export const handleDirectMessageUpdated = rawData => (dispatch) => {
  const data = normalize(rawData, directmessageSchema);
  const dm = data.entities.directmessages[data.result];

  dispatch({
    type: UPDATE_MESSAGE,
    payload: dm,
  });
};

export const handleDirectMessageRemoved = ({ directmessageId: messageId }) => (dispatch) => {
  dispatch({ type: MESSAGE_REMOVING, messageId });
};

export const handleMessengerUpdated = rawData => (dispatch) => {
  const data = normalize(rawData, messengerSchema);
  const messenger = data.entities.messengers[data.result];

  dispatch({ type: UPDATE, data: messenger });
};

export const handleMessengerTyping = ({
  state: typingState,
  messengerId,
  userId,
}) => (dispatch) => {
  const typingKey = `${messengerId}-${userId}`;
  const timerId = typingTimers[typingKey];

  if (timerId) {
    clearTimeout(timerId);
    delete typingTimers[typingKey];
  }

  if (typingState === 'STARTED') {
    dispatch({ type: TYPING_START, messengerId, userId });
    typingTimers[typingKey] = setTimeout(
      () => dispatch({ type: TYPING_STOP, messengerId, userId }),
      20000,
    );
  }
  if (typingState === 'STOPPED') dispatch({ type: TYPING_STOP, messengerId, userId });
};

// This one is a selector, but it needs to be call programaticly, so we disguise it as an action
export const getMessengerIdByUserId = userId => (dispatch, getState) => {
  const state = getState().messengers;
  const matches = state.messengers.filter((messengerId) => {
    const messenger = state.data.messengers[messengerId];
    const participantIds = messenger.participants.map(p => p.userId);
    return participantIds.length === 2 && participantIds.includes(userId);
  });

  return matches.length > 0 && matches[0];
};

export const wipe = () => dispatch => dispatch({ type: WIPE });
