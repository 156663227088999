import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Stack } from '@chakra-ui/react';

import * as feedSelectors from 'state/feed/selectors';
import * as userSelectors from 'state/users/selectors';

import {
  Container,
  CommentContainer,
} from './UI';
import Header from './Header';
import MoreContent from './MoreContent';
import Content from './Content';
import Lovense from './Lovense';
import AddComment from './AddComment';
import Comments from './Comments';
import Likers from './Likers';
import PublicationActions from './Actions';
import PromotedLink from './PromotedLink';
import Voters from './Voters';

const Publication = ({ publicationId, full, onRemove }) => {
  const authorId = useSelector(
    state => feedSelectors.publications.selectAuthorId(state, publicationId),
  );
  const isBlocked = useSelector(userSelectors.isBlockingMe(authorId));
  const isPromoted = useSelector(
    state => feedSelectors.publications.selectIsPromoted(state, publicationId),
  );

  const content = useMemo(() => (
    <Container>
      <Header publicationId={publicationId} onRemove={onRemove} />

      <MoreContent publicationId={publicationId} />
      <Voters publicationId={publicationId} />
      <Content publicationId={publicationId} full={isPromoted || full} />

      <Lovense publicationId={publicationId} />

      <Stack px={2} py={2} spacing={1}>
        <PublicationActions publicationId={publicationId} />
        <Likers publicationId={publicationId} />
      </Stack>

      <PromotedLink publicationId={publicationId} />

      <CommentContainer>
        <Comments publicationId={publicationId} full={full} />
        <AddComment publicationId={publicationId} />
      </CommentContainer>
    </Container>
  ), [publicationId, full, isPromoted, onRemove]);

  return isBlocked ? null : content;
};

Publication.propTypes = {
  publicationId: PropTypes.string.isRequired,
  full: PropTypes.bool,
  onRemove: PropTypes.func,
};

Publication.defaultProps = {
  full: false,
  onRemove: undefined,
};

export default Publication;
