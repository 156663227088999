import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';

import colors from 'utils/css/colors';
import * as channelSelectors from 'state/channels/selectors';

import { Gif, ImageMultiple } from 'components/Icons';

import { CHANNEL_MESSAGES_TYPES } from '../../../../constants';

const Wrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-weight: normal;
  color: ${colors.grey};
  margin-top: 4px;

  svg.gif {
    width: 24px;
    background-color: #EEE;
    padding: 0 2px;
    margin: 0;
  }

  svg.images {
    width: 16px;
    background-color: #EEE;
    padding: 2px 4px;
    margin: 0;
  }
`;

const LastMessage = ({ id }) => {
  const lastMessage = useSelector(
    state => channelSelectors.getLastMessageByChannelId(state, id),
    shallowEqual,
  );
  const isTyping = useSelector(state => channelSelectors.getIsTyping(state, id));

  if (isTyping || !lastMessage || lastMessage.type !== CHANNEL_MESSAGES_TYPES.MESSAGE) return null;

  const { media, rawContent } = lastMessage.payload;
  if (media && media.gif) return <Wrapper><Gif className="gif" color="#666" /></Wrapper>;
  if (media && media.images.length > 0) return <Wrapper><ImageMultiple className="images" color="#666" /></Wrapper>;
  return (
    <Wrapper>{rawContent}</Wrapper>
  );
};

LastMessage.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(LastMessage);
