import { useState } from 'react';
import { Button, Stack, VStack } from '@chakra-ui/react';

import { useSearchUsers, useTranslation } from 'hooks';

import PageTitle from 'components/PageTitle';
import {
  FlexWrapper,
  FlexInnerWrapper,
} from 'components/FlexWrapper';
import { SelectableList, SelectableListItem } from 'components/SelectableList';
import UserAvatar from 'components/UserAvatar';
import { UserSearchInput, UserSearchResults } from 'components/UserSearch';

import ConfirmTransferOwnership from 'containers/Chat/ChannelContent/Modals/ConfirmTransferOwnership';

import locales from './i18n';
import CommunityHeader from '../Header';

interface Props {
  communityId: string;
}

const Content: React.FC<Props> = ({ communityId }) => {
  const { t } = useTranslation(locales);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [newOwner, setNewOwner] = useState<any>();
  const [searchProps, results, reset] = useSearchUsers();

  return (
    <>
      <FlexWrapper>
        <FlexInnerWrapper>
          <VStack>
            <CommunityHeader communityId={communityId} />
            <PageTitle>{t('Transfer ownership')}</PageTitle>

            <VStack spacing={4} width="80%">
              <VStack position="relative">
                {/* @ts-ignore */}
                <UserSearchInput {...searchProps} onBlurTriggered={reset} color="secondary" />
              </VStack>
              <VStack>
                {results.length > 0 && <UserSearchResults results={results} onSelect={(user) => { setNewOwner(user); reset(); }} />}
              </VStack>
              {newOwner && (
                <div>
                  <Stack marginBottom={4}>
                    <SelectableList>
                      {/* @ts-ignore */}
                      <SelectableListItem
                        // @ts-ignore
                        avatar={(<UserAvatar userId={newOwner.id} />)}
                        title={newOwner.displayname}
                      />
                    </SelectableList>
                  </Stack>
                  <Button variant="primary" onClick={() => { setShowConfirmModal(true); }}>
                    {t('Transfer ownership to {{userName}}', { userName: newOwner.displayname })}
                  </Button>
                </div>
              )}

            </VStack>
          </VStack>
        </FlexInnerWrapper>
      </FlexWrapper>
      {showConfirmModal && (<ConfirmTransferOwnership user={newOwner} entityType="community" entityId={communityId} close={() => setShowConfirmModal(false)} />)}
    </>
  );
};

export default Content;
