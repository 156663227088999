import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';
import * as appSelectors from 'state/app/selectors';
import * as messengerSelectors from 'state/messengers/selectors';


import Avatar from 'components/UserAvatar';
import { SelectableListItem } from 'components/SelectableList';
import { Pencil } from 'components/Icons';

import LastMessage from './LastMessage';
import Typing from './Typing';
import UnreadIndicator from './UnreadIndicator';

const Messenger = ({ id }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const url = `/chat/messengers/${id}`;

  const match = useRouteMatch(url);
  const isActive = !!match;

  const hasContentToSubmit = useSelector(state => appSelectors.selectIsComposerReadyToSubmit(state, `messenger-${id}`));

  const messengerUserId = useSelector(state => messengerSelectors.getUserId(state, id));
  const isArchived = useSelector(state => messengerSelectors.isArchived(state, id));
  const user = useSelector(userSelectors.getById(messengerUserId), shallowEqual);

  if (user.loading) dispatch(userActions.fetchData(messengerUserId));

  const onSelect = useCallback(() => {
    history.push(url);
  }, [history, url]);

  const renderAvatar = useCallback(() => <Avatar userId={user.id} />, [user.id]);

  const renderPrebadges = useCallback(() => {
    if (hasContentToSubmit && !isActive) return <Pencil color="#AAA" />;
    return null;
  }, [hasContentToSubmit, isActive]);

  const renderContent = useCallback(() => (
    <>
      <LastMessage id={id} />
      <Typing id={id} />
      <UnreadIndicator id={id} />
    </>
  ), [id]);

  const computeStyle = useCallback(() => {
    if (isArchived) return { opacity: '0.5' };
    return { opacity: '1' };
  }, [isArchived]);

  return (
    <SelectableListItem
      renderAvatar={renderAvatar}
      renderPrebadges={renderPrebadges}
      renderContent={renderContent}
      title={user.displayname}
      onClick={onSelect}
      active={isActive}
      computeStyle={computeStyle}
    />
  );
};

Messenger.propTypes = {
  id: PropTypes.string.isRequired,
};

Messenger.defaultProps = {
};

export default memo(Messenger);
