import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const LockCircled = memo(() => (
  <svg viewBox="0 0 20 20">
    <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#C4C4C4" />
    <path d="M12.4999 7.91667H12.0832V7.08333C12.0832 5.93333 11.1499 5 9.9999 5C8.8499 5 7.91656 5.93333 7.91656 7.08333V7.91667H7.4999C7.04156 7.91667 6.66656 8.29167 6.66656 8.75V12.9167C6.66656 13.375 7.04156 13.75 7.4999 13.75H12.4999C12.9582 13.75 13.3332 13.375 13.3332 12.9167V8.75C13.3332 8.29167 12.9582 7.91667 12.4999 7.91667ZM9.9999 11.6667C9.54156 11.6667 9.16656 11.2917 9.16656 10.8333C9.16656 10.375 9.54156 10 9.9999 10C10.4582 10 10.8332 10.375 10.8332 10.8333C10.8332 11.2917 10.4582 11.6667 9.9999 11.6667ZM11.2916 7.91667H8.70823V7.08333C8.70823 6.37083 9.2874 5.79167 9.9999 5.79167C10.7124 5.79167 11.2916 6.37083 11.2916 7.08333V7.91667Z" fill="white" />
  </svg>
));

LockCircled.propTypes = IconType;
LockCircled.defaultProps = IconDefaults;

export default LockCircled;
