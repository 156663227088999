import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import { useTranslation, useUploadMedia } from 'hooks';
import * as appSelectors from 'state/app/selectors';
import * as authSelectors from 'state/auth/selectors';
import * as threadSelectors from 'state/threads/selectors';
import * as replySelectors from 'state/replies/selectors';
import * as communitySelectors from 'state/communities/selectors';
import * as membershipSelectors from 'state/memberships/selectors';
import * as appActions from 'state/app/actions';

import Composer, { EmojiPicker } from 'components/Composer';
import PageSubTitle from 'components/PageTitle/Secondary';
import Modal from 'components/Modal';
import MembershipRequestButton from 'components/MembershipRequestButton';
import CommunityUploadMedia, { UploadMediaButton } from 'components/CommunityUploadMedia';
import Button from 'components/Button';

import NotLoggedIn from './NotLoggedIn';
import NotConfirmed from './NotConfirmed';
import locales from '../i18n';

const ReplyComposerWrapper = styled.div`
  background-color: ${colors.actionListItemBG};
  padding: 32px 16px;

  .CodeMirror {
    padding: 16px;
  }
`;
ReplyComposerWrapper.displayName = 'ReplyComposerWrapper';

const Actions = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;

  > div {
    display: flex;

    &:first-child {
      position: relative;

      > div, > button {
        margin: 0 4px;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`;
Actions.displayName = 'Actions';

const InactiveThreadAlert = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px 16px;

  button {
    margin-top: 16px;
  }
`;

const INACTIVE_DAYS_AMOUNT = 90;
const INACTIVE_THREAD_AGE = 1000 * 60 * 60 * 24 * INACTIVE_DAYS_AMOUNT;

const ReplyComposer = ({ threadId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [showInactiveThreadAlert, setShowInactiveThreadAlert] = useState(null);
  const [showingSubscribeModal, setShowingSubscribeModal] = useState(false);
  const closeSubsribeModal = () => setShowingSubscribeModal(false);

  const id = `thread-${threadId}`;

  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const isConfirmed = useSelector(authSelectors.isConfirmed);
  const isDeleted = useSelector(threadSelectors.isDeleted(threadId));
  const isLoading = useSelector(replySelectors.isLoading(threadId));
  const isClosed = useSelector(threadSelectors.isClosed(threadId));
  const lastReplyAt = useSelector(threadSelectors.getLastReplyAt(threadId));
  const communityId = useSelector(threadSelectors.getCommunityId(threadId));
  const communityName = useSelector(state => communitySelectors.selectName(state, communityId));
  const hasApprovedMembership = useSelector(
    state => membershipSelectors.hasApprovedMembership(state, communityId),
  );
  const isSaving = useSelector(state => appSelectors.selectIsComposerSaving(state, id));
  const isMod = useSelector(state => membershipSelectors.userIsModOf(state, communityId));


  const {
    getRootProps,
    getInputProps,
    open,
    handlePastedFiles,
  } = useUploadMedia(id, '/communities/upload');

  const afterSend = useCallback(async () => {
    if (!hasApprovedMembership) {
      setShowingSubscribeModal(true);
    }
  }, [hasApprovedMembership]);

  const send = useCallback(async () => {
    await dispatch(appActions.composerSendToServer(id));
    afterSend();
  }, [dispatch, id, afterSend]);

  useEffect(() => {
    if (showInactiveThreadAlert === null) {
      if ((Date.now() - (new Date(lastReplyAt)).getTime()) > INACTIVE_THREAD_AGE) {
        setShowInactiveThreadAlert(true);
      } else {
        setShowInactiveThreadAlert(false);
      }
    }
  }, [showInactiveThreadAlert, lastReplyAt]);

  useEffect(() => () => {
    setShowInactiveThreadAlert(null);
  }, [threadId]);

  if (isClosed && !isMod) return null;
  if (isDeleted || isLoading) return null;
  if (!isLoggedIn) return <NotLoggedIn />;
  if (!isConfirmed) return <NotConfirmed />;
  if (showInactiveThreadAlert) {
    return (
      <InactiveThreadAlert>
        <PageSubTitle>{t('Reply to thread')}</PageSubTitle>
        <span>{t('This thread has been inactive for {{inactiveDays}} days', { inactiveDays: INACTIVE_DAYS_AMOUNT })}</span>
        <span>{t('Are you sure you want to revive this thread?')}</span>
        <Button onClick={() => setShowInactiveThreadAlert(false)} color="white" fontColor="black">
          {t("Yes, I'm sure")}
        </Button>
      </InactiveThreadAlert>
    );
  }

  return (
    <>
      <ReplyComposerWrapper {...getRootProps()}>
        <PageSubTitle>{t('Reply to thread')}</PageSubTitle>

        <Composer
          key={`thread-compose-reply-${threadId}`}
          id={id}
          placeholder={t('Enter your reply here')}
          height="250px"
          handlePastedFiles={handlePastedFiles}
          afterSend={afterSend}
        />

        <CommunityUploadMedia
          id={id}
          open={open}
          getInputProps={getInputProps}
        />

        <Actions>
          <div>
            <EmojiPicker id={id} />
            <UploadMediaButton className="action left" open={open} />
          </div>

          <div>
            <Button onClick={send} loading={isSaving}>{t('global:Send')}</Button>
          </div>
        </Actions>
      </ReplyComposerWrapper>

      {showingSubscribeModal && (
        <Modal
          title={t('Subscribe to {{community}}', { community: communityName })}
          onCancel={closeSubsribeModal}
          actions={[
            <MembershipRequestButton
              key="not-member-modal-subscribe"
              communityId={communityId}
              full={false}
              onClick={closeSubsribeModal}
            />,
          ]}
        >
          {t('Your message was sent, but you won\'t be able to follow this conversation if you are not a member of the community. Do you want to join?')}
        </Modal>
      )}
    </>
  );
};

ReplyComposer.propTypes = {
  threadId: PropTypes.string.isRequired,
};

ReplyComposer.defaultProps = {
};

export default ReplyComposer;
