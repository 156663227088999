import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const CommentReaction = ({
  user,
  comment_text: commentText,
  publication,
  comment,
  read,
  timestamp,
}) => {
  const { t } = useTranslation(locales);

  return (
    <AlertContainer
      timestamp={timestamp}
      image={<UserAvatar userId={user.id} size="40px" showOnline={false} />}
      to={`/publications/${publication}#${comment}`}
      read={read}
    >
      <strong><UserDisplayName userId={user.id} /></strong>
      {' '}
      {t('spanked you a comment')}
      {': '}
      {commentText}
    </AlertContainer>
  );
};

CommentReaction.propTypes = {
  read: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    displayname: PropTypes.string.isRequired,
  }).isRequired,
  comment_text: PropTypes.string,
  publication: PropTypes.number.isRequired,
  comment: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
};

CommentReaction.defaultProps = {
  comment_text: '',
};

export default CommentReaction;
