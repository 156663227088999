import { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import filterEntities from 'utils/filterEntities';
import { useTranslation } from 'hooks';
import * as messengerSelectors from 'state/messengers/selectors';
import { entityEqual } from 'state/channels/equalityFunctions';

import { SelectableListToggle } from 'components/SelectableList';

import Messenger from './Messenger';
import locales from '../i18n';

const Archived = ({ filter }) => {
  const { t } = useTranslation(locales);

  const [showingArchived, setShowingArchived] = useState(false);
  const sortedEntities = useSelector(messengerSelectors.getSortedArchived, entityEqual);
  const archivedMessengers = filterEntities(sortedEntities, filter);

  const toggleShowArchived = useCallback(() => {
    setShowingArchived(state => !state);
  }, []);

  return (
    <>
      {archivedMessengers.length > 0 && (
        <SelectableListToggle
          title={t('archived')}
          expanded={showingArchived}
          onClick={toggleShowArchived}
        />
      )}
      {showingArchived && archivedMessengers.map(({ id }) => (
        <Messenger
          key={`chat-list-archived-${id}`}
          id={id}
        />
      ))}
    </>
  );
};

Archived.propTypes = {
  filter: PropTypes.string.isRequired,
};

Archived.defaultProps = {
};

export default memo(Archived);
