import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { Bot } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';

import locales from '../../../i18n';
import BotsModal from '../../Modals/BotsModal';

const HandleBotsActions: React.FC = () => {
  const { t } = useTranslation(locales);
  const { chatId: channelId } = useParams<{ chatId: string }>();

  const { isOpen: isBotsModalOpen, onOpen: openBotsModal, onClose: closeBotsModal } = useDisclosure();

  const isOwner = useSelector((state) => (
    // @ts-ignore
    channelSelectors.isOwnerOfChannel(state, channelId)
  ));

  if (!isOwner) return null;

  return (
    <>
      <MenuItem icon={<Bot size={16} />} onClick={openBotsModal}>{t('Handle Bots')}</MenuItem>

      {isBotsModalOpen && (
        <BotsModal channelId={channelId} close={closeBotsModal} />
      )}
    </>
  );
};

export default HandleBotsActions;
