import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useElementHeight } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';
import * as appSelectors from 'state/app/selectors';
import * as userActions from 'state/users/actions';

import Participant from './Participant';
import ParticipantBot from './ParticipantBot';

const ParticipantList = styled.div`
  background: #f0f0f0;
  width: 220px;
  overflow: hidden;
  position: relative;

  .os-host {
    height: 100%;
  }

  @media(max-width: 767px) {
    position: absolute;
    height: calc(100% - 64px);
    top: 64px;
    right: 0;
    transform: translate3d(${props => (props.showing ? '0%' : '100%')}, 0, 0);
    transition: all 250ms ease-out;
    z-index: 52;
  }
`;

const Opacity = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  position: absolute;
  z-index: 51;

  @media(min-width: 767px) {
    display: none;
  }
`;

const areRowsEqual = (prevProps, nextProps) => {
  const prevUserId = prevProps.data.participants[prevProps.index];
  const nextUserId = nextProps.data.participants[nextProps.index];

  return prevUserId === nextUserId;
};

const ParticipantRow = memo(({ data: { channelId, participants, bots }, index, style }) => {
  if (index < participants.length) {
    const userId = participants[index];
    return (
      <Participant
        key={`channel-participant-${channelId}-${userId}`}
        userId={userId}
        channelId={channelId}
        style={style}
      />
    );
  }

  const botId = bots[index - participants.length];
  return <ParticipantBot key={`channel-bot-${channelId}-${botId}`} botId={botId} style={style} />;
}, areRowsEqual);

ParticipantRow.propTypes = {
  data: PropTypes.shape({
    channelId: PropTypes.string.isRequired,
    participants: PropTypes.arrayOf(PropTypes.number).isRequired,
    bots: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.shape({}).isRequired,
};

const Participants = memo(({ id }) => {
  const dispatch = useDispatch();

  const participants = useSelector(
    state => channelSelectors.selectParticipantsUsersIdInOrder(state, id),
    shallowEqual,
  );
  const bots = useSelector(state => channelSelectors.botIds(state, id), shallowEqual);
  const showing = useSelector(appSelectors.selectIsShowingParticipants);

  const [listHeight, selectableListEl] = useElementHeight();

  useEffect(() => {
    dispatch(userActions.loadFromIds(participants));
  }, [participants, dispatch]);

  return (
    <>
      {showing && <Opacity />}
      <ParticipantList showing={showing} ref={selectableListEl}>
        <List
          height={listHeight}
          itemCount={participants.length + bots.length}
          itemSize={34}
          itemData={{ channelId: id, participants, bots }}
          width="100%"
        >
          {ParticipantRow}
        </List>
      </ParticipantList>
    </>
  );
});

Participants.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Participants;
