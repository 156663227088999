import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  AlertDescription,
  ButtonGroup,
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  ModalFooter,
} from '@chakra-ui/react';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

import { useTranslation } from 'hooks';
import * as eventSelectors from 'state/events/selectors';
import * as eventActions from 'state/events/actions';

import Loading from 'components/Loading';

import locales from '../i18n';
import Map from './Map';
import AddToCalendarButton from './AddToCalendarButton';

interface Props {
  eventId: string;
  isOpen: boolean;
  onClose: () => void;
  suggestShare?: boolean;
}

const Instructions: React.FC<Props> = ({
  eventId, isOpen, onClose, suggestShare,
}) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  // @ts-ignore
  const name = useSelector(state => eventSelectors.selectName(state, eventId));
  // @ts-ignore
  const address = useSelector(state => eventSelectors.selectAddressString(state, eventId));
  const verificationCode = useSelector(state => (
    // @ts-ignore
    eventSelectors.selectVerificationCode(state, eventId)
  ));
  const earlyTickets = useSelector(state => (
    // @ts-ignore
    eventSelectors.hasEarlyTicketsFeatureEnabled(state, eventId)
  ));

  // @ts-ignore
  const url = useSelector(state => eventSelectors.selectUrl(state, eventId));
  // @ts-ignore
  const instructions = useSelector(state => eventSelectors.selectInstructions(state, eventId));
  // @ts-ignore
  const date = useSelector(state => eventSelectors.selectDate(state, eventId));
  // @ts-ignore
  const lat = useSelector(state => eventSelectors.selectLat(state, eventId));
  // @ts-ignore
  const lng = useSelector(state => eventSelectors.selectLng(state, eventId));

  useEffect(() => {
    if (address && !lat && !lng) {
      dispatch(eventActions.fetch(eventId));
    }
  }, [address, lat, lng, dispatch, eventId]);

  const pubLink = `/new/url?url=${encodeURIComponent(document.location.href)}&content=${encodeURIComponent(t('share.body'))}`;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent pt={6}>
        <ModalCloseButton />
        <ModalBody>
          <VStack textAlign="center" spacing={4}>
            {verificationCode && earlyTickets && (
            <Alert status="warning">
              <AlertDescription>
                <Text fontSize="sm" color="gray.500">{t('Código de Verificación')}</Text>
                <Text fontWeight="bold" fontSize="3xl">{verificationCode}</Text>
                <Text lineHeight={1} mt={2}>
                  Guarda este código en un lugar seguro.
                  Será requerido al momento de ingresar al Evento.
                </Text>
              </AlertDescription>
            </Alert>
            )}

            {(address && !lat && !lng) && <Loading />}

            {(address && lat && lng) && (
            <VStack spacing={4}>
              <VStack spacing={1}>
                <Text fontSize="sm" color="gray.500">{t('Address')}</Text>
                <Text fontSize="3xl" fontWeight="bold" color="black">{address}</Text>
              </VStack>

              <Map lat={lat} lng={lng} />
            </VStack>
            )}

            {!address && (
            <VStack spacing={1}>
              <Text fontSize="sm" color="gray.500">{t('URL')}</Text>
              <Link href={url} target="_blank" rel="noreferrer">{url}</Link>
            </VStack>
            )}

            {instructions && instructions.length > 0 && (
            <VStack spacing={1}>
              <Text fontSize="sm" color="gray.500">{t('Instructions')}</Text>
              <Text>{instructions}</Text>
            </VStack>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter mt={4}>
          <ButtonGroup>
            <AddToCalendarButton
              event={{
                title: name,
                details: instructions,
                location: address || url,
                start: new Date(date).toISOString(),
                end: moment(date).add(6, 'hours').toISOString(),
              }}
            />

            {suggestShare && <Button as={RouterLink} to={pubLink} variant="primary">{t('global:Share')}</Button>}
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Instructions;
