import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useTitle, useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';

import Title from 'components/Main/Title';
import Main from 'components/Forms/Main';
import Wrapper from 'components/Forms/Wrapper';
import TitleWrapper from 'components/Forms/TitleWrapper';

import Form from './Form';
import locales from './i18n';

const Create = () => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  useTitle(t('Create community'));

  useEffect(() => {
    dispatch(appActions.uiLeftColumn(true));
  }, [dispatch]);

  return (
    <Main hasTitle>
      <Wrapper>
        <TitleWrapper>
          <Title>{t('Create community')}</Title>
        </TitleWrapper>
        <Form />
      </Wrapper>
    </Main>
  );
};

export default Create;
