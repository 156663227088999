import { useRef, useEffect } from 'react';

function useFocusOnMount() {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  return ref;
}

export default useFocusOnMount;
