import { Route, Switch } from 'react-router-dom';

import Profile from './Profile';
import Publications from './Publications';
import Photos from './Photos';
import Info from './Info';
import Communities from './Communities';
import Checklist from './Checklist';

const Profiles = () => (
  <Profile>
    <Switch>
      <Route
        path="/@:username([^/]+)"
        exact
        component={Publications}
      />
      <Route
        path="/@:username([^/]+)/collections/:hashtag"
        exact
        component={Publications}
      />
      <Route
        path="/@:username([^/]+)/photos"
        exact
        component={Photos}
      />
      <Route
        path="/@:username([^/]+)/info"
        exact
        component={Info}
      />
      <Route
        path="/@:username([^/]+)/communities"
        exact
        component={Communities}
      />
      <Route
        path="/@:username([^/]+)/checklist"
        exact
        component={Checklist}
      />
    </Switch>
  </Profile>
);

Profiles.propTypes = {
};

Profiles.defaultProps = {
};

export default Profiles;
