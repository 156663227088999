import styled from 'styled-components';

const LinkBox = styled.div`
  padding: 8px;
  border: 1px solid #F5F0F0;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.04), 0px 4px 8px rgba(0,0,0,0.08);
  border-radius: 8px;
  margin-bottom: 24px;
  position: relative;
  display: flex;

  ${props => props.card && `
    flex-direction: column;
    padding: 0;
  `}

  img.fullImage {
    max-width: 100%;
    max-height: 300px;
    margin: 0 auto;

    ${props => props.card && `
      max-width: 100%;
      max-height: 400px;
      margin-bottom: 8px;
    `}
  }

  img.linkImage {
    width: 100px;
    max-height: 100px;
    margin: 0 24px 0 8px;
    object-fit: cover;
  }

  h3 {
    margin: 8px 0;
    word-break: break-word;
  }

  p {
    font-size: 12px;
    word-break: break-word;
  }

  ${props => props.card && `
    > div {
      padding: 8px;
    }
  `}
`;

export default LinkBox;
