import { IconType, IconDefaults } from './type';

const normalPath = 'M9,2A7,7 0 0,1 16,9C16,10.57 15.5,12 14.61,13.19L15.41,14H16L22,20L20,22L14,16V15.41L13.19,14.61C12,15.5 10.57,16 9,16A7,7 0 0,1 2,9A7,7 0 0,1 9,2M8,5V8H5V10H8V13H10V10H13V8H10V5H8Z';
const outlinePath = 'M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z';

const MagnifyPlus = ({ color, className, outline }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d={outline ? outlinePath : normalPath}
      fill={color}
    />
  </svg>
);

MagnifyPlus.propTypes = IconType;
MagnifyPlus.defaultProps = IconDefaults;

export default MagnifyPlus;
