import styled from 'styled-components';

const Wrapper = styled.div`
  word-break: break-word;

  a {
    color: ${props => props.theme.colors.main};
  }

  .userlink, .communitylink, .channellink {
    background-color: ${props => props.theme.colors.mainLight};
    text-decoration: none;
    padding: 0px;
  }

  .emoji-mart-emoji {
    vertical-align: text-bottom;
    height: ${props => props.emojiSize}px;
  }

  p:first-child {
    margin-block-start: 3px;
  }

  p:last-child {
    margin-block-end: 0;
  }

  p code {
    font-size: 14px;
  }

  code {
    background: #E2E2E2;
    padding: 2px 4px;
    border-radius: 5px;
  }

  pre {
    font-size: 14px;
    min-width: 150px;
    white-space: break-spaces;

    > code {
      background: #E2E2E2;
      padding: 8px;
      border-radius: 5px;
      display: block;

      &.language-warning {
        background-color: ${props => props.theme.colors.warningBackground};
        border: 1px solid ${props => props.theme.colors.warningBorder};
      }
    }
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;

    thead {
      background: rgba(0, 0, 0, 0.1);
    }

    th, td {
      border: 1px solid #aaa;
      padding: 4px;
    }
  }

  img {
    max-width: 100%;
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
