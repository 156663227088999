import styled from 'styled-components';

const Keywords = styled.div`
  font-size: 12px;
  color: #666;
  text-align: center;
  margin: 10px;
`;

export default Keywords;
