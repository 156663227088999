import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const ChannelDeleted = ({ channel, read, timestamp }) => {
  const { t } = useTranslation(locales);

  return (
    <AlertContainer timestamp={timestamp} image={channel.avatar} read={read}>
      {channel.name}
      {' '}
      {t('has been deleted by owner')}
    </AlertContainer>
  );
};

ChannelDeleted.propTypes = {
  read: PropTypes.bool.isRequired,
  channel: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
  }).isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default ChannelDeleted;
