import {
  Fragment, useEffect, useCallback, useRef, useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import fastdom from 'fastdom';
import { useDispatch, useSelector } from 'react-redux';

import * as channelsActions from 'state/channels/actions';
import * as channelSelectors from 'state/channels/selectors';
import * as channelEqualityFunctions from 'state/channels/equalityFunctions';
import * as authSelectors from 'state/auth/selectors';

import { useScrollAtBottomSensor } from 'hooks';

import { CHANNEL_MESSAGES_TYPES } from '../../../../constants';
import ChannelActionMessage from '../ChannelActionMessage';
import ChannelMessage from '../Bubble/Channel';
import Wrapper from './Wrapper';
import Container from './Container';
import LoadingMore from './LoadingMore';
import Day from '../Day';

const ChannelMessages = ({ id }) => {
  const dispatch = useDispatch();

  const isLoadingMessages = useRef(false);
  const prevHeight = useRef(null);
  const messages = useSelector(
    state => channelSelectors.getMessagesByChannelId(state, id),
    channelEqualityFunctions.messages,
  );
  const meId = useSelector(authSelectors.selectUserId);
  const isFullyLoaded = useSelector(state => channelSelectors.isFullyLoaded(state, id));
  const unreadCount = useSelector(state => channelSelectors.getUnreadById(state, id));

  const [container, isAtBottom] = useScrollAtBottomSensor([messages]);

  useEffect(() => {
    dispatch(channelsActions.setActive(id));
    return () => dispatch(channelsActions.unmount(id));
  }, [dispatch, id]);

  const visibilityHandler = useCallback(() => {
    if (document.visibilityState === 'visible') dispatch(channelsActions.setActive(id));
    else dispatch(channelsActions.setActive(null));
  }, [dispatch, id]);

  useEffect(() => {
    visibilityHandler();

    document.addEventListener('visibilitychange', visibilityHandler);

    return () => {
      document.removeEventListener('visibilitychange', visibilityHandler);
      dispatch(channelsActions.setActive(null));
    };
  }, [unreadCount, dispatch, visibilityHandler]);

  useEffect(() => {
    dispatch(channelsActions.scrollAtBottomChange(id, isAtBottom.current));
  }, [isAtBottom, dispatch, id]);


  useEffect(() => {
    const el = container.current;

    const loadMoreScrollChanged = async () => {
      const loadMore = async () => {
        await dispatch(channelsActions.loadMoreMessages(id));
        isLoadingMessages.current = false;
      };

      fastdom.measure(() => {
        if (!isLoadingMessages.current && el.scrollTop < 300) {
          isLoadingMessages.current = true;
          prevHeight.current = el ? el.scrollHeight : null;
          loadMore();
        }
      });
    };

    if (container.current) {
      el.addEventListener('scroll', loadMoreScrollChanged);
      setTimeout(loadMoreScrollChanged, 500);
    }

    return () => {
      el.removeEventListener('scroll', loadMoreScrollChanged);
    };
  }, [container, dispatch, id]);

  useLayoutEffect(() => {
    // Restore scroll position after loading previous messages
    fastdom.measure(() => {
      if (prevHeight.current) {
        const nextHeight = container.current.scrollHeight;
        container.current.scrollTo(0, nextHeight - prevHeight.current);
        prevHeight.current = null;
      }
    });
  }, [messages.length, container]);

  return (
    <Wrapper>
      <Container ref={container}>
        {!isFullyLoaded && messages.length > 0 && (
          <LoadingMore />
        )}
        {messages.map((message, index) => {
          if (!message) return <div />;

          const key = message.id || message.referenceId;
          if (
            ![CHANNEL_MESSAGES_TYPES.MESSAGE, CHANNEL_MESSAGES_TYPES.NOTICE].includes(message.type)
          ) {
            return (
              <ChannelActionMessage key={`channel-actionmessage-${key}`} message={message} />
            );
          }

          const hideAuthor = (
            index > 0
            && messages[index - 1]
            && (
              messages[index - 1].authorId === message.authorId
              || (messages[index - 1].authorId === meId && !message.authorId)
            )
            && messages[index - 1].type === CHANNEL_MESSAGES_TYPES.MESSAGE
            && moment(message.createdAt).diff(messages[index - 1].createdAt, 'seconds') < 60
          );
          const showDay = (
            messages.length > 0 && (index === 0 || !moment(messages[index - 1].createdAt).isSame(message.createdAt, 'day'))
          );

          return (
            <Fragment key={`messenger-${key}`}>
              {showDay && <Day date={message.createdAt} />}
              <ChannelMessage
                key={`channel-message-${key}`}
                channelId={id}
                messageId={key}
                showAvatar={!hideAuthor}
                type={message.type}
              />
            </Fragment>
          );
        })}
      </Container>
    </Wrapper>
  );
};

ChannelMessages.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ChannelMessages;
