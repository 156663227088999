import { Skeleton, Stack } from '@chakra-ui/react';

interface Props {
}

const LoadingChecklistContent: React.FC<Props> = () => {
  return (
    <Stack>
      {Array.from({ length: 25 }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton height={{ base: '90px', md: '40px' }} key={`loading-checklist-${index}`} />
      ))}
    </Stack>
  );
};

export default LoadingChecklistContent;
