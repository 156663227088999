import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'utils/css/colors';

import defaultAvatar from 'components/UserAvatar/default.png';

const Displayname = styled.div`
  font-weight: 500;
`;

// We don't use UserAvatar because results are not stored in state
const Avatar = styled.img`
  border-radius: 100%;
  width: 40px;
  height: 40px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
  flex-shrink: 0;
  vertical-align: middle;
`;
Avatar.displayName = 'Avatar';

const Username = styled.div`
  font-size: 14px;
  color: #666;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 10px;

  ${props => (props.active ? `
    background-color: ${colors.red};

    ${Username} {
      color: white;
    }
    ` : `
    &:hover {
      background-color: ${colors.borderRed};
    }
  `)}
`;

const Names = styled.div`
  margin-left: 16px;
`;

const ResultItem = ({ data, onClick, active }) => {
  const avatarSrc = (!data.avatar || !data.avatar['150w']) ? defaultAvatar : data.avatar['150w'].jpeg;

  return (
    <Wrapper active={active} onClick={onClick}>
      <Avatar src={avatarSrc} />
      <Names>
        <Displayname>{data.displayname}</Displayname>
        <Username>{`@${data.username}`}</Username>
      </Names>
    </Wrapper>
  );
};

ResultItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayname: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

ResultItem.defaultProps = {
  onClick: null,
  active: false,
};

export default ResultItem;
