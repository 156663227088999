import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';
import * as replySelectors from 'state/replies/selectors';

import { Unread as UnreadIcon } from 'components/Icons';

import locales from '../i18n';

const UnreadWrapper = styled.div`
  position: absolute;
  right: 52px;
  top: 26px;

  svg {
    width: 16px;
    height: 16px;
  }
`;
UnreadWrapper.displayName = 'UnreadWrapper';

const Unread = ({ replyId }) => {
  const { t } = useTranslation(locales);
  const isUnread = useSelector(replySelectors.isUnread(replyId));

  if (!isUnread) return null;

  return (
    <UnreadWrapper className="unread" title={t('Unread')}><UnreadIcon color={colors.red} /></UnreadWrapper>
  );
};

Unread.propTypes = {
  replyId: PropTypes.string.isRequired,
};

Unread.defaultProps = {
};

export default Unread;
