import { IconType, IconDefaults } from './type';

const AccountEdit = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M21.7,13.35L20.7,14.35L18.65,12.3L19.65,11.3C19.86,11.09 20.21,11.09 20.42,11.3L21.7,12.58C21.91,12.79 21.91,13.14 21.7,13.35M12,18.94L18.06,12.88L20.11,14.93L14.06,21H12V18.94M12,14C7.58,14 4,15.79 4,18V20H10V18.11L14,14.11C13.34,14.03 12.67,14 12,14M12,4A4,4 0 0,0 8,8A4,4 0 0,0 12,12A4,4 0 0,0 16,8A4,4 0 0,0 12,4Z"
      fill={color}
    />
  </svg>
);

AccountEdit.propTypes = IconType;
AccountEdit.defaultProps = IconDefaults;

export default AccountEdit;
