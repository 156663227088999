import { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const Overflow = forwardRef(({ children, onScroll }, ref) => {
  useEffect(() => {
    const el = ref ? ref.current : null;

    if (el && onScroll) el.addEventListener('scroll', onScroll);

    return () => {
      if (onScroll && el) el.removeEventListener('scroll', onScroll);
    };
  }, [onScroll, ref]);

  return (
    <div
      className="overflow-component"
      style={{ overflow: 'auto', height: '100%' }}
      ref={ref}
    >
      {children}
    </div>
  );
});

Overflow.propTypes = {
  children: PropTypes.node.isRequired,
  onScroll: PropTypes.func,
};

Overflow.defaultProps = {
  onScroll: null,
};

export default Overflow;
