import styled from 'styled-components';

interface Props extends Omit<React.HTMLProps<HTMLDivElement>, 'list'> {
  framed?: boolean;
  centered?: boolean;
  wide?: boolean;
  fullViewportMobile?: boolean;
  backgroundColor?: string;
  padding?: string;
  list?: boolean;
}

const Container = styled.div<Props>`
  flex: 1;
  background-color: ${props => props.backgroundColor || 'white'};

  ${props => props.padding && `
    padding: ${props.padding};
  `}

  @media(min-width: 767px) {
    ${props => !props.wide && !props.padding && `
      padding: 24px 48px;
    `}
  }

  ${props => props.framed && `
    padding: 24px;
  `}

  ${props => props.centered && `
    display: flex;
    align-items: center;
    align-self: center;
  `}

  ${props => props.list && `
    @media(min-width: 767px) {
      ul {
        width: 50%;
        margin: 0 auto;
      }
    }
  `}

  ${props => props.fullViewportMobile && `
    @media(max-width: 767px) {
      height: 100vh
      width: 100vw
    }
  `}
`;

Container.displayName = 'Container';

export default Container;
