import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTitle } from 'hooks';
import * as appActions from 'state/app/actions';
import * as appSelectors from 'state/app/selectors';

import Sidebar from 'containers/Sidebar';
import Layout from 'components/Layout';

import Feed from './Feed';

const Home: React.FC = () => {
  useTitle();
  const uiLeftColumn = useSelector(appSelectors.selectIsUiLeftColumnActive);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fix scroll position on mount
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  useEffect(() => {
    dispatch(appActions.uiLeftColumn(true));
  }, [uiLeftColumn, dispatch]);

  return (
    <Layout columns={2} feed leftColumnOpen={false} rightColumnOpen={false}>
      <Feed />
      <Sidebar />
    </Layout>
  );
};

export default Home;
