import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import Select from 'components/Select';

import locales from './i18n';
import { USERS_PRONOUNS } from '../../constants';

const Pronouns = ({ value, onChange, simple }) => {
  const { t } = useTranslation(locales);

  const setPronoun = useCallback((v) => {
    onChange(v);
  }, [onChange]);

  const options = useMemo(() => (
    Object.values(USERS_PRONOUNS).map(p => ({
      label: t(`global:PRONOUN.${p}`),
      value: p,
    }))
  ), [t]);

  return (
    <Select
      value={value}
      onChange={setPronoun}
      placeholder={t('global:Pronoun')}
      options={options}
      simple={simple}
    />
  );
};

Pronouns.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOf(Object.values(USERS_PRONOUNS)).isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  simple: PropTypes.bool,
};

Pronouns.defaultProps = {
  value: null,
  simple: true,
};

export default Pronouns;
