import { useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import colors from 'utils/css/colors';
import * as appActions from 'state/app/actions';

import MobileMenuButton from 'components/MobileMenuButton';
import { ArrowBack } from 'components/Icons';

import Title from './Title';

interface HeaderContainerProps extends React.HTMLProps<HTMLDivElement> {
  mobileOnly?: boolean;
  desktopAbsolute?: boolean;
  backgroundColor?: string;
  border?: string;
}

const HeaderContainer = styled.div<HeaderContainerProps>`
  display: flex;
  flex-direction: row;
  padding: 11px 16px;
  border-bottom: 1px solid #F5EFEF;
  align-items: center;
  height: 64px;
  box-sizing: border-box;
  z-index: 10;
  ${props => props.backgroundColor && `
    background-color: ${props.backgroundColor};
  `}
  ${props => props.border && `
    border: ${props.border};
  `}

  ${props => props.desktopAbsolute && `
    @media(min-width: 767px) {
      position: absolute;
      width: 100%;
    }
  `}

  ${props => props.mobileOnly && `
    @media(min-width: 767px) {
      display: none;
    }
  `}

  @media(max-width: 767px) {
    height: 64px;
    box-sizing: border-box;
    padding: 0;
    background-color: ${props => props.backgroundColor || colors.red};
    border: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;

    .avatar, .community-avatar {
      width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
`;

const DataContainer = styled.div`
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
DataContainer.displayName = 'DataContainer';

interface TitleProps extends React.HTMLProps<HTMLDivElement> {
  foregroundColor?: string;
}

const TitleContainer = styled.div<TitleProps>`
  margin-left: 15px;
  flex: 1;
  overflow: hidden;
  margin-right: 8px;
  color: ${props => props.foregroundColor || 'black'};

  @media(max-width: 767px) {
    margin-left: 8px;
    color: ${props => props.foregroundColor || 'white'};
  }
`;

const Actions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  position: relative;
`;
Actions.displayName = 'Actions';


interface Props {
  title?: string;
  renderTitle?: () => React.ReactElement;
  renderAvatar?: () => React.ReactElement;
  renderInfo?: () => React.ReactElement;
  renderActions?: () => React.ReactElement;
  renderIcon?: () => React.ReactElement;
  onBackClick?: () => void;
  mobileOnly?: boolean;
  desktopAbsolute?: boolean;
  backgroundColor?: string;
  foregroundColor?: string;
  border?: string;
  negativeBadge?: boolean;
}

const Header: React.FC<Props> = ({
  renderAvatar,
  renderTitle,
  title,
  renderInfo,
  renderActions,
  renderIcon,
  onBackClick,
  mobileOnly,
  desktopAbsolute,
  backgroundColor,
  foregroundColor,
  border,
  negativeBadge,
}) => {
  const dispatch = useDispatch();

  const onBack = useCallback(() => {
    if (onBackClick) onBackClick();
    else {
      dispatch(appActions.uiLeftColumn(true));
    }
  }, [dispatch, onBackClick]);

  return (
    <HeaderContainer
      mobileOnly={mobileOnly}
      backgroundColor={backgroundColor}
      desktopAbsolute={desktopAbsolute}
      border={border}
    >
      <MobileMenuButton onClick={onBack} negative={negativeBadge}>
        {renderIcon ? renderIcon() : <ArrowBack color={foregroundColor || 'white'} />}
      </MobileMenuButton>

      <DataContainer>
        {renderAvatar && renderAvatar()}

        <TitleContainer foregroundColor={foregroundColor}>
          <Title>{renderTitle ? renderTitle() : title}</Title>
          {renderInfo && renderInfo()}
        </TitleContainer>
      </DataContainer>

      <Actions>
        {renderActions && renderActions()}
      </Actions>
    </HeaderContainer>
  );
};

export default Header;
