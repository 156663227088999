import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ResultItem from './ResultItem';

const Results = styled.div`
  background-color: white;
  box-shadow: 1px 1px 5px 0px #aaa;
  max-height: 350px;
  overflow-y: auto;

  @media(max-width: 767px) {
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    width: auto;
    height: calc(100vh - 64px);
    max-height: none;
  }
`;

const UserSearchResults = ({ results, onSelect }) => {
  const onClick = user => (e) => {
    if (onSelect) {
      e.preventDefault();
      onSelect(user);
    }
  };

  return (
    <Results>
      {results.map(result => (
        <Link key={`search-user-${result.username}`} to={`/@${result.username}`} onClick={onClick(result)}>
          <ResultItem data={result} />
        </Link>
      ))}
    </Results>
  );
};

UserSearchResults.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string.isRequired,
    displayname: PropTypes.string.isRequired,
  })).isRequired,
  onSelect: PropTypes.func,
};

UserSearchResults.defaultProps = {
  onSelect: null,
};

export default UserSearchResults;
