import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTitle, useTranslation } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';

import CommunityHeader from 'containers/Communities/Header';
import Info from 'containers/Communities/Info';
import Layout from 'components/Layout';
import EmptyState from 'components/EmptyState';
import Button from 'components/Button';
import { FlexWrapper } from 'components/FlexWrapper';

import locales from './i18n';

const Empty = ({ communityId }) => {
  const { t } = useTranslation(locales);
  const params = useParams();

  const name = useSelector(state => communitySelectors.selectName(state, communityId));
  const slug = useSelector(state => communitySelectors.selectSlugById(state, communityId));

  useTitle(`${name} - ${t('global:Communities')}`);

  return (
    <Layout columns={2}>
      <Info communitySlug={params.communitySlug} />
      <FlexWrapper>
        <CommunityHeader communityId={communityId} />
        <EmptyState
          title={t('This community is empty')}
          subtitle={t('Go ahead and create the very first thread')}
        >
          <Button to={`/+${slug}/new`}>{t('Create thread')}</Button>
        </EmptyState>
      </FlexWrapper>
    </Layout>
  );
};

Empty.propTypes = {
  communityId: PropTypes.string.isRequired,
};

Empty.defaultProps = {
};

export default Empty;
