import { useSelector, shallowEqual } from 'react-redux';
import { Text } from '@chakra-ui/react';

import * as feedSelectors from 'state/feed/selectors';
import { useTranslation } from 'hooks';

import locales from './i18n';

interface Props {
  publicationId: string;
}

const Voters: React.FC<Props> = ({ publicationId }) => {
  const { t } = useTranslation(locales);

  const poll = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.selectPoll(state, publicationId),
    shallowEqual,
  );

  if (!poll) return null;

  return (
    <Text textAlign="center" color="gray.500" fontSize="xs" mt={1}>
      {t('Voters {{count}}', { count: poll.voteCount })}
    </Text>
  );
};

export default Voters;
