import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  HStack, Icon, MenuItem, Switch, Text,
} from '@chakra-ui/react';
import { MessageCircle } from 'lucide-react';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';
import * as feedActions from 'state/feed/actions';
import * as appActions from 'state/app/actions';

import locales from '../i18n';

interface Props {
  publicationId: string;
}

const MenuItemAllowComments: React.FC<Props> = ({ publicationId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [toggling, setToggling] = useState(false);

  const userIsAuthor = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.userIsAuthor(state, publicationId),
  );
  const closed = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.commentsBlocked(state, publicationId),
  );

  const onClick = useCallback(async () => {
    try {
      setToggling(true);
      const toast = closed ? 'Comments allowed' : 'Comments disallowed';
      await dispatch(feedActions.toggleBlockedComments(publicationId));
      dispatch(appActions.addToast(t(toast)));
    } catch (error) {
      dispatch(appActions.addError(error));
    }

    setToggling(false);
  }, [publicationId, dispatch, t, closed]);

  if (!userIsAuthor) return null;

  return (
    <MenuItem
      icon={<Icon as={MessageCircle} />}
      closeOnSelect={false}
      justifyContent="space-between"
      onClick={onClick}
    >
      <HStack>
        <Text flex={1}>{t('Allow comments')}</Text>
        <Switch size="sm" isChecked={!closed} isDisabled={toggling} />
      </HStack>
    </MenuItem>
  );
};

export default MenuItemAllowComments;
