import {
  Fragment, useState, useRef, useCallback, useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import fastdom from 'fastdom';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'hooks';
import * as feedActions from 'state/feed/actions';

import Spinner from 'components/Spinner';
import SpinnerWrapper from 'components/Spinner/Wrapper';
import Publication from 'components/Publication';
import EmptyState from 'components/EmptyState';
import Ad from 'components/Ad';

import { PUBLICATIONS_LIST_LIMIT } from '../../../constants';
import locales from '../i18n';

const HashtagFeed = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  const params = useParams();

  const [publicationIds, setPublicationIds] = useState([]);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const isLoading = useRef(false);
  const publicationsCount = useRef(0);

  const load = useCallback(async () => {
    if (!isLoading.current && !fullyLoaded) {
      isLoading.current = true;
      const ids = await dispatch(
        feedActions.loadHashtag(params.hashtag, publicationsCount.current),
      );
      setPublicationIds(prevIds => [...prevIds, ...ids]);
      isLoading.current = false;
      publicationsCount.current += ids.length;

      if (ids.length < PUBLICATIONS_LIST_LIMIT) {
        setFullyLoaded(true);
      }
    }
  }, [dispatch, params.hashtag, fullyLoaded]);

  useEffect(() => {
    const el = document.documentElement;

    const loadMoreScrollChanged = () => {
      fastdom.measure(() => {
        if (el.scrollHeight - el.scrollTop < 2500) {
          load();
        }
      });
    };

    document.addEventListener('scroll', loadMoreScrollChanged);
    load();

    return () => {
      document.removeEventListener('scroll', loadMoreScrollChanged);
    };
  }, [load]);

  useEffect(() => {
    publicationsCount.current = 0;
    setPublicationIds([]);
    setFullyLoaded(false);
  }, [params.hashtag]);

  if (fullyLoaded && !publicationIds.length) {
    return <EmptyState subtitle={t('No publications found')} />;
  }

  return (
    <>
      {publicationIds.map((id, index) => (
        <Fragment key={`pub-${id}`}>
          <Publication publicationId={id} />
          {!((index - 1) % 8) && <Ad id="In Feed" />}
        </Fragment>
      ))}

      {!fullyLoaded && (
      <SpinnerWrapper>
        <Spinner color="#999" />
      </SpinnerWrapper>
      )}
    </>
  );
};

HashtagFeed.propTypes = {
};

HashtagFeed.defaultProps = {
};

export default HashtagFeed;
