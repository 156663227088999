import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';

import Modal from 'components/Modal';
import PollOption from 'components/PollOption';
import UserLink from 'components/UserLink';
import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import locales from '../i18n';

const Wrapper = styled.div`
  display: flex;

  > div {
    flex: 1;

    .voters {
      display: flex;
      margin: 16px;
      flex-direction: column;

      .userlink, .userinactive {
        display: flex;
        align-items: center;

        .displayname {
          margin-left: 8px;
          margin-right: 8px;
        }
      }

      @media(max-width: 768px) {
        font-size: 12px;
        margin: 8px;

        .avatar {
          width: 24px;
          height: 24px;

          img {
            width: 24px;
            height: 24px;
          }
        }

        .userlink {
          .displayname {
            margin-left: 4px;
            margin-right: 4px;
          }
        }
      }
    }

    &:last-child {
      .voters {
        .userlink {
          flex-direction: row-reverse;
        }
      }
    }
  }
`;
Wrapper.displayName = 'Wrapper';

const VotesModal = ({ close, publicationId }) => {
  const { t } = useTranslation(locales);

  const poll = useSelector(
    state => feedSelectors.publications.selectPoll(state, publicationId),
    shallowEqual,
  );

  return (
    <Modal
      title={t('Votes')}
      onClose={close}
    >
      <Wrapper>
        <div>
          <PollOption
            {...poll.options[0]}
            showResults
          />
          <div className="voters">
            {poll.votes.filter(v => v.optionIndex === 0).map(({ userId }) => (
              <UserLink userId={userId} key={`vote-${publicationId}-${userId}`}>
                <UserAvatar size="32px" userId={userId} />
                <UserDisplayName userId={userId} />
              </UserLink>
            ))}
          </div>
        </div>
        <div>
          <PollOption
            {...poll.options[1]}
            showResults
          />
          <div className="voters">
            {poll.votes.filter(v => v.optionIndex === 1).map(({ userId }) => (
              <UserLink userId={userId} key={`vote-${publicationId}-${userId}`}>
                <UserAvatar size="32px" userId={userId} />
                <UserDisplayName userId={userId} />
              </UserLink>
            ))}
          </div>
        </div>
      </Wrapper>
    </Modal>
  );
};

VotesModal.propTypes = {
  close: PropTypes.func.isRequired,
  publicationId: PropTypes.string.isRequired,
};

export default VotesModal;
