import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'utils/css/colors';

const PageTitle = styled.h1`
  color: ${colors.h1};
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  margin: 16px;

  @media(max-width: 767px) {
    margin-top: 8px;

    ${props => props.mobileOnly && `
      display: none;
    `}
  }
`;

PageTitle.propTypes = {
  mobileOnly: PropTypes.bool,
};

PageTitle.defaultProps = {
  mobileOnly: false,
};

export default PageTitle;
