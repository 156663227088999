import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';
import * as feedActions from 'state/feed/actions';

import TransferButton from 'components/TransferButton';
import Button from 'components/Button';

import locales from '../i18n';

const FundingButtonContainer = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  top: calc(50% - 21px);
  width: 100%;
  text-align: center;

  button {
    font-size: 24px;
    line-height: 20px;
    height: 50px;
    background-color: rgba(255, 255, 255, .6);
    color: black;
    padding: 8px 40px;

    &:hover {
      background-color: white;
    }

    .price {
      font-size: 12px;
      font-weight: normal;
      text-transform: none;
      color: #333;
    }
  }
`;
FundingButtonContainer.displayName = 'FundingButtonContainer';

const Reloading = styled.div`
  position: absolute;
  z-index: 10;
  width: 250px;
  height: 66px;
  left: calc(50% - 125px);
  top: calc(50% - 21px);
  text-align: center;
  font-size: 24px;
  line-height: 50px;
  background-color: rgba(255, 255, 255, .6);
  color: black;
  padding: 8px 40px;
  border-radius: 40px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
`;
Reloading.displayName = 'Reloading';

const Funding = ({ publicationId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const authorId = useSelector(
    state => feedSelectors.publications.selectAuthorId(state, publicationId),
  );
  const isBlurred = useSelector(
    state => feedSelectors.publications.isBlurred(state, publicationId),
  );
  const amount = useSelector(
    state => feedSelectors.publications.getFundingAmount(state, publicationId),
  );
  const isTarget = useSelector(
    state => feedSelectors.publications.fundingIsTarget(state, publicationId),
  );

  const [reloading, setReloading] = useState(false);

  const reload = useCallback(async () => {
    try {
      setReloading(true);
      await dispatch(feedActions.loadPublication(publicationId));
      setReloading(false);
    } catch (error) {
      //
    }
  }, [dispatch, publicationId]);

  if (!isBlurred) return null;

  const transferData = {
    publicationId,
    concept: t('Publication unblocking'),
  };

  return (
    <>
      <FundingButtonContainer>
        {reloading ? (
          <Reloading>{t('Reloading...')}</Reloading>
        ) : (
          <TransferButton
            to={{ type: 'USER', id: authorId }}
            data={{ amount, fixed: !isTarget, transferData }}
            afterTransfer={reload}
            component={(
              <Button>
                <span>{t('Unblock')}</span>
                {isTarget
                  ? <div className="price">{t('Target {{amount}} sades', { amount })}</div>
                  : <div className="price">{t('Price {{amount}} sades', { amount })}</div>
                }
              </Button>
            )}
          />
        )}
      </FundingButtonContainer>
    </>
  );
};

Funding.propTypes = {
  publicationId: PropTypes.string.isRequired,
};

Funding.defaultProps = {
};

export default Funding;
