import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appSelectors from 'state/app/selectors';
import * as appActions from 'state/app/actions';

import Button from 'components/Button';

import locales from './i18n';

const CreateThreadSubmit = ({ id, title }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();
  const history = useHistory();

  const canSubmit = useSelector(state => appSelectors.selectIsComposerReadyToSubmit(state, id));
  const isSaving = useSelector(state => appSelectors.selectIsComposerSaving(state, id));
  const disabled = !title.length || !canSubmit;

  const create = async () => {
    const thread = await dispatch(appActions.composerSendToServer(id, { title }));
    dispatch(appActions.addToast(t('Thread created successfully')));
    history.push(`/+${thread.communitySlug}/${thread.slug}`);
  };

  return (
    <Button
      onClick={create}
      disabled={disabled}
      loading={isSaving}
    >
      {t('global:Create')}
    </Button>
  );
};

CreateThreadSubmit.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CreateThreadSubmit;
