import styled from 'styled-components';

const OptionsWrapper = styled.div`
  margin-bottom: 16px;
  margin-top: -32px;
  margin-left: -48px;
  margin-right: -48px;
  background: white;

  @media(max-width: 768px) {
    margin-left: -40px;
    margin-right: -40px;
  }

  > div {
    width: 668px;
    display: flex;
    margin: 0 auto;

    @media(max-width: 768px) {
      width: 100%;

      > div > div > input {
        font-size: 12px;
        padding: 0 8px;
        border: 0;
        width: 100%;
        box-sizing: border-box;
      }
    }

    > div {
      flex: 1;
      text-align: center;

      .image-or-color {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        button {
          margin: 4px;
          border: 0;
          background: none;
          cursor: pointer;
          opacity: .4;

          svg {
            width: 24px;
            height: 24px;
          }

          &:hover {
            opacity: 1;
          }
        }
      }

      input {
        margin-top: 8px;
        font-size: 14px;
        line-height: 32px;
      }
    }
  }
`;
OptionsWrapper.displayName = 'OptionsWrapper';

export default OptionsWrapper;
