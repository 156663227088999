import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import { useTitle } from 'hooks';
import * as communitySelectors from 'state/communities/selectors';

import CommunityAvatar from 'components/CommunityAvatar';
import MainHeader from 'components/Header';
import { Menu } from 'components/Icons';


const Wrapper = styled.div.attrs({
  role: 'button',
})`
  display: flex;
  margin: 16px 16px 0;
  align-items: center;

  @media(min-width: 767px) {
    display: none;
  }

  > .community-avatar {
    opacity: .5;
    margin-right: 8px;
  }

  > div {
    color: ${colors.grey};
  }
`;
Wrapper.displayName = 'Wrapper';

const CommunityHeader = ({ communityId, renderActions, mobileOnly }) => {
  const name = useSelector(state => communitySelectors.selectName(state, communityId));

  useTitle(name);

  const renderAvatar = useCallback(() => (
    <CommunityAvatar className="community-avatar" communityId={communityId} size="40px" />
  ), [communityId]);

  const renderIcon = useCallback(() => (
    <Menu color="white" />
  ), []);

  return (
    <MainHeader
      title={name}
      renderAvatar={renderAvatar}
      renderIcon={renderIcon}
      renderActions={renderActions}
      mobileOnly={mobileOnly}
    />
  );
};

CommunityHeader.propTypes = {
  communityId: PropTypes.string.isRequired,
  renderActions: PropTypes.func,
  mobileOnly: PropTypes.bool,
};

CommunityHeader.defaultProps = {
  renderActions: null,
  mobileOnly: true,
};

export default CommunityHeader;
