import styled from 'styled-components';

const SelectableListHeader = styled.div`
  background: transparent;
  padding: 16px;
  border-bottom: 1px solid rgba(0,0,0,0.04);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 64px;
  box-sizing: border-box;

  @media(max-width: 767px) {
    padding: 8px 16px;
  }
`;

export default SelectableListHeader;
