import { Trans } from 'react-i18next';
import { Heading, Text } from '@chakra-ui/react';

import useTranslation from 'hooks/useTranslation';

import Modal from 'components/Modal';

import { EMAILS } from '../../../constants';
import locales from '../i18n';

interface Props {
  close: () => void;
}

const Promoted: React.FC<Props> = ({ close }) => {
  const { t } = useTranslation(locales);

  return (
    <Modal
      title={t('Why am I seeing this?')}
      onClose={close}
    >
      <Trans t={t} ns="publication" i18nKey="whyads" values={{ sales: EMAILS.SALES }}>
        <Text>Ads are fundamental for Mazmo to continue being a free social network.</Text>
        <Text>
          Thanks to them, we can continue to offer a safe and censorship-free
          {' '}
          space for you to express yourself and find everything you are looking for.
        </Text>

        <Heading as="h3" fontSize="md" mb={4} mt={8}>I want to advertise here</Heading>

        <Text>
          Mazmo will be glad to enabling you getting in touch with the
          {' site\'s '}
          audience. Send an email to
          {' '}
          <a href={`mailto:${EMAILS.SALES}`} target="_blank" rel="noopener noreferrer">{EMAILS.SALES}</a>
          {' '}
          and a sales representative will get in touch with you.
        </Text>
      </Trans>
    </Modal>
  );
};

export default Promoted;
