import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation, useOpenClose } from 'hooks';
import * as authSelectors from 'state/auth/selectors';

import Button from 'components/Button';

import PurchaseModal from './PurchaseModal';
import locales from './i18n';

const PurchaseButton = ({ component, itemName, afterPurchase }) => {
  const { t } = useTranslation(locales);

  const myId = useSelector(authSelectors.selectId);

  const [isModalOpen, openModal, closeModal] = useOpenClose();

  const clickable = cloneElement(
    component || <Button>{t('Purchase')}</Button>,
    {
      onClick: openModal,
      disabled: !myId,
    },
  );

  return (
    <>
      {clickable}

      {/* Modal */}
      {isModalOpen && (
        <PurchaseModal
          itemName={itemName}
          close={closeModal}
          afterPurchase={afterPurchase}
        />
      )}
    </>
  );
};

PurchaseButton.propTypes = {
  component: PropTypes.element,
  itemName: PropTypes.string.isRequired,
  afterPurchase: PropTypes.func,
};

PurchaseButton.defaultProps = {
  component: null,
  afterPurchase: null,
};

export default PurchaseButton;
