/* eslint-disable import/prefer-default-export */
import { shallowEqual } from 'react-redux';

export const contentEqual = (contentA, contentB) => {
  if (typeof contentA !== typeof contentB) return false;
  if (typeof contentA === 'string') return contentA === contentB;

  return (
    shallowEqual(contentA.getCurrentContent(), contentB.getCurrentContent())
    && shallowEqual(contentA.getSelection(), contentB.getSelection())
  );
};

export const mediaGifEqual = (contentA, contentB) => {
  if (typeof contentA !== typeof contentB) return false;
  if (!contentA && !contentB) return true;
  if (!contentA || !contentB) return false;

  return contentA.id === contentB.id;
};

export const mediaEqual = (contentA, contentB) => {
  if (typeof contentA !== typeof contentB) return false;

  if (contentA.images && contentB.images) {
    if (contentA.images.length !== contentB.images.length) return false;
    // eslint-disable-next-line consistent-return
    contentA.images.forEach((imgA, index) => {
      const imgB = contentB.images[index];
      if (imgA._id !== imgB._id) return false;
    });
    return true;
  }

  if (!Object.keys(contentA) && !Object.keys(contentB)) return true;
  return false;
};

export const editorMentionsEqual = (mentionsA, mentionsB) => {
  if (mentionsA.length !== mentionsB.length) return false;
  return mentionsA.every((mA, index) => {
    const mB = mentionsB[index];
    return mA.userId === mB.userId;
  });
};
