import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'utils/css/colors';
import isMobile from 'utils/isMobile';
import { Link } from 'react-router-dom';

const TabListWrapper = styled.ul`
  display: flex;
  margin: 0 10px;
  align-self: center;
`;

const Tab = styled.li`
  padding: 10px 20px;
  cursor: pointer;
  color: ${colors.grey};
  position: relative;
  border-bottom: 4px solid transparent;

  ${props => props.selected && `
    border-bottom: 4px solid ${colors.grey};
    font-weight: 500;
  `}

  svg {
    width: 24px;
    height: 24px;
    margin: 0 4px;
  }
`;

const Badge = styled.div`
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 11px;
  background-color: ${colors.grey};
  color: white;
  border-radius: 14px;
  width: 14px;
  height: 14px;
  text-align: center;
  line-height: 16px;
`;

const TabsList = ({
  selected,
  onSelect,
  data,
}) => {
  const selectionChanged = index => () => { if (onSelect) onSelect(index); };

  return (
    <TabListWrapper>
      {data.map((tab, index) => {
        const tabSelected = index === selected;
        const showLabel = tab.label && (!tab.icon || !isMobile);

        const content = (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <Tab
            key={tab.key}
            onClick={selectionChanged(index)}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            selected={tabSelected}
          >
            {!tabSelected || !tab.iconSelected
              ? tab.icon
              : tab.iconSelected
            }
            {showLabel && tab.label}
            {tab.badge && <Badge>{tab.badge}</Badge>}
          </Tab>
        );

        if (tab.href) {
          return <Link key={tab.key} to={tab.href}>{content}</Link>;
        }

        return content;
      })}
    </TabListWrapper>
  );
};

TabsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.node,
      iconSelected: PropTypes.node,
      label: PropTypes.string,
      href: PropTypes.string,
    }),
  ).isRequired,
  selected: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
};

TabsList.defaultProps = {
  onSelect: null,
};

export default memo(TabsList);
