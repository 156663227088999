import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'hooks';
import * as userActions from 'state/users/actions';
import * as appActions from 'state/app/actions';

import Toggle from 'components/Toggle';
import Button from 'components/Button';

import locales from './i18n';

const Email = ({ data, token }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [transactional, setTransactional] = useState(data.transactional);
  const onTransactionalChange = useCallback(() => { setTransactional(v => !v); }, []);

  const [news, setNews] = useState(data.news);
  const onNewsChange = useCallback(() => { setNews(v => !v); }, []);

  const [digests, setDigests] = useState(data.digests);
  const onDigestsChange = useCallback(() => { setDigests(v => !v); }, []);

  const [chatRequests, setChatRequests] = useState(data.chatRequests);
  const onChatRequestsChange = useCallback(() => { setChatRequests(v => !v); }, []);

  const [chatUnread, setChatUnread] = useState(data.chatUnread);
  const onChatUnreadChange = useCallback(() => { setChatUnread(v => !v); }, []);

  const [comments, setComments] = useState(data.comments);
  const onCommentsChange = useCallback(() => { setComments(v => !v); }, []);

  const [replies, setReplies] = useState(data.replies);
  const onRepliesChange = useCallback(() => { setReplies(v => !v); }, []);

  const [saving, setSaving] = useState(false);

  const save = useCallback(async () => {
    try {
      setSaving(true);
      await dispatch(userActions.saveNotificationsConfigEmail({
        transactional,
        news,
        digests,
        chatRequests,
        chatUnread,
        comments,
        replies,
      }, token));
      dispatch(appActions.addToast(t('E-mail notifications saved')));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    setSaving(false);
  }, [
    transactional, news, digests, chatRequests, chatUnread, comments, replies, dispatch, t, token,
  ]);

  return (
    <div>
      <div style={{ textAlign: 'left' }}>
        <Toggle
          label={t('Transactional')}
          onChange={onTransactionalChange}
          active={transactional}
          position="left"
        />

        <br />

        <Toggle
          label={t('News')}
          onChange={onNewsChange}
          active={news}
          position="left"
        />

        <br />

        <Toggle
          label={t('Digests')}
          onChange={onDigestsChange}
          active={digests}
          position="left"
        />

        <br />

        <Toggle
          label={t('Chat Requests')}
          onChange={onChatRequestsChange}
          active={chatRequests}
          position="left"
        />

        <br />

        <Toggle
          label={t('Chat Unread')}
          onChange={onChatUnreadChange}
          active={chatUnread}
          position="left"
        />

        <br />

        <Toggle
          label={t('Publication comments')}
          onChange={onCommentsChange}
          active={comments}
          position="left"
        />

        <br />

        <Toggle
          label={t('Thread replies')}
          onChange={onRepliesChange}
          active={replies}
          position="left"
        />
      </div>

      <br />
      <br />

      <Button onClick={save} loading={saving}>{t('Save changes')}</Button>
    </div>
  );
};

Email.propTypes = {
  data: PropTypes.shape({
    transactional: PropTypes.bool.isRequired,
    news: PropTypes.bool.isRequired,
    digests: PropTypes.bool.isRequired,
    chatRequests: PropTypes.bool.isRequired,
    chatUnread: PropTypes.bool.isRequired,
    comments: PropTypes.bool.isRequired,
    replies: PropTypes.bool.isRequired,
  }).isRequired,
  token: PropTypes.string,
};

Email.defaultProps = {
  token: null,
};

export default Email;
