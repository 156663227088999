import {
  memo, useCallback, useRef, useEffect,
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import fastdom from 'fastdom';
import { useDispatch, useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';
import * as communitySelectors from 'state/communities/selectors';
import * as threadSelectors from 'state/threads/selectors';
import * as threadActions from 'state/threads/actions';

import {
  FlexWrapper,
  FlexInnerWrapper,
  FlexContainer,
} from 'components/FlexWrapper';
import Overflow from 'components/Layout/Overflow';

import ThreadListHeader from './Header';
import List from './List';
import Loading from './Loading';
import CreateThreadButton from './CreateThreadButton';
import { THREADS_LIST_LIMIT } from '../../../constants';

const LOADING_HEIGHT = 500;

const ThreadList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  const userIsLoggedIn = useSelector(authSelectors.loggedIn);

  const communitySlug = (!userIsLoggedIn || (params.communitySlug && !location.search.includes('showAll'))) ? params.communitySlug : null;
  const communityId = useSelector(
    state => communitySelectors.selectIdBySlug(state, communitySlug),
  ) || 'recent';

  const loadBeforeDate = useSelector(threadSelectors.getLastLoadedThreadDate(communityId));
  const isFullyLoaded = useSelector(threadSelectors.isFullyLoaded(communityId));
  const threadCount = useSelector(threadSelectors.listCount(communitySlug));

  const isLoading = useRef(false);
  const container = useRef(null);

  const load = useCallback(async () => {
    if (!isLoading.current && !isFullyLoaded) {
      isLoading.current = true;

      if (communityId === 'recent') {
        await dispatch(threadActions.loadRecent(loadBeforeDate));
      } else {
        await dispatch(threadActions.loadByCommunity(communityId, loadBeforeDate));
      }

      isLoading.current = false;
    }
  }, [communityId, dispatch, isFullyLoaded, loadBeforeDate]);

  const onScroll = useCallback((e) => {
    const el = e.target;

    fastdom.measure(() => {
      if (el.scrollTop >= (el.scrollHeight - el.offsetHeight - LOADING_HEIGHT)) {
        load();
      }
    });
  }, [load]);

  useEffect(() => {
    if (threadCount < THREADS_LIST_LIMIT) {
      load();
    }
  }, [load, threadCount]);

  return (
    <FlexWrapper>
      <FlexInnerWrapper fullHeight>
        <ThreadListHeader />

        <FlexContainer wide fullViewportMobile backgroundColor="#FAFAFA" style={{ height: 'calc(100% - 63px)' }}>
          <Overflow ref={container} onScroll={onScroll}>
            <ul>
              <List key={`threads-list-${communityId}`} />
              {!isFullyLoaded && <Loading />}
            </ul>
          </Overflow>
        </FlexContainer>

        <CreateThreadButton />
      </FlexInnerWrapper>
    </FlexWrapper>
  );
};

ThreadList.propTypes = {
};

ThreadList.defaultProps = {
};

export default memo(ThreadList);
