/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';

import { useOnMount, useInputValue, useTranslation } from 'hooks';

import Input from 'components/Forms/Input';
import Radio from 'components/Forms/Radio';
import InputWrapper from 'components/Forms/InputWrapper';
import PrivacyLabel from 'components/Forms/PrivacyLabel';
import PrivacyWrapper from 'components/Forms/PrivacyWrapper';

import { ERRORS } from './constants';
import { CHANNEL_PRIVACIES } from '../../constants';
import locales from './i18n';

interface Props {
  onDataChange: (data: any) => void;
  initialData: {
    name: string;
    slug?: string;
    description: string;
    privacy: string;
  };
}

const ChannelForm: React.FC<Props> = ({
  onDataChange,
  initialData = {
    name: '',
    slug: '',
    description: '',
    privacy: CHANNEL_PRIVACIES.PUBLIC,
  },
}) => {
  const { t } = useTranslation(locales);

  const firstInputEl = useRef<HTMLInputElement>(null);
  const [data, setData] = useState({ ...initialData });

  const [name, setName] = useState(initialData.name);
  const description = useInputValue(initialData.description);
  const [privacy, setPrivacy] = useState(initialData.privacy);

  useOnMount(() => {
    firstInputEl?.current?.focus();
  });

  useEffect(() => {
    if (
      data.name !== name
      || data.description !== description.value
      || data.privacy !== privacy
    ) {
      const payload: Props['initialData'] = {
        name,
        description: description.value,
        privacy,
      };
      setData(payload);
      onDataChange(payload);
    }
  }, [name, description, privacy]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    setName(newName);
  };

  const onPrivacyChange = (newPrivacy: string) => () => setPrivacy(newPrivacy);
  const onNameBlur = () => {
    const newErrors = [];

    if (name.length < 3 || name.length > 40) {
      newErrors.push(ERRORS.INCORRECT_NAME);
    }
  };

  return (
    <>
      <InputWrapper>
        <Input
          ref={firstInputEl}
          value={name}
          label={t('Name')}
          maxChars={40}
          onChange={onNameChange}
          onBlur={onNameBlur}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          value={description.value}
          label={t('Description')}
          maxChars={140}
          onChange={description.onChange}
        />
      </InputWrapper>

      <PrivacyLabel>{t('Privacy')}</PrivacyLabel>
      <PrivacyWrapper>
        <Radio
          label={t('Public')}
          onSelect={onPrivacyChange(CHANNEL_PRIVACIES.PUBLIC)}
          selected={privacy === CHANNEL_PRIVACIES.PUBLIC}
          description={t('Anyone can join and see this chat. Channel is reachable and appears in search results.')}
        />
        <Radio
          label={t('Private')}
          onSelect={onPrivacyChange(CHANNEL_PRIVACIES.PRIVATE)}
          selected={privacy === CHANNEL_PRIVACIES.PRIVATE}
          description={t('Only you can join people to this chat. Channel is secret and will only appear to participants.')}
        />
      </PrivacyWrapper>
    </>
  );
};

export default ChannelForm;
