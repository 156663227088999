import {
  useEffect, useCallback, useRef, useState,
} from 'react';
import fastdom from 'fastdom';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';
import * as feedActions from 'state/feed/actions';

import EmptyState from 'components/EmptyState';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import SpinnerWrapper from 'components/Spinner/Wrapper';

import QueueIndicator from './QueueIndicator';
import PublicationList from './PublicationList';
import locales from './i18n';

const MainFeed = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);
  const location = useLocation();
  const history = useHistory();

  const publicationCount = useSelector(feedSelectors.publicationsCount);
  const feedScroll = useSelector(feedSelectors.selectScroll);

  const isEmpty = useSelector(feedSelectors.isFeedEmpty);
  const [hasError, setHasError] = useState(false);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const isLoading = useRef(false);

  const load = useCallback(async (initial = false) => {
    if (!isLoading.current && !fullyLoaded && (!initial || !publicationCount)) {
      try {
        isLoading.current = true;
        const entities = await dispatch(feedActions.load());
        if (!Object.keys(entities || {}).length) setFullyLoaded(true);
        isLoading.current = false;
      } catch (error) {
        setHasError(true);
      }
    }
  }, [dispatch, fullyLoaded, publicationCount]);

  const [storeScrollData] = useDebouncedCallback(() => {
    const el = document.documentElement;
    dispatch(feedActions.setScroll(el.scrollTop));
  }, 500);

  useEffect(() => {
    const el = document.documentElement;

    const loadMoreScrollChanged = () => {
      fastdom.measure(() => {
        if (el.scrollHeight - el.scrollTop < 2500) {
          load();
        }
      });
    };

    document.addEventListener('scroll', loadMoreScrollChanged);
    document.addEventListener('scroll', storeScrollData);

    load(true);

    return () => {
      document.removeEventListener('scroll', loadMoreScrollChanged);
      document.removeEventListener('scroll', storeScrollData);
    };
  }, [load, storeScrollData]);


  const retry = useCallback(() => {
    isLoading.current = false;
    setHasError(false);
    load();
  }, [load]);

  const flushQueue = useCallback(() => {
    document.body.parentElement.scrollTo(0, 0);
    dispatch(feedActions.flushQueue());
  }, [dispatch]);

  useEffect(() => {
    // Not proud of this, but it works consistently
    setTimeout(() => {
      const searchParams = new URLSearchParams(location.search);

      if (searchParams.get('flushQueue') === 'true') {
        searchParams.delete('flushQueue');
        history.replace(`${location.pathname}?${searchParams.toString()}`);
        flushQueue();
      } else {
        const previousFeedScroll = feedScroll;
        const el = document.documentElement;

        if (previousFeedScroll > el.clientHeight) {
          window.scrollTo({ top: previousFeedScroll });
        }
      }
    }, 1);
  }, []);

  if (isEmpty) {
    return (
      <EmptyState
        title={t('Your feed is empty')}
        subtitle={t('We suggest you start following people you find interesting for a more personalized experience. You can explore what\'s hot right now in the trending section.')}
      >
        <Button to="/trending">{t('Go to Trending')}</Button>
      </EmptyState>
    );
  }

  if (hasError) {
    return (
      <EmptyState
        title={t('Oops!')}
        subtitle={t('There was a problem loading your feed. Our team has been alerted and is working on it right now. Please try again later.')}
      >
        <Button onClick={retry}>{t('Retry')}</Button>
      </EmptyState>
    );
  }

  return (
    <>
      <QueueIndicator onFlush={flushQueue} />
      <PublicationList type="main" />

      {!fullyLoaded && (
        <SpinnerWrapper>
          <Spinner color="#999" />
        </SpinnerWrapper>
      )}
    </>
  );
};

MainFeed.propTypes = {
};

MainFeed.defaultProps = {
};

export default MainFeed;
