import { IconType, IconDefaults } from './type';

const Flag = ({ color, className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M12.36,6L12.76,8H18V14H14.64L14.24,12H7V6H12.36M14,4H5V21H7V14H12.6L13,16H20V6H14.4"
      fill={color}
    />
  </svg>
);

Flag.propTypes = IconType;
Flag.defaultProps = IconDefaults;

export default Flag;
