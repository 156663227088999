import styled from 'styled-components';

const MenuWrapper = styled.div.attrs({
  className: 'nav-dd-content',
})`

  > div {
    display: flex;
    flex-direction: column;
    margin: 8px;
    cursor: default;

    .title {
      font-size: 24px;
      font-weight: 500;
      padding: 4px 8px;
      border-bottom: 1px solid ${props => props.theme.colors.mainLight};
    }

    .items {
      a.item:hover, .item.link:hover {
        background-color: ${props => props.theme.colors.mainLight};
      }

      .item {
        display: flex;
        align-items: flex-start;
        padding: 8px;

        &.link {
          cursor: pointer;
        }

        .icon {
          background-color: ${props => props.theme.colors.mainLight};
          border-radius: 100%;
          padding: 4px;
          margin-right: 8px;

          > svg {
            width: 24px;
            height: 24px;

            path {
              fill: rgba(0, 0, 0, .5);
            }
          }
        }

        .item-content {
          flex: 1;

          .item-title {
            font-weight: 500;
          }

          .item-description {
            font-size: 12px;
            color: ${props => props.theme.colors.secondary};
            white-space: initial;
          }

          .item-options {
            display: flex;
            flex-direction: column;
            border-top: 1px solid ${props => props.theme.colors.mainLight};
            padding: 4px;
            margin-top: 4px;
            font-size: 14px;

            a {
              padding: 4px;

              &:hover {
                background-color: ${props => props.theme.colors.mainLight};
              }

              svg {
                width: 16px;
                height: 16px;
                margin-right: 8px;

                path {
                  fill: rgba(0, 0, 0, .5);
                }
              }
            }

          }
        }
      }
    }
  }
`;
MenuWrapper.displayName = 'MenuWrapper';

export default MenuWrapper;
