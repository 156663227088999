import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { Icon } from '@chakra-ui/react';

import { useTranslation } from 'hooks';

import { Tag } from 'components/Icons';

import AlertContainer from '../AlertContainer';
import locales from '../i18n';

const TagIcon = () => <Icon as={Tag} bgColor="brand.500" fill="brand.200" rounded="full" boxSize={10} p={2} w="auto" />;

const TagExpired = ({ timestamp, read, tagName }) => {
  const { t } = useTranslation(locales);

  return (
    <AlertContainer timestamp={timestamp} image={<TagIcon />} read={read} to="/user/edit/tags">
      <Trans t={t} ns="alerts" i18nKey="tagExpired" values={{ tagname: t(`global:TAG.${tagName}`) }}>
        Your tag
        {' '}
        <strong>{'{{tagname}}'}</strong>
        {' '}
        in your profile has now expired. Purchase it again.
      </Trans>
    </AlertContainer>
  );
};

TagExpired.propTypes = {
  read: PropTypes.bool.isRequired,
  tagName: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default TagExpired;
