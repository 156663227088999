import UserDisplayName from 'components/UserDisplayName';

const MAX_USERS = 3;

const usersToText = (users, t, alertId = '') => {
  if (users.length === 1) {
    return <strong><UserDisplayName userId={users[0].id} /></strong>;
  }

  if (users.length <= MAX_USERS) {
    return (
      <>
        {users.map((user, index) => (
          <span key={`alert-dn-${alertId}-${user.id}`}>
            {index === users.length - 1 && ` ${t('global:and')} `}
            {index > 0 && index < users.length - 1 && ', '}
            <strong><UserDisplayName userId={user.id} /></strong>
          </span>
        ))}
      </>
    );
  }

  return (
    <>
      {users.slice(0, MAX_USERS - 1).map((user, index) => (
        <span key={`alert-dn-${alertId}-${user.id}`}>
          {index > 0 && index < MAX_USERS - 1 && ', '}
          <strong><UserDisplayName userId={user.id} /></strong>
          {index === MAX_USERS - 2 && <strong>{` ${t('global:and more', { num: (users.length - MAX_USERS + 1) })}`}</strong>}
        </span>
      ))}
    </>
  );
};

export default usersToText;
