import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  HStack, Icon, MenuItem, Switch, Text,
} from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';
import * as feedActions from 'state/feed/actions';
import * as appActions from 'state/app/actions';

import { Bell } from 'lucide-react';
import locales from '../i18n';

interface Props {
  publicationId: string;
}

const MenuItemFollowPublication: React.FC<Props> = ({ publicationId }) => {
  const { t } = useTranslation(locales);

  const dispatch = useDispatch();

  const [toggling, setToggling] = useState(false);
  const following = useSelector(
    // @ts-ignore
    state => feedSelectors.publications.following(state, publicationId),
  );

  const onClick = useCallback(async () => {
    try {
      setToggling(true);
      await dispatch(feedActions.toggleFollowPublication(publicationId));
      dispatch(appActions.addToast(t('Subscription updated')));
    } catch (error) {
      dispatch(appActions.addError(error));
    }

    setToggling(false);
  }, [publicationId, dispatch, t]);

  return (
    <MenuItem
      icon={<Icon as={Bell} />}
      closeOnSelect={false}
      justifyContent="space-between"
      onClick={onClick}
    >
      <HStack>
        <Text flex={1}>{t('Follow publication')}</Text>
        <Switch size="sm" isChecked={following} isDisabled={toggling} />
      </HStack>
    </MenuItem>
  );
};

export default MenuItemFollowPublication;
