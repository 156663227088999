import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as userSelectors from 'state/users/selectors';

const OnlineComponent = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 1px solid ${props => props.borderColor};
  background-color: green;
  position: absolute;
  bottom: 3px;
  right: 0px;

  ${props => parseInt(props.size.replace('px', ''), 10) < 50 && `
    width: 8px;
    height: 8px;
    bottom: 0px;
  `}
`;

const Online = ({ userId, size, borderColor }) => {
  const isOnline = useSelector(userSelectors.isOnline(userId));

  if (!isOnline) return null;
  return <OnlineComponent size={size} borderColor={borderColor} />;
};

Online.propTypes = {
  userId: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
};

Online.defaultProps = {
};

export default memo(Online);
