import styled from 'styled-components';

import colors from 'utils/css/colors';

const Footer = styled.div`
  border-top: 1px solid #aaa;
  margin-top: 40px;
  padding: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 16px;
  }

  a {
    margin-right: 10px;
    color: ${colors.red};
    text-decoration: none;
  }

  @media(max-width: 767px) {
    flex-direction: column;

    > div:first-child {
      margin-bottom: 16px;
    }
  }
`;

export default Footer;
