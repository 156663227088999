import styled from 'styled-components';

const SelectableListContainer = styled.div.attrs({
  className: 'selectable-list-container',
})`
  background-color: ${props => props.backgroundColor || '#FAFAFA'};
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export default SelectableListContainer;
