import { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as appActions from 'state/app/actions';
import * as channelsActions from 'state/channels/actions';

import { useTranslation } from 'hooks';

import Button from 'components/Button';
import Box from 'components/Forms/Box';
import ButtonsContainer from 'components/Forms/ButtonsContainer';
import ChannelForm from 'components/ChannelForm';

import locales from '../i18n';

const Form = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const [data, setData] = useState(null);
  const [creating, setCreating] = useState(false);

  const onSubmitClick = async () => {
    try {
      setCreating(true);
      const channel = await dispatch(channelsActions.create(data));

      dispatch(appActions.addToast(t('Channel succesfully created!')));
      history.push(`/chat/channels/${channel.id}`);
    } catch (error) {
      dispatch(appActions.addError(error));
      setCreating(false);
    }
  };

  const onCancel = () => {
    history.push('/chat/channels');
  };

  return (
    <Box>
      <ChannelForm onDataChange={setData} />
      <ButtonsContainer>
        <Button
          className="empty"
          onClick={onCancel}
        >
          {t('global:Cancel')}
        </Button>
        <Button
          onClick={onSubmitClick}
          loading={creating}
        >
          {t('Create channel')}
        </Button>
      </ButtonsContainer>
    </Box>
  );
};

Form.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Form);
