import { useState } from 'react';
import { Icon, IconButton } from '@chakra-ui/react';
import Axios from 'axios';
import { Share } from 'lucide-react';

import { useTranslation } from 'hooks';
import useToast from 'hooks/useToast';

import locales from './i18n';

interface Props {
  imgUrl: string;
}

const ShareButton: React.FC<Props> = ({ imgUrl }) => {
  const toast = useToast();
  const { t } = useTranslation(locales);

  const [isLoading, setIsLoading] = useState(false);

  if (!navigator.canShare) return null;

  const onClick = async () => {
    try {
      setIsLoading(true);

      const { data } = await Axios({
        url: imgUrl,
        method: 'GET',
        responseType: 'blob',
      });

      const filesArray = [
        new File([data], 'mazmo-share.jpeg', {
          type: 'image/jpeg',
          lastModified: new Date().getTime(),
        }),
      ];
      const shareData = {
        files: filesArray,
      };

      if (navigator.canShare && navigator.canShare(shareData)) {
        await navigator.share(shareData);
        toast.success('Shared!');
      } else {
        toast.error(t('Your device does not support sharing files.'));
      }
    } catch (error: any) {
      if (error.name !== 'AbortError') toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IconButton aria-label="Share" icon={<Icon as={Share} />} onClick={onClick} isLoading={isLoading} />
  );
};

export default ShareButton;
