import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';
import * as authActions from 'state/auth/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Warning from 'components/Warning';

import MuteToggles from './MuteToggles';
import locales from '../../i18n';

const BlockModal = ({ userId, close }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [feed, setFeed] = useState(true);

  const onConfirm = async () => {
    try {
      setLoading(true);

      const promises = [
        dispatch(authActions.block(userId)),
      ];

      if (feed) promises.push(dispatch(authActions.muteInFeed(userId)));
      else promises.push(dispatch(authActions.unmuteInFeed(userId)));

      await Promise.all(promises);

      const toast = feed ? t('User blocked and silenced') : t('User blocked');
      dispatch(appActions.addToast(toast));
    } catch (error) {
      dispatch(appActions.addError(error));
    }
    close();
  };

  const toggleFeed = () => {
    setFeed(f => !f);
  };

  return (
    <Modal
      title={t('Block')}
      onCancel={close}
      actions={[(
        <Button
          key="block-confirm"
          onClick={onConfirm}
          loading={loading}
        >
          {t('global:Confirm')}
        </Button>
      )]}
    >
      {t('Are you sure you want to block this person?')}

      <Warning>
        {t('When blocking a user, they will not be able to send you any private messages, or follow your publications, or relate with you in any form.')}
      </Warning>

      <br />
      <p>{`${t('You can also mute this person from the following sections of the site')}:`}</p>
      <MuteToggles feed={feed} toggleFeed={toggleFeed} />
    </Modal>
  );
};

BlockModal.propTypes = {
  userId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};

BlockModal.defaultProps = {
};

export default BlockModal;
