import styled from 'styled-components';
import moment from 'moment';

const Select = styled.select<React.HTMLProps<HTMLSelectElement>>`
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575;
  border-radius: 3px;
  font-size: 15px;
  outline: none;
`;


const returnYears = () => {
  const years = [];
  for (let i = moment().year() - 100; i <= moment().year() - 18; i += 1) {
    years.push(<option key={i} value={i}>{i}</option>);
  }

  return years;
};

interface Props {
  month: moment.Moment;
  onMonthSelect: (month: moment.Moment, value: string) => void;
  onYearSelect: (month: moment.Moment, value: string) => void;
}

const MonthElement: React.FC<Props> = ({ month, onMonthSelect, onYearSelect }) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div>
      <Select
        value={month.month()}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onMonthSelect(month, e.target.value)}
      >
        {moment.months().map((label, value) => (
          <option key={`month-${label}`} value={value}>{label}</option>
        ))}
      </Select>
    </div>
    <div>
      <Select value={month.year()} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onYearSelect(month, e.target.value)}>
        {returnYears()}
      </Select>
    </div>
  </div>
);

export default MonthElement;
