import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';

import UserLink from 'components/UserLink';
import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';

import locales from '../i18n';

const Container = styled.div`
  display: flex;
  font-size: 12px;
  box-sizing: border-box;
  padding-right: 8px;
  overflow: hidden;
  width: 125px;

  .avatar {
    margin-right: 8px;
  }

  .data {
    overflow: hidden;

    .displayname {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
Container.displayName = 'Container';

const Role = styled.div`
  color: ${props => props.theme.colors.secondary};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
Role.displayName = 'Role';

const User = ({ userId, style }) => {
  const { t } = useTranslation(locales);
  const userTags = useSelector(userSelectors.getTags(userId));
  const pronoun = useSelector(userSelectors.getPronoun(userId));

  const tags = userTags.map(tag => t(`global:TAG.${tag}`, { context: pronoun })).join(' ');

  if (!userId) return null;

  return (
    <UserLink userId={userId}>
      <Container style={style}>
        <UserAvatar userId={userId} showOnline={false} size="32px" />
        <div className="data">
          <UserDisplayName userId={userId} />
          <Role>{tags}</Role>
        </div>
      </Container>
    </UserLink>
  );
};

User.propTypes = {
  userId: PropTypes.number.isRequired,
  style: PropTypes.shape({}),
};

User.defaultProps = {
  style: {},
};

export default User;
