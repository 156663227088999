import styled from 'styled-components';

const Wrapper = styled.div`
  .community-upload-media-container {
    margin: 0 0 12px;

    li {
      width: 100px;
      height: 100px;
    }
  }
`;
Wrapper.displayName = 'AddCommentWrapper';

export default Wrapper;
