import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from '@chakra-ui/react';
import { RefreshCw } from 'lucide-react';

import { useTranslation } from 'hooks';

import EmptyState from 'components/EmptyState';

import locales from './i18n';

interface Props {
  message?: string;
  reload?: boolean;
}

const ErrorState: React.FC<Props> = ({ message, reload } = { reload: true }) => {
  const { t } = useTranslation(locales);
  const history = useHistory();

  const onReloadClick = useCallback(() => {
    history.go(0);
  }, [history]);
  const title = message || t('An unexpected error occurred. Please try again later.');

  return (
    <EmptyState title={title} uiLeftColumn>
      {reload && (
        <Button
          variant="primary"
          size="lg"
          onClick={onReloadClick}
          leftIcon={<Icon as={RefreshCw} />}
        >
          {t('Reload')}
        </Button>
      )}
    </EmptyState>
  );
};

export default ErrorState;
