import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as messengerSelectors from 'state/messengers/selectors';
import * as messengerActions from 'state/messengers/actions';

import BubbleReactions from '../Reactions';

const Reactions = ({ messageId }) => {
  const dispatch = useDispatch();

  const reactions = useSelector(
    state => messengerSelectors.getMessageReactions(state, messageId),
    shallowEqual,
  );

  const add = name => dispatch(messengerActions.addReaction(messageId, name));
  const remove = name => dispatch(messengerActions.removeReaction(messageId, name));

  return (
    <BubbleReactions
      messageId={messageId}
      reactions={reactions}
      add={add}
      remove={remove}
    />
  );
};

Reactions.propTypes = {
  messageId: PropTypes.string.isRequired,
};

Reactions.defaultProps = {
};

export default Reactions;
