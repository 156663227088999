import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as authSelectors from 'state/auth/selectors';

import Modal from 'components/Modal';
import EmptyState from 'components/EmptyState';
import Button from 'components/Button';

import locales from './i18n';

const AgeConfirmationOverlay = () => {
  const { t } = useTranslation(locales);

  const userIsLoggedIn = useSelector(authSelectors.loggedIn);
  const [opened, setOpened] = useState(!userIsLoggedIn && !localStorage.getItem('over18'));

  if (!opened) return null;

  const leave = () => {
    document.location.href = 'https://disney.com';
  };

  const close = () => {
    localStorage.setItem('over18', 1);
    setOpened(false);
  };

  const actions = [
    <Button key="age-modal-cancel" onClick={leave} color="white" fontColor="#323232">{t('Leave')}</Button>,
    <Button key="age-modal-accept" onClick={close}>{t('Yes, I am')}</Button>,
  ];

  return (
    <Modal backgroundBlur actions={actions}>
      <EmptyState title={t('disclaimer.title')}>
        {t('disclaimer.content')}
      </EmptyState>
    </Modal>
  );
};

AgeConfirmationOverlay.propTypes = {
};

AgeConfirmationOverlay.defaultProps = {
};

export default AgeConfirmationOverlay;
