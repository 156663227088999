import styled from 'styled-components';
import PropTypes from 'prop-types';

const SVG = styled.svg`
  enable-background:new -125.2 343.5 746 155.6;
  width: ${props => props.width} !important;

  .st0 { opacity:0.75; }
  .st1 { fill:#89201D; }
  .st2 { fill:#FFFFFF; }
  .st3 { fill:none; stroke:#FFFFFF; stroke-width:0.25; stroke-miterlimit:10; }
`;

const Logo = ({ width, height }) => (
  <SVG
    x="0px"
    y="0px"
    viewBox="-125.2 343.5 746 155.6"
    xmlSpace="preserve"
    width={width}
    height={height}
  >
    <g>
      <g>
        <g className="st0">
          <g>
            <polygon
              className="st1"
              points="94.3,349.3 53.1,445.8 41.9,472.3 30.4,499.2 7.7,499.2 1.9,493.4 7.7,423.6 -33.9,478.2 -38.9,471.4 -74.2,424.5 -74,499.2 -119.3,499.2 -125.2,493.4 -119.3,349.3 -78.7,350.1 -35.7,405.6 9.1,349.3 88.5,343.5"
            />
            <polygon
              className="st1"
              points="323.9,349.3 260.3,457.6 305.8,451.7 311.6,457.6 311.6,499.2 208.2,499.2 202.4,493.3 198.4,475.3 247.9,390.9 163.6,390.9 157.8,385.1 146.4,349.3 318,343.5"
            />
            <path
              className="st1"
              d="M596.9,371.3c-15.9-14.7-35.2-22-57.7-22c-21.2,0-39.5,6.5-54.8,19.5v110.9c15.3,13,33.6,19.5,54.8,19.5 c22.5,0,41.8-7.3,57.7-22c16-14.6,24-32.2,24-52.9C620.9,403.6,612.9,385.9,596.9,371.3 M564.9,447.8 c-7.2,6.5-15.7,9.7-25.7,9.7c-10,0-18.5-3.2-25.7-9.7c-7.1-6.6-10.6-14.4-10.6-23.6c0-9.1,3.5-17,10.6-23.6 c7.2-6.5,15.7-9.7,25.7-9.7c10,0,18.5,3.2,25.7,9.7c7.1,6.6,10.6,14.4,10.6,23.6C575.5,433.4,572,441.2,564.9,447.8"
            />
            <polygon className="st1" points="397,405.2 439.1,350 434.2,343.5 354.7,350" />
            <polygon className="st1" points="438.6,499.2 438.6,423.6 432.8,417.8 394.7,478 355,425.7 351.1,493.3 356.9,499.2" />
            <polygon className="st1" points="120.1,403 134,436.7 105.7,436.7 99.9,430.9 114.3,397.2" />
            <polygon className="st1" points="160,499.2 148.6,471.6 142.8,465.8 88,478.3 73.3,493.3 79.1,499.2" />
          </g>
        </g>
      </g>
      <polygon
        className="st2"
        points="88.5,343.5 47.3,440 36,466.5 24.6,493.4 1.9,493.4 1.9,417.8 -38.9,471.4 -79.8,417.8 -79.8,493.4 -125.2,493.4 -125.2,343.5 -81.2,343.5 -38.9,398.7 3.2,343.5"
      />
      <polygon
        className="st2"
        points="318,343.5 254.5,451.7 305.8,451.7 305.8,493.3 202.4,493.3 198.8,484.7 192.5,469.5 242.1,385.1 157.8,385.1 140.6,343.5"
      />
      <g>
        <path
          className="st2"
          d="M591.1,365.5c-15.9-14.7-35.2-22-57.7-22c-21.2,0-39.5,6.5-54.8,19.5v110.9c15.3,13,33.6,19.5,54.8,19.5 c22.5,0,41.8-7.3,57.7-22c16-14.6,24-32.2,24-52.9C615.1,397.8,607.1,380.1,591.1,365.5 M559.1,442c-7.2,6.5-15.7,9.7-25.7,9.7 c-10,0-18.5-3.2-25.7-9.7c-7.1-6.6-10.6-14.4-10.6-23.6c0-9.1,3.5-17,10.6-23.6c7.2-6.5,15.7-9.7,25.7-9.7c10,0,18.5,3.2,25.7,9.7 c7.1,6.6,10.6,14.4,10.6,23.6C569.7,427.6,566.2,435.4,559.1,442"
        />
        <polygon className="st2" points="392,398.7 434.2,343.5 349.8,343.5" />
        <polygon className="st2" points="432.8,493.3 432.8,417.8 392,471.3 351.1,417.8 351.1,493.3" />
        <polygon className="st2" points="114.3,397.2 128.2,430.9 99.9,430.9" />
        <polygon className="st2" points="154.2,493.3 142.7,465.8 85.4,465.8 73.3,493.3" />
      </g>
    </g>
    <path className="st3" d="M108.8,382.2" />
  </SVG>
);

Logo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Logo.defaultProps = {
  width: '100%',
  height: null,
};

export default Logo;
