import { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Image } from '@chakra-ui/react';

import colors from 'utils/css/colors';
import * as threadSelectors from 'state/threads/selectors';
import * as communitySelectors from 'state/communities/selectors';
import * as eventSelectors from 'state/events/selectors';

import { SelectableListItem } from 'components/SelectableList';
import CommunityAvatar from 'components/CommunityAvatar';
import {
  Delete, Lock, Pin, Unread,
} from 'components/Icons';

import LastReply from './LastReply';
import Status from './Status';

const Thread = memo(({ threadId, genericListing }) => {
  const communityId = useSelector(threadSelectors.getCommunityId(threadId));
  const communitySlug = useSelector(state => communitySelectors.selectSlugById(state, communityId));
  const communityName = useSelector(state => communitySelectors.selectName(state, communityId));
  const title = useSelector(threadSelectors.getTitle(threadId));
  const slug = useSelector(threadSelectors.getSlug(threadId));
  const isDeleted = useSelector(threadSelectors.isDeleted(threadId));
  const isUnread = useSelector(threadSelectors.isUnread(threadId));
  const isPinned = useSelector(threadSelectors.isPinned(threadId));
  const isClosed = useSelector(threadSelectors.isClosed(threadId));
  const eventId = useSelector(threadSelectors.getEventId(threadId));
  const eventHighlight = useSelector(state => eventSelectors.isHighlighted(state, eventId));
  const eventFlyer = useSelector(state => eventSelectors.selectFlyer(state, eventId));

  const url = `/+${communitySlug}/${slug}`;
  const match = useRouteMatch(url);
  const isActive = !!match;

  const renderContent = useCallback(() => (
    <>
      <LastReply threadId={threadId} active={isActive} />
      <Status threadId={threadId} />
    </>
  ), [threadId, isActive]);

  const leadIcon = useMemo(() => (
    <CommunityAvatar communityId={communityId} size="16px" withShadow={false} />
  ), [communityId]);

  const badges = useMemo(() => {
    const b = [];
    if (isDeleted) b.push(<Delete color={colors.red} />);
    if (isUnread) b.push(<Unread color={colors.red} />);

    return b;
  }, [isDeleted, isUnread]);

  const prebadges = useMemo(() => {
    const b = [];
    if (isPinned) b.push(<Pin color={colors.grey} />);
    if (isClosed) b.push(<Lock color={colors.grey} />);

    return b;
  }, [isPinned, isClosed]);

  const renderAvatar = useCallback(() => {
    if (eventFlyer && eventHighlight) return <Image src={eventFlyer} alt="Event Flyer" height="82px" ml={2} />;
    return null;
  }, [eventFlyer, eventHighlight]);

  return (
    <SelectableListItem
      active={isActive}
      title={title}
      lead={genericListing ? communityName : undefined}
      leadIcon={genericListing && leadIcon}
      to={isActive ? null : `${url}${genericListing ? '?showAll' : ''}`}
      prebadges={prebadges}
      badges={badges}
      renderContent={renderContent}
      renderAvatar={renderAvatar}
      highlight={eventHighlight}
      rowReverse
    />
  );
});

Thread.propTypes = {
  threadId: PropTypes.string.isRequired,
  genericListing: PropTypes.bool,
};

Thread.defaultProps = {
  genericListing: false,
};

export default Thread;
