import { MenuDivider, MenuItem, useDisclosure } from '@chakra-ui/react';
import { Shield } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'hooks';
import * as channelSelectors from 'state/channels/selectors';

import locales from '../../../i18n';
import AddBanModal from '../../Modals/AddBanModal';

interface Props {
}

const AddBanAction: React.FC<Props> = () => {
  const { t } = useTranslation(locales);
  const { chatId: channelId } = useParams<{ chatId: string }>();

  const { isOpen: isAddBanModalOpen, onOpen: openAddBanModal, onClose: closeAddBanModal } = useDisclosure();

  const isMod = useSelector((state) => (
    // @ts-ignore
    channelSelectors.isModOfChannel(state, channelId)
  ));

  if (!isMod) return null;

  return (
    <>
      <MenuItem icon={<Shield size={16} />} onClick={openAddBanModal}>{t('Add ban')}</MenuItem>
      <MenuDivider />

      <AddBanModal channelId={channelId} close={closeAddBanModal} isOpen={isAddBanModalOpen} />
    </>
  );
};

export default AddBanAction;
