/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Emoji, emojiIndex } from 'emoji-mart';

import { useTranslation } from 'hooks';

import EmojiPicker from 'components/EmojiPicker';
import UserLink from 'components/UserLink';
import UserDisplayName from 'components/UserDisplayName';
import customemojis from 'components/Composer/customemojis';
import Button from 'components/Button';

import locales from './i18n';

const customEmojis = customemojis();

const OptionsWrapper = styled.div`
  user-select: none;

  ul {
    font-size: 16px;

    li {
      padding: 12px 8px;
      border-bottom: 1px solid #DDD;
      color: #666;

      &:first-child {
        border-top: 1px solid #DDD;
      }
    }
  }
`;
OptionsWrapper.displayName = 'OptionsWrapper';

const PickerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
PickerWrapper.displayName = 'PickerWrapper';

const ReactionsWrapper = styled.ul`
  > li {
    margin-bottom: 16px;

    ul {
      margin-left: 16px;

      li {
        border-bottom: 1px solid #DDD;
        padding: 8px 0;
      }
    }
  }
`;
ReactionsWrapper.displayName = 'ReactionsWrapper';

const Options = ({
  close, reactions, addReaction, replyTo, onRemove, onEdit,
}) => {
  const { t } = useTranslation(locales);

  const [showing, setShowing] = useState('options');
  const [removing, setRemoving] = useState(false);

  if (showing === 'emojipicker') {
    const onAddReaction = (emoji) => {
      addReaction(emoji.id);
      close();
    };

    return (
      <PickerWrapper>
        <EmojiPicker onSelect={onAddReaction} autoPositioning={false} />
      </PickerWrapper>
    );
  }

  if (showing === 'reactions') {
    return (
      <ReactionsWrapper>
        {reactions.map((reaction) => {
          if (!reaction.authorIds.length) return null;

          const custom = emojiIndex
            .search(reaction.name, { custom: customEmojis })
            .find(e => e.short_names.includes(reaction.name));

          const emoji = custom || reaction.name;

          return (
            <li>
              <Emoji emoji={emoji} size={24} set="apple" />

              <ul>
                {reaction.authorIds.map(authorId => (
                  <li>
                    <UserLink userId={authorId}>
                      <UserDisplayName userId={authorId} />
                    </UserLink>
                  </li>
                ))}
              </ul>
            </li>
          );
        })}
      </ReactionsWrapper>
    );
  }

  if (showing === 'remove') {
    const onClick = async () => {
      setRemoving(true);
      await onRemove();
    };

    return (
      <div>
        {t('Are you sure you want to remove this message?')}
        <br />
        <br />
        <Button onClick={onClick} loading={removing}>{t('global:Confirm')}</Button>
      </div>
    );
  }

  return (
    <OptionsWrapper>
      <ul>
        <li onClick={() => setShowing('emojipicker')}>
          {t('Add reaction')}
        </li>
        {reactions.length > 0 && (
          <li onClick={() => setShowing('reactions')}>{t('Show reactions')}</li>
        )}
        <li onClick={replyTo}>{t('Reply')}</li>
        {onEdit && <li onClick={onEdit}>{t('global:Edit')}</li>}
        {onRemove && <li onClick={() => setShowing('remove')}>{t('global:Remove')}</li>}
      </ul>
    </OptionsWrapper>
  );
};

Options.propTypes = {
  close: PropTypes.func.isRequired,
  reactions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    authorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  })).isRequired,
  addReaction: PropTypes.func.isRequired,
  replyTo: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

Options.defaultProps = {
  onEdit: null,
  onRemove: null,
};

export default Options;
