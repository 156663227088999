import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Select,
  Stack,
  FormErrorMessage,
  Divider,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { subYears } from 'date-fns';

import { useTranslation } from 'hooks';
import * as authActions from 'state/auth/actions';

import locales from './i18n';
import { GENDERS, USERS_PRONOUNS } from '../../constants';
import InputPassword from './InputPassword';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  afterSignup?: () => void;
}

const signUpFormSchema = z.object({
  username: z.string().min(3),
  email: z.string().email(),
  birthdate: z.date(),
  gender: z.string(),
  password: z.string(),
  confirmPassword: z.string(),
}).superRefine((data, ctx) => {
  if (data.password !== data.confirmPassword) {
    ctx.addIssue({
      code: 'custom',
      message: 'Passwords do not match',
      path: ['confirmPassword'],
    });
  }
});

const SignUpModal: React.FC<Props> = ({ isOpen, onClose, afterSignup }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const {
    handleSubmit, control, register, setError, formState: { isSubmitting, errors },
  } = useForm<z.infer<typeof signUpFormSchema>>({
    resolver: zodResolver(signUpFormSchema),
  });

  const onSubmit = async (data: z.infer<typeof signUpFormSchema>) => {
    const genderKey = data.gender as keyof typeof GENDERS;
    const pronoun = (GENDERS[genderKey])?.defaultPronoun || USERS_PRONOUNS.NEUTRAL;

    const { data: { country, city, region } } = await Axios.get('https://mazmo.net/api/whereami');

    try {
      await dispatch(authActions.signup({
        username: data.username,
        password: data.password,
        gender: data.gender,
        email: data.email,
        pronoun,
        birthdate: data.birthdate,
        countryIsoCode: country,
        regionIsoCode: `${country}-${region}`,
        city: decodeURIComponent(city),
      }));

      if (afterSignup) afterSignup();
      else window.location.href = '/';
    } catch (error: any) {
      if (error.response?.data?.message === 'USERNAME_TAKEN') {
        setError('username', { type: 'manual', message: t('Username is already taken') });
      } else if (error.response?.data?.message === 'EMAIL_TAKEN') {
        setError('email', { type: 'manual', message: t('Email is already taken') });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent w="sm">
          <ModalHeader>{t('Create an account')}</ModalHeader>
          <ModalBody>
            <Stack spacing={4}>
              <FormControl isInvalid={!!errors.username}>
                <FormLabel>{t('Username')}</FormLabel>
                <Input type="text" {...register('username')} tabIndex={0} />
                {!errors.username
                  ? <FormHelperText>{t('The identifier you will use to log in')}</FormHelperText>
                  : <FormErrorMessage>{t(errors.username.message)}</FormErrorMessage>}
              </FormControl>

              <FormControl isInvalid={!!errors.email}>
                <FormLabel>{t('Email address')}</FormLabel>
                <Input type="email" {...register('email')} tabIndex={0} />
                {!errors.email
                  ? <FormHelperText>{t('We will never share your email')}</FormHelperText>
                  : <FormErrorMessage>{t(errors.email.message)}</FormErrorMessage>}
              </FormControl>

              <FormControl isInvalid={!!errors.birthdate}>
                <FormLabel>{t('Birthdate')}</FormLabel>
                <Controller
                  control={control}
                  name="birthdate"
                  render={({ field: { onChange, value } }) => (
                    <SingleDatepicker
                      date={value}
                      onDateChange={onChange}
                      maxDate={subYears(new Date(), 18)}
                      propsConfigs={{
                        triggerBtnProps: { w: 'full', fontWeight: 'normal', justifyContent: 'flex-start' },
                        dayOfMonthBtnProps: {
                          defaultBtnProps: { _hover: { bg: 'gray.200' } },
                          selectedBtnProps: { bg: 'gray.700', color: 'white', _hover: { bg: 'gray.900' } },
                        },
                      }}
                    />
                  )}
                />
                <FormErrorMessage>{t(errors.birthdate?.message)}</FormErrorMessage>
              </FormControl>

              <Divider />

              <FormControl isInvalid={!!errors.password}>
                <FormLabel>{t('Password')}</FormLabel>
                <InputPassword {...register('password')} tabIndex={0} />
                <FormErrorMessage>{t(errors.password?.message)}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.confirmPassword}>
                <FormLabel>{t('Confirm password')}</FormLabel>
                <InputPassword {...register('confirmPassword')} tabIndex={0} />
                <FormErrorMessage>{t(errors.confirmPassword?.message)}</FormErrorMessage>
              </FormControl>

              <Divider />

              <FormControl isInvalid={!!errors.gender}>
                <FormLabel>{t('Your gender')}</FormLabel>
                <Select {...register('gender')} tabIndex={0}>
                  {Object.values(GENDERS).map((gender) => (
                    <option key={gender.label} value={gender.label}>{t(`global:GENDER.${gender.label}`)}</option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.gender?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button
                onClick={onClose}
                isDisabled={isSubmitting}
              >
                {t('global:Cancel')}
              </Button>
              <Button
                variant="primary"
                isLoading={isSubmitting}
                type="submit"
              >
                {t('Sign up')}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default SignUpModal;
