import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTranslation, useOpenClose } from 'hooks';
import * as messengerActions from 'state/messengers/actions';
import * as appActions from 'state/app/actions';

import { Item } from 'components/Menu';
import Modal from 'components/Modal';
import Button from 'components/Button';

import locales from '../i18n';

const DeleteOption = ({ messageId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [opened, open, close] = useOpenClose(false);
  const [removing, setRemoving] = useState(false);

  const remove = async () => {
    try {
      setRemoving(true);
      await dispatch(messengerActions.removeMessage(messageId));
    } catch (error) {
      dispatch(appActions.addError(error));
      close();
    }
  };

  return (
    <>
      <Item danger onClick={open}>{t('global:Remove')}</Item>

      {opened && (
        <Modal
          title={t('Remove message')}
          onCancel={close}
          actions={[
            <Button key={`message-delete-confirm-${messageId}`} onClick={remove} loading={removing}>{t('global:Confirm')}</Button>,
          ]}
        >
          {t('Are you sure you want to remove this message?')}
        </Modal>
      )}
    </>
  );
};

DeleteOption.propTypes = {
  messageId: PropTypes.string.isRequired,
};

DeleteOption.defaultProps = {
};

export default DeleteOption;
