import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';

import * as replySelectors from 'state/replies/selectors';

import UserLink from 'components/UserLink';
import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';
import { Author, Description, More } from 'components/CommunityAuthor';

const AuthorInfo = ({ replyId }) => {
  const authorId = useSelector(replySelectors.getAuthorId(replyId));
  const createdAt = useSelector(replySelectors.getCreatedAt(replyId));

  return (
    <UserLink userId={authorId}>
      <Author>
        <UserAvatar userId={authorId} size="32px" />
        <Description>
          <UserDisplayName userId={authorId} />
          <More>{moment(createdAt).fromNow()}</More>
        </Description>
      </Author>
    </UserLink>
  );
};

AuthorInfo.propTypes = {
  replyId: PropTypes.string.isRequired,
};

AuthorInfo.defaultProps = {
};

export default AuthorInfo;
