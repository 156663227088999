import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'hooks';
import * as appActions from 'state/app/actions';
import * as feedActions from 'state/feed/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';

import locales from '../i18n';

const RemoveCommentModal = ({ close, publicationId, commentId }) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  const [removing, setRemoving] = useState(false);

  const remove = async () => {
    try {
      setRemoving(true);
      await dispatch(feedActions.removeComment(publicationId, commentId));
      dispatch(appActions.addToast(t('Comment removed')));
    } catch (error) {
      dispatch(appActions.addError(error));
      setRemoving(false);
    }
  };

  return (
    <Modal
      title={t('Remove comment')}
      onCancel={close}
      actions={[
        <Button key="comment-remove" onClick={remove} loading={removing}>{t('global:Confirm')}</Button>,
      ]}
    >
      {t('Are you sure you want to remove this comment?')}
    </Modal>
  );
};

RemoveCommentModal.propTypes = {
  close: PropTypes.func.isRequired,
  publicationId: PropTypes.string.isRequired,
  commentId: PropTypes.string.isRequired,
};

export default RemoveCommentModal;
