import { memo } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as messengerSelectors from 'state/messengers/selectors';

import Unread from '../Unread';

const UnreadIndicator = ({ id }) => {
  const unreadCount = useSelector(state => messengerSelectors.getUnreadCount(state, id));
  const match = useRouteMatch(`/chat/messengers/${id}`);
  const isActive = !!match;

  if (isActive || !unreadCount) return null;

  return (
    <Unread>{unreadCount}</Unread>
  );
};

UnreadIndicator.propTypes = {
  id: PropTypes.string.isRequired,
};

UnreadIndicator.defaultProps = {
};

export default memo(UnreadIndicator);
