import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { useSelector, shallowEqual } from 'react-redux';

import * as threadSelectors from 'state/threads/selectors';

import ContentMedia from 'components/ContentMedia';
import ParsedContent from 'components/ParsedContent';

import ReplyLoading from './Reply/Loading';

const BodyWrapper = styled.div`
  padding: 16px;
  line-height: 24px;
  font-size: 18px;
  text-align: left;

  h1 {
    text-align: left;
    line-height: 1em;
  }

  p {
    padding: 8px 0;
  }

  ul, ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ul {
    list-style-type: disc;

    &.content-media {
      list-style-type: none;
      padding: 0;
      display: inline-flex;

      li {
        max-width: 31%;
      }
    }
  }

  li p {
    padding: 0;
  }

  h1, h2, h3 {
    .emoji-mart-emoji {
      height: 25px;
    }
  }
`;
BodyWrapper.displayName = 'BodyWrapper';

const Body = ({ threadId }) => {
  const rawContent = useSelector(threadSelectors.getRawContent(threadId));
  const media = useSelector(threadSelectors.getMedia(threadId), shallowEqual);
  const videos = useSelector(threadSelectors.getVideoUrls(threadId), shallowEqual);

  const renderContent = () => {
    if (!rawContent) return <ReplyLoading />;
    return <ParsedContent content={rawContent} />;
  };

  return (
    <BodyWrapper>
      {renderContent()}
      <ContentMedia media={media} threshold />
      {videos.map(url => (
        <ReactPlayer key={url} url={url} width="auto" />
      ))}
    </BodyWrapper>
  );
};

Body.propTypes = {
  threadId: PropTypes.string.isRequired,
};

Body.defaultProps = {
};

export default Body;
