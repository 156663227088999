import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import * as appSelectors from 'state/app/selectors';
import * as appActions from 'state/app/actions';

import SingleColumn from './SingleColumn';
import TwoColumns from './TwoColumns';
import TwoColumnsFeed from './TwoColumnsFeed';
import ThreeColumns from './ThreeColumns';

const Layout = ({
  children,
  leftColumnOpen,
  rightColumnOpen,
  columns,
  feed,
}) => {
  const dispatch = useDispatch();
  const uiLeftColumn = useSelector(appSelectors.selectIsUiLeftColumnActive);
  const uiRightColumn = useSelector(appSelectors.selectIsUiRightColumnActive);

  const closeLeftColumn = useCallback(() => {
    dispatch(appActions.uiLeftColumn(false));
  }, [dispatch]);

  let ColumnsLayout;

  switch (columns) {
    case 1:
      ColumnsLayout = SingleColumn;
      break;
    case 2:
      ColumnsLayout = feed ? TwoColumnsFeed : TwoColumns;
      break;
    case 3:
    default:
      ColumnsLayout = ThreeColumns;
  }

  const isLeftColumnOpen = leftColumnOpen || uiLeftColumn;
  const isRightColumnOpen = rightColumnOpen || uiRightColumn;

  return (
    <ColumnsLayout className={`${isLeftColumnOpen ? 'left-column-active' : ''} ${isRightColumnOpen ? 'right-column-active' : ''}`}>
      {children}
      <div
        className="modal-opacity active"
        onClick={closeLeftColumn}
        role="button"
        tabIndex={-1}
        onKeyDown={closeLeftColumn}
      />
    </ColumnsLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  leftColumnOpen: PropTypes.bool,
  rightColumnOpen: PropTypes.bool,
  columns: PropTypes.number,
  feed: PropTypes.bool,
};

Layout.defaultProps = {
  children: null,
  leftColumnOpen: false,
  rightColumnOpen: false,
  columns: 3,
  feed: false,
};

export default Layout;
