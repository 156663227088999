import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';

import { USERS_PRONOUNS } from '../constants';

const useTranslationHook = (locale = { namespace: 'global', resources: {} }) => {
  const pronoun = useSelector(authSelectors.selectPronoun);

  const { t, i18n } = useTranslation(locale.namespace);
  i18n.addResourceBundle(
    i18n.language,
    locale.namespace,
    (locale.resources[i18n.language] || locale.resources.es || {}),
  );

  const context = pronoun || USERS_PRONOUNS.NEUTRAL;

  const tt = (key, opts, ...props) => t(
    key,
    {
      context,
      ...opts,
    },
    ...props,
  );

  return { t: tt, i18n };
};

export default useTranslationHook;
