import PropTypes from 'prop-types';
import { chakra, Image } from '@chakra-ui/react';

import max from './max.png';
import hush from './hush.png';
import lush2 from './lush2.png';
import lush3 from './lush3.png';

const LovenseToy = ({ name }) => {
  if (name === 'max') return <Image src={max} alt="Lovense Max" title="Lovense Max" width={4} height={4} />;
  if (name === 'hush') return <Image src={hush} alt="Lovense Hush" title="Lovense Hush" width={4} height={4} />;
  if (name === 'lush3') return <Image src={lush3} alt="Lovense Lush 3" title="Lovense Lush 3" width={4} height={4} />;
  return <Image src={lush2} alt="Lovense Toy" title="Lovense Toy" width={4} height={4} />;
};

LovenseToy.propTypes = {
  name: PropTypes.string.isRequired,
};

export default chakra(LovenseToy, { shouldForwardProp: (prop) => ['name'].includes(prop) });
