import axios, { AxiosInstance } from 'axios';

import { API_URL } from '../constants';

class Api {
  instance?: Api;

  baseURL: string = '';

  // @ts-ignore
  req: AxiosInstance;

  constructor() {
    // @ts-ignore
    if (!Api.instance) {
      this.baseURL = API_URL;
      this.req = axios.create({
        baseURL: this.baseURL,
        // withCredentials: true,
        headers: {
          api: 'v1',
          'users-new': 1,
        },
      });

      // @ts-ignore
      Api.instance = this;
    }

    // @ts-ignore
    return Api.instance;
  }

  setJWT(jwt: string) {
    if (this.req) this.req.defaults.headers.common.Authorization = `Bearer ${jwt}`;
  }

  unsetJWT() {
    if (this.req) delete this.req.defaults.headers.common.Authorization;
  }

  setCookie(cookie: string) {
    if (this.req) this.req.defaults.headers.common.Cookie = cookie;
  }

  me = () => this.req.get('/me').then(res => res.data);
}


export default new Api();
