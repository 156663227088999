import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as userSelectors from 'state/users/selectors';
import * as userActions from 'state/users/actions';

import { ALERT_TYPES } from '../../../constants';
import CommunityInvite from './types/CommunityInvite';
import ReactionReply from './types/ReactionReply';
import ReactionThread from './types/ReactionThread';
import SadesTransaction from './types/SadesTransaction';
import SadesAssignment from './types/SadesAssignment';
import Follow from './types/Follow';
import Know from './types/Know';
import PublicationComment from './types/PublicationComment';
import CommentReaction from './types/CommentReaction';
import PublicationReaction from './types/PublicationReaction';
import RelationshipRequested from './types/RelationshipRequested';
import RelationshipAccepted from './types/RelationshipAccepted';
import RelationshipRejected from './types/RelationshipRejected';
import RelationshipRemoved from './types/RelationshipRemoved';
import MessengerApproved from './types/MessengerApproved';
import MessengerRequested from './types/MessengerRequested';
import ChannelDeleted from './types/ChannelDeleted';
import ChannelInvitation from './types/ChannelInvitation';
import ReactionPublication from './types/ReactionPublication';
import ReactionComment from './types/ReactionComment';
import CommentPublication from './types/CommentPublication';
import PublicationMention from './types/PublicationMention';
import ThreadMention from './types/ThreadMention';
import RsvpBanned from './types/RsvpBanned';
import RsvpCreated from './types/RsvpCreated';
import PublicationVote from './types/PublicationVote';
import NewTransaction from './types/NewTransaction';
import ThreadAwarded from './types/ThreadAwarded';
import ReplyAwarded from './types/ReplyAwarded';
import PublicationSingleUnlock from './types/PublicationSingleUnlock';
import PublicationCrowdUnlock from './types/PublicationCrowdUnlock';
import ChatRequestsRefilled from './types/ChatRequestsRefilled';
import TagExpired from './types/TagExpired';
import TagsGifted from './types/TagsGifted';
import EventInvite from './types/EventInvite';
import EnrollmentApproved from './types/EnrollmentApproved';
import PublicationModerated from './types/PublicationModerated';

const Alert = ({ data }) => {
  const dispatch = useDispatch();

  const payload = data.payload || { payload: null };
  const userId = !payload.user || typeof payload.user === 'number' ? payload.user : payload.user.id;
  const authorId = !payload.author || typeof payload.author === 'number' ? payload.author : payload.author.id;
  const user = useSelector(userSelectors.getById(userId), shallowEqual);
  const author = useSelector(userSelectors.getById(authorId || payload.authorId), shallowEqual);

  payload.user = user;
  payload.author = author;

  useEffect(() => {
    if (user && user.loading) dispatch(userActions.fetchData(user.id));
  }, [user, dispatch]);

  useEffect(() => {
    if (author && author.loading) dispatch(userActions.fetchData(author.id));
  }, [author, dispatch]);

  switch (data.type) {
    case ALERT_TYPES.COMMUNITY_INVITE_CREATED:
      return <CommunityInvite {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.REACTION_REPLY_CREATED:
      return <ReactionReply alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.REACTION_THREAD_CREATED:
      return <ReactionThread alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.SADES_TRANSACTION:
      return <SadesTransaction {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.SADES_ASSIGNMENT:
      return <SadesAssignment {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.FOLLOW_CREATED:
      if (!payload || !Object.keys(payload).length) return null;
      return <Follow {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.KNOW_CREATED:
      return <Know {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.COMMENT_CREATED:
      // Legacy
      return <PublicationComment {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.REACTION_COMMENT_CREATED:
      // Legacy
      return <CommentReaction {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.REACTION_PUBLICATION_CREATED:
      // Legacy
      return <PublicationReaction {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.RELATIONSHIP_REQUESTED:
      return <RelationshipRequested {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.RELATIONSHIP_APPROVED:
      return <RelationshipAccepted {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.RELATIONSHIP_REJECTED:
      return <RelationshipRejected {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.RELATIONSHIP_REMOVED:
      return <RelationshipRemoved {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.MESSENGER_APPROVED:
      return <MessengerApproved {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.MESSENGER_REQUESTED:
      return <MessengerRequested {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.CHAT_CHANNEL_DELETED:
      return <ChannelDeleted channel={payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.CHANNELINVITATION_CREATED:
      return <ChannelInvitation {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.FEED_PUBLICATION_REACTED:
      return <ReactionPublication alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.FEED_COMMENT_REACTED:
      return <ReactionComment alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.FEED_COMMENT_CREATED:
      return <CommentPublication alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.FEED_PUBLICATION_MENTION:
      return <PublicationMention alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.COMMUNITIES_THREAD_MENTION:
      return <ThreadMention alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.RSVP_CREATED:
      return <RsvpCreated alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.RSVP_BANNED:
      return <RsvpBanned alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.FEED_PUBLICATION_VOTE:
      return <PublicationVote alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.BANK_TRANSACTION_CREATED:
      return <NewTransaction alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.THREAD_AWARDED:
      return <ThreadAwarded alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.REPLY_AWARDED:
      return <ReplyAwarded alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.PUBLICATION_SINGLE_UNLOCKED:
      return <PublicationSingleUnlock alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.PUBLICATION_CROWD_UNLOCKED:
      return <PublicationCrowdUnlock alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.CHAT_REQUESTS_REFILLED:
      return <ChatRequestsRefilled alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.TAG_EXPIRED:
      return <TagExpired alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.TAGS_GIFTED:
      return <TagsGifted alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.EVENT_INVITE:
      return <EventInvite alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.ENROLLMENT_APPROVED:
      return <EnrollmentApproved alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;
    case ALERT_TYPES.FEED_PUBLICATION_MODERATED:
      return <PublicationModerated alertId={data.id} {...payload} read={data.read} timestamp={data.updatedAt} />;

    default:
      return null;
  }
};

Alert.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(ALERT_TYPES)).isRequired,
    payload: PropTypes.shape({
      user: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
      author: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    }),
    read: PropTypes.bool,
    updatedAt: PropTypes.string,
  }).isRequired,
};

export default Alert;
