import styled from 'styled-components';

import colors from 'utils/css/colors';

const SectionTitle = styled.h3`
  text-transform: uppercase;
  color: ${colors.redReactions};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
`;
SectionTitle.displayName = 'SignupSectionTitle';

export default SectionTitle;
