import { Link } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import * as bankSelectors from 'state/bank/selectors';

import { TicketPercent } from 'components/Icons';

import ItemDetail from './ItemDetail';

const Coupons = () => {
  const coupons = useSelector(bankSelectors.selectCoupons, shallowEqual);

  if (!coupons.length) return null;

  return (
    <>
      {coupons.map(coupon => (
        <ItemDetail warning key={`item-coupon-${coupon.code}`}>
          <div>
            <TicketPercent />
            <Link to={`/user/sades/buy?coupon=${coupon.code}`}>{coupon.code}</Link>
          </div>
          <div>
            {coupon.discount > 0 && <span>{`${coupon.discount}% off`}</span>}
            {!coupon.discount && coupon.increment > 0 && <span>{`+ ${coupon.increment}%`}</span>}
          </div>
        </ItemDetail>
      ))}
    </>
  );
};

Coupons.propTypes = {
};

Coupons.defaultProps = {
};

export default Coupons;
