import PropTypes from 'prop-types';

import colors from 'utils/css/colors';
import { useTranslation, useOpenClose } from 'hooks';

import Trophy from 'components/Icons/Trophy';
import Hoverable from 'components/Hoverable';

import ActionIcon from '../ActionIcon';
import AddModal from './AddModal';
import locales from '../i18n';

const AddButton = ({ type, entityId }) => {
  const { t } = useTranslation(locales);

  const [isAddModalOpen, openAddModal, closeAddModal] = useOpenClose();

  return (
    <>
      <ActionIcon onClick={openAddModal}>
        <Hoverable
          normal={<Trophy color={colors.red} outline />}
          hover={<Trophy color={colors.red} />}
        />
      </ActionIcon>
      <ActionIcon onClick={openAddModal}>
        <span>{t('Award')}</span>
      </ActionIcon>

      {isAddModalOpen && <AddModal entityId={entityId} type={type} close={closeAddModal} />}
    </>
  );
};

AddButton.propTypes = {
  entityId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['thread', 'reply']).isRequired,
};

AddButton.defaultProps = {
};

export default AddButton;
