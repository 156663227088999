import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as userSelectors from 'state/users/selectors';
import * as appActions from 'state/app/actions';
import * as userActions from 'state/users/actions';

import { SelectableListItem } from 'components/SelectableList';
import UserAvatar from 'components/UserAvatar';
import Button from 'components/Button';
import { Delete } from 'components/Icons';

const Role = styled.div`
  color: #666;
  font-size: 14px;
  margin-top: 6px;
`;

const UserItem = ({ userId, subtitle, onDelete }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.getById(userId), shallowEqual);
  if (user.loading) dispatch(userActions.fetchData(userId));

  const [deleting, setDeleting] = useState(false);

  const del = async () => {
    try {
      setDeleting(true);
      await onDelete(userId);
    } catch (error) {
      dispatch(appActions.addError(error));
      setDeleting(false);
    }
  };

  const actions = [];
  if (onDelete) {
    actions.push(<Button key={`channel-user-item-${userId}`} onClick={del} loading={deleting}><Delete color="white" /></Button>);
  }

  return (
    <SelectableListItem
      avatar={(<UserAvatar userId={user.id} />)}
      title={user.displayname}
      actions={actions}
    >
      <Role>{subtitle}</Role>
    </SelectableListItem>
  );
};

UserItem.propTypes = {
  userId: PropTypes.number.isRequired,
  subtitle: PropTypes.string,
  onDelete: PropTypes.func,
};

UserItem.defaultProps = {
  subtitle: '',
  onDelete: null,
};

export default UserItem;
