import PropTypes from 'prop-types';

import { useTranslation } from 'hooks';

import PurchaseSadesModal from 'components/PurchaseSadesModal';

import Modal from './Modal';

const Error = ({ children, ...props }) => {
  const { t } = useTranslation();
  const content = Array.isArray(children) ? children : [children];

  if (content[0]?.response?.data?.message === 'INSUFFICIENT_FUNDS') {
    return <PurchaseSadesModal insufficient {...props} />;
  }

  return (
    <Modal {...props} zIndex={1500}>
      <ul>
        {content.map((error, index) => {
          let msg = t(`global:${error}`);
          if (typeof error === 'object') {
            if (error.response && error.response.data) {
              if (error.response.data.code === 417) {
                msg = t(`global:${error.response.data.error}`);
              } else if (error.response.data.message) {
                msg = t(`global:${error.response.data.message}`);
              }
            } else {
              msg = t(`global:${error.message}`);
            }
          }

          return (
            <li
              // eslint-disable-next-line
              key={`error-${(new Date()).getTime()}-${index}`}
            >
              {msg}
            </li>
          );
        })}
      </ul>
    </Modal>
  );
};

Error.propTypes = {
  children: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])),
};

Error.defaultProps = {
  children: null,
};

export default Error;
