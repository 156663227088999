import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ClientSocket, useSocket, useFeedwatch } from 'hooks';
import * as userActions from 'state/users/actions';
import * as authSelectors from 'state/auth/selectors';
import * as alertsActions from 'state/alerts/actions';
import * as channelsActions from 'state/channels/actions';
import * as messengerActions from 'state/messengers/actions';
import * as communityActions from 'state/communities/actions';
import * as membershipActions from 'state/memberships/actions';
import * as threadActions from 'state/threads/actions';
import * as replyActions from 'state/replies/actions';
import * as feedActions from 'state/feed/actions';
import * as eventActions from 'state/events/actions';
import * as appActions from 'state/app/actions';
import * as authActions from 'state/auth/actions';
import * as bankActions from 'state/bank/actions';

import { SOCKET_ACTIONS, API_URL } from '../../constants';

const {
  MESSENGERS,
  COMMUNITIES,
  ALERTS,
  CHANNELS,
  USERS,
  FEED,
  EVENTS,
  BANK,
} = SOCKET_ACTIONS;

const GlobalSockets = () => {
  const dispatch = useDispatch();
  const lastUpdate = useRef(new Date());
  const refetchTimer = useRef(null);
  const initialized = useRef(false);

  useSocket('disconnect', () => {
    lastUpdate.current = new Date();
  });

  window.addEventListener('offline', () => {
    lastUpdate.current = new Date();
  });

  const fetch = () => {
    if (!refetchTimer.current && initialized.current) {
      refetchTimer.current = setTimeout(() => {
        dispatch(appActions.fetchNewResouces(lastUpdate.current));
        lastUpdate.current = new Date();
      }, 300);
    }

    if (!initialized.current) initialized.current = true;
  };

  useSocket('connect', () => {
    fetch();
  });

  useSocket('reconnect', () => {
    fetch();
  });

  window.addEventListener('online', () => {
    fetch();
  });

  return null;
};

const MessengerSockets = () => {
  const dispatch = useDispatch();

  const handleDirectMessageCreated = rawData => dispatch(
    messengerActions.handleDirectMessageCreated(rawData),
  );
  const handleDirectMessageUpdated = rawData => dispatch(
    messengerActions.handleDirectMessageUpdated(rawData),
  );
  const handleDirectMessageRemoved = rawData => dispatch(
    messengerActions.handleDirectMessageRemoved(rawData),
  );
  const handleMessengerUpdated = rawData => dispatch(
    messengerActions.handleMessengerUpdated(rawData),
  );
  const handleMessengerTyping = payload => dispatch(
    messengerActions.handleMessengerTyping(payload),
  );

  useSocket(MESSENGERS.DM_CREATED, handleDirectMessageCreated);
  useSocket(MESSENGERS.DM_UPDATED, handleDirectMessageUpdated);
  useSocket(MESSENGERS.DM_REMOVED, handleDirectMessageRemoved);
  useSocket(MESSENGERS.MESSENGER_UPDATED, handleMessengerUpdated);
  useSocket(MESSENGERS.MESSENGER_TYPING, handleMessengerTyping);

  return null;
};

const ChannelSockets = () => {
  const dispatch = useDispatch();

  const handleChannelMessageCreated = rawData => dispatch(
    channelsActions.handleChannelMessageCreated(rawData),
  );
  const handleChannelMessageUpdated = rawData => dispatch(
    channelsActions.handleChannelMessageUpdated(rawData),
  );
  const handleChannelMessageRemoved = rawData => dispatch(
    channelsActions.handleChannelMessageRemoved(rawData),
  );
  const handleChannelUpdated = rawData => dispatch(
    channelsActions.handleChannelUpdated(rawData),
  );
  const handleChannelDeleted = channelId => dispatch(
    channelsActions.handleChannelDeleted(channelId),
  );
  const handleChannelTyping = data => dispatch(
    channelsActions.handleChannelTyping(data),
  );
  const handleEmbedUpdated = data => dispatch(
    channelsActions.handleEmbedUpdated(data),
  );

  useSocket(CHANNELS.CM_CREATED, handleChannelMessageCreated);
  useSocket(CHANNELS.CM_UPDATED, handleChannelMessageUpdated);
  useSocket(CHANNELS.CM_REMOVED, handleChannelMessageRemoved);
  useSocket(CHANNELS.CHANNEL_UPDATED, handleChannelUpdated);
  useSocket(CHANNELS.CHANNEL_DELETED, handleChannelDeleted);
  useSocket(CHANNELS.CHANNEL_TYPING, handleChannelTyping);
  useSocket(CHANNELS.CHAT_EMBED_UPDATED, handleEmbedUpdated);

  return null;
};

const CommunitieSockets = () => {
  const dispatch = useDispatch();
  const handleCommunityJoined = data => dispatch(communityActions.handleCommunityJoined(data));
  const handleCommunityUpdated = data => dispatch(communityActions.handleCommunityUpdated(data));

  useSocket(COMMUNITIES.COMMUNITY_JOINED, handleCommunityJoined);
  useSocket(COMMUNITIES.COMMUNITY_EDITED, handleCommunityUpdated);

  return null;
};

const MembershipSockets = () => {
  const dispatch = useDispatch();

  const handleCommunityJoined = data => dispatch(membershipActions.handleCommunityJoined(data));

  useSocket(COMMUNITIES.COMMUNITY_JOINED, handleCommunityJoined);

  return null;
};

const ThreadSockets = () => {
  const dispatch = useDispatch();

  const handleThreadCreated = data => dispatch(threadActions.handleThreadCreated(data));
  const handleThreadUpdated = data => dispatch(threadActions.handleThreadUpdated(data));

  useSocket(COMMUNITIES.THREAD_CREATED, handleThreadCreated);
  useSocket(COMMUNITIES.THREAD_EDITED, handleThreadUpdated);

  return null;
};

const ReplySockets = () => {
  const dispatch = useDispatch();

  const handleReplyCreated = data => dispatch(replyActions.handleReplyCreated(data));

  useSocket(COMMUNITIES.REPLY_CREATED, handleReplyCreated);

  return null;
};

const AlertSockets = () => {
  const dispatch = useDispatch();

  const handleAlertCreated = alert => dispatch(alertsActions.handleAlertCreated(alert));
  const handleAlertUpdated = alert => dispatch(alertsActions.handleAlertUpdated(alert));

  useSocket(ALERTS.ALERT_CREATED, handleAlertCreated);
  useSocket(ALERTS.ALERT_UPDATED, handleAlertUpdated);

  return null;
};

const UserSockets = () => {
  const dispatch = useDispatch();

  const handleOnlineList = data => dispatch(userActions.handleOnlineList(data));
  const handleOnline = data => dispatch(userActions.handleOnline(data));
  const handleOffline = data => dispatch(userActions.handleOffline(data));

  useSocket(USERS.ONLINE_LIST, handleOnlineList);
  useSocket(USERS.ONLINE, handleOnline);
  useSocket(USERS.OFFLINE, handleOffline);

  return null;
};

const FeedSockets = () => {
  const dispatch = useDispatch();
  useFeedwatch();

  const handleUpdate = data => dispatch(feedActions.handleUpdate(data));
  const handleNewPublication = data => dispatch(feedActions.handleNewPublication(data));
  const handlePublicationRemoved = data => dispatch(feedActions.handlePublicationRemoved(data));
  const handlePollUpdated = data => dispatch(feedActions.handlePollUpdated(data));

  useSocket(FEED.PUBLICATION_UPDATED, handleUpdate);
  useSocket(FEED.NEW_PUBLICATION_IN_FEED, handleNewPublication);
  useSocket(FEED.PUBLICATION_REMOVED, handlePublicationRemoved);
  useSocket(FEED.POLL_UPDATED, handlePollUpdated);

  return null;
};

const EventSockets = () => {
  const dispatch = useDispatch();

  const handleRsvpCreated = data => dispatch(eventActions.handleRsvpCreated(data));
  const handleRsvpRemoved = data => dispatch(eventActions.handleRsvpRemoved(data));

  useSocket(EVENTS.RSVP_CREATED, handleRsvpCreated);
  useSocket(EVENTS.RSVP_REMOVED, handleRsvpRemoved);

  return null;
};

const AuthSockets = () => {
  const dispatch = useDispatch();

  const handleBanned = data => dispatch(authActions.logout(data));
  const handleUpdate = data => dispatch(authActions.update(data));
  const handleRequestsUpdate = data => dispatch(authActions.loadChatRequests(data));

  useSocket(USERS.BANNED, handleBanned);
  useSocket(USERS.PRIVATELY_UPDATED, handleUpdate);
  useSocket(USERS.REQUESTS_UPDATED, handleRequestsUpdate);

  return null;
};

const BankSockets = () => {
  const dispatch = useDispatch();

  const handleCouponUpdated = data => dispatch(bankActions.handleCouponUpdated(data));

  useSocket(BANK.COUPON_UPDATED, handleCouponUpdated);

  return null;
};

const Sockets = () => {
  const loggedIn = useSelector(authSelectors.loggedIn);
  const jwt = useSelector(authSelectors.selectJWT);
  if (!loggedIn) return null;

  return (
    <ClientSocket url={API_URL} token={jwt}>
      <GlobalSockets />
      <MessengerSockets />
      <ChannelSockets />
      <CommunitieSockets />
      <MembershipSockets />
      <ThreadSockets />
      <ReplySockets />
      <AlertSockets />
      <UserSockets />
      <FeedSockets />
      <EventSockets />
      <AuthSockets />
      <BankSockets />
    </ClientSocket>
  );
};

export default Sockets;
