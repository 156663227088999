import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonGroup, Button,
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  Heading, NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Box,
  TableContainer,
  Table,
  Tr,
  Tbody,
  Td,
} from '@chakra-ui/react';

import { useTranslation } from 'hooks';
import * as userSelectors from 'state/users/selectors';
import * as channelSelectors from 'state/channels/selectors';
import * as bankSelectors from 'state/bank/selectors';
import * as appActions from 'state/app/actions';
import * as bankActions from 'state/bank/actions';

import locales from './i18n';
import { BANK_BOX_TYPES } from '../../constants';

interface Props {
  to: {
    type: string;
    id: string | number;
  };
  data?: {
    fixed: boolean;
    amount: number;
    min?: number;
    max?: number;
    transferData?: Record<string, any>;
  };
  close: () => void;
  afterTransfer?: () => void;
}

const TransferModal: React.FC<Props> = ({
  to, data, close: onClose, afterTransfer,
}) => {
  const { t } = useTranslation(locales);
  const dispatch = useDispatch();

  let selector = userSelectors.selectDisplayName;
  if (to.type === BANK_BOX_TYPES.BOT) selector = channelSelectors.getBotName;

  // @ts-ignore
  const displayname = useSelector(state => selector(state, to.id));
  const tax = useSelector(bankSelectors.selectGeneralTax);

  const [transfering, setTransfering] = useState(false);
  const [amount, setAmount] = useState(data?.amount || 10);

  const disabled = (data?.min && amount < data.min) || (data?.max && amount > data.max);

  const transfer = useCallback(async () => {
    try {
      setTransfering(true);

      const payload = { to, amount, data: data?.transferData };
      await bankActions.createTransaction(payload);

      if (afterTransfer) await afterTransfer();

      dispatch(appActions.addToast(t('Transaction successful')));
      onClose();
    } catch (error) {
      setTransfering(false);
      dispatch(appActions.addError(error));
    }
  }, [dispatch, t, amount, data, to, onClose, afterTransfer]);

  const hasMinimumAmount = typeof data?.min === 'number';
  const hasMaximumAmount = typeof data?.max === 'number';
  const taxAmount = amount * (tax / 100);
  const total = amount + taxAmount;

  return (
    <Modal isOpen onClose={onClose} closeOnEsc={!transfering} closeOnOverlayClick={!transfering}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Transfer Sades to {{displayname}}', { displayname })}</ModalHeader>
        {!transfering && <ModalCloseButton />}
        <ModalBody textAlign="center">
          <Heading mb={4}>{t('Amount')}</Heading>
          {data?.fixed
            ? <Box fontSize={36} fontWeight="bold" border="1px solid" borderColor="gray.200" w={36} mx="auto" p={2} rounded="md">{`${data.amount}`}</Box>
            : (
              <NumberInput
                defaultValue={amount.toFixed(2)}
                onChange={(valueString) => setAmount(valueString === '' ? 0 : parseFloat(valueString))}
                min={data?.min || 0}
                max={data?.max}
                precision={2}
                step={1}
                size="lg"
                w={36}
                mx="auto"
                isDisabled={transfering}
              >
                <NumberInputField textAlign="center" fontWeight="bold" fontSize={24} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )
           }

          {(hasMinimumAmount || hasMaximumAmount) && (
            <Text color="gray.500" fontSize="sm">
              {`${
                hasMinimumAmount ? `${t('Minimum')}: ${data.min}` : ''
              }${hasMinimumAmount && hasMaximumAmount ? ' - ' : ''}${
                hasMaximumAmount ? `${t('Maximum')}: ${data.max}` : ''
              }`}
            </Text>
          )}

          <TableContainer my={6}>
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Td>{t('Destinatary will receive')}</Td>
                  <Td isNumeric>{`§ ${amount.toFixed(2)}`}</Td>
                </Tr>
                <Tr>
                  <Td>{t('Taxes ({{tax}}%)', { tax })}</Td>
                  <Td isNumeric>{`§ ${taxAmount.toFixed(2)}`}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button variant="outline" onClick={onClose}>{t('global:Cancel')}</Button>
            <Button
              variant="primary"
              isLoading={transfering}
              onClick={transfer}
              isDisabled={disabled || total === 0}
            >
              {t('global:Confirm for §{{total}}', { total: total.toFixed(2) })}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TransferModal;
