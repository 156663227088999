import styled from 'styled-components';

import colors from 'utils/css/colors';

interface Props {
  animate?: boolean;
  locktober?: boolean;
}

const MULT = 0.1;
const DELAY = 1;


const ActionIcon = styled.div<Props>`
  margin-right: 8px;
  color: ${colors.red};
  font-size: 12px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    ${props => props.animate && `
      &:hover {
        animation: spankIt 500ms ease-out infinite;
      }
    `}

    @keyframes spankIt {
      0%, 100% {
        transform: skew(0deg);
      }
      50% {
        transform: skew(15deg);
      }
    }
  }

  ${props => props.locktober && `
    display: flex;

    svg {
      display: none;
    }

    .locktober {
      margin-right: 4px;

      @keyframes unlock-circle {
        0% {
            bottom: ${200 * MULT}px;
        }
        25% {
            bottom: ${200 * MULT}px;
        }
        50% {
            bottom: ${150 * MULT}px;
        }
        75% {
            bottom: ${150 * MULT}px;
        }
        100% {
            bottom: ${200 * MULT}px;
        }
      }

      @keyframes unlock-box {
          0% {
              bottom: ${280 * MULT}px;
          }
          25% {
              bottom: ${280 * MULT}px;
          }
          50% {
              bottom: ${230 * MULT}px;
          }
          75% {
              bottom: ${230 * MULT}px;
          }
          100% {
              bottom: ${280 * MULT}px;
          }
      }

      .base {
          background-color: #ED4D3D;
          width: ${200 * MULT}px;
          height: ${170 * MULT}px;
          border-radius: ${30 * MULT}px;
          margin: 0 auto;
          position: relative;
          top: ${100 * MULT}px;
          z-index: 100;
      }

      .base-bottom {
          background-color: #bdc3c7;
          width: ${200 * MULT}px;
          height: ${85 * MULT}px;
          border-radius: 0 0 ${30 * MULT}px ${30 * MULT}px;
          top: ${85 * MULT}px;
          position: relative;
          opacity: 0.4;
      }

      .lock-cirlce {
          height: ${180 * MULT}px;
          width: ${110 * MULT}px;
          border-radius: ${45 * MULT}px;
          z-index: 10;
          background-color: #bdc3c7;
          position: relative;
          margin: 0 auto;
          bottom: ${150 * MULT}px;
          animation-name: unlock-circle;
          animation-duration: ${DELAY}s;
          animation-iteration-count: infinite;
      }

      .lock-circle-inside {
          height: ${180 * MULT}px;
          width: ${50 * MULT}px;
          border-radius: ${30 * MULT}px;
          z-index: 20;
          background-color: white;
          position: relative;
          margin: 0 auto;
          top: ${25 * MULT}px;
      }

      .lock-box {
          animation-name: unlock-box;
          animation-duration: ${DELAY}s;
          animation-iteration-count: infinite;
          position: relative;
          height: ${50 * MULT}px;
          width: ${50 * MULT}px;
          background-color: white;
          bottom: ${230 * MULT}px;
          left: ${170 * MULT}px;
          z-index: 80;
      }

      .lock-inside-top {
          width: ${50 * MULT}px;
          height: ${50 * MULT}px;
          border-radius: ${50 * MULT}px;
          background-color: black;
          z-index: 300;
          position: relative;
          bottom: ${45 * MULT}px;
          left: ${75 * MULT}px;
      }

      .lock-inside-bottom {
          width: ${30 * MULT}px;
          height: ${80 * MULT}px;
          background-color: black;
          z-index: 300;
          position: relative;
          bottom: ${85 * MULT}px;
          left: ${85 * MULT}px;
      }
    }
  `}
`;

export default ActionIcon;
