import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { HStack, Text } from '@chakra-ui/react';

import colors from 'utils/css/colors';
import { useTranslation } from 'hooks';
import * as feedSelectors from 'state/feed/selectors';
import * as authSelectors from 'state/auth/selectors';
import * as feedActions from 'state/feed/actions';
import * as appActions from 'state/app/actions';

import { Spank } from 'components/Icons';
import UserAvatar from 'components/UserAvatar';
import UserDisplayName from 'components/UserDisplayName';
import UserLink from 'components/UserLink';
import ParsedContent from 'components/ParsedContent';
import ActionIcon from 'components/ActionIcon';
import Hoverable from 'components/Hoverable';
import ContextMenu from 'components/ContextMenu';
import SpankListModal from 'components/SpankListModal';
import ContentMedia from 'components/ContentMedia';

import RemoveModal from './Modals/RemoveComment';
import locales from './i18n';

const Wrapper = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
`;

const Container = styled.div`
  display: flex;
`;

const AvatarContainer = styled.div`
  margin-right: 10px;
`;

const Content = styled.div`
  flex: 1;

  a {
    color: ${colors.red};
    font-weight: 500;
  }

  a.userinactive {
    color: ${colors.grey};
  }

  .content-media {
    margin-top: 16px;
    margin-bottom: 0;

    li {
      max-width: 33%;
    }
  }
`;

const ContentWrapper = styled.div`
  word-break: break-word;
`;
ContentWrapper.displayName = 'ContentWrapper';

const Actions = styled.div`
  color: #666;
  margin-left: 34px;
  margin-top: -6px;
  display: flex;
  align-content: space-between;
  flex-direction: column;
  justify-content: space-between;

  > div:last-child {
    margin-left: auto;
    margin-top: 4px
  }

  svg {
    margin-right: 4px;
  }
`;

const Comment = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(locales);

  const comment = useSelector(state => feedSelectors.selectComment(state, id), shallowEqual);
  const publication = useSelector(
    state => feedSelectors.selectPublication(state, comment.publication),
    shallowEqual,
  );
  const media = useSelector(state => feedSelectors.comments.selectMedia(state, id), shallowEqual);
  const userId = useSelector(authSelectors.selectUserId);

  const [showingSpanksModal, setShowingSpanksModal] = useState(false);
  const showSpanksModal = () => setShowingSpanksModal(true);
  const hideSpanksModal = () => setShowingSpanksModal(false);

  const [showingRemoveModal, setShowingRemoveModal] = useState(false);
  const showRemoveModal = () => setShowingRemoveModal(true);
  const hideRemoveModal = () => setShowingRemoveModal(false);

  const [spanking, setSpanking] = useState(false);
  const [unspanking, setUnspanking] = useState(false);

  const spanked = !unspanking && (spanking || comment.reactedByUserIds.includes(userId));
  const contextItems = [
    { key: 'showspanks', onClick: showSpanksModal, component: t('Show spanks') },
  ];
  if (userId === comment.author || userId === publication.author) {
    contextItems.push({
      key: 'remove',
      onClick: showRemoveModal,
      component: t('Remove'),
      danger: true,
    });
  }

  const spankClick = async () => {
    try {
      if (spanked) {
        setUnspanking(true);
        await dispatch(feedActions.deleteCommentReaction(comment.publication, comment.id));
        setUnspanking(false);
      } else {
        setSpanking(true);
        await dispatch(feedActions.createCommentReaction(comment.publication, comment.id));
        setSpanking(false);
      }
    } catch (error) {
      setSpanking(false);
      setUnspanking(false);
      dispatch(appActions.addError(error));
    }
  };

  const renderCommentButton = outline => (
    <HStack spacing={0}>
      <Spank color={colors.red} outline={outline} />
      {comment.reactedByUserIds.length > 0 && <span>{comment.reactedByUserIds.length}</span>}
    </HStack>
  );

  return (
    <>
      <Wrapper>
        <Container>
          <AvatarContainer>
            <UserLink userId={comment.author}>
              <UserAvatar userId={comment.author} size="24px" />
            </UserLink>
          </AvatarContainer>
          <Content>
            <HStack spacing={0} color="gray.500">
              <UserLink userId={comment.author}>
                <UserDisplayName userId={comment.author} />
              </UserLink>
              <Text>・</Text>
              <Text>{moment(comment.createdAt).fromNow()}</Text>
            </HStack>

            {comment.rawContent && (
              <ContentWrapper>
                <ParsedContent content={comment.rawContent} markdown={false} emojiSize={16} />
              </ContentWrapper>
            )}
            {media.length > 0 && <ContentMedia media={{ images: media }} />}

          </Content>
          <Actions>
            <ContextMenu
              size={3}
              color="#ccc"
              items={contextItems}
            />
            <ActionIcon onClick={spankClick} animate>
              <Hoverable
                normal={renderCommentButton(!spanked)}
                hover={renderCommentButton(spanked)}
              />
            </ActionIcon>
          </Actions>
        </Container>
      </Wrapper>

      {/* Modals */}
      {showingSpanksModal && (
        <SpankListModal type="comment" entityId={id} close={hideSpanksModal} />
      )}
      {showingRemoveModal && (
        <RemoveModal
          publicationId={comment.publication}
          commentId={comment.id}
          close={hideRemoveModal}
        />
      )}
    </>
  );
};

Comment.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Comment;
