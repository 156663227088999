import {
  Fragment, useCallback, useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Api from 'state/api';
import { useTranslation } from 'hooks';

import Spinner from 'components/Spinner';
import SpinnerWrapper from 'components/Spinner/Wrapper';
import EmptyState from 'components/EmptyState';
import Ad from 'components/Ad';
import ParsedContent from 'components/ParsedContent';

import ThreadWrapper from './UI/ThreadWrapper';
import Title from './UI/Title';
import Subtitle from './UI/Subtitle';
import BodyWrapper from './UI/BodyWrapper';
import BodyShadow from './UI/BodyShadow';
import { THREADS_LIST_LIMIT } from '../../../constants';
import locales from '../i18n';

const Threads = ({ userId }) => {
  const { t } = useTranslation(locales);

  const [threads, setThreads] = useState([]);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const isLoading = useRef(false);
  const lastThreadDate = useRef(null);

  const load = useCallback(async () => {
    if (!isLoading.current && !fullyLoaded) {
      isLoading.current = true;
      const params = { authorId: userId };
      if (lastThreadDate.current) {
        params.before = lastThreadDate.current;
      }

      const { data } = await Api.req.get('/communities/threads', { params });

      if (data.length < THREADS_LIST_LIMIT) {
        setFullyLoaded(true);
      }
      if (data.length > 0) {
        setThreads(prevThreads => [
          ...prevThreads,
          ...data,
        ]);
        lastThreadDate.current = data[data.length - 1].createdAt;
      }

      isLoading.current = false;
    }
  }, [userId, fullyLoaded]);

  useEffect(() => {
    const el = document.documentElement;

    const loadMoreScrollChanged = () => {
      if (el.scrollHeight - el.scrollTop < 2500) {
        load();
      }
    };

    document.addEventListener('scroll', loadMoreScrollChanged);
    load();

    return () => {
      document.removeEventListener('scroll', loadMoreScrollChanged);
    };
  }, [load]);

  return (
    <div>
      <div>
        {threads.map((thread, index) => (
          <Fragment key={`thread-${thread.id}`}>
            <Link to={`/+${thread.community.slug}/${thread.slug}`}>
              <ThreadWrapper>
                <div className="header">
                  <Title>{thread.title}</Title>
                  <Subtitle>{`${t('In')} ${thread.community.name} • ${t('At')} ${moment(thread.createdAt).format(t('date.format'))}`}</Subtitle>
                </div>
                <BodyWrapper>
                  <ParsedContent content={thread.rawContent} />
                  <BodyShadow />
                </BodyWrapper>
              </ThreadWrapper>
            </Link>

            {index > 0 && !(index % 8) && <Ad id="In Content" />}
          </Fragment>
        ))}
      </div>

      {!fullyLoaded && (
        <SpinnerWrapper>
          <Spinner color="#999" />
        </SpinnerWrapper>
      )}

      {fullyLoaded && threads.length === 0 && (
        <EmptyState subtitle={t('No threads to show')} />
      )}
    </div>
  );
};

Threads.propTypes = {
  userId: PropTypes.number.isRequired,
};

Threads.defaultProps = {
};

export default Threads;
