import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import colors from 'utils/css/colors';
import * as communitySelectors from 'state/communities/selectors';

import { LockCircled } from 'components/Icons';

const NameWrapper = styled.div`
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  margin-top: 24px;

  > div {
    width: 16px;
    margin-left: auto;
    flex-shrink: 0;
  }
`;

const Description = styled.p`
  color: ${colors.grey};
  font-size: 14px;
`;

const NameAndDescription = ({ communityId }) => {
  const name = useSelector(state => communitySelectors.selectName(state, communityId));
  const description = useSelector(
    state => communitySelectors.selectDescription(state, communityId),
  );
  const privacy = useSelector(state => communitySelectors.selectPrivacy(state, communityId));

  return (
    <>
      <NameWrapper>
        {name}
        {privacy === 'PRIVATE' && <div><LockCircled /></div>}
      </NameWrapper>
      <Description>{description}</Description>
    </>
  );
};

NameAndDescription.propTypes = {
  communityId: PropTypes.string.isRequired,
};

NameAndDescription.defaultProps = {
};

export default NameAndDescription;
