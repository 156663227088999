import PropTypes from 'prop-types';

import { Image, Palette } from 'components/Icons';
import FileSelector from 'components/FileSelector';

const TypeSelector = ({ setPickingColor, onFileSelect }) => (
  <div className="image-or-color">
    <FileSelector onChange={onFileSelect}><button type="button"><Image color="black" /></button></FileSelector>
    <button onClick={setPickingColor} type="button"><Palette color="black" /></button>
  </div>
);

TypeSelector.propTypes = {
  setPickingColor: PropTypes.func.isRequired,
  onFileSelect: PropTypes.func.isRequired,
};

export default TypeSelector;
