import { memo } from 'react';

import { IconType, IconDefaults } from './type';

const ChevronRight = memo(({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
    />
  </svg>
));

ChevronRight.propTypes = IconType;
ChevronRight.defaultProps = IconDefaults;

export default ChevronRight;
