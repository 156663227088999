import { useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as authSelectors from 'state/auth/selectors';
import * as communitySelectors from 'state/communities/selectors';

import CommunityHeader from 'containers/Communities/Header';

import MarkAllAsSeen from './MarkAllAsSeen';
import NoCommunityHeader from './NoCommunityHeader';

const Header = () => {
  const params = useParams();
  const location = useLocation();

  const userIsLoggedIn = useSelector(authSelectors.loggedIn);

  const communitySlug = (!userIsLoggedIn || (params.communitySlug && !location.search.includes('showAll'))) ? params.communitySlug : null;
  const communityId = useSelector(
    state => communitySelectors.selectIdBySlug(state, communitySlug),
  ) || 'recent';

  const renderActions = useCallback(() => (
    <MarkAllAsSeen communityId={communityId} />
  ), [communityId]);

  if (!communitySlug) return <NoCommunityHeader />;

  return (
    <CommunityHeader
      mobileOnly={false}
      communityId={communityId}
      renderActions={renderActions}
    />
  );
};

Header.propTypes = {
};

Header.defaultProps = {
};

export default Header;
