import { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useOpenClose } from 'hooks';
import * as messengerSelectors from 'state/messengers/selectors';
import * as messengerActions from 'state/messengers/actions';

import Modal from 'components/Modal';

import OptionsModal from './OptionsModal';
import Content from './Content';
import Info from './Info';
import Options from './Options';
import Wrapper from '../Wrapper';
import ContentWrapper from '../Content';
import AvatarLink from '../AvatarLink';
import Edited from '../Edited';

const MessengerBubble = ({ messageId, showAvatar, messengerId }) => {
  const dispatch = useDispatch();
  const el = useRef(null);

  const isOutgoing = useSelector(state => messengerSelectors.isMessageOutgoing(state, messageId));
  const authorId = useSelector(state => messengerSelectors.getMessageAuthorId(state, messageId));
  const isBeingRemoved = useSelector(
    state => messengerSelectors.messageIsBeingRemoved(state, messageId),
  );

  const [mobileOptionsOpened, openMobileOptions, closeMobileOptions] = useOpenClose();

  useEffect(() => {
    const element = el.current;
    const removeEnded = () => {
      dispatch(messengerActions.messageRemoved(messageId));
    };

    if (element && isBeingRemoved) {
      element.addEventListener('transitionend', removeEnded);
    }

    return () => {
      if (element) element.removeEventListener('transitionend', removeEnded);
    };
  }, [isBeingRemoved, messageId, dispatch]);

  return (
    <Wrapper
      outgoing={isOutgoing}
      hasAvatar={showAvatar}
      remove={isBeingRemoved}
      ref={el}
    >
      {showAvatar && <AvatarLink authorId={authorId} />}
      <ContentWrapper
        outgoing={isOutgoing}
        hasAvatar={showAvatar}
        showMobileOptions={openMobileOptions}
      >
        <div className="message-header">
          <Edited type="messenger" messageId={messageId} />
          <Options messageId={messageId} messengerId={messengerId} />
        </div>
        <Content messageId={messageId} messengerId={messengerId} />
        <Info messageId={messageId} />
      </ContentWrapper>

      {mobileOptionsOpened && (
        <Modal onClose={closeMobileOptions}>
          <OptionsModal
            messengerId={messengerId}
            messageId={messageId}
            close={closeMobileOptions}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

MessengerBubble.propTypes = {
  messageId: PropTypes.string.isRequired,
  showAvatar: PropTypes.bool.isRequired,
  messengerId: PropTypes.string.isRequired,
};

MessengerBubble.defaultProps = {
};

export default memo(MessengerBubble);
